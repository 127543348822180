import { Injectable } from '@angular/core';
import { Timespans, Timestamps } from '@app/models/date';

@Injectable()
export class DateService {
	constructor(
	) {
	}

	// #region GetFromEnum

	getDate(name: Timestamps): Date {
		let date: Date;
		switch (name) {
			case Timestamps.TODAY:
				date = this.today();
				break;
			case Timestamps.YESTERDAY:
				date = this.yesterday();
				break;
			case Timestamps.DAY_BEFORE_YESTERDAY:
				date = this.dayBeforeYesterday();
				break;
			case Timestamps.ONE_WEEK_AGO:
				date = this.oneWeekAgo();
				break;
			case Timestamps.TWO_WEEKS_AGO:
				date = this.twoWeekAgo();
				break;
			case Timestamps.FOUR_WEEKS_AGO:
				date = this.fourWeekAgo();
				break;
			default:
				break;
		}
		return date;
	}

	getTimespan(name: Timespans): { fromDate: any, toDate: any } {
		let fromDate: Date;
		let toDate: Date;
		let timespan: Date[];
		switch (name) {
			case Timespans.TODAY:
				fromDate = this.today();
				toDate = this.today();
				break;
			case Timespans.YESTERDAY:
				fromDate = this.yesterday();
				toDate = this.yesterday();
				break;
			case Timespans.DAY_BEFORE_YESTERDAY:
				fromDate = this.dayBeforeYesterday();
				toDate = this.dayBeforeYesterday();
				break;
			case Timespans.THIS_WEEK:
				timespan = this.thisWeek();
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			case Timespans.LAST_WEEK:
				timespan = this.lastWeek();
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			case Timespans.WEEK_BEFORE_LAST_WEEK:
				timespan = this.weekBeforeLastWeek();
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			case Timespans.THIS_MONTH:
				timespan = this.thisMonth();
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			case Timespans.LAST_MONTH:
				timespan = this.lastMonth();
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			case Timespans.JANUARY:
				timespan = this.fullMonth(0);
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			case Timespans.FEBRUARY:
				timespan = this.fullMonth(1);
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			case Timespans.MARCH:
				timespan = this.fullMonth(2);
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			case Timespans.APRIL:
				timespan = this.fullMonth(3);
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			case Timespans.MAY:
				timespan = this.fullMonth(4);
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			case Timespans.JUNE:
				timespan = this.fullMonth(5);
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			case Timespans.JULY:
				timespan = this.fullMonth(6);
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			case Timespans.AUGUST:
				timespan = this.fullMonth(7);
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			case Timespans.SEPTEMBER:
				timespan = this.fullMonth(8);
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			case Timespans.OCTOBER:
				timespan = this.fullMonth(9);
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			case Timespans.NOVEMBER:
				timespan = this.fullMonth(10);
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			case Timespans.DECEMBER:
				timespan = this.fullMonth(11);
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			case Timespans.FIRST_QUARTER:
				timespan = this.quarter(0);
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			case Timespans.SECOND_QUARTER:
				timespan = this.quarter(1);
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			case Timespans.THIRD_QUARTER:
				timespan = this.quarter(2);
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			case Timespans.FOURTH_QUARTER:
				timespan = this.quarter(3);
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			case Timespans.FIRST_SEMESTER:
				timespan = this.semester(0);
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			case Timespans.SECOND_SEMESTER:
				timespan = this.semester(1);
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			case Timespans.THIS_YEAR:
				timespan = this.thisYear();
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			case Timespans.LAST_YEAR:
				timespan = this.lastYear();
				fromDate = timespan[0];
				toDate = timespan[1];
				break;
			default:
				break;
		}
		if (fromDate != null && toDate != null) {
			fromDate.setHours(0, 0, 0, 0);
			toDate.setHours(23, 59, 59, 999);
		}
		return { fromDate, toDate };
	}

	// #endregion GetFromEnum

	// #region GetTimestamps

	dayBeforeYesterday(): Date {
		const date: Date = new Date();
		date.setDate(date.getDate() - 2);
		return date;
	}

	fourWeekAgo(): Date {
		const date: Date = new Date();
		date.setDate(date.getDate() - 28);
		return date;
	}

	fullMonth(index: number): Date[] {
		const today: Date = new Date();
		let fromDate: Date = new Date(today.getFullYear(), index, 1, 0, 0, 0, 0);
		let toDate: Date = new Date(today.getFullYear(), index + 1, 1, 23, 59, 59, 999);
		toDate.setDate(0);
		return [fromDate, toDate];
	}

	lastMonth(): Date[] {
		let fromDate: Date = new Date();
		let toDate: Date = new Date();
		fromDate.setMonth(fromDate.getMonth() - 1);
		fromDate.setDate(1);
		toDate.setDate(0);
		return [fromDate, toDate];
	}

	lastWeek(): Date[] {
		let fromDate: Date = new Date();
		let toDate: Date = new Date();
		fromDate.setDate(fromDate.getDate() - fromDate.getDay() - 7);
		toDate.setDate(toDate.getDate() - toDate.getDay() - 1);
		return [fromDate, toDate];
	}

	lastYear(): Date[] {
		const today: Date = new Date();
		let fromDate: Date = new Date(today.getFullYear() - 1, 0, 1, 0, 0, 0, 0);
		let toDate: Date = new Date(today.getFullYear(), 0, 1, 23, 59, 59, 999);
		toDate.setDate(0);
		return [fromDate, toDate];
	}

	oneWeekAgo(): Date {
		const date: Date = new Date();
		date.setDate(date.getDate() - 7);
		return date;
	}

	quarter(index: number): Date[] {
		const today: Date = new Date();
		let fromDate: Date = new Date(today.getFullYear(), index * 3, 1, 0, 0, 0, 0);
		let toDate: Date = new Date(today.getFullYear(), (index + 1) * 3, 1, 23, 59, 59, 999);
		toDate.setDate(0);
		return [fromDate, toDate];
	}

	semester(index: number): Date[] {
		const today: Date = new Date();
		let fromDate: Date = new Date(today.getFullYear(), index * 6, 1, 0, 0, 0, 0);
		let toDate: Date = new Date(today.getFullYear(), (index + 1) * 6, 1, 23, 59, 59, 999);
		toDate.setDate(0);
		return [fromDate, toDate];
	}

	thisMonth(): Date[] {
		let fromDate: Date = new Date();
		let toDate: Date = new Date();
		fromDate.setDate(1);
		toDate.setMonth(toDate.getMonth() + 1);
		toDate.setDate(0);
		return [fromDate, toDate];
	}

	thisWeek(): Date[] {
		let fromDate: Date = new Date();
		let toDate: Date = new Date();
		fromDate.setDate(fromDate.getDate() - fromDate.getDay());
		toDate.setDate(toDate.getDate() - toDate.getDay() + 6);
		return [fromDate, toDate];
	}

	thisYear(): Date[] {
		const today: Date = new Date();
		let fromDate: Date = new Date(today.getFullYear(), 0, 1, 0, 0, 0, 0);
		let toDate: Date = new Date(today.getFullYear() + 1, 0, 1, 23, 59, 59, 999);
		toDate.setDate(0);
		return [fromDate, toDate];
	}

	today(): Date {
		const date: Date = new Date();
		return date;
	}

	twoWeekAgo(): Date {
		const date: Date = new Date();
		date.setDate(date.getDate() - 14);
		return date;
	}

	weekBeforeLastWeek(): Date[] {
		let fromDate: Date = new Date();
		let toDate: Date = new Date();
		fromDate.setDate(fromDate.getDate() - fromDate.getDay() - 14);
		toDate.setDate(toDate.getDate() - toDate.getDay() - 8);
		return [fromDate, toDate];
	}

	yesterday(): Date {
		const date: Date = new Date();
		date.setDate(date.getDate() - 1);
		return date;
	}

	inputDateEx(input: string): Date {
		return DateService.inputDateExStatic(input);
	}

	static inputDateExStatic(input: string): Date {
		//console.log('called inputDateExStatic, input: ' + input);
		const today = new Date();
		let date = null;

		if (input.length == 1) {
			if (input == 'h') {
				date = today;
				date.setHours(0, 0, 0, 0);
			} else if (input == 'g') {
				date = new Date(today.setDate(today.getDate() - 1));
				date.setHours(0, 0, 0, 0);
			} else if (input == 'm') {
				date = new Date(today.setDate(today.getDate() + 1));
				date.setHours(0, 0, 0, 0);
			}
		//} else if (input.length == 8 && (/^\d+$/.test(input))) {
		//	date = new Date(Number(input.substring(4, 8)), Number(input.substring(2, 4)) - 1, Number(input.substring(0, 2)), 0, 0, 0, 0);
		//}
		} else if (input.length == 6 && (/^\d+$/.test(input))) {
			// 24042022
			//date = new Date(Number(input.substring(4, 8)), Number(input.substring(2, 4)) - 1, Number(input.substring(0, 2)), 0, 0, 0, 0);
			// umgestellt auf: 240422 => 24.04.2022
			// Jahreswert zweistellig, wenn <= 50, dann +2000, wenn > 50, dann + 1900
			let jahr = Number(input.substring(4, 6));
			if( jahr <= 50)
				jahr += 2000;
			else jahr += 1900;

			date = new Date(jahr, Number(input.substring(2, 4)) - 1, Number(input.substring(0, 2)), 0, 0, 0, 0);
		}

		//console.log('returning: ' + date);
		return date;
	}

	// #endregion GetTimestamps
}
