<div class="p-grid">
	<div class="p-col-12">

		<!-- Message -->
		<p-toast>
			<ng-template let-message pTemplate="message">
				<div class="p-grid p-ml-2">
					<div class="p-col-2">
						<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
					</div>
					<div class="p-col-10">
						<h4>{{message.summary}}</h4>
					</div>
					<div class="p-col-12">
						<p>{{message.detail}}</p>
					</div>
				</div>
			</ng-template>
		</p-toast>

		<!-- Spinner -->
		<div *ngIf="loading" class="spinner">
			<span class="ball-1"></span>
			<span class="ball-2"></span>
			<span class="ball-3"></span>
			<span class="ball-4"></span>
			<span class="ball-5"></span>
			<span class="ball-6"></span>
			<span class="ball-7"></span>
			<span class="ball-8"></span>
		</div>

		<div class="card">
			<h4 translate>REPORTS.ESWANMBAUMSCHNITT_HEADER</h4>
			<br />
			<p [innerHTML]="'ESWREPORT.ANM_BAUMSCHNITT_UNTERTITEL' | translate"></p>
		</div>
		<div class="card p-mb-0">
			<!--
			<h6 class="p-my-2" translate>ESWREPORT.INPUT_KASSENZEICHEN</h6>
			<span class="p-input-icon-right" style="width:auto">
				<input type="text" placeholder='xxxxxx.xxx.x' pInputText style="width:auto"
					   [value]="filterKassenzeichen" (input)="filterKassenzeichen=$event.target.value" />
				<i class="pi pi-times" (click)="filterKassenzeichen=''"></i>
			</span>
			-->

			<h6 class="p-my-2" translate>ESWREPORT.INPUT_KUNDENNUMMER</h6>
			<span class="p-input-icon-right" style="width:auto">
				<input type="text" placeholder='xxxxxx' pInputText style="width:auto"
					   [value]="filterKundennummer" (input)="filterKundennummer=$event.target.value" />
				<i class="pi pi-times" (click)="filterKundennummer=''"></i>
			</span>

			<h6 class="p-my-2" translate>ESWREPORT.INPUT_GEFAESSNUMMER</h6>
			<span class="p-input-icon-right" style="width:auto">
				<input type="text" placeholder='xxxxxxxx' pInputText style="width:auto" [value]="filterGefaessnummer"
					   (input)="filterGefaessnummer=$event.target.value" />
				<i class="pi pi-times" (click)="filterGefaessnummer=''"></i>
			</span>
			
			<br>
			<button pButton pRipple label="{{'ESWREPORT.BUTTON_CHECK_BERECHTIGUNG_STRAUCHSCHNITT'|translate}}" class="p-button p-mt-5"
					style="width:auto" (click)="checkBerechtigungShowResult()"></button>
		</div>

		<div *ngIf="dataReadyOk" class="card">
			<p [innerHTML]="resultBodyOk"></p>
			<button pButton pRipple label="{{'ESWREPORT.BUTTON_OPEN_PDF'|translate}}" class="p-button p-mt-5"
				style="width:auto" (click)="strauchschnittAsPdf_openWindow()"></button>
			&nbsp;&nbsp;&nbsp;
			<button pButton pRipple label="{{'ESWREPORT.BUTTON_DOWNLOAD_PDF'|translate}}" class="p-button p-mt-5"
				style="width:auto" (click)="strauchschnittAsPdf_downloadFile()"></button>
		</div>

		<div *ngIf="dataReadyDenied" class="card">
			<p [innerHTML]="resultBodyDenied"></p>
		</div>

	</div>
</div>