import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	templateUrl: './create.component.html',
	styleUrls: ['../style.scss'],
})
export class VToolsCreateComponent {
	loading = 0;
	vtools = {};

	constructor(
		public config: DynamicDialogConfig,
		private messageService: MessageService,
		public ref: DynamicDialogRef,
		public translate: TranslateService
	) {
	}

	ngOnInit(): void {
		this.vtools['Mode'] = 1;
		this.vtools['UserName'] = this.config.data.UserName;
		this.vtools['UserConnectionSettingsId'] = this.config.data.UserConnectionSettingsId;
	}

	create(): void {
		if (this.vtools && this.vtools['Mode'] && this.vtools['UserName'] && this.vtools['UserConnectionSettingsId'] && this.vtools['Password'] && this.vtools['Password'].length > 0) {
			this.ref.close(this.vtools);
		} else {
			this.messageService.add({ severity: 'error', summary: this.translate.instant('Required Field'), detail: this.translate.instant('Password Required'), life: 5000 });
		}
	}
}