<p-toast>
	<ng-template let-message pTemplate="message">
		<div class="p-grid p-ml-2">
			<div class="p-col-2">
				<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
			</div>
			<div class="p-col-10">
				<h4>{{message.summary}}</h4>
			</div>
			<div class="p-col-12">
				<p>{{message.detail}}</p>
			</div>
			<div *ngIf="message.severity!='success'" class="p-col-12">
				<a class="p-button"
					href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
					Fehler melden
				</a>
			</div>
		</div>
	</ng-template>
</p-toast>

<!-- Spinner -->
<div *ngIf="loading!==0" class="spinner">
	<span class="ball-1"></span>
	<span class="ball-2"></span>
	<span class="ball-3"></span>
	<span class="ball-4"></span>
	<span class="ball-5"></span>
	<span class="ball-6"></span>
	<span class="ball-7"></span>
	<span class="ball-8"></span>
</div>


<div class="p-grid p-formgrid">
	<!-- allVehiclesTable -->
	<div class="p-col-12 p-md-12 p-lg-7 p-mb-3">
		<!-- <p-table #allVehiclesTable styleClass="p-datatable-sm" [value]="allVehicles" [columns]="allVehiclesTableColumns"
			[rows]="5" [scrollable]="true" scrollHeight="40vh" selectionMode="multiple" [(selection)]="selectedVehicles"
			reorderableColumns="true" resizableColumns="true" (sortFunction)="allVehiclesTableOnSort($event)"
			[customSort]="true" [globalFilterFields]="allVehiclesTableColumns" filterDelay="0" [rowHover]="true"
			dataKey="ds_this_id">
			<ng-template pTemplate="caption">
				<div class="p-d-flex p-ai-center p-jc-between">
					<div>
					</div>
					<h5 class="p-m-0" translate>VEHICLES.ALL_VEHICLES</h5>
					<span class="p-input-icon-left">
						<i class="pi pi-search"></i>
						<input pInputText id="global" type="text" id="vehiclesSearch"
							(input)="allVehiclesTableOnFilter($event)" placeholder="{{'BUTTONS.SEARCH'|translate}}" />
					</span>
				</div>
			</ng-template>
			<ng-template pTemplate="colgroup" let-columns>
				<colgroup>
					<col style="width: 40px;">
					<col style="width: 60px;">
					<col *ngFor="let col of columns" style="width: 125px;">
					<col style="width: 50px;">
				</colgroup>
			</ng-template>
			<ng-template pTemplate="header" let-vehicle let-columns>
				<tr>
					<th>
						<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
					</th>
					<th pResizableColumn pReorderableColumn class="p-pl-3">
						<div class="p-d-flex p-jc-between p-ai-center">{{ 'HEADERS.Servicedaten_ts_lastidle' |
							translate}}</div>
					</th>
					<th *ngFor="let col of columns" pResizableColumn pReorderableColumn [pSortableColumn]="col.field">
						<div class="p-d-flex p-jc-between p-ai-center">{{ 'HEADERS.' + col.header | translate}}</div>
					</th>
					<th pResizableColumn>
						<button pButton pRipple icon="pi pi-filter" class="p-button-rounded p-button-secondary p-mr-2"
							(click)="allVehiclesTableShowFilters = !allVehiclesTableShowFilters"
							pTooltip="{{'BUTTONS.FILTER'|translate}}" tooltipPosition="bottom" showDelay="500"></button>
					</th>
				</tr>
				<tr *ngIf="allVehiclesTableShowFilters">
					<th></th>
					<th>
						<p-dropdown [style]="{'width':'100%'}" appendTo="body" [options]="onlineStatusTypes"
							[(ngModel)]="selectedOnlineStatusType"
							(onChange)="allVehiclesTableOnFilterOnlineStatus($event)" optionLabel="label"
							placeholder="-" dropdownDirective>
							<ng-template pTemplate="selectedItem">
								<div *ngIf="selectedOnlineStatusType&&selectedOnlineStatusType.color">
									<i class="{{selectedOnlineStatusType.icon}}"
										style="color: {{selectedOnlineStatusType.color}}"></i>
								</div>
								<div *ngIf="selectedOnlineStatusType&&!selectedOnlineStatusType.color">
									<i class="{{selectedOnlineStatusType.icon}}"></i>
								</div>
							</ng-template>
							<ng-template let-onlineStatusType pTemplate="item">
								<div *ngIf="onlineStatusType.color">
									<i class="{{onlineStatusType.icon}}" style="color: {{onlineStatusType.color}}"></i>
								</div>
								<div *ngIf="!onlineStatusType.color">
									<i class="{{onlineStatusType.icon}}"></i>
								</div>
							</ng-template>
						</p-dropdown>
					</th>
					<th *ngFor="let col of columns" class="p-pl-3">
						<input pInputText id="{{col.field}}" type="text"
							(input)="allVehiclesTableOnFilterColumn($event, col)"
							placeholder="{{'HEADERS.' + col.header|translate}}" class="p-column-filter ml-0"
							style="width:100%;">
					</th>
					<th>
						<button pButton pRipple icon="pi pi-times" class="p-button-rounded p-button-secondary p-mr-2"
							(click)="allVehiclesTableClearFilters()" pTooltip="{{'BUTTONS.RESET'|translate}}"
							tooltipPosition="bottom" showDelay="500"></button>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-vehicle let-columns="columns">
				<tr>
					<td>
						<p-tableCheckbox [value]="vehicle"></p-tableCheckbox>
					</td>
					<td class="p-pl-3">
						<div *ngIf="vehicle.onlineStatus === null">
							<i class="pi pi-circle-off"></i>
						</div>
						<div *ngIf="vehicle.onlineStatus < 0">
							<i class="pi pi-circle-on" style="color: crimson"></i>
						</div>
						<div *ngIf="vehicle.onlineStatus === 0">
							<i class="pi pi-circle-on" style="color: orange"></i>
						</div>
						<div *ngIf="vehicle.onlineStatus > 0">
							<i class="pi pi-circle-on" style="color: green"></i>
						</div>
					</td>
					<td *ngFor="let col of columns">
						<div *ngIf="!vehicle[col.field]">
							<div *ngIf="vehicle[col.field]!==false">
								<i class="pi pi-minus"></i>
							</div>
							<div *ngIf="vehicle[col.field]===false">
								<i class="pi pi-times" style="color: red"></i>
							</div>
						</div>
						<div *ngIf="vehicle[col.field]">
							<div *ngIf="vehicle[col.field]!==true">
								{{vehicle[col.field]}}
							</div>
							<div *ngIf="vehicle[col.field]===true">
								<i class="pi pi-check" style="color: green;font-weight: bold;"></i>
							</div>
						</div>
					</td>
					<td></td>
				</tr>
			</ng-template>
			<ng-template pTemplate="summary">
				<div class="p-d-flex p-ai-center p-jc-between p-pl-2">
					{{ 'VEHICLES.VEHICLE_COUNT' | translate : { count : (allVehicles ? allVehiclesCount : 0)
					} }}
				</div>
			</ng-template>
		</p-table> -->
		<p-table #vehiclesTable (onColResize)="onColResize($event)" (onColReorder)="onColReorder($event)"
			(onFilter)="onFilter($event)" [globalFilterFields]="vehiclesTableFilters" [reorderableColumns]="true"
			[resizableColumns]="true" [scrollHeight]="'40vh'" [virtualRowHeight]="45" [virtualScroll]="true"
			columnResizeMode="expand" dataKey="ds_auto_id" scrollDirection="both" selectionMode="multiple"
			[(selection)]="selectedVehicles" [stateKey]="vehiclesTableStateName" stateStorage="local"
			styleClass="p-datatable-gridlines" [columns]="vehiclesTableCols" [rows]="100" [scrollable]="true"
			[value]="vehicles">
			<ng-template pTemplate="caption">
				<div class="p-d-flex p-ai-center p-jc-between">
					<div id="buttoncolhead">
						<p-multiSelect #colselection [options]="vehiclesTablePossibleCols"
							[(ngModel)]="vehiclesTableCols" dataKey="key" (onChange)="toggleColumn($event)"
							optionLabel="label" appendTo="body" [style.visibility]="'hidden'"
							[style.position]="'absolute'" class="colselect" [panelStyle]="{'min-width':'auto'}">
						</p-multiSelect>
						<button pButton pRipple icon="fas fa-table-columns"
							class="p-button-rounded p-button-secondary p-mr-2"
							(click)="colselection.show($event.originalEvent);"
							pTooltip="{{'BUTTONS.COL_SELECT'|translate}}" tooltipPosition="bottom"
							showDelay="500"></button>
						<button pButton pRipple icon="pi pi-filter-slash"
							class="p-button-rounded p-button-secondary p-mr-2" (click)="vehiclesTable.clear()"
							pTooltip="{{'BUTTONS.RESET_FILTERS'|translate}}" tooltipPosition="bottom"
							showDelay="500"></button>
						<button pButton pRipple icon="fas fa-table-cells"
							class="p-button-rounded p-button-secondary p-mr-2" (click)="resetTable()"
							pTooltip="{{'BUTTONS.RESET_TABLE'|translate}}" tooltipPosition="bottom"
							showDelay="500"></button>
					</div>
					<h5 class="p-m-0">{{ vehiclesTableName | translate }}</h5>
					<span class="p-input-icon-left">
						<i class="pi pi-search"></i>
						<input pInputText id="global" type="text" [value]="vehiclesTableGlobalFilter"
							(input)="vehiclesTable.filterGlobal($event.target.value, 'contains');vehiclesTableGlobalFilter = $event.target.value;"
							placeholder="{{'BUTTONS.SEARCH'|translate}}" />
					</span>
				</div>
			</ng-template>
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th style="width:50px;flex:none;">
						<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
					</th>
					<th *ngFor="let col of columns" [style.flex]="'0 0 ' + col.width + 'px !important'"
						[pSortableColumn]="col.key" pResizableColumn pReorderableColumn>
						<div class="p-d-flex p-ai-center p-jc-between" style="min-width: 100%">
							<div>
								<p-sortIcon [field]="col.key"></p-sortIcon>
								{{ 'HEADERS.' + col.key | translate }}
							</div>
							<ng-container *ngIf="col.type == 'online'">
								<p-columnFilter [field]="col.key" matchMode="in" display="menu" [showMatchModes]="false"
									[showOperator]="false" [showAddButton]="false" appendTo="body"
									[style.color]="(isColFiltered(col.key) ? 'var(--primary-color)' : 'var(--text-color)')">
									<ng-template pTemplate="filter" let-value let-filter="filterCallback">
										<p-multiSelect [ngModel]="value" [options]="onlineStatusTypeOptions"
											placeholder="-" (onChange)="filter($event.value)" optionLabel="icon"
											optionValue="value">
											<ng-template let-value pTemplate="selectedItems">
												<div class="p-d-flex">
													<div *ngFor="let option of value" class="p-mx-1">
														<i [ngClass]="option === null ? 'pi pi-circle-off' : 'pi pi-circle-on'"
															[style.color]="option !== null ? onlineStatusTypes[option+1] : ''"></i>
													</div>
												</div>
												<div *ngIf="!value || value.length === 0">
													-
												</div>
											</ng-template>
											<ng-template let-option pTemplate="item">
												<i [ngClass]="option.value === null ? 'pi pi-circle-off' : 'pi pi-circle-on'"
													[style.color]="option.value !== null ? onlineStatusTypes[option.value+1] : ''"></i>
											</ng-template>
										</p-multiSelect>
									</ng-template>
								</p-columnFilter>
							</ng-container>
							<ng-container *ngIf="col.type != 'online'">
								<p-columnFilter [type]="col.type" [field]="col.key" display="menu" appendTo="body"
									[style.color]="(isColFiltered(col.key) ? 'var(--primary-color)' : 'var(--text-color)')">
								</p-columnFilter>
							</ng-container>
						</div>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-entry let-rowIndex="rowIndex" let-columns="columns">
				<tr style="height:45px">
					<td style="width:50px;flex:none;">
						<p-tableCheckbox [value]="entry"></p-tableCheckbox>
					</td>
					<td *ngFor="let col of columns" [style.flex]="'0 0 ' + col.width + 'px !important'">
						<div *ngIf="entry[col.key] === null">
							<i class="pi pi-minus"></i>
						</div>
						<div *ngIf="entry[col.key] !== null">
							<div *ngIf="col.type == 'text'">
								{{ entry[col.key] }}
							</div>
							<div *ngIf="col.type == 'numeric'">
								{{ entry[col.key] }}
							</div>
							<div *ngIf="col.type == 'boolean'">
								<i [ngClass]="entry[col.key] ? 'pi pi-check' : 'pi pi-times'"
									[style.color]="entry[col.key] ? 'green' : 'red'"></i>
							</div>
							<div *ngIf="col.type == 'date'">
								{{ entry[col.key].toLocaleString() }}
							</div>
							<div *ngIf="col.type == 'online'">
								<div *ngIf="entry[col.key] === null">
									<i class="pi pi-circle-off"></i>
								</div>
								<div *ngIf="entry[col.key] < 0">
									<i class="pi pi-circle-on" style="color: crimson"></i>
								</div>
								<div *ngIf="entry[col.key] === 0">
									<i class="pi pi-circle-on" style="color: orange"></i>
								</div>
								<div *ngIf="entry[col.key] > 0">
									<i class="pi pi-circle-on" style="color: green"></i>
								</div>
							</div>
						</div>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="summary">
				<div class="p-d-flex p-ai-center p-jc-between">
					{{ 'TABLE.COUNT' | translate : { count : vehiclesTableCount } }}
					{{ (vehiclesTable && vehiclesTable.filteredValue ? 'TABLE.TOTAL' : '') | translate : {
					count : (vehicles
					? vehicles.length
					: 0) } }}
				</div>
			</ng-template>
		</p-table>
	</div>
	<div class="p-col-12 p-md-12 p-lg-5 p-mb-3">
		<div class="p-d-flex p-jc-center" style="height: 100%;">
			<div>
				<h6 class="p-my-2">Zeitpunkt</h6>
				<p-dropdown [style]="{'width':'100%'}" [options]="timespans" [(ngModel)]="selectedTimespan"
					appendTo="body" (onChange)="convertTimespanSelection($event)" [filter]="true" filterBy="label"
					placeholder="-" dropdownDirective>
				</p-dropdown>
				<h6 class="p-mt-5 p-mb-2">Von</h6>
				<p-calendar [showIcon]="true" dateFormat="dd.mm.yy" keepInvalid="true"
					(onInput)="inputDate('fromDate', $event.target.value)" showTime="true" hourFormat="24"
					[(ngModel)]="fromDate" appendTo="body" (onSelect)="selectedTimespan=-1;">
				</p-calendar>
				<h6 class="p-mt-5 p-mb-2">Bis</h6>
				<p-calendar [showIcon]="true" dateFormat="dd.mm.yy" keepInvalid="true"
					(onInput)="inputDate('toDate', $event.target.value)" showTime="true" hourFormat="24"
					[(ngModel)]="toDate" appendTo="body" (onSelect)="selectedTimespan=-1;">
				</p-calendar>
				<div class="p-my-5">
					<p-checkbox [(ngModel)]="keepUpToDate" [binary]="true"></p-checkbox>
					{{ 'VEHICLES.LOAD_LIVE_DATA' | translate }}
				</div>
				<div class="p-my-5">
					<button pButton pRipple label="{{'Fahrzeuge laden'| translate }}" class="p-button p-my-5"
						(click)="load()"
						[disabled]="(selectedVehicles.length == 0 || loading !== 0) ? true : false"></button>
				</div>
			</div>
		</div>
	</div>
</div>