<div class="p-grid">
	<div class="p-col-12 p-p-0">

		<!-- Message -->
		<p-toast>
			<ng-template let-message pTemplate="message">
				<div class="p-grid p-ml-2">
					<div class="p-col-2">
						<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
					</div>
					<div class="p-col-10">
						<h4>{{message.summary}}</h4>
					</div>
					<div class="p-col-12">
						<p>{{message.detail}}</p>
					</div>
					<div *ngIf="message.severity!='success'" class="p-col-12">
						<a class="p-button"
							href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
							Fehler melden
						</a>
					</div>
				</div>
			</ng-template>
		</p-toast>

		<!-- Spinner -->
		<div *ngIf="loading!==0" class="spinner">
			<span class="ball-1"></span>
			<span class="ball-2"></span>
			<span class="ball-3"></span>
			<span class="ball-4"></span>
			<span class="ball-5"></span>
			<span class="ball-6"></span>
			<span class="ball-7"></span>
			<span class="ball-8"></span>
		</div>

		<div class="card p-mb-0">
			<!-- Content -->
			<div class="p-grid p-formgrid">
				<div class="p-col-12 p-md-12 p-lg-4 p-mb-3">
					<h6 class="p-my-2">Schlüssel</h6>
					<input type="text" pInputText placeholder="Schlüssel" [(ngModel)]="ankey" style="width:80%">
				</div>
				<div class="p-col-12 p-md-12 p-lg-4 p-mb-3">
					<h6 class="p-my-2">Tour</h6>
					<p-multiSelect [options]="tours" [(ngModel)]="selectedTours" optionLabel="name" appendTo="body"
						placeholder="-" [virtualScroll]="true" itemSize="30" [filter]="true" filterBy="name"
						[style]="{'width':'80%'}" multiSelectDirective>
					</p-multiSelect>
				</div>
				<div class="p-col-12 p-md-12 p-lg-4 p-mb-3">
					<h6 class="p-my-2">Fahrzeug</h6>
					<p-multiSelect [options]="vehicles" [(ngModel)]="selectedVehicles" optionLabel="name"
						appendTo="body" placeholder="-" [virtualScroll]="true" itemSize="30" [filter]="true"
						filterBy="name" [style]="{'width':'80%'}" multiSelectDirective>
					</p-multiSelect>
				</div>
				<div class="p-col-12 p-md-12 p-lg-4 p-mb-3">
					<h6 class="p-my-2">Abfallart</h6>
					<p-multiSelect [options]="fractions" [(ngModel)]="selectedFractions" optionLabel="name"
						appendTo="body" placeholder="-" [virtualScroll]="true" itemSize="30" [filter]="true"
						filterBy="name" [style]="{'width':'80%'}" multiSelectDirective>
					</p-multiSelect>
				</div>
				<div class="p-col-12 p-md-12 p-lg-4 p-mb-3">
					<h6 class="p-my-2">Typ</h6>
					<p-multiSelect [options]="types" [(ngModel)]="selectedTypes" optionLabel="name" appendTo="body"
						placeholder="-" [virtualScroll]="true" itemSize="30" [filter]="true" filterBy="name"
						[style]="{'width':'80%'}" multiSelectDirective>
					</p-multiSelect>
				</div>
				<div class="p-col-12 p-md-12 p-lg-4 p-mb-3">
					<h6 class="p-my-2">Entsorger</h6>
					<p-multiSelect [options]="companies" [(ngModel)]="selectedCompanies" optionLabel="name"
						appendTo="body" placeholder="-" [virtualScroll]="true" itemSize="30" [filter]="true"
						filterBy="name" [style]="{'width':'80%'}" multiSelectDirective>
					</p-multiSelect>
				</div>
				<div class="p-col-12 p-md-12 p-lg-4 p-mb-3">
					<h6 class="p-my-2">Fahrer</h6>
					<p-multiSelect [options]="staff" [(ngModel)]="selectedDriver" optionLabel="name" appendTo="body"
						placeholder="-" [virtualScroll]="true" itemSize="30" [filter]="true" filterBy="name"
						[style]="{'width':'80%'}" multiSelectDirective>
					</p-multiSelect>
				</div>
				<div class="p-col-12 p-md-12 p-lg-4 p-mb-3">
					<h6 class="p-my-2">Lader</h6>
					<p-multiSelect [options]="staff" [(ngModel)]="selectedLoader" optionLabel="name" appendTo="body"
						placeholder="-" [virtualScroll]="true" itemSize="30" [filter]="true" filterBy="name"
						[style]="{'width':'80%'}" multiSelectDirective>
					</p-multiSelect>
				</div>
				<div class="p-col-12 p-my-5">
					<div class="p-grid p-formgrid">
						<div class="p-col-12 p-md-12 p-lg-4 p-mb-3">
							<h6 class="p-my-2">Zeitspanne</h6>
							<p-dropdown [style]="{'width':'80%'}" [options]="timespans" [(ngModel)]="selectedTimespan"
								(onChange)="convertTimespanSelection($event)" [filter]="true" filterBy="label"
								placeholder="-" appendTo="body" dropdownDirective>
							</p-dropdown>
						</div>
						<div class="p-col-12 p-md-12 p-lg-4 p-mb-3">
							<h6 class="p-my-2">Von</h6>
							<p-calendar [showIcon]="true" dateFormat="dd.mm.yy" keepInvalid="true"
								(onInput)="inputDate('fromDate', $event.target.value)" [(ngModel)]="fromDate"
								appendTo="body" (onSelect)="selectedTimespan=-1;" [style]="{'width':'80%'}">
							</p-calendar>
						</div>
						<div class="p-col-12 p-md-12 p-lg-4 p-mb-3">
							<h6 class="p-my-2">Bis</h6>
							<p-calendar [showIcon]="true" dateFormat="dd.mm.yy" keepInvalid="true"
								(onInput)="inputDate('toDate', $event.target.value)" [(ngModel)]="toDate"
								appendTo="body" (onSelect)="selectedTimespan=-1;" [style]="{'width':'80%'}">
							</p-calendar>
						</div>
					</div>
				</div>
				<div class="p-col-12">
					<button pButton pRipple label="{{'BUTTONS.LOAD'|translate}}" class="p-button p-mt-5"
						style="width:100%" (click)="load()"></button>
				</div>
			</div>
		</div>
	</div>
</div>