import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals, VIONSessionStorage } from '@app/common/global_variables';
import PATH from '@assets/routes/routes.json';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '@services/account.service';
import { AppMainComponent } from './app.main.component';

@Component({
	selector: 'app-topbar',
	templateUrl: './app.topbar.component.html',
	styleUrls: ['./app.topbar.component.scss']
})
export class AppTopBarComponent implements OnInit {
	activeItem: number;
	role: string;
	username: string;

	constructor(
		private accountService: AccountService,
		public app: AppMainComponent,
		public globals: Globals,
		private router: Router,
		public translate: TranslateService
	) { }

	ngOnInit() {
		this.username = localStorage.getItem('username');
		this.role = localStorage.getItem('roles');
		this.app.userDatabaseName = localStorage.getItem('database');
	}

	changePassword(): void {
		this.router.navigate(['/' + PATH.CHANGE_PASSWORD], { queryParams: { returnUrl: this.router.routerState.snapshot.url } });
	}

	closeMobileProfile(event, mobileProfile) {
		if (this.app.activeTopbarItem === mobileProfile) {
			this.app.onTopbarMobileMenuButtonClick(event);
			this.app.onTopbarItemClick(event, mobileProfile);
		}
	}

	logout(): void {
		this.accountService.logout().then(res => {
			sessionStorage.clear();
			localStorage.clear();
			VIONSessionStorage.getInstance().clear();
			this.router.navigate(['/' + PATH.LOGIN], { queryParams: { returnUrl: this.router.routerState.snapshot.url, userID: this.globals.log_UserID } });
		});
	}
}
