import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { AccountService } from '@services/account.service';

@Injectable()
export class MapService {
	constructor(
		private accountService: AccountService,
		private http: HttpClient
	) {
	}

	/**
	 * Init Map Hub
	 * 
	 * @returns Antwort der API
	 */
	init(): Promise<any> {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/map/init`, options).toPromise());
		});
	}

	/**
	 * Anzahl Leerungen eines Fahrzeugs im Zeitraum abfragen
	 * 
	 * @param logboxId Logbox ID des Fahrzeugs
	 * @param fromDate Startpunkt des Suchzeitraums
	 * @param toDate Endpunkt des Suchzeitraums
	 * @returns Antwort der API
	 */
	getCollectionCount(logboxId: string, fromDate: Date, toDate: Date): Promise<any> {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/map/GetAnzahlLeerungen`, { p_strLogboxId: logboxId, p_dtFrom: fromDate, p_dtTo: toDate }, options).toPromise());
		});
	}

	/**
	 * Letzte GPS Pings der Fahrzeuge abfragen
	 * 
	 * @param logboxIds Liste von LogboxIds von der Fahrzeuge
	 * @returns Antwort der API
	 */
	getLastGPS(logboxIds: string[]): Promise<any[]> {
		return new Promise<any[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any[]>(`${environment.apiUrl}/map/GetLastGPS`, { p_strLogboxIds: logboxIds }, options).toPromise());
		});
	}

	/**
	 * Alte Tourdaten für Fahrzeug im Zeitraum abfragen
	 * 
	 * @param logboxId LogboxId des Fahrzeugs
	 * @param fromDate Startpunkt des Suchzeitraums
	 * @param toDate Endpunkt des Suchzeitraums
	 * @returns Antwort der API
	 */
	getOldGPS(logboxId: string, fromDate: Date, toDate: Date): Promise<any[]> {
		return new Promise<any[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any[]>(`${environment.apiUrl}/map/GetMaptourDataset`, { p_strLogboxId: logboxId, p_dtFrom: fromDate, p_dtTo: toDate }, options).toPromise());
		});
	}
}
