import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { tbl_fahrtbericht_abschnitt } from '@app/models/tbl_fahrtbericht_abschnitt';
import { colors } from '@app/models/tbl_fahrtbericht_abschnitt_typ';
import { DateService } from '@app/services/date.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
	templateUrl: './edit.component.html',
	styleUrls: ['../style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class TblFahrtberichtEditComponent implements OnInit {
	basefields: any[];
	currentYear: number;
	entry;
	fieldgroups: any[];
	id: number;
	loading = 0;
	url = '/' + PATH.FAHRTEBRICHT;
	sections: tbl_fahrtbericht_abschnitt[];
	locale = 'de';
	options: any;

	constructor(
		private breadcrumbService: BreadcrumbService,
		private crudService: CRUDService,
		private confirmationService: ConfirmationService,
		public datepipe: DatePipe,
		private messageService: MessageService,
		private router: Router,
		public translate: TranslateService,
	) {
		const href = this.router.url.split('/');
		this.id = +href[href.length - 1];
		this.locale = localStorage.getItem('lang') ? localStorage.getItem('lang') : this.locale;

		this.breadcrumbService.setItems([
			{ label: 'MENU.BERICHTE' },
			{ label: 'MENU.FAHRTBERICHTE', routerLink: [this.url + '/' + PATH.LIST] },
			{ label: 'BREADCRUMBS.EDIT', routerLink: [this.url + '/' + PATH.EDIT + '/' + this.id] }
		]);

		this.basefields = [
			{ type: 'string', key: 'ankey', label: 'Schlüssel', required: true },
			{ type: 'date', key: 'anfang_zeit', label: 'Anfang', required: true },
			{ type: 'date', key: 'ende_zeit', label: 'Ende', required: true },
			{ type: 'number', key: 'ende_anzahl_2rad', label: '2Rad', required: false },
			{ type: 'number', key: 'ende_anzahl_4rad', label: '4Rad', required: false },
		];

		const foreignFields = [
			{ type: 'dropdown', key: 'tour_id', label: 'Tour', required: false },
			{ type: 'dropdown', key: 'abfallart_id', label: 'Abfallart', required: false },
			{ type: 'dropdown', key: 'typ_id', label: 'Typ', required: false },
			{ type: 'dropdown', key: 'entsorger_id', label: 'Entsorger', required: false },
			{ type: 'dropdown', key: 'fahrzeug_id', label: 'Fahrzeug', required: false },
			{ type: 'dropdown', key: 'fahrer_id', label: 'Fahrer', required: false },
			{ type: 'dropdown', key: 'lader1_id', label: 'Lader1', required: false },
			{ type: 'dropdown', key: 'lader2_id', label: 'Lader2', required: false },
			{ type: 'dropdown', key: 'lader3_id', label: 'Lader3', required: false },
		];

		this.fieldgroups = [
			this.basefields,
			foreignFields
		];

		this.options = {
			tour_id: null,
			abfallart_id: null,
			typ_id: null,
			entsorger_id: null,
			fahrzeug_id: null,
			fahrer_id: null,
			lader1_id: null,
			lader2_id: null,
			lader3_id: null,
			abschnitt_typ_id: null,
			standort_id: null,
		}
	}

	ngOnInit(): void {
		this.currentYear = (new Date()).getFullYear();
		this.get();
	}

	addSection(index): void {
		this.sections.splice(index, 0, {});
	}

	get(): void {
		this.loading += 1;
		this.crudService.getTripReport(this.id).then(res => {
			this.entry = res;
			this.entry.anfang_zeit = new Date(this.entry.anfang_zeit);
			this.entry.ende_zeit = new Date(this.entry.ende_zeit);
			this.loading += 1;
			this.crudService.getFilteredTripReportSections(this.id).then(res => {
				this.sections = res;
				this.sections.forEach(s => {
					s.anfang_zeit = new Date(s.anfang_zeit);
					s.ende_zeit = new Date(s.ende_zeit);
				});
				this.loadOptions();
			}).catch(err => {
				err.error.forEach(e => {
					if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
					}
				})
			}).finally(() => {
				this.loading -= 1;
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	stringIsNullOrWhitespace(value): boolean {
		if (value !== null && value !== undefined) {
			let val = value + '';
			if (val.trim().length > 0) {
				return false;
			}
		}
		return true;
	}

	checkRequiredFields(): boolean {
		let valid = true;
		this.basefields.forEach(field => {
			if (field.required && this.stringIsNullOrWhitespace(this.entry[field.key])) {
				valid = false;
				this.messageService.add({ severity: 'error', summary: this.translate.instant('MESSAGES.ERROR'), detail: this.translate.instant('MESSAGES.REQUIRED_FIELD', { field: this.translate.instant('HEADERS.' + field.key) }), life: 3000 });
			}
		});
		return valid;
	}

	deleteSection(section): void {
		this.sections.splice(this.sections.indexOf(section), 1);
	}

	inputDate(dateField, input: string): void {
		let date = DateService.inputDateExStatic(input);
		if (date) {
			if (this.entry[dateField]) {
				date.setHours(this.entry[dateField].getHours(), this.entry[dateField].getMinutes(), this.entry[dateField].getSeconds(), this.entry[dateField].getMilliseconds());
			} else {
				date.setHours(0, 0, 0, 0);
			}
			this.entry[dateField] = date;
		}
	}

	inputSectionDate(sectionIndex, dateField, input: string): void {
		const today = new Date();
		let date;

		if (input.length == 1) {
			if (input == 'h') {
				date = today;
			} else if (input == 'g') {
				date = new Date(today.setDate(today.getDate() - 1));
			} else if (input == 'm') {
				date = new Date(today.setDate(today.getDate() + 1));
			}
			if (date && this.sections[sectionIndex][dateField]) {
				date.setHours(this.sections[sectionIndex][dateField].getHours(), this.sections[sectionIndex][dateField].getMinutes(), this.sections[sectionIndex][dateField].getSeconds(), this.sections[sectionIndex][dateField].getMilliseconds());
			} else {
				date.setHours(0, 0, 0, 0);
			}
		} else if (input.length == 8 && (/^\d+$/.test(input))) {
			date = new Date(Number(input.substring(4, 8)), Number(input.substring(2, 4)) - 1, Number(input.substring(0, 2)), 0, 0, 0, 0);
		}

		if (date) {
			this.sections[sectionIndex][dateField] = date;
		}
	}

	loadOptions(): void {
		this.loading += 1;
		this.crudService.getAllTripReportTypes().then(res => {
			this.options.typ_id = [];
			res.forEach(c => {
				this.options.typ_id.push({ label: c.bezeichnung, value: c.ds_this_id });
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getAllTours().then(res => {
			this.options.tour_id = [];
			res.forEach(c => {
				this.options.tour_id.push({ label: c.bezeichnung, value: c.ds_this_id });
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getAllFractions().then(res => {
			this.options.abfallart_id = [];
			res.forEach(c => {
				this.options.abfallart_id.push({ label: c.bezeichnung, value: c.ds_this_id });
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getAllCompanies().then(res => {
			this.options.entsorger_id = [];
			res.forEach(c => {
				this.options.entsorger_id.push({ label: c.bezeichnung, value: c.ds_this_id });
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getAllVehicles().then(res => {
			this.options.fahrzeug_id = [];
			res.forEach(c => {
				this.options.fahrzeug_id.push({ label: c.bezeichnung, value: c.ds_this_id });
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getStaff().then(res => {
			this.options.fahrer_id = [];
			this.options.lader1_id = [];
			this.options.lader2_id = [];
			this.options.lader3_id = [];
			res.forEach(c => {
				this.options.fahrer_id.push({ label: c.bezeichnung, value: c.ds_this_id });
				this.options.lader1_id.push({ label: c.bezeichnung, value: c.ds_this_id });
				this.options.lader2_id.push({ label: c.bezeichnung, value: c.ds_this_id });
				this.options.lader3_id.push({ label: c.bezeichnung, value: c.ds_this_id });
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getAllTripReportSectionTypes().then(res => {
			this.options.abschnitt_typ_id = [];
			res.forEach(c => {
				this.options.abschnitt_typ_id.push({ label: c.bezeichnung, value: c.ds_this_id });
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getAllPOI().then(res => {
			this.options.standort_id = [];
			res.forEach(c => {
				this.options.standort_id.push({ label: c.bezeichnung, value: c.ds_this_id });
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	save(): void {
		if (this.checkRequiredFields()) {
			this.loading += 1;
			this.crudService.editTripReport(this.entry, this.sections).then(res => {
				this.router.navigate([this.url + '/' + PATH.DETAIL + '/' + this.id]);
				this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
			}).catch(err => {
				err.error.forEach(e => {
					if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
					}
				})
			}).finally(() => {
				this.loading -= 1;
			});
		}
	}

	isDarkMode(): boolean {
		return (localStorage.getItem('darkMode') === 'true');
	}

	getBackgroundColor(typ_id): string {
		return ('rgba(' + colors[typ_id] + ',0.5)');
	}
}
