import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { User } from '@models/admin';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from '@services/admin.service';
import { MessageService } from 'primeng/api';


import { CRUDService } from '@app/services/crud.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
	templateUrl: './create.component.html',
	styleUrls: ['../style.scss'],
	providers: [MessageService]
})
export class AdminVisyUsersCreateComponent implements OnInit {
	adminPermission: boolean = false;
	displayDialog = false;
	entry: User;
	fieldgroups: any[] = [];
	id: string;
	limitedVehicles = [];
	loading: number = 0;
	name: string = '';
	newPassword: string;
	options: any = {};
	permissions: any[];
	state: any = {};
	stateName: string = '';
	url: string = '';

	constructor(
		private adminService: AdminService,
		private breadcrumbService: BreadcrumbService,
		private crudService: CRUDService,
		private jwtHelper: JwtHelperService,
		private messageService: MessageService,
		private router: Router,
		public translate: TranslateService,
	) {
		this.name = 'MENU.NUTZER';
		this.url = '/' + PATH.VISY_USERS;
		this.stateName = 'state' + 'AdminVisyUsers' + 'Create';

		this.breadcrumbService.setItems([
			{ label: 'MENU.ADMIN' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.CREATE', routerLink: [this.url + '/' + PATH.CREATE] }
		]);
	}

	// #region Angular

	ngOnInit(): void {
		this.loading += 1;

		this.fieldgroups = [
			[
				{ type: 'text', key: 'Email', required: true, width: 400 },
				{ type: 'password', key: 'Password', required: true, width: 400 },
				{ type: 'object', key: 'VehicleRolesList', required: true, width: 400, defaultOptionSortField: 'RoleName' },
			], [
				{ type: 'object', key: 'UserConnectionSettings', required: false, width: 400, defaultOptionSortField: 'SettingsName' },
				{ type: 'boolean', key: 'MasterUser', required: false, width: 400 },
				{ type: 'boolean', key: 'ServiceUser', required: false, width: 400 },
			]
		];

		this.state = JSON.parse(localStorage.getItem(this.stateName));
		this.setHorizontalGroups(((this.state && this.state.horizontalGroups !== null) ? this.state.horizontalGroups : true));

		const role = this.jwtHelper.decodeToken(localStorage.getItem('jwt'))['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
		if (role === 'ViSyAdministrator') {
			this.adminPermission = true;

			this.entry = {
				Email: null,
				Password: null,
				VehicleRolesList: null,
				UserConnectionSettings: null
			};
			this.entry['MasterUser'] = false;
			this.entry['ServiceUser'] = false;

			this.getOptions();
		}

		this.loading -= 1;
	}

	// #endregion Angular

	// #region CRUD

	createEntry(): void {
		if (this.checkRequiredFields()) {
			this.loading += 1;
			this.adminService.createUser(this.entry).then(res => {
				this.router.navigate([PATH.VISY_USERS + '/' + PATH.DETAIL + '/' + res.Id]);
				this.messageService.add({
					severity: 'success',
					summary: this.translate.instant('MESSAGES.SUCCESSFUL'),
					detail: this.translate.instant('MESSAGES.SAVED'),
					life: 3000
				});
			}).catch(err => {
				err.error.forEach(e => {
					this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
				})
			}).finally(() => {
				this.loading -= 1;
			});
		}
	}

	// #endregion CRUD

	// #region Spezial

	stringIsNullOrWhitespace(value): boolean {
		if (value !== null && value !== undefined) {
			let val = value + '';
			if (val.trim().length > 0) {
				return false;
			}
		}
		return true;
	}

	/**
	 * Schaltet die Sortierung eines Dropdowns oder Multiselects um
	 * 
	 * @param field Maskenfeld, für das die Sortierung geändert werden soll
	 */
	changeSorting(field: any, init?: boolean) {
		if (init) {
			this.state = JSON.parse(localStorage.getItem(this.stateName));
			if (this.state && this.state.optionSortFields) {
				field.optionSortField = this.state.optionSortFields[field.key] ? this.state.optionSortFields[field.key].optionSortField : field.defaultOptionSortField;
				field.sortDescending = this.state.optionSortFields[field.key] ? this.state.optionSortFields[field.key].sortDescending : false;
			} else {
				this.state = this.state ? this.state : {};
				this.state.optionSortFields = {};
				field.optionSortField = field.defaultOptionSortField;
				field.sortDescending = false;
			}
		}

		if (!field.sortDescending) {
			this.options[field.key].sort((a, b) => (b.object[field.optionSortField] < a.object[field.optionSortField]) ? 1 : -1);
		} else {
			this.options[field.key].sort((a, b) => (b.object[field.optionSortField] > a.object[field.optionSortField]) ? 1 : -1);
		}
		this.state.optionSortFields[field.key] = { optionSortField: field.optionSortField, sortDescending: field.sortDescending };
		localStorage.setItem(this.stateName, JSON.stringify(this.state));
		this.options[field.key] = this.options[field.key].map(option => ({ label: (field.optionSortField == field.defaultOptionSortField ? option.object[field.defaultOptionSortField] : option.object[field.defaultOptionSortField] + ' (' + option.object[field.optionSortField] + ')'), value: option.value, object: option.object }));
		this.options[field.key] = [...this.options[field.key]];
	}

	checkRequiredFields(): boolean {
		let valid = true;
		this.fieldgroups.forEach(fg => {
			fg.forEach(field => {
				if (field.required && this.stringIsNullOrWhitespace(this.entry[field.key])) {
					valid = false;
					this.messageService.add({ severity: 'error', summary: this.translate.instant('MESSAGES.ERROR'), detail: this.translate.instant('MESSAGES.REQUIRED_FIELD', { field: this.translate.instant(field.key) }), life: 3000 });
				}
			});
		});
		return valid;
	}

	/**
	 * Ordnet das Sortierfeld im Multiselect über dem Filterfeld an
	 */
	formatMultiselectLayout() {
		const msHeaderElements = document.getElementsByClassName('p-multiselect-header');
		Array.from(msHeaderElements).forEach(msHeaderElement => {
			(msHeaderElement as HTMLElement).style.flexWrap = 'wrap';
		});
	}

	setHorizontalGroups(value: boolean): void {
		this.state = JSON.parse(localStorage.getItem(this.stateName));
		if (!this.state) {
			this.state = {};
		}
		this.state.horizontalGroups = value;
		localStorage.setItem(this.stateName, JSON.stringify(this.state));
	}

	getOptions(): void {
		this.loading += 1;
		this.adminService.getAllConnectionSettings().then(res => {
			let field = {};
			this.fieldgroups.forEach(fg => {
				const temp = fg.find(f => f.key == 'UserConnectionSettings');
				field = temp ? temp : field;
			});
			this.options.UserConnectionSettings = res.map(option => ({ label: option.SettingsName, value: option, object: option }));
			field['optionSortFieldOptions'] = Object.keys(res[0]).map(key => ({ label: this.translate.instant('HEADERS.' + key), value: key }));
			field['optionSortFieldOptions'] = field['optionSortFieldOptions'].filter(option => !option.label.includes('HEADERS.'));
			this.changeSorting(field, true);
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.adminService.getAllRoles().then(res => {
			let field = {};
			this.fieldgroups.forEach(fg => {
				const temp = fg.find(f => f.key == 'VehicleRolesList');
				field = temp ? temp : field;
			});
			this.options.VehicleRolesList = res.map(option => ({ label: option.RoleName, value: option, object: option }));
			field['optionSortFieldOptions'] = Object.keys(res[0]).map(key => ({ label: this.translate.instant('HEADERS.' + key), value: key }));
			field['optionSortFieldOptions'] = field['optionSortFieldOptions'].filter(option => !option.label.includes('HEADERS.'));
			this.changeSorting(field, true);
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	// #endregion Spezial
}