<div class="layout-sidebar" [ngClass]="{'layout-sidebar-active': app.sidebarActive}"
	(click)="app.onSidebarClick($event)" (mouseover)="app.sidebarActive=true" (mouseleave)="app.sidebarActive=false">
	<div class="sidebar-logo">
		<a href="#" class="sidebar-pin" title="Toggle Menu" (click)="app.onToggleMenuClick($event)">
			<i class="pi pi-lock" *ngIf="app.staticMenuActive"></i>
			<i class="pi pi-lock-open" *ngIf="!app.staticMenuActive"></i>
		</a>
		<a routerLink="/">
			<img alt="logo" class="logo" src="assets/images/visy/favicon.svg" />
			<span class="app-name">ViSy Online</span>
		</a>
	</div>

	<div class="layout-menu-container" (click)="onMenuClick()">
		<ul class="layout-menu">
			<ng-template ngFor let-item let-i="index" [ngForOf]="model">
				<li *ngIf="authorized(item) && !isEmptyParent(item)" app-menuitem [item]="item" [index]="i"
					[root]="true"></li>
			</ng-template>
		</ul>
	</div>
</div>