import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { VIONSessionStorage } from '@app/common/global_variables';
import { DetailComponent } from '@app/common/templates/detail/detail.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
	templateUrl: '../../../../common/templates/detail/detail.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/detail/style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class TblKundeDetailComponent extends DetailComponent {
	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public messageService: MessageService,
		public router: Router,
		public translate: TranslateService,
	) {
		super(accountService, breadcrumbService, confirmationService, crudService, messageService, router, translate);

		this.apiUrl = 'TblKunde';
		this.name = 'MENU.KUNDE';
		this.url = '/' + PATH.KUNDE;
		this.fieldgroups.push(
			[
				{ type: 'text', key: 'ankey', required: true, width: 400 },
				{ type: 'text', key: 'bezeichnung', required: false, width: 400 },
				{ type: 'text', key: 'bezeichnung_kurz', required: false, width: 400 },
				{ type: 'text', key: 'vorname1', required: true, width: 400 },
				{ type: 'text', key: 'nachname', required: true, width: 400 },
				{ type: 'object', key: 'FREMD_anrede_bezeichnung', required: false, width: 400, url: '/' + PATH.ANREDE, id: 'anrede_id' },
			],
			[
				{ type: 'text', key: 'strasse', required: false, width: 400 },
				{ type: 'numeric', key: 'hausnummer1', required: false, width: 400 },
				{ type: 'text', key: 'hauszusatz1', required: false, width: 400 },
				{ type: 'numeric', key: 'hausnummer2', required: false, width: 400 },
				{ type: 'text', key: 'hauszusatz2', required: false, width: 400 },
				{ type: 'text', key: 'plz', required: false, width: 400 },
				{ type: 'text', key: 'ort', required: false, width: 400 },
			]
		);
		this.breadcrumbService.setItems([
			{ label: 'MENU.BHV' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.DETAIL', routerLink: [this.url + '/' + PATH.DETAIL + '/' + this.id] }
		]);
	}

	// override, weil vor Rückkehr zur gepufferten liste muss jetzt der gelöschte Eintrag aus dem Puffer gelöscht werden
	deleteEntry() {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.DELETE_QUESTION'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.loading += 1;
				this.crudService.deleteEntry(this.apiUrl, this.entry.ds_this_id).then(res => {
					this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });

					// hier eintrag aus gepufferter Liste löschen
					let storage = VIONSessionStorage.getInstance().get(this.apiUrl);
					if (null != storage && undefined != storage) {
						const storedTimestamp = new Date(storage.timestamp);
						if (null != storage.entries && undefined != storage.entries) {
							let index = 0;
							let foundElement = false;
							for (let i = 0; i < storage.entries.length; ++i) {
								if (storage.entries[i]['ds_this_id'] === this.entry.ds_this_id) {
									foundElement = true;
									break;
								}
								index += 1;
							}
							if (foundElement && index < storage.entries.length) {
								storage.entries.splice(index, 1);
								VIONSessionStorage.getInstance().set(this.apiUrl, storedTimestamp, storage.filters, storage.entries);
							}
						}
					}

					// und neu öffnen
					this.router.navigate([this.url + '/list/']);
				}).catch(err => {
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				}).finally(() => {
					this.loading -= 1;
				});
			}
		});
	}
}
