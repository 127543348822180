import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Globals, VIONSessionStorage } from '@app/common/global_variables';
import { Base } from '@app/models/base';
import { Module, Operation } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { ExportService } from '@app/services/export.service';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { MultiSelect } from 'primeng/multiselect';
import { Table } from 'primeng/table';

@Component({
	selector: 'listTemplate',
	templateUrl: './listEx.component.html',
	styleUrls: ['./style.scss'],
	providers: [MessageService, ConfirmationService]
})

export class ListExComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('table') table: Table;
	@ViewChild('colselection') colselection: MultiSelect;
	@ViewChild('emptyCanvas', { static: false }) emptyCanvas: ElementRef;
	apiUrl: string = ''; // Url des API-Controllers nach 'https://localhost:44311/'
	buttonColWidth: number = 150; // Breite der Buttonspalte in Pixeln
	cols: any[] = []; // Tabellenspalten
	contentHeight: number = 4320; // Höhe der Tabelle
	contextMenu: MenuItem[]; // Kontextmenu der Tabelle
	count: number = 0; // Anzahl der Einträge in der Tabelle
	createPermission: boolean = false; // Darf der Nutzer neue Stammdateneinträge anlegen
	deletePermission: boolean = false; // Darf der Nutzer Stammdateneinträge löschen
	entries: Base[]; // Geladene Datensätze
	filters: string[]; // Spaltenkeys nach denen gefiltert werden kann
	globalFilter: string = ''; // Filterstring des globalen Tabellenfilters
	hasFilterDialog: boolean = false; // Hat die Stammdatentabelle einen Ladefilter
	isMobile: boolean = false; // Ist das Portal auf einem mobilen Browser 
	isTableInit: boolean = false; // Wurde die Tabelle schon initialisiert
	loadFilters: any; // Ladefilter die im Ladefilterpopup ausgewählt wurden
	loading: number = 0; // Ladeindikator
	loadTimestamp: Date; // Zeitpunkt zu den die eintries geladen wurden
	module: Module; // Modul der Seite zur Rechteüberprüfung
	name: string = ''; // Name des Stammdatentyps
	persistenceCheckInterval: any; // Intervall in dem die Persistenz der Daten geprüft wird
	possibleCols: any[] = []; // Mögliche Tabellenspalten
	readPermission: boolean = false; // Darf der Nutzer Stammdateneinträge ansehen
	selectedEntry: Base; // Angeklickter Tabelleneintrag
	state: any; // localstorage State der Seite
	stateName: string = ''; // key des States der Seite im localstorage
	updatePermission: boolean = false; // Darf der Nutzer Stammdateneinträge bearbeiten
	url: string = ''; // Url des Stammdatentyps nach dem # 
	url_detail: string = PATH.DETAIL; // Url Zusatz für die Detail-Seite nach der url
	url_edit: string = PATH.EDIT; // Url Zusatz für die Edit-Seite nach der url
	reloading = false; // Soll die Tabelle neu laden
	autosizeColumns: boolean = false; // Tabellenspaltenbreiten automatisch berechnen
	userAdjustedColumnSize: boolean = false; // Tabellenspaltenbreiten vom Nutzer gesetzt

	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public changeDetectorRef: ChangeDetectorRef,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public elRef: ElementRef,
		public exportService: ExportService,
		public globals: Globals,
		public messageService: MessageService,
		public router: Router,
		public settingsService: SettingsService,
		public translate: TranslateService,
	) {
	}

	// #region Angular

	ngOnInit(): void {
		this.loading += 1;

		// Nutzerberechtigungen abfragen
		this.createPermission = this.accountService.checkPermissions(this.module, Operation.CREATE);
		this.readPermission = this.accountService.checkPermissions(this.module, Operation.READ);
		this.updatePermission = this.accountService.checkPermissions(this.module, Operation.UPDATE);
		this.deletePermission = this.accountService.checkPermissions(this.module, Operation.DELETE);

		// Key des States auf apiUrl generieren
		this.stateName = 'state' + this.apiUrl + 'List';

		if (this.readPermission) {
			if (this.hasFilterDialog) {
				const storage = this.storageGetEntries();
				if (storage && storage.timestamp && storage.entries && storage.filters) {
					this.loading += 1;
					const storedTimestamp = new Date(storage.timestamp);
					this.loadTimestamp = storedTimestamp;
					this.loadFilters = storage.filters;
					this.processEntries(storage.entries);
					this.crudService.checkPersistence(this.apiUrl, storedTimestamp).then(isPersistent => {
						if (!isPersistent) {
							this.messageService.add({ key: 'refresh', severity: 'warn', summary: this.translate.instant('BUTTONS.REFRESH'), detail: this.translate.instant('MESSAGES.NEW_DATA_AVAILABLE') });
						}
					}).catch(err => {
						err.error.forEach(e => {
							this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
						})
					}).finally(() => {
						this.loading -= 1;
					});
				} else {
					if ((localStorage.getItem('defaultOpenVehicleDialog') === 'true')) {
						this.openFilterDialog();
					}
				}
			} else {
				this.getAllEntries();
			}
		}

		this.retrieveTableState(this.state);
		if (this.state && this.state.columnOrder) {
			this.cols = [];
			this.state.columnOrder.forEach(col => {
				this.possibleCols.forEach(c => {
					if (col == c.key) {
						this.cols.push(c);
					}
				});
			});
		}
		if (this.state && this.state.filters && this.state.filters.global) {
			this.globalFilter = this.state.filters.global.value;
		}

		this.isMobile = JSON.parse(this.globals.log_Platform).Mobile == 'yes' || JSON.parse(this.globals.log_Platform).Tablet == 'yes';

		this.initContextMenu();

		this.filters = this.cols.map(c => c.key);

		this.customInit();

		this.loading -= 1;
	}

	initContextMenu(): void {
		this.translate.get('init').subscribe((text: string) => {
			this.contextMenu = [
				{ label: this.translate.instant('CONTEXT_MENU.CREATE'), icon: 'pi pi-fw pi-plus', command: () => this.create() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN'), icon: 'pi pi-fw pi-search', command: () => this.detail() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_TAB'), icon: 'pi pi-fw pi-search', command: () => this.detail('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_WINDOW'), icon: 'pi pi-fw pi-search', command: () => this.detail('window') },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT'), icon: 'pi pi-fw pi-pencil', command: () => this.edit() },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT_TAB'), icon: 'pi pi-fw pi-pencil', command: () => this.edit('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT_WINDOW'), icon: 'pi pi-fw pi-pencil', command: () => this.edit('window') },
				{ label: this.translate.instant('CONTEXT_MENU.DELETE'), icon: 'pi pi-fw pi-trash', command: () => this.delete() },
				{ label: this.translate.instant('CONTEXT_MENU.RESIZE'), icon: 'pi pi-fw pi-arrows-h', command: () => this.resizeTableWidthFromContent(true) }
			];
			this.possibleCols.forEach(c => {
				if(c.sub)
					c.label = this.translate.instant('HEADERS.' + c.sub + '.' + c.key);
				else c.label = this.translate.instant('HEADERS.' + c.key);
			});
		});
	}

	ngAfterViewInit(): void {
		this.settingsService.footerVisibilityChange.subscribe(value => {
			this.initTable();
		});

		if (this.table && this.table.filters) {
			let restoredFilter = false;
			this.filters.forEach(col => {
				Object.keys(this.table.filters[col]).forEach(filter => {
					if (this.table.filters[col][filter]['value'] != null) {
						restoredFilter = true;
					}
				})
			});
			if (restoredFilter) {
				this.messageService.add({ key: 'reset', severity: 'warn', summary: this.translate.instant('MESSAGES.WARNING'), detail: this.translate.instant('MESSAGES.LOADED_FILTER'), life: 10000 });
			}
		}

		this.initTable();


		this.customAfterViewInit();
	}

	ngAfterViewChecked(): void {
		if (!this.isTableInit && this.table && this.table.value) {
			this.isTableInit = true;
			this.resizeTableWidthFromContent(false);

			const el = document.querySelector<HTMLElement>('.cdk-virtual-scroll-viewport');
			if (el != null)
				this.changeWheelSpeed(el, 0.9);

			/* Schnellere UI aber teils unschöne Seiteffekte
			this.changeDetectorRef.detach();
			setInterval(() => {
				this.changeDetectorRef.detectChanges();
			}, 1000);
			*/

			this.changeDetectorRef.detectChanges();
		}

		this.customAfterViewChecked();
	}

	ngOnDestroy(): void {
		clearInterval(this.persistenceCheckInterval);
	}

	@HostListener('window:resize', ['$event'])
	onResize(event): void {
		this.initTable();
	}

	customInit(): void {

	}

	customAfterViewInit(): void {

	}

	customAfterViewChecked(): void {

	}

	// #endregion Angular

	// #region CRUD

	/**
	 * Öffnet die Erstellen-Seite für diesen Stammdatentyp
	 */
	create(): void {
		this.router.navigate([this.url + '/' + PATH.CREATE]);
	}

	/**
	 * Öffnet die Detail-Seite für den ausgewählten Eintrag abhängig von target in einem neuen Fenster, in einem neuen Tab oder im selben Tab
	 * 
	 * @param target 'window' für öffnen in neuen Fenster, 'tab' für öffnen in neuem Tab, null oder alles andere für öffnen im selben Tab
	 */
	detail(target?: string): void {
		if (target == 'window') {
			window.open('/#/' + this.url + '/' + PATH.DETAIL + '/' + this.selectedEntry.ds_this_id, '_blank', 'newWindow=1');
		} else if (target == 'tab') {
			window.open('/#/' + this.url + '/' + PATH.DETAIL + '/' + this.selectedEntry.ds_this_id);
		} else {
			this.router.navigate([this.url + '/' + PATH.DETAIL + '/' + this.selectedEntry.ds_this_id]);
		}
	}

	/**
	 * Öffnet die Bearbeiten-Seite für den ausgewählten Eintrag abhängig von target in einem neuen Fenster, in einem neuen Tab oder im selben Tab
	 * 
	 * @param target 'window' für öffnen in neuen Fenster, 'tab' für öffnen in neuem Tab, null oder alles andere für öffnen im selben Tab
	 */
	edit(target?: string): void {
		if (target == 'window') {
			window.open('/#/' + this.url + '/' + PATH.EDIT + '/' + this.selectedEntry.ds_this_id, '_blank', 'newWindow=1');
		} else if (target == 'tab') {
			window.open('/#/' + this.url + '/' + PATH.EDIT + '/' + this.selectedEntry.ds_this_id);
		} else {
			this.router.navigate([this.url + '/' + PATH.EDIT + '/' + this.selectedEntry.ds_this_id]);
		}
	}

	getFilteredEntries(filters: any): void {
		// dummy, um duplikation von funktionen zu vermeiden
	}

	/**
	 * Löscht den Eintrag mit der angegebenen ds_this_id aus der Datenbank 
	 * 
	 * @param ds_this_id Id des zu löschenden Eintrags
	 */
	delete(ds_this_id?: number): void {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.DELETE_QUESTION'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.loading += 1;
				this.crudService.deleteEntry(this.apiUrl, ds_this_id ? ds_this_id : this.selectedEntry.ds_this_id).then(res => {
					this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });
					if (this.hasFilterDialog)
						this.getFilteredEntries(this.loadFilters);
					else
						this.getAllEntries();
				}).catch(err => {
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				}).finally(() => {
					this.loading -= 1;
				});
			}
		});
	}

	// #endregion CRUD

	// #region Table

	/**
	 * 
	 * @param container Hilfsmethode die das Flackern der Listenkopfzeile verhindert
	 * @param speedY 
	 * @returns 
	 */
	changeWheelSpeed(container, speedY) {
		var scrollY = 0;
		var handleScrollReset = function () {
			scrollY = container.scrollTop;
		};

		var handleMouseWheel = function (e) {
			e.preventDefault();
			scrollY += speedY * e.deltaY
			if (scrollY < 0) {
				scrollY = 0;
			} else {
				var limitY = container.scrollHeight - container.clientHeight;
				if (scrollY > limitY) {
					scrollY = limitY;
				}
			}
			container.scrollTop = scrollY;
		};

		var removed = false;
		container.addEventListener('mouseup', handleScrollReset, false);
		container.addEventListener('mousedown', handleScrollReset, false);
		container.addEventListener('mousewheel', handleMouseWheel, false);

		return function () {
			if (removed) {
				return;
			}
			container.removeEventListener('mouseup', handleScrollReset, false);
			container.removeEventListener('mousedown', handleScrollReset, false);
			container.removeEventListener('mousewheel', handleMouseWheel, false);
			removed = true;
		};
	}

	/**
	 * Exportiert die aktuelle Tabelle in eine CSV-Datei
	 */
	exportCSV(): void {
		this.exportService.exportCSV(this.translate.instant(this.name), (this.table && this.table.filteredValue ? this.table.filteredValue : this.table.value), this.cols);
	}

	/**
	 * Exportiert die aktuelle Tabelle in eine PDF-Datei
	 */
	exportPDF(): void {
		this.exportService.exportPDF(this.translate.instant(this.name), (this.table && this.table.filteredValue ? this.table.filteredValue : this.table.value), this.cols);
	}

	/**
	 * Exportiert die aktuelle Tabelle in eine XLSX-Datei
	 */
	exportXLSX(): void {
		this.exportService.exportXLSX(this.translate.instant(this.name), (this.table && this.table.filteredValue ? this.table.filteredValue : this.table.value), this.cols);
	}

	/**
	 * Läd alle gültigen Einträge für den aktuellen Stammdatentyp aus der Datenbank
	 */
	getAllEntries(): void {
		this.loading += 1;
		this.messageService.clear('refresh');
		this.crudService.getAllEntries(this.apiUrl).then(res => {
			this.processEntries(res);
			this.resizeTableWidthFromContent(false);
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	/**
	 * Passt die Tabellenhöhe und -breite an den verfügbaren PLatz an, um den Bildschrim auszufüllen
	 */
	initTable(): void {
		setTimeout(() => {
			this.contentHeight = 400;
		}, 0);
		setTimeout(() => {
			this.contentHeight = this.elRef.nativeElement.parentElement.offsetHeight - 200 + ((localStorage.getItem('showFooter') === 'true') ? 5 : 0)
			if (this.contentHeight < 400) {
				this.contentHeight = 400;
			}
		}, 0);
		this.resizeTableWidthFromContent(false);
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Überprüft, ob für die gegebene Spalte ein Filter auf die Tabelle angewendet wurde
	 * 
	 * @param col Tabellenspalte, die überprüft werden soll
	 * @returns boolean, ob für die gegebene Spalte ein Filter auf die Tabelle angewendet wurde
	 */
	isColFiltered(col: any): boolean {
		let isFiltered = false;
		if (this.table && this.table.filters[col.key]) {
			Object.keys(this.table.filters[col.key]).forEach(filter => {
				if (this.table.filters[col.key][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		else if (this.state && this.state.filters[col.key]) {
			Object.keys(this.state.filters[col.key]).forEach(filter => {
				if (this.state.filters[col.key][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		return isFiltered;
	}

	/**
	 * Passt die Anzeige der Tabelleneintragsanzahl an, wenn sich etwas an den Tabellenfilter geändert hat
	 * 
	 * @param event Filter-Event der Tabelle
	 */
	onFilter(event: any): void {
		this.count = (this.table && this.table.filteredValue) ? this.table.filteredValue.length : this.entries.length;
	}

	/**
	 * Aktuelaisiert den TableState, wenn die Tabellenspalten umsortiert wurden
	 * 
	 * @param event Reorder-Event der Tabelle
	 */
	onColReorder(event: any): void {
		this.retrieveTableState(this.state);
		const columnWidths = this.state.columnWidths.split(',');
		columnWidths.splice(event.dropIndex, 0, columnWidths.splice(event.dragIndex, 1)[0]);
		this.state.columnWidths = columnWidths.join(',');
		localStorage.setItem(this.stateName, JSON.stringify(this.state));
	}

	/**
	 * Aktuelaisiert die Tabelle und den TableState, wenn die Tabellenspaltenbreiten angepasst wurden
	 * 
	 * @param event Resize-Event der Tabelle
	 */
	onColResize(event: any): void {
		const index = Array.from(event.element.parentNode.children).indexOf(event.element);
		this.userAdjustedColumnSize = true;
		this.retrieveTableState(this.state);
		this.cols[index].width = Number(event.element.style.width.split('px')[0]);
		this.state.columnWidths = (this.cols.map(c => c.width)).concat([this.buttonColWidth]).join(',');
		localStorage.setItem(this.stateName, JSON.stringify(this.state));
		this.resizeTableWidth(this.state);
	}

	/**
	 * Öffnet den Ladefilterdialog
	 */
	openFilterDialog(): void {
		// In Child Klasse überschreiben
	}

	/**
	 * Aus der Datenbank geladene Einträge für die Verwendung vorbereiten
	 * 
	 * @param entries Aus der Datenbank geladenen Einträge
	 */
	processEntries(entries: any): void {
		this.loading += 1;
		this.loadTimestamp = new Date();
		if (!this.persistenceCheckInterval) {
			this.persistenceCheckInterval = setInterval(() => {
				this.crudService.checkPersistence(this.apiUrl, this.loadTimestamp).then(isPersistent => {
					if (!isPersistent) {
						this.messageService.add({ key: 'refresh', severity: 'warn', summary: this.translate.instant('BUTTONS.REFRESH'), detail: this.translate.instant('MESSAGES.NEW_DATA_AVAILABLE') });
					}
				}).catch(err => {
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				});
			}, 1000 * 60 * 10);
		}
		this.possibleCols.forEach(c => {
			if (c.type == 'date') {
				entries.forEach(e => {
					if (e[c.key] != null) {
						e[c.key] = new Date(e[c.key]);
					}
				});
			}
		});
		this.entries = entries;
		this.count = this.entries.length;
		this.storageSetEntries();
		this.loading -= 1;
	}

	/**
	 * Listeneinträge neu aus der Datenbank holen
	 */
	refresh(): void {
		if (this.hasFilterDialog)
			this.getFilteredEntries(this.loadFilters);
		else
			this.getAllEntries();
	}

	/**
	 * Tabelle neu laden
	 */
	reloadTable(): void {
		this.reloading = true;
		setTimeout(() => {
			this.reloading = false;
		}, 0);
	}

	/**
	 * TableState zurücksetzen und Seite neu laden
	 */
	resetTable(): void {
		this.table.clearState();
		window.location.reload();
	}

	/**
	 * Tabellenbreite an Fenster anpassen
	 * 
	 * @param state TableState der Tabelle
	 */
	resizeTableWidth(state?): void {
		this.loading += 1;

		this.retrieveTableState(this.state);
		if (this.table) {
			const tableElement = document.getElementById(this.table.id);
			tableElement.style.width = '100%';
			const columnWidths = this.state ? this.state.columnWidths.split(',') : (this.cols.map(c => c.width)).concat([this.buttonColWidth]);
			const contentWidth = columnWidths.reduce((summe, element) => summe + Number(element), 0);
			const tableWidthOffset = tableElement.clientWidth - contentWidth;
			for (let index = 0; index < this.cols.length; index++) {
				this.cols[index].width = Number(columnWidths[index]);
			}
			if (tableWidthOffset > 0 && this.cols.length > 0) {
				this.cols[this.cols.length - 1].width += tableWidthOffset;
				if (this.contentHeight < (this.table.filteredValue ? this.table.filteredValue.length : (this.table.value ? this.table.value.length : 0)) * this.table.virtualRowHeight) {
					this.cols[this.cols.length - 1].width -= 10;
				}
			}

			document.getElementById(this.table.id + '-table').style.width = this.cols.reduce((summe, element) => summe + element.width, 0) + this.buttonColWidth + 'px';
			document.getElementById(this.table.id + '-table').style.minWidth = this.cols.reduce((summe, element) => summe + element.width, 0) + this.buttonColWidth + 'px';

			setTimeout(() => {
				if (this.state) {
					localStorage.setItem(this.stateName, JSON.stringify(this.state));
				}
			}, 0);
		}

		this.loading -= 1;
	}

	/**
	 * TableState abrufen
	 * 
	 * @param state  TableState der Tabelle
	 */
	retrieveTableState(state?): void {
		this.state = state ? state : JSON.parse(localStorage.getItem(this.stateName));
		if (this.table && (this.state == undefined)) {
			// for storage of table state
			this.table.saveState();
			// reload and parse
			this.state = JSON.parse(localStorage.getItem(this.stateName));
		}
	}

	/**
	 * Daten aus dem Zwischenspeicher holen
	 * 
	 * @returns Objekt mit dem Ladezeitpunkt, den angewendeten Ladefiltern und allen Einträgen oder null bei ungültigem key
	 */
	storageGetEntries(): { timestamp: Date, filters: any, entries: Base[] } {
		return VIONSessionStorage.getInstance().get(this.apiUrl);
	}

	/**
	 * Daten im Zwischenspeicher ablegen
	 */
	storageSetEntries(): void {
		VIONSessionStorage.getInstance().set(this.apiUrl, this.loadTimestamp, this.loadFilters, this.entries);
	}

	/**
	 * Tabellenspalte ein- oder ausblenden
	 * 
	 * @param event Auswahl-Event des Spaltenauswahldropdowns
	 */
	toggleColumn(event): void {
		this.retrieveTableState(this.state);
		this.state.columnOrder = event.value.map(c => c.key);
		this.state.columnWidths = event.value.map(c => c.width);
		this.state.columnWidths = this.state.columnWidths.join(',');
		this.state.columnWidths = this.state.columnWidths + ',' + this.buttonColWidth;
		this.state.tableWidth = (this.state.columnWidths.split(',')).reduce((summe, element) => summe + Number(element), 0) + 'px';
		this.filters = event.value.map(c => c.key);
		localStorage.setItem(this.stateName, JSON.stringify(this.state));
		this.resizeTableWidth(this.state);
	}

	/**
	 * Liefert die Länge der Darstellung eines Textes in Pixeln zurück
	 * 
	 * @param text Text, der überprüft werden soll
	 * @param styleFont Font, in der der Text geschrieben ist
	 * @returns Textbreite in Pixeln
	 */
	getTextLength(text: string, styleFont: string): number {
		const ctx = this.emptyCanvas.nativeElement.getContext('2d');
		ctx.font = styleFont;
		const textMetrics = ctx.measureText(text);
		return Math.round(textMetrics.actualBoundingBoxLeft + textMetrics.actualBoundingBoxRight);
	}

	/**
	 * wenn eine spalte noch ein special symbol im header hat
	 * 
	 * @param maxStringLength 
	 * @param columnkey 
	 * @returns 
	 */
	adaptColumnSize(maxStringLength, columnkey): number {
		return maxStringLength;
	}

	/**
	 * berechnet die optimale spaltenbreite für die tabelle in abhängigkeit vom inhalt
	 * 
	 * @param bForce 
	 * @param state 
	 */
	resizeTableWidthFromContent(bForce, state?): void {
		var bResize = bForce;

		this.loading += 1;

		// code aus retrieveTableState, muss hier separat gemacht werden damit man bResize korrekt setzen kann
		this.state = state ? state : JSON.parse(localStorage.getItem(this.stateName));
		if (this.state == undefined) {
			// force storage of table state
			this.table.saveState();
			// reload state
			this.state = JSON.parse(localStorage.getItem(this.stateName));
			bResize = true;
		}

		if (this.table && bResize) {
			// autosize columns
			const lTable = document.getElementById(this.table.id);
			var lTableFont = window.getComputedStyle(lTable, null).getPropertyValue('font');
			// für alle spalten, alle daten            
			this.cols.forEach(col => {
				let columnname = col.sub ? this.translate.instant('HEADERS.' + col.sub + '.' + col.key) : this.translate.instant('HEADERS.' + col.key);
				let maxStringLength = this.getTextLength(columnname, lTableFont);
				// filter symbol
				maxStringLength = maxStringLength + 80;
				maxStringLength = this.adaptColumnSize(maxStringLength, col.key);
				if (this.entries) {
					this.entries.forEach(row => {
						let newLength = 0;
						if (col.type == 'date') {
							if (row[col.key] != undefined)
								newLength = this.getTextLength(row[col.key].toLocaleString(), lTableFont);
							else
								newLength = 0;
						} else {
							newLength = this.getTextLength(row[col.key], lTableFont);
						}
						// margins zur zelle
						newLength = newLength + 26;
						if (newLength > maxStringLength)
							maxStringLength = newLength;
					})
				}

				col.width = maxStringLength;
			});

			this.state.columnWidths = (this.cols.map(c => c.width)).concat([this.buttonColWidth]).join(',');
		}

		// standard funktion aufrufen
		this.resizeTableWidth(this.state);

		this.loading -= 1;
	}

	//headerKeyEx(col): string {
	//	let strHeader: string = '';
	//	if(col && col.key) {
	//		if(col.sub)
	//			strHeader = 'HEADERS.' + col.sub + '.' + col.key;
	//		else strHeader = 'HEADERS.' + col.key;
	//	}
	//	console.log('headerKeyEx returning: ' + strHeader);
	//	console.log(col);
	//	return strHeader;
	//}

	listReplaceEntry(entry): void {
		if(entry && entry['ds_this_id']) {
			let id = entry['ds_this_id'];
			let newEntries: Base[] = [];
			if(this.entries) {
				for(let i = 0; i < this.entries.length; ++i) {
					if( this.entries[i].ds_this_id === id) {
						newEntries.push(entry);
					} else {
						newEntries.push(this.entries[i]);
					}
				}
				this.entries = newEntries;
				this.storageSetEntries();
				this.resizeTableWidth(); // geht auch ohne
			}
		}
	}

	listReplaceEntries(replaceEntries): void {
		if(replaceEntries && replaceEntries.length > 0) {
			let newEntries: Base[] = [];
			if(this.entries) {
				for(let i = 0; i < this.entries.length; ++i) {
					let bFoundMatch: boolean = false;
					let nFoundIdx: number = 0;
					for(let u = 0; u < replaceEntries.length; ++u) {
						if( this.entries[i].ds_this_id === replaceEntries[u].ds_this_id ) {
							nFoundIdx = u;
							bFoundMatch = true;
							break;
						}
					}
					if( bFoundMatch ) {
						newEntries.push(replaceEntries[nFoundIdx]);
					} else {
						newEntries.push(this.entries[i]);
					}
				}
				this.entries = newEntries;
				this.storageSetEntries();
				this.resizeTableWidth(); // geht auch ohne
			}
		}
	}

	// #endregion Table
}
