import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { EditComponent } from '@app/common/templates/edit/edit.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TblKomponenteTypCreateComponent } from '../../tbl_komponente_typ/create/create.component';

@Component({
	templateUrl: '../../../../common/templates/edit/edit.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/edit/style.scss'],
	providers: [DialogService, MessageService, ConfirmationService]
})
export class TblKomponenteEditComponent extends EditComponent {
	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public dialogService: DialogService,
		public globals: Globals,
		public messageService: MessageService,
		public router: Router,
		public translate: TranslateService,
	) {
		super(accountService, breadcrumbService, confirmationService, crudService, dialogService, globals, messageService, router, translate);

		this.apiUrl = 'TblKomponente';
		this.name = 'MENU.KOMPONENTE';
		this.url = '/' + PATH.KOMPONENTE;
		this.fieldgroups.push(
			[
				{ type: 'text', key: 'ankey', required: false, width: 400, readonly: false },
				{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
				{ type: 'text', key: 'bezeichnung_kurz', required: false, width: 400 },
				{ type: 'text', key: 'serial_no', required: false, width: 400 },
				{ type: 'text', key: 'lizenzkey_qt5', required: false, width: 400 },
				{ type: 'text', key: 'lizenzkey_lic_ki_lib', required: false, width: 400 },
				{ type: 'text', key: 'lizenzkey_visy_software', required: false, width: 400 },
				{ type: 'text', key: 'lizenzkey_sywatec_software', required: false, width: 400 },
				{ type: 'object', key: 'komponente_typ_id', label: 'komponente_typ', required: false, width: 400, apiUrl: 'TblKomponenteTyp', createComponent: TblKomponenteTypCreateComponent },
				{ type: 'text', key: 'bemerkungen', required: false, width: 400 },
				{ type: 'numeric', key: 'feature_flags', required: false, width: 400 },
			]
		);
		this.breadcrumbService.setItems([
			{ label: 'MENU.STAMMDATEN' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.EDIT', routerLink: [this.url + '/' + PATH.EDIT + '/' + this.id] }
		]);
	}

	getOptions(): void {
		this.fieldgroups.forEach(fg => {
			fg.forEach(field => {
				if (field.type == 'object' || field.type == 'list') {
					this.loading += 1;
					this.crudService.getAllEntries(field.apiUrl).then(res => {
						this.options[field.key] = res.map(option => ({ label: option.bezeichnung, value: option.ds_this_id, object: option }));
						field.optionSortFieldOptions = [
							{ label: this.translate.instant('HEADERS.lfd_nummer'), value: 'lfd_nummer' },
							{ label: this.translate.instant('HEADERS.ankey'), value: 'ankey' },
							{ label: this.translate.instant('HEADERS.Bezeichnung'), value: 'bezeichnung' }
						];
						//field.optionSortFieldOptions = Object.keys(res[0]).map(key => ({ label: this.translate.instant('HEADERS.' + key), value: key }));
						//field.optionSortFieldOptions = field.optionSortFieldOptions.filter(option => !option.label.includes('HEADERS.'));
						this.changeSorting(field, true);
					}).catch(err => {
						err.error.forEach(e => {
							this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
						})
					}).finally(() => {
						this.loading -= 1;
					});
				}
			});
		});
	}
}
