<div class="p-grid">
    <div class="p-col-12">
        <!-- Spinner -->
        <div *ngIf="loading" class="spinner">
            <span class="ball-1"></span>
            <span class="ball-2"></span>
            <span class="ball-3"></span>
            <span class="ball-4"></span>
            <span class="ball-5"></span>
            <span class="ball-6"></span>
            <span class="ball-7"></span>
            <span class="ball-8"></span>
        </div>

        <!-- Content -->
        <h5>Firma</h5>
        <input type="text" pInputText [(ngModel)]="company" [style]="{'width':'100%'}" placeholder="Name">

        <h5>Ansprechpartner</h5>
        <input type="text" pInputText [(ngModel)]="name" [style]="{'width':'100%'}" placeholder="Name">
        <input type="text" pInputText [(ngModel)]="email" [style]="{'width':'100%'}" placeholder="E-Mail">
        <input type="text" pInputText [(ngModel)]="phone" [style]="{'width':'100%'}" placeholder="Telefon">

        <h5>Wartungsort</h5>
        <input type="text" pInputText [(ngModel)]="addressname" [style]="{'width':'100%'}" placeholder="Name">
        <input type="text" pInputText [(ngModel)]="street" [style]="{'width':'100%'}" placeholder="Straße, Hausnummer">
        <input type="text" pInputText [(ngModel)]="town" [style]="{'width':'100%'}" placeholder="PLZ, Ort">

        <h5>Fahrzeuge</h5>
        <p-multiSelect [style]="{'width':'100%'}" [options]="vehicles" placeholder="Fahrzeuge"
            [(ngModel)]="selectedVehicles" [virtualScroll]="true" itemSize="30" filterBy="bezeichnung"
            optionLabel="bezeichnung" appendTo="body">
        </p-multiSelect>

        <h5>Nachricht</h5>
        <textarea pInputTextarea [(ngModel)]="comment" [style]="{'width':'100%'}" placeholder="Nachricht"></textarea>

    </div>
    <button pButton pRipple label="{{'BUTTONS.CREATE_REQUEST'|translate}}" class="p-button-success p-m-2"
        (click)="save()"></button>
</div>