<ng-container>
	<a [attr.href]="item.url" (click)="itemClick($event)" *ngIf="!item.routerLink || item.items"
		(mouseenter)="onMouseEnter()" (keydown.enter)="itemClick($event)" [attr.target]="item.target"
		[attr.tabindex]="0">
		<span class="menuitem-text" style="margin: 0px 5px 5px 5px;">{{item.label | translate}}</span>
		<i class="pi pi-fw pi-angle-down layout-submenu-toggler" style="margin-bottom: 8px;" *ngIf="item.items"></i>
		<i [ngClass]="item.icon"></i>
	</a>
	<a (click)="itemClick($event)" (mouseenter)="onMouseEnter()" *ngIf="item.routerLink && !item.items"
		[routerLink]="item.routerLink" routerLinkActive="active-menuitem-routerlink"
		[routerLinkActiveOptions]="{exact: true}" [attr.target]="item.target" [attr.tabindex]="0">
		<span class="menuitem-text" style="margin: 0px 5px 5px 5px;">{{item.label | translate}}</span>
		<i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items"></i>
		<i [ngClass]="item.icon"></i>
	</a>
	<ul *ngIf="item.items && active"
		[@children]="(app.isHorizontal() && root) ? (active ? 'visible' : 'hidden') : (active ? 'visibleAnimated' : 'hiddenAnimated')">
		<ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
			<li *ngIf="authorized(child) && !isEmptyParent(child)" app-menuitem [item]="child" [index]="i"
				[parentKey]="key" [class]="child.badgeClass"></li>
		</ng-template>
	</ul>
</ng-container>