import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { tbl_fahrzeug } from '@app/models/tbl_fahrzeug';
import PATH from '@assets/routes/routes.json';
import { AppMainComponent } from '@components/app.main.component';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { environment } from '@environments/environment';
import { Arrangement, Occasion, Risk, State, Track } from '@models/reversingcadastral';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '@services/account.service';
import { CRUDService } from '@services/crud.service';
import { LogService } from "@services/log.service";
import * as fs from 'file-saver';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { asArray } from 'ol/color';
import GPX from 'ol/format/GPX';
import { Point } from 'ol/geom';
import { Feature, Map, View } from 'ol/index';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { useGeographic } from 'ol/proj';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TblRueckfahrkatasterAufzeichnungAktenzuweisungListComponent } from '../popup_list_aktenzuweisung/list_aktenzuweisung.component';
import { DialogService } from 'primeng/dynamicdialog';
import { Base } from '@app/models/base';

@Component({
	templateUrl: './detail.component.html',
	styleUrls: ['../style.scss'],
	providers: [MessageService, ConfirmationService, DialogService]
})
export class TblRueckfahrkatasterAufzeichnungDetailComponent implements OnInit {
	public arrangements: Arrangement[];
	public basefields: any[];
	public fieldgroups: any[];
	public id: number;
	public loading = 0;
	public mapRasDetails: Map;
	public occasions: Occasion[];
	public options: any;
	positionLayer: VectorLayer;
	public ratings: any;
	public risks: Risk[];
	routeLayer: VectorLayer;
	startTimeStamp: number;
	public states: State[];
	public track: Track;
	trackPoints: any[];
	url: string = '';
	url_rk_akte: string = '';
	public vehicles: tbl_fahrzeug[];
	videoplayer: any;
	video_api_url: string = '';
	videoSafeUrl: any;
	gpx_api_url: string = '';
	gpxUnsafeUrl: string = '';
	gpxSafeUrl: any;
	bHasNoRasFile: boolean = false;



	public url_prefix_strecke: string = '#/' + PATH.RK_STRECKE + '/' + PATH.DETAIL + '/';
	public url_prefix_akte_edit: string = '#/' + PATH.RK_STRECKE + '/' + PATH.EDIT + '/';
	public url_prefix_fahrzeug: string = '#/' + PATH.FAHRZEUG + '/' + PATH.DETAIL + '/';

	@ViewChild("detail_track_video") videoDetailTrackVideo: ElementRef;
	//@ViewChild("gpxSrcWrapper") gpxSrcWrapper: ElementRef;
	constructor(
		public app: AppMainComponent,
		private breadcrumbService: BreadcrumbService,
		private confirmationService: ConfirmationService,
		private crudService: CRUDService,
		private messageService: MessageService,
		private router: Router,
		public translate: TranslateService,
		private logService: LogService,
		private accountService: AccountService,
		private http: HttpClient,
		private sanitizer: DomSanitizer,
		private dialogService: DialogService
	) {
		const href = this.router.url.split('/');
		this.id = +href[href.length - 1];
		this.url = '/' + PATH.RK_AUFZEICHNUNG;
		this.url_rk_akte = '/' + PATH.RK_STRECKE;

		this.breadcrumbService.setItems([
			{ label: 'MENU.RUECKFAHRKATASTER', routerLink: [this.url] },
			{ label: 'BREADCRUMBS.DETAIL', routerLink: [this.url + '/' + PATH.DETAIL + '/' + this.id] }
		]);

		this.fieldgroups = [
			[
				{ type: 'string', key: 'name', label: 'Name' },
				{ type: 'date', key: 'aufgezeichnet', label: 'Aufzeichnung' },
				{ type: 'boolean', key: 'ras_deaktiviert', label: 'RAS deaktiviert' },
				{ type: 'vehicle', key: 'fahrzeug_id', label: 'Fahrzeug' },
				{ type: 'string', key: 'freitext', label: 'Anmerkungen' },
				{ type: 'list', key: 'status', label: 'Status' },
				{ type: 'parent', key: 'akte_id', label: 'RK Akte' },
				{ type: 'boolean', key: 'in_kartaster', label: 'In Kataster' },
			],
			[
				{ type: 'string', key: 'plz_von', label: 'PLZ von' },
				{ type: 'string', key: 'ort_von', label: 'Ort von' },
				{ type: 'string', key: 'strasse_von', label: 'Straße von' },
				{ type: 'string', key: 'hausnummer_von', label: 'Hausnummer von' },
				{ type: 'string', key: 'plz_bis', label: 'PLZ bis' },
				{ type: 'string', key: 'ort_bis', label: 'Ort bis' },
				{ type: 'string', key: 'strasse_bis', label: 'Straße bis' },
				{ type: 'string', key: 'hausnummer_bis', label: 'Hausnummer bis' },
				{ type: 'number', key: 'laenge', label: 'Länge' },
				{ type: 'boolean', key: 'breiter_350', label: 'Breiter als 3,50m' },
				{ type: 'boolean', key: 'kuerzer_150', label: 'Kürzer als 150m' },
				{ type: 'string', key: 'center_latitude', label: 'Latitude' },
				{ type: 'string', key: 'center_longitude', label: 'Longitude' },
			]
		];

		this.ratings =
		{
			0: '',
			1: 'green',
			2: 'lightgreen',
			3: 'yellow',
			4: 'lightcoral',
			5: 'red'
		};
	}

	ngAfterViewInit() {
		this.accountService.getOptions().then(options => {
			this.http.get(this.gpx_api_url, { withCredentials: options.withCredentials, headers: options.headers, responseType: "blob" }).subscribe(blob => {
				const unsafeUrl = URL.createObjectURL(blob);
				const safeUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeUrl);
				this.gpxUnsafeUrl = unsafeUrl;
				this.gpxSafeUrl = safeUrl;
				this.initMap();
			});

			this.http.get(this.video_api_url, { withCredentials: options.withCredentials, headers: options.headers, responseType: "blob" }).subscribe(blob => {
				const unsafeUrl = URL.createObjectURL(blob);
				const safeUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeUrl);
				this.videoSafeUrl = safeUrl;
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		});
	}

	getEntryEx() {
		this.crudService.getTrack(this.id).then(res => {
			this.track = res;
			//this.track["gefahren"] = this.track.rk_gefahren ? this.track.rk_gefahren.substring(1, this.track.rk_gefahren.length - 1).replaceAll('"', '').split(',') : null;
			//this.track["gruende"] = this.track.rk_gruende ? this.track.rk_gruende.substring(1, this.track.rk_gruende.length - 1).replaceAll('"', '').split(',') : null;
			//this.track["massnahmen"] = this.track.rk_massnahmen ? this.track.rk_massnahmen.substring(1, this.track.rk_massnahmen.length - 1).replaceAll('"', '').split(',') : null;
			//this.track["status"] = this.track.rk_status ? this.track.rk_status.substring(1, this.track.rk_status.length - 1).replaceAll('"', '').split(',') : null;
			this.bHasNoRasFile = this.hasNoRasFile();
			this.stringsToDates();
			//this.initMap();
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	ngOnInit() {
		this.loading += 1;
		this.video_api_url = `${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/video/` + this.id;
		this.gpx_api_url = `${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/gpx/` + this.id;
		this.getEntryEx();

		// log view
		setTimeout(() => this.logService.log("Information", 1, "ReversingCadastral", "ReversingCadastral.Detail::Open"), 5000);
	}

	delete() {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.DELETE_QUESTION'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.loading += 1;
				this.crudService.deleteTrack(this.track.ds_this_id).then(res => {
					this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });
					this.router.navigate(['/reports/reversingcadastral']);
				}).catch(err => {
					err.error.forEach(e => {
						if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
						}
					})
				}).finally(() => {
					this.loading -= 1;
				});
			}
		});
	}

	edit() {
		this.router.navigate([this.url + '/' + PATH.EDIT + '/' + this.id]);
	}

	hasRasFile(): boolean {
		if(this.track && this.track.akte_id != null && this.track.akte_id != undefined && this.track.akte_id >= 1) {
			return true;
		}
		return false;
	}

	hasNoRasFile(): boolean {
		return !this.hasRasFile();
	}

	_aktenzuweisung_neue_anlegen_und_zeigen(): void {
		// neue anlegen und zeigen
		this.loading += 1;
		this.crudService.createFileForRecord(this.track).then(resultingId => {
			//console.log('createFileForRecord done!');
			//console.log(res);
			this.loading -= 1;
			if( resultingId != null && resultingId != undefined && resultingId > 0 ) {
				this.confirmationService.confirm({
					message: this.translate.instant('CONFIRMATION.RUECKFAHRKATASTER.NEUE_AKTE_ANZEIGEN'),
					header: this.translate.instant('CONFIRMATION.CONFIRM'),
					icon: 'pi pi-exclamation-triangle',
					acceptLabel: this.translate.instant('CONFIRMATION.YES'),
					rejectLabel: this.translate.instant('CONFIRMATION.NO'),
					accept: () => {
						//this.router.navigate([this.url_rk_akte + '/' + PATH.EDIT + '/' + resultingId]);
						this.loading += 1;
						this.crudService.getTrack(this.id).then(res => {
							this.loading -= 1;
							this.track = res;
							this.bHasNoRasFile = this.hasNoRasFile();
							this.stringsToDates();
							if(this.track['FREMD_strecke_ds_this_id']) {
								let strTarget: string = 'tab'; // über Variable implementiert, falls das doch noch parametrierbar gestaltet werden soll später
								this.editAkteEx(strTarget);
							}
						}).catch(err => {
							this.loading -= 1;
							err.error.forEach(e => {
								if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
								} else {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
								}
							})
						});
					}
				});
			} else {
				this.messageService.add({ severity: 'info', summary: 'Information ', detail: this.translate.instant('INFORMATION.RUECKFAHRKATASTER.AKTENERZEUGUNG_FEHLER'), life: 8000 });
			}
		}).catch(err => {
			this.loading -= 1;
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		});
	}

	_aktenzuweisung_link2existing(akte_id_existing: number): void {
		this.loading += 1;
		this.crudService.linkRecordToFile(this.track.ds_this_id, akte_id_existing).then(res => {
			this.loading -= 1;
			if(res) {
				this.confirmationService.confirm({
					message: this.translate.instant('CONFIRMATION.RUECKFAHRKATASTER.NEU_VERKNUEPFTE_AKTE_ANZEIGEN'),
					header: this.translate.instant('CONFIRMATION.CONFIRM'),
					icon: 'pi pi-exclamation-triangle',
					acceptLabel: this.translate.instant('CONFIRMATION.YES'),
					rejectLabel: this.translate.instant('CONFIRMATION.NO'),
					accept: () => {
						//this.router.navigate([this.url_rk_akte + '/' + PATH.EDIT + '/' + akte_id_existing]);
						this.loading += 1;
						this.crudService.getTrack(this.id).then(res => {
							this.loading -= 1;
							this.track = res;
							this.bHasNoRasFile = this.hasNoRasFile();
							this.stringsToDates();
							if(this.track['FREMD_strecke_ds_this_id']) {
								//this.router.navigate([this.url_rk_akte + '/' + PATH.EDIT + '/' + this.track['FREMD_strecke_ds_this_id']]);
								let strTarget: string = 'tab'; // über Variable implementiert, falls das doch noch parametrierbar gestaltet werden soll später
								this.editAkteEx(strTarget);
							}
						}).catch(err => {
							this.loading -= 1;
							err.error.forEach(e => {
								if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
								} else {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
								}
							})
						});
					}
				});
			} else {
				this.messageService.add({ severity: 'info', summary: 'Information ', detail: this.translate.instant('INFORMATION.RUECKFAHRKATASTER.AKTENVERKNUEPFUNG_FEHLER'), life: 8000 });
			}
		}).catch(err => {
			this.loading -= 1;
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			});
		});
	}

	editAkteEx(target: string) {
		if (target == 'window') {
			window.open('/#/' + this.url_rk_akte + '/' + PATH.EDIT + '/' + this.track['FREMD_strecke_ds_this_id'], '_blank', 'newWindow=1');
		} else if (target == 'tab') {
			window.open('/#/' + this.url_rk_akte + '/' + PATH.EDIT + '/' + this.track['FREMD_strecke_ds_this_id']);
		} else {
			this.router.navigate([this.url_rk_akte + '/' + PATH.EDIT + '/' + this.track['FREMD_strecke_ds_this_id']]);
		}
		/*
			if (target == 'window') {
				window.open('/#/' + this.url + '/' + PATH.EDIT + '/' + this.selectedEntry.ds_this_id, '_blank', 'newWindow=1');
			} else if (target == 'tab') {
				window.open('/#/' + this.url + '/' + PATH.EDIT + '/' + this.selectedEntry.ds_this_id);
			} else {
				this.router.navigate([this.url + '/' + PATH.EDIT + '/' + this.selectedEntry.ds_this_id]);
			}
		*/
	}

	aktenzuweisung(): void {
		// Mindestbedingungen zur Aktenerzeugung prüfen und ggf. Meldung anzeigen!
		// sollte mindestens aufweisen: this.track.[...]:
		// - plz_von
		// - ort_von
		// - strasse_von
		
		let bDatenAusreichend: boolean = false;
		if( this.track != null && this.track != undefined && this.track.ds_this_id != null && this.track.ds_this_id != undefined && this.track.ds_this_id > 0 ) {
			let hasPLZ = this.track['plz_von'] != null && this.track['plz_von'] != undefined && this.track['plz_von'].toString().trim().length > 0;
			let hasOrt = this.track['ort_von'] != null && this.track['ort_von'] != undefined && this.track['ort_von'].toString().trim().length > 0;
			let hasStrasse = this.track['strasse_von'] != null && this.track['strasse_von'] != undefined && this.track['strasse_von'].toString().trim().length > 0;
			bDatenAusreichend = hasPLZ && hasOrt && hasStrasse;
		}

		if( !bDatenAusreichend ) {
			this.messageService.add({ severity: 'info', summary: 'Information ', detail: this.translate.instant('INFORMATION.RUECKFAHRKATASTER.DATEN_UNGENUEGEND'), life: 8000 });
		} else {
			this.loading += 1;
			this.crudService.getTrackfileCandidatesIncludingAlwaysCandidates(this.track).then(res => {
				this.loading -= 1;
				if( res && res.length > 0 ) {
					// picker anzeigen
					const ref = this.dialogService.open(TblRueckfahrkatasterAufzeichnungAktenzuweisungListComponent, {
						header: this.translate.instant('Aktenzuweisung'),
						width: '70%',
						data: { candidateFiles: res }
					});
					
					ref.onClose.subscribe((data) => {
						// onClose:
						//this.resultData['selectedEntry'] = this.selectedEntry;
	
						if( data != null && data != undefined ) {
							if( data['selectedEntry'] != null && data['selectedEntry'] != undefined ) {
								let pExistingFile: Base = data['selectedEntry'];
								if( pExistingFile != null && pExistingFile != undefined
									&& pExistingFile.ds_this_id != null && pExistingFile.ds_this_id != undefined
									&& pExistingFile.ds_this_id !== 0 )
								{
									this._aktenzuweisung_link2existing(pExistingFile.ds_this_id);
								}
							} else if(data['createNew']) {
								this.confirmationService.confirm({
									message: this.translate.instant('CONFIRMATION.RUECKFAHRKATASTER.KEINE_AKTE_ZUM_LINKEN_AUSGEWAEHLT'),
									header: this.translate.instant('CONFIRMATION.CONFIRM'),
									icon: 'pi pi-exclamation-triangle',
									acceptLabel: this.translate.instant('CONFIRMATION.YES'),
									rejectLabel: this.translate.instant('CONFIRMATION.NO'),
									accept: () => {
										this._aktenzuweisung_neue_anlegen_und_zeigen();
									}
								});
							}
						}
					});
				} else {
					// keine kandidaten, neue anlegen mit rückfrage
					this.confirmationService.confirm({
						message: this.translate.instant('CONFIRMATION.RUECKFAHRKATASTER.KEINE_AKTE_VORHANDEN_NEUE_ANLEGEN'),
						header: this.translate.instant('CONFIRMATION.CONFIRM'),
						icon: 'pi pi-exclamation-triangle',
						acceptLabel: this.translate.instant('CONFIRMATION.YES'),
						rejectLabel: this.translate.instant('CONFIRMATION.NO'),
						accept: () => {
							this._aktenzuweisung_neue_anlegen_und_zeigen();
						}
					});
				}
			}).catch(err => {
				this.loading -= 1;
				err.error.forEach(e => {
					if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 3000 });
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 3000 });
					}
				});
				this.confirmationService.confirm({
					message: this.translate.instant('CONFIRMATION.RUECKFAHRKATASTER.FEHLER_AKTEN_LADEN_NEUE_ANLEGEN'),
					header: this.translate.instant('CONFIRMATION.CONFIRM'),
					icon: 'pi pi-exclamation-triangle',
					acceptLabel: this.translate.instant('CONFIRMATION.YES'),
					rejectLabel: this.translate.instant('CONFIRMATION.NO'),
					accept: () => {
						this._aktenzuweisung_neue_anlegen_und_zeigen();
					}
				});
			});
		} // if( !bDatenAusreichend )
	}

	export() {
		const doc = new jsPDF();

		autoTable(doc, {
			head: [['Rückfahrkataster', '']],
			body: [
				['Name', this.track.name],
				['Aufgezeichnet', this.parseDate(this.track.aufgezeichnet)],
				['RAS deaktiviert', (this.track.ras_deaktiviert === true ? 'Ja' : 'Nein')],
				['Anmerkungen', this.track.freitext],
				['Status', this.track.rk_status],
				['Postleitzahl von', this.track.plz_von],
				['Ort von', this.track.ort_von],
				['Straße von', this.track.strasse_von],
				['Hausnummer von', this.track.hausnummer_von],
				['Postleitzahl bis', this.track.plz_bis],
				['Ort bis', this.track.ort_bis],
				['Straße bis', this.track.strasse_bis],
				['Hausnummer bis', this.track.hausnummer_bis],
				['Länge', this.track.laenge + 'm'],
				['Kürzer als 150 m', (this.track.kuerzer_150 === true ? 'Ja' : 'Nein')],
				['Breiter als 3,50 m', (this.track.breiter_350 === true ? 'Ja' : 'Nein')],
			]
		});
		html2canvas(document.getElementById('mapRasDetails'), { scrollY: -window.scrollY }).then(canvas => {
			const image = new Image();
			image.src = canvas.toDataURL('image/png');
			doc.addImage(image, 'png', 15, 170, 180, 110);
			doc.save((this.track.name ? this.track.name : 'rk' + this.track.ds_this_id) + '.pdf');
		});

		this.accountService.getOptions().then(options => {
			this.http.get(this.video_api_url, { withCredentials: options.withCredentials, headers: options.headers, responseType: "blob" }).subscribe(blob => {
				fs.saveAs(blob, this.track['name'] + '.mp4');
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		});
	}

	initMap() {
		useGeographic();
		const coord = [this.track.center_longitude, this.track.center_latitude];

		this.positionLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			style: new Style({
				image: new Circle({
					radius: 5,
					fill: new Fill({
						color: 'blue'
					})
				})
			})
		});

		this.routeLayer = new VectorLayer({
			source: new VectorSource({
				//url: '/assets' + this.track.gpx,
				url: this.gpxUnsafeUrl,
				format: new GPX(),
				crossOrigin: 'anonymous'
			}),
			style: (feature) => {
				var colorName = feature.get('desc') == 'v' ? this.app.forwardingColor : this.app.reversingColor;
				var colorRGB = asArray(colorName); // Farbe von Name in RGB Array umwandeln
				colorRGB = colorRGB.slice();
				colorRGB[3] = 0.7; // Alphakanal hinzufügen
				return new Style({
					stroke: new Stroke({
						color: colorRGB,
						width: 4
					})
				})
			}
		});

		this.routeLayer.getSource().on('error', this.mapError());

		this.mapRasDetails = new Map({
			target: 'mapRasDetails',
			view: new View({
				center: coord,
				zoom: 17,
				maxZoom: 18,
			}),
			controls: [],
			renderer: 'webgl',
			layers: [
				new TileLayer({
					source: new OSM({
						url: environment.mapUrl,
						format: 'image/png',
						crossOrigin: 'anonymous'
					})
				}),
				this.routeLayer,
				this.positionLayer
			]
		});

		this.mapRasDetails.on('singleclick', event => {
			const coord = this.mapRasDetails.getCoordinateFromPixel(event.pixel);
			let closestPoint = [0, 0, 0];
			this.trackPoints.forEach(tp => {
				const distCurrentPoint = Math.abs(tp[0] - coord[0]) + Math.abs(tp[1] - coord[1]);
				const distClosestPoint = Math.abs(closestPoint[0] - coord[0]) + Math.abs(closestPoint[1] - coord[1]);
				if (distCurrentPoint < distClosestPoint) {
					closestPoint = tp;
				}
			});
			const timestamp = closestPoint[2] - this.startTimeStamp;
			this.videoplayer.currentTime = timestamp;
			this.setVehiclePosition(timestamp);
		});
	}

	initGPX(event) {
		this.videoplayer = event.target;
		if (!this.trackPoints) {
			this.trackPoints = [];
			this.routeLayer.getSource().getFeatures().forEach(feature => {
				const chunkSize = 3;
				let points = [];
				for (let i = 0; i < feature.getGeometry().flatCoordinates.length; i += chunkSize) {
					const chunk = feature.getGeometry().flatCoordinates.slice(i, i + chunkSize);
					points.push(chunk);
					if (!this.startTimeStamp || +chunk[2] < this.startTimeStamp) {
						this.startTimeStamp = +chunk[2];
					}
				}
				this.trackPoints = [...this.trackPoints, ...points];
			});
		}

		this.setVehiclePosition(0);
	}

	parseDate(date: Date): string {
		const options: Intl.DateTimeFormatOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
		if (date) {
			return (date.toLocaleDateString('de-DE', options)) + ', ' + (date.toLocaleTimeString('de-DE'));
		} else {
			return null;
		}
	}

	setVehiclePosition(videoTimestamp) {
		let position = this.trackPoints[0];
		this.trackPoints.forEach(tp => {
			if (Math.abs(this.startTimeStamp + videoTimestamp - tp[2]) < Math.abs(this.startTimeStamp + videoTimestamp - position[2])) { // evtl +5 Sekunden wegen GPS Buffer?!
				position = tp;
			}
		})

		const feature = new Feature({
			geometry: new Point([position[0], position[1]]),
		});
		this.positionLayer.getSource().clear();
		this.positionLayer.getSource().addFeature(feature);
	}

	stringsToDates() {
		this.fieldgroups.forEach(group => {
			group.forEach(field => {
				if (field.type === 'date') {
					if (this.track[field.key]) {
						this.track[field.key] = new Date(this.track[field.key]);
					}
				}
			});
		});
	}

	updateVehicle() {
		this.vehicles.forEach(v => {
			if (v.ds_this_id === this.track.fahrzeug_id) {
				this.track.Vehicle = v;
			}
		});
	}

	mapError() {
		if (this.gpxUnsafeUrl == null || this.gpxUnsafeUrl == undefined || this.gpxUnsafeUrl.length == 0) {
			this.messageService.add({ severity: 'error', summary: 'Map Error', detail: 'Can\'t load gpx file.', life: 10000 });
		}
	}

	videoError() {
		this.messageService.add({ severity: 'error', summary: 'Video Error', detail: 'Can\'t load video file.', life: 10000 });
		this.track.video_url = null;
	}
}
