import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import PATH from '@assets/routes/routes.json';
import { environment } from '@environments/environment';
import { Settings } from '@models/settings';
import { AccountService } from '@services/account.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SettingsService {
	isFooterVisible: boolean;
	footerVisibilityChange: Subject<boolean> = new Subject<boolean>();

	// landing page indizes und object data
	private readonly indexLandingPage_Label: number = 0;
	private readonly indexLandingPage_Alias: number = 1;
	private readonly indexLandingPage_Route: number = 2;
	private LandingPage_Home: string[];// = ['Home', 'lpHome', PATH.HOME]; // private readonly LandingPage_Home: string[] = ['Home', 'lpHome', 'home'];
	private LandingPage_OnlineFahrzeuge: string[];// = ['Online - Fahrzeuge', 'lpOnlineFahrzeuge', PATH.ONLINE_FAHRZEUGE]; // private readonly LandingPage_OnlineFahrzeuge: string[] = ['Online - Fahrzeuge', 'lpOnlineFahrzeuge', 'online/karte'];
	private LandingPage_OnlineLeerungen: string[];// = ['Online - Leerungen', 'lpOnlineLeerungen', PATH.ONLINE_LEERUNGEN]; // private readonly LandingPage_OnlineLeerungen: string[] = ['Online - Leerungen', 'lpOnlineLeerungen', 'online/leerung'];
	private LandingPage_StammdatenFahrzeuge: string[];// = ['Stammdaten - Fahrzeuge', 'lpStammdatenFahrzeuge', PATH.FAHRZEUG + '/' + PATH.LIST]; // private readonly LandingPage_StammdatenFahrzeuge: string[] = ['Stammdaten - Fahrzeuge', 'lpStammdatenFahrzeuge', 'fahrzeug/list'];
	private LandingPage_RueckfahrkatasterListe: string[];// = ['Rückfahrkataster', 'lpRasListe', PATH.RK_STRECKE + '/' + PATH.LIST]; // private readonly LandingPage_RueckfahrkatasterListe: string[] = ['Rückfahrkataster', 'lpRasListe', 'rueckfahrkataster_aufzeichnung/list'];
	private LandingPage_BerichteLeerungen: string[];// = ['Berichte - Leerungen', 'lpBerichteLeerungen', PATH.LEERUNG + '/' + PATH.LIST]; // private readonly LandingPage_BerichteLeerungen: string[] = ['Berichte - Leerungen', 'lpBerichteLeerungen', 'leerung/list'];
	private LandingPage_BerichteFahrtbericht: string[];// = ['Berichte - Fahrtberichte', 'lpBerichteFahrtberichte', PATH.FAHRTEBRICHT + '/' + PATH.LIST]; // private readonly LandingPage_BerichteFahrtbericht: string[] = ['Berichte - Fahrtberichte', 'lpBerichteFahrtberichte', 'fahrtbericht/list'];

	// ras aufzeichnung filter settings zeugs
	public readonly enumRasAufzeichnungDefaultFilter_KeineDaten: number = 0;
	public readonly enumRasAufzeichnungDefaultFilter_AlleDaten: number = 1;
	public readonly enumRasAufzeichnungDefaultFilter_AufzeichnungenOhneAkte: number = 2;
	public readonly enumRasAufzeichnungDefaultFilter_AufzeichnungenMitAkte: number = 3;
	public readonly enumRasAufzeichnungDefaultFilter_AufzeichnungenFilterAnzeigen: number = 4;

	private RasAufzeichnungDefaultFilter_KeineDaten: any;// = {label: 'alle Daten', enumValue: this.enumRasAufzeichnungDefaultFilter_KeineDaten};
	private RasAufzeichnungDefaultFilter_AlleDaten: any;// = {label: 'alle Daten', enumValue: this.enumRasAufzeichnungDefaultFilter_AlleDaten};
	private RasAufzeichnungDefaultFilter_AufzeichnungenOhneAkte: any;// = {label: 'alle ohne Akte', enumValue: this.enumRasAufzeichnungDefaultFilter_AufzeichnungenOhneAkte};
	private RasAufzeichnungDefaultFilter_AufzeichnungenMitAkte: any;// = {label: 'alle mit Akte', enumValue: this.enumRasAufzeichnungDefaultFilter_AufzeichnungenMitAkte};
	private RasAufzeichnungDefaultFilter_AufzeichnungenFilterAnzeigen: any;

	public readonly enumRasAufzeichnungDefaultFilterGeoOptions_alle: number = 0;
	public readonly enumRasAufzeichnungDefaultFilterGeoOptions_mit_geo: number = 1;
	public readonly enumRasAufzeichnungDefaultFilterGeoOptions_ohne_geo: number = 2;

	private RasAufzeichnungDefaultFilterGeoOptions_Alle: any;
	private RasAufzeichnungDefaultFilterGeoOptions_MitGeo: any;
	private RasAufzeichnungDefaultFilterGeoOptions_OhneGeo: any;


	constructor(
		private accountService: AccountService,
		private http: HttpClient,
		public translate: TranslateService
	) {
		this.footerVisibilityChange.subscribe(value => {
			this.isFooterVisible = value;
		});
	}

	/**
	 * Einstellung bearbeitung
	 * 
	 * @param setting Einstellung die geändert werden soll
	 * @returns Antwort der API
	 */
	editSetting(setting): Promise<any> {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/settings/edit`, setting, options).toPromise());
		});
	}

	/**
	 * Einstellungen abfragen
	 * 
	 * @returns Antwort der API
	 */
	getSettings(): Promise<Settings> {
		return new Promise<Settings>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/settings`, options).toPromise());
		});
	}

	/**
	 * Footer ein-/ausblenden
	 */
	toggleFooterVisibility(): void {
		this.footerVisibilityChange.next(!this.isFooterVisible);
	}

	getPageSelection(): any[] {
		this.LandingPage_Home = [this.translate.instant('SETTINGS.LANDING_PAGE.LP_HOME'), 'lpHome', PATH.HOME];
		this.LandingPage_OnlineFahrzeuge = [this.translate.instant('SETTINGS.LANDING_PAGE.LP_ONLINE_FAHRZEUGE'), 'lpOnlineFahrzeuge', PATH.ONLINE_FAHRZEUGE];
		this.LandingPage_OnlineLeerungen = [this.translate.instant('SETTINGS.LANDING_PAGE.LP_ONLINE_LEERUNGEN'), 'lpOnlineLeerungen', PATH.ONLINE_LEERUNGEN];
		this.LandingPage_StammdatenFahrzeuge = [this.translate.instant('SETTINGS.LANDING_PAGE.LP_STAMMDATEN_FAHRZEUGE'), 'lpStammdatenFahrzeuge', PATH.FAHRZEUG + '/' + PATH.LIST];
		this.LandingPage_RueckfahrkatasterListe = [this.translate.instant('SETTINGS.LANDING_PAGE.LP_RAS'), 'lpRasListe', PATH.RK_STRECKE + '/' + PATH.LIST];
		this.LandingPage_BerichteLeerungen = [this.translate.instant('SETTINGS.LANDING_PAGE.LP_BERICHTE_LEERUNGEN'), 'lpBerichteLeerungen', PATH.LEERUNG + '/' + PATH.LIST];
		this.LandingPage_BerichteFahrtbericht = [this.translate.instant('SETTINGS.LANDING_PAGE.LP_BERICHTE_FAHRTBERICHTE'), 'lpBerichteFahrtberichte', PATH.FAHRTEBRICHT + '/' + PATH.LIST];
		return [
			{ label: this.LandingPage_Home[this.indexLandingPage_Label], value: this.LandingPage_Home[this.indexLandingPage_Alias] },
			{ label: this.LandingPage_OnlineFahrzeuge[this.indexLandingPage_Label], value: this.LandingPage_OnlineFahrzeuge[this.indexLandingPage_Alias] },
			{ label: this.LandingPage_OnlineLeerungen[this.indexLandingPage_Label], value: this.LandingPage_OnlineLeerungen[this.indexLandingPage_Alias] },
			{ label: this.LandingPage_StammdatenFahrzeuge[this.indexLandingPage_Label], value: this.LandingPage_StammdatenFahrzeuge[this.indexLandingPage_Alias] },
			{ label: this.LandingPage_RueckfahrkatasterListe[this.indexLandingPage_Label], value: this.LandingPage_RueckfahrkatasterListe[this.indexLandingPage_Alias] },
			{ label: this.LandingPage_BerichteLeerungen[this.indexLandingPage_Label], value: this.LandingPage_BerichteLeerungen[this.indexLandingPage_Alias] },
			{ label: this.LandingPage_BerichteFahrtbericht[this.indexLandingPage_Label], value: this.LandingPage_BerichteFahrtbericht[this.indexLandingPage_Alias] }
		];
	}

	getRouteForLandingPageAlias(value: string): string {
		let resultRoute: string = this.LandingPage_Home[this.indexLandingPage_Route]; // failback/default

		if (value !== null && value !== undefined && value.trim().length > 0) {
			value = value.trim();

			if (value === this.LandingPage_Home[this.indexLandingPage_Alias]) {
				resultRoute = this.LandingPage_Home[this.indexLandingPage_Route];
			} else if (value === this.LandingPage_OnlineFahrzeuge[this.indexLandingPage_Alias]) {
				resultRoute = this.LandingPage_OnlineFahrzeuge[this.indexLandingPage_Route];
			} else if (value === this.LandingPage_OnlineLeerungen[this.indexLandingPage_Alias]) {
				resultRoute = this.LandingPage_OnlineLeerungen[this.indexLandingPage_Route];
			} else if (value === this.LandingPage_StammdatenFahrzeuge[this.indexLandingPage_Alias]) {
				resultRoute = this.LandingPage_StammdatenFahrzeuge[this.indexLandingPage_Route];
			} else if (value === this.LandingPage_RueckfahrkatasterListe[this.indexLandingPage_Alias]) {
				resultRoute = this.LandingPage_RueckfahrkatasterListe[this.indexLandingPage_Route];
			} else if (value === this.LandingPage_BerichteLeerungen[this.indexLandingPage_Alias]) {
				resultRoute = this.LandingPage_BerichteLeerungen[this.indexLandingPage_Route];
			} else if (value === this.LandingPage_BerichteFahrtbericht[this.indexLandingPage_Alias]) {
				resultRoute = this.LandingPage_BerichteFahrtbericht[this.indexLandingPage_Route];
			} // ... to be continued
		}

		return resultRoute;
	}

	getRasAufzeichnungDefaultFilterSelection(): any[] {
		this.RasAufzeichnungDefaultFilter_KeineDaten = {label: this.translate.instant('SETTINGS.RAS_AUFZEICHNUNG.FILTER_KEINE_DATEN'), enumValue: this.enumRasAufzeichnungDefaultFilter_KeineDaten};
		this.RasAufzeichnungDefaultFilter_AlleDaten = {label: this.translate.instant('SETTINGS.RAS_AUFZEICHNUNG.FILTER_ALLE_DATEN'), enumValue: this.enumRasAufzeichnungDefaultFilter_AlleDaten};
		this.RasAufzeichnungDefaultFilter_AufzeichnungenOhneAkte = {label: this.translate.instant('SETTINGS.RAS_AUFZEICHNUNG.FILTER_OHNE_AKTE'), enumValue: this.enumRasAufzeichnungDefaultFilter_AufzeichnungenOhneAkte};
		this.RasAufzeichnungDefaultFilter_AufzeichnungenMitAkte = {label: this.translate.instant('SETTINGS.RAS_AUFZEICHNUNG.FILTER_MIT_AKTE'), enumValue: this.enumRasAufzeichnungDefaultFilter_AufzeichnungenMitAkte};
		this.RasAufzeichnungDefaultFilter_AufzeichnungenFilterAnzeigen = {label: this.translate.instant('SETTINGS.RAS_AUFZEICHNUNG.FILTER_ANZEIGEN'), enumValue: this.enumRasAufzeichnungDefaultFilter_AufzeichnungenFilterAnzeigen};
		return [
			{ label: this.RasAufzeichnungDefaultFilter_KeineDaten['label'], value: this.RasAufzeichnungDefaultFilter_KeineDaten['enumValue'] },
			{ label: this.RasAufzeichnungDefaultFilter_AlleDaten['label'], value: this.RasAufzeichnungDefaultFilter_AlleDaten['enumValue'] },
			{ label: this.RasAufzeichnungDefaultFilter_AufzeichnungenOhneAkte['label'], value: this.RasAufzeichnungDefaultFilter_AufzeichnungenOhneAkte['enumValue'] },
			{ label: this.RasAufzeichnungDefaultFilter_AufzeichnungenMitAkte['label'], value: this.RasAufzeichnungDefaultFilter_AufzeichnungenMitAkte['enumValue'] },
			{ label: this.RasAufzeichnungDefaultFilter_AufzeichnungenFilterAnzeigen['label'], value: this.RasAufzeichnungDefaultFilter_AufzeichnungenFilterAnzeigen['enumValue'] }
		];
	}

	getRasAufzeichnungDefaultFilterSelectionGeoOptions(): any[] {
		this.RasAufzeichnungDefaultFilterGeoOptions_Alle = {label: this.translate.instant('SETTINGS.RAS_AUFZEICHNUNG.FILTER_GEOADRESSE_ALLE'), enumValue: this.enumRasAufzeichnungDefaultFilterGeoOptions_alle};
		this.RasAufzeichnungDefaultFilterGeoOptions_MitGeo = {label: this.translate.instant('SETTINGS.RAS_AUFZEICHNUNG.FILTER_GEOADRESSE_NUR_MIT'), enumValue: this.enumRasAufzeichnungDefaultFilterGeoOptions_mit_geo};
		this.RasAufzeichnungDefaultFilterGeoOptions_OhneGeo = {label: this.translate.instant('SETTINGS.RAS_AUFZEICHNUNG.FILTER_GEOADRESSE_NUR_OHNE'), enumValue: this.enumRasAufzeichnungDefaultFilterGeoOptions_ohne_geo};
		
		return [
			{ label: this.RasAufzeichnungDefaultFilterGeoOptions_Alle['label'], value: this.RasAufzeichnungDefaultFilterGeoOptions_Alle['enumValue'] },
			{ label: this.RasAufzeichnungDefaultFilterGeoOptions_MitGeo['label'], value: this.RasAufzeichnungDefaultFilterGeoOptions_MitGeo['enumValue'] },
			{ label: this.RasAufzeichnungDefaultFilterGeoOptions_OhneGeo['label'], value: this.RasAufzeichnungDefaultFilterGeoOptions_OhneGeo['enumValue'] },
		];
	}

	safeToIntEx(val: any, defaultval: number): number {
		let result: number = defaultval;
		if(val) {
			try {
				result = this.safeToInt(val.toString(), defaultval);
			} catch {
				result = defaultval;
			}
		}
		return result;
	}

	safeToInt(val: string, defaultval: number): number {
		let result: number = defaultval;
		try {
			result = parseInt(val);
		} catch {
			result = defaultval;
		}
		return result;
	}

	getCurrentRasRecordsDefaultView(): any {
		let nDefaultView: number = this.safeToInt(localStorage.getItem('rasAufzeichnungDefaultFilter'), this.enumRasAufzeichnungDefaultFilter_KeineDaten);
		let nDefaultGeoOption: number = this.safeToInt(localStorage.getItem('rasAufzeichnungDefaultFilterGeoOptions'), this.enumRasAufzeichnungDefaultFilterGeoOptions_alle);
		return {
			optionDefaultView: nDefaultView,
			optionDefaultGeoOption: nDefaultGeoOption
		};
	}
}
