import { Injectable } from '@angular/core';
import { Base } from '@app/models/base';
import { Track } from '@app/models/reversingcadastral';

@Injectable()
export class Globals {
	log_AppName: string = 'vion-frontend';
	log_AppKey: string = 'MKXKd-=3+KxDmAlD';
	log_IP: string = '0.0.0.0';
	log_WANIP: string = '0.0.0.0';
	log_Module: string = 'frontend';
	log_Session: string = '';
	log_ClientID: string = '';
	log_Client: string = '';
	log_UserID: string = '';
	log_User: string = '';
	log_Platform: string = '';
}

export class VIONSessionStorage {
	private static instance: VIONSessionStorage;
	tracks: Track[];
	tracksFilters: any;
	tracksTimestamp: Date;
	tripReports: Base[];
	tripReportsFilters: any;
	tripReportsTimestamp: Date;
	storage = {};
	token: string;

	private constructor() { }

	/**
	 * Gibt die Instanz des temporären Speichers zurück bzw. erstellt vorher eine Neue falls nötig
	 * @returns Instanz des temporären Speichers
	 */
	public static getInstance(): VIONSessionStorage {
		if (!VIONSessionStorage.instance) {
			VIONSessionStorage.instance = new VIONSessionStorage();
		}

		const token = localStorage.getItem('jwt');
		if (token != VIONSessionStorage.instance.token) {
			VIONSessionStorage.instance = new VIONSessionStorage();
			VIONSessionStorage.instance.token = token;
		}

		return VIONSessionStorage.instance;
	}

	/**
	 * Alle Daten aus dem Temporären Speicher löschen
	 */
	public clear() {
		this.storage = {};
	}

	/**
	 * Gespeicherten Datensatz aus dem temporärem Speicher abfragen
	 * 
	 * @param key Der Key unter dem die Liste gespeichert wurde (normalerweise die ApiUrl)
	 * @returns Objekt mit dem Ladezeitpunkt, den angewendeten Ladefiltern und allen Einträgen oder null bei ungültigem key
	 */
	public get(key: string): { timestamp: Date, filters: any, entries: Base[] } {
		return this.storage[key];
	}

	/**
	 * Datensatz im temporären Speicher ablegen
	 * 
	 * @param key Der Key unter dem die Liste gespeichert wurde (normalerweise die ApiUrl)
	 * @param p_timestamp Ladezeitpunkt
	 * @param p_filters Ladefilter
	 * @param p_data Einträge
	 */
	public set(key: string, p_timestamp: Date, p_filters: any, p_data: Base[]) {
		this.storage[key] = { timestamp: p_timestamp, filters: p_filters, entries: p_data };
	}
}