export const environment = {
	// appVersion: require('../../package.json').version,
	appVersion: 'unknown',
	appRevision: 'unknown',
	production: true,
	apiUrl: 'https://api-staging.visy-gmbh.de',
	appUrl: 'https://app-staging.visy-gmbh.de',
	mapUrl: 'https://map.visy-gmbh.de/tile/{z}/{x}/{y}.png',
	logUrl: 'https://log.visy-gmbh.de:8080',
	overpassUrl: 'http://overpass.visy-gmbh.de/api'

};
