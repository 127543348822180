import { Component, OnInit } from '@angular/core';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	templateUrl: './terms.component.html',
	styleUrls: ['./style.scss']
})
export class TermsComponent implements OnInit {

	constructor(
		private breadcrumbService: BreadcrumbService,
		public translate: TranslateService,
	) {
		this.breadcrumbService.setItems([
			{ label: 'MENU.TERMS', routerLink: ['/' + PATH.TERMS] }
		]);
	}

	ngOnInit() {
	}
}
