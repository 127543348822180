import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DetailComponent } from '@app/common/templates/detail/detail.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
	templateUrl: './detail.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/detail/style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class TblOrtDetailComponent extends DetailComponent {
	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public messageService: MessageService,
		public router: Router,
		public translate: TranslateService,
	) {
		super(accountService, breadcrumbService, confirmationService, crudService, messageService, router, translate);

		this.apiUrl = 'TblOrt';
		this.name = 'MENU.ORT';
		this.url = '/' + PATH.ORT;
		this.fieldgroups.push(
			[
				{ type: 'text', key: 'ankey', required: true, width: 400 },
				{ type: 'text', key: 'postleitzahl', required: false, width: 400 },
				{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
				{ type: 'text', key: 'bezeichnung_kurz', required: false, width: 400 },
				{ type: 'text', key: 'landeskennzahl', required: false, width: 400 },
				{ type: 'object', key: 'FREMD_gebiet_bezeichnung', required: false, width: 400, url: '/' + PATH.GEBIET, id: 'gebiet_id' },
				{ type: 'object', key: 'FREMD_land_bezeichnung', required: false, width: 400, url: '/' + PATH.LAND, id: 'land_id' },
			]
		);
		this.breadcrumbService.setItems([
			{ label: 'MENU.STAMMDATEN' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.DETAIL', routerLink: [this.url + '/' + PATH.DETAIL + '/' + this.id] }
		]);
	}

	generateStreets() {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.GENERATE_STREETS'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.loading += 1;
				this.messageService.add({ severity: 'info', summary: this.translate.instant('MESSAGES.WARNING'), detail: "Dieser Vorgang kann mehrere Minuten dauern. Bitte warten...", life: 600000 });
				this.crudService.generateStreetsForVillage(this.id).then(res => {
					this.messageService.clear();
					this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
				}).catch(err => {
					this.messageService.clear();
					err.error.forEach(e => {
						if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
						}
					})
				}).finally(() => {
					this.loading -= 1;
				});
			}
		});
	}
}
