import { Component, OnInit } from '@angular/core';
import { Timespans } from '@app/models/date';
import { Module, Operation } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { DateService } from '@app/services/date.service';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	templateUrl: './filter_dialog.component.html',
	styleUrls: ['../style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class ReportsTripsFilterDialogComponent implements OnInit {
	loading = 0;
	ankey: string;
	fromDate;
	toDate;
	selectedTimespan;
	timespans = [];
	selectedTours = [];
	tours = [];
	selectedFractions = [];
	fractions = [];
	selectedVehicles = [];
	vehicles = [];
	selectedCompanies = [];
	companies = [];
	selectedDriver = [];
	selectedLoader = [];
	staff = [];
	selectedTypes = [];
	types = [];

	constructor(
		private accountService: AccountService,
		private crudService: CRUDService,
		public config: DynamicDialogConfig,
		private dateService: DateService,
		private messageService: MessageService,
		public ref: DynamicDialogRef,
		public translate: TranslateService
	) {
	}

	ngOnInit(): void {
		this.loading += 1;
		if (this.accountService.checkPermissions(Module.OnlineCollections, Operation.READ)) {
			this.loadOptions();

			this.translate.get('init').subscribe((text: string) => {
				Object.values(Timespans).filter((v) => isNaN(Number(v))).forEach((key, index) => {
					this.timespans.push(
						{ label: this.translate.instant('TIMESPANS.' + key), value: index }
					);
				});
				this.loading -= 1;
			});
		} else {
			this.loading -= 1;
		}
	}

	convertTimespanSelection(event): void {
		const timespan = this.dateService.getTimespan(event.value);
		if (timespan != null) {
			this.fromDate = timespan.fromDate;
			this.toDate = timespan.toDate;
		}
	}

	load(): void {
		this.loading += 1;
		if (this.accountService.checkPermissions(Module.Masterdata, Operation.READ)) {
			if (this.fromDate) {
				this.fromDate.setHours(0, 0, 0, 0);
			}
			if (this.toDate) {
				this.toDate.setHours(23, 59, 59, 999);
			}
			this.ref.close({
				datum_von: this.fromDate,
				datum_bis: this.toDate,
				ankey: this.ankey,
				tours: this.selectedTours.map(x => x.value),
				fractions: this.selectedFractions.map(x => x.value),
				vehicles: this.selectedVehicles.map(x => x.value),
				companies: this.selectedCompanies.map(x => x.value),
				driver: this.selectedDriver.map(x => x.value),
				loader: this.selectedLoader.map(x => x.value),
				types: this.selectedTypes.map(x => x.value),
			});
		} else {
			this.loading -= 1;
			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORS.ACCESS_DENIED'), detail: this.translate.instant('ERRORS.ACCESS_DENIED_MSG'), life: 3000 });
		}
	}

	loadOptions(): void {
		this.loading += 1;
		this.crudService.getAllTours().then(res => {
			res.forEach(x => {
				this.tours.push({ name: x.bezeichnung, value: x.ds_this_id });
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getAllFractions().then(res => {
			res.forEach(x => {
				this.fractions.push({ name: x.bezeichnung, value: x.ds_this_id });
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getAllVehicles().then(res => {
			res.forEach(x => {
				this.vehicles.push({ name: x.bezeichnung, value: x.ds_this_id });
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getAllCompanies().then(res => {
			res.forEach(x => {
				this.companies.push({ name: x.bezeichnung, value: x.ds_this_id });
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getStaff().then(res => {
			res.forEach(x => {
				this.staff.push({ name: x.bezeichnung, value: x.ds_this_id });
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getAllTripReportTypes().then(res => {
			res.forEach(x => {
				this.types.push({ name: x.bezeichnung, value: x.ds_this_id });
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	inputDate(dateField, input: string): void {
		let date = DateService.inputDateExStatic(input);
		if (date) {
			if (dateField == 'fromDate') {
				this.fromDate = date;
			} else if (dateField == 'toDate') {
				this.toDate = date;
			}
			this.selectedTimespan = -1;
		}
	}
}
