import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Globals } from '@common/global_variables';
import { environment } from '@environments/environment';
import { LogService } from "@services/log.service";
import { NetService } from "@services/net.service";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	location: Location;

	constructor(
		private globals: Globals,
		private logService: LogService,
		private netService: NetService,
	) { }

	ngOnInit() {
		if (environment.production) {
			if (location.protocol === 'http:') {
				window.location.href = location.href.replace('http:', 'https:');
			}
		}

		// update IPs
		//console.log('vion-frontend loaded ... ');
		//console.log(window.location.origin);

		this.netService.updateGlobals_IP();
		this.netService.updateGlobals_WANIP();
		this.netService.updateGlobals_PlatformInfo();

		this.globals.log_User = localStorage.getItem('username') ? localStorage.getItem('username') : this.globals.log_User;
		this.globals.log_UserID = localStorage.getItem('userID') ? localStorage.getItem('userID') : this.globals.log_UserID;
		this.globals.log_Client = localStorage.getItem('database') ? localStorage.getItem('database') : this.globals.log_Client;
		this.globals.log_Session = localStorage.getItem('jwt') ? localStorage.getItem('jwt') : this.globals.log_Session;

		// log "start" of application with a delay of 5000 seconds
		setTimeout(() => this.logService.log("Information", 1, "Frontend loaded", "Frontend is loaded and app.component.ngOnInit() the first time called"), 5000);
	}
}
