import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { tbl_fahrzeug } from '@app/models/tbl_fahrzeug';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { Arrangement, Occasion, Risk, State, Track } from '@models/reversingcadastral';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
	templateUrl: './create.component.html',
	styleUrls: ['../style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class TblRueckfahrkatasterAufzeichnungCreateComponent implements OnInit {
	arrangements: Arrangement[];
	basefields: any[];
	fieldgroups: any[];
	loading = 0;
	occasions: Occasion[];
	options: any;
	placeNames: any = {}; // Auswahloptionen für Orte
	risks: Risk[];
	states: State[];
	track: Track;
	url: string = '';
	vehicles: tbl_fahrzeug[];

	constructor(
		private breadcrumbService: BreadcrumbService,
		private crudService: CRUDService,
		private messageService: MessageService,
		private router: Router,
		public translate: TranslateService,
	) {
		this.url = '/' + PATH.RK_AUFZEICHNUNG;

		this.breadcrumbService.setItems([
			{ label: 'MENU.RUECKFAHRKATASTER', routerLink: [this.url] },
			{ label: 'BREADCRUMBS.CREATE', routerLink: [this.url + '/' + PATH.CREATE] }
		]);

		this.fieldgroups = [
			[
				{ type: 'string', key: 'name', label: 'Name' },
				{ type: 'date', key: 'aufgezeichnet', label: 'Aufzeichnung' },
				{ type: 'dropdown', key: 'fahrzeug_id', label: 'Fahrzeug' },
				{ type: 'string', key: 'freitext', label: 'Anmerkungen' },
				{ type: 'multiselect', key: 'status', label: 'Status' },
				{ type: 'dropdown', key: 'akte_id', label: 'RK Akte' },
				{ type: 'boolean', key: 'in_kartaster', label: 'In Kataster' },
			],
			[
				{ type: 'plz', key: 'plz_von', label: 'PLZ von' },
				{ type: 'village', key: 'ort_von', label: 'Ort von', plz_field: 'plz_von' },
				{ type: 'string', key: 'strasse_von', label: 'Straße von' },
				{ type: 'string', key: 'hausnummer_von', label: 'Hausnummer von' },
				{ type: 'plz', key: 'plz_bis', label: 'PLZ bis' },
				{ type: 'village', key: 'ort_bis', label: 'Ort bis', plz_field: 'plz_bis' },
				{ type: 'string', key: 'strasse_bis', label: 'Straße bis' },
				{ type: 'string', key: 'hausnummer_bis', label: 'Hausnummer bis' },
				{ type: 'number', key: 'laenge', label: 'Länge' },
				{ type: 'boolean', key: 'schmaler_350', label: 'Schmaler als 3,50m' },
				{ type: 'string', key: 'center_latitude', label: 'Latitude' },
				{ type: 'string', key: 'center_longitude', label: 'Longitude' },
			]
		];

		this.options = {
			Bewertung: [
				{ label: '', value: null, icon: 'pi pi-minus' },
				{ label: '0 von 5', value: 0, icon: '' },
				{ label: '1 von 5', value: 1, icon: 'green' },
				{ label: '2 von 5', value: 2, icon: 'lightgreen' },
				{ label: '3 von 5', value: 3, icon: 'yellow' },
				{ label: '4 von 5', value: 4, icon: 'lightcoral' },
				{ label: '5 von 5', value: 5, icon: 'red' },
			],
			rk_gefahren: [{ label: '', value: null }],
			rk_grund: [{ label: '', value: null }],
			rk_massnahmen: [{ label: '', value: null }],
			rk_status: [{ label: '', value: null }],
			fahrzeug_id: [{ label: '', value: null }],
		};
	}

	ngOnInit() {
		this.track = {};
		this.loadOptions();
	}

	datesToStrings() {
		this.fieldgroups.forEach(group => {
			group.forEach(field => {
				if (field.type === 'date' && this.track[field.key]) {
					this.track[field.key] = new Date(this.track[field.key]).toISOString();
				}
			});
		});
	}

	/**
	 * Holt alle Orte für eine PLZ
	 */
	getPlacesFromPostalCode(key) {
		this.loading += 1;
		this.crudService.getPlacesFromPostalCode(+this.track[key]).then(res => {
			this.placeNames[key] = res;
		}).catch(err => {
			//err.error.forEach(e => {
			//	if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
			//		this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
			//	} else {
			//		this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
			//	}
			//})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	loadOptions() {
		this.loading += 1;
		this.crudService.getAllRisks().then(res => {
			this.risks = res;
			this.risks.forEach(l => {
				this.options.rk_gefahren.push({ label: l.bezeichnung, value: l.bezeichnung });
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getAllOccasions().then(res => {
			this.occasions = res;
			this.occasions.forEach(l => {
				this.options.rk_grund.push({ label: l.bezeichnung, value: l.bezeichnung });
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getAllArrangements().then(res => {
			this.arrangements = res;
			this.arrangements.forEach(l => {
				this.options.rk_massnahmen.push({ label: l.bezeichnung, value: l.bezeichnung });
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getAllStates().then(res => {
			this.states = res;
			this.states.forEach(l => {
				this.options.rk_status.push({ label: l.bezeichnung, value: l.bezeichnung });
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getAllVehicles().then(res => {
			this.vehicles = res;
			this.vehicles.forEach(l => {
				if (l.kennzeichen) {
					this.options.fahrzeug_id.push({ label: l.kennzeichen, value: l.ds_this_id });
				} else {
					this.options.fahrzeug_id.push({ label: l.logbox_serial, value: l.ds_this_id });
				}
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	stringIsNullOrWhitespace(value): boolean {
		if (value !== null && value !== undefined) {
			let val = value + '';
			if (val.trim().length > 0) {
				return false;
			}
		}
		return true;
	}

	save() {
		this.loading += 1;
		if (!this.stringIsNullOrWhitespace(this.track.plz_von)) {
			this.datesToStrings();
			this.updateVehicle();
			this.crudService.createTrack(this.track).then(res => {
				this.router.navigate([this.url + '/' + PATH.LIST]);
				this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
			}).catch(err => {
				err.error.forEach(e => {
					if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
					}
				})
			}).finally(() => {
				this.loading -= 1;
			});
		} else {
			this.messageService.add({ severity: 'error', summary: this.translate.instant('MESSAGES.ERROR'), detail: this.translate.instant('MESSAGES.REQUIRED_FIELD', { field: 'PLZ' }), life: 3000 });
			this.loading -= 1;
		}
	}

	updateVehicle() {
		this.vehicles.forEach(v => {
			if (v.ds_this_id === this.track.fahrzeug_id) {
				this.track.Vehicle = v;
			}
		});
	}
}
