import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { ListComponent } from '@app/common/templates/list/list.component';
import { Module } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { ExportService } from '@app/services/export.service';
import { SettingsService } from '@app/services/settings.service';
//import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { VehicleProcessScheduleDetailComponent } from '../vehicle_detail_process_schedule/vehicle_detail_process_schedule.component';
import { VehicleProcessScheduleEditComponent } from '../vehicle_edit_process_schedule/vehicle_edit_process_schedule.component';

@Component({
	//templateUrl: '../../../../../common/templates/list/list.component.html',
	templateUrl: './vehicle_process_schedule_history_list.component.html',
	styleUrls: ['../../style.scss', '../../../../../common/templates/list/style.scss'],
	providers: [DialogService, MessageService, ConfirmationService]
})
export class VehicleProcessScheduleHistoryListComponent extends ListComponent {
	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public changeDetectorRef: ChangeDetectorRef,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public elRef: ElementRef,
		public exportService: ExportService,
		public globals: Globals,
		public messageService: MessageService,
		public router: Router,
		public settingsService: SettingsService,
		public translate: TranslateService,
		public config: DynamicDialogConfig,
		public dialogService: DialogService,
		public ref: DynamicDialogRef
	) {
		super(accountService, breadcrumbService, changeDetectorRef, confirmationService, crudService, elRef, exportService, globals, messageService, router, settingsService, translate);

		this.apiUrl = 'TblFahrzeugAbwicklungEreignisse';
		//this.name = 'MENU.KOMPONENTE';
		//this.url = '/' + PATH.KOMPONENTE;
		this.module = Module.Masterdata;

		if( this.config.data && this.config.data['dataset'])
			this.entries = this.config.data['dataset'];

		this.possibleCols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ereigniszeitpunkt_string_german', required: false, width: 200 },
			{ type: 'text', key: 'FREMD_mitarbeiter_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'erfasst_string_german', required: false, width: 200 },
			{ type: 'text', key: 'bemerkungen', required: false, width: 600 },
		];
		this.cols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ereigniszeitpunkt_string_german', required: false, width: 200 },
			{ type: 'text', key: 'FREMD_mitarbeiter_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'erfasst_string_german', required: false, width: 200 },
			{ type: 'text', key: 'bemerkungen', required: false, width: 600 },
		];

		//this.breadcrumbService.setItems([
		//	{ label: 'MENU.STAMMDATEN' },
		//	{ label: this.name, routerLink: [this.url] },
		//	{ label: 'BREADCRUMBS.LIST', routerLink: [this.url + '/' + PATH.LIST] }
		//]);
	}

	/*
	 * override
	 */
	getAllEntries() {
		//super.getAllEntries();
		// manual data only
		if( this.entries ) {
			this.processEntries(this.entries);
			this.resizeTableWidthFromContent(false);
		}
	}

	processEntries(entries: any): void {
		this.loading += 1;
		this.loadTimestamp = new Date();
		//if (!this.persistenceCheckInterval) {
		//	this.persistenceCheckInterval = setInterval(() => {
		//		this.crudService.checkPersistence(this.apiUrl, this.loadTimestamp).then(isPersistent => {
		//			if (!isPersistent) {
		//				this.messageService.add({ key: 'refresh', severity: 'warn', summary: this.translate.instant('BUTTONS.REFRESH'), detail: this.translate.instant('MESSAGES.NEW_DATA_AVAILABLE') });
		//			}
		//		}).catch(err => {
		//			err.error.forEach(e => {
		//				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
		//			})
		//		});
		//	}, 1000 * 60 * 10);
		//}
		this.possibleCols.forEach(c => {
			if (c.type == 'date') {
				entries.forEach(e => {
					if (e[c.key] != null) {
						e[c.key] = new Date(e[c.key]);
					}
				});
			}
		});
		this.entries = entries;
		this.count = this.entries.length;
		//this.storageSetEntries();
		this.loading -= 1;
	}

	/**
	 * Listeneinträge neu aus der Datenbank holen
	 */
	refresh(): void {
		//if (this.hasFilterDialog)
		//	this.getFilteredEntries(this.loadFilters);
		//else
		//	this.getAllEntries();
	}

	vehicleProcessDetailSchedule(datarow) {
		//console.log(datarow);
		if( datarow && datarow['ds_this_id'] ) {
			const ref = this.dialogService.open(VehicleProcessScheduleDetailComponent, {
				header: this.translate.instant('HEADERS.PROCESS_SCHEDULE_DETAIL'),
				width: '70%',
				data: { entryData: datarow }
			});

			ref.onClose.subscribe((needsReload) => {
				if( needsReload && needsReload === true ) {
					this.ref.close(true);
				}
			});
		}
	}

	vehicleProcessEditSchedule(datarow) {
		//console.log(datarow);
		if( datarow && datarow['ds_this_id'] ) {
			const ref = this.dialogService.open(VehicleProcessScheduleEditComponent, {
				header: this.translate.instant('HEADERS.PROCESS_SCHEDULE_EDIT'),
				width: '70%',
				data: { entryData: datarow }
			});

			ref.onClose.subscribe((needsReload) => {
				if( needsReload && needsReload === true ) {
					this.ref.close(true);
				}
			});
		}
	}

	vehicleProcessDeleteSchedule(datarow) {
		if( datarow && datarow['ds_this_id'] ) {
			this.confirmationService.confirm({
				message: this.translate.instant('CONFIRMATION.DELETE_QUESTION'),
				header: this.translate.instant('CONFIRMATION.CONFIRM'),
				icon: 'pi pi-exclamation-triangle',
				acceptLabel: this.translate.instant('CONFIRMATION.YES'),
				rejectLabel: this.translate.instant('CONFIRMATION.NO'),
				accept: () => {
					this.loading += 1;
					this.crudService.deleteVehicleProcessScheduleEntry(datarow['ds_this_id']).then(res => {
						this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });
						this.ref.close(true);
					}).catch(err => {
						err.error.forEach(e => {
							if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 3000 });
							} else {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 3000 });
							}
						})
					}).finally(() => {
						this.loading -= 1;
					});
				}
			});
		}
	}
}
