import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DetailComponent } from '@app/common/templates/detail/detail.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
	templateUrl: '../../../../common/templates/detail/detail.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/detail/style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class TblLeerungBarcodeStatusDetailComponent extends DetailComponent {
	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public messageService: MessageService,
		public router: Router,
		public translate: TranslateService,
	) {
		super(accountService, breadcrumbService, confirmationService, crudService, messageService, router, translate);

		this.apiUrl = 'TblLeerungBarcodeStatus';
		this.name = 'MENU.STATUSBARCODES';
		this.url = '/' + PATH.LEERUNG_BARCODE_STATUS;
		this.fieldgroups.push(
			[
				{ type: 'numeric', key: 'nkey', required: true, width: 400 },
				{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
				{ type: 'text', key: 'bezeichnung_kurz', required: false, width: 400 },
				{ type: 'boolean', key: 'VIRTUAL_flag_ScannungErsetztLrgStatus', required: false, width: 400 },
				{ type: 'boolean', key: 'VIRTUAL_flag_ScannungIstAbrechnungsrelevant', required: false, width: 400 },
				{ type: 'boolean', key: 'VIRTUAL_flag_ScannungMehrfacheNichtLoeschen', required: false, width: 400 },
				{ type: 'boolean', key: 'VIRTUAL_flag_ScannungVerbergenInLrgListe', required: false, width: 400 },
			]
		);
		this.breadcrumbService.setItems([
			{ label: 'MENU.STAMMDATEN' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.DETAIL', routerLink: [this.url + '/' + PATH.DETAIL + '/' + this.id] }
		]);
	}
}
