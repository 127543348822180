import { AfterViewInit, Component, OnInit, Optional, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { environment } from '@environments/environment';
import { Area, AreaPoint } from '@models/area';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { defaults as defaultControls, FullScreen, ScaleLine, ZoomSlider } from 'ol/control';
import { LineString, Point, Polygon } from 'ol/geom';
import { Collection, Feature, Map, View } from 'ol/index';
import { Translate } from 'ol/interaction';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { useGeographic } from 'ol/proj';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { ConfirmationService, MessageService, SortEvent } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';

@Component({
	templateUrl: './create.component.html',
	styleUrls: ['../style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class TblGebietCreateComponent implements OnInit, AfterViewInit {
	@ViewChild('dt', { read: '', static: true }) dt: Table;
	public area: AreaPoint[];
	private borderLayer: VectorLayer;
	private borderStyle: Style;
	public cols: any[];
	private coord = [8.0787, 50.8233];
	public filters = [];
	public loading = 0;
	private mapGebietCreate: Map;
	private mapLayer: TileLayer;
	private markerLayer: VectorLayer;
	private markerStyle: Style;
	public name: string;
	public pointCount = 0;
	private polygonLayer: VectorLayer;
	private polygonStyle: Style;
	private selectedMarkerStyle: Style;
	public selectedRow: AreaPoint;

	apiUrl: string = '';
	url: string = '';

	constructor(
		private breadcrumbService: BreadcrumbService,
		private crudService: CRUDService,
		@Optional() public ref: DynamicDialogRef,
		private messageService: MessageService,
		private router: Router,
		public translate: TranslateService,
	) {
		this.apiUrl = 'TblGebiet';
		this.name = 'MENU.GEBIET';
		this.url = '/' + PATH.GEBIET;
		if (!this.ref) {
			this.breadcrumbService.setItems([
				{ label: 'MENU.STAMMDATEN' },
				{ label: this.name, routerLink: [this.url] },
				{ label: 'BREADCRUMBS.CREATE', routerLink: [this.url + '/' + PATH.CREATE] }
			]);
		}
	}

	ngAfterViewInit() {
		this.initMap();
		(document.querySelector('.p-datatable-scrollable-body') as HTMLElement).style.maxHeight = 'calc(100vh - ' + ((localStorage.getItem('horizontalMenu') === 'true') ? '32rem' : '28rem') + ')';
		(document.querySelector('#mapGebietCreate') as HTMLElement).style.maxHeight = 'calc(100vh - ' + ((localStorage.getItem('horizontalMenu') === 'true') ? '21rem' : '17rem') + ')';
	}

	ngOnInit() {
		this.area = [];
		this.cols = [
			{ field: 'X', header: 'Longitude' },
			{ field: 'Y', header: 'Latitude' },
		];

		this.cols.forEach(col => {
			if ('subfield' in col) {
				this.filters.push(col.field + '.' + col.subfield);
			} else {
				this.filters.push(col.field);
			}
		});
	}

	centerOfPoints(points: AreaPoint[]) {
		let centerX = 0;
		let centerY = 0;

		points.forEach(p => {
			centerX += p.X;
			centerY += p.Y;
		});

		centerX /= points.length;
		centerY /= points.length;

		return [centerX, centerY];
	}

	centerOn(point: number[]) {
		this.mapGebietCreate.getView().centerOn(point, this.mapGebietCreate.getSize(), [this.mapGebietCreate.getSize()[0] / 2, this.mapGebietCreate.getSize()[1] / 2]);
	}

	deletePoint(point) {
		this.area.splice(this.area.indexOf(point), 1);
		this.redraw();
	}

	initMap() {
		this.mapLayer = new TileLayer({
			preload: Infinity,
			source: new OSM({
				url: environment.mapUrl,
				format: 'image/png',
				crossOrigin: 'anonymous'
			}),
			name: 'Map'
		});

		this.selectedMarkerStyle = new Style({
			image: new Circle({
				radius: 4,
				fill: new Fill({
					color: '#55ff55'
				}),
			})
		});
		this.markerStyle = new Style({
			image: new Circle({
				radius: 4,
				fill: new Fill({
					color: '#ff5555'
				}),
			})
		});
		this.markerLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			style: this.markerStyle,
			name: 'Marker'
		});

		this.borderStyle = new Style({
			stroke: new Stroke({
				color: '#3333ff',
				width: 4
			})
		});
		this.borderLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			opacity: 0.5,
			style: this.borderStyle,
			name: 'Border'
		});

		this.polygonStyle = new Style({
			fill: new Fill({
				color: '#ccccff'
			})
		});
		this.polygonLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			opacity: 0.5,
			style: this.polygonStyle,
			name: 'Polygon'
		});

		useGeographic();
		this.mapGebietCreate = new Map({
			controls: defaultControls({ attribution: false }).extend([new FullScreen(), new ScaleLine(), new ZoomSlider()]),
			target: 'mapGebietCreate',
			view: new View({
				center: this.coord,
				zoom: 14,
				maxZoom: 18,
			}),
			renderer: 'webgl',
			layers: [
				this.mapLayer,
				this.polygonLayer,
				this.borderLayer,
				this.markerLayer
			]
		});
		[this.mapLayer, this.markerLayer, this.borderLayer, this.polygonLayer].forEach(layer => {
			layer.getSource().on('tileloadstart', () => {
				this.loading += 1;
			});
			layer.getSource().on('tileloadend', () => {
				this.loading -= 1;
			});
			layer.getSource().on('tileloaderror', () => {
				this.loading -= 1;
			});
		});

		this.mapGebietCreate.addInteraction(new Translate({
			features: new Collection([])
		}));

		this.mapGebietCreate.on('click', (event) => {
			let clickedMarker = false;
			let clickedBorder = false;

			this.mapGebietCreate.forEachFeatureAtPixel(
				event.pixel,
				(feature, layer) => {
					if (layer.get('name') === 'Marker') {
						this.area.forEach(point => {
							if (this.area.indexOf(point) === feature.get('name')) {
								this.selectPoint(point);
								clickedMarker = true;
							}
						});
					} else if (layer.get('name') === 'Border') {
						if (!clickedMarker) {
							this.area.forEach(point => {
								if (this.area.indexOf(point) === feature.get('name')) {
									const newPoint = {
										GebietId: point.GebietId,
										Bezeichnung: point.Bezeichnung,
										X: event.coordinate[0],
										Y: event.coordinate[1]
									};
									this.area.splice(this.area.indexOf(point), 0, newPoint);
									this.redraw();
									clickedBorder = true;
								}
							});
						}
					}
				},
				{
					hitTolerance: 5
				}
			);

			if (!clickedMarker && !clickedBorder) {
				const newPoint = {
					X: event.coordinate[0],
					Y: event.coordinate[1]
				};
				if (this.area) {
					this.area.push(newPoint);
				} else {
					this.area = [newPoint];
				}
				this.redraw();
			}
		});
	}

	onFilter(event) {
		this.loading += 1;
		this.dt.filterGlobal(event.target.value, 'contains');
		setTimeout(() => {
			if (this.dt.filteredValue) {
				this.pointCount = this.dt.filteredValue.length;
			} else {
				this.pointCount = this.area.length;
			}
			this.loading -= 1;
		}, 500);
	}

	OnRowSelect(event) {
		this.selectPoint(event.data);
	}

	onSort(event: SortEvent) {
		let subfield: string;
		this.cols.forEach(col => {
			if (col.field === event.field) {
				if ('subfield' in col) {
					subfield = col.subfield;
				}
			}
		});

		event.data.sort((data1, data2) => {
			let value1 = data1[event.field];
			let value2 = data2[event.field];
			let result = null;

			if (data1[event.field] && subfield) {
				value1 = data1[event.field][subfield];
			}
			if (data2[event.field] && subfield) {
				value2 = data2[event.field][subfield];
			}

			if (value1 == null && value2 != null)
				result = -1;
			else if (value1 != null && value2 == null)
				result = 1;
			else if (value1 == null && value2 == null)
				result = 0;
			else if (typeof value1 === 'string' && typeof value2 === 'string')
				result = value1.localeCompare(value2);
			else
				result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

			return (event.order * result);
		});
	}

	redraw() {
		this.borderLayer.getSource().clear();
		this.markerLayer.getSource().clear();
		this.polygonLayer.getSource().clear();
		this.showMarkers();
		this.showPolygon();
		this.showBorders();
		this.pointCount = this.area.length;
	}

	stringIsNullOrWhitespace(value): boolean {
		if( value !== null && value !== undefined ) {
			let val = value + '';
			if( val.trim().length > 0 ) {
				return false;
			}
		}
		return true;
	}

	hasValue(val): boolean {
		if( val !== null && val !== undefined ) {
			return true;
		}
		return false;
	}

	save() {
		if (!this.stringIsNullOrWhitespace(this.name)) {
			this.loading += 1;

			const newArea: Area = {
				Id: 1,
				Bezeichnung: this.name,
				Eckpunkte: []
			};
			this.area.forEach(point => {
				if( this.hasValue(point.X) && this.hasValue(point.Y) ) {
					newArea.Eckpunkte.push([point.X, point.Y]);
				}
			});

			this.crudService.createArea(newArea).then(res => {
				if (this.ref) {
					this.ref.close(true);
				} else {
					//this.router.navigate(['/masterdata/areas']);
					if (res) {
						this.router.navigate([this.url + '/' + PATH.DETAIL + '/' + res]);
					} else {
						this.router.navigate([this.url + '/' + PATH.LIST]);
					}
				}
			}).catch(err => {
				err.error.forEach(e => {
					if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
					}
				})
			}).finally(() => {
				this.loading -= 1;
			});
		} else {
			this.messageService.add({
				severity: 'error',
				summary: this.translate.instant('MESSAGES.ERROR'),
				detail: this.translate.instant('MESSAGES.REQUIRED_FIELD', { field: 'Name' }),
				life: 3000
			});
		}
	}

	selectPoint(point) {
		this.selectedRow = point;

		this.markerLayer.getSource().getFeatures().forEach(feature => {
			if (feature.get('name') === this.area.indexOf(point)) {
				feature.setStyle(this.selectedMarkerStyle);
				const t = new Translate({
					features: new Collection([feature])
				});
				t.on('translateend', (e) => {
					e.features.forEach(f => {
						point.X = f.getGeometry().getCoordinates()[0];
						point.Y = f.getGeometry().getCoordinates()[1];
					});
					this.redraw();
				});
				this.mapGebietCreate.addInteraction(t);
			} else {
				feature.setStyle(this.markerStyle);
			}
		});
	}

	showBorders() {
		let oldMarker = this.area[this.area.length - 1];
		this.area.forEach(newMarker => {
			const feature = new Feature({
				geometry: new LineString(
					[
						[oldMarker.X, oldMarker.Y],
						[newMarker.X, newMarker.Y]
					]
				),
				name: this.area.indexOf(newMarker)
			});
			this.borderLayer.getSource().addFeature(feature);
			oldMarker = newMarker;
		});
	}

	showMarkers() {
		this.area.forEach(point => {
			point.PunktId = this.area.indexOf(point);
			const feature = new Feature({
				geometry: new Point([point.X, point.Y]),
				name: this.area.indexOf(point)
			});
			this.markerLayer.getSource().addFeature(feature);
		});
	}

	showPolygon() {
		const points = [];
		points.push([this.area[this.area.length - 1].X, this.area[this.area.length - 1].Y]);
		this.area.forEach(nextPoint => {
			points.push([nextPoint.X, nextPoint.Y]);
		});
		const feature = new Feature(new Polygon([points]));
		this.polygonLayer.getSource().addFeature(feature);
	}
}
