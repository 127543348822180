import { Component, OnInit } from '@angular/core';
import { Chassis, tbl_fahrzeug } from '@app/models/tbl_fahrzeug';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    templateUrl: './maintenanceDialog.component.html',
    styleUrls: ['../../style.scss', '../../../../../common/templates/list/style.scss'],
    providers: [MessageService, ConfirmationService]
})
export class MaintenanceDialog implements OnInit {
    loading = false;
    vehicles: tbl_fahrzeug[] = [];

    selectedVehicles: tbl_fahrzeug[] = [];
    email: string = '';
    name: string = '';
    company: string = '';
    phone: string = '';
    comment: string = '';
    addressname: string = '';
    street: string = '';
    town: string = '';

    constructor(
        public config: DynamicDialogConfig,
        public ref: DynamicDialogRef,
        public translate: TranslateService,
    ) {
    }

    ngOnInit() {
        this.vehicles = this.config.data;
        this.email = localStorage.getItem('username');
        this.company = localStorage.getItem('database');
    }

    save() {
        this.loading = true;
        let message = '';

        message += 'Firma%0A';
        message += '-----------------------%0A';
        message += (this.company ? this.company : '/') + '%0A';
        message += '%0A'
        message += 'Ansprechpartner%0A';
        message += '-----------------------%0A';
        message += (this.name ? this.name : '/') + '%0A';
        message += (this.email ? this.email : '/') + '%0A';
        message += (this.phone ? this.phone : '/') + '%0A';
        message += '%0A'
        message += 'Wartungsort%0A';
        message += '-----------------------%0A';
        message += (this.addressname ? this.addressname : '/') + '%0A';
        message += (this.street ? this.street : '/') + '%0A';
        message += (this.town ? this.town : '/') + '%0A';
        message += '%0A'
        message += 'Fahrzeuge%0A';
        message += '-----------------------%0A';
        message += (this.selectedVehicles && this.selectedVehicles.length > 0 ? this.selectedVehicles.map(v => v.fahrgestellnummer + ' | ' + v.kennzeichen).join('%0A') : '/') + '%0A';
        message += '%0A'
        message += 'Nachricht%0A';
        message += '-----------------------%0A';
        message += (this.comment ? this.comment : '/');

        window.open('mailto:support@visy-gmbh.de?subject=Wartungsanfrage&body=' + message, '_blank', 'noreferrer');
        this.ref.close();
        this.loading = false;
    }
}
