import { Component } from '@angular/core';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { GfStammdaten } from '@models/gfstammdaten';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { MessageService } from 'primeng/api';

@Component({
	templateUrl: './eswinput.component.html',
	providers: [MessageService]
})
export class EswComponent {
	dataReady = false;
	gfStammdaten: GfStammdaten;
	loading = false;

	// debug-Werte mit sinnvollen Ergebnissen:
	//filterKassenzeichen: string = '113336.200.1';
	//filterGefaessnummer: string = '31000002';
	//filterAbrechnungsjahr: number = 2021;


	//filterKassenzeichen: string = '113180.200.35';
	//filterGefaessnummer: string = '34000002';
	//filterAbrechnungsjahr: number = 2022;

	// Initialwerte normalerweise:
	filterKassenzeichen: string = '';
	filterKundennummer: string = '';
	filterGefaessnummer: string = '';
	filterAbrechnungsjahr: number = new Date().getFullYear();

	resultBody: string = '';
	// http://localhost:57368/ReportsData/GefaessStammdatenblatt/113336.200.1/31000002/ESW/2021
	constructor(
		private breadcrumbService: BreadcrumbService,
		public crudService: CRUDService,
		private messageService: MessageService,
		public translate: TranslateService
	) {
		this.breadcrumbService.setItems([
			{ label: 'REPORTS.ESWMAIN', routerLink: ['/esw'] }
		]);
	}

	fetchGfStammdaten(): void {
		this.loading = true;
		this.dataReady = false;
		this.crudService.getGfStammdaten(this.filterKassenzeichen, this.filterKundennummer, this.filterGefaessnummer, 'ESW', this.filterAbrechnungsjahr).then(res => {
			// TODO:
			//   - Datum als "nur" Datum ausgeben
			this.gfStammdaten = res;
			this.resultBody = '<h3 class="gfreport">Gefäßstammdatenblatt für das Abrechnungsjahr ' + this.filterAbrechnungsjahr + '</h3>';
			this.resultBody += '<table class="gfreportInvisible">';
			this.resultBody += '<tr><td class="gfreportLeftaligned"><b>Gefäßnummer:</b></td><td>&nbsp;&nbsp;&nbsp;&nbsp;</td><td class="gfreportRightaligned">' + this.gfStammdaten.GefaessNummer + '</td></tr>';
			//this.resultBody += '<tr><td class="gfreportLeftaligned"><b>Kunde:</b></td><td>&nbsp;&nbsp;&nbsp;&nbsp;</td><td class="gfreportRightaligned">' + this.gfStammdaten.KundeNummer + ' / ' + this.gfStammdaten.KundeBezeichnung + '</td></tr>';
			this.resultBody += '<tr><td class="gfreportLeftaligned"><b>Kundennummer:</b></td><td>&nbsp;&nbsp;&nbsp;&nbsp;</td><td class="gfreportRightaligned">' + this.gfStammdaten.KundeNummer + '</td></tr>';
			this.resultBody += '<tr><td class="gfreportLeftaligned"><b>Objekt:</b></td><td>&nbsp;&nbsp;&nbsp;&nbsp;</td><td class="gfreportRightaligned">' + this.gfStammdaten.ObjektNummer + ' / ' + this.gfStammdaten.ObjektBezeichnung + '</td></tr>';
			this.resultBody += '<tr><td class="gfreportLeftaligned"><b>Bereitgestellt am:</b></td><td>&nbsp;&nbsp;&nbsp;&nbsp;</td><td class="gfreportRightaligned">' + ((this.gfStammdaten.ObjektStelldatum == undefined || this.gfStammdaten.ObjektStelldatum == null) ? 'n/a' : this.gfStammdaten.ObjektStelldatum) + '</td></tr>';
			this.resultBody += '<tr><td class="gfreportLeftaligned"><b>Eingezogen am:</b></td><td>&nbsp;&nbsp;&nbsp;&nbsp;</td><td class="gfreportRightaligned">' + ((this.gfStammdaten.ObjektAbholdatum == undefined || this.gfStammdaten.ObjektAbholdatum == null) ? 'n/a' : this.gfStammdaten.ObjektAbholdatum) + '</td></tr>';
			this.resultBody += '<tr><td class="gfreportLeftaligned"><b>Abfallart:</b></td><td>&nbsp;&nbsp;&nbsp;&nbsp;</td><td class="gfreportRightaligned">' + this.gfStammdaten.AbfallartNummer + ' - ' + this.gfStammdaten.AbfallartBezeichnung + '</td></tr>';
			this.resultBody += '<tr><td class="gfreportLeftaligned"><b>Gefäßtyp:</b></td><td>&nbsp;&nbsp;&nbsp;&nbsp;</td><td class="gfreportRightaligned">' + this.gfStammdaten.VolumenNummer + ' - ' + this.gfStammdaten.VolumenBezeichnung + '</td></tr>';
			this.resultBody += '<tr><td class="gfreportLeftaligned"><b>Leerungen im Zeitraum:</b></td><td>&nbsp;&nbsp;&nbsp;&nbsp;</td><td class="gfreportRightaligned">' + this.gfStammdaten.AnzahlLeerungen + '</td></tr>';
			this.resultBody += '</table>';
			if (0 < this.gfStammdaten.AnzahlLeerungen) {
				this.resultBody += '<br>';
				this.resultBody += '<h4 class="gfreport">Auflistung der Leerungen</h4>';
				this.resultBody += '<table class="gfreportVisible">';
				this.resultBody += '<tr><td class="gfreportCenteraligned"><b>Lfd.</b></td><td class="gfreportCenteraligned"><b>Zeitpunkt</b></td><td class="gfreportCenteraligned"><b>Geleert?</b></td><td class="gfreportCenteraligned"><b>Status</b></td></tr>';
				this.gfStammdaten.Leerungen.forEach(e => {
					this.resultBody += '<tr><td class="gfreportCenteraligned">' + e.LfdNummer + '</td><td class="gfreportCenteraligned">&nbsp;&nbsp;&nbsp;' + e.Zeitpunkt + '&nbsp;&nbsp;&nbsp;</td><td class="gfreportCenteraligned">' + (e.Geleert ? '&nbsp;X&nbsp;' : '&nbsp;&nbsp;&nbsp;') + '</td><td class="gfreportCenteraligned">' + e.StatusText + '</td></tr>';
				});
				this.resultBody += '</table>';
			}
			this.dataReady = true;
			//this.gfStammdatenAsPdf_openWindow();
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading = false;
		});
	}

	gfStammdatenAsPdf_openWindow(): void {
		if (this.dataReady) {
			if (this.gfStammdaten != null && this.gfStammdaten != undefined) {
				this.crudService.getGfStammdatenPDF(this.gfStammdaten).then(res => {
					var blob = new Blob([res], { type: 'application/pdf' });
					var fileURL = URL.createObjectURL(blob);
					window.open(fileURL);
				});
			}
		}
	}

	gfStammdatenAsPdf_downloadFile(): void {
		if (this.dataReady) {
			if (this.gfStammdaten != null && this.gfStammdaten != undefined) {
				this.crudService.getGfStammdatenPDF(this.gfStammdaten).then(res => {
					var blob = new Blob([res], { type: 'application/pdf' });
					var fileURL = URL.createObjectURL(blob);
					var a = document.createElement('a');
					a.href = fileURL;
					a.target = '_blank';
					a.download = 'Gefäßstammdatenblatt.pdf';
					a.click();
				});
			}
		}
	}
}
