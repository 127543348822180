export enum Timestamps {
	TODAY,
	YESTERDAY,
	DAY_BEFORE_YESTERDAY,
	ONE_WEEK_AGO,
	TWO_WEEKS_AGO,
	FOUR_WEEKS_AGO
}

export enum Timespans {
	TODAY,
	YESTERDAY,
	DAY_BEFORE_YESTERDAY,
	THIS_WEEK,
	LAST_WEEK,
	WEEK_BEFORE_LAST_WEEK,
	THIS_MONTH,
	LAST_MONTH,
	JANUARY,
	FEBRUARY,
	MARCH,
	APRIL,
	MAY,
	JUNE,
	JULY,
	AUGUST,
	SEPTEMBER,
	OCTOBER,
	NOVEMBER,
	DECEMBER,
	FIRST_QUARTER,
	SECOND_QUARTER,
	THIRD_QUARTER,
	FOURTH_QUARTER,
	FIRST_SEMESTER,
	SECOND_SEMESTER,
	THIS_YEAR,
	LAST_YEAR
}