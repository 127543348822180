import { Directive, Host, Optional, Self } from '@angular/core';
import { Dropdown } from 'primeng/dropdown';

@Directive({
	selector: '[dropdownDirective]'
})
export class DropdownDirective {
	constructor(@Host() @Self() @Optional() public host: Dropdown) {
		if (this.isTouchDevice()) {
			host.onWindowResize = () => { }
		}
	}

	/**
	 * Überprüft, ob die Seite auf einem TouchDevice geöffnet wurde
	 * 
	 * @returns boolean, ob die Seite auf einem TouchDevice geöffnet wurde
	 */
	isTouchDevice(): boolean {
		return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0));
	}
}