<!-- Message -->
<p-toast>
	<ng-template let-message pTemplate="message">
		<div class="p-grid p-ml-2">
			<div class="p-col-2">
				<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
			</div>
			<div class="p-col-10">
				<h4>{{message.summary}}</h4>
			</div>
			<div class="p-col-12">
				<p>{{message.detail}}</p>
			</div>
			<div *ngIf="message.severity!='success'" class="p-col-12">
				<a class="p-button"
					href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
					Fehler melden
				</a>
			</div>
		</div>
	</ng-template>
</p-toast>

<!-- Spinner -->
<div *ngIf="loading !== 0" class="spinner">
	<span class="ball-1"></span>
	<span class="ball-2"></span>
	<span class="ball-3"></span>
	<span class="ball-4"></span>
	<span class="ball-5"></span>
	<span class="ball-6"></span>
	<span class="ball-7"></span>
	<span class="ball-8"></span>
</div>

<div class="card">
	<h5>
		{{ 'ADMIN.ORGANIZATION.REPORT_HEADER' | translate }}
	</h5>
	<p>
		{{ 'ADMIN.ORGANIZATION.HEADER_PROPERTIES' | translate }}
	</p>

	<section id="upload_section">
		<p-fileUpload mode="basic" [maxFileSize]="10485760" accept="image/png" fileLimit="1"
			chooseLabel="{{'BUTTONS.UPLOAD_PICTURE'|translate}}" (onUpload)="loadHeader()" withCredentials="true"
			[headers]="options.headers" url="{{attachUrl}}" class="p-mr-2 p-d-inline-block" auto=true>
		</p-fileUpload>
	</section>

	<!--<div *ngIf="hasHeader" class="p-mt-5">-->
	<section id="header_section" hidden="hidden">
	<div class="p-mt-5">
		<h6>
			{{ 'ADMIN.ORGANIZATION.CURRENT_HEADER' | translate }}
		</h6>
	</div>
	<img id="header_image" width="100%" alt="-/-"/>
	<div class="p-mt-5">
		<button type="button" pButton pRipple class="p-button-warning p-my-3" (click)="removeCurrentHeader()">
			{{'ADMIN.ORGANIZATION.REMOVE_CURRENT_HEADER' | translate }}
		</button>
	</div>
	</section>
</div>
<div class="card">
	<h5>
		{{ 'ADMIN.ORGANIZATION.RK_RATING_BOUNDARIES' | translate }}
	</h5>
	<div *ngFor="let rating of settings.rk_rating_boundaries | keyvalue" class="p-my-3">
		<h6 class="p-my-2">
			{{ 'ADMIN.ORGANIZATION.RK_RATING_' + rating.key | translate }}
		</h6>
		<p-inputNumber [(ngModel)]="settings.rk_rating_boundaries[rating.key]" [showButtons]="true" mode="decimal"
			locale="de-DE" [style]="{'width':'100%'}"></p-inputNumber>
	</div>

	<button type="button" pButton pRipple class="p-button-success p-my-3" (click)="saveSettings()">
		{{'BUTTONS.SAVE' | translate }}
	</button>
</div>