import { Component, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { CreateComponent } from '@app/common/templates/create/create.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TblGebietCreateComponent } from '../../tbl_gebiet/create/create.component';
import { TblLandCreateComponent } from '../../tbl_land/create/create.component';

@Component({
	templateUrl: '../../../../common/templates/create/create.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/create/style.scss'],
	providers: [DialogService, MessageService, ConfirmationService]
})
export class TblOrtCreateComponent extends CreateComponent {
	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		@Optional() public config: DynamicDialogConfig,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public dialogService: DialogService,
		public globals: Globals,
		public messageService: MessageService,
		@Optional() public ref: DynamicDialogRef,
		public router: Router,
		public translate: TranslateService,
	) {
		super(accountService, breadcrumbService, config, confirmationService, crudService, dialogService, globals, messageService, ref, router, translate);

		this.apiUrl = 'TblOrt';
		this.name = 'MENU.ORT';
		this.url = '/' + PATH.ORT;
		this.fieldgroups.push(
			[
				{ type: 'text', key: 'ankey', required: true, width: 400 },
				{ type: 'plz', key: 'postleitzahl', required: false, width: 400 },
				{ type: 'village', key: 'bezeichnung', required: true, width: 400, plz_field: 'postleitzahl' },
				{ type: 'text', key: 'bezeichnung_kurz', required: false, width: 400 },
				{ type: 'text', key: 'landeskennzahl', required: false, width: 400 },
				{ type: 'object', key: 'gebiet_id', required: false, width: 400, apiUrl: 'TblGebiet', createComponent: TblGebietCreateComponent },
				{ type: 'object', key: 'land_id', required: false, width: 400, apiUrl: 'TblLand', createComponent: TblLandCreateComponent },
			]
		);
		this.breadcrumbService.setItems([
			{ label: 'MENU.STAMMDATEN' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.CREATE', routerLink: [this.url + '/' + PATH.CREATE] }
		]);
	}
}
