import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { ConnectionSetting } from '@models/admin';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from '@services/admin.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
	templateUrl: './create.component.html',
	styleUrls: ['../style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class AdminVisyConnectionSettingsCreateComponent implements OnInit {
	basefields: any[];
	connectionSetting: ConnectionSetting;
	fieldgroups: any[];
	loading = false;

	constructor(
		private adminService: AdminService,
		private breadcrumbService: BreadcrumbService,
		private messageService: MessageService,
		private router: Router,
		public translate: TranslateService,
	) {
		this.breadcrumbService.setItems([
			{ label: 'MENU.ADMIN' },
			{ label: 'MENU.CONNECTION_SETTINGS', routerLink: ['/' + PATH.CONNECTION_SETTINGS] },
			{ label: 'BREADCRUMBS.CREATE', routerLink: ['/' + PATH.CONNECTION_SETTINGS + '/' + PATH.CREATE] }
		]);

		this.basefields = [
			{ type: 'string', key: 'SettingsName', label: 'Name' },
			{ type: 'string', key: 'ConnectionStringBusinessData', label: 'Business Data' },
			{ type: 'string', key: 'ConnectionStringTblOnline', label: 'TblOnline' },
			{ type: 'string', key: 'ConnectionStringMaptour', label: 'Maptour' },
			{ type: 'string', key: 'ConnectionStringServiceData', label: 'Service Data' },
			{ type: 'string', key: 'MandantId', label: 'Mandant Id' },
		];

		this.fieldgroups = [
			this.basefields,
		];
	}

	ngOnInit(): void {
		this.connectionSetting = {};
	}

	save(): void {
		this.loading = true;
		if (this.connectionSetting) {
			this.adminService.createConnectionSetting(this.connectionSetting).then(res => {
				this.router.navigate([PATH.CONNECTION_SETTINGS + '/' + PATH.LIST]);
				this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
			}).catch(err => {
				err.error.forEach(e => {
					if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
					}
				})
			}).finally(() => {
				this.loading = false;
			});
		} else {
			this.messageService.add({ severity: 'error', summary: this.translate.instant('MESSAGES.ERROR'), detail: this.translate.instant('MESSAGES.REQUIRED_FIELD', { field: 'Name' }), life: 3000 });
			this.loading = false;
		}
	}
}
