import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Globals } from '@common/global_variables';

@Injectable()
export class LogService {
	constructor(
		private httpClient: HttpClient,
		private globals: Globals
	) { }

	/**
	 * Logge Eintrag
	 * 
	 * @param pLevelReadable Log Level
	 * @param pEvent Log Event
	 * @param pSummary Log Titel
	 * @param pDescription Log Nachricht
	 */
	log(pLevelReadable: string, pEvent: number, pSummary: string, pDescription: string): void {
		var now = new Date();

		// fehlt: in der common/global-variables.ts m�ssen noch die User* und Client* gesetzt werden 
		const logmessage = {
			AppName: this.globals.log_AppName,
			AppKey: this.globals.log_AppKey,
			Module: this.globals.log_Module,
			IP: this.globals.log_IP,
			WANIP: this.globals.log_WANIP,
			Session: this.globals.log_Session,
			ClientID: this.globals.log_ClientID,
			Client: this.globals.log_Client,
			UserID: this.globals.log_UserID,
			User: this.globals.log_User,
			Platform: this.globals.log_Platform,
			Timestamp: now.toJSON(),
			LevelReadable: pLevelReadable,
			Summary: pSummary,
			Event: pEvent.toString(),
			Description: pDescription
		};

		if (isDevMode()) {
			console.log('LogMessage: ', logmessage);
		} else {
			const body = JSON.stringify(logmessage);
			const headers = {
				'content-type': 'application/json'
			}
			this.httpClient.post<any>('https://log.visy-gmbh.de/api/LogMessage', body, {
				headers: headers,
				observe: 'response',
				responseType: "json"
			}).subscribe(response => {
				//console.log(response);
			}, error => {
				console.log(error);
			});
		}

	}
}
