<div class="layout-topbar">
	<div class="layout-topbar-wrapper">
		<div class="layout-topbar-left">
			<div class="layout-topbar-logo-wrapper">
				<a href="#" class="layout-topbar-logo">
					<img class="logo" src="assets/images/visy/favicon.svg" />
					<span class="app-name">ViSy Online</span>
				</a>
			</div>

			<a href="#" class="topbar-menu-mobile-button"
				(click)="app.onTopbarMobileMenuButtonClick($event); app.onTopbarItemClick($event,mobileProfile);">
				<i class="pi pi-user"></i>
			</a>

			<a href="#" class="sidebar-menu-button"
				(click)="closeMobileProfile($event, mobileProfile); app.onMenuButtonClick($event);">
				<i class="pi pi-bars"></i>
			</a>
		</div>
		<div class="layout-topbar-right fadeInDown"
			[ngStyle]="{'height' : app.activeTopbarItem === mobileProfile ? '0' : '75px'}">
			<ul class="layout-topbar-actions">
				<li #profile class="topbar-item profile-item"
					[ngClass]="{'active-topmenuitem': app.activeTopbarItem === profile}">
					<a href="#" (click)="app.onTopbarItemClick($event,profile)">
						<span class="profile-image-wrapper">
							<i class="pi pi-user"></i>
						</span>
						<span class="profile-info-wrapper">
							<h3>{{username}}</h3>
							<span>{{app.userDatabaseName}}</span>
						</span>
					</a>
					<ul class="profile-item-submenu fadeInDown">
						<li class="profile-submenu-header">
							<span class="profile-image-wrapper">
								<i class="pi pi-user"></i>
							</span>
							<div class="profile">
								<h1>{{username}}</h1>
								<span>{{app.userDatabaseName}}</span>
							</div>
						</li>
						<li class="layout-submenu-footer">
							<button class="signout-button" (click)="logout()" translate>TOPBAR.LOGOUT</button>
							<a (click)="changePassword()" translate>TOPBAR.CHANGE_PASSWORD</a>
						</li>
					</ul>
				</li>
			</ul>

			<ul class="profile-mobile-wrapper" style="height:0">
				<li #mobileProfile class="topbar-item profile-item"
					[ngClass]="{'active-topmenuitem': app.activeTopbarItem === mobileProfile}">
					<ul class="fadeInDown" style="top:10px">
						<li class="profile-submenu-header">
							<span class="profile-image-wrapper">
								<i class="pi pi-user"></i>
							</span>
							<div class="profile">
								<h1>{{username}}</h1>
								<span>{{app.userDatabaseName}}</span>
							</div>
						</li>
						<li class="layout-submenu-footer">
							<button class="signout-button" (click)="logout()" translate>TOPBAR.LOGOUT</button>
							<a (click)="changePassword()" translate>TOPBAR.CHANGE_PASSWORD</a>
						</li>
					</ul>
				</li>
			</ul>
		</div>
	</div>
</div>