import { Component, OnDestroy } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { BreadcrumbService } from './breadcrumb.service';

@Component({
	selector: 'app-breadcrumb',
	templateUrl: './app.breadcrumb.component.html'
})
export class AppBreadcrumbComponent implements OnDestroy {
	items: MenuItem[];
	subscription: Subscription;

	constructor(
		public breadcrumbService: BreadcrumbService
	) {
		this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
			this.items = response;
		});
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
