<!-- Confirm -->
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

<!-- Context Menu -->
<p-contextMenu #cm [model]="contextMenu" [style]="{'width': 'auto'}"></p-contextMenu>

<!-- ui Canvas -->
<canvas hidden #emptyCanvas></canvas>

<!-- Message -->
<p-toast>
	<ng-template let-message pTemplate="message">
		<div class="p-grid p-ml-2">
			<div class="p-col-2">
				<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
			</div>
			<div class="p-col-10">
				<h4>{{message.summary}}</h4>
			</div>
			<div class="p-col-12">
				<p>{{message.detail}}</p>
			</div>
			<div *ngIf="message.severity!='success'" class="p-col-12">
				<a class="p-button"
					href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
					Fehler melden
				</a>
			</div>
		</div>
	</ng-template>
</p-toast>

<p-toast key="refresh">
	<ng-template let-message pTemplate="message">
		<div class="p-grid p-ml-2">
			<div class="p-col-2">
				<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
			</div>
			<div class="p-col-10">
				<h4>{{message.summary}}</h4>
			</div>
			<div class="p-col-12">
				<p>{{message.detail}}</p>
			</div>
			<div class="p-col-12">
				<button *ngIf="accountService.checkPermissions(4, 0)" pButton pRipple
					label="{{'BUTTONS.REFRESH'|translate}}" (click)="read()"></button>
			</div>
		</div>
	</ng-template>
</p-toast>

<p-toast key="reset">
	<ng-template let-message pTemplate="message">
		<div class="p-grid p-ml-2">
			<div class="p-col-2">
				<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
			</div>
			<div class="p-col-10">
				<h4>{{message.summary}}</h4>
			</div>
			<div class="p-col-12">
				<p>{{message.detail}}</p>
			</div>
			<div class="p-col-12">
				<button *ngIf="accountService.checkPermissions(4, 0)" pButton pRipple
					label="{{'BUTTONS.RESET'|translate}}" (click)="table.clear()"></button>
			</div>
		</div>
	</ng-template>
</p-toast>

<!-- Spinner -->
<div *ngIf="loading !== 0" class="spinner">
	<span class="ball-1"></span>
	<span class="ball-2"></span>
	<span class="ball-3"></span>
	<span class="ball-4"></span>
	<span class="ball-5"></span>
	<span class="ball-6"></span>
	<span class="ball-7"></span>
	<span class="ball-8"></span>
</div>

<!-- Toolbar -->
<div class="card p-my-0">
	<p-toolbar>
		<div class="p-toolbar-group-left">
			<a *ngIf="updatePermission" id="editbutton" [routerLink]="url + '/edit/' + id"
				class="p-button-warning p-mr-2 p-ripple p-button p-component p-button-icon-only">
				<i class="pi pi-pencil"></i>
				<span aria-hidden="true" class="p-button-label">&nbsp;</span>
			</a>
			<a *ngIf="deletePermission" id="deletebutton" (click)="deleteEntry()"
				class="p-button-danger p-mr-2 p-ripple p-button p-component p-button-icon-only">
				<i class="pi pi-trash"></i>
				<span aria-hidden="true" class="p-button-label">&nbsp;</span>
			</a>
			<button *ngIf="accountService.checkPermissions(2, 2)" type="button" pButton pRipple icon="fa fa-house"
				class="p-mx-2" pTooltip="{{'BUTTONS.GEFAESS_STELLEN' | translate }}" tooltipPosition="bottom"
				(click)="setUpGarbageCan()"></button>
			<button *ngIf="accountService.checkPermissions(2, 2)" type="button" pButton pRipple icon="fa fa-user"
				class="p-mx-2" pTooltip="{{'BUTTONS.EIGENTUEMER_ZUWEISEN' | translate }}" tooltipPosition="bottom"
				(click)="openSetEigentuemerDialog()"></button>
			<button *ngIf="accountService.checkPermissions(2, 2)" type="button" pButton pRipple icon="fa fa-house-circle-check"
				class="p-mr-2" pTooltip="{{'CONTEXT_MENU.ALLE_GEFAESSE_AUF_HALDE_STELLEN' | translate }}" tooltipPosition="bottom"
				(click)="gfAlleAufHaldeStellen()"></button>
		</div>
		<h5 class="p-m-0">{{ name | translate }}</h5>
		<div class="p-toolbar-group-right">
			<button type="button" pButton
				[icon]="state.horizontalGroups ? 'fa fa-grip-vertical' : 'fa fa-grip-horizontal'"
				class="p-button-secondary p-mx-2"
				pTooltip="{{(state.horizontalGroups ? 'BUTTONS.VERTICAL_GROUPS' : 'BUTTONS.HORIZONTAL_GROUPS') | translate }}"
				tooltipPosition="bottom" (click)="setHorizontalGroups(!state.horizontalGroups)"></button>
		</div>
	</p-toolbar>
</div>

<!-- Content -->
<div class="p-grid p-formgrid">
	<div *ngIf="entry" id="details" class="p-col-12">
		<div class="p-grid p-formgrid">
			<div *ngFor="let fields of fieldgroups"
				[ngClass]="state.horizontalGroups ? 'p-col-12  p-my-2' : ('p-col-12' + (fieldgroups.length > 1 ? ' p-md-6' : '') + (fieldgroups.length > 3 ? ' p-lg-3' : '') + ' p-my-2')">
				<div class="card" style="height:100%;">
					<div class="p-grid p-formgrid">
						<div *ngFor="let field of fields"
							[ngClass]="state.horizontalGroups ? 'p-col-12 p-md-6 p-lg-3 p-my-2' : 'p-col-12 p-my-2'">
							<h6 class="p-my-2">
								{{ 'HEADERS.' + field.key | translate }}
							</h6>
							<div *ngIf="entry[field.key] === null">
								<i class="pi pi-minus"></i>
							</div>
							<div *ngIf="entry[field.key] !== null">
								<div *ngIf="field.type == 'text'">
									{{ entry[field.key] }}
								</div>
								<div *ngIf="field.type == 'numeric'">
									{{ entry[field.key] }}
								</div>
								<div *ngIf="field.type == 'boolean'">
									<i [ngClass]="entry[field.key] ? 'pi pi-check' : 'pi pi-times'"
										[style.color]="entry[field.key] ? 'green' : 'red'"></i>
								</div>
								<div *ngIf="field.type == 'date'">
									{{ entry[field.key].toLocaleString() }}
								</div>
								<div *ngIf="field.type == 'object'">
									<a [routerLink]="field.url + '/' + url_detail + '/' + entry[field.id]">
										{{ entry[field.key] }}
									</a>
								</div>
								<div *ngIf="field.type == 'list'">
									<div *ngFor="let item of entry[field.key].split(',')">
										<a
											[routerLink]="field.url + '/' + url_detail + '/' + entry[field.id].split(',')[(entry[field.key].split(',')).indexOf(item)]">
											{{ item }}
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="p-col-12">
		<div class="card p-my-2" style="min-height: 100%">
			<!-- [stateKey]="tableStateName" stateStorage="local" -->
			<p-table #table (onColResize)="onColResize($event)" (onColReorder)="onColReorder($event)" *ngIf="visibleTable"
				[stateKey]="tableStateName" stateStorage="local"
				(onFilter)="onFilter($event)"
				(onRowSelect)="handleRowSelection($event); isMobile && cm.show($event.originalEvent); $event.originalEvent.stopPropagation();"
				[(contextMenuSelection)]="selectedEntry" [contextMenu]="cm" (onContextMenuSelect)="handleContextMenuSelect($event);"
				[(selection)]="selectedTrashbins" selectionMode="multiple"
				[globalFilterFields]="filters" [reorderableColumns]="true" [resizableColumns]="true"
				[scrollHeight]="contentHeight + 'px'" [virtualRowHeight]="45" [virtualScroll]="true"
				columnResizeMode="expand" dataKey="lfd_nummer" scrollDirection="both"
				sortField="ankey"  styleClass="p-datatable-gridlines"
				[columns]="cols" [rows]="100" [scrollable]="true" [value]="entries">
				<ng-template pTemplate="caption">
					<div class="p-d-flex p-ai-center p-jc-between">
						<div>
							<button type="button" pButton icon="pi pi-file-o" (click)="table.exportCSV()"
								class="p-my-2 p-mx-1" pTooltip="CSV" tooltipPosition="bottom"></button>
							<button type="button" pButton icon="pi pi-file-excel" (click)="exportXLSX()"
								class="p-button-success p-my-2 p-mx-1" pTooltip="XLS" tooltipPosition="bottom"></button>
							<button type="button" pButton icon="pi pi-file-pdf" (click)="exportPDF()"
								class="p-button-warning p-my-2 p-mx-1" pTooltip="PDF" tooltipPosition="bottom"></button>
						</div>
						<h5 class="p-m-0">{{ 'Historie' | translate }}</h5>
						<span class="p-input-icon-left">
							<i class="pi pi-search"></i>
							<input pInputText id="global" type="text" [value]="globalFilter"
								(input)="table.filterGlobal($event.target.value, 'contains');globalFilter = $event.target.value;"
								placeholder="{{'BUTTONS.SEARCH'|translate}}" />
						</span>
					</div>
				</ng-template>
				<ng-template pTemplate="header" let-columns>
					<tr>
						<th *ngFor="let col of columns" [style.flex]="'0 0 ' + col.width + 'px !important'"
							[pSortableColumn]="col.key" pResizableColumn pReorderableColumn>
							<div class="p-d-flex p-ai-center p-jc-between" style="min-width: 100%">
								<div>
									<p-sortIcon [field]="col.key"></p-sortIcon>
									{{ 'HEADERS.' + col.key | translate }}
								</div>
								<p-columnFilter [type]="col.type" [field]="col.key" display="menu" appendTo="body"
									[style.color]="(isColFiltered(col) ? 'var(--primary-color)' : 'var(--text-color)')">
								</p-columnFilter>
							</div>
						</th>
						<th id="buttoncolhead" [style.flex]="'0 0 ' + buttonColWidth + 'px'" alignFrozen="right"
							pFrozenColumn>
							<p-multiSelect #colselection [options]="possibleCols" [(ngModel)]="cols" dataKey="key"
								(onChange)="toggleColumn($event)" optionLabel="label" appendTo="body"
								[style.visibility]="'hidden'" [style.position]="'absolute'"
								[panelStyle]="{'min-width':'auto'}" class="colselect">
							</p-multiSelect>
							<button pButton pRipple icon="fas fa-table-columns"
								class="p-button-rounded p-button-secondary p-mr-2"
								(click)="colselection.show($event.originalEvent);"
								pTooltip="{{'BUTTONS.COL_SELECT'|translate}}" tooltipPosition="bottom"
								showDelay="500"></button>
							<button pButton pRipple icon="pi pi-filter-slash"
								class="p-button-rounded p-button-secondary p-mr-2" (click)="table.clear()"
								pTooltip="{{'BUTTONS.RESET_FILTERS'|translate}}" tooltipPosition="bottom"
								showDelay="500"></button>
							<button pButton pRipple icon="fas fa-table-cells"
								class="p-button-rounded p-button-secondary p-mr-2" (click)="resetTable()"
								pTooltip="{{'BUTTONS.RESET_TABLE'|translate}}" tooltipPosition="bottom"
								showDelay="500"></button>
						</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-entry let-rowIndex="rowIndex" let-columns="columns">
					<tr style="height:45px" [pContextMenuRow]="entry" [pSelectableRow]="entry">
						<td *ngFor="let col of columns" [style.flex]="'0 0 ' + col.width + 'px !important'">
							<div *ngIf="entry[col.key] === null">
								<i class="pi pi-minus"></i>
							</div>
							<div *ngIf="entry[col.key] !== null">
								<div *ngIf="col.type == 'text'">
									{{ entry[col.key] }}
								</div>
								<div *ngIf="col.type == 'numeric'">
									{{ entry[col.key] }}
								</div>
								<div *ngIf="col.type == 'boolean'">
									<i [ngClass]="entry[col.key] ? 'pi pi-check' : 'pi pi-times'"
										[style.color]="entry[col.key] ? 'green' : 'red'"></i>
								</div>
								<div *ngIf="col.type == 'date'">
									{{ entry[col.key].toLocaleDateString() }}
								</div>
							</div>
						</td>
						<td class="buttoncolbody" [style.flex]="'0 0 ' + buttonColWidth + 'px'" alignFrozen="right"
							pFrozenColumn>
							<a id="detailbutton" [routerLink]="url_gefaess + '/' + url_detail + '/' + entry.ds_this_id"
								class="p-button-rounded p-button-success p-mr-2 p-ripple p-button p-component p-button-icon-only">
								<i class="pi pi-search"></i>
							</a>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="summary">
					<div class="p-d-flex p-ai-center p-jc-between">
						{{ 'TABLE.COUNT' | translate : { count : count } }}
						{{ (table && table.filteredValue ? 'TABLE.TOTAL' : '') | translate : { count : (entries ?
						entries.length
						: 0) } }}
						<div>
							{{ 'TABLE.LAST_REFRESH' | translate }}: {{ loadTimestamp ?
							loadTimestamp.toLocaleString() : '' }}
						</div>
					</div>
				</ng-template>
			</p-table>
		</div>
	</div>
</div>