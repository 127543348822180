import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DetailComponent } from '@app/common/templates/detail/detail.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
	templateUrl: '../../../../common/templates/detail/detail.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/detail/style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class TblFirmaDetailComponent extends DetailComponent {
	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public messageService: MessageService,
		public router: Router,
		public translate: TranslateService,
	) {
		super(accountService, breadcrumbService, confirmationService, crudService, messageService, router, translate);

		this.apiUrl = 'TblFirma';
		this.name = 'MENU.FIRMA';
		this.url = '/' + PATH.FIRMA;
		this.fieldgroups.push(
			[
				{ type: 'text', key: 'ankey', required: true, width: 400 },
				{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
				{ type: 'text', key: 'bezeichnung_kurz', required: false, width: 400 },
				{ type: 'text', key: 'ansprechpartner', required: false, width: 400 },
				{ type: 'text', key: 'url', required: false, width: 400 },
				{ type: 'text', key: 'email', required: false, width: 400 },
				{ type: 'text', key: 'fax', required: false, width: 400 },
				{ type: 'text', key: 'telefon1', required: false, width: 400 },
				{ type: 'text', key: 'land', required: false, width: 400 },
				{ type: 'text', key: 'plz', required: false, width: 400 },
				{ type: 'text', key: 'ort', required: false, width: 400 },
				{ type: 'text', key: 'hausnummer', required: false, width: 400 },
				{ type: 'text', key: 'strasse', required: false, width: 400 },
			]
		);
		this.breadcrumbService.setItems([
			{ label: 'MENU.BHV' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.DETAIL', routerLink: [this.url + '/' + PATH.DETAIL + '/' + this.id] }
		]);
	}
}
