import { Component } from '@angular/core';
import { BaumschnittBerechtigung, BaumschnittBerechtigungErrorcodes } from '@app/models/baumschnittBerechtigung';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { MessageService } from 'primeng/api';

@Component({
	templateUrl: './esw_anmeldung_baumschnitt.component.html',
	providers: [MessageService]
})
export class EswAnmeldungBaumschnittComponent {
	dataReadyOk = false;
	dataReadyDenied = false;
	loading = false;

	// debug-Werte mit sinnvollen Ergebnissen:
	//filterKassenzeichen: string = '113336.200.1';
	//filterGefaessnummer: string = '31000002';
	//filterAbrechnungsjahr: number = 2021;


	//filterKassenzeichen: string = '113180.200.35';
	//filterGefaessnummer: string = '34000002';
	//filterAbrechnungsjahr: number = 2022;

	filterKassenzeichen: string = '';
	filterKundennummer: string = '';
	filterGefaessnummer: string = '';

	resultBodyOk: string = '';
	resultBodyDenied: string = '';

	resultBerechtigungsschein: BaumschnittBerechtigung = null;

	constructor(
		private breadcrumbService: BreadcrumbService,
		public crudService: CRUDService,
		private messageService: MessageService,
		public translate: TranslateService
	) {
		this.breadcrumbService.setItems([
			{ label: 'REPORTS.ESWANMBAUMSCHNITT_HEADER', routerLink: ['/eswanmbaumschnitt'] }
		]);
	}

	checkBerechtigungShowResult(): void {
		this.resultBerechtigungsschein = null;
		this.dataReadyOk = false;
		this.dataReadyDenied = false;
		this.resultBodyOk = '';
		this.resultBodyDenied = '';
		/*
		Prüfungskriterien:
		 - Die Kombination aus Kassenzeichen (erste 7 Stellen) und Gefäßnummer muss es geben
		 - Das Gefäß muss ein Biogefäß sein
		 - Das Gefäß muss zum Zeitpunkt der Formularübermittlung angemeldet/gestellt sein am Kassenzeichen
		*/
		let bKundennummerOk: boolean = false;
		if( this.filterKundennummer != null && this.filterKundennummer != undefined ) {
			if( this.filterKundennummer.trim().length >= 6 ) {
				bKundennummerOk = true;
			}
		}
		if(!bKundennummerOk) {
			this.messageService.add({ severity: 'error', summary: 'Input Error', detail: 'Kundennummer ungültig', life: 6000 });
		} else {
			let bGefaessnummerOk: boolean = false;
			if( this.filterGefaessnummer != null && this.filterGefaessnummer != undefined ) {
				if( this.filterGefaessnummer.trim().length > 0 ) {
					bGefaessnummerOk = true;
				}
			}
			if(!bGefaessnummerOk) {
				this.messageService.add({ severity: 'error', summary: 'Input Error', detail: 'Gefäßnummer ungültig', life: 6000 });
			} else {
				this.loading = true;
				this.crudService.checkBerechtigungBaumschnittESW(this.filterKundennummer, this.filterGefaessnummer, 'ESW').then(res => {
					if( res ) {
						if( res.Berechtigt && res.Berechtigt === true ) {
							// OK!
							this.resultBerechtigungsschein = res;
							let strGrundstueck: string = "";
							if( res.ObjektNummer && res.ObjektNummer.length > 0 )
								strGrundstueck += '(' + res.ObjektNummer + ') ';
							if( res.ObjektBezeichnung && res.ObjektBezeichnung.length > 0 )
								strGrundstueck += res.ObjektBezeichnung;

							let strDatumVon: string = "";
							if( res.BerechtigungStartdatum ) {
								let von = new Date(res.BerechtigungStartdatum); // das muss man so machen, sonst gibt jegliche toString Variante eine Exception
								try {
									strDatumVon = von.toLocaleDateString('de-DE');
								} catch {
									strDatumVon = von.toString();
									strDatumVon = strDatumVon.substring(0, 10);
								}
							}
							let strDatumBis: string = "";
							if( res.BerechtigungEnddatum ) {
								let bis = new Date(res.BerechtigungEnddatum);
								try {
									strDatumBis = bis.toLocaleDateString('de-DE');
								} catch {
									strDatumBis = bis.toString();
									strDatumBis = strDatumBis.substring(0, 10);
								}
							}

							this.resultBodyOk = '<h3 class="gfreport">Berechtigungsschein zum Anliefern von Baum- und Strauchschnitt</h3>';
							this.resultBodyOk += '<table class="gfreportInvisible">';
							this.resultBodyOk += '<tr><td class="gfreportLeftaligned"><b>für das Grundstück:</b></td><td>&nbsp;&nbsp;&nbsp;&nbsp;</td><td class="gfreportRightaligned">' + strGrundstueck + '</td></tr>';
							this.resultBodyOk += '<tr><td class="gfreportLeftaligned"><b>erstellt am:</b></td><td>&nbsp;&nbsp;&nbsp;&nbsp;</td><td class="gfreportRightaligned">' + strDatumVon + '</td></tr>';
							this.resultBodyOk += '<tr><td class="gfreportLeftaligned"><b>gültig bis zum:</b></td><td>&nbsp;&nbsp;&nbsp;&nbsp;</td><td class="gfreportRightaligned">' + strDatumBis + '</td></tr>';
							this.resultBodyOk += '</table>';
							this.resultBodyOk += '<br>';
							this.resultBodyOk += '<p>';
							this.resultBodyOk += 'Hiermit möchten wir Ihre Anmeldung bestätigen und Sie bitten diesen Berechtigungsschein als Ausdruck oder gern auch digital zum Anlieferungstermin mitzubringen, um Ihre Legitimation gegenüber unseren dort anwesenden Mitarbeiterinnen und Mitarbeitern nachzuweisen.';
							this.resultBodyOk += '</p>';
							this.resultBodyOk += '<p>';
							this.resultBodyOk += 'Die Annahmestelle finden Sie im „Mönchewinkel“ (das ist die Verbindungsstraße zwischen „Landstraße“ und „Thüringer Straße“).';
							this.resultBodyOk += '<br>';
							this.resultBodyOk += 'Bitte beachten Sie folgende Annahmebedingungen:';
							this.resultBodyOk += '<ul>';
							this.resultBodyOk += '<li>Angenommen wird nur Baum- und Strauchschnitt.</li>';
							this.resultBodyOk += '<li>Keine Annahme von Wurzeln bzw. Wurzelstöcken sowie Laub und Grasschnitt.</li>';
							this.resultBodyOk += '<li>Die Annahmetage entnehmen Sie bitte dem Eschweger Abfallkalender. Die Annahme ist ausschließlich an den dort angegebenen Tagen möglich.</li>';
							this.resultBodyOk += '<li>Annahme: Freitag, 9 bis 17 Uhr und Samstag, 9 bis 15 Uhr.</li>';
							this.resultBodyOk += '<li>Das Befahren des Grundstücks erfolgt auf eigene Gefahr.</li>';
							this.resultBodyOk += '<li>Bitte achten Sie insbesondere auf herumliegende Gegenstände.</li>';
							this.resultBodyOk += '</ul>';
							this.resultBodyOk += '</p>';

							this.resultBodyOk += '<p>';
							this.resultBodyOk += 'Baubetriebshof Kreisstadt Eschwege - Eigenbetrieb<br>';
							this.resultBodyOk += 'Georgstraße 10 | 37269 Eschwege';
							this.resultBodyOk += '</p>';
							this.resultBodyOk += '<p>';
							this.resultBodyOk += 'Telefon: +49 5651 22939-0<br>';
							this.resultBodyOk += 'Fax.: +49 5651 22939-40<br>';
							this.resultBodyOk += 'E-Mail: bbh@baubetriebshof-eschwege.de';
							this.resultBodyOk += '</p>';
							this.dataReadyOk = true;
						} else {
							// ABGELEHNT!
							this.resultBodyDenied = '<h3 class="gfreport">Abgelehnt / keine Berechtigung</h3>';
							if( res.Errorcode != null && res.Errorcode != undefined ) {
								switch(res.Errorcode) {
									case BaumschnittBerechtigungErrorcodes.cnERROR_DATABASE:
										this.resultBodyDenied += '<p>';
										this.resultBodyDenied += 'Bei der Berechtigungsprüfung ist ein Fehler in der Datenbankabfrage aufgetreten.<br>Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.';
										this.resultBodyDenied += '</p>';
										break;
    								case BaumschnittBerechtigungErrorcodes.cnERROR_GEFAESS_KUNDE_ZEITPUNKT_UNGUELTIG:
										this.resultBodyDenied += '<p>';
										this.resultBodyDenied += 'Für die eingegebenen Daten liegt aktuell kein gültiges Biomüllgefäß zur Berechtigung der Baum- und Strauchschnittabgabe vor.<br>Für weitere Rückfragen stehen wir Ihnen gerne persönlich zur Verfügung.';
										this.resultBodyDenied += '</p>';
										break;
    								case BaumschnittBerechtigungErrorcodes.cnERROR_KENNUNG:
										this.resultBodyDenied += '<p>';
										this.resultBodyDenied += 'Bei der Berechtigungsprüfung ist ein Fehler aufgetreten, ungültige Kennung!';
										this.resultBodyDenied += '</p>';
										break;
    								case BaumschnittBerechtigungErrorcodes.cnERROR_GEFAESSSTAMMDATENFEHLER:
										this.resultBodyDenied += '<p>';
										this.resultBodyDenied += 'Bei der Berechtigungsprüfung ist ein Fehler in der Datenbankabfrage aufgetreten, die Abfallartstammdaten konnten nicht abgerufen werden.<br>Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.';
										this.resultBodyDenied += '</p>';
										break;
    								case BaumschnittBerechtigungErrorcodes.cnERROR_UNKNOWN:
									default:
										this.resultBodyDenied += '<p>';
										this.resultBodyDenied += 'Bei der Berechtigungsprüfung ist ein allgemeiner Fehler aufgetreten.<br>Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.';
										this.resultBodyDenied += '</p>';
										break;
								}
							} else {
								this.resultBodyDenied += '<p>';
								this.resultBodyDenied += 'Bei der Berechtigungsprüfung ist ein allgemeiner Fehler aufgetreten.<br>Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.';
								this.resultBodyDenied += '</p>';
							}

							this.resultBodyDenied += '<p>';
							this.resultBodyDenied += 'Baubetriebshof Kreisstadt Eschwege - Eigenbetrieb<br>';
							this.resultBodyDenied += 'Georgstraße 10 | 37269 Eschwege';
							this.resultBodyDenied += '</p>';
							this.resultBodyDenied += '<p>';
							this.resultBodyDenied += 'Telefon: +49 5651 22939-0<br>';
							this.resultBodyDenied += 'Fax.: +49 5651 22939-40<br>';
							this.resultBodyDenied += 'E-Mail: bbh@baubetriebshof-eschwege.de';
							this.resultBodyDenied += '</p>';
							this.dataReadyDenied = true;
						}
						
					} else {
						this.messageService.add({ severity: 'error', summary: 'Output Error', detail: 'Antwort ungültig', life: 6000 });
					}
				}).catch(err => {
					err.error.forEach(e => {
						if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
						}
					})
				}).finally(() => {
					this.loading = false;
				});
			}
		}
	}

	strauchschnittAsPdf_openWindow(): void {
		if (this.dataReadyOk) {
			if (this.resultBerechtigungsschein != null && this.resultBerechtigungsschein != undefined) {
				this.crudService.getBaumschnittberechtigungsscheinPDF(this.resultBerechtigungsschein).then(res => {
					var blob = new Blob([res], { type: 'application/pdf' });
					var fileURL = URL.createObjectURL(blob);
					window.open(fileURL);
				});
			}
		}
	}

	strauchschnittAsPdf_downloadFile(): void {
		if (this.dataReadyOk) {
			if (this.resultBerechtigungsschein != null && this.resultBerechtigungsschein != undefined) {
				this.crudService.getBaumschnittberechtigungsscheinPDF(this.resultBerechtigungsschein).then(res => {
					var blob = new Blob([res], { type: 'application/pdf' });
					var fileURL = URL.createObjectURL(blob);
					var a = document.createElement('a');
					a.href = fileURL;
					a.target = '_blank';
					a.download = 'BaumschnittBerechtigungsschein.pdf';
					a.click();
				});
			}
		}
	}
}
