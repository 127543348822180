

export interface BaumschnittBerechtigung {
    Berechtigt?: boolean;
    ObjektNummer?: string;
    ObjektBezeichnung?: string;
    BerechtigungStartdatum?: Date;
    BerechtigungEnddatum?: Date;
    Errorcode?: number;
};

export class BaumschnittBerechtigungErrorcodes {
    public static readonly cnERROR_DATABASE: number = -1;
    public static readonly cnERROR_GEFAESS_KUNDE_ZEITPUNKT_UNGUELTIG: number = -2;
    public static readonly cnERROR_KENNUNG: number = -3;
    public static readonly cnERROR_GEFAESSSTAMMDATENFEHLER: number = -4;
    public static readonly cnERROR_UNKNOWN: number = -99;
};
