import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Base } from '@app/models/base';
import { Module, Operation } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
	selector: 'detailTemplate',
	templateUrl: './detail.component.html',
	styleUrls: ['./style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class DetailComponent implements OnInit {
	apiUrl: string = ''; // Url des API-Controllers nach 'https://localhost:44311/'
	entry: Base; // Stammdateneintrag, der geladen wird
	fieldgroups: any[] = []; // Gruppen, in die die Felder eingeteilt sind
	id: number; // ds_this_id des geladenen Stammdateneintrags
	loading: number = 0; // Ladeindikator
	name: string = ''; // Name des Stammdatentyps
	state: any; // localstorage State der Seite
	stateName: string = ''; // key des States der Seite im localstorage
	readPermission: boolean = false; // Darf der Nutzer Stammdateneinträge ansehen
	updatePermission: boolean = false; // Darf der Nutzer Stammdateneinträge bearbeiten
	deletePermission: boolean = false; // Darf der Nutzer Stammdateneinträge löschen
	url: string = ''; // Url des Stammdatentyps nach dem # 
	url_detail: string = PATH.DETAIL; // Url Zusatz für die Detail-Seite nach der url

	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public messageService: MessageService,
		public router: Router,
		public translate: TranslateService,
	) {
		const href = this.router.url.split('/');
		this.id = +href[href.length - 1];
	}

	// #region Angular

	ngOnInit(): void {
		this.loading += 1;

		// Nutzerberechtigungen abfragen
		this.readPermission = this.accountService.checkPermissions(Module.Masterdata, Operation.READ);
		this.updatePermission = this.accountService.checkPermissions(Module.Masterdata, Operation.UPDATE);
		this.deletePermission = this.accountService.checkPermissions(Module.Masterdata, Operation.DELETE);

		// Key des States auf apiUrl generieren
		this.stateName = 'state' + this.apiUrl + 'Detail';
		this.state = JSON.parse(localStorage.getItem(this.stateName));
		this.setHorizontalGroups(((this.state && this.state.horizontalGroups !== null) ? this.state.horizontalGroups : true));

		if (this.readPermission) {
			this.getEntry();
		}

		this.loading -= 1;
	}

	// #endregion Angular

	// #region CRUD

	/**
	 * Löscht den aktuellen Eintrag aus der Datenbank 
	 */
	deleteEntry(): void {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.DELETE_QUESTION'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.loading += 1;
				this.crudService.deleteEntry(this.apiUrl, this.entry.ds_this_id).then(res => {
					this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });
					this.router.navigate([this.url + '/list/']);
				}).catch(err => {
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				}).finally(() => {
					this.loading -= 1;
				});
			}
		});
	}

	/**
	 * Holt den aktuellen Eintrag aus der Datenbank
	 */
	getEntry(): void {
		this.loading += 1;
		this.crudService.getEntry(this.apiUrl, this.id).then(res => {
			this.entry = res;
			this.fieldgroups.forEach(fg => {
				fg.forEach(field => {
					if (field.type == 'date') {
						if (this.entry[field.key] != null) {
							this.entry[field.key] = new Date(this.entry[field.key]);
						}
					}
				});
			});
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	// #endregion CRUD

	// #region Spezial

	/**
	 * Schaltet die Feldgruppenanordnung auf horizontal bzw. vertikal
	 * 
	 * @param value boolean, ob die Feldgruppen horizontal angeordnet werden sollen
	 */
	setHorizontalGroups(value: boolean): void {
		this.state = JSON.parse(localStorage.getItem(this.stateName));
		if (!this.state) {
			this.state = {};
		}
		this.state.horizontalGroups = value;
		localStorage.setItem(this.stateName, JSON.stringify(this.state));
	}

	// #endregion Spezial
}
