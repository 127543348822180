import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Module, Operation } from '@app/models/permission';
import { Policy } from '@app/models/policy';
import PATH from '@assets/routes/routes.json';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AccountService } from '@services/account.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	constructor(
		private accountService: AccountService,
		private jwtHelper: JwtHelperService,
		private router: Router,
	) { }

	/**
	 * Kontrolliert, ob der Nutzer einen gültigen Zugangsberechtigung hat
	 *
	 * @param _route Aktuelle Route
	 * @param state Snapshot der Route
	 */
	async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		//console.log('authGuard called with state.url: ' + '"' + state.url + '"');
		//if( state.url === '/esw' ) {
		//	return true;
		//}

		let authorized = false;
		const token = localStorage.getItem('jwt');

		if (token && !this.jwtHelper.isTokenExpired(token)) {
			authorized = true;
		} else if (token && this.jwtHelper.isTokenExpired(token)) {
			authorized = await this.accountService.refresh().then(res => {
				localStorage.setItem('jwt', res.accessToken);
				localStorage.setItem('refreshToken', res.refreshToken);
				return true;
			}).catch(err => {
				return false;
			});
			if (!authorized) {
				this.router.navigate(['/' + PATH.LOGIN]); //, { queryParams: { returnUrl: state.url } });
				return false;
			}
		} else {
			this.router.navigate(['/' + PATH.LOGIN]); //, { queryParams: { returnUrl: state.url } });
			return false;
		}

		if (authorized) {
			authorized = await this.isAuthorized(state);
		}

		if (!authorized) {
			this.router.navigate(['/' + PATH.ACCESS_DENIED], { queryParams: { returnUrl: state.url } });
		}

		return authorized;
	}

	/**
	 * Überprüft, ob der Nutzer die Berechtigung für das Modul hat
	 * 
	 * @param state RouterState aus dem die url gezogen wird
	 * @returns boolean, ob der Nutzer Rechte für diese Seite hat
	 */
	async isAuthorized(state: RouterStateSnapshot): Promise<boolean> {
		let authorized = true;

		const token = localStorage.getItem('jwt');

		if (state && state.url && state.url.includes(PATH.ADMIN)) {
			const role = this.jwtHelper.decodeToken(token)['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
			if (state.url.includes(PATH.USERS) && !this.accountService.isAuthorized(Policy.CustomerAdmins)) {
				this.router.navigate(['/' + PATH.ACCESS_DENIED]);
				authorized = false;
			}
			if (state.url.includes(PATH.VISY_USERS) && !this.accountService.isAuthorized(Policy.ViSyAdmins)) {
				this.router.navigate(['/' + PATH.ACCESS_DENIED]);
				authorized = false;
			}
			if (state.url.includes(PATH.CONNECTION_SETTINGS) && !this.accountService.isAuthorized(Policy.ViSyAdmins)) {
				this.router.navigate(['/' + PATH.ACCESS_DENIED]);
				authorized = false;
			}
			if (state.url.includes(PATH.ORGANIZATION) && !this.accountService.isAuthorized(Policy.CustomerAdmins)) {
				this.router.navigate(['/' + PATH.ACCESS_DENIED]);
				authorized = false;
			}
		}

		let operation = Operation.READ;

		if (state.url.includes(PATH.EDIT)) {
			operation = Operation.UPDATE;
		} else if (state.url.includes(PATH.CREATE)) {
			operation = Operation.CREATE;
		}

		if (state.url.includes(PATH.ONLINE_FAHRZEUGE)) {
			authorized = this.accountService.checkPermissions(Module.OnlineVehicles, operation);
		} else if (state.url.includes(PATH.ONLINE_LEERUNGEN)) {
			authorized = this.accountService.checkPermissions(Module.OnlineCollections, operation);
		} else if (state.url.includes(PATH.BHV)) {
			authorized = this.accountService.checkPermissions(Module.Masterdata, operation);
		} else if (state.url.includes(PATH.RK)) {
			authorized = this.accountService.checkPermissions(Module.ReversingCadastral, operation);
		} else if (state.url.includes(PATH.STAMMDATEN)) {
			authorized = this.accountService.checkPermissions(Module.Masterdata, operation);
		} else if (state.url.includes(PATH.LEERUNG)) {
			authorized = this.accountService.checkPermissions(Module.OnlineCollections, operation);
		}

		return authorized;
	}
}
