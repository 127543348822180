import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

@Component({
	templateUrl: './manual.component.html',
	styleUrls: ['./style.scss'],
	providers: [MessageService]
})
export class ManualComponent implements OnInit, AfterViewChecked {
	done: boolean = false;

	constructor(
		private breadcrumbService: BreadcrumbService,
		private messageService: MessageService,
		private route: ActivatedRoute,
		public translate: TranslateService,
	) {
		this.breadcrumbService.setItems([
			{ label: 'MENU.MANUAL', routerLink: ['/' + PATH.MANUAL] }
		]);
	}

	ngOnInit() {
	}

	ngAfterViewChecked() {
		if (!this.done) {
			try {
				this.done = true;
				this.route.fragment.subscribe(fragment => {
					if (fragment) {
						if (fragment.includes('masterdata')) {
							if (fragment.includes('create')) {
								this.scrollToSection('masterdata/create');
							} else if (fragment.includes('detail')) {
								this.scrollToSection('masterdata/detail');
							} else if (fragment.includes('edit')) {
								this.scrollToSection('masterdata/edit');
							} else {
								this.scrollToSection('masterdata/list');
							}
						} else {
							this.scrollToSection(fragment);
						}
					}
				});
			} catch (e) {
				this.messageService.add({ severity: 'error', summary: 'Nicht gefunden', detail: 'Zu diesem Thema konnte kein Handbucheintrag gefunden werden.', life: 3000 });
			}
		}
	}

	scrollToSection(fragment) {
		const section = document.getElementById(fragment);
		if (section == null) {
			this.messageService.add({ severity: 'error', summary: 'Nicht gefunden', detail: 'Zu diesem Thema konnte kein Handbucheintrag gefunden werden.', life: 3000 });
		} else {
			const breadcrumb = document.getElementsByTagName('app-breadcrumb')[0] as HTMLElement;
			window.scrollTo(0, section.offsetTop - breadcrumb.offsetTop);
		}
	}
}
