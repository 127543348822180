import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { SettingsService } from '@app/services/settings.service';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { MenuService } from './app.menu.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import PATH from '@assets/routes/routes.json';

@Component({
	selector: 'app-main',
	templateUrl: './app.main.component.html',
	providers: [MessageService],
	animations: [
		trigger('mask-anim', [
			state('void', style({
				opacity: 0
			})),
			state('visible', style({
				opacity: 0.8
			})),
			transition('* => *', animate('250ms cubic-bezier(0, 0, 0.2, 1)'))
		])
	]
})
export class AppMainComponent implements OnInit, OnDestroy {
	activeTopbarItem: any;
	colors = [];
	configActive: boolean;
	configClick: boolean;
	darkMode = false;
	defaultLiveRouteColor;
	defaultOldRouteColor;
	defaultOpenVehicleDialog = false;
	defaultShowAreas = false;
	defaultShowCollections = false;
	defaultShowRoutes = false;
	defaultShowTimeline = false;
	private finishedSaving = true;
	fontSize = '14';
	forwardingColor = 'blue';
	horizontalMenu: boolean;
	restoreLastPageOnOpen: boolean = false;
	lastRouteToRestore: string = '';
	isNgOnInitDone: boolean = false;
	hqCoords = [8.0787, 50.8233];
	inputStyle = 'outlined';
	layoutColor = 'blue';
	menuClick: boolean;
	menuColor = 'layout-menu-light';
	menuColorMode = 'light';
	menuHoverActive: boolean;
	menuMobileActive: boolean;
	reversingColor = 'red';
	rightPanelActive: boolean;
	rightPanelClick: boolean;
	ripple = true;
	showClients = false;
	showFooter = true;
	sidebarActive: boolean;
	staticMenuActive: boolean;
	subscription: Subscription;
	themeColor = 'blue';
	timelineAccuracy = '100';
	topbarItemClick: boolean;
	topbarMenuActive: boolean;
	topbarMobileMenuActive: boolean;
	topbarMobileMenuClick: boolean;
	public userDatabaseName: string = '';

	constructor(
		private breadcrumbService: BreadcrumbService,
		private menuService: MenuService,
		private messageService: MessageService,
		public primeNGConfig: PrimeNGConfig,
		public renderer: Renderer2,
		private router: Router,
		private settingsService: SettingsService,
		public route: ActivatedRoute,
		public translate: TranslateService,
	) {
		this.router.events.subscribe((event) => {
			if(event instanceof NavigationStart) {
				//console.log({routerEvent: "NavigationStart", routerEventID: event.id, routerEventURL: event.url});
				this.setPreventRedirectByURL(event.url, false);
			}
			else if(event instanceof NavigationEnd) {
				//console.log({routerEvent: "NavigationEnd", routerEventID: event.id, routerEventURL: event.url, routerEventUrlAfterRedirect: event.urlAfterRedirects});
				let isReload = ((event.id === 1) && (event.url === event.urlAfterRedirects)); // bei reload ist die event.id immer 1, zählt ab dort hoch, und natürlich beide URL identisch
				this.setPreventRedirectByURL(event.urlAfterRedirects, isReload);

				if( this.isNgOnInitDone ) {
					this.setLastRouteToRestore(event.url);
				}
			}
		  });
	}

	setPreventRedirectByURL(url: string, isReload: boolean): void {
		let isTroubleSite = false;
		if( url.endsWith(PATH.ACCESS_DENIED) || url.endsWith(PATH.ERROR) || url.endsWith(PATH.NOT_FOUND) ) {
			isTroubleSite = true;
		}
		
		if( isReload || isTroubleSite ) {
			//console.log('visitedFlag or reload: is TRUE, set preventFlag in localStorage');
			localStorage.setItem('preventNextRedirectLogic', String(true));
		}
	}

	ngOnInit() {
		// das hier MUSS im constructor geschehen, damit die isReload Logik funktioniert;
		// Aber erst wenn ngOnInit aufgerufen wurde, darf die setLastRouteToRestore gespeichert werden, weil sonst immer Home gespeichert wird

		//this.router.events.subscribe((event) => {
		//	if(event instanceof NavigationStart) {
		//		//this.isReload = !this.router.navigated;
		//		//console.log('isReload: ' + this.isReload);
		//	}
		//	else if(event instanceof NavigationEnd) {
		//		this.isReload = event.url === event.urlAfterRedirects;
		//		console.log('isReload: ' + this.isReload);
		//		this.setLastRouteToRestore(event.url);
		//	}
		//  });
		// daher dieses Flag setzen
		this.isNgOnInitDone = true;

		this.colors = [
			{ name: 'Blue', file: 'blue', color: '#5e81ac' },
			{ name: 'Brown', file: 'brown', color: '#BE9B7F' },
			{ name: 'Cyan', file: 'cyan', color: '#88C0D0' },
			{ name: 'Green', file: 'green', color: '#A3BE8C' },
			{ name: 'Indigo', file: 'indigo', color: '#8886F2' },
			{ name: 'Lime', file: 'lime', color: '#B9BE7F' },
			{ name: 'Magenta', file: 'magenta', color: '#BD69AE' },
			{ name: 'Orange', file: 'orange', color: '#D08770' },
			{ name: 'Purple', file: 'purple', color: '#B48EAD' },
			{ name: 'Red', file: 'red', color: '#F28686' },
			{ name: 'Teal', file: 'teal', color: '#88D0BD' },
			{ name: 'Yellow', file: 'yellow', color: '#EBCB8B' },
		];

		this.initLayout();
		this.initLang();
		this.initLandingPageSetting();
		this.initLastRouteToRestore();

		let preventRedirectLogic = false;
		if( localStorage.getItem('preventNextRedirectLogic') && String(localStorage.getItem('preventNextRedirectLogic')) === String(true) ) {
			preventRedirectLogic = true;
		}

		//console.log('testing preventRedirect: ' + preventRedirectLogic);
		if( !preventRedirectLogic ) {
			let url = '';
			if( this.restoreLastPageOnOpen && localStorage.getItem('lastRouteToRestore') ) {
				url = localStorage.getItem('lastRouteToRestore');
				if( url.length > 0 ) {
					this.router.navigate([url]);
				}
			} else {
				this.settingsService.getSettings().then(response => {
					//console.log(response);
					if (response['landingPage']) {
						url = '/' + this.settingsService.getRouteForLandingPageAlias(response['landingPage']);
						if( url.length > 0 ) {
							this.router.navigate([url]);
						}
					}
				}).catch(() => {
				});
			}
		} else {
			localStorage.setItem('preventNextRedirectLogic', String(false));
		}
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
		// funktioniert nicht beim Schließen des Browsers
		//console.log('foo');
		//this.setLastRouteToRestore();
	}

	changeStyleSheet(id, module, mode, color) {
		/* const element = document.getElementById(id);
		const urlTokens = element.getAttribute('href').split('/');
		urlTokens[urlTokens.length - 2] = color;
		urlTokens[urlTokens.length - 1] = mode + '.css';
		const newURL = urlTokens.join('/');

		console.log(element, newURL);
		this.replaceLink(element, newURL); */

		const head = document.getElementsByTagName('head')[0];

		let themeLink = document.getElementById(id) as HTMLLinkElement;
		if (themeLink) {
			themeLink.href = `assets/${module}/${color}/${mode}.css`;
		} else {
			const style = document.createElement('link');
			style.id = id;
			style.rel = 'stylesheet';
			style.type = 'text/css';
			style.href = `assets/${module}/${color}/${mode}.css`;
			head.appendChild(style);
		}
	}

	initLang(): void {
		this.translate.addLangs(['en', 'de']);
		this.translate.setDefaultLang('de');

		const browserLang = this.translate.getBrowserLang();
		let lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : (browserLang.match(/en|de/) ? browserLang : 'de');
		if (lang) {
			this.translate.use(lang);

		} else {
			this.translate.use(this.translate.getDefaultLang());
		}

		this.subscription = this.translate.stream('PRIMENG').subscribe(data => {
			this.primeNGConfig.setTranslation(data);
		});

		this.breadcrumbService.setItems(this.breadcrumbService.getItems());
	}

	initLayout(): void {
		this.inputStyle = localStorage.getItem('inputStyle') ? localStorage.getItem('inputStyle') : this.inputStyle;
		this.fontSize = localStorage.getItem('fontSize') ? localStorage.getItem('fontSize') : this.fontSize;

		this.horizontalMenu = (localStorage.getItem('horizontalMenu') === 'true');
		this.staticMenuActive = (localStorage.getItem('staticMenuActive') === 'true');

		this.forwardingColor = localStorage.getItem('forwardingColor') ? localStorage.getItem('forwardingColor') : this.forwardingColor;
		this.reversingColor = localStorage.getItem('reversingColor') ? localStorage.getItem('reversingColor') : this.reversingColor;

		this.defaultLiveRouteColor = localStorage.getItem('defaultLiveRouteColor') ? localStorage.getItem('defaultLiveRouteColor') : this.defaultLiveRouteColor;
		this.defaultOldRouteColor = localStorage.getItem('defaultOldRouteColor') ? localStorage.getItem('defaultOldRouteColor') : this.defaultOldRouteColor;
		this.defaultShowRoutes = (localStorage.getItem('defaultShowRoutes') === 'true');
		this.showClients = (localStorage.getItem('showClients') === 'true');
		this.showFooter = localStorage.getItem('showFooter') ? (localStorage.getItem('showFooter') === 'true') : true;
		this.defaultShowAreas = (localStorage.getItem('defaultShowAreas') === 'true');
		this.defaultShowTimeline = (localStorage.getItem('defaultShowTimeline') === 'true');
		this.defaultShowCollections = (localStorage.getItem('defaultShowCollections') === 'true');
		this.timelineAccuracy = localStorage.getItem('timelineAccuracy') ? localStorage.getItem('timelineAccuracy') : this.timelineAccuracy;
		this.defaultOpenVehicleDialog = (localStorage.getItem('defaultOpenVehicleDialog') === 'true');

		this.darkMode = (localStorage.getItem('darkMode') === 'true');
		this.menuColorMode = (this.darkMode ? 'dark' : 'light');
		this.themeColor = localStorage.getItem('themeColor') ? localStorage.getItem('themeColor') : this.colors[0].file;
		this.layoutColor = this.themeColor;
		this.menuColor = 'layout-menu-' + this.menuColorMode;
		this.changeStyleSheet('layout-css', 'layout/css', 'layout-' + (this.darkMode ? 'dark' : 'light'), this.layoutColor);
		this.changeStyleSheet('theme-css', 'theme', 'theme-' + (this.darkMode ? 'dark' : 'light'), this.themeColor);
	}

	initLandingPageSetting(): void {
		this.restoreLastPageOnOpen = (localStorage.getItem('restoreLastPageOnOpen') === 'true');
		//console.log('initLayout value restoreLastPageOnOpen: ' + this.restoreLastPageOnOpen);
	}

	initLastRouteToRestore(): void {
		this.lastRouteToRestore = (localStorage.getItem('lastRouteToRestore'));
		//console.log('initLayout value lastRouteToRestore: ' + this.lastRouteToRestore);
	}

	setLastRouteToRestore(val: string): void {
		localStorage.setItem('lastRouteToRestore', val);
		//console.log(this.restoreLastPageOnOpen ? 'restore ON' : 'restore OFF');
		//console.log('restore-URL: \'' + localStorage.getItem('lastRouteToRestore') + '\'');
	}

	isDesktop() {
		return window.innerWidth > 991;
	}

	isHorizontal() {
		return this.horizontalMenu === true;
	}

	isIE() {
		return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
	}

	isMobile() {
		return window.innerWidth <= 1199;
	}

	onConfigClick(event) {
		this.configClick = true;
	}

	onLayoutClick() {
		if (!this.topbarItemClick) {
			this.activeTopbarItem = null;
			this.topbarMenuActive = false;
		}

		if (!this.rightPanelClick) {
			this.rightPanelActive = false;
		}

		if (!this.menuClick) {
			if (this.isHorizontal()) {
				this.menuService.reset();
			}

			if (this.menuMobileActive) {
				this.menuMobileActive = false;
			}

			this.menuHoverActive = false;
		}

		if (this.configActive && !this.configClick) {
			this.configActive = false;
		}

		this.configClick = false;
		this.menuClick = false;
		this.topbarItemClick = false;
		this.rightPanelClick = false;
	}

	onMenuButtonClick(event) {
		this.menuClick = true;
		this.topbarMenuActive = false;

		if (this.isMobile()) {
			this.menuMobileActive = !this.menuMobileActive;
		}

		event.preventDefault();
	}

	onRightPanelButtonClick(event) {
		this.rightPanelClick = true;
		this.rightPanelActive = !this.rightPanelActive;

		event.preventDefault();
	}

	onRightPanelClose(event) {
		this.rightPanelActive = false;
		this.rightPanelClick = false;

		event.preventDefault();
	}

	onRightPanelClick(event) {
		this.rightPanelClick = true;

		event.preventDefault();
	}

	onRippleChange(event) {
		this.ripple = event.checked;
	}

	onSidebarClick(event: Event) {
		this.menuClick = true;
	}

	onToggleMenuClick(event: Event) {
		this.staticMenuActive = !this.staticMenuActive;
		localStorage.setItem('staticMenuActive', String(this.staticMenuActive));
		this.sendSetting({ Name: 'staticMenuActive', Value: this.staticMenuActive });
		event.preventDefault();
	}

	onTopbarItemClick(event, item) {
		this.topbarItemClick = true;

		if (this.activeTopbarItem === item) {
			this.activeTopbarItem = null;
		} else {
			this.activeTopbarItem = item;
		}

		event.preventDefault();
	}

	onTopbarMobileMenuButtonClick(event) {
		this.topbarMobileMenuClick = true;
		this.topbarMobileMenuActive = !this.topbarMobileMenuActive;

		event.preventDefault();
	}

	replaceLink(linkElement, href) {
		if (this.isIE()) {
			linkElement.setAttribute('href', href);
		}
		else {
			const id = linkElement.getAttribute('id');
			const cloneLinkElement = linkElement.cloneNode(true);

			cloneLinkElement.setAttribute('href', href);
			cloneLinkElement.setAttribute('id', id + '-clone');

			linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

			cloneLinkElement.addEventListener('load', () => {
				linkElement.remove();
				cloneLinkElement.setAttribute('id', id);
			});
		}
	}

	sendSetting(setting) {
		this.settingsService.editSetting(setting).then(res => {
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		});
	}
}
