import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DetailComponent } from '@app/common/templates/detail/detail.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { VehicleProcessScheduleEditComponent } from '../vehicle_edit_process_schedule/vehicle_edit_process_schedule.component';

@Component({
	//templateUrl: '../../../../../common/templates/detail/detail.component.html',
	templateUrl: './vehicle_detail_process_schedule.component.html',
	styleUrls: ['../../style.scss', '../../../../../common/templates/detail/style.scss'],
	providers: [DialogService, MessageService, ConfirmationService]
})
export class VehicleProcessScheduleDetailComponent extends DetailComponent {
	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public messageService: MessageService,
		public router: Router,
		public translate: TranslateService,
		public config: DynamicDialogConfig,
		public ref: DynamicDialogRef,
		public dialogService: DialogService,
	) {
		super(accountService, breadcrumbService, confirmationService, crudService, messageService, router, translate);

		this.apiUrl = 'TblFahrzeugAbwicklungEreignisse';
		//this.name = 'MENU.ANREDE';
		//this.url = '/' + PATH.ANREDE;
		
		if( this.config.data && this.config.data['entryData'])
			this.entry = this.config.data['entryData'];

		this.fieldgroups.push(
			[
				{ type: 'text', key: 'ereigniszeitpunkt_string_german', required: false, width: 400 },
				{ type: 'text', key: 'FREMD_mitarbeiter_bezeichnung', required: false, width: 400 },
				{ type: 'text', key: 'erfasst_string_german', required: false, width: 400 },
				{ type: 'text', key: 'bemerkungen', required: false, width: 400 },
			]
		);
		//this.breadcrumbService.setItems([
		//	{ label: 'MENU.STAMMDATEN' },
		//	{ label: this.name, routerLink: [this.url] },
		//	{ label: 'BREADCRUMBS.DETAIL', routerLink: [this.url + '/' + PATH.DETAIL + '/' + this.id] }
		//]);
	}

	/**
	 * Löscht den aktuellen Eintrag aus der Datenbank 
	 */
	deleteEntry(): void {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.DELETE_QUESTION'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.loading += 1;
				this.crudService.deleteEntry(this.apiUrl, this.entry.ds_this_id).then(res => {
					this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });
					this.ref.close(true);
				}).catch(err => {
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				}).finally(() => {
					this.loading -= 1;
				});
			}
		});
	}

	editEntry() {
		if( this.entry && this.entry['ds_this_id'] ) {
			const ref = this.dialogService.open(VehicleProcessScheduleEditComponent, {
				header: this.translate.instant('HEADERS.PROCESS_SCHEDULE_EDIT'),
				width: '70%',
				data: { entryData: this.entry }
			});

			ref.onClose.subscribe((needsReload) => {
				if( needsReload && needsReload === true ) {
					this.ref.close(true);
				}
			});
		}
	}

	/**
	 * Holt den aktuellen Eintrag aus der Datenbank
	 */
	getEntry(): void {
		// gibts nicht, wird eingefüttert (nur popup mit dialogservice)

		//this.loading += 1;
		//this.crudService.getEntry(this.apiUrl, this.id).then(res => {
		//	this.entry = res;
		//	this.fieldgroups.forEach(fg => {
		//		fg.forEach(field => {
		//			if (field.type == 'date') {
		//				if (this.entry[field.key] != null) {
		//					this.entry[field.key] = new Date(this.entry[field.key]);
		//				}
		//			}
		//		});
		//	});
		//}).catch(err => {
		//	err.error.forEach(e => {
		//		this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
		//	})
		//}).finally(() => {
		//	this.loading -= 1;
		//});
	}
}
