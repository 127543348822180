import { Component, OnInit } from '@angular/core';
import { TestUserCreate, TestUserResult } from '@app/models/admin';
import { DateService } from '@app/services/date.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from '@services/admin.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
	templateUrl: './create.component.html',
	styleUrls: ['../style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class AdminVisyUsersCreateTestuserComponent implements OnInit {
	basefields: any[];
	createResult: TestUserResult;
	createData: TestUserCreate;
	fieldgroups: any[];
	loading = 0;
	options: any;
	outputfieldgroup: any[];
	outputfields: any[];

	constructor(
		private adminService: AdminService,
		private breadcrumbService: BreadcrumbService,
		private messageService: MessageService,
		public translate: TranslateService,
	) {
		this.breadcrumbService.setItems([
			{ label: 'MENU.ADMIN' },
			{ label: 'MENU.NUTZER', routerLink: ['/' + PATH.VISY_USERS] },
			{ label: 'BREADCRUMBS.CREATE', routerLink: ['/' + PATH.TEST_USER] }
		]);

		this.basefields = [
			{ type: 'string', key: 'UsernameProposal', label: 'Benutzername:', placeholder: '(optional, Format: E-Mail)' },
			{ type: 'string', key: 'Kennzeichen', label: 'Kennzeichen:', placeholder: '(optional)' },
			{ type: 'string', key: 'LogboxId', label: 'Logbox-Id(s):', placeholder: '(Pflichtfeld)' },
			{ type: 'date', key: 'ValidFrom', label: 'gültig von:', placeholder: '(Pflichtfeld)' },
			{ type: 'date', key: 'ValidTo', label: 'gültig bis:', placeholder: '(Pflichtfeld)' },
		];

		this.fieldgroups = [
			this.basefields,
		];

		this.outputfields = [
			{ type: 'string', key: 'LoginName', label: 'Username:' },
			{ type: 'string', key: 'LoginPassword', label: 'Password:' },
		];

		this.outputfieldgroup = [
			this.outputfields,
		];

		this.options = {
			UserConnectionSettings: null,
			VehicleRolesList: null
		};
	}

	ngOnInit(): void {
		this.createResult = {
			LoginName: '',
			LoginPassword: '',
			FahrzeugeOk: false,
			ResultValid: false
		};
		this.createData = {
			ValidFrom: new Date(),
			ValidTo: new Date(),
			Kennzeichen: '',
			UsernameProposal: ''
		};
		//this.createData.ValidTo.setDate(this.createData.ValidTo.getDate() + 30);
		this.createData.ValidTo.setMonth(this.createData.ValidTo.getMonth() + 1);
	}

	createTrial(): void {
		//console.log('function call: createTrial()');
		this.createResult.ResultValid = false;
		this.createResult.LoginName = '';
		this.createResult.LoginPassword = '';
		this.createResult.FahrzeugeOk = false;
		this.loading += 1;

		if (this.createData.ValidFrom != null && this.createData.ValidTo != null && this.createData.LogboxId != null && this.createData.LogboxId.trim().length > 0) {
			this.adminService.createTestUser(this.createData).then(res => {
				this.createData.Kennzeichen = '';
				this.createData.LogboxId = '';
				this.createResult = res;
				this.createResult.ResultValid = true;
				this.messageService.add({
					severity: 'success',
					summary: this.translate.instant('MESSAGES.SUCCESSFUL'),
					detail: this.translate.instant('MESSAGES.SAVED'),
					life: 3000
				});
			}).catch(err => {
				err.error.forEach(e => {
					if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
					}
				})
			}).finally(() => {
				this.loading -= 1;
			});
		} else {
			this.messageService.add({
				severity: 'error',
				summary: this.translate.instant('MESSAGES.ERROR'),
				detail: this.translate.instant('MESSAGES.REQUIRED_FIELD', 'ValidFrom, ValidTo, LogboxId required'),
				life: 3000
			});
			this.loading -= 1;
		}
	}

	inputDate(dateField, input: string): void {
		let date = DateService.inputDateExStatic(input);
		if (date) {
			this.setDateInput(date, dateField);
		}
	}

	setDateInput(p_event, p_key): void {
		//console.log('enter setDateInput...');
		//console.log('setDateInput: key=' + p_key + '; event_value=' + p_event.toString() + ';');
		this.createData[p_key] = new Date(p_event);
		this.createData[p_key].setHours(12);
		//console.log('resulting in: \'' + this.createData[p_key].toString() + '\'');
	}
}
