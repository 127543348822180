import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Operation } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import PATH from '@assets/routes/routes.json';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppMainComponent } from './app.main.component';
import { MenuService } from './app.menu.service';

@Component({
	selector: '[app-menuitem]',
	templateUrl: './app.menuitem.component.html',
	host: {
		'[class.active-menuitem]': 'active'
	},
	animations: [
		trigger('children', [
			state('void', style({
				height: '0px'
			})),
			state('hiddenAnimated', style({
				height: '0px'
			})),
			state('visibleAnimated', style({
				height: '*'
			})),
			state('visible', style({
				height: '*',
				'z-index': 100
			})),
			state('hidden', style({
				height: '0px',
				'z-index': '*'
			})),
			transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
			transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
			transition('void => visibleAnimated, visibleAnimated => void',
				animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
		])
	]
})
export class AppMenuitemComponent implements OnInit, OnDestroy {
	@Input() index: number;
	@Input() item: any;
	@Input() parentKey: string;
	@Input() root: boolean;
	active = false;
	key: string;
	menuSourceSubscription: Subscription;
	menuResetSubscription: Subscription;

	constructor(
		private accountService: AccountService,
		public app: AppMainComponent,
		private jwtHelper: JwtHelperService,
		private menuService: MenuService,
		public router: Router,
	) {
		this.menuSourceSubscription = this.menuService.menuSource$.subscribe(key => {
			if (this.active && this.key !== key && key.indexOf(this.key) !== 0) {
				this.active = false;
			}
		});

		this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
			this.active = false;
		});

		this.router.events.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(params => {
				if (this.app.isHorizontal()) {
					this.active = false;
				} else {
					if (this.item.routerLink) {
						this.updateActiveStateFromRoute();
					} else {
						this.active = false;
					}
				}
			});
	}

	ngOnInit() {
		if (!this.app.isHorizontal() && this.item.routerLink) {
			this.updateActiveStateFromRoute();
		}

		this.key = this.parentKey ? this.parentKey + '-' + this.index : String(this.index);
	}

	ngOnDestroy() {
		if (this.menuSourceSubscription) {
			this.menuSourceSubscription.unsubscribe();
		}

		if (this.menuResetSubscription) {
			this.menuResetSubscription.unsubscribe();
		}
	}

	authorized(item) {
		if (item.module != null) {
			if (!this.accountService.checkPermissions(item.module, Operation.READ)) {
				return false;
			}
		}
		const role = this.jwtHelper.decodeToken(localStorage.getItem('jwt'))['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
		if (role === 'ViSyAdministrator') {
			return true;
		}
		if (item.routerLink[0].includes(PATH.ADMIN)) {
			if (item.routerLink[0].includes(PATH.VISY)) {
				return false;
			}

			if (role === 'CustomerAdministrator') {
				return true;
			}
			return false;
		}
		return true;
	}

	isEmptyParent(item) {
		let result = true;
		if (item.items != null) {
			item.items.forEach(i => {
				if (i.module != null) {
					if (this.accountService.checkPermissions(i.module, Operation.READ)) {
						result = false;
					}
				} else {
					result = false;
				}
			});
		} else {
			return false;
		}
		return result;
	}

	itemClick(event: Event) {
		// avoid processing disabled items
		if (this.item.disabled) {
			event.preventDefault();
			return true;
		}

		// navigate with hover in horizontal mode
		if (this.root) {
			this.app.menuHoverActive = !this.app.menuHoverActive;
		}

		// notify other items
		this.menuService.onMenuStateChange(this.key);

		// execute command
		if (this.item.command) {
			this.item.command({ originalEvent: event, item: this.item });
		}

		// toggle active state
		if (this.item.items) {
			this.active = !this.active;
		} else {
			// activate item
			this.active = true;

			// hide overlay menus
			if (this.app.isMobile()) {
				this.app.sidebarActive = false;
				this.app.menuMobileActive = false;
			}

			// reset horizontal menu
			if (this.app.isHorizontal()) {
				this.menuService.reset();
			}
		}
	}

	onMouseEnter() {
		// activate item on hover
		if (this.root && this.app.menuHoverActive && this.app.isHorizontal() && this.app.isDesktop()) {
			this.menuService.onMenuStateChange(this.key);
			this.active = true;
		}
	}

	updateActiveStateFromRoute() {
		//this.active = this.router.isActive(this.item.routerLink[0], this.item.items ? false : true);
		//this.active = this.router.isActive(this.item.routerLink[0], false);

		// 2022-08-04 mmathofer: mal hiermit herumprobieren wegen VION-374
		//this.active = this.router.isActive(this.item.routerLink[0], {paths: 'subset', matrixParams: 'ignored', queryParams: 'ignored', fragment: 'ignored'});

		// Klappt auch nicht; was klappt: komplett weglassen. Denn der active-state wird bereits im itemClick korrekt gesetzt, hier dann wegen fehlschlagendens .isActive wieder zurückgesetzt
		// Nachteil des Weglassens: Beim Reload der Seite (F5, oder STRG+R) wird der Active-State gelöscht und das Menü deckt sich nicht mit der aktiven Seite. Das ist aber das kleinere Übel.

		// TODO das dynamische Abfragen des active state auf andere Art selbst implementieren.
	}
}
