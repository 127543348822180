<p-toast>
	<ng-template let-message pTemplate="message">
		<div class="p-grid p-ml-2">
			<div class="p-col-2">
				<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
			</div>
			<div class="p-col-10">
				<h4>{{message.summary}}</h4>
			</div>
			<div class="p-col-12">
				<p>{{message.detail}}</p>
			</div>
			<div *ngIf="message.severity!='success'" class="p-col-12">
				<a class="p-button"
					href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
					Fehler melden
				</a>
			</div>
		</div>
	</ng-template>
</p-toast>

<!-- Spinner -->
<div *ngIf="loading!==0" class="spinner">
	<span class="ball-1"></span>
	<span class="ball-2"></span>
	<span class="ball-3"></span>
	<span class="ball-4"></span>
	<span class="ball-5"></span>
	<span class="ball-6"></span>
	<span class="ball-7"></span>
	<span class="ball-8"></span>
</div>


<div class="p-grid p-formgrid">
	<div class="p-col-12">
		<h6 class="p-mt-2 p-mb-2">UserName</h6>
		{{ vtools['UserName'] }}
	</div>
	<div class="p-col-12">
		<h6 class="p-mt-4 p-mb-2">UserConnectionSettingId</h6>
		{{ vtools['UserConnectionSettingsId'] }}
	</div>
	<div class="p-col-12">
		<h6 class="p-mt-4 p-mb-2">Passwort</h6>
		<input type="text" pInputText placeholder="Passwort" (input)="vtools['Password']=$event.target.value">
	</div>
	<div class="p-col-12">
		<button pButton pRipple label="{{'Speichern'| translate }}" class="p-button p-my-5" (click)="create()"></button>
	</div>
</div>