<div class="p-grid">
    <div class="p-col-12">
        <div class="card">
            <h1>
                Datenschutzvereinbarung
            </h1>

            <span>
                Wir haben diese Datenschutzerklärung (Fassung 22.01.2021) verfasst, um Ihnen gemäß der
                Vorgaben der Datenschutz-Grundverordnung (EU) 2016/679 zu erklären, welche Informationen wir sammeln und
                wie wir Daten verwenden.
            </span>

            <h3>
                Verantwortlicher
            </h3>
            <span>
                ViSy GmbH
                <br />
                Albert-Schweitzer-Str. 5
                <br />
                64807 Dieburg
                <br />
                E-Mailadresse: info@visy-gmbh.de
                <br />
                Geschäftsführer: Markus Fick & Enrico Skrypzak
                <br />
            </span>

            <h3>
                Automatische Datenspeicherung
            </h3>
            <span>
                Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und
                gespeichert, so auch auf dieser Webseite.
                <br /><br />
                Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf dem diese
                Webseite gespeichert ist) automatisch Daten wie die Adresse (URL) der aufgerufenen Webseite Browser und
                Browserversion das verwendete Betriebssystem die Adresse (URL) der zuvor besuchten Seite (Referrer URL)
                den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird Datum und Uhrzeit in Dateien
                (Webserver-Logfiles).
                <br /><br />
                In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gelöscht. Wir
                geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von
                rechtswidrigem Verhalten eingesehen werden.
            </span>

            <h3>
                Cookies
            </h3>
            <span>
                Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu speichern. Im Folgenden erklären
                wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende Datenschutzerklärung besser
                verstehen.
            </span>

            <h5>
                Was genau sind Cookies?
            </h5>
            <span>
                Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind
                beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Webseiten
                speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.
                <br /><br />
                Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Webseiten
                verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere
                Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer
                gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem “Hirn” Ihres
                Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines
                Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.
                <br /><br />
                Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche
                Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“
                Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet
                Ihnen Ihre gewohnte Standardeinstellung. In einigen Browsern hat jedes Cookie eine eigene Datei, in
                anderen wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.
                <br /><br />
                Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt
                von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Webseiten (z.B. Google Analytics)
                erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die
                Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine
                Software-Programme und enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch
                nicht auf Informationen Ihres PCs zugreifen.
            </span>

            <h6>
                So können zum Beispiel Cookie-Daten aussehen:
            </h6>

            <ul>
                Name: _ga<br />
                Ablaufzeit: 2 Jahre<br />
                Verwendung: Unterscheidung der Webseitenbesucher<br />
                Beispielhafter Wert: GA1.2.1326744211.152311253252
            </ul>

            <h6>
                Ein Browser sollte folgende Mindestgrößen unterstützen:
            </h6>

            <ul>
                Ein Cookie soll mindestens 4096 Bytes enthalten können<br />
                Pro Domain sollen mindestens 50 Cookies gespeichert werden können<br />
                Insgesamt sollen mindestens 3000 Cookies gespeichert werden können
            </ul>

            <h5>
                Welche Arten von Cookies gibt es?
            </h5>
            <span>
                Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in
                der folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die
                verschiedenen Arten von HTTP-Cookies eingehen.
            </span>

            <h6>
                Man kann 4 Arten von Cookies unterscheiden:
            </h6>

            <h6>
                Unbedingt notwendige Cookies
            </h6>
            <span>
                Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel braucht
                es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft
                und später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der
                User sein Browserfenster schließt.
            </span>

            <h6>
                Funktionelle Cookies
            </h6>
            <span>
                Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt.
                Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen
                Browsern gemessen.
            </span>

            <h6>
                Zielorientierte Cookies
            </h6>
            <span>
                Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte,
                Schriftgrößen oder Formulardaten gespeichert.
            </span>

            <h6>
                Werbe-Cookies
            </h6>
            <span>
                Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste
                Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.
            </span>

            <span>
                Üblicherweise werden Sie beim erstmaligen Besuch einer Webseite gefragt, welche dieser Cookiearten Sie
                zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.
            </span>

            <h5>
                Wie kann ich Cookies löschen?
            </h5>

            <span>
                Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder
                welcher Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, nur teilweise
                zuzulassen oder zu deaktivieren. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber
                alle anderen Cookies zulassen.
                <br /><br />
                Sie haben jederzeit das Recht auf Ihre personenbezogenen Daten zuzugreifen und Einspruch gegen die
                Nutzung und Verarbeitung zu erheben. Cookies, die von OpenStreetMap möglicherweise gesetzt werden,
                können Sie in Ihrem Browser jederzeit verwalten, löschen oder deaktivieren. Dadurch wird allerdings der
                Dienst nicht mehr im vollen Ausmaß funktionieren. Bei jedem Browser funktioniert die Verwaltung,
                Löschung oder Deaktivierung von Cookies etwas anders. Im Folgenden finden Sie Links zu den Anleitungen
                der bekanntesten Browser:
                <br /><br />
                Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie
                Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:
                <br /><br />
                <a href="https://support.google.com/chrome/answer/95647?tid=311253252">
                    Chrome: Cookies in Chrome löschen, aktivieren und verwalten
                </a>
                <br />
                <a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311253252">
                    Safari: Verwalten von Cookies und Websitedaten mit Safari
                </a>
                <br />
                <a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311253252">
                    Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt
                    haben
                </a>
                <br />
                <a
                    href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311253252">
                    Internet Explorer: Löschen und Verwalten von Cookies
                </a>
                <br />
                <a href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311253252">
                    Microsoft Edge: Löschen und Verwalten von Cookies
                </a>
                <br /><br />
                Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie
                immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
                entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden.
                Am besten ist es Sie suchen die Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder
                “Cookies deaktivieren Chrome” im Falle eines Chrome Browsers oder tauschen das Wort “Chrome” gegen den
                Namen Ihres Browsers, z.B. Edge, Firefox, Safari aus.
            </span>

            <h5>
                Wie sieht es mit meinem Datenschutz aus?
            </h5>
            <span>
                Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von
                Cookies eine Einwilligung von Ihnen verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr
                unterschiedliche Reaktionen auf diese Richtlinien. In Deutschland wurden die Cookie-Richtlinien nicht
                als nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15
                Abs.3 des Telemediengesetzes (TMG).

                Wenn Sie mehr über Cookies wissen möchten und technischen Dokumentationen nicht scheuen, empfehlen wir
                <a href="https://tools.ietf.org/html/rfc6265">https://tools.ietf.org/html/rfc6265</a>, dem Request for
                Comments der Internet Engineering Task Force (IETF) namens “HTTP State Management Mechanism”.
            </span>

            <h3>
                Speicherung persönlicher Daten
            </h3>
            <span>
                Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel Name,
                E-Mail-Adresse, Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars oder
                Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum jeweils
                angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben.
                <br /><br />
                Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die Kontakt
                ausdrücklich wünschen und für die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und
                Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht weiter, können jedoch nicht
                ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.
                <br /><br />
                Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits dieser Webseite – können wir keine
                sichere Übertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten
                niemals unverschlüsselt per E-Mail zu übermitteln.
                <br /><br />
                Die Rechtsgrundlage besteht nach Artikel 6 Absatz 1 a DSGVO (Rechtmäßigkeit der Verarbeitung) darin,
                dass Sie uns die Einwilligung zur Verarbeitung der von Ihnen eingegebenen Daten geben. Sie können diesen
                Einwilligung jederzeit widerrufen – eine formlose E-Mail reicht aus, Sie finden unsere Kontaktdaten im
                Impressum.
            </span>

            <h3>
                Rechte laut Datenschutzgrundverordnung
            </h3>
            <span>
                Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende Rechte zu:
                <br /><br />
                Recht auf Berichtigung (Artikel 16 DSGVO)<br />
                Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)<br />
                Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)<br />
                Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung
                personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)<br />
                Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)<br />
                Widerspruchsrecht (Artikel 21 DSGVO)<br />
                Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling —
                beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)
                <br /><br />
                Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
                datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich an die
                Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI) wenden.
            </span>

            <h3>
                TLS-Verschlüsselung mit https
            </h3>
            <span>
                Wir verwenden https um Daten abhörsicher im Internet zu übertragen (Datenschutz durch Technikgestaltung
                Artikel 25 Absatz 1 DSGVO). Durch den Einsatz von TLS (Transport Layer Security), einem
                Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet können wir den Schutz vertraulicher
                Daten sicherstellen. Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen
                Schloßsymbol links oben im Browser und der Verwendung des Schemas https (anstatt http) als Teil unserer
                Internetadresse.
            </span>

            <h3>
                OpenStreetMap Datenschutzerklärung
            </h3>
            <span>
                Wir haben auf unserer Website Kartenausschnitte des Online-Kartentools „OpenStreetMap“ eingebunden.
                Dabei handelt es sich um ein sogenanntes Open-Source-Mapping, welches wir über eine API (Schnittstelle)
                abrufen können. Angeboten wird diese Funktion von OpenStreetMap Foundation, St John’s Innovation Centre,
                Cowley Road, Cambridge, CB4 0WS, United Kingdom. Durch die Verwendung dieser Kartenfunktion wird Ihre
                IP-Adresse an OpenStreetMap weitergeleitet. In dieser Datenschutzerklärung erfahren Sie warum wir
                Funktionen des Tools OpenStreetMap verwenden, wo welche Daten gespeichert werden und wie Sie diese
                Datenspeicherung verhindern können.
            </span>

            <h5>
                Was ist OpenStreetMap?
            </h5>
            <span>
                Das Projekt OpenStreetMap wurde 2004 ins Leben gerufen. Ziel des Projekts ist und war es, eine freie
                Weltkarte zu erschaffen. User sammeln weltweit Daten etwa über Gebäude, Wälder, Flüsse und Straßen. So
                entstand über die Jahre eine umfangreiche, von Usern selbst erstellte digitale Weltkarte.
                Selbstverständlich ist die Karte, nicht vollständig, aber in den meisten Regionen mit sehr vielen Daten
                ausgestattet.
            </span>

            <h5>
                Warum verwenden wir OpenStreetMap auf unserer Website?
            </h5>
            <span>
                Unsere Website soll Ihnen in erster Linie hilfreich sein. Und das ist sie aus unserer Sicht immer dann,
                wenn man Information schnell und einfach findet. Da geht es natürlich einerseits um unsere
                Dienstleistungen und Produkte, andererseits sollen Ihnen auch weitere hilfreiche Informationen zur
                Verfügung stehen. Deshalb nutzen wir auch den Kartendienst OpenStreetMap. Denn so können wir Ihnen
                beispielsweise genau zeigen, wie Sie unsere Firma finden. Die Karte zeigt Ihnen den besten Weg zu uns
                und Ihre Anfahrt wird zum Kinderspiel.
            </span>

            <h5>
                Welche Daten werden von OpenStreetMap gespeichert?
            </h5>
            <span>
                Wenn Sie eine unserer Webseiten besuchen, die OpenStreetMap anbietet, werden Nutzerdaten an den Dienst
                übermittelt und dort gespeichert. OpenStreetMap sammelt etwa Informationen über Ihre Interaktionen mit
                der digitalen Karte, Ihre IP-Adresse, Daten zu Ihrem Browser, Gerätetyp, Betriebssystem und an welchem
                Tag und zu welcher Uhrzeit Sie den Dienst in Anspruch genommen haben. Dafür wird auch Tracking-Software
                zur Aufzeichnung von Userinteraktionen verwendet. Das Unternehmen gibt hier in der eigenen
                Datenschutzerklärung das Analysetool „Piwik“ an.
                <br /><br />
                Die erhobenen Daten sind in Folge den entsprechenden Arbeitsgruppen der OpenStreetMap Foundation
                zugänglich. Laut dem Unternehmen werden persönliche Daten nicht an andere Personen oder Firmen
                weitergegeben, außer dies ist rechtlich notwendig. Der Drittanbieter Piwik speichert zwar Ihre
                IP-Adresse, allerdings in gekürzter Form.
                <br /><br />
                Folgendes Cookie könnten in Ihrem Browser gesetzt werden, wenn Sie mit OpenStreetMap auf unserer Website
                interagieren:
                <br /><br />
                Name: _osm_location<br />
                Wert: 9.63312%7C52.41500%7C17%7CM<br />
                Verwendungszweck: Das Cookie wird benötigt, um die Inhalte von OpenStreetMap zu entsperren.<br />
                Ablaufdatum: nach 10 Jahren
                <br /><br />
                Wenn Sie sich das Vollbild der Karte ansehen wollen, werden Sie auf die OpenStreetMap-Website verlinkt.
                <br /><br />
                Dort können unter anderem folgende Cookies in Ihrem Browser gespeichert werden:
                <br /><br />
                Name: _osm_totp_token<br />
                Wert: 148253311253252-2<br />
                Verwendungszweck: Dieses Cookie wird benutzt, um die Bedienung des Kartenausschnitts zu
                gewährleisten.<br />
                Ablaufdatum: nach einer Stunde
                <br /><br />
                Name: _osm_session<br />
                Wert: 1d9bfa122e0259d5f6db4cb8ef653a1c<br />
                Verwendungszweck: Mit Hilfe des Cookies können Sitzungsinformationen (also Userverhalten) gespeichert
                werden.<br />
                Ablaufdatum: nach Sitzungsende
                <br /><br />
                Name: _pk_id.1.cf09<br />
                Wert: 4a5.1593684142.2.1593688396.1593688396311253252-9<br />
                Verwendungszweck: Dieses Cookie wird von Piwik gesetzt, um Userdaten wie etwa das Klickverhalten zu
                speichern bzw. zu messen.<br />
                Ablaufdatum: nach einem Jahr
            </span>

            <h5>
                Wie lange und wo werden die Daten gespeichert?
            </h5>
            <span>
                Die API-Server, die Datenbanken und die Server von Hilfsdiensten befinden sich derzeit im Vereinten
                Königreich (Großbritannien und Nordirland) und in den Niederlanden. Ihre IP-Adresse und
                Userinformationen, die in gekürzter Form durch das Webanalysetool Piwik gespeichert werden, werden nach
                180 Tagen wieder gelöscht.
            </span>

            <h5>
                Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
            </h5>
            <span>
                Sie haben jederzeit das Recht auf Ihre personenbezogenen Daten zuzugreifen und Einspruch gegen die
                Nutzung und Verarbeitung zu erheben. Cookies, die von OpenStreetMap möglicherweise gesetzt werden,
                können Sie in Ihrem Browser jederzeit verwalten, löschen oder deaktivieren. Dadurch wird allerdings der
                Dienst nicht mehr im vollen Ausmaß funktionieren. Bei jedem Browser funktioniert die Verwaltung,
                Löschung oder Deaktivierung von Cookies etwas anders. Im Folgenden finden Sie Links zu den Anleitungen
                der bekanntesten Browser:
                <br /><br />
                <a href="https://support.google.com/chrome/answer/95647?tid=311253252">
                    Chrome: Cookies in Chrome löschen, aktivieren und verwalten
                </a>
                <br />
                <a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311253252">
                    Safari: Verwalten von Cookies und Websitedaten mit Safari
                </a>
                <br />
                <a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311253252">
                    Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt
                    haben
                </a>
                <br />
                <a
                    href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311253252">
                    Internet Explorer: Löschen und Verwalten von Cookies
                </a>
                <br />
                <a href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311253252">
                    Microsoft Edge: Löschen und Verwalten von Cookies
                </a>
                <br /><br />
                Wenn Sie mehr über die Datenverarbeitung durch OpenStreetMap erfahren wollen, empfehlen wir Ihnen die
                Datenschutzerklärung des Unternehmens unter <a
                    href="https://wiki.osmfoundation.org/wiki/Privacy_Policy">https://wiki.osmfoundation.org/wiki/Privacy_Policy</a>.
            </span>
        </div>
    </div>