import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DetailComponent } from '@app/common/templates/detail/detail.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
	templateUrl: '../../../../common/templates/detail/detail.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/detail/style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class TblFahrzeugAbwicklungAbschnittDetailComponent extends DetailComponent {
	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public messageService: MessageService,
		public router: Router,
		public translate: TranslateService,
	) {
		super(accountService, breadcrumbService, confirmationService, crudService, messageService, router, translate);

		this.apiUrl = 'TblFahrzeugAbwicklungAbschnitt';
		this.name = 'MENU.FAHRZEUG_ABWICKLUNG_ABSCHNITT';
		this.url = '/' + PATH.FAHRZEUG_ABWICKLUNG_ABSCHNITT;
		this.fieldgroups.push(
			[
				{ type: 'text', key: 'ankey', required: true, width: 400 },
				{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
				{ type: 'text', key: 'bezeichnung_kurz', required: false, width: 400 },
				//{ type: 'date', key: 'geoeffnet_datetime', required: false, width: 400, dateonly: true },
				//{ type: 'date', key: 'geschlossen_datetime', required: false, width: 400, dateonly: true },
				{ type: 'text', key: 'geoeffnet_string_german', required: false, width: 400, dateonly: true },
				{ type: 'text', key: 'geschlossen_string_german', required: false, width: 400, dateonly: true },
				{ type: 'text', key: 'FREMD_abgezeichnet_mitarbeiter_ankey', required: false, width: 400 },
				{ type: 'text', key: 'FREMD_abgezeichnet_mitarbeiter_bezeichnung', required: false, width: 400 },
				{ type: 'text', key: 'FREMD_abschnitt_typ_ankey', required: false, width: 400 },
				{ type: 'text', key: 'FREMD_abschnitt_typ_bezeichnung', required: false, width: 400 },
				{ type: 'text', key: 'bemerkungen', required: false, width: 400 },
			]
		);
		this.breadcrumbService.setItems([
			{ label: 'MENU.STAMMDATEN' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.DETAIL', routerLink: [this.url + '/' + PATH.DETAIL + '/' + this.id] }
		]);
	}
}
