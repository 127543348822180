<div class="p-grid">
	<div class="p-col-12">

		<!-- Message -->
		<p-toast>
			<ng-template let-message pTemplate="message">
				<div class="p-grid p-ml-2">
					<div class="p-col-2">
						<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
					</div>
					<div class="p-col-10">
						<h4>{{message.summary}}</h4>
					</div>
					<div class="p-col-12">
						<p>{{message.detail}}</p>
					</div>
					<div *ngIf="message.severity!='success'" class="p-col-12">
						<a class="p-button"
							href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
							Fehler melden
						</a>
					</div>
				</div>
			</ng-template>
		</p-toast>

		<!-- Spinner -->
		<div *ngIf="loading !== 0" class="spinner">
			<span class="ball-1"></span>
			<span class="ball-2"></span>
			<span class="ball-3"></span>
			<span class="ball-4"></span>
			<span class="ball-5"></span>
			<span class="ball-6"></span>
			<span class="ball-7"></span>
			<span class="ball-8"></span>
		</div>

		<div class="card">
			<!-- Toolbar -->
			<p-toolbar styleClass="p-mb-4">
				<ng-template pTemplate="left">
					<button pButton pRipple label="{{'BUTTONS.EDIT'|translate}}" class="p-button-warning p-mr-2"
						(click)="edit()"></button>
					<button pButton pRipple label="{{'BUTTONS.DELETE'|translate}}" class="p-button-danger p-mr-2"
						(click)="delete()"></button>
					<button pButton pRipple label="{{'BUTTONS.EXPORT'|translate}}" class="p-button-help p-mr-2"
						(click)="export()"></button>
					<button *ngIf="bHasNoRasFile" pButton pRipple label="{{'BUTTONS.RAS_AUFZEICHNUNG.AKTE_ZUWEISEN'|translate}}" class="p-button-warning"
						(click)="aktenzuweisung()"></button>
				</ng-template>
				<ng-template pTemplate="right">
				</ng-template>
			</p-toolbar>

			<!-- Content -->
			<div *ngIf="track&&loading===0">
				<div *ngFor="let fields of fieldgroups" class="p-my-5">
					<div class="p-grid p-formgrid">
						<div *ngFor="let field of fields" class="p-col-12 p-md-6 p-lg-3 p-my-2">
							<h6 class="p-my-2">{{field.label}}</h6>
							<div *ngIf="!track[field.key] && track[field.key] !== false && track[field.key] !== 0">
								<i class="pi pi-minus"></i>
							</div>
							<div *ngIf="field.type === 'string'">
								{{track[field.key]}}
							</div>
							<div *ngIf="field.type === 'number'">
								{{track[field.key]}}
							</div>
							<div *ngIf="field.type === 'date'">
								{{parseDate(track[field.key])}}
							</div>
							<div *ngIf="field.type === 'boolean'">
								<p-radioButton [value]="true" [(ngModel)]="track[field.key]" disabled></p-radioButton>
								<span class="p-m-1">
									{{'CONFIRMATION.YES'|translate}}
								</span>
								<p-radioButton [value]="false" [(ngModel)]="track[field.key]" disabled></p-radioButton>
								<span class="p-m-1">
									{{'CONFIRMATION.NO'|translate}}
								</span>
							</div>
							<div *ngIf="field.type === 'list'">
								<div *ngFor="let e of track[field.key]">
									{{e}}
								</div>
							</div>
							<div *ngIf="field.type === 'vehicle'">
								<a href="{{url_prefix_fahrzeug}}{{track[field.key]}}">{{track["FREMD_kennzeichen"]}}</a>
							</div>
							<div *ngIf="field.type === 'parent'">
								<a
									href="{{url_prefix_strecke}}{{track['FREMD_strecke_ds_this_id']}}">{{track["FREMD_akte_bezeichnung"]}}</a>
							</div>
							<div *ngIf="field.type === 'rating'">
								<i [ngClass]="track[field.key] === 0 ? 'pi pi-circle-off' : 'pi pi-circle-on'"
									[style.color]="ratings[track[field.key]]"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="p-grid p-formgrid">
				<div  class="p-m-2 p-p-0">
					<video #detail_track_video (timeupdate)="setVehiclePosition($event.target.currentTime)"
						(loadedmetadata)="initGPX($event)" controls
						[src]="videoSafeUrl">
					</video>
				</div>
				<div id="mapRasDetails" class="p-m-2 p-p-0" style="width: 720px; height: 575px;" ></div>
			</div>
		</div>

		<!-- Confirm -->
		<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
	</div>
</div>