<!-- Confirm -->
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

<!-- Message -->
<p-toast>
	<ng-template let-message pTemplate="message">
		<div class="p-grid p-ml-2">
			<div class="p-col-2">
				<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
			</div>
			<div class="p-col-10">
				<h4>{{message.summary}}</h4>
			</div>
			<div class="p-col-12">
				<p>{{message.detail}}</p>
			</div>
			<div *ngIf="message.severity!='success'" class="p-col-12">
				<a class="p-button"
					href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
					Fehler melden
				</a>
			</div>
		</div>
	</ng-template>
</p-toast>

<!-- Spinner -->
<div *ngIf="loading !== 0" class="spinner">
	<span class="ball-1"></span>
	<span class="ball-2"></span>
	<span class="ball-3"></span>
	<span class="ball-4"></span>
	<span class="ball-5"></span>
	<span class="ball-6"></span>
	<span class="ball-7"></span>
	<span class="ball-8"></span>
</div>

<!-- Toolbar -->
<div class="card p-my-0">
	<p-toolbar>
		<div class="p-toolbar-group-left">
			<a *ngIf="updatePermission" id="editbutton" [routerLink]="url + '/edit/' + id"
				class="p-button-warning p-mr-2 p-ripple p-button p-component p-button-icon-only">
				<i class="pi pi-pencil"></i>
				<span aria-hidden="true" class="p-button-label">&nbsp;</span>
			</a>
			<a *ngIf="deletePermission" id="deletebutton" (click)="deleteEntry()"
				class="p-button-danger p-mr-2 p-ripple p-button p-component p-button-icon-only">
				<i class="pi pi-trash"></i>
				<span aria-hidden="true" class="p-button-label">&nbsp;</span>
			</a>
			<button type="button" pButton pRipple icon="fa fa-road" (click)="generateStreets()" class="p-m-2"
				pTooltip="{{'BUTTONS.GENERATE_STREETS' | translate }}" tooltipPosition="bottom"></button>
		</div>
		<h5 class="p-m-0">{{ name | translate }}</h5>
		<div class="p-toolbar-group-right">
			<button type="button" pButton
				[icon]="state.horizontalGroups ? 'fa fa-grip-vertical' : 'fa fa-grip-horizontal'"
				class="p-button-secondary p-mx-2"
				pTooltip="{{(state.horizontalGroups ? 'BUTTONS.VERTICAL_GROUPS' : 'BUTTONS.HORIZONTAL_GROUPS') | translate }}"
				tooltipPosition="bottom" (click)="setHorizontalGroups(!state.horizontalGroups)"></button>
		</div>
	</p-toolbar>
</div>

<!-- Content -->
<div *ngIf="entry">
	<div class="p-grid p-formgrid">
		<div *ngFor="let fields of fieldgroups"
			[ngClass]="state.horizontalGroups ? 'p-col-12  p-my-2' : ('p-col-12' + (fieldgroups.length > 1 ? ' p-md-6' : '') + (fieldgroups.length > 3 ? ' p-lg-3' : '') + ' p-my-2')">
			<div class="card" style="height:100%;">
				<div class="p-grid p-formgrid">
					<div *ngFor="let field of fields"
						[ngClass]="state.horizontalGroups ? 'p-col-12 p-md-6 p-lg-3 p-my-2' : 'p-col-12 p-my-2'">
						<h6 class="p-my-2">
							{{ 'HEADERS.' + field.key | translate }}
						</h6>
						<div *ngIf="entry[field.key] === null">
							<i class="pi pi-minus"></i>
						</div>
						<div *ngIf="entry[field.key] !== null">
							<div *ngIf="field.type == 'text'">
								{{ entry[field.key] }}
							</div>
							<div *ngIf="field.type == 'numeric'">
								{{ entry[field.key] }}
							</div>
							<div *ngIf="field.type == 'boolean'">
								<i [ngClass]="entry[field.key] ? 'pi pi-check' : 'pi pi-times'"
									[style.color]="entry[field.key] ? 'green' : 'red'"></i>
							</div>
							<div *ngIf="field.type == 'date'">
								{{ entry[field.key].toLocaleString() }}
							</div>
							<div *ngIf="field.type == 'object'">
								<a [routerLink]="field.url + '/' + url_detail + '/' + entry[field.id]">
									{{ entry[field.key] }}
								</a>
							</div>
							<div *ngIf="field.type == 'list'">
								<div *ngFor="let item of entry[field.key].split(',')">
									<a
										[routerLink]="field.url + '/' + url_detail + '/' + entry[field.id].split(',')[(entry[field.key].split(',')).indexOf(item)]">
										{{ item }}
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>