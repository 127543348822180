<div *ngIf="app.showFooter" style="position: relative;">
	<div class="layout-footer">
		<div class="logo-text">
			<div class="text">
				<div style="display:flex;align-items: center;">
					<img class="logo" style="height: 2rem;width: 2rem;" src="assets/images/visy/favicon.svg" />
					<h1 class="p-mx-3">{{productTitle}}</h1>
				</div>
				<span>{{version}} - {{copyright}} {{copyrightDisclaimer}}</span>
			</div>
		</div>
		<div class="icons">
			<!--
			  <div class="icon icon-hastag" (click)="this.displayFeedbackDialog=true">
				  <i class="pi pi-question-circle"></i>
			  </div>
		-->
			<div class="icon icon-hastag">
				<a
					href="mailto:info@visy-gmbh.de?subject=Feedback%20ViSy%20Online&amp;body=Name:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
					<i class="pi pi-comment"></i>
				</a>
			</div>
			<div class="icon icon-hastag">
				<a [routerLink]="'manual'" [fragment]="router.url.substring(1).toLowerCase()">
					<i class="fas fa-book"></i>
				</a>
			</div>
			<div class="icon icon-hastag">
				<a [routerLink]="'terms'">
					<i class="pi pi-info"></i>
				</a>
			</div>
			<div class="icon icon-hastag">
				<a (click)="toggleFullscreen()">
					<i
						[ngClass]="['fas', fullscreen ? 'fa-down-left-and-up-right-to-center' : 'fa-up-right-and-down-left-from-center']"></i>
				</a>
			</div>
			<div class="icon icon-hastag">
				<a (click)="scrollToTop()">
					<i class="pi pi-chevron-up"></i>
				</a>
			</div>

		</div>
		<button pButton pRipple icon="pi pi-chevron-down" class="p-button-text p-p-0" (click)="switchFooterState(false)"
			style="position: absolute;left: 50%; bottom: 70px; transform: translate(-50%, +100%); margin: 0 0;"></button>
	</div>
</div>
<div *ngIf="!app.showFooter">
	<button pButton pRipple icon="pi pi-chevron-up" class="p-button-text p-p-0" (click)="switchFooterState(true)"
		style="position: absolute;left: 50%; bottom: 0px; transform: translate(-50%, -0%); margin: 0 0;"></button>
</div>

<!-- Feedback -->
<p-dialog header="Title" [(visible)]="displayFeedbackDialog">
	Content
	<ng-template pTemplate="footer">
		<p-button icon="pi pi-check" (click)="displayFeedbackDialog=false" label="{{ 'FEEDBACK.ABORT' | translate }}"
			styleClass="p-button-text"></p-button>
		<p-button icon="pi pi-check" (click)="displayFeedbackDialog=false" label="{{ 'FEEDBACK.SEND' | translate }}"
			styleClass="p-button-text"></p-button>
	</ng-template>
</p-dialog>