import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ConnectionSetting, Role, TestUserCreate, User, VehicleUser } from '@models/admin';
import { AccountService } from '@services/account.service';

@Injectable()
export class AdminService {
	constructor(
		private accountService: AccountService,
		private http: HttpClient
	) {
	}

	/**
	 * Settings
	 */

	getCustomerSettings() {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/admin/getCustomerSettings`, options).toPromise());
		});
	}

	saveCustomerSettings(settings: any) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/admin/saveCustomerSettings`, settings, options).toPromise());
		});
	}

	removeReportHeader() {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/admin/RemoveHeader`, options).toPromise());
		});
	}

	/*
	 * Users
	 */
	getAllCustomerUsers() {
		return new Promise<VehicleUser[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/users/index`, options).toPromise());
		});
	}

	getUser(id: string): Promise<User> {
		return new Promise<User>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/users/details/` + id, options).toPromise());
		});
	}

	createUser(user: User) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/users/create`, user, options).toPromise());
		});
	}

	deleteUser(id: string) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/users/delete/` + id, options).toPromise());
		});
	}

	editUser(id: string, user: User) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/users/edit/` + id, user, options).toPromise());
		});
	}

	setPassword(userId: string, newPassword: string) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/users/setpassword/` + userId, { NewPassword: newPassword }, options).toPromise());
		});
	}

	getAllRoles() {
		return new Promise<Role[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/users/roles`, options).toPromise());
		});
	}

	getAllViSyUsers() {
		return new Promise<VehicleUser[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/users/visy`, options).toPromise());
		});
	}

	createVtoolsAuthentication(vtools) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/users/SetVtoolsAuthentication/`, vtools, options).toPromise());
		});
	}

	deleteVtoolsAuthentication(vtools) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/users/SetVtoolsAuthentication/`, vtools, options).toPromise());
		});
	}

	createTestUser(testUserData: TestUserCreate) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/users/CreatePeriodicalTestUser/`, testUserData, options).toPromise());
		});
	}

	/*
	 * Connection Settings
	 */
	getAllConnectionSettings() {
		return new Promise<ConnectionSetting[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/userConnectionSettings`, options).toPromise());
		});
	}

	getConnectionSetting(id: number): Promise<ConnectionSetting> {
		return new Promise<ConnectionSetting>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/userConnectionSettings/details/` + id, options).toPromise());
		});
	}

	createConnectionSetting(connectionSetting: ConnectionSetting) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/userConnectionSettings/create/`, connectionSetting, options).toPromise());
		});
	}

	deleteConnectionSetting(id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/userConnectionSettings/delete/` + id, {}, options).toPromise());
		});
	}

	editConnectionSetting(id: number, connectionSetting: ConnectionSetting) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/userConnectionSettings/edit/` + id, connectionSetting, options).toPromise());
		});
	}
}
