export enum Module {
    OnlineVehicles,
    OnlineCollections,
    BHV,
    ReversingCadastral,
    Masterdata
}

export enum Operation {
    CREATE,
    READ,
    UPDATE,
    DELETE
}