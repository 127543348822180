import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DetailComponent } from '@app/common/templates/detail/detail.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
	templateUrl: './detail.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/detail/style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class TblVolumenDetailComponent extends DetailComponent {
	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public messageService: MessageService,
		public router: Router,
		public translate: TranslateService,
	) {
		super(accountService, breadcrumbService, confirmationService, crudService, messageService, router, translate);

		this.apiUrl = 'TblVolumen';
		this.name = 'MENU.VOLUMEN';
		this.url = '/' + PATH.VOLUMEN;
		this.fieldgroups.push(
			[
				{ type: 'text', key: 'ankey', required: true, width: 400 },
				{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
				{ type: 'text', key: 'bezeichnung_kurz', required: false, width: 400 },
				{ type: 'numeric', key: 'wert', required: false, width: 400 },
			],
			[
				{ type: 'text', key: 'barcode', required: false, width: 400 },
				{ type: 'text', key: 'barcode_text', required: false, width: 400 },
				{ type: 'numeric', key: 'barcode_ziffer', required: false, width: 400 },
			]
		);
		this.breadcrumbService.setItems([
			{ label: 'MENU.STAMMDATEN' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.DETAIL', routerLink: [this.url + '/' + PATH.DETAIL + '/' + this.id] }
		]);
	}

	setzeAktiv() {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.ACTIVATE_QUESTION'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.internal_AktivInaktiv(true);
			}
		});
	}

	setzeInaktiv() {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.DEACTIVATE_QUESTION'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.internal_AktivInaktiv(false);
			}
		});
	}

	private async internal_AktivInaktiv(value: boolean) {
		this.loading += 1;
		this.crudService.setVolumeActiveState(this.entry.ds_this_id, value).then(() => {
			window.location.reload();
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}
}
