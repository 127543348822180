<div class="p-grid">
	<div class="p-col-12">

		<!-- Message -->
		<p-toast>
			<ng-template let-message pTemplate="message">
				<div class="p-grid p-ml-2">
					<div class="p-col-2">
						<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
					</div>
					<div class="p-col-10">
						<h4>{{message.summary}}</h4>
					</div>
					<div class="p-col-12">
						<p>{{message.detail}}</p>
					</div>
					<div *ngIf="message.severity!='success'" class="p-col-12">
						<a class="p-button"
							href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
							Fehler melden
						</a>
					</div>
				</div>
			</ng-template>
		</p-toast>

		<!-- Spinner -->
		<div *ngIf="loading!==0" class="spinner">
			<span class="ball-1"></span>
			<span class="ball-2"></span>
			<span class="ball-3"></span>
			<span class="ball-4"></span>
			<span class="ball-5"></span>
			<span class="ball-6"></span>
			<span class="ball-7"></span>
			<span class="ball-8"></span>
		</div>

		<div class="card">
			<!-- Toolbar -->
			<p-toolbar styleClass="p-mb-4">
				<ng-template pTemplate="left">
					<button pButton pRipple label="{{'BUTTONS.SAVE'|translate}}" class="p-button-success p-mr-2"
						(click)="save()"></button>
				</ng-template>
				<ng-template pTemplate="right">
					<p-fileUpload #uploadButton *ngIf="isAdmin()" mode="basic" [maxFileSize]="20971520" fileLimit="1"
						chooseLabel="{{'BUTTONS.ATTACH_FILE'|translate}}"
						(onUpload)="loading=loading-1;refreshAttachments();" (onBeforeUpload)="loading=loading+1;"
						(onError)="loading=loading-1;uploadButton.clear();" withCredentials="true" [headers]="headers"
						url="{{attachUrl}}" class="p-mr-2 p-d-inline-block" [auto]="true">
					</p-fileUpload>
				</ng-template>
			</p-toolbar>



			<!-- Content -->
			<div *ngIf="vehicle&&loading===0">
				<div class="p-grid p-formgrid">
					<div *ngFor="let group of fieldgroups" class="p-col-12 p-md-4 p-lg-2">
						<div *ngIf="group.title" class="p-my-2 p-p-0">
							<h4>
								{{ 'MASTERDATA.VEHICLES.' + group.title | translate}}
							</h4>
						</div>
						<div *ngFor="let field of group.fields" class="p-my-3">
							<div *ngIf="field.parent === 'vehicle'">
								<h6 class="p-my-2">
									{{ 'MASTERDATA.VEHICLES.' + field.label | translate}}
								</h6>
								<div *ngIf="field.type === 'string'">
									<input type="text" pInputText [value]="vehicle[field.key]"
										(input)="vehicle[field.key]=$event.target.value">
								</div>
								<div *ngIf="field.type === 'date'" [style.width]="'90%'" [style.max-width]="'400px'">
									<p-calendar [style]="{'width':'100%'}" [showIcon]="true" [showTime]="true"
										[showSeconds]="true" hourFormat="24" dateFormat="dd.mm.yy" keepInvalid="true"
										(onInput)="inputDate(field.key, $event.target.value)" [touchUI]="isMobile"
										[ngModel]="vehicle[field.key]" (onSelect)="vehicle[field.key]=$event">
									</p-calendar>
								</div>
								<div *ngIf="field.type === 'boolean'">
									<p-radioButton [value]="true" [(ngModel)]="vehicle[field.key]"></p-radioButton>
									<span class="p-m-1">
										{{'CONFIRMATION.YES'|translate}}
									</span>
									<p-radioButton [value]="false" [(ngModel)]="vehicle[field.key]"></p-radioButton>
									<span class="p-m-1">
										{{'CONFIRMATION.NO'|translate}}
									</span>
								</div>
								<div *ngIf="field.type === 'dropdown'" class="p-inputgroup" [style.width]="'90%'"
									[style.max-width]="'400px'">
									<p-dropdown class="dropdown-button-group" [style]="{'width':'100%'}"
										[options]="options[field.key]" [(ngModel)]="vehicle[field.key]" placeholder="-"
										[showClear]="true" [virtualScroll]="true" itemSize="30" [filter]="true"
										appendTo="body" dropdownDirective></p-dropdown>
								</div>
								<div *ngIf="field.type == 'object'" class="p-inputgroup" [style.width]="'90%'"
									[style.max-width]="'400px'">
									<p-dropdown class="dropdown-button-group" [style]="{'width':'100%'}"
										[options]="options[field.key]" [(ngModel)]="vehicle[field.key]" placeholder="-"
										[showClear]="true" [virtualScroll]="true" itemSize="30" [filter]="true" appendTo="body"
										dropdownDirective>
										<ng-template pTemplate="header">
											<div class="p-inputgroup p-px-4 p-pt-3" (click)="$event.stopPropagation()">
												<p-dropdown #optionSortFieldSelection class="dropdown-button-group"
													[style]="{'width':'100%'}" [options]="field.optionSortFieldOptions"
													placeholder="-" [virtualScroll]="true" itemSize="30" [filter]="true"
													(onChange)="changeSorting(field);$event.originalEvent.stopPropagation();"
													[(ngModel)]="field.optionSortField" appendTo="body" dropdownDirective>
												</p-dropdown>
												<button type="button" pButton pRipple
													[icon]="field.sortDescending ? 'fa fa-arrow-down-wide-short' : 'fa fa-arrow-down-short-wide'"
													class="p-button-secondary p-ml-1"
													(click)="field.sortDescending = !field.sortDescending; changeSorting(field);"
													pTooltip="{{'BUTTONS.CHANGE_SORTING' | translate }}"
													tooltipPosition="bottom"></button>
											</div>
										</ng-template>
									</p-dropdown>
									<button type="button" pButton pRipple icon="fa fa-plus" class="p-button-success p-ml-2"
										(click)="createOption(field)" pTooltip="{{'BUTTONS.NEW' | translate }}"
										tooltipPosition="bottom"></button>
								</div>
								<small
									*ngIf="field.required && (vehicle[field.key] == null || vehicle[field.key] === '')"
									class="p-error block" translate>REQUIRED</small>
							</div>
							<div *ngIf="field.parent === 'vehicleFile'">
								<h6 class="p-my-2">
									{{ 'MASTERDATA.VEHICLES.' + field.label | translate}}
								</h6>
								<div *ngIf="field.type === 'string'">
									<input type="text" pInputText [value]="vehicleFile[field.key]"
										(input)="vehicleFile[field.key]=$event.target.value">
								</div>
								<div *ngIf="field.type === 'date'" [style.width]="'90%'" [style.max-width]="'400px'">
									<p-calendar [style]="{'width':'100%'}" [showIcon]="true" [showTime]="true"
										[showSeconds]="true" hourFormat="24" dateFormat="dd.mm.yy" [touchUI]="isMobile"
										[showClear]="true" [showButtonBar]="true" [(ngModel)]="vehicleFile[field.key]">
									</p-calendar>
								</div>
								<div *ngIf="field.type === 'boolean'">
									<p-radioButton [value]="true" [(ngModel)]="vehicleFile[field.key]"></p-radioButton>
									<span class="p-m-1">
										{{'CONFIRMATION.YES'|translate}}
									</span>
									<p-radioButton [value]="false" [(ngModel)]="vehicleFile[field.key]"></p-radioButton>
									<span class="p-m-1">
										{{'CONFIRMATION.NO'|translate}}
									</span>
								</div>
								<div *ngIf="field.type === 'dropdown'" class="p-inputgroup" [style.width]="'90%'"
									[style.max-width]="'400px'">
									<p-dropdown class="dropdown-button-group" [style]="{'width':'100%'}"
										[options]="options[field.key]" [(ngModel)]="vehicleFile[field.key]"
										placeholder="-" [showClear]="true" [virtualScroll]="true" itemSize="30"
										[filter]="true" appendTo="body" dropdownDirective></p-dropdown>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="isVisy">
					<div *ngIf="attachments && attachments.length > 0">
						<h3>
							{{ 'MASTERDATA.VEHICLES.' + 'Anhaenge' | translate}}
						</h3>
						<div class="p-mb-2">
							<button pButton pRipple label="Liste" class="p-button-text p-p-0"
								(click)="attachmentListStyle='list'"></button> | <button pButton pRipple
								label="Vorschau" class="p-button-text p-p-0"
								(click)="attachmentListStyle='preview'"></button>
						</div>
						<div *ngFor="let attachment of attachments">
							<img *ngIf="attachmentListStyle==='preview'&&getFileType(attachment.bezeichnung) === 'image'"
								[src]="attachment.fileUrl" style="max-height:50px;max-width:50px;">
							<iframe
								*ngIf="attachmentListStyle==='preview'&&getFileType(attachment.bezeichnung) === 'text'"
								[src]="attachment.fileUrl"
								style="max-height:50px;max-width:50px;background: #FFFFFF;"></iframe>
							<iframe
								*ngIf="attachmentListStyle==='preview'&&getFileType(attachment.bezeichnung) === 'pdf'"
								[src]="attachment.fileUrl" style="max-height:50px;max-width:50px;"></iframe>
							<button pButton pRipple label="{{ attachment.bezeichnung }}" class="p-button p-my-2 p-mx-1"
								(click)="filePreview.toggle($event);"></button>
							<button pButton pRipple icon="pi pi-trash"
								class="p-button-rounded p-button-danger p-my-2 p-mx-1"
								(click)="deleteFile(attachment)"></button>
							<p-overlayPanel #filePreview>
								<ng-template pTemplate>
									<div *ngIf="getFileType(attachment.bezeichnung) === 'image'"
										style="max-height:550px;max-width:550px;">
										<img [src]="attachment.fileUrl">
									</div>
									<div *ngIf="getFileType(attachment.bezeichnung) === 'text'"
										style="overflow:scroll;height:550px;width:550px;background: #FFFFFF;">
										<iframe [src]="attachment.fileUrl" width="100%" height="99%"
											allowfullscreen></iframe>
									</div>
									<div *ngIf="getFileType(attachment.bezeichnung) === 'pdf'"
										style="overflow:scroll;height:550px;width:550px;">
										<iframe [src]="attachment.fileUrl" width="100%" height="99%"
											allowfullscreen></iframe>
									</div>
								</ng-template>
							</p-overlayPanel>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Confirm -->
		<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
	</div>
</div>