import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as FileSaver from 'file-saver';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';

@Injectable()
export class ExportService {
	constructor(
		public translate: TranslateService,
	) { }

	/**
	 * Exportiert den JSON-Datensatz als Liste in eine PDF-Datei
	 * 
	 * @param name Name der Exportdatei
	 * @param dataset JSON Datensatz
	 * @param cols Spaltennamen
	 */
	exportPDF(name: string, dataset: any[], cols: any[]): void {
		const doc = new jsPDF({
			orientation: 'l',
			format: 'a4'
		});
		const header = [];
		cols.forEach(col => {
			header.push(
				{ content: this.translate.instant('HEADERS.' + col.key), styles: { halign: 'center', fontSize: 9, fillColor: [150, 150, 150], textColor: [250, 250, 250] } }
			);
		});
		const content = [];
		dataset.forEach(entry => {
			const e = [];
			cols.forEach(col => {
				e.push(entry[col.key] ? (col.type == 'date' ? entry[col.key].toLocaleString() : entry[col.key]) : '-');
			});
			content.push(e);
		});
		autoTable(doc, {
			theme: 'grid',
			rowPageBreak: 'auto',
			startY: 15,
			styles: { valign: 'middle', halign: 'center', fontSize: 7, cellPadding: 1 },
			head: [
				[{
					content: name,
					colSpan: cols.length,
					styles: {
						halign: 'center', fontSize: 11, fillColor: false, textColor: [0, 0, 0],
						cellPadding: { bottom: 3 }
					}
				}],
				header
			],
			body: content
		});
		doc.save(name + '.pdf');
	}

	/**
	 * Exportiert den JSON-Datensatz als Liste in eine XLSX-Datei
	 * 
	 * @param name Name der Exportdatei
	 * @param dataset JSON Datensatz
	 * @param cols Spaltennamen
	 */
	exportXLSX(name: string, dataset: any[], cols: any[]): void {
		let content = [];
		dataset.forEach(entry => {
			const e = {};
			cols.forEach(col => {
				e[this.translate.instant('HEADERS.' + col.key)] = (entry[col.key] ? (col.type == 'date' ? entry[col.key].toLocaleString() : entry[col.key]) : '-');
			});
			if (Object.keys(entry).indexOf('parent_id') > -1) {
				e['Parent'] = (entry.ds_this_id == entry.parent_id);
			}
			content.push(e);
		});
		const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(content);
		const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
		const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
		const blob: Blob = new Blob([excelBuffer], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
		});
		FileSaver.saveAs(blob, name + '.xlsx');
	}

	/**
	 * Exportiert den JSON-Datensatz als Liste in eine CSV-Datei
	 * 
	 * @param name Name der Exportdatei
	 * @param dataset JSON Datensatz
	 * @param cols Spaltennamen 
	 */
	exportCSV(name: string, dataset: any[], cols: any[]): void {
		let csv = '';
		cols.forEach(col => {
			csv = csv + this.translate.instant('HEADERS.' + col.key) + ',';
		});
		csv = csv + 'Parent\r\n';
		dataset.forEach(entry => {
			cols.forEach(col => {
				csv = csv + '"' + (entry[col.key] ? (col.type == 'date' ? entry[col.key].toLocaleString() : entry[col.key]) : '-') + '",';
			});
			if (Object.keys(entry).indexOf('parent_id') > -1) {
				csv = csv + (entry.ds_this_id == entry.parent_id);
			}
			csv = csv + '\r\n';
		});

		const blob: Blob = new Blob([csv], { type: 'text/csv;charset=UTF-8' })
		FileSaver.saveAs(blob, name + ".csv");
	}
}