<div class="p-grid">
	<div class="p-col-12">

		<!-- Message -->
		<p-toast>
			<ng-template let-message pTemplate="message">
				<div class="p-grid p-ml-2">
					<div class="p-col-2">
						<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
					</div>
					<div class="p-col-10">
						<h4>{{message.summary}}</h4>
					</div>
					<div class="p-col-12">
						<p>{{message.detail}}</p>
					</div>
					<div *ngIf="message.severity!='success'" class="p-col-12">
						<a class="p-button"
							href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
							Fehler melden
						</a>
					</div>
				</div>
			</ng-template>
		</p-toast>
		<!-- Spinner -->
		<div *ngIf="loading !== 0" class="spinner">
			<span class="ball-1"></span>
			<span class="ball-2"></span>
			<span class="ball-3"></span>
			<span class="ball-4"></span>
			<span class="ball-5"></span>
			<span class="ball-6"></span>
			<span class="ball-7"></span>
			<span class="ball-8"></span>
		</div>

		<div class="card">
			<!-- Toolbar -->
			<p-toolbar styleClass="p-mb-4">
				<ng-template pTemplate="left">
					<button pButton pRipple label="{{'BUTTONS.CREATETRIAL'|translate}}" class="p-button-success p-mr-2"
						(click)="createTrial()"></button>
				</ng-template>
				<ng-template pTemplate="right">
				</ng-template>
			</p-toolbar>

			<div *ngFor="let fields of fieldgroups" class="p-my-5">
				<div class="p-grid p-formgrid">
					<div *ngFor="let field of fields" class="p-col-12 p-md-6 p-lg-3 p-my-2">
						<h6 class="p-my-2">{{field.label}}</h6>
						<div *ngIf="field.type === 'string' || field.type === 'number'">
							<input type="text" pInputText placeholder="{{field.placeholder}}"
								(input)="createData[field.key]=$event.target.value">
						</div>
						<div *ngIf="field.type === 'date'">
							<p-calendar [showIcon]="true" [showTime]="false" placeholder="{{field.placeholder}}"
								dateFormat="dd.mm.yy" keepInvalid="true"
								(onInput)="inputDate(field.key, $event.target.value)" [ngModel]="createData[field.key]"
								(onSelect)="setDateInput($event, field.key)">
							</p-calendar>
						</div>
					</div>
				</div>
			</div>

			<span *ngIf="createResult.ResultValid">
				<h5 class="p-my-2">Testzugang erfolgreich angelegt!</h5>
				<h6 *ngIf="createResult.FahrzeugeOk == false" class="p-my-2">(Fehler bei Testfahrzeug(en), bitte prüfen)
				</h6>
				<div *ngFor="let fields of outputfieldgroup" class="p-my-5">
					<div class="p-grid p-formgrid">
						<div *ngFor="let field of fields" class="p-col-12 p-md-6 p-lg-3 p-my-2">
							<h6 class="p-my-2">{{field.label}}</h6>
							<div *ngIf="field.type === 'string'">
								{{createResult[field.key]}}
							</div>
						</div>
					</div>
				</div>
			</span>
		</div>
	</div>
</div>