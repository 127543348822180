import { Component, OnInit } from '@angular/core';
import { AccountService } from '@app/services/account.service';
import { AdminService } from '@app/services/admin.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
	templateUrl: './organization.component.html',
	styleUrls: ['./style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class OrganizationComponent implements OnInit {
	attachUrl: string;
	loading = 0;
	hasHeader = false;
	options;
	settings: any = { rk_rating_boundaries: { 0: 0, 1: 10, 2: 20, 3: 30, 4: 40, 5: 50 } };
	url: string = '';

	constructor(
		private accountService: AccountService,
		public adminService: AdminService,
		private breadcrumbService: BreadcrumbService,
		private messageService: MessageService,
		public crudService: CRUDService,
		public translate: TranslateService,
		public confirmationService: ConfirmationService,
	) {
		this.url = '/' + PATH.ORGANIZATION;

		this.breadcrumbService.setItems([
			{ label: 'MENU.ADMIN' },
			{ label: 'MENU.ORGANISATION', routerLink: [this.url] }
		]);
	}

	async ngOnInit() {
		this.attachUrl = `${environment.apiUrl}/Admin/UploadHeader`;
		this.loadHeader();
		this.options = await this.accountService.getOptions();
		this.getSettings();
	}

	getSettings(): void {
		this.loading += 1;
		this.adminService.getCustomerSettings().then(res => {
			if (res) {
				this.settings = res;
			}
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	//upload_section
	showSection(id: string): void {
		try {
			let section = document.querySelector(id);
			if( section ) {
				section.removeAttribute("hidden");
			}
		}
		catch { }
	}

	hideSection(id: string): void {
		try {
			let section = document.querySelector(id);
			if( section ) {
				section.setAttribute("hidden", "hidden");
			}
		}
		catch { }
	}

	showHeaderSection(): void {
		this.showSection('#header_section');
	}

	hideHeaderSection(): void {
		this.hideSection('#header_section');
	}

	showUploadButtonSection(): void {
		this.showSection('#upload_section');
	}

	hideUploadButtonSection(): void {
		this.hideSection('#upload_section');
	}

	unloadHeader(): void {
		this.hasHeader = false;
		const img: HTMLImageElement = document.querySelector('#header_image');
		if( img ) {
			img.src = undefined;
			img.style.maxWidth = undefined;
			img.style.borderStyle = undefined;
			img.style.padding = undefined;
		}
		this.hideHeaderSection();
		this.showUploadButtonSection();
	}

	/**
	 * Berichtskopfzeile aus DB laden
	 */
	loadHeader(): void {
		this.unloadHeader();
		this.loading += 1;
		this.crudService.getHeader().then(res => {
			if( res && res['size'] && res['size'] > 0) {
				this.hasHeader = true;
				this.showHeaderSection();
				this.hideUploadButtonSection();
				const blob = new Blob([res], { type: 'application/octet-stream' });
				const urlCreator = window.URL || window.webkitURL;
				const imageUrl = urlCreator.createObjectURL(blob);
				const img: HTMLImageElement = document.querySelector('#header_image');
				img.src = imageUrl;
				img.style.maxWidth = '900px';
				img.style.borderStyle = 'outset';
				img.style.padding = '5px';
			}
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	removeCurrentHeader(): void {
		// TODO aus irgendwelchen Gründen kommt hier an dieser Stelle kein confirmation popup. Warum?! Ist doch wie in anderen Dialogen auch implementiert!
		//this.confirmationService.confirm({
		//	message: this.translate.instant('CONFIRMATION.DELETE_QUESTION'),
		//	header: this.translate.instant('CONFIRMATION.CONFIRM'),
		//	icon: 'pi pi-exclamation-triangle',
		//	acceptLabel: this.translate.instant('CONFIRMATION.YES'),
		//	rejectLabel: this.translate.instant('CONFIRMATION.NO'),
		//	accept: () => {
		//		this.loading += 1;
		//		this.adminService.removeReportHeader().then(() => {
		//			this.loadHeader();
		//		}).catch(err => {
		//			err.error.forEach(e => {
		//				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
		//			})
		//		}).finally(() => {
		//			this.loading -= 1;
		//		});
		//	}
		//});

		this.loading += 1;
		this.adminService.removeReportHeader().then(() => {
			this.loadHeader();
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	saveSettings(): void {
		this.loading += 1;
		this.adminService.saveCustomerSettings(this.settings).then(res => {
			this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}
}
