<div id="container" class="card p-m-0" [style.min-height]="contentHeight + 'px'">
	<!-- Toolbar -->
	<p-toolbar>
		<div class="p-toolbar-group-left">
			<button type="button" pButton pRipple icon="fa fa-plus" class="p-mr-2"
				pTooltip="{{'VEHICLES.LOAD_VEHICLES' | translate }}" tooltipPosition="bottom"
				(click)="openVehicleSelectionDialog()"></button>
			<button type="button" pButton pRipple icon="fa fa-x" class="p-button-danger p-mr-3"
				pTooltip="{{'VEHICLES.REMOVE_ALL_VEHICLES' | translate }}" tooltipPosition="bottom"
				(click)="removeAllVehicles()"></button>

			<button type="button" pButton pRipple icon="fa fa-truck" class="p-mx-1"
				pTooltip="{{(isShownElement('vehicleContainer') ? 'VEHICLES.CLOSE_SELECTED_VEHICLES_LIST': 'VEHICLES.OPEN_SELECTED_VEHICLES_LIST')| translate }}"
				tooltipPosition="bottom" (click)="toggleElement('vehicleContainer')"></button>
			<button type="button" pButton pRipple icon="fa fa-trash-can" class="p-mx-1"
				pTooltip="{{(isShownElement('collectionsContainer') ? 'VEHICLES.HIDE_COLLECTIONS': 'VEHICLES.SHOW_COLLECTIONS')| translate }}"
				tooltipPosition="bottom" (click)="toggleElement('collectionsContainer')"></button>
			<button type="button" pButton pRipple icon="fa fa-chart-line" class="p-mx-1"
				pTooltip="{{(isShownElement('timelineContainer') ? 'VEHICLES.HIDE_TIMELINE': 'VEHICLES.SHOW_TIMELINE')| translate }}"
				tooltipPosition="bottom" (click)="toggleElement('timelineContainer')"></button>
			<button *ngIf="!timelineMinMaxByData" type="button" pButton icon="fa fa-maximize"
				(click)="toggleTimelineMinMaxMode()" class="p-button p-mx-1"
				pTooltip="{{ 'VEHICLES.TIMELINE_SCALE_SELECTED' | translate }}" tooltipPosition="bottom"></button>
			<button *ngIf="timelineMinMaxByData" type="button" pButton icon="fa fa-minimize"
				(click)="toggleTimelineMinMaxMode()" class="p-button p-mx-1"
				pTooltip="{{ 'VEHICLES.TIMELINE_SCALE_BY_DATA' | translate }}" tooltipPosition="bottom"></button>

			<button type="button" pButton pRipple icon="fa fa-map-pin" class="p-ml-3"
				pTooltip="{{(showAreas ? 'VEHICLES.HIDE_AREAS': 'VEHICLES.SHOW_AREAS')| translate }}"
				tooltipPosition="bottom" (click)="showHideAreas(!showAreas)"></button>
		</div>
		<div class="p-toolbar-group-right">
			<div *ngIf="vehicles && vehicles.length > 0">
				<button pButton pRipple icon="pi pi-reply" class="p-button-rounded p-button p-mr-2"
					(click)="showAllRoutes()" pTooltip="{{'VEHICLES.SHOW_ALL_ROUTES'|translate}}"
					tooltipPosition="bottom" showDelay="500"></button>
				<button pButton pRipple icon="pi pi-reply" class="p-button-rounded p-button-outlined p-button p-mr-2"
					(click)="hideAllRoutes()" pTooltip="{{'VEHICLES.HIDE_ALL_ROUTES'|translate}}"
					tooltipPosition="bottom" showDelay="500"></button>
				<button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button p-mr-2"
					(click)="showAllCollections()" pTooltip="{{'VEHICLES.SHOW_ALL_COLLECTIONS'|translate}}"
					tooltipPosition="bottom" showDelay="500"></button>
				<button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button p-mr-2"
					(click)="hideAllCollections()" pTooltip="{{'VEHICLES.HIDE_ALL_COLLECTIONS'|translate}}"
					tooltipPosition="bottom" showDelay="500"></button>
			</div>
		</div>
	</p-toolbar>

	<!-- Content -->
	<div class="p-grid p-p-2 p-mt-2" style="flex: 1 1 0;">
		<div [hidden]="!isShownElement('vehicleContainer') && !isShownElement('collectionsContainer')"
			[ngClass]="isShownElement('mapContainer') ? 'p-col-12 p-md-12 p-lg-6 p-xl-6 p-p-1' : 'p-col-12 p-p-1'"
			style="display: flex; flex-direction: column;">
			<div class="p-grid" style="flex: 1 1 0;">
				<div #vehicleContainer [hidden]="!isShownElement('vehicleContainer')" class="p-col-12"
					style="min-height: 350px;">
					<p-table #vehicleTable (onColResize)=" onVehicleColResize($event)"
						(onColReorder)="onVehicleColReorder($event)" (onFilter)="onVehicleFilter($event)"
						[globalFilterFields]="vehicleFilters" [reorderableColumns]="true" [resizableColumns]="true"
						scrollHeight="flex" [virtualScroll]="true" columnResizeMode="expand" dataKey="ds_auto_id"
						scrollDirection="both" [stateKey]="vehicleStateName" stateStorage="local"
						styleClass="p-datatable-gridlines p-datatable-sm" [columns]="vehicleCols" [rows]="100"
						[scrollable]="true" [value]="vehicles">
						<ng-template pTemplate="caption">
							<div class="p-d-flex p-ai-center p-jc-between">
								<div>
								</div>
								<h5 class="p-m-0">{{ 'MENU.FAHRZEUG' | translate }}</h5>
								<span class="p-input-icon-left">
									<i class="pi pi-search"></i>
									<input pInputText id="global" type="text" [value]="vehicleGlobalFilter"
										(input)="vehicleTable.filterGlobal($event.target.value, 'contains');vehicleGlobalFilter = $event.target.value;"
										placeholder="{{'BUTTONS.SEARCH'|translate}}" />
								</span>
							</div>
						</ng-template>
						<ng-template pTemplate="header" let-columns>
							<tr>
								<th pResizableColumn [pSortableColumn]="'onlineStatus'"
									[style.flex]="'0 0 ' + onlineStatusWidth + 'px !important'">
									<div class="p-d-flex p-ai-center p-jc-between" style="min-width: 100%">
										<div>
											<p-sortIcon [field]="'onlineStatus'"></p-sortIcon>
											{{ 'HEADERS.onlineStatus'| translate }}
										</div>
										<p-columnFilter [type]="'onlineStatus'" [field]="'onlineStatus'" display="menu"
											appendTo="body"
											[style.color]="(isColFiltered('onlineStatus') ? 'var(--primary-color)' : 'var(--text-color)')">
										</p-columnFilter>
									</div>
								</th>
								<th *ngFor="let col of columns" [style.flex]="'0 0 ' + col.width + 'px !important'"
									[pSortableColumn]="col.key" pResizableColumn pReorderableColumn>
									<div class="p-d-flex p-ai-center p-jc-between" style="min-width: 100%">
										<div>
											<p-sortIcon [field]="col.key"></p-sortIcon>
											{{ 'HEADERS.' + col.key | translate }}
										</div>
										<p-columnFilter [type]="col.type" [field]="col.key" display="menu"
											appendTo="body"
											[style.color]="(isColFiltered(col.key) ? 'var(--primary-color)' : 'var(--text-color)')">
										</p-columnFilter>
									</div>
								</th>
								<th id="buttoncolhead" [style.flex]="'0 0 ' + vehicleButtonColWidth + 'px'"
									alignFrozen="right" pFrozenColumn>
									<p-multiSelect #vehicleColSelection [options]="vehiclePossibleCols"
										[(ngModel)]="vehicleCols" dataKey="key" (onChange)="toggleVehicleColumn($event)"
										class="colselect" optionLabel="label" appendTo="body"
										[style.visibility]="'hidden'" [style.position]="'absolute'"
										[panelStyle]="{'min-width':'auto'}">
									</p-multiSelect>
									<button pButton pRipple icon="fas fa-table-columns"
										class="p-button-rounded p-button-secondary p-mr-2"
										(click)="vehicleColSelection.show($event.originalEvent);"
										pTooltip="{{'BUTTONS.COL_SELECT'|translate}}" tooltipPosition="bottom"
										showDelay="500"></button>
									<button pButton pRipple icon="pi pi-filter-slash"
										class="p-button-rounded p-button-secondary p-mr-2"
										(click)="vehicleTable.clear()" pTooltip="{{'BUTTONS.RESET_FILTERS'|translate}}"
										tooltipPosition="bottom" showDelay="500"></button>
									<button pButton pRipple icon="fas fa-table-cells"
										class="p-button-rounded p-button-secondary" (click)="resetTable(vehicleTable)"
										pTooltip="{{'BUTTONS.RESET_TABLE'|translate}}" tooltipPosition="bottom"
										showDelay="500"></button>
								</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-entry let-rowIndex="rowIndex" let-columns="columns">
							<tr [pContextMenuRow]="entry" [pSelectableRow]="entry"
								[style.background]="centeringMapOnVehicle == entry['mapId'] ? 'var(--surface-b)' : ''">
								<td [style.flex]="'0 0 120px !important'">
									<div *ngIf="entry.onlineStatus === null" pReorderableRowHandle>
										<i class="pi pi-circle-off" pReorderableRowHandle></i>
									</div>
									<div *ngIf="entry.onlineStatus < 0" pReorderableRowHandle>
										<i class="pi pi-circle-on" style="color: crimson" pReorderableRowHandle></i>
									</div>
									<div *ngIf="entry.onlineStatus === 0" pReorderableRowHandle>
										<i class="pi pi-circle-on" style="color: orange" pReorderableRowHandle></i>
									</div>
									<div *ngIf="entry.onlineStatus > 0" pReorderableRowHandle>
										<i class="pi pi-circle-on" style="color: green" pReorderableRowHandle></i>
									</div>
								</td>
								<td *ngFor="let col of columns" [style.flex]="'0 0 ' + col.width + 'px !important'">
									<div *ngIf="entry[col.key] === null">
										<i class="pi pi-minus"></i>
									</div>
									<div *ngIf="entry[col.key] !== null">
										<div *ngIf="col.type == 'text'">
											{{ entry[col.key] }}
										</div>
										<div *ngIf="col.type == 'numeric'">
											{{ entry[col.key] }}
										</div>
										<div *ngIf="col.type == 'boolean'">
											<i [ngClass]="entry[col.key] ? 'pi pi-check' : 'pi pi-times'"
												[style.color]="entry[col.key] ? 'green' : 'red'"></i>
										</div>
										<div *ngIf="col.type == 'date'">
											{{ (entry[col.key] ? entry[col.key].toLocaleString() : entry[col.key]) }}
										</div>
									</div>
								</td>
								<td class="buttoncolbody" [style.flex]="'0 0 ' + vehicleButtonColWidth + 'px'"
									alignFrozen="right" pFrozenColumn>
									<button pButton pRipple icon="pi pi-arrow-left"
										class="p-button-rounded p-button-danger p-mr-2" (click)="removeVehicle(entry)"
										pTooltip="{{'BUTTONS.REMOVE'|translate}}" tooltipPosition="bottom"
										showDelay="500"></button>
									<button *ngIf="centeringMapOnVehicle==entry['mapId']" pButton pRipple
										icon="pi pi-map-marker" class="p-button-rounded p-button p-mr-2"
										(click)="centerOnVehicle(entry)" pTooltip="{{'BUTTONS.CENTER'|translate}}"
										tooltipPosition="bottom" showDelay="500"></button>
									<button *ngIf="centeringMapOnVehicle!=entry['mapId']" pButton pRipple
										icon="pi pi-map-marker"
										class="p-button-rounded p-button-outlined p-button p-mr-2"
										(click)="centerOnVehicle(entry)" pTooltip="{{'BUTTONS.CENTER'|translate}}"
										tooltipPosition="bottom" showDelay="500"></button>
									<button *ngIf="visibleRoutes.indexOf(entry['mapId']) > -1" pButton pRipple
										icon="pi pi-reply" class="p-button-rounded p-button p-mr-2"
										(click)="switchRouteVisbility(entry)"
										pTooltip="{{'BUTTONS.SHOW_HIDE_ROUTE'|translate}}" tooltipPosition="bottom"
										showDelay="500"></button>
									<button *ngIf="visibleRoutes.indexOf(entry['mapId']) < 0" pButton pRipple
										icon="pi pi-reply" class="p-button-rounded p-button-outlined p-button p-mr-2"
										(click)="switchRouteVisbility(entry)"
										pTooltip="{{'BUTTONS.SHOW_HIDE_ROUTE'|translate}}" tooltipPosition="bottom"
										showDelay="500"></button>
									<button *ngIf="visibleCollections.indexOf(entry['mapId']) > -1" pButton pRipple
										icon="pi pi-trash" class="p-button-rounded p-button p-mr-2"
										(click)="switchCollectionVisbility(entry)"
										pTooltip="{{'BUTTONS.SHOW_HIDE_COLLECTIONS'|translate}}"
										tooltipPosition="bottom" showDelay="500"></button>
									<button *ngIf="visibleCollections.indexOf(entry['mapId']) < 0" pButton pRipple
										icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button p-mr-2"
										(click)="switchCollectionVisbility(entry)"
										pTooltip="{{'BUTTONS.SHOW_HIDE_COLLECTIONS'|translate}}"
										tooltipPosition="bottom" showDelay="500"></button>
									<p-colorPicker [(ngModel)]="routeColors[entry['mapId']]"
										(onChange)="redrawRoute(entry); updateTimelineData(entry);" appendTo="body"
										styleClass="colorpicker" pTooltip="{{'BUTTONS.PICK_COLOR'|translate}}"
										tooltipPosition="bottom" showDelay="500"></p-colorPicker>
								</td>
							</tr>
						</ng-template>
						<ng-template pTemplate="summary">
							<div class="p-d-flex p-ai-center p-jc-between">
								{{ 'TABLE.COUNT' | translate : { count : vehicleCount } }}
								{{ (vehicleTable && vehicleTable.filteredValue ? 'TABLE.TOTAL' : '') | translate : {
								vehicleCount : (vehicles
								? vehicles.length
								: 0) } }}
							</div>
						</ng-template>
					</p-table>
				</div>
				<div #collectionsContainer [hidden]="!isShownElement('collectionsContainer')" class="p-col-12"
					style="min-height: 350px;">
					<p-table #collectionTable (onColResize)="onCollectionColResize($event)" styleClass="p-datatable-sm"
						(onColReorder)="onCollectionColReorder($event)" (onFilter)="onCollectionFilter($event)"
						[(selection)]="collectionSelection" [globalFilterFields]="collectionFilters"
						[reorderableColumns]="true" [resizableColumns]="true" scrollHeight="flex" [virtualScroll]="true"
						columnResizeMode="expand" (onRowSelect)="onRowSelect($event)" dataKey="autoid"
						scrollDirection="both" selectionMode="single" [stateKey]="collectionStateName"
						stateStorage="local" styleClass="p-datatable-gridlines p-datatable-sm"
						[columns]="collectionCols" [rows]="100" [scrollable]="true" [value]="collections">
						<ng-template pTemplate="caption">
							<div class="p-d-flex p-ai-center p-jc-between">
								<div>
								</div>
								<h5 class="p-m-0">{{ 'MENU.LEERUNGEN' | translate }}
									(Logbox: {{collections && collections[0] && collections[0].f_system_id ?
									collections[0].f_system_id : '-'}})</h5>
								<span class="p-input-icon-left">
									<i class="pi pi-search"></i>
									<input pInputText id="global" type="text" [value]="collectionGlobalFilter"
										(input)="collectionTable.filterGlobal($event.target.value, 'contains');collectionGlobalFilter = $event.target.value;"
										placeholder="{{'BUTTONS.SEARCH'|translate}}" />
								</span>
							</div>
						</ng-template>
						<ng-template pTemplate="header" let-columns>
							<tr>
								<th *ngFor="let col of columns" [style.flex]="'0 0 ' + col.width + 'px !important'"
									[pSortableColumn]="col.key" pResizableColumn pReorderableColumn>
									<div class="p-d-flex p-ai-center p-jc-between" style="min-width: 100%">
										<div>
											<p-sortIcon [field]="col.key"></p-sortIcon>
											{{ 'HEADERS.' + col.key | translate }}
										</div>
										<p-columnFilter [type]="col.type" [field]="col.key" display="menu"
											appendTo="body"
											[style.color]="(isColFiltered(col.key) ? 'var(--primary-color)' : 'var(--text-color)')">
										</p-columnFilter>
									</div>
								</th>
								<th id="buttoncolhead" [style.flex]="'0 0 ' + collectionButtonColWidth + 'px'"
									alignFrozen="right" pFrozenColumn>
									<p-multiSelect #collectionColSelection [options]="collectionPossibleCols"
										[(ngModel)]="collectionCols" dataKey="key"
										(onChange)="toggleCollectionColumn($event)" optionLabel="label"
										class="colselect" appendTo="body" [style.visibility]="'hidden'"
										[style.position]="'absolute'" [panelStyle]="{'min-width':'auto'}">
									</p-multiSelect>
									<button pButton pRipple icon="fas fa-table-columns"
										class="p-button-rounded p-button-secondary p-mr-2"
										(click)="collectionColSelection.show($event.originalEvent);"
										pTooltip="{{'BUTTONS.COL_SELECT'|translate}}" tooltipPosition="bottom"
										showDelay="500"></button>
									<button pButton pRipple icon="pi pi-filter-slash"
										class="p-button-rounded p-button-secondary p-mr-2"
										(click)="collectionTable.clear()"
										pTooltip="{{'BUTTONS.RESET_FILTERS'|translate}}" tooltipPosition="bottom"
										showDelay="500"></button>
									<button pButton pRipple icon="fas fa-table-cells"
										class="p-button-rounded p-button-secondary"
										(click)="resetTable(collectionTable)"
										pTooltip="{{'BUTTONS.RESET_TABLE'|translate}}" tooltipPosition="bottom"
										showDelay="500"></button>
								</th>
							</tr>
						</ng-template>
						<ng-template pTemplate="body" let-entry let-rowIndex="rowIndex" let-columns="columns">
							<tr [pContextMenuRow]="entry" [pSelectableRow]="entry">
								<td *ngFor="let col of columns" [style.flex]="'0 0 ' + col.width + 'px !important'">
									<div *ngIf="entry[col.key] === null">
										<i class="pi pi-minus"></i>
									</div>
									<div *ngIf="entry[col.key] !== null">
										<div *ngIf="col.type == 'text'">
											{{ entry[col.key] }}
										</div>
										<div *ngIf="col.type == 'numeric'">
											{{ entry[col.key] }}
										</div>
										<div *ngIf="col.type == 'boolean'">
											<i [ngClass]="entry[col.key] ? 'pi pi-check' : 'pi pi-times'"
												[style.color]="entry[col.key] ? 'green' : 'red'"></i>
										</div>
										<div *ngIf="col.type == 'date'">
											{{ (entry[col.key] ? entry[col.key].toLocaleString() : '') }}
										</div>
									</div>
								</td>
								<td></td>
							</tr>
						</ng-template>
						<ng-template pTemplate="summary">
							<div class="p-d-flex p-ai-center p-jc-between">
								{{ 'TABLE.COUNT' | translate : { count : collectionCount } }}
								{{ (collectionTable && collectionTable.filteredValue ? 'TABLE.TOTAL' : '') | translate :
								{
								collectionCount : (collections
								? collections.length
								: 0) } }}
							</div>
						</ng-template>
					</p-table>
				</div>
			</div>
		</div>
		<div [ngClass]="(isShownElement('vehicleContainer') || isShownElement('collectionsContainer')) ? 'p-col-12 p-md-12 p-lg-6 p-xl-6 p-p-1' : 'p-col-12 p-p-1'"
			style="display: flex; flex-direction: column;">
			<div class="p-grid" style="flex: 1 1 0;flex-direction: column;">
				<div #mapContainer [hidden]="!isShownElement('mapContainer')" class="p-col-12" style="flex: 1 1 400px;">
					<div id="onlineMap" class="p-m-0 p-p-0" style="height: 100%; width: 100%;"></div>
				</div>
				<div #timelineContainer [hidden]="!isShownElement('timelineContainer')" class="p-col-12"
					style="flex: 0 0 175px;">
					<p-chart type="line" [data]="timelineData" height="175px" [responsive]="true"
						(onDataSelect)="selectTimelineData($event)" [options]="timelineOptions">
					</p-chart>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- Tooltip -->
<div id="mapTooltip" style="position: absolute; display: none;">
	<span class="p-tag">
		{{ tooltipText }}
	</span>
</div>

<!-- Message -->
<p-toast>
	<ng-template let-message pTemplate="message">
		<div class="p-grid p-ml-2">
			<div class="p-col-2">
				<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
			</div>
			<div class="p-col-10">
				<h4>{{message.summary}}</h4>
			</div>
			<div class="p-col-12">
				<p>{{message.detail}}</p>
			</div>
			<div *ngIf="message.severity!='success'" class="p-col-12">
				<a class="p-button"
					href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
					Fehler melden
				</a>
			</div>
		</div>
	</ng-template>
</p-toast>

<!-- Spinner -->
<div *ngIf="loading!==0" class="spinner">
	<span class="ball-1"></span>
	<span class="ball-2"></span>
	<span class="ball-3"></span>
	<span class="ball-4"></span>
	<span class="ball-5"></span>
	<span class="ball-6"></span>
	<span class="ball-7"></span>
	<span class="ball-8"></span>
</div>