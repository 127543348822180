import { ErrorHandler, Injectable } from "@angular/core";
import { LogService } from "@app/services/log.service";

@Injectable()
export class ExceptionHandler implements ErrorHandler {
	constructor(
		private logService: LogService
	) { }

	/**
	 * Error loggen
	 * 
	 * @param error Error der geloggt werden soll
	 */
	handleError(error: Error): void {
		this.logService.log("Error", 1, error.name, error.message + (error.stack ? ': \n' + error.stack : ''));
	}
}