<div class="p-grid">
	<div class="p-col-12 p-p-0">

		<!-- Message -->
		<p-toast>
			<ng-template let-message pTemplate="message">
				<div class="p-grid p-ml-2">
					<div class="p-col-2">
						<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
					</div>
					<div class="p-col-10">
						<h4>{{message.summary}}</h4>
					</div>
					<div class="p-col-12">
						<p>{{message.detail}}</p>
					</div>
					<div *ngIf="message.severity!='success'" class="p-col-12">
						<a class="p-button"
							href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
							Fehler melden
						</a>
					</div>
				</div>
			</ng-template>
		</p-toast>

		<!-- Spinner -->
		<div *ngIf="loading!==0" class="spinner">
			<span class="ball-1"></span>
			<span class="ball-2"></span>
			<span class="ball-3"></span>
			<span class="ball-4"></span>
			<span class="ball-5"></span>
			<span class="ball-6"></span>
			<span class="ball-7"></span>
			<span class="ball-8"></span>
		</div>

		<div class="card p-mb-0">
			<!-- Content -->
			<div class="p-grid">
				<div class="p-col-12 p-md-6 p-lg-4 p-my-2">
					<h6 class="p-my-2" translate>gültig von</h6>
					<p-calendar [style]="{'width':'100%'}" [showIcon]="true" dateFormat="dd.mm.yy" keepInvalid="true"
						(onInput)="inputDate('date_from', $event.target.value)" [(ngModel)]="date_from" appendTo="body">
					</p-calendar>
				</div>
				<div class="p-col-12 p-md-6 p-lg-4 p-my-2">
					<h6 class="p-my-2" translate>gültig bis</h6>
					<p-calendar [style]="{'width':'100%'}" [showIcon]="true" dateFormat="dd.mm.yy" keepInvalid="true"
						(onInput)="inputDate('date_to', $event.target.value)" [(ngModel)]="date_to" appendTo="body">
					</p-calendar>
				</div>
				<div class="p-col-12 p-md-6 p-lg-4 p-my-2">
					<h6 class="p-my-2" translate>Gefäße</h6>
					<div class="p-inputgroup">
						<p-dropdown class="dropdown-button-group" [style]="{'width':'100%'}" [options]="options"
							[(ngModel)]="selectedGarbageCanId" placeholder="-" [showClear]="true" [virtualScroll]="true"
							itemSize="30" [filter]="true" filterBy="label" appendTo="body" dropdownDirective>
							<ng-template let-item pTemplate="item">
								<table width="100%" style="white-space: normal;">
									<tr>
										<td width="40%">
											{{item.ankey}}
										</td>
										<td width="60%">
											{{item.bezeichnung}}
										</td>
									</tr>
								</table>
							</ng-template>
						</p-dropdown>
					</div>
				</div>
			</div>

			<button pButton pRipple label="{{'BUTTONS.SAVE'|translate}}" class="p-button p-mt-5" style="width:100%"
				(click)="save()" [disabled]="!selectedGarbageCanId"></button>
		</div>
	</div>
</div>