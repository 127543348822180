import { Component, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { CreateComponent } from '@app/common/templates/create/create.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TblFahrzeugAbwicklungAbschnittTypCreateComponent } from '@app/views/stammdaten/tbl_fahrzeug_abwicklung_abschnitt_typ/create/create.component';

@Component({
	//templateUrl: '../../../../common/templates/create/create.component.html',
	//styleUrls: ['../style.scss', '../../../../common/templates/create/style.scss'],
	templateUrl: '../../../../../common/templates/create/create.component.html',
	styleUrls: ['../../style.scss', '../../../../../common/templates/create/style.scss'],
	providers: [DialogService, MessageService, ConfirmationService]
})
export class VehicleAddProcesstaskDialogComponent extends CreateComponent {
	fahrzeug_abwicklung_id: number = null;

	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		@Optional() public config: DynamicDialogConfig,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public dialogService: DialogService,
		public globals: Globals,
		public messageService: MessageService,
		@Optional() public ref: DynamicDialogRef,
		public router: Router,
		public translate: TranslateService,
	) {
		super(accountService, breadcrumbService, config, confirmationService, crudService, dialogService, globals, messageService, ref, router, translate);

		if( this.config && this.config.data ) {
			// init custom button label
			if( this.config.data['abwicklung_id'] ) {
				this.fahrzeug_abwicklung_id = this.config.data['abwicklung_id'];
			}
		}

		this.apiUrl = 'TblFahrzeugAbwicklungAbschnitt';
		this.name = 'MENU.FAHRZEUG_ABWICKLUNG_ABSCHNITT';
		this.url = '/' + PATH.FAHRZEUG_ABWICKLUNG_ABSCHNITT;
		this.fieldgroups.push(
			[
				{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
				{ type: 'text', key: 'bezeichnung_kurz', required: false, width: 400 },
				{ type: 'object', key: 'abschnitt_typ_id', label: 'abschnitt_typ', required: false, width: 400, apiUrl: 'TblFahrzeugAbwicklungAbschnittTyp', createComponent: TblFahrzeugAbwicklungAbschnittTypCreateComponent },
				{ type: 'text', key: 'bemerkungen', required: false, width: 400 },
			]
		);
		this.breadcrumbService.setItems([
			{ label: 'MENU.STAMMDATEN' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.CREATE', routerLink: [this.url + '/' + PATH.CREATE] }
		]);
	}

	getOptions(): void {
		this.fieldgroups.forEach(fg => {
			fg.forEach(field => {
				if (field.type == 'object' || field.type == 'list') {
					this.loading += 1;
					this.crudService.getAllEntries(field.apiUrl).then(res => {
						this.options[field.key] = res.map(option => ({ label: option.bezeichnung, value: option.ds_this_id, object: option }));
						field.optionSortFieldOptions = [
							{ label: this.translate.instant('HEADERS.lfd_nummer'), value: 'lfd_nummer' },
							{ label: this.translate.instant('HEADERS.ankey'), value: 'ankey' },
							{ label: this.translate.instant('HEADERS.Bezeichnung'), value: 'bezeichnung' }
						];
						//field.optionSortFieldOptions = Object.keys(res[0]).map(key => ({ label: this.translate.instant('HEADERS.' + key), value: key }));
						//field.optionSortFieldOptions = field.optionSortFieldOptions.filter(option => !option.label.includes('HEADERS.'));
						this.changeSorting(field, true);
					}).catch(err => {
						err.error.forEach(e => {
							this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
						})
					}).finally(() => {
						this.loading -= 1;
					});
				}
			});
		});
	}

	/*
	 * override
	 */
	createEntry(): void {
		this.entry['fahrzeug_abwicklung_id'] = this.fahrzeug_abwicklung_id;
		if( this.entry['fahrzeug_abwicklung_id'] && this.entry['fahrzeug_abwicklung_id'] > 0 ) {
			super.createEntry();
		}
	}
}
