import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Globals, VIONSessionStorage } from '@app/common/global_variables';
import { Base } from '@app/models/base';
import { Module, Operation } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { ExportService } from '@app/services/export.service';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { MultiSelect } from 'primeng/multiselect';
import { Table } from 'primeng/table';
import { ReportsTripsFilterDialogComponent } from '../filter_dialog/filter_dialog.component';

@Component({
	templateUrl: './list.component.html',
	styleUrls: ['../style.scss'],
	providers: [MessageService, ConfirmationService, DialogService]
})
export class TblFahrtberichtListComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('table') table: Table;
	@ViewChild('colselection') colselection: MultiSelect;
	apiUrl: string = '';
	buttonColWidth: number = 150;
	cols: any[] = [];
	contentHeight: number = 4320;
	contextMenu: MenuItem[];
	count: number = 0;
	createPermission: boolean = false;
	deletePermission: boolean = false;
	entries: Base[];
	filters: string[];
	globalFilter: string = '';
	isMobile: boolean = false;
	isTableInit: boolean = false;
	loadFilters: any;
	loading: number = 0;
	loadTimestamp: Date;
	name: string = '';
	oldCols: any[] = [];
	persistenceCheckInterval: any;
	possibleCols: any[] = [];
	readPermission: boolean = false;
	selectedEntry: Base;
	state: any;
	stateName: string = '';
	trackByFunction = (index, item) => {
		return item.ds_auto_id;
	}
	updatePermission: boolean = false;
	url: string = '';

	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public changeDetectorRef: ChangeDetectorRef,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public dialogService: DialogService,
		public elRef: ElementRef,
		public exportService: ExportService,
		public globals: Globals,
		public messageService: MessageService,
		public router: Router,
		public settingsService: SettingsService,
		public translate: TranslateService,
	) {
		this.apiUrl = 'TblFahrtbericht';
		this.name = 'MENU.FAHRTBERICHTE';
		this.url = '/' + PATH.FAHRTEBRICHT;
		this.stateName = 'state' + this.apiUrl + 'List';

		this.possibleCols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'date', key: 'anfang_zeit', required: false, width: 400 },
			{ type: 'date', key: 'ende_zeit', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_tour_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_abfallart_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_typ_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_entsorger_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_fahrzeug_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_fahrer_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_lader1_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_lader2_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_lader3_bezeichnung', required: false, width: 400 },
			{ type: 'numeric', key: 'ende_anzahl_2rad', required: false, width: 400 },
			{ type: 'numeric', key: 'ende_anzahl_4rad', required: false, width: 400 },
		];
		this.cols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'date', key: 'anfang_zeit', required: false, width: 400 },
			{ type: 'date', key: 'ende_zeit', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_tour_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_abfallart_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_typ_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_fahrzeug_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_fahrer_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_lader1_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_lader2_bezeichnung', required: false, width: 400 },
			{ type: 'numeric', key: 'ende_anzahl_2rad', required: false, width: 400 },
			{ type: 'numeric', key: 'ende_anzahl_4rad', required: false, width: 400 },
		];

		this.breadcrumbService.setItems([
			{ label: 'MENU.RUECKFAHRKATASTER' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.LIST', routerLink: [this.url + '/' + PATH.LIST] }
		]);
	}

	// #region Angular

	ngOnInit(): void {
		this.loading += 1;

		this.stateName = 'state' + this.apiUrl + 'List';

		this.createPermission = this.accountService.checkPermissions(Module.Masterdata, Operation.CREATE);
		this.readPermission = this.accountService.checkPermissions(Module.Masterdata, Operation.READ);
		this.updatePermission = this.accountService.checkPermissions(Module.Masterdata, Operation.UPDATE);
		this.deletePermission = this.accountService.checkPermissions(Module.Masterdata, Operation.DELETE);

		if (this.readPermission) {
			const storage = this.storageGetEntries();
			if (storage && storage.timestamp && storage.entries && storage.filters) {
				this.loading += 1;
				const storedTimestamp = new Date(storage.timestamp);
				this.loadTimestamp = storedTimestamp;
				this.loadFilters = storage.filters;
				this.processEntries(storage.entries);
				this.crudService.checkPersistence(this.apiUrl, storedTimestamp).then(isPersistent => {
					if (!isPersistent) {
						this.messageService.add({ key: 'refresh', severity: 'warn', summary: this.translate.instant('BUTTONS.REFRESH'), detail: this.translate.instant('MESSAGES.NEW_DATA_AVAILABLE') });
					}
				}).catch(err => {
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				}).finally(() => {
					this.loading -= 1;
				});
			} else {
				if ((localStorage.getItem('defaultOpenVehicleDialog') === 'true')) {
					this.openFilterDialog();
				}
			}
		}

		this.retrieveTableState(this.state);
		if (this.state && this.state.columnOrder) {
			this.cols = [];
			this.state.columnOrder.forEach(col => {
				this.possibleCols.forEach(c => {
					if (col == c.key) {
						this.cols.push(c);
					}
				});
			});
		}
		if (this.state && this.state.filters && this.state.filters.global) {
			this.globalFilter = this.state.filters.global.value;
		}

		this.isMobile = JSON.parse(this.globals.log_Platform).Mobile == 'yes' || JSON.parse(this.globals.log_Platform).Tablet == 'yes';

		this.translate.get('init').subscribe((text: string) => {
			this.contextMenu = [
				{ label: this.translate.instant('CONTEXT_MENU.CREATE'), icon: 'pi pi-fw pi-plus', command: () => this.create() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN'), icon: 'pi pi-fw pi-search', command: () => this.detail() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_TAB'), icon: 'pi pi-fw pi-search', command: () => this.detail('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_WINDOW'), icon: 'pi pi-fw pi-search', command: () => this.detail('window') },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT'), icon: 'pi pi-fw pi-pencil', command: () => this.edit() },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT_TAB'), icon: 'pi pi-fw pi-pencil', command: () => this.edit('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT_WINDOW'), icon: 'pi pi-fw pi-pencil', command: () => this.edit('window') },
				{ label: this.translate.instant('CONTEXT_MENU.DELETE'), icon: 'pi pi-fw pi-trash', command: () => this.delete() }
			];
			this.possibleCols.forEach(c => {
				c.label = this.translate.instant('HEADERS.' + c.key);
			});
		});

		this.oldCols = this.cols;
		this.filters = this.cols.map(c => c.key);

		this.loading -= 1;
	}

	ngAfterViewInit(): void {
		this.settingsService.footerVisibilityChange.subscribe(value => {
			this.initTable();
		});

		if (this.table.filters) {
			let restoredFilter = false;
			this.filters.forEach(col => {
				Object.keys(this.table.filters[col]).forEach(filter => {
					if (this.table.filters[col][filter]['value'] != null) {
						restoredFilter = true;
					}
				})
			});
			if (restoredFilter) {
				this.messageService.add({ key: 'reset', severity: 'warn', summary: this.translate.instant('MESSAGES.WARNING'), detail: this.translate.instant('MESSAGES.LOADED_FILTER'), life: 10000 });
			}
		}

		const el = document.querySelector<HTMLElement>('.cdk-virtual-scroll-viewport');
		this.changeWheelSpeed(el, 0.9);

		this.initTable();
	}

	ngAfterViewChecked(): void {
		if (!this.isTableInit && this.table.value) {
			this.isTableInit = true;
			this.resizeTableWidth();
			this.changeDetectorRef.detectChanges();
		}
	}

	ngOnDestroy(): void {
		clearInterval(this.persistenceCheckInterval);
	}

	@HostListener('window:resize', ['$event'])
	onResize(event): void {
		this.initTable();
	}

	// #endregion Angular

	// #region CRUD

	create(): void {
		this.router.navigate([this.url + '/' + PATH.CREATE]);
	}

	detail(target?: string): void {
		if (target == 'window') {
			window.open('/#/' + this.url + '/' + PATH.DETAIL + '/' + this.selectedEntry.ds_this_id, '_blank', 'newWindow=1');
		} else if (target == 'tab') {
			window.open('/#/' + this.url + '/' + PATH.DETAIL + '/' + this.selectedEntry.ds_this_id);
		} else {
			this.router.navigate([this.url + '/' + PATH.DETAIL + '/' + this.selectedEntry.ds_this_id]);
		}
	}

	edit(target?: string): void {
		if (target == 'window') {
			window.open('/#/' + this.url + '/' + PATH.EDIT + '/' + this.selectedEntry.ds_this_id, '_blank', 'newWindow=1');
		} else if (target == 'tab') {
			window.open('/#/' + this.url + '/' + PATH.EDIT + '/' + this.selectedEntry.ds_this_id);
		} else {
			this.router.navigate([this.url + '/' + PATH.EDIT + '/' + this.selectedEntry.ds_this_id]);
		}
	}

	delete(ds_this_id?: number): void {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.DELETE_QUESTION'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.loading += 1;
				let sections: number[];
				this.crudService.getFilteredTripReportSections(ds_this_id ? ds_this_id : this.selectedEntry.ds_this_id).then(res => {
					this.loading += 1;
					this.crudService.deleteTripReport(ds_this_id ? ds_this_id : this.selectedEntry.ds_this_id).then(res => {
						this.loading += 1;
						this.crudService.deleteTripReportSections(sections).then(res => {
							// Do nothing
						}).catch(err => {
							err.error.forEach(e => {
								this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
							})
						}).finally(() => {
							this.loading -= 1;
						});
						this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });
						this.entries.splice(this.entries.findIndex(x => x.ds_this_id == (ds_this_id ? ds_this_id : this.selectedEntry.ds_this_id)), 1);
						this.count = this.entries.length;
						this.entries = [...this.entries];
					}).catch(err => {
						err.error.forEach(e => {
							this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
						})
					}).finally(() => {
						this.loading -= 1;
					});
				}).catch(err => {
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				}).finally(() => {
					this.loading -= 1;
				});
			}
		});
	}

	// #endregion CRUD

	// #region Table

	changeWheelSpeed(container, speedY): any {
		var scrollY = 0;
		var handleScrollReset = function () {
			scrollY = container.scrollTop;
		};
		var handleMouseWheel = function (e) {
			e.preventDefault();
			scrollY += speedY * e.deltaY
			if (scrollY < 0) {
				scrollY = 0;
			} else {
				var limitY = container.scrollHeight - container.clientHeight;
				if (scrollY > limitY) {
					scrollY = limitY;
				}
			}
			container.scrollTop = scrollY;
		};

		var removed = false;
		container.addEventListener('mouseup', handleScrollReset, false);
		container.addEventListener('mousedown', handleScrollReset, false);
		container.addEventListener('mousewheel', handleMouseWheel, false);

		return function () {
			if (removed) {
				return;
			}
			container.removeEventListener('mouseup', handleScrollReset, false);
			container.removeEventListener('mousedown', handleScrollReset, false);
			container.removeEventListener('mousewheel', handleMouseWheel, false);
			removed = true;
		};
	}

	exportCSV(): void {
		this.exportService.exportCSV(this.translate.instant('MENU.FAHRTBERICHTE'), (this.table && this.table.filteredValue ? this.table.filteredValue : this.table.value), this.cols);
	}

	exportPDF(): void {
		this.exportService.exportPDF(this.translate.instant('MENU.FAHRTBERICHTE'), (this.table && this.table.filteredValue ? this.table.filteredValue : this.table.value), this.cols);
	}

	exportXLSX(): void {
		this.exportService.exportXLSX(this.translate.instant('MENU.FAHRTBERICHTE'), (this.table && this.table.filteredValue ? this.table.filteredValue : this.table.value), this.cols);
	}

	getFilteredEntries(filters): void {
		this.loading += 1;
		this.messageService.clear('refresh');
		this.crudService.getFilteredTripReports(filters).then(res => {
			this.messageService.clear();
			this.loadTimestamp = new Date();
			this.processEntries(res);
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	initTable(): void {
		setTimeout(() => {
			this.contentHeight = 400;
			this.resizeTableWidth();
		}, 0);
		setTimeout(() => {
			if (this.elRef && this.elRef.nativeElement && this.elRef.nativeElement.parentElement && this.elRef.nativeElement.parentElement.offsetHeight) {
				this.contentHeight = this.elRef.nativeElement.parentElement.offsetHeight - 200 + ((localStorage.getItem('showFooter') === 'true') ? 5 : 0)
				if (this.contentHeight < 400) {
					this.contentHeight = 400;
				}
			}
		}, 10);
	}

	isColFiltered(col): boolean {
		let isFiltered = false;
		if (this.table && this.table.filters[col.key]) {
			Object.keys(this.table.filters[col.key]).forEach(filter => {
				if (this.table.filters[col.key][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		else if (this.state && this.state.filters[col.key]) {
			Object.keys(this.state.filters[col.key]).forEach(filter => {
				if (this.state.filters[col.key][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		return isFiltered;
	}

	onFilter(event): void {
		this.count = this.table.filteredValue ? this.table.filteredValue.length : this.entries.length;
	}

	onColReorder(event): void {
		this.retrieveTableState(this.state);
		const columnWidths = this.state.columnWidths.split(',');
		columnWidths.splice(event.dropIndex, 0, columnWidths.splice(event.dragIndex, 1)[0]);
		this.state.columnWidths = columnWidths.join(',');
		localStorage.setItem(this.stateName, JSON.stringify(this.state));
	}

	onColResize(event): void {
		const index = Array.from(event.element.parentNode.children).indexOf(event.element);
		this.retrieveTableState(this.state);
		this.cols[index].width = Number(event.element.style.width.split('px')[0]);
		this.state.columnWidths = (this.cols.map(c => c.width)).concat([this.buttonColWidth]).join(',');
		localStorage.setItem(this.stateName, JSON.stringify(this.state));

		this.resizeTableWidth(this.state);
	}

	openFilterDialog(): void {
		const ref = this.dialogService.open(ReportsTripsFilterDialogComponent, {
			header: this.translate.instant('Fahrtberichte Filter'),
			width: '70%'
		});

		ref.onClose.subscribe((filters) => {
			if (filters) {
				this.loadFilters = filters;
				this.getFilteredEntries(filters);
			}
		});
	}

	processEntries(entries): void {
		this.loading += 1;
		if (!this.persistenceCheckInterval) {
			this.persistenceCheckInterval = setInterval(() => {
				this.crudService.checkPersistence(this.apiUrl, this.loadTimestamp).then(isPersistent => {
					if (!isPersistent) {
						this.messageService.add({ key: 'refresh', severity: 'warn', summary: this.translate.instant('BUTTONS.REFRESH'), detail: this.translate.instant('MESSAGES.NEW_DATA_AVAILABLE') });
					}
				}).catch(err => {
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				});
			}, 1000 * 60 * 10);
		}
		this.possibleCols.forEach(c => {
			if (c.type == 'date') {
				entries.forEach(e => {
					if (e[c.key] != null) {
						e[c.key] = new Date(e[c.key]);
					}
				});
			}
		});
		this.entries = entries;
		this.count = this.entries.length;
		this.storageSetEntries();
		this.loading -= 1;
	}

	resetTable(): void {
		this.table.clearState();
		window.location.reload();
	}

	resizeTableWidth(state?): void {
		this.loading += 1;

		this.retrieveTableState(this.state);
		if (this.table) {
			const tableElement = document.getElementById(this.table.id);
			tableElement.style.width = '100%';
			const columnWidths = this.state ? this.state.columnWidths.split(',') : (this.cols.map(c => c.width)).concat([this.buttonColWidth]);
			const contentWidth = columnWidths.reduce((summe, element) => summe + Number(element), 0);
			const tableWidthOffset = tableElement.clientWidth - contentWidth;
			for (let index = 0; index < this.cols.length; index++) {
				this.cols[index].width = Number(columnWidths[index]);
			}
			if (tableWidthOffset > 0 && this.cols.length > 0) {
				this.cols[this.cols.length - 1].width += tableWidthOffset;
				if (this.contentHeight < (this.table.filteredValue ? this.table.filteredValue.length : (this.table.value ? this.table.value.length : 0)) * this.table.virtualRowHeight) {
					this.cols[this.cols.length - 1].width -= 10;
				}
			}

			document.getElementById(this.table.id + '-table').style.width = this.cols.reduce((summe, element) => summe + element.width, 0) + this.buttonColWidth + 'px';
			document.getElementById(this.table.id + '-table').style.minWidth = this.cols.reduce((summe, element) => summe + element.width, 0) + this.buttonColWidth + 'px';

			setTimeout(() => {
				if (this.state) {
					localStorage.setItem(this.stateName, JSON.stringify(this.state));
				}
			}, 0);
		}

		this.loading -= 1;
	}

	retrieveTableState(state?): void {
		this.state = state ? state : JSON.parse(localStorage.getItem(this.stateName));
		if (this.table && (this.state == undefined)) {
			// for storage of table state
			this.table.saveState();
			// reload and parse
			this.state = JSON.parse(localStorage.getItem(this.stateName));
		}
	}

	storageGetEntries(): any {
		return VIONSessionStorage.getInstance().get(this.apiUrl);
	}

	storageSetEntries(): void {
		VIONSessionStorage.getInstance().set(this.apiUrl, this.loadTimestamp, this.loadFilters, this.entries);
	}

	toggleColumn(event): void {
		this.retrieveTableState(this.state);
		this.state.columnOrder = event.value.map(c => c.key);
		this.state.columnWidths = event.value.map(c => c.width);
		this.state.columnWidths = this.state.columnWidths.join(',');
		this.state.columnWidths = this.state.columnWidths + ',' + this.buttonColWidth;
		this.state.tableWidth = (this.state.columnWidths.split(',')).reduce((summe, element) => summe + Number(element), 0) + 'px';
		this.filters = event.value.map(c => c.key);
		localStorage.setItem(this.stateName, JSON.stringify(this.state));
		this.resizeTableWidth(this.state);
	}

	// #endregion Table
}