import { Component, OnInit } from '@angular/core';
import { Module, Operation } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { DateService } from '@app/services/date.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	templateUrl: 'dialog.component.html',
	styleUrls: ['../style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class SetUpGarbageCanDialogComponent implements OnInit {
	date_from = new Date();
	date_to: Date = null;
	selectedGarbageCanId: number = null;
	loading = 0;
	options: any;

	constructor(
		private accountService: AccountService,
		public config: DynamicDialogConfig,
		private crudService: CRUDService,
		private messageService: MessageService,
		public ref: DynamicDialogRef,
		public translate: TranslateService
	) {
	}

	ngOnInit() {
		this.options = [];

		this.loadOptions();
	}

	inputDate(dateField, input: string) {
		let date = DateService.inputDateExStatic(input);
		if (date) {
			if (dateField == 'date_from') {
				this.date_from = date;
			} else if (dateField == 'date_to') {
				this.date_to = date;
			}
		}
	}

	loadOptions() {
		this.loading += 1;
		let bReturnedOk: boolean = false;
		let bReturnedData: boolean = false;
		let bExceptionError: boolean = false;
		//this.crudService.getAllGarbageCans().then(res => {
		this.crudService.getAllEntries('TblGefaess').then(res => {
			bReturnedOk = true;
			if( res && res.length > 0 ) {
				bReturnedData = true;
				res.forEach(c => {
					//this.options.push({ label: c.ankey + ' ' + c.bezeichnung, bezeichnung: c.bezeichnung, ankey: c.ankey, value: c.ds_this_id });
					this.options.push({ label: c.ankey + '\t' + c['barcode'], bezeichnung: c['barcode_sichtbar'] ? c['barcode_sichtbar'] : c['barcode'], ankey: c.ankey, value: c.ds_this_id });
				});
			}
		}).catch(err => {
			err.error.forEach(e => {
				bExceptionError = true;
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
			let msgOk: string = 'returned OK: ';
			let msgData: string = 'returned data: ';
			let msgError: string = 'error/exception: ';
			if( bReturnedOk )
				msgOk += 'true';
			else msgOk += 'false';
			if( bReturnedData )
				msgData += 'true';
			else msgData += 'false';
			if( bExceptionError )
				msgError += 'true';
			else msgError += 'false';
			console.log('-- getAllGarbageCans() results --');
			console.log(msgOk);
			console.log(msgData);
			console.log(msgError);
			console.log('-- ----------------------- --');
		});
	}

	save() {
		this.loading += 1;
		if (this.accountService.checkPermissions(Module.BHV, Operation.UPDATE)) {
			this.ref.close({
				gefaess_id: this.selectedGarbageCanId,
				datum_von: this.date_from,
				datum_bis: this.date_to
			});
		} else {
			this.loading -= 1;
			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORS.ACCESS_DENIED'), detail: this.translate.instant('ERRORS.ACCESS_DENIED_MSG'), life: 3000 });
		}
	}
}
