import { Component } from '@angular/core';
import { FilterDialogComponent } from '@app/common/templates/filter_dialog/filter_dialog.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { DateService } from '@app/services/date.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	templateUrl: '../../../../../common/templates/filter_dialog/filter_dialog.component.html',
	styleUrls: ['../../style.scss', '../../../../../common/templates/filter_dialog/style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class TblRueckfahrkatasterAkteFilterDialogComponent extends FilterDialogComponent {
	constructor(
		public accountService: AccountService,
		public crudService: CRUDService,
		public config: DynamicDialogConfig,
		public dateService: DateService,
		public messageService: MessageService,
		public ref: DynamicDialogRef,
		public translate: TranslateService
	) {
		super(accountService, crudService, config, dateService, messageService, ref, translate);

		const optionGroup1 = new Map();
		optionGroup1.set('ankey', { type: 'text' });
		optionGroup1.set('is_always_candidate', { sub: "RASAKTE", type: 'boolean' });
		optionGroup1.set('plz', { sub: "RASAKTE", type: 'text' });
		optionGroup1.set('ort', { sub: "RASAKTE", type: 'text' });
		optionGroup1.set('strasse', { sub: "RASAKTE", type: 'text' });
		optionGroup1.set('hsnr', { sub: "RASAKTE", type: 'text' });
		optionGroup1.set('laenge_von', { sub: "RASAKTE", type: 'numeric' });
		optionGroup1.set('laenge_bis', { sub: "RASAKTE", type: 'numeric' });
		optionGroup1.set('gebiet', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblGebiet', values: [] });

		optionGroup1.set('breiter_350_ex', { sub: "RASAKTE", type: 'dropdown', labelFieldName: 'bezeichnung', valueFieldName: 'mode_id', values: [{ "mode_id": 1, "bezeichnung": 'ja' }, { "mode_id": 0, "bezeichnung": 'nein' }] });
		optionGroup1.set('kuerzer_150_ex', { sub: "RASAKTE", type: 'dropdown', labelFieldName: 'bezeichnung', valueFieldName: 'mode_id', values: [{ "mode_id": 1, "bezeichnung": 'ja' }, { "mode_id": 0, "bezeichnung": 'nein' }] });

		this.optionGroups = [optionGroup1];
	}
}
