<div class="p-grid" [style.height]="'calc(' + height + 'px - 2rem)'">
	<div class="p-col-12" style="height: 100%">
		<div class="card"
			style="height: 100%; display: flex; flex-direction: column; flex-wrap: wrap; justify-content: space-between;">
			<div class="p-m-3">
				<h2 translate>HOME.WELCOME</h2>
				<p [innerHTML]="'HOME.SUPPORT' | translate"></p>
			</div>
			<div class="home-logo">

			</div>
			<div class="p-m-3">
				<h3 translate>HOME.NEW_VERSION</h3>
				<p [translateParams]="{ date: '30.03.2023', version: '2.1.8' }" translate>
					HOME.NEW_VERSION_DATE
				</p>
			</div>
			<div class="p-m-3">
				<h3 translate>HOME.WHATS_NEW</h3>
				<p>
					Wir haben das Rückfahrkataster komplett überarbeitet! <br />
					Alle Aufzeichnungen einer Rückfahrstrecke werden in einer Akte gesammelt. <br />
					So können Sie eine Rückfahrstrecke an einem zentralen Ort bewerten und haben trotzdem alle
					Aufzeichnungen im Blick.
				</p>
				<p>
					Nutzen Sie das Rückfahrkataster noch nicht, haben aber Interesse bekommen? <br />
					Informieren Sie sich auf unserer <a href="https://www.visy-gmbh.de/visy-portal/"
						target="_blank">Website</a> oder
					lassen Sie sich direkt von uns per <a href="mailto:info@visy-gmbh.de">Mail</a> oder <a
						href="tel:+4960719599160">Telefon</a> beraten.
				</p>
			</div>

			<!--<button (click)="printZPL()">
				Print
			</button>-->
		</div>
	</div>
</div>