import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Globals, VIONSessionStorage } from '@app/common/global_variables';
import { DetailComponent } from '@app/common/templates/detail/detail.component';
import { Base } from '@app/models/base';
import { Module, Operation } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { ExportService } from '@app/services/export.service';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { AppMainComponent } from '@components/app.main.component';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { environment } from '@environments/environment';
import { Occasion, State } from '@models/reversingcadastral';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import * as fs from 'file-saver';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { asArray } from 'ol/color';
import GPX from 'ol/format/GPX';
import { Point } from 'ol/geom';
import { Feature, Map, View } from 'ol/index';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { useGeographic } from 'ol/proj';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { MultiSelect } from 'primeng/multiselect';
import { Table } from 'primeng/table';

@Component({
	templateUrl: './detail.component.html',
	styleUrls: ['../style.scss'],
	providers: [DialogService, MessageService, ConfirmationService]
})
export class TblRueckfahrkatasterAkteDetailComponent extends DetailComponent {
	@ViewChild('table') table: Table;
	@ViewChild('colselection') colselection: MultiSelect;
	@ViewChild('emptyCanvas', { static: false }) emptyCanvas: ElementRef;
	apiUrl: string = '';
	buttonColWidth: number = 150;
	cols: any[] = [];
	contentHeight: number = 4320;
	contextMenu: MenuItem[];
	count: number = 0;
	createPermission: boolean = false;
	entries: Base[];
	filters: string[];
	globalFilter: string = '';
	historyUrl: string = '';
	isMobile: boolean = false;
	isTableInit: boolean = false;
	loading: number = 0;
	loadTimestamp: Date;
	minTableHeight: number = 80;
	name: string = '';
	oldCols: any[] = [];
	persistenceCheckInterval: any;
	possibleCols: any[] = [];
	selectedEntry: Base;
	tableState: any;
	tableStateName: string = '';
	url: string = '';
	url_detail: string = PATH.DETAIL;
	url_aufzeichnung: string = '';
	apiUrlAufzeichnung: string = '';
	url_strecke: string = '';
	apiUrlStrecke: string = '';

	video_api_url: string = '';
	videoSafeUrl: any;
	gpx_api_url: string = '';
	gpxUnsafeUrl: string = '';
	gpxSafeUrl: any;

	public mapAkteRefAufzeichnung: Map;
	public occasions: Occasion[];
	public options: any;
	positionLayer: VectorLayer;
	routeLayer: VectorLayer;
	startTimeStamp: number;
	public states: State[];
	trackPoints: any[];
	videoplayer: any;
	public rating: number = 0;
	public ratings: any;

	apiUrlRkGefahren: string = '';
	apiUrlRkGruende: string = '';
	apiUrlRkMassnahmen: string = '';
	apiUrlRkStatus: string = '';

	rk_rating_boundaries: any;

	imageUrl: string;

	constructor(
		public app: AppMainComponent,
		public dialogService: DialogService,
		private exportService: ExportService,
		public globals: Globals,
		public elRef: ElementRef,
		private settingsService: SettingsService,
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public messageService: MessageService,
		public router: Router,
		public translate: TranslateService,
		public changeDetectorRef: ChangeDetectorRef,
		private http: HttpClient,
		private sanitizer: DomSanitizer
	) {
		super(accountService, breadcrumbService, confirmationService, crudService, messageService, router, translate);

		this.apiUrl = 'TblRueckfahrkatasterStrecke';
		this.name = 'MENU.RUECKFAHRKATASTER';
		this.url = '/' + PATH.RK_STRECKE;
		this.url_aufzeichnung = '/' + PATH.RK_AUFZEICHNUNG;
		this.apiUrlAufzeichnung = 'TblRueckfahrkatasterAufzeichnung';
		this.url_strecke = '/' + PATH.RK_STRECKE;
		this.apiUrlStrecke = 'TblRueckfahrkatasterStrecke';

		this.apiUrlRkGefahren = 'TblRueckfahrkatasterGefahr';
		this.apiUrlRkGruende = 'TblRueckfahrkatasterGrund';
		this.apiUrlRkMassnahmen = 'TblRueckfahrkatasterMassnahme';
		this.apiUrlRkStatus = 'TblRueckfahrkatasterStatus';

		this.ratings =
		{
			0: '',
			1: 'green',
			2: 'lightgreen',
			3: 'yellow',
			4: 'lightcoral',
			5: 'red'
		};

		this.fieldgroups.push(
			[
				{ type: 'text', key: 'ankey', required: true, width: 400 },
				{ type: 'text', key: 'name', required: false, width: 400 },
				{ type: 'text', key: 'freitext', required: false, width: 400 },
				{ type: 'boolean', key: 'VIRTUAL_flag_is_always_candidate', required: false, width: 400 },
				{ type: 'boolean', key: 'in_kartaster', required: false, width: 400 },
				{ type: 'object', key: 'FREMD_sachbearbeiter_bezeichnung', label: 'FREMD_sachbearbeiter_bezeichnung', url: '/' + PATH.MITARBEITER + '/' + PATH.DETAIL, id: 'sachbearbeiter_id' },
				{ type: 'object', key: 'FREMD_verantwortlicher_bezeichnung', label: 'FREMD_verantwortlicher_bezeichnung', url: '/' + PATH.MITARBEITER + '/' + PATH.DETAIL, id: 'verantwortlicher_id' },
				{ type: 'text', key: 'weitere_personen', required: false, width: 400 },
			],
			[
				{ type: 'numeric', key: 'plz_von', required: false, width: 400 },
				{ type: 'text', key: 'ort_von', required: false, width: 400 },
				{ type: 'text', key: 'strasse_von', required: false, width: 400 },
				{ type: 'text', key: 'hausnummer_von', required: false, width: 400 },
				{ type: 'numeric', key: 'plz_bis', required: false, width: 400 },
				{ type: 'text', key: 'ort_bis', required: false, width: 400 },
				{ type: 'text', key: 'strasse_bis', required: false, width: 400 },
				{ type: 'text', key: 'hausnummer_bis', required: false, width: 400 },
				{ type: 'object', key: 'FREMD_gebiet_bezeichnung', required: false, width: 400, url: '/' + PATH.GEBIET, id: 'gebiet_id' },
			],
			[
				{ type: 'object', key: 'FREMD_aufzeichnung_label', required: true, width: 400, url: '/' + PATH.RK_AUFZEICHNUNG, id: 'referenz_aufzeichnung_id' },
				{ type: 'numeric', key: 'laenge', required: false, width: 400 },
				{ type: 'boolean', key: 'breiter_350', required: false, width: 400 },
				{ type: 'boolean', key: 'kuerzer_150', required: false, width: 400 },
				{ type: 'list', key: 'rk_gruende', required: false, width: 400, apiUrl: "TblRueckfahrkatasterGrund" },
				{ type: 'list', key: 'rk_gefahren', required: false, width: 400, apiUrl: "TblRueckfahrkatasterGefahr" },
				{ type: 'list', key: 'rk_status', required: false, width: 400, apiUrl: "TblRueckfahrkatasterStatus" },
				//{ type: 'numeric', key: 'fkey', label: 'Risikopunkte' },
				{ type: 'numeric', key: 'CALC_summe_rk_gefahren_werte' },
				{ type: 'rating', key: 'bewertung', label: 'Risikobewertung' },
				{ type: 'rating', key: 'CALC_rk_gefahren_werte_einstufung' },
			],
			[
				{ type: 'list', key: 'rk_massnahmen', required: false, width: 400, apiUrl: "TblRueckfahrkatasterMassnahme" },
				{ type: 'date', key: 'massnahme_umgesetzt_am', label: 'Umgesetzt am' },
				{ type: 'object', key: 'FREMD_massnahme_umgesetzt_von_bezeichnung', label: 'FREMD_massnahme_umgesetzt_von_bezeichnung', url: '/' + PATH.MITARBEITER + '/' + PATH.DETAIL, id: 'massnahme_umgesetzt_von_id' },
			]
		);
		this.breadcrumbService.setItems([
			{ label: 'MENU.RUECKFAHRKATASTER' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.DETAIL', routerLink: [this.url + '/' + PATH.DETAIL + '/' + this.id] }
		]);

		this.historyUrl = '/rueckfahrkataster_aufzeichnung';
		this.possibleCols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'date', key: 'aufgezeichnet', required: true, width: 400 },
			{ type: 'text', key: 'name', required: true, width: 400 },
			{ type: 'numeric', key: 'plz_von', required: false, width: 400 },
			{ type: 'text', key: 'ort_von', required: false, width: 400 },
			{ type: 'text', key: 'strasse_von', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_von', required: false, width: 400 },
			{ type: 'numeric', key: 'plz_bis', required: false, width: 400 },
			{ type: 'text', key: 'ort_bis', required: false, width: 400 },
			{ type: 'text', key: 'strasse_bis', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_bis', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_kennzeichen', required: true, width: 400 },
			{ type: 'numeric', key: 'laenge', required: true, width: 400 },
			{ type: 'boolean', key: 'ras_deaktiviert', required: true, width: 400 },
			{ type: 'text', key: 'freitext', required: true, width: 400 },
			{ type: 'text', key: 'gpx', required: false, width: 400 },
			{ type: 'text', key: 'video_url', required: false, width: 400 }
		];
		this.cols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'date', key: 'aufgezeichnet', required: true, width: 400 },
			{ type: 'numeric', key: 'plz_von', required: false, width: 400 },
			{ type: 'text', key: 'ort_von', required: false, width: 400 },
			{ type: 'text', key: 'strasse_von', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_von', required: false, width: 400 },
			{ type: 'numeric', key: 'plz_bis', required: false, width: 400 },
			{ type: 'text', key: 'ort_bis', required: false, width: 400 },
			{ type: 'text', key: 'strasse_bis', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_bis', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_kennzeichen', required: true, width: 400 },
			{ type: 'numeric', key: 'laenge', required: true, width: 400 },
			{ type: 'boolean', key: 'ras_deaktiviert', required: true, width: 400 },
			{ type: 'text', key: 'freitext', required: true, width: 400 }
		];

		this.options = {
			massnahme_umgesetzt_von_id: [],
			sachbearbeiter_id: [],
			verantwortlicher_id: []

		};
	}

	// #region Angular

	ngOnInit() {
		//this.loading += 1;

		this.createPermission = this.accountService.checkPermissions(Module.ReversingCadastral, Operation.CREATE);
		this.readPermission = this.accountService.checkPermissions(Module.ReversingCadastral, Operation.READ);
		this.updatePermission = this.accountService.checkPermissions(Module.ReversingCadastral, Operation.UPDATE);
		this.deletePermission = this.accountService.checkPermissions(Module.ReversingCadastral, Operation.DELETE);

		this.stateName = 'state' + this.apiUrl + 'Detail';
		this.retrieveTableState(this.state);
		this.setHorizontalGroups(((this.state && this.state.horizontalGroups !== null) ? this.state.horizontalGroups : true));

		this.tableStateName = 'state' + this.apiUrl + 'Tracks' + 'List';
		this.retrieveTableState(this.tableState);
		if (this.tableState) {
			this.cols = [];
			this.tableState['columnOrder'].forEach(col => {
				this.possibleCols.forEach(c => {
					if (col == c.key) {
						this.cols.push(c);
					}
				});
			});
			if (this.tableState.filters.global) {
				this.globalFilter = this.tableState.filters.global.value;
			}
		}

		this.isMobile = JSON.parse(this.globals.log_Platform).Mobile == 'yes' || JSON.parse(this.globals.log_Platform).Tablet == 'yes';

		this.translate.get('init').subscribe((text: string) => {
			this.contextMenu = [
				{ label: this.translate.instant('CONTEXT_MENU.OPEN'), icon: 'pi pi-fw pi-search', command: () => this.detailTracks() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_TAB'), icon: 'pi pi-fw pi-search', command: () => this.detailTracks('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_WINDOW'), icon: 'pi pi-fw pi-search', command: () => this.detailTracks('window') },
				{ label: this.translate.instant('CONTEXT_MENU.RESIZE'), icon: 'pi pi-fw', command: () => this.resizeTableWidthFromContent(true) }
			];
			this.possibleCols.forEach(c => {
				c.label = this.translate.instant('HEADERS.' + c.key);
			});
		});

		//this.loading -= 1;
	}


	ngAfterViewInit() {
		if (this.readPermission) {
			this.getEntry();
		}

		this.settingsService.footerVisibilityChange.subscribe(value => {
			this.initTable();
		});

		this.oldCols = this.cols;
		this.filters = this.cols.map(c => c.key);

		if (this.table.filters) {
			let restoredFilter = false;
			this.filters.forEach(col => {
				Object.keys(this.table.filters[col]).forEach(filter => {
					if (this.table.filters[col][filter]['value'] != null) {
						restoredFilter = true;
					}
				})
			});
			if (restoredFilter) {
				this.messageService.add({ key: 'reset', severity: 'warn', summary: this.translate.instant('MESSAGES.WARNING'), detail: this.translate.instant('MESSAGES.LOADED_FILTER'), life: 10000 });
			}
		}

		const el = document.querySelector<HTMLElement>('.cdk-virtual-scroll-viewport');
		this.changeWheelSpeed(el, 0.9);

		this.initTable();
	}

	ngAfterViewChecked() {
		if (!this.isTableInit && this.table && this.table.value) {
			this.isTableInit = true;
			this.resizeTableWidthFromContent(false);
			this.changeDetectorRef.detectChanges();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.initTable();
	}

	// #endregion Angular

	// #region CRUD

	// override, weil vor Rückkehr zur gepufferten liste muss jetzt der gelöschte Eintrag aus dem Puffer gelöscht werden
	deleteEntry() {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.DELETE_QUESTION'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.loading += 1;
				this.crudService.deleteEntry(this.apiUrl, this.entry.ds_this_id).then(res => {
					this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });

					// hier eintrag aus gepufferter Liste löschen
					let storage = VIONSessionStorage.getInstance().get(this.apiUrl);
					if (null != storage && undefined != storage) {
						const storedTimestamp = new Date(storage.timestamp);
						if (null != storage.entries && undefined != storage.entries) {
							let index = 0;
							let foundElement = false;
							for (let i = 0; i < storage.entries.length; ++i) {
								if (storage.entries[i]['ds_this_id'] === this.entry.ds_this_id) {
									foundElement = true;
									break;
								}
								index += 1;
							}
							if (foundElement && index < storage.entries.length) {
								storage.entries.splice(index, 1);
								VIONSessionStorage.getInstance().set(this.apiUrl, storedTimestamp, storage.filters, storage.entries);
							}
						}
					}

					// und neu öffnen
					this.router.navigate([this.url + '/list/']);
				}).catch(err => {
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				}).finally(() => {
					this.loading -= 1;
				});
			}
		});
	}

	getEntry() {
		this.loading += 1;
		this.crudService.getEntry(this.apiUrl, this.id).then(res => {
			this.entry = res;
			this.getStaff();
			this.getAufzeichnungen();
			this.getHeaderEx();
			this.video_api_url = `${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/video/` + this.entry['referenz_aufzeichnung_id'];
			this.gpx_api_url = `${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/gpx/` + this.entry['referenz_aufzeichnung_id'];
			this.fieldgroups.forEach(fg => {
				fg.forEach(field => {
					if (field.type == 'date') {
						if (this.entry[field.key] != null) {
							this.entry[field.key] = new Date(this.entry[field.key]);
						}
					}
				});
			});

			this.accountService.getOptions().then(options => {
				this.http.get(this.gpx_api_url, { withCredentials: options.withCredentials, headers: options.headers, responseType: "blob" }).subscribe(blob => {
					const unsafeUrl = URL.createObjectURL(blob);
					const safeUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeUrl);
					this.gpxUnsafeUrl = unsafeUrl;
					this.gpxSafeUrl = safeUrl;
					this.initMap();
				});

				this.http.get(this.video_api_url, { withCredentials: options.withCredentials, headers: options.headers, responseType: "blob" }).subscribe(blob => {
					const unsafeUrl = URL.createObjectURL(blob);
					const safeUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeUrl);
					this.videoSafeUrl = safeUrl;
				});
			}).catch(err => {
				err.error.forEach(e => {
					if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
					}
				})
			});

			this.entries = [...this.entries];
			this.initTable();
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	// #endregion CRUD

	// #region Spezial

	export() {
		const doc = new jsPDF({
			orientation: 'p',
			format: 'a4'
		});


		if (this.imageUrl) {
			const img = new Image();
			img.src = this.imageUrl;
			doc.addImage(img, 'png', 30, 10, 150, 18);
		}

		if (this.imageUrl) {
			// Bewertung in Rückfahrkataster 1622 rückgängig machen

			var labelSize = 10
			var contentSize = 8
			var headSize = 12
			autoTable(doc, {
				startY: 35,
				columnStyles: {
					0: { cellWidth: 36 },
					1: { cellWidth: 28 },
					2: { cellWidth: 30 },
					3: { cellWidth: 40 },
					4: { cellWidth: 30 },
					5: { cellWidth: 40 }
				},
				head: [[{ content: 'Gefährdungsbeurteilung aus Rückfahrkataster', colSpan: 6, styles: { fontSize: headSize, halign: 'left' } }]],
				body: [
					[
						{ content: 'Name', colSpan: 1, styles: { fontSize: labelSize } },
						{ content: this.entry['name'], colSpan: 5, styles: { fontSize: contentSize } }
					],

					[
						{ content: 'Anmerkungen', colSpan: 1, rowSpan: 1, styles: { fontSize: labelSize } },
						{ content: this.entry['freitext'], colSpan: 5, rowSpan: 1, styles: { fontSize: contentSize } }
					],

					[
						{ content: 'Postleitzahl', colSpan: 1, styles: { fontSize: labelSize } },
						{ content: this.entry['plz_von'], colSpan: 1, styles: { fontSize: contentSize } },
						{ content: 'Ort', colSpan: 1, styles: { fontSize: labelSize } },
						{ content: this.entry['ort_von'], colSpan: 3, styles: { fontSize: contentSize } },
					],

					[
						{ content: 'Straße', colSpan: 1, styles: { fontSize: labelSize } },
						{ content: this.entry['strasse_von'], colSpan: 5, styles: { fontSize: contentSize } },
					],

					[
						{ content: 'Länge', colSpan: 1, styles: { fontSize: labelSize } },
						{ content: this.entry['laenge'] + 'm', colSpan: 1, styles: { fontSize: contentSize } },

						{ content: 'Kürzer als 150 m', colSpan: 1, styles: { fontSize: labelSize } },
						{ content: (this.entry['kuerzer_150'] === true ? 'Ja' : 'Nein'), colSpan: 1, styles: { fontSize: contentSize } },

						{ content: 'Breiter als 3,50 m', colSpan: 1, styles: { fontSize: labelSize } },
						{ content: (this.entry['breiter_350'] === true ? 'Ja' : 'Nein'), colSpan: 1, styles: { fontSize: contentSize } },

					],

					[
						{ content: 'Status', colSpan: 1, styles: { fontSize: labelSize } },
						{ content: this.entry['rk_status'].map(x => x.bezeichnung).join(', '), colSpan: 5, styles: { fontSize: contentSize } },
					],

					[
						{ content: 'Gründe', colSpan: 1, styles: { fontSize: labelSize } },
						{ content: this.entry['rk_gruende'].map(x => x.bezeichnung).join(', '), colSpan: 5, styles: { fontSize: contentSize } },
					],

					[
						{ content: 'Gefahren', colSpan: 1, styles: { fontSize: labelSize } },
						{ content: this.entry['rk_gefahren'].map(x => x.bezeichnung).join(', '), colSpan: 5, styles: { fontSize: contentSize } },
					],

					[
						{ content: 'Maßnahmen', colSpan: 1, styles: { fontSize: labelSize } },
						{ content: this.entry['rk_massnahmen'].map(x => x.bezeichnung).join(', '), colSpan: 5, styles: { fontSize: contentSize } },
					],

					[
						{ content: ' ', colSpan: 1, styles: { fontSize: labelSize } },

						{ content: 'umgesetzt am', colSpan: 1, styles: { fontSize: labelSize } },
						{ content: this.entry['massnahme_umgesetzt_am'], colSpan: 1, styles: { fontSize: contentSize } },

						{ content: 'umgesetzt von', colSpan: 1, styles: { fontSize: labelSize } },
						{ content: this.entry['FREMD_massnahme_umgesetzt_von_bezeichnung'], colSpan: 1, styles: { fontSize: contentSize } },

						{ content: ' ', colSpan: 1, styles: { fontSize: labelSize } },
					],

					[
						{ content: 'Risikopunkte', colSpan: 1, styles: { fontSize: labelSize } },
						//{ content: this.entry['fkey'], colSpan: 5, styles: { fontSize: labelSize } },
						{ content: this.entry['CALC_summe_rk_gefahren_werte'], colSpan: 5, styles: { fontSize: labelSize } },

						{ content: 'Risikobewertung', colSpan: 1, styles: { fontSize: labelSize } },
						{ content: this.entry['bewertung'] + ' von 5', colSpan: 5, styles: { fontSize: labelSize } },
					],

					[
						{ content: 'Sachbearbeiter', colSpan: 1, styles: { fontSize: labelSize } },
						{ content: this.entry['FREMD_sachbearbeiter_bezeichnung'], colSpan: 5, styles: { fontSize: contentSize } },
					],

					[
						{ content: 'Verantwortlicher', colSpan: 1, styles: { fontSize: labelSize } },
						{ content: this.entry['FREMD_verantwortlicher_bezeichnung'], colSpan: 5, styles: { fontSize: contentSize } },
					],

					[
						{ content: 'Weitere Personen', colSpan: 1, styles: { fontSize: labelSize } },
						{ content: this.entry['weitere_personen'], colSpan: 5, styles: { fontSize: contentSize } },
					],




					/*
					
					['Status', this.entry['rk_status'].map(x => x.bezeichnung).join(', ')],
					['Gründe', this.entry['rk_gruende'].map(x => x.bezeichnung).join(', ')],
					['Gefahren', this.entry['rk_gefahren'].map(x => x.bezeichnung).join(', ')],
					['Risikopunkte', this.entry['fkey']],
					['Risikobewertung', this.entry['bewertung'] + ' von 5'],*/
					/*
					

					['Maßnahme umgesetzt am', this.entry['massnahme_umgesetzt_am']],
					['Maßnahme umgesetzt von', this.entry['FREMD_massnahme_umgesetzt_von_bezeichnung']],
					['Sachbearbeiter', this.entry['FREMD_sachbearbeiter_bezeichnung']],
					['Verantwortlicher', this.entry['FREMD_verantwortlicher_bezeichnung']],
					['Weitere beteiligte Personen', this.entry['weitere_personen']],
					*/
				],
			});

		}
		else {
			autoTable(doc, {
				head: [['Rückfahrkataster', '']],
				body: [
					[{ content: 'Name', styles: { fontSize: 5 } }, this.entry['name']],
					['Anmerkungen', this.entry['freitext']],
					['Postleitzahl von', this.entry['plz_von']],
					['Ort von', this.entry['ort_von']],
					['Straße von', this.entry['strasse_von']],
					['Hausnummer von', this.entry['hausnummer_von']],
					['Postleitzahl bis', this.entry['plz_bis']],
					['Ort bis', this.entry['ort_bis']],
					['Straße bis', this.entry['strasse_bis']],
					['Hausnummer bis', this.entry['hausnummer_bis']],
					['Länge', this.entry['laenge'] + 'm'],
					['Kürzer als 150 m', (this.entry['kuerzer_150'] === true ? 'Ja' : 'Nein')],
					['Breiter als 3,50 m', (this.entry['breiter_350'] === true ? 'Ja' : 'Nein')],
					['Status', this.entry['rk_status'].map(x => x.bezeichnung).join(', ')],
					['Gründe', this.entry['rk_gruende'].map(x => x.bezeichnung).join(', ')],
					['Gefahren', this.entry['rk_gefahren'].map(x => x.bezeichnung).join(', ')],
					//['Risikopunkte', this.entry['fkey']],
					['Risikopunkte', this.entry['CALC_summe_rk_gefahren_werte']],
					['Risikobewertung', this.entry['bewertung'] + ' von 5'],
					['Maßnahmen', this.entry['rk_massnahmen'].map(x => x.bezeichnung).join(', ')],
					['Maßnahme umgesetzt am', this.entry['massnahme_umgesetzt_am']],
					['Maßnahme umgesetzt von', this.entry['FREMD_massnahme_umgesetzt_von_bezeichnung']],
					['Sachbearbeiter', this.entry['FREMD_sachbearbeiter_bezeichnung']],
					['Verantwortlicher', this.entry['FREMD_verantwortlicher_bezeichnung']],
					['Weitere beteiligte Personen', this.entry['weitere_personen']],
				],
			});


		}

		html2canvas(document.getElementById('mapAkteRefAufzeichnung'), { scrollY: -window.scrollY }).then(canvas => {
			const image = new Image();
			image.src = canvas.toDataURL('image/png');
			doc.addImage(image, 'png', 15, (doc as any).lastAutoTable.finalY + 5/*170*/, 180, 110);
			doc.save((this.entry['name'] ? this.entry['name'] : 'rk' + this.entry.ds_this_id) + '.pdf');
		});

		this.accountService.getOptions().then(options => {
			this.http.get(this.video_api_url, { withCredentials: options.withCredentials, headers: options.headers, responseType: "blob" }).subscribe(blob => {
				fs.saveAs(blob, this.entry['name'] + '.mp4');
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		});
	}

	// #enregion Spezial

	// #region History

	// #region CRUD

	detailTracks(target?: string) {
		if (target == 'window') {
			window.open('/#/' + this.historyUrl + '/' + PATH.DETAIL + '/' + this.selectedEntry.ds_this_id, '_blank', 'newWindow=1');
		} else if (target == 'tab') {
			window.open('/#/' + this.historyUrl + '/' + PATH.DETAIL + '/' + this.selectedEntry.ds_this_id);
		} else {
			this.router.navigate([this.historyUrl + '/' + PATH.DETAIL + '/' + this.selectedEntry.ds_this_id]);
		}
	}

	getStaff() {
		this.loading += 1;
		this.crudService.getStaff().then(res => {
			const o = [];
			res.forEach(l => {
				o.push({ label: l.bezeichnung, value: l.ds_this_id });
			});
			this.options.massnahme_umgesetzt_von_id = o;
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getStaff().then(res => {
			const o = [];
			res.forEach(l => {
				o.push({ label: l.bezeichnung, value: l.ds_this_id });
			});
			this.options.sachbearbeiter_id = o;
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getStaff().then(res => {
			const o = [];
			res.forEach(l => {
				o.push({ label: l.bezeichnung, value: l.ds_this_id });
			});
			this.options.verantwortlicher_id = o;
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	getHeaderEx() {
		this.loading += 1;
		this.crudService.getHeader().then(res => {
			if (res) {
				const blob = new Blob([res], { type: 'application/octet-stream' });
				if (blob.size > 0) {
					const urlCreator = window.URL || window.webkitURL;
					this.imageUrl = urlCreator.createObjectURL(blob);
				}
			}
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	getAufzeichnungen() {
		this.loading += 1;
		this.messageService.clear('refresh');
		const filter = {
			plz: "",
			ort: "",
			strasse: "",
			in_kartaster: null,
			laenger_als: null,
			datum_von: null,
			datum_bis: null,
			fahrzeuge: [],
			gefahren: [],
			massnahmen: [],
			gruende: [],
			status: [],
			akte_id: this.entry['akte_id']
		}
		this.crudService.getFilteredEntries('TblRueckfahrkatasterAufzeichnung', filter).then(res => {
			this.entries = res;
			this.count = this.entries.length;
			this.possibleCols.forEach(c => {
				if (c.type == 'date') {
					this.entries.forEach(e => {
						if (e[c.key] != null) {
							e[c.key] = new Date(e[c.key]);
						}
					});
				}
			});
			this.entries = [...this.entries];
			this.initTable();
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	// #endregion CRUD

	// #region Table

	changeWheelSpeed(container, speedY) {
		var scrollY = 0;
		var handleScrollReset = function () {
			scrollY = container.scrollTop;
		};
		var handleMouseWheel = function (e) {
			e.preventDefault();
			scrollY += speedY * e.deltaY
			if (scrollY < 0) {
				scrollY = 0;
			} else {
				var limitY = container.scrollHeight - container.clientHeight;
				if (scrollY > limitY) {
					scrollY = limitY;
				}
			}
			container.scrollTop = scrollY;
		};

		var removed = false;
		container.addEventListener('mouseup', handleScrollReset, false);
		container.addEventListener('mousedown', handleScrollReset, false);
		container.addEventListener('mousewheel', handleMouseWheel, false);

		return function () {
			if (removed) {
				return;
			}
			container.removeEventListener('mouseup', handleScrollReset, false);
			container.removeEventListener('mousedown', handleScrollReset, false);
			container.removeEventListener('mousewheel', handleMouseWheel, false);
			removed = true;
		};
	}

	exportPDF() {
		this.exportService.exportPDF(this.translate.instant(this.tableStateName), this.table.value, this.cols);
	}

	exportXLSX() {
		this.exportService.exportXLSX(this.translate.instant(this.tableStateName), this.table.value, this.cols);
	}

	initTable() {
		this.contentHeight = 0;
		setTimeout(() => {
			const detailDiv = document.getElementById('details');
			if (detailDiv) {
				this.contentHeight = this.elRef.nativeElement.parentElement.offsetHeight - 320 - detailDiv.offsetHeight + ((localStorage.getItem('showFooter') === 'true') ? 5 : 0)
				if (this.entries && this.entries.length > 0) {
					let entryHeight;
					if (this.entries.length < 5) {
						entryHeight = this.minTableHeight + (this.entries.length * 45);
					} else {
						entryHeight = this.minTableHeight + (5 * 45);
					}
					if (entryHeight > this.contentHeight) {
						this.contentHeight = entryHeight;
					}
				} else {
					this.contentHeight = this.minTableHeight;
				}
			}
		}, 0);
		this.resizeTableWidthFromContent(false);
		//this.changeDetectorRef.detectChanges();
	}

	isColFiltered(col) {
		let isFiltered = false;
		if (this.table && this.table.filters[col.key]) {
			Object.keys(this.table.filters[col.key]).forEach(filter => {
				if (this.table.filters[col.key][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		else if (this.tableState && this.tableState.filters[col.key]) {
			Object.keys(this.tableState.filters[col.key]).forEach(filter => {
				if (this.tableState.filters[col.key][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		return isFiltered;
	}

	onFilter(event) {
		this.count = this.table.filteredValue ? this.table.filteredValue.length : this.entries.length;
	}

	onColReorder(event) {
		this.tableState = JSON.parse(localStorage.getItem(this.tableStateName));
		const columnWidths = this.tableState.columnWidths.split(',');
		columnWidths.splice(event.dropIndex, 0, columnWidths.splice(event.dragIndex, 1)[0]);
		this.tableState.columnWidths = columnWidths.join(',');
		localStorage.setItem(this.tableStateName, JSON.stringify(this.tableState));
	}

	onColResize(event) {
		const index = Array.from(event.element.parentNode.children).indexOf(event.element);
		this.tableState = JSON.parse(localStorage.getItem(this.tableStateName));
		this.cols[index].width = Number(event.element.style.width.split('px')[0]);
		this.tableState.columnWidths = (this.cols.map(c => c.width)).concat([this.buttonColWidth]).join(',');
		localStorage.setItem(this.tableStateName, JSON.stringify(this.tableState));

		this.resizeTableWidth(this.tableState);
	}

	resetTable() {
		this.table.clearState();
		window.location.reload();
	}

	resizeTableWidth(tableState?) {
		this.loading += 1;

		this.retrieveTableState(this.tableState);
		if (this.table) {
			const tableElement = document.getElementById(this.table.id);
			tableElement.style.width = '100%';
			const columnWidths = this.tableState ? this.tableState.columnWidths.split(',') : (this.cols.map(c => c.width)).concat([this.buttonColWidth]);
			const contentWidth = columnWidths.reduce((summe, element) => summe + Number(element), 0);
			const tableWidthOffset = tableElement.clientWidth - contentWidth;
			for (let index = 0; index < this.cols.length; index++) {
				this.cols[index].width = Number(columnWidths[index]);
			}
			if (tableWidthOffset > 0 && this.cols.length > 0) {
				this.cols[this.cols.length - 1].width += tableWidthOffset;
				if (this.contentHeight < (this.table.filteredValue ? this.table.filteredValue.length : (this.table.value ? this.table.value.length : 0)) * this.table.virtualRowHeight) {
					this.cols[this.cols.length - 1].width -= 10;
				}
			}

			document.getElementById(this.table.id + '-table').style.width = this.cols.reduce((summe, element) => summe + element.width, 0) + this.buttonColWidth + 'px';
			document.getElementById(this.table.id + '-table').style.minWidth = this.cols.reduce((summe, element) => summe + element.width, 0) + this.buttonColWidth + 'px';

			setTimeout(() => {
				if (this.tableState) {
					console.log(Date.now().toString() + ' - storing "resizeTableWidth"');
					localStorage.setItem(this.stateName, JSON.stringify(this.tableState));
				}
			}, 0);
		}

		this.loading -= 1;
	}

	retrieveTableState(state?) {
		this.tableState = state ? state : JSON.parse(localStorage.getItem(this.tableStateName));
		if (this.table && (this.tableState == undefined)) {
			// for storage of table state
			this.table.saveState();
			// reload and parse
			this.tableState = JSON.parse(localStorage.getItem(this.tableStateName));
		}
	}

	toggleColumn(event) {
		this.tableState = JSON.parse(localStorage.getItem(this.tableStateName));
		this.tableState.columnOrder = event.value.map(c => c.key);
		this.tableState.columnWidths = event.value.map(c => c.width);
		this.tableState.columnWidths = this.tableState.columnWidths.join(',');
		this.tableState.columnWidths = this.tableState.columnWidths + ',' + this.buttonColWidth;
		this.tableState.tableWidth = (this.tableState.columnWidths.split(',')).reduce((summe, element) => summe + Number(element), 0) + 'px';
		this.filters = event.value.map(c => c.key);
		localStorage.setItem(this.tableStateName, JSON.stringify(this.tableState));
		this.resizeTableWidth(this.tableState);
	}

	// liefert die länge der darstellung eines textes in pixeln zurück 
	getTextLength(text: string, styleFont: string) {
		const ctx = this.emptyCanvas.nativeElement.getContext('2d');
		ctx.font = styleFont;
		const textMetrics = ctx.measureText(text);
		return Math.round(textMetrics.actualBoundingBoxLeft + textMetrics.actualBoundingBoxRight);
	}

	// wenn eine spalte noch ein special symbol im header hat
	adaptColumnSize(maxStringLength, columnkey) {
		return maxStringLength;
	}

	// berechnet die optimale spaltenbreite für die tabelle in abhängigkeit vom inhalt
	resizeTableWidthFromContent(bForce, state?) {
		var bResize = bForce;

		this.loading += 1;

		// code aus retrieveTableState, muss hier separat gemacht werden damit man bResize korrekt setzen kann
		this.tableState = state ? state : JSON.parse(localStorage.getItem(this.tableStateName));
		if (this.tableState == undefined || this.tableState == null) {
			// force storage of table state
			this.table.saveState();
			// reload state
			this.tableState = JSON.parse(localStorage.getItem(this.tableStateName));
			bResize = true;
		}

		if (this.table && bResize) {
			// autosize columns
			const lTable = document.getElementById(this.table.id);
			var lTableFont = window.getComputedStyle(lTable, null).getPropertyValue('font');
			// für alle spalten, alle daten            
			this.cols.forEach(col => {
				let columnname = this.translate.instant('HEADERS.' + col.key);
				let maxStringLength = this.getTextLength(columnname, lTableFont);
				// filter symbol
				maxStringLength = maxStringLength + 80;
				maxStringLength = this.adaptColumnSize(maxStringLength, col.key);
				if (this.entries) {
					this.entries.forEach(row => {
						let newLength = 0;
						if (col.type == 'date') {
							if (row[col.key] != undefined)
								newLength = this.getTextLength(row[col.key].toLocaleString(), lTableFont);
							else
								newLength = 0;
						} else {
							newLength = this.getTextLength(row[col.key], lTableFont);
						}
						// margins zur zelle
						newLength = newLength + 26;
						if (newLength > maxStringLength)
							maxStringLength = newLength;
					})
				}

				col.width = maxStringLength;
			});

			this.tableState.columnWidths = (this.cols.map(c => c.width)).concat([this.buttonColWidth]).join(',');
		}

		// standard funktion aufrufen
		this.resizeTableWidth(this.tableState);

		this.loading -= 1;
	}

	// #endregion Table

	// #region Map & Player
	initMap() {
		useGeographic();
		const coord = [this.entry["FREMD_aufzeichnung_center_longitude"], this.entry["FREMD_aufzeichnung_center_latitude"]];

		this.positionLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			style: new Style({
				image: new Circle({
					radius: 5,
					fill: new Fill({
						color: 'blue'
					})
				})
			})
		});

		this.routeLayer = new VectorLayer({
			source: new VectorSource({
				//url: '/assets' + this.entry["FREMD_aufzeichnung_gpx"],
				url: this.gpxUnsafeUrl,
				format: new GPX(),
				crossOrigin: 'anonymous'
			}),
			style: (feature) => {
				var colorName = feature.get('desc') == 'v' ? this.app.forwardingColor : this.app.reversingColor;
				var colorRGB = asArray(colorName); // Farbe von Name in RGB Array umwandeln
				colorRGB = colorRGB.slice();
				colorRGB[3] = 0.7; // Alphakanal hinzufügen
				return new Style({
					stroke: new Stroke({
						color: colorRGB,
						width: 4
					})
				})
			}
		});

		this.routeLayer.getSource().on('error', this.mapError());

		this.mapAkteRefAufzeichnung = new Map({
			target: 'mapAkteRefAufzeichnung',
			view: new View({
				center: coord,
				zoom: 17,
				maxZoom: 18,
			}),
			controls: [],
			renderer: 'webgl',
			layers: [
				new TileLayer({
					source: new OSM({
						url: environment.mapUrl,
						format: 'image/png',
						crossOrigin: 'anonymous'
					})
				}),
				this.routeLayer,
				this.positionLayer
			]
		});

		this.mapAkteRefAufzeichnung.on('singleclick', event => {
			const coord = this.mapAkteRefAufzeichnung.getCoordinateFromPixel(event.pixel);
			let closestPoint = [0, 0, 0];
			this.trackPoints.forEach(tp => {
				const distCurrentPoint = Math.abs(tp[0] - coord[0]) + Math.abs(tp[1] - coord[1]);
				const distClosestPoint = Math.abs(closestPoint[0] - coord[0]) + Math.abs(closestPoint[1] - coord[1]);
				if (distCurrentPoint < distClosestPoint) {
					closestPoint = tp;
				}
			});
			const timestamp = closestPoint[2] - this.startTimeStamp;
			this.videoplayer.currentTime = timestamp;
			this.setVehiclePosition(timestamp);
		});
	}

	initGPX(event) {
		this.videoplayer = event.target;
		if (!this.trackPoints) {
			this.trackPoints = [];
			this.routeLayer.getSource().getFeatures().forEach(feature => {
				const chunkSize = 3;
				let points = [];
				for (let i = 0; i < feature.getGeometry().flatCoordinates.length; i += chunkSize) {
					const chunk = feature.getGeometry().flatCoordinates.slice(i, i + chunkSize);
					points.push(chunk);
					if (!this.startTimeStamp || +chunk[2] < this.startTimeStamp) {
						this.startTimeStamp = +chunk[2];
					}
				}
				this.trackPoints = [...this.trackPoints, ...points];
			});
		}

		this.setVehiclePosition(0);
	}

	parseDate(date: Date): string {
		const options: Intl.DateTimeFormatOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
		if (date) {
			return (date.toLocaleDateString('de-DE', options)) + ', ' + (date.toLocaleTimeString('de-DE'));
		} else {
			return null;
		}
	}

	setVehiclePosition(videoTimestamp) {
		let position = this.trackPoints[0];
		this.trackPoints.forEach(tp => {
			if (Math.abs(this.startTimeStamp + videoTimestamp - tp[2]) < Math.abs(this.startTimeStamp + videoTimestamp - position[2])) { // evtl +5 Sekunden wegen GPS Buffer?!
				position = tp;
			}
		});

		const feature = new Feature({
			geometry: new Point([position[0], position[1]]),
		});
		this.positionLayer.getSource().clear();
		this.positionLayer.getSource().addFeature(feature);
	}

	mapError() {
		if (this.gpxUnsafeUrl == null || this.gpxUnsafeUrl == undefined || this.gpxUnsafeUrl.length == 0) {
			this.messageService.add({ severity: 'error', summary: 'Map Error', detail: 'Can\'t load gpx file.', life: 10000 });
		}
	}

	videoError() {
		this.messageService.add({ severity: 'error', summary: 'Video Error', detail: 'Can\'t load video file.', life: 10000 });
		this.entry['FREMD_aufzeichnung_video_url'] = null;
	}
	// #endregion Map & Player

	// #region Spezial

	// #endregion Spezial

	// #endregion History
}