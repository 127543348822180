import { Component } from '@angular/core';
import { FilterDialogComponent } from '@app/common/templates/filter_dialog/filter_dialog.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { DateService } from '@app/services/date.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	templateUrl: '../../../../../common/templates/filter_dialog/filter_dialog.component.html',
	styleUrls: ['../../style.scss', '../../../../../common/templates/filter_dialog/style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class ComponentToVehicleDialogComponent extends FilterDialogComponent {
	constructor(
		public accountService: AccountService,
		public crudService: CRUDService,
		public config: DynamicDialogConfig,
		public dateService: DateService,
		public messageService: MessageService,
		public ref: DynamicDialogRef,
		public translate: TranslateService
	) {
		super(accountService, crudService, config, dateService, messageService, ref, translate);

		const optionGroup1 = new Map();
		optionGroup1.set('fahrgestellnummer', { type: 'dropdown', labelFieldName: 'fahrgestellnummer', valueFieldName: 'ds_this_id', apiUrl: 'TblFahrzeug', values: [] });
		optionGroup1.set('COMPONENT_LINK_FROM', { type: 'date_noTimespan' });
		optionGroup1.set('COMPONENT_LINK_TO', { type: 'date_noTimespan' });
		optionGroup1.set('COMPONENT_LINK_COMMENTS', { type: 'text' });


		if( this.config.data && this.config.data['buttonLabel'] )
			this.setCommitButtonText(this.config.data['buttonLabel']);
		this.optionGroups = [optionGroup1];
	}

	// override
	ngOnInit() {
		this.loading += 1;
		if (this.isPermitted()) {
			this.loadOptions();
		}
		this.loading -= 1;
	}
}
