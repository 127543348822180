import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AdminVisyUsersCreateTestuserComponent } from '@app/views/admin/visy/users/createtestuser/create.component';
import { EswComponent } from '@app/views/berichte/esw/container_overview/eswinput.component';
import { TblFahrtberichtDetailComponent } from '@app/views/berichte/tbl_fahrtbericht/detail/detail.component';
import { TblFahrtberichtEditComponent } from '@app/views/berichte/tbl_fahrtbericht/edit/edit.component';
import { TblFahrtberichtListComponent } from '@app/views/berichte/tbl_fahrtbericht/list/list.component';
import { TblLeerungListComponent } from '@app/views/berichte/tbl_leerung/list/list.component';
import { TblAbfuhrrhythmusCreateComponent } from '@app/views/bhv/tbl_abfuhrrhythmus/create/create.component';
import { TblAbfuhrrhythmusDetailComponent } from '@app/views/bhv/tbl_abfuhrrhythmus/detail/detail.component';
import { TblAbfuhrrhythmusEditComponent } from '@app/views/bhv/tbl_abfuhrrhythmus/edit/edit.component';
import { TblAbfuhrrhythmusListComponent } from '@app/views/bhv/tbl_abfuhrrhythmus/list/list.component';
import { TblFirmaCreateComponent } from '@app/views/bhv/tbl_firma/create/create.component';
import { TblFirmaDetailComponent } from '@app/views/bhv/tbl_firma/detail/detail.component';
import { TblFirmaEditComponent } from '@app/views/bhv/tbl_firma/edit/edit.component';
import { TblFirmaListComponent } from '@app/views/bhv/tbl_firma/list/list.component';
import { TblGefaessCreateComponent } from '@app/views/bhv/tbl_gefaess/create/create.component';
import { TblGefaessDetailComponent } from '@app/views/bhv/tbl_gefaess/detail/detail.component';
import { TblGefaessEditComponent } from '@app/views/bhv/tbl_gefaess/edit/edit.component';
import { TblGefaessListComponent } from '@app/views/bhv/tbl_gefaess/list/list.component';
import { TblGefaessStatusCreateComponent } from '@app/views/bhv/tbl_gefaess_status/create/create.component';
import { TblGefaessStatusDetailComponent } from '@app/views/bhv/tbl_gefaess_status/detail/detail.component';
import { TblGefaessStatusEditComponent } from '@app/views/bhv/tbl_gefaess_status/edit/edit.component';
import { TblGefaessStatusListComponent } from '@app/views/bhv/tbl_gefaess_status/list/list.component';
import { TblGefaessTypCreateComponent } from '@app/views/bhv/tbl_gefaess_typ/create/create.component';
import { TblGefaessTypDetailComponent } from '@app/views/bhv/tbl_gefaess_typ/detail/detail.component';
import { TblGefaessTypEditComponent } from '@app/views/bhv/tbl_gefaess_typ/edit/edit.component';
import { TblGefaessTypListComponent } from '@app/views/bhv/tbl_gefaess_typ/list/list.component';
import { TblGefaessSperreCreateComponent } from '@app/views/bhv/tbl_gefaess_sperre/create/create.component';
import { TblGefaessSperreDetailComponent } from '@app/views/bhv/tbl_gefaess_sperre/detail/detail.component';
import { TblGefaessSperreEditComponent } from '@app/views/bhv/tbl_gefaess_sperre/edit/edit.component';
import { TblGefaessSperreListComponent } from '@app/views/bhv/tbl_gefaess_sperre/list/list.component';
import { TblGefaessZustandCreateComponent } from '@app/views/bhv/tbl_gefaess_zustand/create/create.component';
import { TblGefaessZustandDetailComponent } from '@app/views/bhv/tbl_gefaess_zustand/detail/detail.component';
import { TblGefaessZustandEditComponent } from '@app/views/bhv/tbl_gefaess_zustand/edit/edit.component';
import { TblGefaessZustandListComponent } from '@app/views/bhv/tbl_gefaess_zustand/list/list.component';
import { TblKundeCreateComponent } from '@app/views/bhv/tbl_kunde/create/create.component';
import { TblKundeDetailComponent } from '@app/views/bhv/tbl_kunde/detail/detail.component';
import { TblKundeEditComponent } from '@app/views/bhv/tbl_kunde/edit/edit.component';
import { TblKundeListComponent } from '@app/views/bhv/tbl_kunde/list/list.component';
import { TblAuftragCreateComponent } from '@app/views/bhv/tbl_auftrag/create/create.component';
import { TblAuftragDetailComponent } from '@app/views/bhv/tbl_auftrag/detail/detail.component';
import { TblAuftragEditComponent } from '@app/views/bhv/tbl_auftrag/edit/edit.component';
import { TblAuftragListComponent } from '@app/views/bhv/tbl_auftrag/list/list.component';
import { TblObjektCreateComponent } from '@app/views/bhv/tbl_objekt/create/create.component';
import { TblObjektDetailComponent } from '@app/views/bhv/tbl_objekt/detail/detail.component';
import { TblObjektEditComponent } from '@app/views/bhv/tbl_objekt/edit/edit.component';
import { TblObjektListComponent } from '@app/views/bhv/tbl_objekt/list/list.component';
import { TblObjektGruppeCreateComponent } from '@app/views/bhv/tbl_objekt_gruppe/create/create.component';
import { TblObjektGruppeDetailComponent } from '@app/views/bhv/tbl_objekt_gruppe/detail/detail.component';
import { TblObjektGruppeEditComponent } from '@app/views/bhv/tbl_objekt_gruppe/edit/edit.component';
import { TblObjektGruppeListComponent } from '@app/views/bhv/tbl_objekt_gruppe/list/list.component';
import { TblObjektStatusCreateComponent } from '@app/views/bhv/tbl_objekt_status/create/create.component';
import { TblObjektStatusDetailComponent } from '@app/views/bhv/tbl_objekt_status/detail/detail.component';
import { TblObjektStatusEditComponent } from '@app/views/bhv/tbl_objekt_status/edit/edit.component';
import { TblObjektStatusListComponent } from '@app/views/bhv/tbl_objekt_status/list/list.component';
import { TblObjektTypCreateComponent } from '@app/views/bhv/tbl_objekt_typ/create/create.component';
import { TblObjektTypDetailComponent } from '@app/views/bhv/tbl_objekt_typ/detail/detail.component';
import { TblObjektTypEditComponent } from '@app/views/bhv/tbl_objekt_typ/edit/edit.component';
import { TblObjektTypListComponent } from '@app/views/bhv/tbl_objekt_typ/list/list.component';
import { SettingsComponent } from '@app/views/einstellungen/allgemein/settings.component';
import { ChangePasswordComponent } from '@app/views/einstellungen/passwort/changepassword.component';
import { ManualComponent } from '@app/views/handbuch/manual.component';
import { HomeComponent } from '@app/views/home/home.component';
import { TermsComponent } from '@app/views/impressum/terms.component';
import { OnlineKarteComponent } from '@app/views/online/karte/map.component';
import { OnlineLeerungComponent } from '@app/views/online/leerung/collections.component';
import { TblRueckfahrkatasterAufzeichnungCreateComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_aufzeichnung/create/create.component';
import { TblRueckfahrkatasterAufzeichnungDetailComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_aufzeichnung/detail/detail.component';
import { TblRueckfahrkatasterAufzeichnungEditComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_aufzeichnung/edit/edit.component';
import { TblRueckfahrkatasterAufzeichnungListComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_aufzeichnung/list/list.component';
import { TblRueckfahrkatasterGefahrCreateComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_gefahr/create/create.component';
import { TblRueckfahrkatasterGefahrDetailComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_gefahr/detail/detail.component';
import { TblRueckfahrkatasterGefahrEditComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_gefahr/edit/edit.component';
import { TblRueckfahrkatasterGefahrListComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_gefahr/list/list.component';
import { TblRueckfahrkatasterGrundCreateComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_grund/create/create.component';
import { TblRueckfahrkatasterGrundDetailComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_grund/detail/detail.component';
import { TblRueckfahrkatasterGrundEditComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_grund/edit/edit.component';
import { TblRueckfahrkatasterGrundListComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_grund/list/list.component';
import { TblRueckfahrkatasterMassnahmeCreateComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_massnahme/create/create.component';
import { TblRueckfahrkatasterMassnahmeDetailComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_massnahme/detail/detail.component';
import { TblRueckfahrkatasterMassnahmeEditComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_massnahme/edit/edit.component';
import { TblRueckfahrkatasterMassnahmeListComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_massnahme/list/list.component';
import { TblRueckfahrkatasterStatusCreateComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_status/create/create.component';
import { TblRueckfahrkatasterStatusDetailComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_status/detail/detail.component';
import { TblRueckfahrkatasterStatusEditComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_status/edit/edit.component';
import { TblRueckfahrkatasterStatusListComponent } from '@app/views/rueckfahrkataster/tbl_rueckfahrkataster_status/list/list.component';
import { TblAbfallartCreateComponent } from '@app/views/stammdaten/tbl_abfallart/create/create.component';
import { TblAbfallartDetailComponent } from '@app/views/stammdaten/tbl_abfallart/detail/detail.component';
import { TblAbfallartEditComponent } from '@app/views/stammdaten/tbl_abfallart/edit/edit.component';
import { TblAbfallartListComponent } from '@app/views/stammdaten/tbl_abfallart/list/list.component';
import { TblAufbauCreateComponent } from '@app/views/stammdaten/tbl_aufbau/create/create.component';
import { TblAufbauDetailComponent } from '@app/views/stammdaten/tbl_aufbau/detail/detail.component';
import { TblAufbauEditComponent } from '@app/views/stammdaten/tbl_aufbau/edit/edit.component';
import { TblAufbauListComponent } from '@app/views/stammdaten/tbl_aufbau/list/list.component';
import { TblFahrgestellCreateComponent } from '@app/views/stammdaten/tbl_fahrgestell/create/create.component';
import { TblFahrgestellDetailComponent } from '@app/views/stammdaten/tbl_fahrgestell/detail/detail.component';
import { TblFahrgestellEditComponent } from '@app/views/stammdaten/tbl_fahrgestell/edit/edit.component';
import { TblFahrgestellListComponent } from '@app/views/stammdaten/tbl_fahrgestell/list/list.component';
import { TblFahrzeugCreateComponent } from '@app/views/stammdaten/tbl_fahrzeug/create/create.component';
import { TblFahrzeugDetailComponent } from '@app/views/stammdaten/tbl_fahrzeug/detail/detail.component';
import { TblFahrzeugEditComponent } from '@app/views/stammdaten/tbl_fahrzeug/edit/edit.component';
import { TblFahrzeugListComponent } from '@app/views/stammdaten/tbl_fahrzeug/list/list.component';
import { TblGebietCreateComponent } from '@app/views/stammdaten/tbl_gebiet/create/create.component';
import { TblGebietDetailComponent } from '@app/views/stammdaten/tbl_gebiet/detail/detail.component';
import { TblGebietEditComponent } from '@app/views/stammdaten/tbl_gebiet/edit/edit.component';
import { TblGebietListComponent } from '@app/views/stammdaten/tbl_gebiet/list/list.component';
import { TblGemeindeCreateComponent } from '@app/views/stammdaten/tbl_gemeinde/create/create.component';
import { TblGemeindeDetailComponent } from '@app/views/stammdaten/tbl_gemeinde/detail/detail.component';
import { TblGemeindeEditComponent } from '@app/views/stammdaten/tbl_gemeinde/edit/edit.component';
import { TblGemeindeListComponent } from '@app/views/stammdaten/tbl_gemeinde/list/list.component';
import { TblLandCreateComponent } from '@app/views/stammdaten/tbl_land/create/create.component';
import { TblLandDetailComponent } from '@app/views/stammdaten/tbl_land/detail/detail.component';
import { TblLandEditComponent } from '@app/views/stammdaten/tbl_land/edit/edit.component';
import { TblLandListComponent } from '@app/views/stammdaten/tbl_land/list/list.component';
import { TblLeerungBarcodeStatusCreateComponent } from '@app/views/stammdaten/tbl_leerung_barcode_status/create/create.component';
import { TblLeerungBarcodeStatusDetailComponent } from '@app/views/stammdaten/tbl_leerung_barcode_status/detail/detail.component';
import { TblLeerungBarcodeStatusEditComponent } from '@app/views/stammdaten/tbl_leerung_barcode_status/edit/edit.component';
import { TblLeerungBarcodeStatusListComponent } from '@app/views/stammdaten/tbl_leerung_barcode_status/list/list.component';
import { TblLifterCreateComponent } from '@app/views/stammdaten/tbl_lifter/create/create.component';
import { TblLifterDetailComponent } from '@app/views/stammdaten/tbl_lifter/detail/detail.component';
import { TblLifterEditComponent } from '@app/views/stammdaten/tbl_lifter/edit/edit.component';
import { TblLifterListComponent } from '@app/views/stammdaten/tbl_lifter/list/list.component';
import { TblMitarbeiterCreateComponent } from '@app/views/stammdaten/tbl_mitarbeiter/create/create.component';
import { TblMitarbeiterDetailComponent } from '@app/views/stammdaten/tbl_mitarbeiter/detail/detail.component';
import { TblMitarbeiterEditComponent } from '@app/views/stammdaten/tbl_mitarbeiter/edit/edit.component';
import { TblMitarbeiterListComponent } from '@app/views/stammdaten/tbl_mitarbeiter/list/list.component';
import { TblOrtCreateComponent } from '@app/views/stammdaten/tbl_ort/create/create.component';
import { TblOrtDetailComponent } from '@app/views/stammdaten/tbl_ort/detail/detail.component';
import { TblOrtEditComponent } from '@app/views/stammdaten/tbl_ort/edit/edit.component';
import { TblOrtListComponent } from '@app/views/stammdaten/tbl_ort/list/list.component';
import { TblStrasseCreateComponent } from '@app/views/stammdaten/tbl_strasse/create/create.component';
import { TblStrasseDetailComponent } from '@app/views/stammdaten/tbl_strasse/detail/detail.component';
import { TblStrasseEditComponent } from '@app/views/stammdaten/tbl_strasse/edit/edit.component';
import { TblStrasseListComponent } from '@app/views/stammdaten/tbl_strasse/list/list.component';
import { TblTourCreateComponent } from '@app/views/stammdaten/tbl_tour/create/create.component';
import { TblTourDetailComponent } from '@app/views/stammdaten/tbl_tour/detail/detail.component';
import { TblTourEditComponent } from '@app/views/stammdaten/tbl_tour/edit/edit.component';
import { TblTourListComponent } from '@app/views/stammdaten/tbl_tour/list/list.component';
import { TblTourGruppeCreateComponent } from '@app/views/stammdaten/tbl_tour_gruppe/create/create.component';
import { TblTourGruppeDetailComponent } from '@app/views/stammdaten/tbl_tour_gruppe/detail/detail.component';
import { TblTourGruppeEditComponent } from '@app/views/stammdaten/tbl_tour_gruppe/edit/edit.component';
import { TblTourGruppeListComponent } from '@app/views/stammdaten/tbl_tour_gruppe/list/list.component';
import { TblVolumenCreateComponent } from '@app/views/stammdaten/tbl_volumen/create/create.component';
import { TblVolumenDetailComponent } from '@app/views/stammdaten/tbl_volumen/detail/detail.component';
import { TblVolumenEditComponent } from '@app/views/stammdaten/tbl_volumen/edit/edit.component';
import { TblVolumenListComponent } from '@app/views/stammdaten/tbl_volumen/list/list.component';
import PATH from '@assets/routes/routes.json';
import { AppMainComponent } from '@components/app.main.component';
import { AuthGuard } from '@helpers/auth.guard';
import { AppAccessdeniedComponent } from '@pages/app.accessdenied.component';
import { AppErrorComponent } from '@pages/app.error.component';
import { GdpaComponent } from '@pages/app.gdpa.component';
import { AppLoginComponent } from '@pages/app.login.component';
import { AppNotfoundComponent } from '@pages/app.notfound.component';
import { OrganizationComponent } from '@views/admin/clients/organization/organization.component';
import { AdminUsersCreateComponent } from '@views/admin/clients/users/create/create.component';
import { AdminUsersDetailComponent } from '@views/admin/clients/users/detail/detail.component';
import { AdminUsersEditComponent } from '@views/admin/clients/users/edit/edit.component';
import { AdminUsersListComponent } from '@views/admin/clients/users/list/list.component';
import { AdminVisyConnectionSettingsCreateComponent } from '@views/admin/visy/connection_settings/create/create.component';
import { AdminVisyConnectionSettingsDetailComponent } from '@views/admin/visy/connection_settings/detail/detail.component';
import { AdminVisyConnectionSettingsEditComponent } from '@views/admin/visy/connection_settings/edit/edit.component';
import { AdminVisyConnectionSettingsListComponent } from '@views/admin/visy/connection_settings/list/list.component';
import { AdminVisyUsersCreateComponent } from '@views/admin/visy/users/create/create.component';
import { AdminVisyUsersDetailComponent } from '@views/admin/visy/users/detail/detail.component';
import { AdminVisyUsersEditComponent } from '@views/admin/visy/users/edit/edit.component';
import { AdminVisyUsersListComponent } from '@views/admin/visy/users/list/list.component';
import { TblRueckfahrkatasterAkteDetailComponent } from '@views/rueckfahrkataster/tbl_rueckfahrkataster_akte/detail/detail.component';
import { TblRueckfahrkatasterAkteEditComponent } from '@views/rueckfahrkataster/tbl_rueckfahrkataster_akte/edit/edit.component';
import { TblRueckfahrkatasterAkteListComponent } from '@views/rueckfahrkataster/tbl_rueckfahrkataster_akte/list/list.component';
import { TblAbfallartTypCreateComponent } from '@views/stammdaten/tbl_abfallart_typ/create/create.component';
import { TblAbfallartTypDetailComponent } from '@views/stammdaten/tbl_abfallart_typ/detail/detail.component';
import { TblAbfallartTypEditComponent } from '@views/stammdaten/tbl_abfallart_typ/edit/edit.component';
import { TblAbfallartTypListComponent } from '@views/stammdaten/tbl_abfallart_typ/list/list.component';
import { TblAnredeCreateComponent } from '@views/stammdaten/tbl_anrede/create/create.component';
import { TblAnredeDetailComponent } from '@views/stammdaten/tbl_anrede/detail/detail.component';
import { TblAnredeEditComponent } from '@views/stammdaten/tbl_anrede/edit/edit.component';
import { TblAnredeListComponent } from '@views/stammdaten/tbl_anrede/list/list.component';
import { TblAvvCreateComponent } from '@views/stammdaten/tbl_avv/create/create.component';
import { TblAvvDetailComponent } from '@views/stammdaten/tbl_avv/detail/detail.component';
import { TblAvvEditComponent } from '@views/stammdaten/tbl_avv/edit/edit.component';
import { TblAvvListComponent } from '@views/stammdaten/tbl_avv/list/list.component';
import { TblTourTypCreateComponent } from '@views/stammdaten/tbl_tour_typ/create/create.component';
import { TblTourTypDetailComponent } from '@views/stammdaten/tbl_tour_typ/detail/detail.component';
import { TblTourTypEditComponent } from '@views/stammdaten/tbl_tour_typ/edit/edit.component';
import { TblTourTypListComponent } from '@views/stammdaten/tbl_tour_typ/list/list.component';
import { TblRueckfahrkatasterAkteCreateComponent } from './views/rueckfahrkataster/tbl_rueckfahrkataster_akte/create/create.component';
import { TblKomponenteTypCreateComponent } from './views/stammdaten/tbl_komponente_typ/create/create.component';
import { TblKomponenteTypDetailComponent } from './views/stammdaten/tbl_komponente_typ/detail/detail.component';
import { TblKomponenteTypEditComponent } from './views/stammdaten/tbl_komponente_typ/edit/edit.component';
import { TblKomponenteTypListComponent } from './views/stammdaten/tbl_komponente_typ/list/list.component';
import { TblKomponenteCreateComponent } from './views/stammdaten/tbl_komponente/create/create.component';
import { TblKomponenteDetailComponent } from './views/stammdaten/tbl_komponente/detail/detail.component';
import { TblKomponenteEditComponent } from './views/stammdaten/tbl_komponente/edit/edit.component';
import { TblKomponenteListComponent } from './views/stammdaten/tbl_komponente/list/list.component';
import { TblFahrzeugAkteKomponenteLinkDetailComponent } from './views/stammdaten/tbl_komponente_fahrzeugakte_link/detail/detail.component';
import { TblFahrzeugAkteKomponenteLinkEditComponent } from './views/stammdaten/tbl_komponente_fahrzeugakte_link/edit/edit.component';
import { TblFahrzeugAbwicklungAbschnittTypCreateComponent } from './views/stammdaten/tbl_fahrzeug_abwicklung_abschnitt_typ/create/create.component';
import { TblFahrzeugAbwicklungAbschnittTypDetailComponent } from './views/stammdaten/tbl_fahrzeug_abwicklung_abschnitt_typ/detail/detail.component';
import { TblFahrzeugAbwicklungAbschnittTypEditComponent } from './views/stammdaten/tbl_fahrzeug_abwicklung_abschnitt_typ/edit/edit.component';
import { TblFahrzeugAbwicklungAbschnittTypListComponent } from './views/stammdaten/tbl_fahrzeug_abwicklung_abschnitt_typ/list/list.component';
import { TblFahrzeugAbwicklungAbschnittCreateComponent } from './views/stammdaten/tbl_fahrzeug_abwicklung_abschnitt/create/create.component';
import { TblFahrzeugAbwicklungAbschnittDetailComponent } from './views/stammdaten/tbl_fahrzeug_abwicklung_abschnitt/detail/detail.component';
import { TblFahrzeugAbwicklungAbschnittEditComponent } from './views/stammdaten/tbl_fahrzeug_abwicklung_abschnitt/edit/edit.component';
import { TblFahrzeugAbwicklungAbschnittListComponent } from './views/stammdaten/tbl_fahrzeug_abwicklung_abschnitt/list/list.component';
import { EswAnmeldungBaumschnittComponent } from './views/berichte/esw/container_overview/esw_anmeldung_baumschnitt.component';

@NgModule({
	imports: [
		RouterModule.forRoot([
			{
				path: '', component: AppMainComponent, canActivate: [AuthGuard],
				children: [
					// #region Home

					{ path: '', redirectTo: PATH.HOME, pathMatch: 'full' },
					{ path: PATH.HOME, component: HomeComponent, canActivate: [AuthGuard] },

					// #endregion Home

					// #region Admin
					{ path: PATH.ADMIN, redirectTo: PATH.USERS },

					// Admin/Organization
					{ path: PATH.ORGANIZATION, component: OrganizationComponent, canActivate: [AuthGuard] },

					// Admin/Users
					{ path: PATH.USERS, redirectTo: PATH.USERS + '/' + PATH.LIST },
					{ path: PATH.USERS + '/' + PATH.CREATE, component: AdminUsersCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.USERS + '/' + PATH.DETAIL + '/:id', component: AdminUsersDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.USERS + '/' + PATH.EDIT + '/:id', component: AdminUsersEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.USERS + '/' + PATH.LIST, component: AdminUsersListComponent, canActivate: [AuthGuard] },

					// Admin/ViSy/ConnectionSettings
					{ path: PATH.CONNECTION_SETTINGS, redirectTo: PATH.CONNECTION_SETTINGS + '/' + PATH.LIST },
					{ path: PATH.CONNECTION_SETTINGS + '/' + PATH.CREATE, component: AdminVisyConnectionSettingsCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.CONNECTION_SETTINGS + '/' + PATH.DETAIL + '/:id', component: AdminVisyConnectionSettingsDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.CONNECTION_SETTINGS + '/' + PATH.EDIT + '/:id', component: AdminVisyConnectionSettingsEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.CONNECTION_SETTINGS + '/' + PATH.LIST, component: AdminVisyConnectionSettingsListComponent, canActivate: [AuthGuard] },

					// Admin/ViSy/Users
					{ path: PATH.VISY_USERS, redirectTo: PATH.VISY_USERS + '/' + PATH.LIST },
					{ path: PATH.VISY_USERS + '/' + PATH.CREATE, component: AdminVisyUsersCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.VISY_USERS + '/' + PATH.DETAIL + '/:id', component: AdminVisyUsersDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.VISY_USERS + '/' + PATH.EDIT + '/:id', component: AdminVisyUsersEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.VISY_USERS + '/' + PATH.LIST, component: AdminVisyUsersListComponent, canActivate: [AuthGuard] },
					{ path: PATH.TEST_USER, component: AdminVisyUsersCreateTestuserComponent, canActivate: [AuthGuard] },

					// #endregion Admin

					// #region BHV

					// #region abfuhrrhythmus
					{ path: PATH.ABFUHRRHYTHMUS, redirectTo: PATH.ABFUHRRHYTHMUS + '/' + PATH.LIST },
					{ path: PATH.ABFUHRRHYTHMUS + '/' + PATH.CREATE, component: TblAbfuhrrhythmusCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.ABFUHRRHYTHMUS + '/' + PATH.DETAIL + '/:id', component: TblAbfuhrrhythmusDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.ABFUHRRHYTHMUS + '/' + PATH.EDIT + '/:id', component: TblAbfuhrrhythmusEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.ABFUHRRHYTHMUS + '/' + PATH.LIST, component: TblAbfuhrrhythmusListComponent, canActivate: [AuthGuard] },
					// #endregion abfuhrrhythmus

					// #region firma
					{ path: PATH.FIRMA, redirectTo: PATH.FIRMA + '/' + PATH.LIST },
					{ path: PATH.FIRMA + '/' + PATH.CREATE, component: TblFirmaCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.FIRMA + '/' + PATH.DETAIL + '/:id', component: TblFirmaDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.FIRMA + '/' + PATH.EDIT + '/:id', component: TblFirmaEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.FIRMA + '/' + PATH.LIST, component: TblFirmaListComponent, canActivate: [AuthGuard] },
					// #endregion firma

					// #region gefaess
					{ path: PATH.GEFAESS, redirectTo: PATH.GEFAESS + '/' + PATH.LIST },
					{ path: PATH.GEFAESS + '/' + PATH.CREATE, component: TblGefaessCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.GEFAESS + '/' + PATH.DETAIL + '/:id', component: TblGefaessDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.GEFAESS + '/' + PATH.EDIT + '/:id', component: TblGefaessEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.GEFAESS + '/' + PATH.LIST, component: TblGefaessListComponent, canActivate: [AuthGuard] },
					// #endregion gefaess

					// #region gefaess_typ
					{ path: PATH.GEFAESS_TYP, redirectTo: PATH.GEFAESS_TYP + '/' + PATH.LIST },
					{ path: PATH.GEFAESS_TYP + '/' + PATH.CREATE, component: TblGefaessTypCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.GEFAESS_TYP + '/' + PATH.DETAIL + '/:id', component: TblGefaessTypDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.GEFAESS_TYP + '/' + PATH.EDIT + '/:id', component: TblGefaessTypEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.GEFAESS_TYP + '/' + PATH.LIST, component: TblGefaessTypListComponent, canActivate: [AuthGuard] },
					// #endregion gefaess_typ

					// #region gefaess_typ
					{ path: PATH.GEFAESS_SPERRE, redirectTo: PATH.GEFAESS_SPERRE + '/' + PATH.LIST },
					{ path: PATH.GEFAESS_SPERRE + '/' + PATH.CREATE, component: TblGefaessSperreCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.GEFAESS_SPERRE + '/' + PATH.DETAIL + '/:id', component: TblGefaessSperreDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.GEFAESS_SPERRE + '/' + PATH.EDIT + '/:id', component: TblGefaessSperreEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.GEFAESS_SPERRE + '/' + PATH.LIST, component: TblGefaessSperreListComponent, canActivate: [AuthGuard] },
					// #endregion gefaess_typ

					// #region gefaess_status
					{ path: PATH.GEFAESS_STATUS, redirectTo: PATH.GEFAESS_STATUS + '/' + PATH.LIST },
					{ path: PATH.GEFAESS_STATUS + '/' + PATH.CREATE, component: TblGefaessStatusCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.GEFAESS_STATUS + '/' + PATH.DETAIL + '/:id', component: TblGefaessStatusDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.GEFAESS_STATUS + '/' + PATH.EDIT + '/:id', component: TblGefaessStatusEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.GEFAESS_STATUS + '/' + PATH.LIST, component: TblGefaessStatusListComponent, canActivate: [AuthGuard] },
					// #endregion gefaess_status

					// #region gefaess_zustand
					{ path: PATH.GEFAESS_ZUSTAND, redirectTo: PATH.GEFAESS_ZUSTAND + '/' + PATH.LIST },
					{ path: PATH.GEFAESS_ZUSTAND + '/' + PATH.CREATE, component: TblGefaessZustandCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.GEFAESS_ZUSTAND + '/' + PATH.DETAIL + '/:id', component: TblGefaessZustandDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.GEFAESS_ZUSTAND + '/' + PATH.EDIT + '/:id', component: TblGefaessZustandEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.GEFAESS_ZUSTAND + '/' + PATH.LIST, component: TblGefaessZustandListComponent, canActivate: [AuthGuard] },
					// #endregion gefaess_zustand

					// #region kunde
					{ path: PATH.KUNDE, redirectTo: PATH.KUNDE + '/' + PATH.LIST },
					{ path: PATH.KUNDE + '/' + PATH.CREATE, component: TblKundeCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.KUNDE + '/' + PATH.DETAIL + '/:id', component: TblKundeDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.KUNDE + '/' + PATH.EDIT + '/:id', component: TblKundeEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.KUNDE + '/' + PATH.LIST, component: TblKundeListComponent, canActivate: [AuthGuard] },
					// #endregion kunde

					// #region auftrag
					{ path: PATH.AUFTRAG, redirectTo: PATH.AUFTRAG + '/' + PATH.LIST },
					{ path: PATH.AUFTRAG + '/' + PATH.CREATE, component: TblAuftragCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.AUFTRAG + '/' + PATH.DETAIL + '/:id', component: TblAuftragDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.AUFTRAG + '/' + PATH.EDIT + '/:id', component: TblAuftragEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.AUFTRAG + '/' + PATH.LIST, component: TblAuftragListComponent, canActivate: [AuthGuard] },
					// #endregion auftrag

					// #region objekt
					{ path: PATH.OBJEKT, redirectTo: PATH.OBJEKT + '/' + PATH.LIST },
					{ path: PATH.OBJEKT + '/' + PATH.CREATE, component: TblObjektCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.OBJEKT + '/' + PATH.DETAIL + '/:id', component: TblObjektDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.OBJEKT + '/' + PATH.EDIT + '/:id', component: TblObjektEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.OBJEKT + '/' + PATH.LIST, component: TblObjektListComponent, canActivate: [AuthGuard] },
					// #endregion objekt

					// #region objekt_gruppe
					{ path: PATH.OBJEKT_GRUPPE, redirectTo: PATH.OBJEKT_GRUPPE + '/' + PATH.LIST },
					{ path: PATH.OBJEKT_GRUPPE + '/' + PATH.CREATE, component: TblObjektGruppeCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.OBJEKT_GRUPPE + '/' + PATH.DETAIL + '/:id', component: TblObjektGruppeDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.OBJEKT_GRUPPE + '/' + PATH.EDIT + '/:id', component: TblObjektGruppeEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.OBJEKT_GRUPPE + '/' + PATH.LIST, component: TblObjektGruppeListComponent, canActivate: [AuthGuard] },
					// #endregion objekt_gruppe

					// #region objekt_status
					{ path: PATH.OBJEKT_STATUS, redirectTo: PATH.OBJEKT_STATUS + '/' + PATH.LIST },
					{ path: PATH.OBJEKT_STATUS + '/' + PATH.CREATE, component: TblObjektStatusCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.OBJEKT_STATUS + '/' + PATH.DETAIL + '/:id', component: TblObjektStatusDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.OBJEKT_STATUS + '/' + PATH.EDIT + '/:id', component: TblObjektStatusEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.OBJEKT_STATUS + '/' + PATH.LIST, component: TblObjektStatusListComponent, canActivate: [AuthGuard] },
					// #endregion objekt_status

					// #region objekt_typ
					{ path: PATH.OBJEKT_TYP, redirectTo: PATH.OBJEKT_TYP + '/' + PATH.LIST },
					{ path: PATH.OBJEKT_TYP + '/' + PATH.CREATE, component: TblObjektTypCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.OBJEKT_TYP + '/' + PATH.DETAIL + '/:id', component: TblObjektTypDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.OBJEKT_TYP + '/' + PATH.EDIT + '/:id', component: TblObjektTypEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.OBJEKT_TYP + '/' + PATH.LIST, component: TblObjektTypListComponent, canActivate: [AuthGuard] },
					// #endregion objekt_typ

					// #endregion BHV

					// #region Manual

					{ path: PATH.MANUAL, component: ManualComponent, canActivate: [AuthGuard] },

					// #endregion Manual

					// #region Stammdaten

					// #region abfallart
					{ path: PATH.ABFALLART, redirectTo: PATH.ABFALLART + '/' + PATH.LIST },
					{ path: PATH.ABFALLART + '/' + PATH.CREATE, component: TblAbfallartCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.ABFALLART + '/' + PATH.DETAIL + '/:id', component: TblAbfallartDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.ABFALLART + '/' + PATH.EDIT + '/:id', component: TblAbfallartEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.ABFALLART + '/' + PATH.LIST, component: TblAbfallartListComponent, canActivate: [AuthGuard] },
					// #endregion abfallart

					// #region abfallart_typ
					{ path: PATH.ABFALLART_TYP, redirectTo: PATH.ABFALLART_TYP + '/' + PATH.LIST },
					{ path: PATH.ABFALLART_TYP + '/' + PATH.CREATE, component: TblAbfallartTypCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.ABFALLART_TYP + '/' + PATH.DETAIL + '/:id', component: TblAbfallartTypDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.ABFALLART_TYP + '/' + PATH.EDIT + '/:id', component: TblAbfallartTypEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.ABFALLART_TYP + '/' + PATH.LIST, component: TblAbfallartTypListComponent, canActivate: [AuthGuard] },
					// #endregion abfallart_typ

					// #region anrede
					{ path: PATH.ANREDE, redirectTo: PATH.ANREDE + '/' + PATH.LIST },
					{ path: PATH.ANREDE + '/' + PATH.CREATE, component: TblAnredeCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.ANREDE + '/' + PATH.DETAIL + '/:id', component: TblAnredeDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.ANREDE + '/' + PATH.EDIT + '/:id', component: TblAnredeEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.ANREDE + '/' + PATH.LIST, component: TblAnredeListComponent, canActivate: [AuthGuard] },
					// #endregion anrede

					// #region aufbau
					{ path: PATH.AUFBAU, redirectTo: PATH.AUFBAU + '/' + PATH.LIST },
					{ path: PATH.AUFBAU + '/' + PATH.CREATE, component: TblAufbauCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.AUFBAU + '/' + PATH.DETAIL + '/:id', component: TblAufbauDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.AUFBAU + '/' + PATH.EDIT + '/:id', component: TblAufbauEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.AUFBAU + '/' + PATH.LIST, component: TblAufbauListComponent, canActivate: [AuthGuard] },
					// #endregion aufbau

					// #region avv
					{ path: PATH.AVV, redirectTo: PATH.AVV + '/' + PATH.LIST },
					{ path: PATH.AVV + '/' + PATH.CREATE, component: TblAvvCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.AVV + '/' + PATH.DETAIL + '/:id', component: TblAvvDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.AVV + '/' + PATH.EDIT + '/:id', component: TblAvvEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.AVV + '/' + PATH.LIST, component: TblAvvListComponent, canActivate: [AuthGuard] },
					// #endregion avv

					// #region fahrgestell
					{ path: PATH.FAHRGESTELL, redirectTo: PATH.FAHRGESTELL + '/' + PATH.LIST },
					{ path: PATH.FAHRGESTELL + '/' + PATH.CREATE, component: TblFahrgestellCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.FAHRGESTELL + '/' + PATH.DETAIL + '/:id', component: TblFahrgestellDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.FAHRGESTELL + '/' + PATH.EDIT + '/:id', component: TblFahrgestellEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.FAHRGESTELL + '/' + PATH.LIST, component: TblFahrgestellListComponent, canActivate: [AuthGuard] },
					// #endregion fahrgestell

					// #region fahrzeug
					{ path: PATH.FAHRZEUG, redirectTo: PATH.FAHRZEUG + '/' + PATH.LIST },
					{ path: PATH.FAHRZEUG + '/' + PATH.CREATE, component: TblFahrzeugCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.FAHRZEUG + '/' + PATH.DETAIL + '/:id', component: TblFahrzeugDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.FAHRZEUG + '/' + PATH.EDIT + '/:id', component: TblFahrzeugEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.FAHRZEUG + '/' + PATH.LIST, component: TblFahrzeugListComponent, canActivate: [AuthGuard] },
					// #endregion fahrzeug

					// #region komponente typ
					{ path: PATH.KOMPONENTE_TYP, redirectTo: PATH.KOMPONENTE_TYP + '/' + PATH.LIST },
					{ path: PATH.KOMPONENTE_TYP + '/' + PATH.CREATE, component: TblKomponenteTypCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.KOMPONENTE_TYP + '/' + PATH.DETAIL + '/:id', component: TblKomponenteTypDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.KOMPONENTE_TYP + '/' + PATH.EDIT + '/:id', component: TblKomponenteTypEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.KOMPONENTE_TYP + '/' + PATH.LIST, component: TblKomponenteTypListComponent, canActivate: [AuthGuard] },
					// #endregion komponente typ

					// #region komponente
					{ path: PATH.KOMPONENTE, redirectTo: PATH.KOMPONENTE + '/' + PATH.LIST },
					{ path: PATH.KOMPONENTE + '/' + PATH.CREATE, component: TblKomponenteCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.KOMPONENTE + '/' + PATH.DETAIL + '/:id', component: TblKomponenteDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.KOMPONENTE + '/' + PATH.EDIT + '/:id', component: TblKomponenteEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.KOMPONENTE + '/' + PATH.LIST, component: TblKomponenteListComponent, canActivate: [AuthGuard] },
					// #endregion komponente

					// #region komponente fahrzeugakte link
					{ path: PATH.FAHRZEUGAKTE_KOMPONENTE_LINK + '/' + PATH.DETAIL + '/:id', component: TblFahrzeugAkteKomponenteLinkDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.FAHRZEUGAKTE_KOMPONENTE_LINK + '/' + PATH.EDIT + '/:id', component: TblFahrzeugAkteKomponenteLinkEditComponent, canActivate: [AuthGuard] },
					// #endregion komponente fahrzeugakte link

					// #region gebiet
					{ path: PATH.GEBIET, redirectTo: PATH.GEBIET + '/' + PATH.LIST },
					{ path: PATH.GEBIET + '/' + PATH.CREATE, component: TblGebietCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.GEBIET + '/' + PATH.DETAIL + '/:id', component: TblGebietDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.GEBIET + '/' + PATH.EDIT + '/:id', component: TblGebietEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.GEBIET + '/' + PATH.LIST, component: TblGebietListComponent, canActivate: [AuthGuard] },
					// #endregion gebiet

					// #region gemeinde
					{ path: PATH.GEMEINDE, redirectTo: PATH.GEMEINDE + '/' + PATH.LIST },
					{ path: PATH.GEMEINDE + '/' + PATH.CREATE, component: TblGemeindeCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.GEMEINDE + '/' + PATH.DETAIL + '/:id', component: TblGemeindeDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.GEMEINDE + '/' + PATH.EDIT + '/:id', component: TblGemeindeEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.GEMEINDE + '/' + PATH.LIST, component: TblGemeindeListComponent, canActivate: [AuthGuard] },
					// #endregion gemeinde

					// #region land
					{ path: PATH.LAND, redirectTo: PATH.LAND + '/' + PATH.LIST },
					{ path: PATH.LAND + '/' + PATH.CREATE, component: TblLandCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.LAND + '/' + PATH.DETAIL + '/:id', component: TblLandDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.LAND + '/' + PATH.EDIT + '/:id', component: TblLandEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.LAND + '/' + PATH.LIST, component: TblLandListComponent, canActivate: [AuthGuard] },
					// #endregion land

					// #region leerung_barcode_status
					{ path: PATH.LEERUNG_BARCODE_STATUS, redirectTo: PATH.LEERUNG_BARCODE_STATUS + '/' + PATH.LIST },
					{ path: PATH.LEERUNG_BARCODE_STATUS + '/' + PATH.CREATE, component: TblLeerungBarcodeStatusCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.LEERUNG_BARCODE_STATUS + '/' + PATH.DETAIL + '/:id', component: TblLeerungBarcodeStatusDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.LEERUNG_BARCODE_STATUS + '/' + PATH.EDIT + '/:id', component: TblLeerungBarcodeStatusEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.LEERUNG_BARCODE_STATUS + '/' + PATH.LIST, component: TblLeerungBarcodeStatusListComponent, canActivate: [AuthGuard] },
					// #endregion leerung_barcode_status

					// #region lifter
					{ path: PATH.LIFTER, redirectTo: PATH.LIFTER + '/' + PATH.LIST },
					{ path: PATH.LIFTER + '/' + PATH.CREATE, component: TblLifterCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.LIFTER + '/' + PATH.DETAIL + '/:id', component: TblLifterDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.LIFTER + '/' + PATH.EDIT + '/:id', component: TblLifterEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.LIFTER + '/' + PATH.LIST, component: TblLifterListComponent, canActivate: [AuthGuard] },
					// #endregion lifter

					// #region mitarbeiter
					{ path: PATH.MITARBEITER, redirectTo: PATH.MITARBEITER + '/' + PATH.LIST },
					{ path: PATH.MITARBEITER + '/' + PATH.CREATE, component: TblMitarbeiterCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.MITARBEITER + '/' + PATH.DETAIL + '/:id', component: TblMitarbeiterDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.MITARBEITER + '/' + PATH.EDIT + '/:id', component: TblMitarbeiterEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.MITARBEITER + '/' + PATH.LIST, component: TblMitarbeiterListComponent, canActivate: [AuthGuard] },
					// #endregion mitarbeiter

					// #region ort
					{ path: PATH.ORT, redirectTo: PATH.ORT + '/' + PATH.LIST },
					{ path: PATH.ORT + '/' + PATH.CREATE, component: TblOrtCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.ORT + '/' + PATH.DETAIL + '/:id', component: TblOrtDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.ORT + '/' + PATH.EDIT + '/:id', component: TblOrtEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.ORT + '/' + PATH.LIST, component: TblOrtListComponent, canActivate: [AuthGuard] },
					// #endregion ort

					// #region strasse
					{ path: PATH.STRASSE, redirectTo: PATH.STRASSE + '/' + PATH.LIST },
					{ path: PATH.STRASSE + '/' + PATH.CREATE, component: TblStrasseCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.STRASSE + '/' + PATH.DETAIL + '/:id', component: TblStrasseDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.STRASSE + '/' + PATH.EDIT + '/:id', component: TblStrasseEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.STRASSE + '/' + PATH.LIST, component: TblStrasseListComponent, canActivate: [AuthGuard] },
					// #endregion strasse

					// #region tour
					{ path: PATH.TOUR, redirectTo: PATH.TOUR + '/' + PATH.LIST },
					{ path: PATH.TOUR + '/' + PATH.CREATE, component: TblTourCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.TOUR + '/' + PATH.DETAIL + '/:id', component: TblTourDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.TOUR + '/' + PATH.EDIT + '/:id', component: TblTourEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.TOUR + '/' + PATH.LIST, component: TblTourListComponent, canActivate: [AuthGuard] },
					// #endregion tour

					// #region tour_gruppe
					{ path: PATH.TOUR_GRUPPE, redirectTo: PATH.TOUR_GRUPPE + '/' + PATH.LIST },
					{ path: PATH.TOUR_GRUPPE + '/' + PATH.CREATE, component: TblTourGruppeCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.TOUR_GRUPPE + '/' + PATH.DETAIL + '/:id', component: TblTourGruppeDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.TOUR_GRUPPE + '/' + PATH.EDIT + '/:id', component: TblTourGruppeEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.TOUR_GRUPPE + '/' + PATH.LIST, component: TblTourGruppeListComponent, canActivate: [AuthGuard] },
					// #endregion tour_gruppe

					// #region tour_typ
					{ path: PATH.TOUR_TYP, redirectTo: PATH.TOUR_TYP + '/' + PATH.LIST },
					{ path: PATH.TOUR_TYP + '/' + PATH.CREATE, component: TblTourTypCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.TOUR_TYP + '/' + PATH.DETAIL + '/:id', component: TblTourTypDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.TOUR_TYP + '/' + PATH.EDIT + '/:id', component: TblTourTypEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.TOUR_TYP + '/' + PATH.LIST, component: TblTourTypListComponent, canActivate: [AuthGuard] },
					// #endregion tour_typ

					// #region volumen
					{ path: PATH.VOLUMEN, redirectTo: PATH.VOLUMEN + '/' + PATH.LIST },
					{ path: PATH.VOLUMEN + '/' + PATH.CREATE, component: TblVolumenCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.VOLUMEN + '/' + PATH.DETAIL + '/:id', component: TblVolumenDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.VOLUMEN + '/' + PATH.EDIT + '/:id', component: TblVolumenEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.VOLUMEN + '/' + PATH.LIST, component: TblVolumenListComponent, canActivate: [AuthGuard] },
					// #endregion volumen

					// #region Fahrzeug Abwicklung Abschnitt
					{ path: PATH.FAHRZEUG_ABWICKLUNG_ABSCHNITT, redirectTo: PATH.FAHRZEUG_ABWICKLUNG_ABSCHNITT + '/' + PATH.LIST },
					{ path: PATH.FAHRZEUG_ABWICKLUNG_ABSCHNITT + '/' + PATH.CREATE, component: TblFahrzeugAbwicklungAbschnittCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.FAHRZEUG_ABWICKLUNG_ABSCHNITT + '/' + PATH.DETAIL + '/:id', component: TblFahrzeugAbwicklungAbschnittDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.FAHRZEUG_ABWICKLUNG_ABSCHNITT + '/' + PATH.EDIT + '/:id', component: TblFahrzeugAbwicklungAbschnittEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.FAHRZEUG_ABWICKLUNG_ABSCHNITT + '/' + PATH.LIST, component: TblFahrzeugAbwicklungAbschnittListComponent, canActivate: [AuthGuard] },
					// #endregion Fahrzeug Abwicklung Abschnitt

					// #region Fahrzeug Abwicklung Abschnitt Typ
					{ path: PATH.FAHRZEUG_ABWICKLUNG_ABSCHNITT_TYP, redirectTo: PATH.FAHRZEUG_ABWICKLUNG_ABSCHNITT_TYP + '/' + PATH.LIST },
					{ path: PATH.FAHRZEUG_ABWICKLUNG_ABSCHNITT_TYP + '/' + PATH.CREATE, component: TblFahrzeugAbwicklungAbschnittTypCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.FAHRZEUG_ABWICKLUNG_ABSCHNITT_TYP + '/' + PATH.DETAIL + '/:id', component: TblFahrzeugAbwicklungAbschnittTypDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.FAHRZEUG_ABWICKLUNG_ABSCHNITT_TYP + '/' + PATH.EDIT + '/:id', component: TblFahrzeugAbwicklungAbschnittTypEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.FAHRZEUG_ABWICKLUNG_ABSCHNITT_TYP + '/' + PATH.LIST, component: TblFahrzeugAbwicklungAbschnittTypListComponent, canActivate: [AuthGuard] },
					// #endregion Fahrzeug Abwicklung Abschnitt Typ

					// #endregion Stammdaten

					// #region Online

					// #region online/leerung
					{ path: PATH.ONLINE, redirectTo: PATH.ONLINE_FAHRZEUGE },
					{ path: PATH.ONLINE_LEERUNGEN, component: OnlineLeerungComponent, canActivate: [AuthGuard] },
					// #endregion online/leerung

					// #region online/karte
					{ path: PATH.ONLINE_FAHRZEUGE, component: OnlineKarteComponent, canActivate: [AuthGuard] },
					// #endregion online/karte

					// #endregion Online

					// #region Berichte

					// #region leerung
					{ path: PATH.LEERUNG, redirectTo: PATH.LEERUNG + '/' + PATH.LIST },
					{ path: PATH.LEERUNG + '/' + PATH.LIST, component: TblLeerungListComponent, canActivate: [AuthGuard] },
					{ path: PATH.LEERUNG + '/' + PATH.CREATE, component: TblLeerungListComponent, canActivate: [AuthGuard] },
					// #endregion leerung

					// #region fahrtbericht
					{ path: PATH.FAHRTEBRICHT, redirectTo: PATH.FAHRTEBRICHT + '/' + PATH.LIST },
					{ path: PATH.FAHRTEBRICHT + '/' + PATH.LIST, component: TblFahrtberichtListComponent, canActivate: [AuthGuard] },
					{ path: PATH.FAHRTEBRICHT + '/' + PATH.DETAIL + '/:id', component: TblFahrtberichtDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.FAHRTEBRICHT + '/' + PATH.EDIT + '/:id', component: TblFahrtberichtEditComponent, canActivate: [AuthGuard] },
					// #endregion fahrtbericht

					// #endregion Berichte

					// #region Rückfahrkartaster

					// #region rueckfahrkataster_akte
					{ path: PATH.RK_STRECKE, redirectTo: PATH.RK_STRECKE + '/' + PATH.LIST },
					{ path: PATH.RK_STRECKE + '/' + PATH.CREATE, component: TblRueckfahrkatasterAkteCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.RK_STRECKE + '/' + PATH.DETAIL + '/:id', component: TblRueckfahrkatasterAkteDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.RK_STRECKE + '/' + PATH.EDIT + '/:id', component: TblRueckfahrkatasterAkteEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.RK_STRECKE + '/' + PATH.LIST, component: TblRueckfahrkatasterAkteListComponent, canActivate: [AuthGuard] },
					// #endregion rueckfahrkataster_akte

					// #region rueckfahrkataster_aufzeichnung
					{ path: PATH.RK_AUFZEICHNUNG, redirectTo: PATH.RK_AUFZEICHNUNG + '/' + PATH.LIST },
					{ path: PATH.RK_AUFZEICHNUNG + '/' + PATH.CREATE, component: TblRueckfahrkatasterAufzeichnungCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.RK_AUFZEICHNUNG + '/' + PATH.DETAIL + '/:id', component: TblRueckfahrkatasterAufzeichnungDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.RK_AUFZEICHNUNG + '/' + PATH.EDIT + '/:id', component: TblRueckfahrkatasterAufzeichnungEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.RK_AUFZEICHNUNG + '/' + PATH.LIST, component: TblRueckfahrkatasterAufzeichnungListComponent, canActivate: [AuthGuard] },
					// #endregion rueckfahrkataster_aufzeichnung

					// #region rueckfahrkataster_massnahme
					{ path: PATH.RK_MASSNAHME, redirectTo: PATH.RK_MASSNAHME + '/' + PATH.LIST },
					{ path: PATH.RK_MASSNAHME + '/' + PATH.CREATE, component: TblRueckfahrkatasterMassnahmeCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.RK_MASSNAHME + '/' + PATH.DETAIL + '/:id', component: TblRueckfahrkatasterMassnahmeDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.RK_MASSNAHME + '/' + PATH.EDIT + '/:id', component: TblRueckfahrkatasterMassnahmeEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.RK_MASSNAHME + '/' + PATH.LIST, component: TblRueckfahrkatasterMassnahmeListComponent, canActivate: [AuthGuard] },
					// #endregion rueckfahrkataster_massnahme

					// #region rueckfahrkataster_grund
					{ path: PATH.RK_GRUND, redirectTo: PATH.RK_GRUND + '/' + PATH.LIST },
					{ path: PATH.RK_GRUND + '/' + PATH.CREATE, component: TblRueckfahrkatasterGrundCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.RK_GRUND + '/' + PATH.DETAIL + '/:id', component: TblRueckfahrkatasterGrundDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.RK_GRUND + '/' + PATH.EDIT + '/:id', component: TblRueckfahrkatasterGrundEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.RK_GRUND + '/' + PATH.LIST, component: TblRueckfahrkatasterGrundListComponent, canActivate: [AuthGuard] },
					// #endregion rueckfahrkataster_grund

					// #region rueckfahrkataster_gefahr
					{ path: PATH.RK_GEFAHR, redirectTo: PATH.RK_GEFAHR + '/' + PATH.LIST },
					{ path: PATH.RK_GEFAHR + '/' + PATH.CREATE, component: TblRueckfahrkatasterGefahrCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.RK_GEFAHR + '/' + PATH.DETAIL + '/:id', component: TblRueckfahrkatasterGefahrDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.RK_GEFAHR + '/' + PATH.EDIT + '/:id', component: TblRueckfahrkatasterGefahrEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.RK_GEFAHR + '/' + PATH.LIST, component: TblRueckfahrkatasterGefahrListComponent, canActivate: [AuthGuard] },
					// #endregion rueckfahrkataster_gefahr

					// #region rueckfahrkataster_status
					{ path: PATH.RK_STATUS, redirectTo: PATH.RK_STATUS + '/' + PATH.LIST },
					{ path: PATH.RK_STATUS + '/' + PATH.CREATE, component: TblRueckfahrkatasterStatusCreateComponent, canActivate: [AuthGuard] },
					{ path: PATH.RK_STATUS + '/' + PATH.DETAIL + '/:id', component: TblRueckfahrkatasterStatusDetailComponent, canActivate: [AuthGuard] },
					{ path: PATH.RK_STATUS + '/' + PATH.EDIT + '/:id', component: TblRueckfahrkatasterStatusEditComponent, canActivate: [AuthGuard] },
					{ path: PATH.RK_STATUS + '/' + PATH.LIST, component: TblRueckfahrkatasterStatusListComponent, canActivate: [AuthGuard] },
					// #endregion rueckfahrkataster_status

					// #endregion Rückfahrkartaster

					// #region Einstellungen

					{ path: PATH.SETTINGS, redirectTo: PATH.GENERAL_SETTINGS },
					{ path: PATH.GENERAL_SETTINGS, component: SettingsComponent, canActivate: [AuthGuard] },
					{ path: PATH.CHANGE_PASSWORD, component: ChangePasswordComponent, canActivate: [AuthGuard] },

					// #endregion Einstellungen

					// #region Impressum

					{ path: PATH.TERMS, component: TermsComponent },

					// #endregion Impressum
				]
			},

			// #region ESW

			{ path: PATH.ESW, component: EswComponent },
			{ path: PATH.ESW_ANMELDUNG_BAUMSCHNITT, component: EswAnmeldungBaumschnittComponent },

			// #endregion ESW

			// #region Pages

			{ path: PATH.ACCESS_DENIED, component: AppAccessdeniedComponent },
			{ path: PATH.ERROR, component: AppErrorComponent },
			{ path: PATH.GDPA, component: GdpaComponent },
			{ path: PATH.LOGIN, component: AppLoginComponent },
			{ path: PATH.NOT_FOUND, component: AppNotfoundComponent },
			{ path: '**', redirectTo: '/notfound' },

			// #endregion Pages

		], { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
