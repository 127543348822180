import { formatDate } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { Base } from '@app/models/base';
import { Collection } from '@app/models/collection';
import { Module, Operation } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { ExportService } from '@app/services/export.service';
import { MapService } from '@app/services/map.service';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { environment } from '@environments/environment';
import * as signalR from '@microsoft/signalr';
import { IHttpConnectionOptions } from '@microsoft/signalr';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { jsPDF } from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import { defaults as defaultControls, FullScreen, ScaleLine, ZoomSlider } from 'ol/control';
import { Point } from 'ol/geom';
import { Feature, Map, View } from 'ol/index';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { useGeographic } from 'ol/proj';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Circle, Fill, Icon, Style } from 'ol/style';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { MultiSelect } from 'primeng/multiselect';
import { Splitter } from 'primeng/splitter';
import { Table } from 'primeng/table';
import { OnlineCollectionsFilterDialogComponent } from './collection_filter_dialog/collection_filter_dialog.component';

@Component({
	templateUrl: './collections.component.html',
	styleUrls: ['./style.scss'],
	providers: [MessageService, ConfirmationService, DialogService]
})
export class OnlineLeerungComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('colselection') colselection: MultiSelect;
	@ViewChild('splitter') splitter: Splitter;
	@ViewChild('table') table: Table;
	apiUrl: string = '';
	buttonColWidth: number = 150;
	cols: any[] = [];
	contentHeight: number = 4320;
	contextMenu: MenuItem[];
	coord = [10.447683, 51.163361];
	count: number = 0;
	createPermission: boolean = false;
	deletePermission: boolean = false;
	entries: any[];
	filters: string[];
	globalFilter: string = '';
	highlightedCollection = null;
	hubConnections = [];
	imageUrl: string;
	isMobile: boolean = false;
	isTableInit: boolean = false;
	leerungLayer: VectorLayer;
	leerungStyle: Style;
	loadFilters: any;
	loading: number = 0;
	loadTimestamp: Date;
	map: Map;
	mapLayer: TileLayer;
	mapWidth: string = '400px';
	name: string = '';
	module: Module;
	oldCols: any[] = [];
	possibleCols: any[] = [];
	readPermission: boolean = false;
	selectedEntry: Base;
	selectionLayer: VectorLayer;
	showMap: boolean = true;
	splitterLayout: string = 'horizontal';
	state: any;
	stateName: string = '';
	tableWidth: string = '400px';
	tooltip: string = '';
	trackByFunction = (index, item) => {
		return item.ds_auto_id;
	}
	updatePermission: boolean = false;
	url: string = '';
	zoom = 6.5;

	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public changeDetectorRef: ChangeDetectorRef,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public dialogService: DialogService,
		public elRef: ElementRef,
		public exportService: ExportService,
		public globals: Globals,
		public mapService: MapService,
		public messageService: MessageService,
		public router: Router,
		public settingsService: SettingsService,
		public translate: TranslateService,
	) {
		this.apiUrl = 'TblOnline';
		this.name = 'MENU.LEERUNGEN';
		this.url = '/' + PATH.ONLINE_LEERUNGEN;
		this.stateName = 'state' + this.apiUrl + 'Leerung' + 'List';
		this.module = Module.OnlineCollections;

		this.possibleCols = [
			{ type: 'date', key: 'se_timestamp', required: false, width: 150 },
			{ type: 'text', key: 'be_event_display', required: false, width: 150 },
			{ type: 'text', key: 'lrg_barcode', required: false, width: 150 },
			{ type: 'text', key: 'FREMD_fahrzeug_bezeichnung', required: false, width: 150 },
			{ type: 'text', key: 'FREMD_fahrzeug_kennzeichen', required: false, width: 150 },
			{ type: 'text', key: 'se_signature', required: false, width: 150 },
			{ type: 'text', key: 'FREMD_fahrer_bezeichnung', required: false, width: 150 },
			{ type: 'text', key: 'FREMD_lader1_bezeichnung', required: false, width: 150 },
			{ type: 'text', key: 'FREMD_lader2_bezeichnung', required: false, width: 150 },
			{ type: 'text', key: 'FREMD_abfallart_bezeichnung', required: false, width: 150 },
			{ type: 'text', key: 'FREMD_tour_bezeichnung', required: false, width: 150 },
			{ type: 'text', key: 'lrg_gefaessnr', required: false, width: 150 },
			{ type: 'text', key: 'lrg_status', required: false, width: 150 },
			{ type: 'text', key: 'lrg_quelle', required: false, width: 150 },
			{ type: 'text', key: 'lrg_error', required: false, width: 150 },
			{ type: 'text', key: 'lrg_brutto_display', required: false, width: 150 },
			{ type: 'text', key: 'lrg_netto_display', required: false, width: 150 },
			{ type: 'text', key: 'lrg_info', required: false, width: 150 },
		];

		this.cols = [
			{ type: 'date', key: 'se_timestamp', required: false, width: 150 },
			{ type: 'text', key: 'lrg_barcode', required: false, width: 150 },
			{ type: 'text', key: 'FREMD_fahrzeug_kennzeichen', required: false, width: 150 },
			{ type: 'text', key: 'lrg_gefaessnr', required: false, width: 150 },
			{ type: 'text', key: 'lrg_brutto_display', required: false, width: 150 },
			{ type: 'text', key: 'lrg_netto_display', required: false, width: 150 },
		];

		this.breadcrumbService.setItems([
			{ label: 'MENU.ONLINE' },
			{ label: this.name, routerLink: [this.url] }
		]);
	}

	// #region Angular

	ngOnInit() {
		this.loading += 1;

		this.stateName = 'state' + this.apiUrl + 'List';

		this.createPermission = this.accountService.checkPermissions(Module.Masterdata, Operation.CREATE);
		this.readPermission = this.accountService.checkPermissions(Module.Masterdata, Operation.READ);
		this.updatePermission = this.accountService.checkPermissions(Module.Masterdata, Operation.UPDATE);
		this.deletePermission = this.accountService.checkPermissions(Module.Masterdata, Operation.DELETE);

		if (this.readPermission) {
			this.loading += 1;
			this.mapService.init().then(res => {
				//
			}).catch(() => {
				//
			}).finally(() => {
				this.loading -= 1;
			})

			if ((localStorage.getItem('defaultOpenVehicleDialog') === 'true')) {
				this.openFilterDialog();
			}
		}

		this.retrieveTableState(this.state);
		if (this.state && this.state.columnOrder) {
			this.cols = [];
			this.state.columnOrder.forEach(col => {
				this.possibleCols.forEach(c => {
					if (col == c.key) {
						this.cols.push(c);
					}
				});
			});
		}
		if (this.state && this.state.filters && this.state.filters.global) {
			this.globalFilter = this.state.filters.global.value;
		}

		this.isMobile = JSON.parse(this.globals.log_Platform).Mobile == 'yes' || JSON.parse(this.globals.log_Platform).Tablet == 'yes';

		this.translate.get('init').subscribe((text: string) => {
			this.contextMenu = [
				{ label: this.translate.instant('CONTEXT_MENU.CREATE'), icon: 'pi pi-fw pi-plus', command: () => this.create() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN'), icon: 'pi pi-fw pi-search', command: () => this.detail() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_TAB'), icon: 'pi pi-fw pi-search', command: () => this.detail('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_WINDOW'), icon: 'pi pi-fw pi-search', command: () => this.detail('window') },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT'), icon: 'pi pi-fw pi-pencil', command: () => this.edit() },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT_TAB'), icon: 'pi pi-fw pi-pencil', command: () => this.edit('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT_WINDOW'), icon: 'pi pi-fw pi-pencil', command: () => this.edit('window') },
				{ label: this.translate.instant('CONTEXT_MENU.DELETE'), icon: 'pi pi-fw pi-trash', command: () => this.delete() }
			];
			this.possibleCols.forEach(c => {
				c.label = this.translate.instant('HEADERS.' + c.key);
			});
		});

		this.oldCols = this.cols;
		this.filters = this.cols.map(c => c.key);

		this.loading -= 1;
	}

	ngAfterViewInit() {
		this.settingsService.footerVisibilityChange.subscribe(value => {
			this.initTable();
		});

		if (this.table.filters) {
			let restoredFilter = false;
			this.filters.forEach(col => {
				Object.keys(this.table.filters[col]).forEach(filter => {
					if (this.table.filters[col][filter]['value'] != null) {
						restoredFilter = true;
					}
				})
			});
			if (restoredFilter) {
				this.messageService.add({ key: 'reset', severity: 'warn', summary: this.translate.instant('MESSAGES.WARNING'), detail: this.translate.instant('MESSAGES.LOADED_FILTER'), life: 10000 });
			}
		}

		const el = document.querySelector<HTMLElement>('.cdk-virtual-scroll-viewport');
		this.changeWheelSpeed(el, 0.9);

		this.initTable();
		this.initMap();
	}

	ngAfterViewChecked() {
		if (!this.isTableInit) {
			this.isTableInit = true;
			setTimeout(() => {
				if (window.innerWidth < 1650) {
					this.splitterLayout = 'vertical';
				} else {
					this.splitterLayout = 'horizontal';
				}
				this.splitter._panelSizes = [...this.splitter._panelSizes];
				this.initTable();
			}, 0);
		}
	}

	ngOnDestroy() {
		// Beim Schließen der Seite sollen alle Hub Verbindungen getrennt werden
		this.disconnectAllFromDatahub();
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		setTimeout(() => {
			if (window.innerWidth < 1650) {
				this.splitterLayout = 'vertical';
			} else {
				this.splitterLayout = 'horizontal';
			}
		}, 0);
		this.initTable();
	}

	// #endregion Angular

	// #region CRUD

	create() {
		this.router.navigate([this.url + '/create']);
	}

	detail(target?: string) {
		if (target == 'window') {
			window.open('/#/' + this.url + '/detail/' + this.selectedEntry.ds_this_id, '_blank', 'newWindow=1');
		} else if (target == 'tab') {
			window.open('/#/' + this.url + '/detail/' + this.selectedEntry.ds_this_id);
		} else {
			this.router.navigate([this.url + '/detail/' + this.selectedEntry.ds_this_id]);
		}
	}

	edit(target?: string) {
		if (target == 'window') {
			window.open('/#/' + this.url + '/edit/' + this.selectedEntry.ds_this_id, '_blank', 'newWindow=1');
		} else if (target == 'tab') {
			window.open('/#/' + this.url + '/edit/' + this.selectedEntry.ds_this_id);
		} else {
			this.router.navigate([this.url + '/edit/' + this.selectedEntry.ds_this_id]);
		}
	}

	delete(ds_this_id?: number) {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.DELETE_QUESTION'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.loading += 1;
				let sections: number[];
				this.crudService.getFilteredTripReportSections(ds_this_id ? ds_this_id : this.selectedEntry.ds_this_id).then(res => {
					this.loading += 1;
					this.crudService.deleteTripReport(ds_this_id ? ds_this_id : this.selectedEntry.ds_this_id).then(res => {
						this.loading += 1;
						this.crudService.deleteTripReportSections(sections).then(res => {
							// Do nothing
						}).catch(err => {
							err.error.forEach(e => {
								this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
							})
						}).finally(() => {
							this.loading -= 1;
						});
						this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });
						this.entries.splice(this.entries.findIndex(x => x.ds_this_id == (ds_this_id ? ds_this_id : this.selectedEntry.ds_this_id)), 1);
						this.count = this.entries.length;
						this.entries = [...this.entries];
					}).catch(err => {
						err.error.forEach(e => {
							this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
						})
					}).finally(() => {
						this.loading -= 1;
					});
				}).catch(err => {
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				}).finally(() => {
					this.loading -= 1;
				});
			}
		});
	}

	// #endregion CRUD

	// #region Table

	buildReport(exportCollections: Collection[]): RowInput[] {
		const content = [];
		const dates = {};
		exportCollections.forEach(collection => {
			const dateString = new Date(collection.f_zeitpunkt).toLocaleDateString();
			if (dates[dateString]) {
				dates[dateString].Gesamt += 1;
			} else {
				dates[dateString] = { Gesamt: 1 };
			}

			const area = (collection.gebiet_id ? collection.gebiet_id + ' - ' : '') + (collection.f_gebiet ? collection.f_gebiet : 'Unbekannt');
			if (dates[dateString][area]) {
				dates[dateString][area].Gesamt += 1;
			} else {
				dates[dateString][area] = { Gesamt: 1 };
			}

			const file = collection.dateiname ? collection.dateiname : 'Unbekannt';
			if (dates[dateString][area][file]) {
				dates[dateString][area][file].Gesamt += 1;
			} else {
				dates[dateString][area][file] = { Gesamt: 1 };
			}
		});

		const dateLineCounts = {};
		Object.keys(dates).forEach(date => {
			dateLineCounts[date] = 1;
			Object.keys(dates[date]).forEach(area => {
				Object.keys(dates[date][area]).forEach(file => {
					dateLineCounts[date] += 1;
				});
			});
		});

		Object.keys(dates).forEach(date => {
			let firstLineOfDate = true;
			const sortedAreas = Object.keys(dates[date]).sort((a, b) => (+a.split(' - ')[0] > +b.split(' - ')[0]) ? 1 : ((+b.split(' - ')[0] > +a.split(' - ')[0]) ? -1 : 0))
			sortedAreas.forEach(area => {
				let firstLineOfArea = true;
				if (area !== 'Gesamt') {
					Object.keys(dates[date][area]).forEach(file => {
						if (file !== 'Gesamt') {
							if (firstLineOfDate && firstLineOfArea) {
								content.push([
									{ content: date, rowSpan: dateLineCounts[date] },
									{ content: area, rowSpan: Object.keys(dates[date][area]).length },
									{ content: file },
									{ content: dates[date][area][file].Gesamt }
								]);
								firstLineOfDate = false;
								firstLineOfArea = false;
							} else if (!firstLineOfDate && firstLineOfArea) {
								content.push([
									{ content: area, rowSpan: Object.keys(dates[date][area]).length },
									{ content: file },
									{ content: dates[date][area][file].Gesamt }
								]);
								firstLineOfArea = false;
							} else {
								content.push([
									{ content: file },
									{ content: dates[date][area][file].Gesamt }
								]);
							}
						}
					});
					content.push([
						{ content: 'Gesamt', styles: { fontStyle: 'bold' } },
						{ content: dates[date][area].Gesamt, styles: { fontStyle: 'bold' } }
					]);
				}
			});
			content.push([
				{ content: 'Gesamt', colSpan: 2, styles: { fontStyle: 'bold' } },
				{ content: dates[date].Gesamt, styles: { fontStyle: 'bold' } }
			]);
		});

		if (Object.keys(dates).length > 1) {
			content.push([
				{ content: 'Gesamt', colSpan: 3, styles: { fontStyle: 'bold' } },
				{ content: exportCollections.length, styles: { fontStyle: 'bold' } }
			]);
		}

		return content;
	}

	changeWheelSpeed(container, speedY) {
		var scrollY = 0;
		var handleScrollReset = function () {
			scrollY = container.scrollTop;
		};
		var handleMouseWheel = function (e) {
			e.preventDefault();
			scrollY += speedY * e.deltaY
			if (scrollY < 0) {
				scrollY = 0;
			} else {
				var limitY = container.scrollHeight - container.clientHeight;
				if (scrollY > limitY) {
					scrollY = limitY;
				}
			}
			container.scrollTop = scrollY;
		};

		var removed = false;
		container.addEventListener('mouseup', handleScrollReset, false);
		container.addEventListener('mousedown', handleScrollReset, false);
		container.addEventListener('mousewheel', handleMouseWheel, false);

		return function () {
			if (removed) {
				return;
			}
			container.removeEventListener('mouseup', handleScrollReset, false);
			container.removeEventListener('mousedown', handleScrollReset, false);
			container.removeEventListener('mousewheel', handleMouseWheel, false);
			removed = true;
		};
	}

	connectToDatahub(logboxId, filters) {
		var hubConnection = new signalR.HubConnectionBuilder()
			.withUrl(
				`${environment.apiUrl}/onlineLeerungenHub?logboxid=` + logboxId +
				'&dateFrom=' + new Date(filters.dateFrom).toISOString() +
				'&dateTo=' + new Date(filters.dateTo).toISOString() +
				'&eventTypes=' + filters.eventTypes +
				'&status=' + filters.status,
				{
					accessTokenFactory: () => {
						return window.localStorage.getItem('jwt');
					},
					skipNegotiation: true,
					transport: signalR.HttpTransportType.WebSockets,
				} as IHttpConnectionOptions)
			.configureLogging({
				log: (logLevel, message) => {
					if (logLevel > 1) {
						console.log(formatDate(new Date(), 'MM/dd/yyyy HH:mm:ss', 'de') + ' [' + 0 + '] ' + message);
					}
				}
			})
			.withAutomaticReconnect()
			.build();

		if (hubConnection != null && hubConnection != undefined) {
			hubConnection.on('ReceiveNewCollectionData', (message) => {
				const data = JSON.parse(message);
				if (data) {
					this.loadTimestamp = new Date();
					this.hubUpdateReceived(data);
				}
			});

			hubConnection.start();
			this.hubConnections.push({ id: logboxId, connection: hubConnection });
		}
	}

	createReport() {
		this.loading += 1;
		if (!this.entries || this.entries.length === 0) {
			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORS.NO_DATA'), detail: this.translate.instant('ERRORS.LOAD_DATA_FIRST'), life: 3000 });
		} else {
			const doc = new jsPDF({
				orientation: 'p',
				format: 'a4'
			});

			let title = 'Leerungsbericht - ';
			if (this.loadFilters) {
				if (this.loadFilters.DateFrom.toLocaleDateString() === this.loadFilters.DateTo.toLocaleDateString()) {
					title += this.loadFilters.DateFrom.toLocaleDateString();
				} else {
					title += this.loadFilters.DateFrom.toLocaleDateString() + ' bis ' + this.loadFilters.DateTo.toLocaleDateString();
				}
			}

			const disposers = {};

			const collections = this.entries.sort((a, b) => { return a.f_zeitpunkt.getTime() - b.f_zeitpunkt.getTime(); });
			collections.forEach(collection => {
				const disposerName = collection.FREMD_entsorger_bezeichnung ? collection.FREMD_entsorger_bezeichnung : 'Unbekannt';
				if (disposers[disposerName]) {
					disposers[disposerName].push(collection);
				} else {
					disposers[disposerName] = [collection];
				}
			});

			Object.keys(disposers).forEach(disposer => {
				let top = 15;

				if (this.imageUrl) {
					const img = new Image();
					img.src = this.imageUrl; // 'assets/images/collection_report_header.png';
					doc.addImage(img, 'png', 30, 10, 150, 18);
					top = 30;
				}

				autoTable(doc, {
					theme: 'grid',
					rowPageBreak: 'auto',
					startY: top,
					styles: { valign: 'middle', halign: 'center', fontSize: 8, cellPadding: 1 },
					head: [
						[{
							content: title + ' - ' + disposer,
							colSpan: 4,
							styles: {
								halign: 'center', fontSize: 14, fillColor: false, textColor: [0, 0, 0],
								cellPadding: { bottom: 5 }
							}
						}],
						[
							{ content: 'Datum', styles: { halign: 'center', fontSize: 12, fillColor: [150, 150, 150], textColor: [250, 250, 250] } },
							{ content: 'Gebiet', styles: { halign: 'center', fontSize: 12, fillColor: [150, 150, 150], textColor: [250, 250, 250] } },
							{ content: 'Datei', styles: { halign: 'center', fontSize: 12, fillColor: [150, 150, 150], textColor: [250, 250, 250] } },
							{ content: 'Anzahl', styles: { halign: 'center', fontSize: 12, fillColor: [150, 150, 150], textColor: [250, 250, 250] } }
						]
					],
					body: this.buildReport(disposers[disposer])
				});
				doc.addPage();
			});
			doc.deletePage(doc.getNumberOfPages());

			doc.save(title + '.pdf');
		}

		this.loading -= 1;
	}

	disconnectAllFromDatahub() {
		if (this.loadFilters) {
			this.loadFilters.seSignatures.split(',').forEach(logboxId => {
				this.disconnectFromDatahub(logboxId);
			});
		}
	}

	disconnectFromDatahub(logboxId: string) {
		this.hubConnections.forEach(c => {
			if (c.id === logboxId) {
				try {
					c.connection.stop();
				} catch (e) {
					console.log(e);
				}
				this.hubConnections.splice(this.hubConnections.indexOf(c), 1);
				return;
			}
		});
	}

	exportCSV() {
		this.exportService.exportCSV(this.translate.instant('MENU.RUECKFAHRKATASTER'), (this.table && this.table.filteredValue ? this.table.filteredValue : this.table.value), this.cols);
	}

	exportPDF() {
		this.exportService.exportPDF(this.translate.instant('MENU.RUECKFAHRKATASTER'), (this.table && this.table.filteredValue ? this.table.filteredValue : this.table.value), this.cols);
	}

	exportXLSX() {
		this.exportService.exportXLSX(this.translate.instant('MENU.RUECKFAHRKATASTER'), (this.table && this.table.filteredValue ? this.table.filteredValue : this.table.value), this.cols);
	}

	getFilteredEntries(filters) {
		this.loading += 1;
		this.messageService.clear('refresh');
		this.crudService.getOnlineCollections(filters).then(res => {
			this.messageService.clear();
			this.loadTimestamp = new Date();
			this.processEntries(res);
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	hubUpdateReceived(data: any[]) {
		this.loading += 1;

		// Alle Datenfelder von String in Date Objekte umwandeln
		this.possibleCols.forEach(c => {
			if (c.type == 'date') {
				data.forEach(e => {
					if (e[c.key] != null) {
						e[c.key] = new Date(e[c.key] + 'Z'); // UTC vorausgesetzt
					}
				});
			}
		});

		// Koordinaten auf für die Karte benötigtes Format konvertieren
		data.forEach(c => {
			c.gps_longitude = c.gps_longitude / 6000000;
			c.gps_latitude = c.gps_latitude / 6000000;
		});

		// Empfangene Daten an vorhandene Liste anhängen
		this.entries = this.entries ? [...this.entries, ...data] : data;
		this.count = this.entries.length;

		setTimeout(() => {
			this.updateCollections(this.entries);
		}, 0);

		this.loading -= 1;
	}

	initTable() {
		setTimeout(() => {
			this.contentHeight = 400;
			document.getElementById('collectionMap').style.height = this.contentHeight + 'px';
			this.resizeTableWidth();
		}, 0);
		setTimeout(() => {
			this.map.updateSize();
		}, 0);
		setTimeout(() => {
			if (this.elRef && this.elRef.nativeElement && this.elRef.nativeElement.parentElement && this.elRef.nativeElement.parentElement.offsetHeight) {
				this.contentHeight = this.elRef.nativeElement.parentElement.offsetHeight - 210 + ((localStorage.getItem('showFooter') === 'true') ? 5 : 0)
				if (this.contentHeight < 400) {
					this.contentHeight = 400;
				}
			}
		}, 0);
		this.adaptMapSize();

		// resetScrollTop überschreiben, damit bei Data Update die Liste nicht immer nach oben scrollt
		if (this.table != null) {
			this.table.resetScrollTop = function () { }
		}
	}

	isColFiltered(col) {
		let isFiltered = false;
		if (this.table && this.table.filters[col.key]) {
			Object.keys(this.table.filters[col.key]).forEach(filter => {
				if (this.table.filters[col.key][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		else if (this.state && this.state.filters[col.key]) {
			Object.keys(this.state.filters[col.key]).forEach(filter => {
				if (this.state.filters[col.key][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		return isFiltered;
	}

	onFilter(event) {
		this.count = (this.table && this.table.filteredValue) ? this.table.filteredValue.length : this.entries.length;
	}

	onColReorder(event) {
		this.retrieveTableState(this.state);
		const columnWidths = this.state.columnWidths.split(',');
		columnWidths.splice(event.dropIndex, 0, columnWidths.splice(event.dragIndex, 1)[0]);
		this.state.columnWidths = columnWidths.join(',');
		localStorage.setItem(this.stateName, JSON.stringify(this.state));
	}

	onColResize(event) {
		const index = Array.from(event.element.parentNode.children).indexOf(event.element);
		this.retrieveTableState(this.state);
		this.cols[index].width = Number(event.element.style.width.split('px')[0]);
		this.state.columnWidths = (this.cols.map(c => c.width)).concat([this.buttonColWidth]).join(',');
		localStorage.setItem(this.stateName, JSON.stringify(this.state));

		this.resizeTableWidth(this.state);
	}

	openFilterDialog() {
		const ref = this.dialogService.open(OnlineCollectionsFilterDialogComponent, {
			header: this.translate.instant('Leerungen Filter'),
			width: '70%'
		});

		ref.onClose.subscribe((filters) => {
			this.entries = [];
			this.count = 0;
			this.disconnectAllFromDatahub();
			if (filters && this.accountService.checkPermissions(this.module, Operation.READ)) {
				this.loadFilters = filters;
				this.getFilteredEntries(filters);

				if (new Date(filters.dateTo).getDate() == new Date().getDate() && new Date(filters.dateTo).getMonth() == new Date().getMonth() && new Date(filters.dateTo).getFullYear() == new Date().getFullYear()) {
					filters.seSignatures.split(',').forEach(logboxId => {
						this.connectToDatahub(logboxId, filters);
					});
				}
			}
		});
	}

	processEntries(entries) {
		this.loading += 1;

		// Alle Datenfelder von String in Date Objekte umwandeln
		this.possibleCols.forEach(c => {
			if (c.type == 'date') {
				entries.forEach(e => {
					if (e[c.key] != null) {
						e[c.key] = new Date(e[c.key]);
					}
				});
			}
		});

		// Koordinaten auf für die Karte benötigtes Format konvertieren
		entries.forEach(c => {
			c.gps_longitude = c.gps_longitude / 6000000;
			c.gps_latitude = c.gps_latitude / 6000000;
		});

		this.entries = entries;
		this.count = this.entries.length;

		setTimeout(() => {
			this.updateCollections(this.entries);
		}, 0);

		this.loading -= 1;
	}

	resetTable() {
		this.table.clearState();
		window.location.reload();
	}

	resizeTableWidth(state?) {
		this.loading += 1;

		this.retrieveTableState(this.state);
		if (this.table) {
			const tableElement = document.getElementById(this.table.id);
			this.tableWidth = (this.splitterLayout == 'horizontal' ? 0.01 * this.splitter._panelSizes[0] * this.splitter.containerViewChild.nativeElement.clientWidth + 'px' : '100%');
			tableElement.style.width = '100%';
			const columnWidths = this.state ? this.state.columnWidths.split(',') : (this.cols.map(c => c.width)).concat([this.buttonColWidth]);
			const contentWidth = columnWidths.reduce((summe, element) => summe + Number(element), 0);
			const tableWidthOffset = tableElement.clientWidth - contentWidth;
			for (let index = 0; index < this.cols.length; index++) {
				this.cols[index].width = Number(columnWidths[index]);
			}
			if (tableWidthOffset > 0 && this.cols.length > 0) {
				this.cols[this.cols.length - 1].width += tableWidthOffset;
				if (this.contentHeight < (this.table.filteredValue ? this.table.filteredValue.length : (this.table.value ? this.table.value.length : 0)) * this.table.virtualRowHeight) {
					this.cols[this.cols.length - 1].width -= 10;
				}
			}

			document.getElementById(this.table.id + '-table').style.width = this.cols.reduce((summe, element) => summe + element.width, 0) + this.buttonColWidth + 'px';
			document.getElementById(this.table.id + '-table').style.minWidth = this.cols.reduce((summe, element) => summe + element.width, 0) + this.buttonColWidth + 'px';

			setTimeout(() => {
				if (this.state) {
					localStorage.setItem(this.stateName, JSON.stringify(this.state));
				}
			}, 0);
		}

		this.loading -= 1;
	}

	retrieveTableState(state?) {
		this.state = state ? state : JSON.parse(localStorage.getItem(this.stateName));
		if (this.table && (this.state == undefined)) {
			// for storage of table state
			this.table.saveState();
			// reload and parse
			this.state = JSON.parse(localStorage.getItem(this.stateName));
		}
	}

	toggleColumn(event) {
		this.retrieveTableState(this.state);
		this.state.columnOrder = event.value.map(c => c.key);
		this.state.columnWidths = event.value.map(c => c.width);
		this.state.columnWidths = this.state.columnWidths.join(',');
		this.state.columnWidths = this.state.columnWidths + ',' + this.buttonColWidth;
		this.state.tableWidth = (this.state.columnWidths.split(',')).reduce((summe, element) => summe + Number(element), 0) + 'px';
		this.filters = event.value.map(c => c.key);
		localStorage.setItem(this.stateName, JSON.stringify(this.state));
		this.resizeTableWidth(this.state);
	}

	// #endregion Table

	// #region Map

	adaptMapSize() {
		setTimeout(() => {
			document.getElementById('collectionMap').style.height = this.contentHeight + 130 + 'px';
			this.mapWidth = (this.splitterLayout == 'horizontal' ? 0.01 * this.splitter._panelSizes[1] * this.splitter.containerViewChild.nativeElement.clientWidth - 4 + 'px' : '100%');
		}, 0);
		setTimeout(() => {
			this.map.updateSize();
		}, 0);
	}

	calcIconScale() {
		return 1.0 / (1.0 + (this.map.getView().getMaxZoom() - this.map.getView().getZoom()) / 3);
	}

	center(event) {
		const entry = this.entries.find(x => x.ds_auto_id === event.data.ds_auto_id);
		this.map.updateSize();
		this.map.getView().centerOn([entry.gps_longitude, entry.gps_latitude], this.map.getSize(), [this.map.getSize()[0] / 2, this.map.getSize()[1] / 2]);
		this.selectFeature(entry.gps_longitude, entry.gps_latitude, entry.se_timestamp + (entry.lrg_barcode ? ' - ' + entry.lrg_barcode : ''));
	}

	displayTooltip(event) {
		const pixel = event.pixel;
		document.getElementById('tooltip').style.display = 'none';
		this.map.forEachFeatureAtPixel(pixel, feature => {
			this.tooltip = feature.get('name');
			document.getElementById('tooltip').style.display = 'inherit';
			document.getElementById('tooltip').style.top = (document.getElementById('collectionMap').getBoundingClientRect().top - document.body.getBoundingClientRect().top - 25 + event.pixel[1]) + 'px';
			document.getElementById('tooltip').style.left = (document.getElementById('collectionMap').getBoundingClientRect().left - document.body.getBoundingClientRect().left + 5 + event.pixel[0]) + 'px';
		});
	}

	highlightCollection(collection: Collection, feature = null) {
		const newCenter = [collection.gps_longitude, collection.gps_latitude];
		this.map.getView().animate({
			center: newCenter,
			zoom: 18,
			duration: 1000
		})

		const iconScale = 1.5 / (1.0 + this.map.getView().getMaxZoom() - this.map.getView().getZoom());

		if (this.highlightedCollection) {
			this.highlightedCollection.feature.setStyle(
				new Style({
					image: new Icon({
						src: 'assets/icons/trash-green.png',
						scale: iconScale
					})
				})
			);
		}
		if (!feature) {
			this.leerungLayer.getSource().getFeatures().forEach(f => {
				if (f.get('name').split(' - ')[1] === collection.lrg_barcode) {
					feature = f;
				}
			});
		} else {
			this.table.scrollToVirtualIndex(this.table.value.indexOf(collection));
		}
		feature.setStyle(
			new Style({
				image: new Icon({
					src: 'assets/icons/trash-brown.png',
					scale: iconScale
				})
			})
		);
		this.selectedEntry = collection;
		this.highlightedCollection = { collection, feature };
	}

	initMap() {
		this.leerungStyle = new Style({
			image: new Circle({
				radius: 4,
				fill: new Fill({ color: 'red' }),
			})
		});

		this.mapLayer = new TileLayer({
			preload: Infinity,
			source: new OSM({
				url: environment.mapUrl,
				format: 'image/png',
				crossOrigin: 'anonymous'
			})
		});
		this.leerungLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			style: this.leerungStyle,
			name: 'Leerungen'
		});
		this.selectionLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			zIndex: 2000,
			style: new Style({
				image: new Icon({
					src: 'assets/icons/circle.png'
				})
			}),
			name: 'Selection'
		});

		useGeographic();
		this.map = new Map({
			controls: defaultControls({ attribution: false }).extend([new FullScreen(), new ScaleLine(), new ZoomSlider()]),
			target: 'collectionMap',
			view: new View({
				center: this.coord,
				zoom: this.zoom,
				minZoom: 5,
				maxZoom: 18,
			}),
			renderer: 'webgl',
			layers: [
				this.mapLayer,
				this.leerungLayer,
				this.selectionLayer
			]
		});
		this.map.on('pointermove', event => {
			this.displayTooltip(event);
		});
		this.map.on('moveend', () => {
			if (this.map.getView().getZoom() !== this.zoom) {
				this.zoom = this.map.getView().getZoom();
				const scale = 1.5 / (1.0 + this.map.getView().getMaxZoom() - this.map.getView().getZoom());
				this.leerungLayer.getSource().getFeatures().forEach(feature => {
					feature.getStyle().getImage().setScale(scale);
				});
				this.leerungLayer.getSource().changed();
			}
		});
		this.map.on('click', (event) => {
			this.map.forEachFeatureAtPixel(
				event.pixel,
				(feature) => {
					this.selectedEntry = this.entries.find(x => x.ds_auto_id == feature.getId());
					this.selectFeature(this.selectedEntry['gps_longitude'], this.selectedEntry['gps_latitude'], feature.get('name'));
					this.table.scrollToVirtualIndex(this.table.value.indexOf(this.selectedEntry));
					return true;
				},
				{
					hitTolerance: 5,
					layerFilter: (layer) => {
						return layer === this.leerungLayer;
					}
				}
			);
		});
	}

	selectFeature(long, lat, name) {
		const selectionFeature = new Feature({
			geometry: new Point([long, lat]),
			name: name
		})
		this.selectionLayer.getSource().clear();
		const selectionStyle = new Style({
			image: new Icon({
				src: 'assets/icons/circle.png',
				scale: this.calcIconScale()
			})
		});
		this.selectionLayer.setStyle(selectionStyle);
		this.selectionLayer.getSource().addFeature(selectionFeature);
	}

	showHideMap() {
		this.showMap = !this.showMap;
		this.adaptMapSize();
		this.resizeTableWidth();
		setTimeout(() => {
			this.map.updateSize();
		}, 100);
	}

	updateCollections(shownCollections: Collection[]) {
		let newCenter;
		this.leerungLayer.getSource().clear();

		const iconScale = 1.5 / (1.0 + this.map.getView().getMaxZoom() - this.map.getView().getZoom());

		shownCollections.forEach(c => {
			if (c.gps_latitude !== 0 || c.gps_longitude !== 0) {
				newCenter = [c.gps_longitude, c.gps_latitude]; // 6000000 ist der Umrechnungsfaktor zur Datenbank, keine Ahnung wieso
				const feature = new Feature({
					geometry: new Point(newCenter),
					name: c.se_timestamp.toLocaleString() + (c.lrg_barcode ? ' - ' + c.lrg_barcode : '')
				})
				feature.setId(c.ds_auto_id);
				feature.setStyle(
					new Style({
						image: new Icon({
							src: 'assets/icons/trash-green.png',
							scale: iconScale
						})
					})
				);
				this.leerungLayer.getSource().addFeature(feature);
			}
		});

		if (newCenter && !this.highlightedCollection) {
			this.map.getView().animate({
				center: newCenter,
				zoom: (this.map.getView().getZoom() <= 13 ? 13 : this.map.getView().getZoom()),
				duration: 1000
			})
		}
	}

	// #endregion Map
}
