import { Injectable } from '@angular/core';
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper';

@Injectable()
export class ZPLPrintService {
	constructor() { }

	async print(zplString: string) {
		try {
			// Create a new instance of the object
			const browserPrint = new ZebraBrowserPrintWrapper();
			// Select default printer
			const defaultPrinter = await (browserPrint.getDefaultPrinter());
			// Set the printer
			browserPrint.setPrinter(defaultPrinter);
			// Check if the printer is ready
			const printerStatus = await browserPrint.checkPrinterStatus();

			if (printerStatus.isReadyToPrint) {
				// ZPL script to print a qr code
				browserPrint.print(zplString);

				const printerStatus = await browserPrint.checkPrinterStatus();

				if (printerStatus.isReadyToPrint) {
					console.log("Print success");
				}
				else {
					console.log("Error/s - printing not finished", printerStatus.errors);
					throw new Error("Druckfehler");
				}
			} else {
				console.log("Error/s - unable to print", printerStatus.errors);
				throw new Error("Nicht gedruckt");
			}

		} catch (error) {
			throw new Error(error);
		}
	}
}
