import { Component, OnInit } from '@angular/core';
import { Timespans } from '@app/models/date';
import { Module, Operation } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { DateService } from '@app/services/date.service';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	templateUrl: './filter_dialog.component.html',
	styleUrls: ['./style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class FilterDialogComponent implements OnInit {
	loading = 0;
	optionGroups: Map<any, any>[];
	permissions: { module: Module, operation: Operation }[] = [];
	selections: any = {};
	manualData: any[] = [[], [], [], [], [], [], [], [], [], []];

	commitButtonLabel: string = "Laden";
	commitButtonVisible: boolean = true;
	

	constructor(
		public accountService: AccountService,
		public crudService: CRUDService,
		public config: DynamicDialogConfig,
		public dateService: DateService,
		public messageService: MessageService,
		public ref: DynamicDialogRef,
		public translate: TranslateService
	) {
		//if( this.config && this.config.data && this.config.data['manualData1']) {
		//	this.manualData = this.config.data['manualData1'];
		//}

		this.setCommitButtonText(); // init default

		if( this.config && this.config.data ) {
			// init custom button label
			if( this.config.data['buttonLabel'] ) {
				this.setCommitButtonText(this.config.data['buttonLabel']);
			}

			// init up to 10 manualData arrays
			for( let i = 0; i < 10; ++i ) {
				let fieldKey: string = 'manualData' + (i+1).toString();
				if( this.config.data[fieldKey] ) {
					this.manualData[i] = this.config.data[fieldKey];
				}
			}
		}
	}

	ngOnInit() {
		this.loading += 1;
		if (this.isPermitted()) {
			this.loadOptions();
		}
		this.loading -= 1;
	}

	public setCommitButtonText(displayText: string = "default") : void {
		if( !displayText || displayText === 'default') {
			this.commitButtonLabel = this.translate.instant('BUTTONS.LOAD');
		} else {
			this.commitButtonLabel = displayText;
		}
		this.redrawCommitButton();
	}

	ngAfterViewInit() {
		this.redrawCommitButton();
	}

	redrawCommitButton() {
		this.commitButtonVisible = false;
		try {
			setTimeout(() => this.commitButtonVisible = true, 0);
			//console.log('all went well...');
			//console.log(this.commitButtonLabel);
		} catch(err) {
			//console.log(err);
		}
	}

	convertTimespanSelection(event, fromDateFieldName: string, toDateFieldName: string) {
		const timespan = this.dateService.getTimespan(event.value);
		if (timespan != null) {
			this.selections[fromDateFieldName] = timespan.fromDate;
			this.selections[toDateFieldName] = timespan.toDate;
		}
	}

	convertTimestampSelection(event, dateFieldName: string) {
		const timestamp = this.dateService.getDate(event.value);
		if (timestamp != null) {
			this.selections[dateFieldName] = timestamp;
		}
	}

	inputDate(dateFieldName: string, input: string, timespanFieldName?: string) {
		let date = DateService.inputDateExStatic(input);
		if (date) {
			this.selections[dateFieldName] = date;
			this.selections[timespanFieldName] = null;
		}
	}

	isPermitted(): boolean {
		this.permissions.forEach(permission => {
			if (!this.accountService.checkPermissions(permission.module, permission.operation)) {
				return false;
			}
		});
		return true;
	}

	load() {
		this.loading += 1;
		if (this.isPermitted()) {
			this.ref.close(this.selections);
		} else {
			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORS.ACCESS_DENIED'), detail: this.translate.instant('ERRORS.ACCESS_DENIED_MSG'), life: 3000 });
		}
		this.loading -= 1;
	}

	loadOptions() {
		this.optionGroups.forEach(options => {
			options.forEach((option, key) => {
				if (option['type'] == 'timespan') {
					this.loading += 1;
					this.translate.get('init').subscribe((text: string) => {
						Object.values(Timespans).filter((v) => isNaN(Number(v))).forEach((key, index) => {
							option['values'].push(
								{ label: this.translate.instant('TIMESPANS.' + key), value: index }
							);
						});
					});
					this.loading -= 1;
				} else if (option['type'] == 'dropdown' || option['type'] == 'multiselect') {
					if( option['apiUrl'] ) {
						let strApiUrl: string = option['apiUrl'];
						let strBase = 'manualdata';
						let lengthBase = strBase.length;
						if( strApiUrl.toLowerCase().startsWith(strBase) ) {
							if( strApiUrl.toLowerCase().startsWith(strBase) && strApiUrl.length > lengthBase ) {
								let index = parseInt(strApiUrl.substring(lengthBase));
								if( index && index >= 1 && index <= 10 ) {
									option['values'] = this.manualData[index-1]; // siehe constructor, input option['apiUrl'] = 'manualData1' bis 'manualData10'
								}
							}
						} else {
							this.selections[key] = [];
							this.loading += 1;
							this.crudService.getAllEntries(option['apiUrl']).then(res => {
								option['values'] = res;
							}).catch(err => {
								err.error.forEach(e => {
									this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
								})
							}).finally(() => {
								this.loading -= 1;
							});
						}
					}
				} else {
					this.selections[key] = null;
				}
			});
		});
	}

	unsorted() { }
}
