import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { ListComponent } from '@app/common/templates/list/list.component';
import { Base } from '@app/models/base';
import { Module } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { AdminService } from '@app/services/admin.service';
import { CRUDService } from '@app/services/crud.service';
import { ExportService } from '@app/services/export.service';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
	templateUrl: './list.component.html',
	styleUrls: ['../style.scss', '../../../../../common/templates/list/style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class AdminVisyUsersListComponent extends ListComponent {
	edit_url = PATH.EDIT;
	detail_url = PATH.DETAIL;

	constructor(
		public accountService: AccountService,
		public adminService: AdminService,
		public breadcrumbService: BreadcrumbService,
		public changeDetectorRef: ChangeDetectorRef,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public elRef: ElementRef,
		public exportService: ExportService,
		public globals: Globals,
		public messageService: MessageService,
		public router: Router,
		public settingsService: SettingsService,
		public translate: TranslateService
	) {
		super(accountService, breadcrumbService, changeDetectorRef, confirmationService, crudService, elRef, exportService, globals, messageService, router, settingsService, translate);

		this.apiUrl = 'users/visy';
		this.name = 'MENU.NUTZER';
		this.url = '/' + PATH.VISY_USERS;
		this.module = Module.Masterdata;

		this.possibleCols = [
			{ type: 'text', key: 'UserEmail', required: true, width: 400 },
			{ type: 'text', key: 'RoleName', required: true, width: 400 },
			{ type: 'text', key: 'UserConnectionSettingsName', required: true, width: 400 },
		];
		this.cols = [
			{ type: 'text', key: 'UserEmail', required: true, width: 400 },
			{ type: 'text', key: 'RoleName', required: true, width: 400 },
			{ type: 'text', key: 'UserConnectionSettingsName', required: true, width: 400 },
		];

		this.breadcrumbService.setItems([
			{ label: 'MENU.ADMIN' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.LIST', routerLink: [this.url + '/' + PATH.LIST] }
		]);
	}

	override getAllEntries(): void {
		this.loading += 1;
		this.messageService.clear('refresh');
		this.adminService.getAllViSyUsers().then(res => {
			this.loadTimestamp = new Date();
			if (!this.persistenceCheckInterval) {
				this.persistenceCheckInterval = setInterval(() => {
					this.crudService.checkPersistence(this.apiUrl, this.loadTimestamp).then(isPersistent => {
						if (!isPersistent) {
							this.messageService.add({ key: 'refresh', severity: 'warn', summary: this.translate.instant('BUTTONS.REFRESH'), detail: this.translate.instant('MESSAGES.NEW_DATA_AVAILABLE') });
						}
					}).catch(err => {
						err.error.forEach(e => {
							this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
						})
					});
				}, 1000 * 60 * 10);
			}
			this.entries = res as Base[];
			this.count = this.entries.length;
			this.possibleCols.forEach(c => {
				if (c.type == 'date') {
					this.entries.forEach(e => {
						if (e[c.key] != null) {
							e[c.key] = new Date(e[c.key]);
						}
					});
				}
			});
			this.resizeTableWidth();
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	createTestUser(): void {
		this.router.navigate([PATH.TEST_USER]);
	}
}