<!-- Message -->
<p-toast>
	<ng-template let-message pTemplate="message">
		<div class="p-grid p-ml-2">
			<div class="p-col-2">
				<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
			</div>
			<div class="p-col-10">
				<h4>{{message.summary}}</h4>
			</div>
			<div class="p-col-12">
				<p>{{message.detail}}</p>
			</div>
			<div *ngIf="message.severity!='success'" class="p-col-12">
				<a class="p-button"
					href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
					Fehler melden
				</a>
			</div>
		</div>
	</ng-template>
</p-toast>

<!-- Spinner -->
<div *ngIf="loading!==0" class="spinner">
	<span class="ball-1"></span>
	<span class="ball-2"></span>
	<span class="ball-3"></span>
	<span class="ball-4"></span>
	<span class="ball-5"></span>
	<span class="ball-6"></span>
	<span class="ball-7"></span>
	<span class="ball-8"></span>
</div>

<!-- Content -->
<div *ngIf="progress!=null">
	<p-progressBar [value]="progress"></p-progressBar>
	<!--<button pButton pRipple type="button" label="Details" class="p-button-text"
		(click)="showDetails=!showDetails"></button>-->
	<div *ngIf="showDetails">
		{{progressDetails}}
	</div>
	<div *ngIf="showDoneErrorRunningInfo" class="p-mt-4">
		{{progressDoneInfo}}
	</div>
	<div *ngIf="showDone" class="p-my-0 p-mx-1">
		<button pButton pRipple type="button" label="Ok" class="p-button-text"
		(click)="close()"></button>
	</div>
</div>

<div *ngIf="progress==null">
	<div class="card" style="display: flex;">
		<i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
		<p class="p-ml-2">
			Aktualisieren oder Ersetzen der Identcode-Sperrliste mit Daten aus einer Textdatei. 
			Bitte nutzen Sie diese Funktion nur in Rücksprache mit Ihrem Dienstleister, und stellen Sie sicher, dass die gewünschten Sperren 
			der Eingabedatei aktuell und gemäß der Schnittstellenvereinbarung korrekt sind.
		</p>
	</div>
	
	<div class="p-my-2">
		<p-checkbox [(ngModel)]="modeReplaceLocks" [binary]="true" inputId="mreplace"
			(onChange)="changeReplaceMode()">
		</p-checkbox>
		<label for="mreplace">&nbsp;&nbsp;&nbsp;Vorhandene Sperren LÖSCHEN vor Import (Import ERSETZT ALLE bisherige Sperren)</label>
	</div>
	<div class="p-my-2">&nbsp;</div>
	<div class="p-my-2">
		<p-fileUpload #uploadButton mode="basic" [maxFileSize]="20971520" fileLimit="1" accept=".dat, .txt, .*"
			[disabled]="!hubConnectionId" chooseLabel="{{'BUTTONS.UPLOAD_FILE'|translate}}" chooseIcon="pi-upload"
			(onUpload)="disconnectFromHub()" (onBeforeUpload)="progress = 0"
			(onError)="uploadError($event.error.error[0]);uploadButton.clear();" withCredentials="true"
			[headers]="headers" url="{{fileUploadUrl}}/{{hubConnectionId}}" [auto]="true">
		</p-fileUpload>
	</div>
</div>
