<div class="p-grid">
	<div class="p-col-12">

		<!-- Message -->
		<p-toast>
			<ng-template let-message pTemplate="message">
				<div class="p-grid p-ml-2">
					<div class="p-col-2">
						<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
					</div>
					<div class="p-col-10">
						<h4>{{message.summary}}</h4>
					</div>
					<div class="p-col-12">
						<p>{{message.detail}}</p>
					</div>
					<div *ngIf="message.severity!='success'" class="p-col-12">
						<a class="p-button"
							href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
							Fehler melden
						</a>
					</div>
				</div>
			</ng-template>
		</p-toast>

		<!-- Spinner -->
		<div *ngIf="loading!==0" class="spinner">
			<span class="ball-1"></span>
			<span class="ball-2"></span>
			<span class="ball-3"></span>
			<span class="ball-4"></span>
			<span class="ball-5"></span>
			<span class="ball-6"></span>
			<span class="ball-7"></span>
			<span class="ball-8"></span>
		</div>

		<div class="card">
			<!-- Toolbar -->
			<p-toolbar styleClass="p-mb-4">
				<ng-template pTemplate="left">
					<button pButton pRipple label="{{'BUTTONS.SAVE'|translate}}" class="p-button-success p-mr-2"
						(click)="save()"></button>
				</ng-template>
				<ng-template pTemplate="right">
				</ng-template>
			</p-toolbar>

			<!-- Content -->
			<div *ngIf="entry&&loading===0">
				<h4 class="p-my-2">Allgemein</h4>
				<div *ngFor="let fields of fieldgroups" class="p-my-2">
					<div class="p-grid p-formgrid">
						<div *ngFor="let field of fields" class="p-col-12 p-md-6 p-lg-3 p-my-2">
							<h6 class="p-my-2">{{field.label}}</h6>
							<div *ngIf="field.type === 'string'">
								<input type="text" pInputText [value]="entry[field.key]"
									(input)="entry[field.key]=$event.target.value">
							</div>
							<div *ngIf="field.type === 'date'">
								<p-calendar [showIcon]="true" [showTime]="true" [showSeconds]="true" hourFormat="24"
									dateFormat="dd.mm.yy" keepInvalid="true"
									(onInput)="inputDate(field.key, $event.target.value)" [ngModel]="entry[field.key]"
									(onSelect)="entry[field.key]=$event" appendTo="body">
								</p-calendar>
							</div>
							<div *ngIf="field.type === 'number'">
								<p-inputNumber [(ngModel)]="entry[field.key]" [showButtons]="true" [min]="0"
									mode="decimal" locale="de-DE" [style]="{'width':'100%'}"></p-inputNumber>
							</div>
							<div *ngIf="field.type === 'dropdown'">
								<p-dropdown [options]="options[field.key]" [(ngModel)]="entry[field.key]"
									placeholder="-" [showClear]="false" [filter]="true" filterBy="label" appendTo="body"
									dropdownDirective></p-dropdown>
							</div>
							<small *ngIf="field.required && !entry[field.key]" class="p-error"
								translate>REQUIRED</small>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div *ngFor="let section of sections">
			<button type="button" pButton pRipple icon="fa fa-plus" class="p-button-success"
				pTooltip="{{'BUTTONS.ADD' | translate }}" tooltipPosition="bottom"
				(click)="addSection(sections.indexOf(section))"></button>
			<div class="card p-my-2"
				[ngStyle]="{'backdrop-filter': isDarkMode() ? 'brightness(0.5)' : 'brightness(1.5)', 'background':'none', 'background-color': getBackgroundColor(section.typ_id)}">
				<div class="p-grid p-formgrid">
					<div class="p-col-11">
						<h6 class="p-my-2">
							Typ
						</h6>
						<p-dropdown class="sectionheader" [options]="options['abschnitt_typ_id']"
							[(ngModel)]="section['typ_id']" placeholder="-" [showClear]="false" [filter]="true"
							filterBy="label" appendTo="body" dropdownDirective></p-dropdown>
					</div>
					<div class="p-col-1" style="text-align: right;">
						<button type="button" pButton pRipple icon="fa fa-trash-can" class="p-button-danger"
							pTooltip="{{'BUTTONS.DELETE' | translate }}" tooltipPosition="bottom"
							(click)="deleteSection(section)"></button>
					</div>
					<div class="p-col-12 p-md-6 p-lg-3 p-my-2">
						<h6 class="p-my-2">
							Datum von
						</h6>
						<p-calendar [showIcon]="true" [showTime]="true" [showSeconds]="true" hourFormat="24"
							dateFormat="dd.mm.yy" keepInvalid="true"
							(onInput)="inputSectionDate(sections.indexOf(section), 'anfang_zeit', $event.target.value)"
							[ngModel]="section['anfang_zeit']" (onSelect)="section['anfang_zeit']=$event"
							appendTo="body" [monthNavigator]="true" [yearNavigator]="true"
							[yearRange]="'2007:'+currentYear">
						</p-calendar>
					</div>
					<div class="p-col-12 p-md-6 p-lg-3 p-my-2">
						<h6 class="p-my-2">
							Datum bis
						</h6>
						<p-calendar [showIcon]="true" [showTime]="true" [showSeconds]="true" hourFormat="24"
							dateFormat="dd.mm.yy" keepInvalid="true"
							(onInput)="inputSectionDate(sections.indexOf(section), 'ende_zeit', $event.target.value)"
							[ngModel]="section['ende_zeit']" (onSelect)="section['ende_zeit']=$event" appendTo="body">
						</p-calendar>
					</div>
					<div class="p-col-12 p-md-6 p-lg-3 p-my-2">
						<h6 class="p-my-2">
							Meter von
						</h6>
						<p-inputNumber [(ngModel)]="section.anfang_meter" [showButtons]="true" [min]="0" mode="decimal"
							[style]="{'width':'100%'}" locale="de-DE"></p-inputNumber>
					</div>
					<div class="p-col-12 p-md-6 p-lg-3 p-my-2">
						<h6 class="p-my-2">
							Meter bis
						</h6>
						<p-inputNumber [(ngModel)]="section.ende_meter" [showButtons]="true" [min]="0" mode="decimal"
							[style]="{'width':'100%'}" locale="de-DE"></p-inputNumber>
					</div>
					<div *ngIf="section.typ_id === 3" class="p-col-12 p-md-6 p-lg-3 p-my-2">
						<h6 class="p-my-2">
							Leerungen 2-Rad
						</h6>
						<p-inputNumber [(ngModel)]="section.anzahl_2rad" [showButtons]="true" [min]="0" mode="decimal"
							[style]="{'width':'100%'}" locale="de-DE"></p-inputNumber>
					</div>
					<div *ngIf="section.typ_id === 3" class="p-col-12 p-md-6 p-lg-3 p-my-2">
						<h6 class="p-my-2">
							Leerungen 4-Rad
						</h6>
						<p-inputNumber [(ngModel)]="section.anzahl_4rad" [showButtons]="true" [min]="0" mode="decimal"
							[style]="{'width':'100%'}" locale="de-DE"></p-inputNumber>
					</div>
					<div *ngIf="section.typ_id === 9" class="p-col-12 p-md-6 p-lg-3 p-my-2">
						<h6 class="p-my-2">
							Entleerungsort
						</h6>
						<p-dropdown class="sectionheader" [options]="options['standort_id']"
							[(ngModel)]="section['poi_id']" placeholder="-" [showClear]="false" [filter]="true"
							filterBy="label" appendTo="body" dropdownDirective>
						</p-dropdown>
					</div>
					<div *ngIf="section.typ_id === 9" class="p-col-12 p-md-6 p-lg-3 p-my-2">
						<h6 class="p-my-2">
							Wiegeschein
						</h6>
						<input type="text" pInputText [value]="section.wiegeschein"
							(input)="section.wiegeschein=$event.target.value">
					</div>
					<div *ngIf="section.typ_id === 9" class="p-col-12 p-md-6 p-lg-3 p-my-2">
						<h6 class="p-my-2">
							Tonnage
						</h6>
						<p-inputNumber [(ngModel)]="section.netto" [showButtons]="true" [min]="0" mode="decimal"
							[style]="{'width':'100%'}" locale="de-DE"></p-inputNumber>
					</div>
				</div>
			</div>
		</div>
		<button type="button" pButton pRipple icon="fa fa-plus" class="p-button-success"
			pTooltip="{{'BUTTONS.ADD' | translate }}" tooltipPosition="bottom"
			(click)="addSection(sections.length)"></button>

		<!-- Confirm -->
		<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
	</div>
</div>