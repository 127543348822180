import { Component } from '@angular/core';
import { SettingsService } from '@app/services/settings.service';
import { ZPLPrintService } from '@app/services/zplprint.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';

@Component({
	templateUrl: './home.component.html',
	styleUrls: ['./style.scss']
})
export class HomeComponent {
	height: number = 400;
	innerContent;

	constructor(
		private breadcrumbService: BreadcrumbService,
		public settingsService: SettingsService,
		private zplPrinter: ZPLPrintService
	) {
		this.breadcrumbService.setItems([
			{ label: 'MENU.HOME', routerLink: ['/' + PATH.HOME] }
		]);
	}

	ngOnInit() {
		this.settingsService.footerVisibilityChange.subscribe(value => {
			this.adjustHewight();
		});

		this.adjustHewight();
	}

	adjustHewight() {
		this.height = 0;
		setTimeout(() => {
			this.innerContent = document.getElementById('innerContent');
		}, 0);
		setTimeout(() => {
			this.height = this.innerContent.scrollHeight;
		}, 0);
	}

	//printZPL() {
	//	const zplString = `^XA
	//	^MUD
	//	^LH0,0
	//	^FO508,1032^FB280,1,0,L,0^A0I,132^FDRest^FS (Rest)
	//	^FO48,1356^FB280,1,0,L,0^A0N,132^FDRest^FS (Rest)
	//	^FO52,1036^FB504,3,0,R,0^A0I,48^FDKreisstadt\&Eschwege^FS (Abfallwirtschaft...)
	//	^FO280,1340^FB504,3,0,R,0^A0N,48^FDKreisstadt\&Eschwege^FS (Abfallwirtschaft...)
	//	^ISR:rest.grf,N
	//	^XZ
	//	
	//	^XA
	//	^MUD
	//	^LH0,0
	//	^FO508,1032^FB280,1,0,L,0^A0I,132^FDBio^FS (Bio)
	//	^FO48,1356^FB280,1,0,L,0^A0N,132^FDBio^FS (Bio)
	//	^FO52,1036^FB504,3,0,R,0^A0I,48^FDKreisstadt\&Eschwege^FS (Abfallwirtschaft...)
	//	^FO280,1340^FB504,3,0,R,0^A0N,48^FDKreisstadt\&Eschwege^FS (Abfallwirtschaft...)
	//	^ISR:bio.grf,N
	//	^XZ
	//	
	//	^XA
	//	^MUD
	//	^LH0,0
	//	^FO508,1032^FB280,1,0,L,0^A0I,132^FDPPK^FS (Papier)
	//	^FO48,1356^FB280,1,0,L,0^A0N,132^FDPPK^FS (Papier)
	//	^FO52,1036^FB504,3,0,R,0^A0I,48^FDKreisstadt\&Eschwege^FS (Abfallwirtschaft...)
	//	^FO280,1340^FB504,3,0,R,0^A0N,48^FDKreisstadt\&Eschwege^FS (Abfallwirtschaft...)
	//	^ISR:papier.grf,N
	//	^XZ
	//	
	//	^XA
	//	^DFR:ESCHWEGE.GRF
	//	^MUD
	//	^FO18,113^FB776,1,0,C,0^A0I,48^FN1^FS
	//	^FO18,185^FB776,1,0,C,0^A0I,48^FN2^FS
	//	^FO18,281^FB776,1,0,C,0^A0I,48^FN3^FS
	//	^FO18,351^FB776,1,0,C,0^A0I,58^FN4^FS
	//	^FO150,425^BY6,^BCI,560,N,N,N,N^FN5^FS
	//	^FO140,991^FB300,1,0,R,0^A0I,24^FN6^FS
	//	^FO399,1515^FB300,1,0,R,0^A0N,24^FN11^FS
	//	^FO150,1540^BY6,^BCN,560,N,N,N,N^FN12^FS
	//	^FO28,2121^FB776,1,0,C,0^A0N,58^FN13^FS
	//	^FO28,2201^FB776,1,0,C,0^A0N,48^FN14^FS
	//	^FO28,2297^FB776,1,0,C,0^A0N,48^FN15^FS
	//	^FO28,2369^FB776,1,0,C,0^A0N,48^FN16^FS
	//	^XZ
	//	
	//	^XA
	//	^XFR:ESCHWEGE.GRF
	//	^ILR:bio.grf^FS
	//	^FN1^FH\^FD^FS
	//	^FN2^FH\^FDBaubetriebshof^FS
	//	^FN3^FH\^FDBioabfallbehaelter 1100 Liter^FS
	//	^FN4^FD44000000^FS
	//	^FN5^FD>;4400000011^FS
	//	^FN12^FD>;4400000011^FS
	//	^FN13^FD44000000^FS
	//	^FN14^FH\^FDBioabfallbehaelter 1100 Liter^FS
	//	^FN15^FH\^FDBaubetriebshof^FS
	//	^FN16^FH\^FD^FS
	//	^XZ
	//	
	//	`;
	//	this.zplPrinter.print(zplString);
	//}
}
