import { Component } from '@angular/core';
import { FilterDialogComponent } from '@app/common/templates/filter_dialog/filter_dialog.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { DateService } from '@app/services/date.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	templateUrl: '../../../../../common/templates/filter_dialog/filter_dialog.component.html',
	styleUrls: ['../../style.scss', '../../../../../common/templates/filter_dialog/style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class VehicleAddProcessScheduleDialogComponent extends FilterDialogComponent {
	constructor(
		public accountService: AccountService,
		public crudService: CRUDService,
		public config: DynamicDialogConfig,
		public dateService: DateService,
		public messageService: MessageService,
		public ref: DynamicDialogRef,
		public translate: TranslateService
	) {
		super(accountService, crudService, config, dateService, messageService, ref, translate);

		const optionGroup1 = new Map();
		optionGroup1.set('mitarbeiter_id', { type: 'dropdown', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblMitarbeiter', values: [] });
		optionGroup1.set('angelegt', { type: 'date' });
		optionGroup1.set('geplant', { type: 'date' });
		optionGroup1.set('bemerkungen', { type: 'text' });

		if( this.config.data && this.config.data['buttonLabel'] )
			this.setCommitButtonText(this.config.data['buttonLabel']);

		this.selections['angelegt'] = new Date();
		this.selections['geplant'] = new Date();

		this.optionGroups = [optionGroup1];
	}
}
