import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Globals } from '@common/global_variables';
import { DeviceDetectorService } from 'ngx-device-detector';

declare global {
	interface Window {
		RTCPeerConnection: RTCPeerConnection;
		mozRTCPeerConnection: RTCPeerConnection;
		webkitRTCPeerConnection: RTCPeerConnection;
	}
}

@Injectable()
export class NetService {
	deviceInfo = null;
	mobile: boolean = false;

	constructor(
		private httpClient: HttpClient,
		private globals: Globals,
		private zone: NgZone,
		private deviceService: DeviceDetectorService
	) { }

	// https://developer.mozilla.org/es/docs/Web/API/RTCPeerConnection
	// https://stackoverflow.com/questions/20194722/can-you-get-a-users-local-lan-ip-address-via-javascript
	// => liefert mittlerweile nicht mehr die ip zur�ck, aber eine ID die f�r die Browsersession erstmal eindeutig erscheint
	updateGlobals_IP(): void {
		let ips = [];

		this.globals.log_IP = window.location.origin;

		const pc = new RTCPeerConnection({ iceServers: [] });
		pc.createDataChannel('');
		pc.createOffer().then(pc.setLocalDescription.bind(pc));

		pc.onicecandidate = (ice) => {
			this.zone.run(() => {
				if (!ice || !ice.candidate || !ice.candidate.candidate) {
					return;
				}

				let parts = ice.candidate.candidate.split(' ');
				let [base, componentId, protocol, priority, ip, port, , type, ...attr] = parts;
				let component = ['rtp', 'rtpc'];

				if (!ips.some(e => e == ip))
					ips.push(ip);

				this.globals.log_IP = ip;

				pc.onicecandidate = () => { };
				pc.close();
			});
		};
	}

	// https://www.eduforbetterment.com/how-to-get-client-ip-address-in-angular-apps/
	updateGlobals_WANIP(): void {
		// http://api.ipify.org/?format=json
		// https://ipecho.net/plain
		this.httpClient.get("https://ipecho.net/plain").subscribe((res: any) => {
			this.globals.log_WANIP = res;//.ip;
		});
	}

	updateGlobals_PlatformInfo(): void {
		this.deviceInfo = this.deviceService.getDeviceInfo();
		const platform = {
			"OS": this.deviceInfo.os_version,
			"Browser": this.deviceInfo.browser,
			"Version": this.deviceInfo.browser_version,
			"Desktop": this.deviceService.isDesktop() ? "yes" : "no",
			"Mobile": this.deviceService.isMobile() ? "yes" : "no",
			"Tablet": this.deviceService.isTablet() ? "yes" : "no"
		};
		this.globals.log_Platform = JSON.stringify(platform);
	}
}