import { Component } from '@angular/core';
import { FilterDialogComponent } from '@app/common/templates/filter_dialog/filter_dialog.component';
import { Timespans } from '@app/models/date';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { DateService } from '@app/services/date.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	templateUrl: '../../../../../common/templates/filter_dialog/filter_dialog.component.html',
	styleUrls: ['../../style.scss', '../../../../../common/templates/filter_dialog/style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class VehicleCheckProcessTaskDialogComponent extends FilterDialogComponent {
	entryData: any = null;
	constructor(
		public accountService: AccountService,
		public crudService: CRUDService,
		public config: DynamicDialogConfig,
		public dateService: DateService,
		public messageService: MessageService,
		public ref: DynamicDialogRef,
		public translate: TranslateService
	) {
		super(accountService, crudService, config, dateService, messageService, ref, translate);

		const optionGroup1 = new Map();
		optionGroup1.set('mitarbeiter_id', { type: 'dropdown', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblMitarbeiter', values: [] });
		optionGroup1.set('geoeffnet', { type: 'date' });
		optionGroup1.set('geschlossen', { type: 'date' });
		optionGroup1.set('bemerkungen', { type: 'text' });

		if( this.config.data && this.config.data['buttonLabel'] )
			this.setCommitButtonText(this.config.data['buttonLabel']);

		if( this.config.data && this.config.data['entryData'])
			this.entryData = this.config.data['entryData'];

		this.optionGroups = [optionGroup1];
	}

	// override
	ngOnInit() {
		this.loading += 1;
		if (this.isPermitted()) {
			this.loadOptions();

			//datarowTask['abgezeichnet_mitarbeiter_id'] = mitarbeiter_id;
			//datarowTask['geoeffnet_datetime'] = von;
			//datarowTask['geschlossen_datetime'] = bis;
			//datarowTask['bemerkungen'] = bemerkungen;
		}
		this.loading -= 1;
	}

	// override
	loadOptions() {
		this.optionGroups.forEach(options => {
			options.forEach((option, key) => {
				if (option['type'] == 'timespan') {
					this.loading += 1;
					this.translate.get('init').subscribe((text: string) => {
						Object.values(Timespans).filter((v) => isNaN(Number(v))).forEach((key, index) => {
							option['values'].push(
								{ label: this.translate.instant('TIMESPANS.' + key), value: index }
							);
						});
					});
					this.loading -= 1;
				} else if (option['type'] == 'dropdown' || option['type'] == 'multiselect') {
					if( option['apiUrl'] ) {
						let strApiUrl: string = option['apiUrl'];
						let strBase = 'manualdata';
						let lengthBase = strBase.length;
						if( strApiUrl.toLowerCase().startsWith(strBase) ) {
							if( strApiUrl.toLowerCase().startsWith(strBase) && strApiUrl.length > lengthBase ) {
								let index = parseInt(strApiUrl.substring(lengthBase));
								if( index && index >= 1 && index <= 10 ) {
									option['values'] = this.manualData[index-1]; // siehe constructor, input option['apiUrl'] = 'manualData1' bis 'manualData10'
								}
							}
						} else {
							this.selections[key] = null;
							this.loading += 1;
							this.crudService.getAllEntries(option['apiUrl']).then(res => {
								option['values'] = res;
								if( this.entryData && this.entryData['abgezeichnet_mitarbeiter_id']) {
									if( key === 'mitarbeiter_id' )
									this.selections[key] = this.entryData['abgezeichnet_mitarbeiter_id'];
								}
							}).catch(err => {
								err.error.forEach(e => {
									this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
								})
							}).finally(() => {
								this.loading -= 1;
							});
						}
					}
				} else {
					this.selections[key] = null;
					if( this.entryData ) {
						if( key === 'geoeffnet' ) {
							if( this.entryData['geoeffnet_datetime'] ) {
								this.selections[key] = new Date(this.entryData['geoeffnet_datetime']);
							}
						} else if( key === 'geschlossen' ) {
							if( this.entryData['geschlossen_datetime'] ) {
								this.selections[key] = new Date(this.entryData['geschlossen_datetime']);
							} else this.selections[key] = new Date(); // now
						} else if( key === 'bemerkungen' ) {
							this.selections[key] = this.entryData['bemerkungen'];
						}
					}
				}
			});
		});
	}
}
