import { Component, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { CreateComponent } from '@app/common/templates/create/create.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TblKomponenteTypCreateComponent } from '@app/views/stammdaten/tbl_komponente_typ/create/create.component';

@Component({
	//templateUrl: '../../../../common/templates/create/create.component.html',
	//styleUrls: ['../style.scss', '../../../../common/templates/create/style.scss'],
	templateUrl: '../../../../../common/templates/create/create.component.html',
	styleUrls: ['../../style.scss', '../../../../../common/templates/create/style.scss'],
	providers: [DialogService, MessageService, ConfirmationService]
})
export class VehicleAddComponentDialogComponent extends CreateComponent {
	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		@Optional() public config: DynamicDialogConfig,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public dialogService: DialogService,
		public globals: Globals,
		public messageService: MessageService,
		@Optional() public ref: DynamicDialogRef,
		public router: Router,
		public translate: TranslateService,
	) {
		super(accountService, breadcrumbService, config, confirmationService, crudService, dialogService, globals, messageService, ref, router, translate);

		this.apiUrl = 'TblKomponente';
		this.name = 'MENU.KOMPONENTE';
		this.url = '/' + PATH.KOMPONENTE;
		this.fieldgroups.push(
			[
				//{ type: 'multiselect', key: 'bezeichnung', required: true, width: 400 },
				{ type: 'dropdown', key: 'bezeichnung', label: 'bezeichnung', required: false },
				{ type: 'text', key: 'bezeichnung_kurz', required: false, width: 400 },
				{ type: 'text', key: 'serial_no', required: false, width: 400 },
				{ type: 'text', key: 'lizenzkey_qt5', required: false, width: 400 },
				{ type: 'text', key: 'lizenzkey_lic_ki_lib', required: false, width: 400 },
				{ type: 'text', key: 'lizenzkey_visy_software', required: false, width: 400 },
				{ type: 'text', key: 'lizenzkey_sywatec_software', required: false, width: 400 },
				{ type: 'object', key: 'komponente_typ_id', label: 'komponente_typ', required: false, width: 400, apiUrl: 'TblKomponenteTyp', createComponent: TblKomponenteTypCreateComponent },
				{ type: 'text', key: 'bemerkungen', required: false, width: 400 },
				{ type: 'numeric', key: 'feature_flags', required: false, width: 400 },
				{ type: 'date', key: 'COMPONENT_LINK_FROM', required: false, width: 400, dateonly: true },
				{ type: 'date', key: 'COMPONENT_LINK_TO', required: false, width: 400, dateonly: true },
				{ type: 'text', key: 'COMPONENT_LINK_COMMENTS', required: false, width: 400},
			]
		);
		this.breadcrumbService.setItems([
			{ label: 'MENU.STAMMDATEN' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.CREATE', routerLink: [this.url + '/' + PATH.CREATE] }
		]);
	}

	getOptions(): void {
		this.fieldgroups.forEach(fg => {
			fg.forEach(field => {
				if (field.type == 'object' || field.type == 'list') {
					this.loading += 1;
					this.crudService.getAllEntries(field.apiUrl).then(res => {
						this.options[field.key] = res.map(option => ({ label: option.bezeichnung, value: option.ds_this_id, object: option }));
						field.optionSortFieldOptions = [
							{ label: this.translate.instant('HEADERS.lfd_nummer'), value: 'lfd_nummer' },
							{ label: this.translate.instant('HEADERS.ankey'), value: 'ankey' },
							{ label: this.translate.instant('HEADERS.Bezeichnung'), value: 'bezeichnung' }
						];
						//field.optionSortFieldOptions = Object.keys(res[0]).map(key => ({ label: this.translate.instant('HEADERS.' + key), value: key }));
						//field.optionSortFieldOptions = field.optionSortFieldOptions.filter(option => !option.label.includes('HEADERS.'));
						this.changeSorting(field, true);
					}).catch(err => {
						err.error.forEach(e => {
							this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
						})
					}).finally(() => {
						this.loading -= 1;
					});
				}
			});
		});

		this.options.bezeichnung = [];
		this.loading += 1;
		this.crudService.getVehicleComponentDefaultNames().then(res => {
			if(res && res.length > 0) {
				const o = [];
				res.forEach(dataEntry => {
					o.push({
						label: dataEntry,
						value: dataEntry
					});
				});
				this.options.bezeichnung = o;
			}
		}).catch(() => {
			// no exception expected...
		}).finally(()=> {
			this.loading -= 1;
		})
	}

	/*
	 * override
	 */
	createEntry(): void {
		if (this.checkRequiredFields()) {
			this.loading += 1;
			this.fieldgroups.forEach(fg => {
				fg.forEach(field => {
					if (field.type == 'list') {
						let listString = '';
						if (this.entry[field.key]) {
							this.entry[field.key].forEach(element => {
								listString += element + ',';
							});
						}
						this.entry[field.key] = listString.slice(0, -1);
					}
				});
			});
			this.crudService.createEntry(this.apiUrl, this.entry).then(res => {
				if (this.ref) {
					//this.ref.close(true); // => Unterschied zur superclass: hier neues Objekt und Link-Komment sowie Link-Zeitfelder rausgeben
					let strComment: string = null;
					let dtFrom: Date = null;
					let dtTo: Date = null;
					this.fieldgroups.forEach(fg => {
						fg.forEach(field => {
							//{ type: 'date', key: 'COMPONENT_LINK_FROM', required: false, width: 400, dateonly: true },
							//{ type: 'date', key: 'COMPONENT_LINK_TO', required: false, width: 400, dateonly: true },
							//{ type: 'text', key: 'COMPONENT_LINK_COMMENTS', required: false, width: 400},
							if (field.key === 'COMPONENT_LINK_FROM') {
								//console.log(field);
								//console.log(this.entry[field.key]);
								dtFrom = new Date(this.entry[field.key]);
							} else if (field.key === 'COMPONENT_LINK_TO') {
								//console.log(field);
								//console.log(this.entry[field.key]);
								dtTo = new Date(this.entry[field.key]);
							} else if (field.key === 'COMPONENT_LINK_COMMENTS') {
								//console.log(field);
								//console.log(this.entry[field.key]);
								strComment = this.entry[field.key];
							}
						})
					});
					this.ref.close({componentId: res, dateFrom: dtFrom, dateTo: dtTo, comment: strComment});
				} else {
					if (res) {
						this.router.navigate([this.url + '/' + PATH.DETAIL + '/' + res]);
					} else {
						this.router.navigate([this.url + '/' + PATH.LIST]);
					}
				}
				this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
			}).catch(err => {
				err.error.forEach(e => {
					this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
				})
			}).finally(() => {
				this.loading -= 1;
			});
		}
	}
}
