import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { EditComponent } from '@app/common/templates/edit/edit.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TblFahrzeugAbwicklungAbschnittTypCreateComponent } from '../../tbl_fahrzeug_abwicklung_abschnitt_typ/create/create.component';
import { TblMitarbeiterCreateComponent } from '../../tbl_mitarbeiter/create/create.component';

@Component({
	templateUrl: '../../../../common/templates/edit/edit.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/edit/style.scss'],
	providers: [DialogService, MessageService, ConfirmationService]
})
export class TblFahrzeugAbwicklungAbschnittEditComponent extends EditComponent {
	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public dialogService: DialogService,
		public globals: Globals,
		public messageService: MessageService,
		public router: Router,
		public translate: TranslateService,
	) {
		super(accountService, breadcrumbService, confirmationService, crudService, dialogService, globals, messageService, router, translate);

		this.apiUrl = 'TblFahrzeugAbwicklungAbschnitt';
		this.name = 'MENU.FAHRZEUG_ABWICKLUNG_ABSCHNITT';
		this.url = '/' + PATH.FAHRZEUG_ABWICKLUNG_ABSCHNITT;
		this.fieldgroups.push(
			[
				//{ type: 'text', key: 'ankey', required: true, width: 400 },
				{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
				{ type: 'text', key: 'bezeichnung_kurz', required: false, width: 400 },
				{ type: 'date', key: 'geoeffnet_datetime', required: false, width: 400, dateonly: true },
				{ type: 'date', key: 'geschlossen_datetime', required: false, width: 400, dateonly: true },
				{ type: 'object', key: 'abgezeichnet_mitarbeiter_id', label: 'abgezeichnet_mitarbeiter', required: false, width: 400, apiUrl: 'TblMitarbeiter', createComponent: TblMitarbeiterCreateComponent },
				{ type: 'object', key: 'abschnitt_typ_id', label: 'abschnitt_typ', required: false, width: 400, apiUrl: 'TblFahrzeugAbwicklungAbschnittTyp', createComponent: TblFahrzeugAbwicklungAbschnittTypCreateComponent },
				{ type: 'text', key: 'bemerkungen', required: false, width: 400 },
			]
		);
		this.breadcrumbService.setItems([
			{ label: 'MENU.STAMMDATEN' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.EDIT', routerLink: [this.url + '/' + PATH.EDIT + '/' + this.id] }
		]);
	}
}
