import { formatDate } from '@angular/common';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { Collection } from '@app/models/collection';
import { Module, Operation } from '@app/models/permission';
import { tbl_fahrzeug } from '@app/models/tbl_fahrzeug';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { ExportService } from '@app/services/export.service';
import { MapService } from '@app/services/map.service';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { environment } from '@environments/environment';
import * as signalR from '@microsoft/signalr';
import { IHttpConnectionOptions } from '@microsoft/signalr';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from "@services/log.service";
import 'chartjs-adapter-moment';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import { defaults as defaultControls, FullScreen, ScaleLine, ZoomSlider } from 'ol/control';
import { LineString, Point, Polygon } from 'ol/geom';
import { Feature, Map, View } from 'ol/index';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { useGeographic } from 'ol/proj';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { MultiSelect } from 'primeng/multiselect';
import { Table } from 'primeng/table';
import { VehicleSelection } from './vehicle_selection/vehicle_selection.component';
import { waitForAsync } from '@angular/core/testing';

@Component({
	templateUrl: './map.component.html',
	styleUrls: ['./style.scss'],
	providers: [DialogService, MessageService, ConfirmationService]
})
export class OnlineKarteComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
	apiUrl: string;
	contentHeight: number;
	hubConnections = [];
	loading: number = 0;
	module: Module;
	name: string;
	shownElements: string[] = [];
	url: string;

	timelineMinMaxByData: boolean = true;
	private readonly timelineLimitationModeKey: string = 'timelineLimitationMode';
	private readonly timelineLimitationMode_byFilter: string = '1';
	private readonly timelineLimitationMode_byData: string = '2';

	// Collections
	@ViewChild('collectionColSelection') collectionColSelection: MultiSelect;
	@ViewChild('collectionTable') collectionTable: Table;
	collectionButtonColWidth: number = 130;
	collectionCols: any[];
	collectionCount: number = 0;
	collectionFilters: string[];
	collectionGlobalFilter: string = '';
	collectionIsTableInit: boolean = false;
	collectionPossibleCols: any[];
	collections: Collection[];
	collectionSelection: Collection;
	collectionStateName: string;
	collectionState: any;

	// Vehicle
	@ViewChild('vehicleColSelection') vehicleColSelection: MultiSelect;
	@ViewChild('vehicleTable') vehicleTable: Table;
	onlineStatusWidth: number = 120;
	vehicleButtonColWidth: number = 210;
	vehicleCols: any[];
	vehicleCount: number = 0;
	vehicleFilters: string[];
	vehicleGlobalFilter: string = '';
	vehicleIsTableInit: boolean = false;
	vehiclePossibleCols: any[];
	vehicles: tbl_fahrzeug[];
	vehicleSelection: number;
	vehicleStateName: string;
	vehicleState: any;

	// Map
	areaLayer: VectorLayer;
	areaStyle: Style;
	areas;
	areaBorderLayer: VectorLayer;
	areaBorderStyle: Style;
	centeringMapOnVehicle = '';
	center = [10.447683, 51.163361];
	colors = [];
	defaultLiveRouteColor: string;
	defaultOldRouteColor: string;
	defaultShowRoutes: boolean;
	lastArrow = [];
	map: Map;
	mapLayer: TileLayer;
	routeColors = {};
	selectedFeature: any;
	selectionLayer: VectorLayer;
	showAreas = false;
	tooltipText: string = '';
	vehiclesLayer: VectorLayer;
	visibleCollections = [];
	visibleRoutes = [];
	zoom = 6.5;

	// Timeline
	timelineVehicle;
	timelineAccuracy = 100; // x Prozent der vorhanden Punkte anzeigen
	timelineData;
	timelineOptions;

	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public changeDetectorRef: ChangeDetectorRef,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public dialogService: DialogService,
		public elRef: ElementRef,
		public exportService: ExportService,
		public globals: Globals,
		public mapService: MapService,
		public messageService: MessageService,
		public router: Router,
		public settingsService: SettingsService,
		public translate: TranslateService,
		private logService: LogService
	) {
		this.apiUrl = 'TblOnline';
		this.name = 'MENU.KARTE';
		this.url = '/' + PATH.ONLINE_FAHRZEUGE;
		this.module = Module.OnlineVehicles;

		this.breadcrumbService.setItems([
			{ label: 'MENU.ONLINE' },
			{ label: this.name, routerLink: [this.url] }
		]);
	}

	// #region Angular
	ngOnInit() {
		this.loading += 1;

		this.loading += 1;
		this.mapService.init().then(res => {
			this.loading -= 1;
		});

		this.shownElements = [
			'vehicleContainer',
			'mapContainer',
			'collectionsContainer',
			'timelineContainer'
		];
		this.showAreas = localStorage.getItem('defaultShowAreas') === 'true';

		// VehicleTable
		this.vehicleStateName = 'state' + this.apiUrl + 'Fahrzeug' + 'List';
		this.vehiclePossibleCols = [
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'text', key: 'bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'kennzeichen', required: false, width: 400 },
			{ type: 'text', key: 'logbox_serial', required: false, width: 400 },
			{ type: 'date', key: 'LastUpdate', required: false, width: 400 },
			{ type: 'date', key: 'fromDate', required: false, width: 400 },
			{ type: 'date', key: 'toDate', required: false, width: 400 },
			{ type: 'text', key: 'LeerungenCount', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_firma_bezeichnung', required: false, width: 400 },
		];
		this.vehicleCols = [
			{ type: 'text', key: 'kennzeichen', required: false, width: 400 },
			{ type: 'text', key: 'logbox_serial', required: false, width: 400 },
			{ type: 'date', key: 'LastUpdate', required: false, width: 400 },
			{ type: 'date', key: 'fromDate', required: false, width: 400 },
			{ type: 'date', key: 'toDate', required: false, width: 400 },
			{ type: 'text', key: 'LeerungenCount', required: false, width: 400 },
		];
		this.retrieveVehicleTableState(this.vehicleState);
		if (this.vehicleState && this.vehicleState.columnOrder) {
			this.vehicleCols = [];
			this.vehicleState['columnOrder'].forEach(col => {
				this.vehiclePossibleCols.forEach(c => {
					if (col == c.key) {
						this.vehicleCols.push(c);
					}
				});
			});
		}
		if (this.vehicleState && this.vehicleState.filters && this.vehicleState.filters.global) {
			this.vehicleGlobalFilter = this.vehicleState.filters.global.value;
		}
		this.vehicleFilters = this.vehicleCols.map(c => c.key);

		// CollectionTable
		this.collectionStateName = 'state' + this.apiUrl + 'Leerung' + 'List';
		this.collectionPossibleCols = [
			{ type: 'numeric', key: 'autoid', required: false, width: 400 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'date', key: 'datetime', required: false, width: 400 },
			{ type: 'text', key: 'Identcode', required: false, width: 400 },
			{ type: 'text', key: 'WasteType', required: false, width: 400 },
		];
		this.collectionCols = [
			{ type: 'date', key: 'datetime', required: false, width: 400 },
			{ type: 'text', key: 'Identcode', required: false, width: 400 },
			{ type: 'text', key: 'WasteType', required: false, width: 400 },
		];
		this.retrieveCollectionTableState(this.collectionState);
		if (this.collectionState && this.collectionState.columnOrder) {
			this.collectionCols = [];
			this.collectionState['columnOrder'].forEach(col => {
				this.collectionPossibleCols.forEach(c => {
					if (col == c.key) {
						this.collectionCols.push(c);
					}
				});
			});
		}
		if (this.collectionState && this.collectionState.filters && this.collectionState.filters.global) {
			this.collectionGlobalFilter = this.collectionState.filters.global.value;
		}
		this.collectionFilters = this.collectionCols.map(c => c.key);
		this.timelineOptions = {
			plugins: {
				legend: {
					display: false
				},
				tooltip: {
					mode: "index",
					intersect: false,
					callbacks: {
						title: function (tooltipItems) {
							return (new Date(tooltipItems[0].label)).toLocaleTimeString();
						}
					}
				},
			},
			animation: false,
			hover: {
				mode: "nearest",
				intersect: true
			},
			responsive: true,
			scales: {
				x: {
					type: "time",
					time: {
						unit: "minute",
						displayFormats: { minute: "HH:mm" }
					},
					display: true,
					ticks: {
						fontColor: (localStorage.getItem('darkMode') === 'true') ? '#ffffff' : '#000000'
					},
					gridLines: {
						color: (localStorage.getItem('darkMode') === 'true') ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)'
					}
				},
				y: {
					display: true,
					ticks: {
						fontColor: (localStorage.getItem('darkMode') === 'true') ? '#ffffff' : '#000000'
					},
					gridLines: {
						color: (localStorage.getItem('darkMode') === 'true') ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)'
					}
				}
			}
		};

		if (this.accountService.checkPermissions(Module.Masterdata, Operation.READ)) {
			this.loading += 1;
			this.crudService.getAllAreas().then(res => {
				this.areas = res;
				this.loadAreasToMap();
				this.showHideAreas(this.showAreas);
			}).catch(err => {
				err.error.forEach(e => {
					if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
					}
				})
			}).finally(() => {
				this.loading -= 1;
			});
		}

		this.defaultLiveRouteColor = localStorage.getItem('defaultLiveRouteColor') ? localStorage.getItem('defaultLiveRouteColor') : '#00FF00';
		this.defaultOldRouteColor = localStorage.getItem('defaultOldRouteColor') ? localStorage.getItem('defaultOldRouteColor') : '#FF0000';
		this.defaultShowRoutes = (localStorage.getItem('defaultShowRoutes') === 'true');

		this.colors = ['#006400', '#FF8C00', '#483D8B', '#DAA520', '#E9967A', '#000080', '#DDA0DD', '#00FF7F', '#CD853F', '#00FFFF', '#FFFF00', '#AA0000'];

		this.translate.get('init').subscribe((text: string) => {
			this.vehiclePossibleCols.forEach(c => {
				c.label = this.translate.instant('HEADERS.' + c.key);
			});
			this.collectionPossibleCols.forEach(c => {
				c.label = this.translate.instant('HEADERS.' + c.key);
			});
		});

		this.restoreTimelineLimitationMode();
		this.loading -= 1;

		// log view
		setTimeout(() => this.logService.log("Information", 1, "ReversingCadastral", "Online.Map::Open"), 5000);
	}

	ngAfterViewInit() {
		this.settingsService.footerVisibilityChange.subscribe(value => {
			this.initLayout();
		});

		if (this.vehicleTable.filters) {
			let restoredFilter = false;
			this.vehicleFilters.forEach(col => {
				Object.keys(this.vehicleTable.filters[col]).forEach(filter => {
					if (this.vehicleTable.filters[col][filter]['value'] != null) {
						restoredFilter = true;
					}
				})
			});
			if (restoredFilter) {
				this.messageService.add({ key: 'reset', severity: 'warn', summary: this.translate.instant('MESSAGES.WARNING'), detail: this.translate.instant('MESSAGES.LOADED_FILTER'), life: 10000 });
			}
		}

		if (this.collectionTable.filters) {
			let restoredFilter = false;
			this.collectionFilters.forEach(col => {
				Object.keys(this.collectionTable.filters[col]).forEach(filter => {
					if (this.collectionTable.filters[col][filter]['value'] != null) {
						restoredFilter = true;
					}
				})
			});
			if (restoredFilter) {
				this.messageService.add({ key: 'reset', severity: 'warn', summary: this.translate.instant('MESSAGES.WARNING'), detail: this.translate.instant('MESSAGES.LOADED_FILTER'), life: 10000 });
			}
		}

		this.initMap();
		this.shownElements = [
			'vehicleContainer',
			'mapContainer'
		];

		if (localStorage.getItem('defaultShowTimeline') === null || localStorage.getItem('defaultShowTimeline') === 'true') {
			this.shownElements.push('timelineContainer');
		}
		if (localStorage.getItem('defaultShowCollections') === null || localStorage.getItem('defaultShowCollections') === 'true') {
			this.shownElements.push('collectionsContainer');
		}
		if (localStorage.getItem('timelineAccuracy') !== null) {
			this.timelineAccuracy = +localStorage.getItem('timelineAccuracy');
		}

		const elements = document.querySelectorAll<HTMLElement>('.cdk-virtual-scroll-viewport');
		elements.forEach(el => {
			this.changeWheelSpeed(el, 0.9);
		});

		this.initLayout();
		this.initTable();
	}

	ngAfterViewChecked() {
		if (!this.vehicleIsTableInit && this.vehicleTable.value) {
			this.vehicleIsTableInit = true;
			setTimeout(() => {
				this.resizeTablesWidths();
			}, 0);
		}
	}

	ngOnDestroy() {
		this.disconnectAllVehiclesFromDatahub();
	}

	// #endregion Angular

	// #region Layout

	initLayout() {
		setTimeout(() => {
			this.contentHeight = 0;
		}, 0);
		setTimeout(() => {
			const el = document.getElementById('innerContent');
			this.contentHeight = el.offsetHeight - 28;
		}, 0);
		setTimeout(() => {
			this.map.updateSize();
		}, 0);
	}

	initTable() {
		setTimeout(() => {
			this.resizeTablesWidths();
		}, 0);

		// resetScrollTop überschreiben, damit bei Data Update die Liste nicht immer nach oben scrollt
		if (this.collectionTable != null) {
			this.collectionTable.resetScrollTop = function () { }
		}
		if (this.vehicleTable != null) {
			this.vehicleTable.resetScrollTop = function () { }
		}
	}

	/**
	 * Ist das Modul ein- oder ausgeblendet
	 */
	isShownElement(element: string): boolean {
		return this.shownElements.indexOf(element) != -1;
	}

	resizeTablesWidths(state?, statename?) {
		this.loading += 1;

		if (state && statename) {
			if (statename == 'collection') {
				this.retrieveCollectionTableState(this.collectionState);
			} else {
				this.retrieveVehicleTableState(this.vehicleState);
			}
		}
		if (this.vehicleTable) {
			const tableElement = document.getElementById(this.vehicleTable.id);
			tableElement.style.width = '100%';
			const columnWidths = this.vehicleState && this.vehicleState.columnWidths ? this.vehicleState.columnWidths.split(',') : (this.vehicleCols.map(c => c.width)).concat([this.vehicleButtonColWidth]);
			const contentWidth = columnWidths.reduce((summe, element) => summe + Number(element), 0);
			const tableWidthOffset = tableElement.clientWidth - contentWidth;
			for (let index = 0; index < this.vehicleCols.length; index++) {
				this.vehicleCols[index].width = Number(columnWidths[index]);
			}
			if (tableWidthOffset > 0 && this.vehicleCols.length > 0) {
				this.vehicleCols[this.vehicleCols.length - 1].width += tableWidthOffset;
				if (this.contentHeight < (this.vehicleTable.filteredValue ? this.vehicleTable.filteredValue.length : (this.vehicleTable.value ? this.vehicleTable.value.length : 0)) * this.vehicleTable.virtualRowHeight) {
					this.vehicleCols[this.vehicleCols.length - 1].width -= 10;
				}
			}

			document.getElementById(this.vehicleTable.id + '-table').style.width = this.vehicleCols.reduce((summe, element) => summe + element.width, 0) + this.vehicleButtonColWidth + 'px';
			document.getElementById(this.vehicleTable.id + '-table').style.minWidth = this.vehicleCols.reduce((summe, element) => summe + element.width, 0) + this.vehicleButtonColWidth + 'px';

			setTimeout(() => {
				if (this.vehicleState) {
					localStorage.setItem(this.vehicleStateName, JSON.stringify(this.vehicleState));
				}
			}, 0);
		}

		if (this.collectionTable) {
			const tableElement = document.getElementById(this.collectionTable.id);
			tableElement.style.width = '100%';
			const columnWidths = this.collectionState && this.collectionState.columnWidths ? this.collectionState.columnWidths.split(',') : (this.collectionCols.map(c => c.width)).concat([this.collectionButtonColWidth]);
			const contentWidth = columnWidths.reduce((summe, element) => summe + Number(element), 0);
			const tableWidthOffset = tableElement.clientWidth - contentWidth;
			for (let index = 0; index < this.collectionCols.length; index++) {
				this.collectionCols[index].width = Number(columnWidths[index]);
			}
			if (tableWidthOffset > 0 && this.collectionCols.length > 0) {
				this.collectionCols[this.collectionCols.length - 1].width += tableWidthOffset;
				if (this.contentHeight < (this.collectionTable.filteredValue ? this.collectionTable.filteredValue.length : (this.collectionTable.value ? this.collectionTable.value.length : 0)) * this.collectionTable.virtualRowHeight) {
					this.collectionCols[this.collectionCols.length - 1].width -= 10;
				}
			}

			document.getElementById(this.collectionTable.id + '-table').style.width = this.collectionCols.reduce((summe, element) => summe + element.width, 0) + this.collectionButtonColWidth + 'px';
			document.getElementById(this.collectionTable.id + '-table').style.minWidth = this.collectionCols.reduce((summe, element) => summe + element.width, 0) + this.collectionButtonColWidth + 'px';

			setTimeout(() => {
				if (this.collectionState) {
					localStorage.setItem(this.collectionStateName, JSON.stringify(this.collectionState));
				}
			}, 0);
		}

		this.loading -= 1;
	}

	/**
	 * Modul ein oder ausblenden
	 */
	toggleElement(element: string) {
		const index = this.shownElements.indexOf(element);
		if (index != -1) {
			this.shownElements.splice(index, 1);
		} else {
			this.shownElements.push(element);
		}
		this.initLayout();
	}

	// #endregion Layout

	// #region Map

	calcIconScale() {
		return 1.0 / (1.0 + (this.map.getView().getMaxZoom() - this.map.getView().getZoom()) / 3);
	}

	centerMapOn(long, lat) {
		if (long != null && lat != null && (long != 0.0 || lat != 0.0) && long >= -180.0 && long <= 180.0 && lat >= -90.0 && lat <= 90.0) {
			setTimeout(() => {
				this.center = [long, lat];
				this.map.updateSize();
				this.map.getView().animate({ center: [long, lat], zoom: (this.map.getView().getZoom() < 14 ? 14 : this.map.getView().getZoom()) });
			}, 100);
		}
	}

	centerOnVehicle(vehicle) {
		if (null !== vehicle && undefined !== vehicle) {
			this.vehicleSelection = vehicle.ds_this_id;
			if (vehicle['LastGPS'] !== null && vehicle['LastGPS'] !== undefined && vehicle['LastGPS'].length >= 2) {
				this.centerMapOn(vehicle['LastGPS'][0], vehicle['LastGPS'][1]);
			}
			this.centeringMapOnVehicle = vehicle['mapId'];
			this.clearSelectedCollection();
			this.vehicleColSelection = vehicle;
			this.updateCollectionsTable(vehicle);
			this.updateTimelineData(vehicle);
			//this.vehicleTable.scrollToVirtualIndex(this.vehicleTable.value.indexOf(vehicle)); // soll nicht mehr springen, die table
			// und wenn doch wieder, dann schon so:
			//let index = this.vehicles.indexOf(vehicle);
			//if( index && index >= 0 ) {
			//	this.vehicleTable.scrollToVirtualIndex(index); // .indexOf auf das list.value funktioniert nämlich nicht zuverlässig, auf das gebundene array hingegen schon
			//}
			// aber: da das eher stört erstmal auf Markus' Wunsch ganz rausgenommen
		}
	}

	clearSelectedCollection() {
		this.selectedFeature = null;
		this.collectionSelection = null;
		this.selectionLayer.getSource().clear();
	}

	displayMapTooltip(event) {
		const pixel = event.pixel;
		document.getElementById('mapTooltip').style.display = 'none';
		this.map.forEachFeatureAtPixel(pixel, feature => {
			this.tooltipText = feature.get('name');
			document.getElementById('mapTooltip').style.display = 'inherit';
			document.getElementById('mapTooltip').style.top = (document.getElementById('onlineMap').getBoundingClientRect().top - document.body.getBoundingClientRect().top - 25 + event.pixel[1]) + 'px';
			document.getElementById('mapTooltip').style.left = (document.getElementById('onlineMap').getBoundingClientRect().left - document.body.getBoundingClientRect().left + 5 + event.pixel[0]) + 'px';
		});
	}

	exportMap() {
		this.loading += 1;
		const controls = defaultControls({ attribution: false }).extend([new FullScreen(), new ScaleLine(), new ZoomSlider()]);

		this.map.getControls().forEach(control => {
			setTimeout(() => {
				this.map.removeControl(control);
			}, 100);
		});

		setTimeout(() => {
			html2canvas(document.getElementById('onlineMap'), { scrollX: -window.scrollX, scrollY: -window.scrollY }).then(canvas => {
				saveAs(canvas.toDataURL('image/png'), 'map.png');
			});
		}, 1000);

		setTimeout(() => {
			controls.forEach(control => {
				setTimeout(() => {
					this.map.addControl(control);
				}, 100);
			});
		}, 2000);

		setTimeout(() => {
			this.loading -= 1;
		}, 3000);
	}

	hideAllCollections() {
		this.visibleCollections = [];
		this.map.getLayers().forEach(layer => {
			if (layer.get('name').split('-')[0] == 'C') {
				layer.setVisible(false);
			}
		});
	}

	hideAllRoutes() {
		this.visibleRoutes = [];
		this.map.getLayers().forEach(layer => {
			if (layer.get('name').split('-')[0] == 'R') {
				layer.setVisible(false);
			}
		});
	}

	initMap() {
		this.mapLayer = new TileLayer({
			preload: Infinity,
			source: new OSM({
				url: environment.mapUrl,
				format: 'image/png',
				crossOrigin: 'anonymous'
			}),
			name: 'onlineMap'
		});
		this.vehiclesLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			zIndex: 1000,
			name: 'V'
		});

		this.areaStyle = new Style({
			fill: new Fill({
				color: '#bbbbff'
			})
		});
		this.areaLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			zIndex: 500,
			opacity: 0.2,
			style: this.areaStyle,
			name: 'Areas'
		});
		this.areaBorderStyle = new Style({
			stroke: new Stroke({
				color: '#7777ff',
				width: 4
			})
		});
		this.areaBorderLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			opacity: 0.5,
			style: this.areaBorderStyle,
			name: 'AreaBorder'
		});
		this.selectionLayer = new VectorLayer({
			source: new VectorSource({
				features: [],
			}),
			zIndex: 2000,
			style: new Style({
				image: new Icon({
					src: 'assets/icons/circle.png'
				})
			}),
			name: 'Selection'
		});

		useGeographic();
		this.map = new Map({
			controls: defaultControls({ attribution: false }).extend([new FullScreen(), new ScaleLine(), new ZoomSlider()]),
			target: 'onlineMap',
			view: new View({
				center: this.center,
				zoom: this.zoom,
				minZoom: 5,
				maxZoom: 18,
			}),
			renderer: 'webgl',
			layers: [
				this.mapLayer,
				this.areaLayer,
				this.areaBorderLayer,
				this.vehiclesLayer,
				this.selectionLayer
			]
		});

		this.map.on('pointermove', event => {
			this.displayMapTooltip(event);
		});
		this.map.on('pointerdrag', () => {
			this.centeringMapOnVehicle = '';
		});
		this.map.on('moveend', () => {
			if (this.map.getView().getZoom() !== this.zoom) {
				this.loading += 1;
				this.zoom = this.map.getView().getZoom();
				const scale = this.calcIconScale();
				this.map.getLayers().forEach(layer => {
					if (layer.get('name').split('-')[0] == 'C' || layer.get('name') == 'Selection') {
						layer.getStyle().getImage().setScale(scale);
						layer.getSource().changed();
					}
				});
				this.loading -= 1;
			}
		});
		this.map.on('singleclick', event => {
			const pixel = event.pixel;
			if (this.selectedFeature) {
				this.selectedFeature.setStyle();
			}
			let first = true;
			this.map.forEachFeatureAtPixel(pixel, (feature, layer) => {
				if (layer.get('name') == 'V') {
					if( this.vehicles) {
						this.vehicles.forEach(v => {
							if (v['mapId'] == feature.getId()) {
								this.centerOnVehicle(v);
							}
						});
					}
				}
				else if (layer.get('name').split('-')[0] == 'C') {
					if (first) {
						first = false;
						if( this.vehicles) {
							let vhicle = layer.get('name').split('-')[1];
							if( vhicle) {
								//console.log('vhicle: ' + vhicle);
								for(let vehicle of this.vehicles) { // ersetzt forEach, denn die kann man nicht mit break beenden //this.vehicles.forEach(vehicle => {
									if( vehicle && vehicle['mapId'] ) {
										//console.log('vehicle: ' + vehicle['mapId']);
										if (vehicle['mapId'] == vhicle) {
											//console.log('=> match!');
											this.updateCollectionsTable(vehicle);
											if( this.collections ) {
												//this.collections.forEach(c => {
												for(let c of this.collections) {
													if (c['autoid'] == feature.getId()) {
														this.centeringMapOnVehicle = '';
														this.selectFeature(c['Map_CenterLongitude'], c['Map_CenterLatitude'], feature.get('name'));
														this.collectionSelection = c;
														if( this.collections && this.collections.length > 0 ) {
															let index = this.collections.indexOf(c); // es ist wichtig, den index aus der variablen zu ziehen, nicht aus table.value, denn das schlägt oftmals mit -1 fehl
															if( index && index >= 0 ) {
																//console.log('scrolling to index ' + index);
																this.collectionTable.scrollToVirtualIndex(index);
															}
															//else {
															//	if( index ) {
															//		console.log('index is ' + index);
															//	} else {
															//		console.log('index is null');
															//	}
															//}
														}
														//console.log('found matching collection, collections loop done');
														break;
													}
												};
											}
											//console.log('found matching vehicle, vehicles loop done');
											break;
										}
									}
								};
							}
						}
					}
				}
			});
		});
	}

	isNearOldArrow(mapId, coords: number[]) {
		const distance = 0.001;
		const isOutsideBoundingBox = (
			this.lastArrow[mapId][0] - distance > coords[0] ||
			this.lastArrow[mapId][0] + distance < coords[0] ||
			this.lastArrow[mapId][1] - distance > coords[1] ||
			this.lastArrow[mapId][1] + distance < coords[1]
		);
		return !isOutsideBoundingBox;
	}

	loadAreasToMap() {
		this.areas.forEach(area => {
			// Area Borders
			let oldMarker = area.Eckpunkte[area.Eckpunkte.length - 1];
			area.Eckpunkte.forEach(m => {
				const feature = new Feature({
					geometry: new LineString(
						[
							oldMarker,
							m
						]
					),
					name: area.Bezeichnung
				});
				this.areaBorderLayer.getSource().addFeature(feature);
				oldMarker = m;
			});
			// Area Polygon
			area.Eckpunkte.push(area.Eckpunkte[0]);
			const feature = new Feature({
				geometry: new Polygon([area.Eckpunkte]),
				name: area.Bezeichnung
			});
			feature.setId(area.Id);
			this.areaLayer.getSource().addFeature(feature);
			area.Eckpunkte.pop();
		});
	}

	loadOldRoute(vehicle, fromDate: Date, toDate: Date) {
		this.loading += 1;
		this.mapService.getOldGPS(vehicle.logbox_serial, fromDate, toDate).then(res => {
			vehicle['gpsData'] = vehicle['gpsData'] ? res.concat(vehicle['gpsData']) : res;
			if (this.defaultShowRoutes) {
				this.switchRouteVisbility(vehicle);
			}
			this.updateTimelineData(vehicle);
			this.updateCollectionsTable(vehicle);
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 10000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 10000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	redrawCollections(vehicle) {
		this.map.getLayers().forEach(layer => {
			if (layer.get('name').split('-')[0] == 'C' && layer.get('name').split('-')[1] == vehicle['mapId']) {
				this.map.removeLayer(layer);
			}
		});
		let collectionFeatures = [];
		for (var i = 0; i < vehicle['gpsData'].length; i++) {
			const dataset = vehicle['gpsData'][i];
			if (dataset.IsLeerung == 1) {
				let datetime = new Date(dataset['f_jdzeit_String']);
				const collectionfeature = new Feature({
					geometry: new Point([dataset.Map_CenterLongitude, dataset.Map_CenterLatitude]),
					name: datetime.toLocaleString() + (dataset.Identcode ? ' - ' + dataset.Identcode : '')
				})
				collectionfeature.setId(dataset['autoid']);
				collectionFeatures.push(collectionfeature);
			}
		}
		const iconScale = this.calcIconScale();
		const collectionLayer = new VectorLayer({
			source: new VectorSource({
				features: collectionFeatures,
			}),
			zIndex: 2000,
			style: new Style({
				image: new Icon({
					src: 'assets/icons/trash-green.png',
					scale: iconScale
				})
			}),
			name: 'C-' + vehicle['mapId']
		});
		this.map.addLayer(collectionLayer);
		this.visibleCollections.push(vehicle['mapId']);
	}

	redrawRoute(vehicle) {
		const layersToRemove = [];
		this.map.getLayers().forEach(layer => {
			if (layer.get('name').split('-')[0] == 'R' && layer.get('name').split('-')[1] == vehicle['mapId']) {
				layersToRemove.push(layer);
			}
		});
		layersToRemove.forEach(l => {
			this.map.removeLayer(l);
		});
		let routeFeatures = [];
		let arrowFeatures = [];
		let startPointSet = false;
		let dataset = null;
		for (var i = 0; i < vehicle['gpsData'].length; i++) {
			if (vehicle['gpsData'][i].Map_CenterLongitude != 0 || vehicle['gpsData'][i].Map_CenterLatitude != 0) {
				const old_dataset = dataset;
				dataset = vehicle['gpsData'][i];
				let datetime = new Date(dataset['f_jdzeit_String']);
				if (dataset) {
					if (!startPointSet) {
						// Draw Start
						const routeStartFeature = new Feature({
							geometry: new Point(
								[
									dataset.Map_CenterLongitude, dataset.Map_CenterLatitude
								]
							),
							name: vehicle.logbox_serial + ' - ' + datetime.toLocaleString()
						});
						const routeStartFeatureStyle = new Style({
							image: new Circle({
								radius: 5,
								fill: new Fill({ color: this.routeColors[vehicle['mapId']] })
							})
						});
						routeStartFeature.setStyle(routeStartFeatureStyle);
						routeFeatures.push(routeStartFeature);
						startPointSet = true;
					} else {
						// Draw Route
						if (old_dataset.Map_CenterLongitude !== dataset.Map_CenterLongitude || old_dataset.Map_CenterLatitude !== dataset.Map_CenterLatitude) {
							const routeFeature = new Feature({
								geometry: new LineString(
									[
										[dataset.Map_CenterLongitude, dataset.Map_CenterLatitude],
										[old_dataset.Map_CenterLongitude, old_dataset.Map_CenterLatitude]
									]
								),
								name: datetime.toLocaleString()
							});
							routeFeatures.push(routeFeature);
							if (dataset.f_heading !== 0 && !this.isNearOldArrow(vehicle['mapId'], [dataset.Map_CenterLongitude, dataset.Map_CenterLatitude])) {
								const deltaLong = dataset.Map_CenterLongitude - this.lastArrow[vehicle['mapId']][0];
								const deltaLat = dataset.Map_CenterLatitude - this.lastArrow[vehicle['mapId']][1];
								const angle = Math.atan2(deltaLong, deltaLat);
								const arrowFeature = new Feature({
									geometry: new Point(
										this.lastArrow[vehicle['mapId']]
									)
								});
								arrowFeature.setStyle(
									new Style({
										image: new Icon({
											src: 'assets/icons/arrow.svg',
											scale: 0.75,
											rotation: angle
										})
									})
								);
								arrowFeatures.push(arrowFeature);
								this.lastArrow[vehicle['mapId']] = [dataset.Map_CenterLongitude, dataset.Map_CenterLatitude];
							}
						}
					}
				}
			}
		}
		// Draw End
		if (!vehicle['keepUpToDate'] && vehicle['gpsData'].length > 2) {
			const endPoint = vehicle['gpsData'][vehicle['gpsData'].length - 2];
			if (endPoint != null) {
				let datetime = new Date(endPoint['f_jdzeit_String']);
				const routeEndFeature = new Feature({
					geometry: new Point(
						[
							endPoint.Map_CenterLongitude, endPoint.Map_CenterLatitude
						]
					),
					name: vehicle.logbox_serial + ' - ' + datetime.toLocaleString()
				});
				const routeEndFeatureStyle = new Style({
					image: new Circle({
						radius: 5,
						fill: new Fill({ color: this.routeColors[vehicle['mapId']] })
					})
				});
				routeEndFeature.setStyle(routeEndFeatureStyle);
				routeFeatures.push(routeEndFeature);
			}
		}
		const routeLayer = new VectorLayer({
			source: new VectorSource({
				features: routeFeatures,
			}),
			style: [
				new Style({
					stroke: new Stroke({
						color: this.routeColors[vehicle['mapId']],
						width: 4
					})
				})
			],
			opacity: 0.8,
			name: 'R-' + vehicle['mapId']
		});
		this.map.addLayer(routeLayer);
		const arrowLayer = new VectorLayer({
			source: new VectorSource({
				features: arrowFeatures,
			}),
			minZoom: 15,
			opacity: 0.8,
			name: 'A-' + vehicle['mapId']
		});
		this.map.addLayer(arrowLayer);
		this.visibleRoutes.push(vehicle['mapId']);
	}

	selectFeature(long, lat, name) {
		const selectionFeature = new Feature({
			geometry: new Point([long, lat]),
			name: name
		})
		this.selectionLayer.getSource().clear();
		const selectionStyle = new Style({
			image: new Icon({
				src: 'assets/icons/circle.png',
				scale: this.calcIconScale()
			})
		});
		this.selectionLayer.setStyle(selectionStyle);
		this.selectionLayer.getSource().addFeature(selectionFeature);
	}

	/**
	 * Leerungen aller
	 */
	showAllCollections() {
		this.visibleCollections = []
		this.vehicles.forEach(vehicle => {
			this.switchCollectionVisbility(vehicle);
		});
	}

	/**
	 * Routen aller Fahrzeuge in der Fahrzeugliste auf der Karte ein-/ausblenden
	 */
	showAllRoutes() {
		this.visibleRoutes = []
		this.vehicles.forEach(vehicle => {
			this.switchRouteVisbility(vehicle);
		});
	}

	/**
	 * Gebiete in der Karte ein-/ausblenden
	 */
	showHideAreas(value: boolean) {
		this.showAreas = value;
		this.areaLayer.setVisible(value);
		this.areaBorderLayer.setVisible(value);
	}

	/**
	 * Leerungen eines Fahrzeugs auf der Karte ein-/ausblenden
	 */
	switchCollectionVisbility(vehicle: tbl_fahrzeug) {
		const index = this.visibleCollections.indexOf(vehicle['mapId']);
		if (index > -1) {
			this.map.getLayers().forEach(layer => {
				if (layer.get('name').split('-')[0] == 'C' && layer.get('name').split('-')[1] == vehicle['mapId']) {
					this.visibleCollections.splice(index, 1);
					layer.setVisible(false);
				}
			});
		} else {
			let layerExists = false;
			this.map.getLayers().forEach(layer => {
				if (layer.get('name').split('-')[0] == 'C' && layer.get('name').split('-')[1] == vehicle['mapId']) {
					this.visibleCollections.push(vehicle['mapId']);
					layer.setVisible(true);
					layerExists = true;
				}
			});
			if (!layerExists) {
				this.redrawCollections(vehicle);
			}
		}
	}

	/**
	 * Route eines Fahrzeugs auf der Karte ein-/ausblenden
	 */
	switchRouteVisbility(vehicle) {
		const index = this.visibleRoutes.indexOf(vehicle['mapId']);
		if (index > -1) {
			this.map.getLayers().forEach(layer => {
				if (layer.get('name').split('-')[0] == 'R' && layer.get('name').split('-')[1] == vehicle['mapId']) {
					this.visibleRoutes.splice(index, 1);
					layer.setVisible(false);
				}
			});
		} else {
			let layerExists = false;
			this.map.getLayers().forEach(layer => {
				if (layer.get('name').split('-')[0] == 'R' && layer.get('name').split('-')[1] == vehicle['mapId']) {
					this.visibleRoutes.push(vehicle['mapId']);
					layer.setVisible(true);
					layerExists = true;
				}
			});
			if (!layerExists) {
				this.redrawRoute(vehicle);
			}
		}
	}

	// #endregion Map

	// #region Vehicle

	/**
	 * Fahrzeug zur Fahrzeugliste und Karte hinzufügen und beim Hub anmelden
	 */
	addVehicle(vehicle: tbl_fahrzeug) {
		if (this.accountService.checkPermissions(this.module, Operation.READ) == false || vehicle.logbox_serial === null || vehicle.logbox_serial === undefined)
			return;

		vehicle['mapId'] = this.generateNewVehicleId();

		this.mapService.getCollectionCount(vehicle.logbox_serial, vehicle['fromDate'], vehicle['toDate']).then(res => {
			vehicle['LeerungenCount'] = res;
		});

		this.lastArrow[vehicle['mapId']] = [0, 0];

		if (!this.vehicles) {
			this.vehicles = [];
		}
		this.vehicles.push(vehicle);
		this.vehicles = [...this.vehicles];
		this.vehicleCount = this.vehicles.length;

		if (!vehicle['LastGPS']) {
			vehicle['LastGPS'] = [0, 0];
		}

		if (vehicle['keepUpToDate'] && vehicle['LastGPS']) {
			const feature = new Feature({
				geometry: new Point(vehicle['LastGPS']),
				name: vehicle.logbox_serial
			});
			feature.setId(vehicle['mapId']);
			feature.setStyle(new Style({
				image: new Icon({
					src: 'assets/icons/truck.png',
					scale: 1
				}),
				text: new Text({
					text: vehicle.kennzeichen ? vehicle.kennzeichen : vehicle.bezeichnung,
					scale: 1.2,
					fill: new Fill({
						color: '#fff'
					}),
					stroke: new Stroke({
						color: '0',
						width: 3
					}),
					offsetY: 25
				})
			}));
			this.vehiclesLayer.getSource().addFeature(feature);

			this.centeringMapOnVehicle = vehicle['mapId'];
			this.vehicleSelection = vehicle.ds_this_id;
			if (vehicle['LastGPS'] != null && vehicle['LastGPS'].length >= 2 && vehicle['LastGPS'][0] != null && vehicle['LastGPS'][0] != 0.0 && vehicle['LastGPS'][1] != null && vehicle['LastGPS'][1] != 0.0 && ((vehicle['LastGPS'][0] >= -180.0 && vehicle['LastGPS'][0] <= 180.0) && vehicle['LastGPS'][1] >= -90.0 && vehicle['LastGPS'][1] <= 90.0)) {
				if (this.vehicles.length == 1) {
					this.map.getView().animate({ center: [vehicle['LastGPS'][0], vehicle['LastGPS'][1]], zoom: 11 });
				} else {
					this.map.getView().animate({ center: [vehicle['LastGPS'][0], vehicle['LastGPS'][1]] });
				}
			}
			vehicle['gpsData'] = [];

			this.connectVehicleToDatahub(vehicle.logbox_serial);
		}

		if (!(vehicle['mapId'] in this.routeColors)) {
			this.routeColors[vehicle['mapId']] = vehicle['keepUpToDate'] ? this.defaultLiveRouteColor : this.defaultOldRouteColor;
		}
		this.loadOldRoute(vehicle, vehicle['fromDate'], vehicle['toDate']);
	}

	/**
	 * Position des Spaltenauswahldropdown der Fahrzeugliste festlegen
	 */
	alignVehicleColumnSelection() {
		const columnSelectionPanel: HTMLElement = (document.getElementsByClassName('p-multiselect-panel')[0] as HTMLElement);
		columnSelectionPanel.style.left = this.vehicleColSelection.el.nativeElement.getBoundingClientRect().x;
	}

	/**
	 * Verhindert Stottern des Headers beim Scrollen
	 */
	changeWheelSpeed(container, speedY) {
		var scrollY = 0;
		var handleScrollReset = function () {
			scrollY = container.scrollTop;
		};
		var handleMouseWheel = function (e) {
			e.preventDefault();
			scrollY += speedY * e.deltaY
			if (scrollY < 0) {
				scrollY = 0;
			} else {
				var limitY = container.scrollHeight - container.clientHeight;
				if (scrollY > limitY) {
					scrollY = limitY;
				}
			}
			container.scrollTop = scrollY;
		};

		var removed = false;
		container.addEventListener('mouseup', handleScrollReset, false);
		container.addEventListener('mousedown', handleScrollReset, false);
		container.addEventListener('mousewheel', handleMouseWheel, false);

		return function () {
			if (removed) {
				return;
			}
			container.removeEventListener('mouseup', handleScrollReset, false);
			container.removeEventListener('mousedown', handleScrollReset, false);
			container.removeEventListener('mousewheel', handleMouseWheel, false);
			removed = true;
		};
	}

	/**
	 * Fahrzueg beim DataHub anmelden
	 */
	connectVehicleToDatahub(logboxId: string) {
		var hubConnection = new signalR.HubConnectionBuilder()
			.withUrl(
				`${environment.apiUrl}/OnlineDataHub?logboxid=` + logboxId + '?datetime=' + formatDate(new Date(), 'MM/dd/yyyy HH:mm:ss a', 'en'),
				{
					accessTokenFactory: () => {
						return window.localStorage.getItem('jwt');
					},
					skipNegotiation: true,
					transport: signalR.HttpTransportType.WebSockets,
				} as IHttpConnectionOptions)
			.configureLogging({
				log: (logLevel, message) => {
					if (logLevel > 1) {
						console.log(formatDate(new Date(), 'MM/dd/yyyy HH:mm:ss', 'de') + ' [' + 0 + '] ' + message);
					}
				}
			})
			.withAutomaticReconnect()
			.build();

		if (hubConnection != null && hubConnection != undefined) {
			hubConnection.on('ReceiveNewMaptourData', (message) => {
				const data = JSON.parse(message);
				this.hubUpdateReceived(data);
			});

			hubConnection.start();
			this.hubConnections.push({ id: logboxId, connection: hubConnection });
		}
	}

	/**
	 * Alle Fahrzeuge vom Hub abmelden
	 */
	disconnectAllVehiclesFromDatahub() {
		if (this.vehicles) {
			this.vehicles.forEach(vehicle => {
				this.disconnectVehicleFromDatahub(vehicle.logbox_serial);
			});
		}
	}

	/**
	 * Fahrzeug vom DataHub abmelden
	 */
	disconnectVehicleFromDatahub(logboxId: string) {
		this.hubConnections.forEach(c => {
			if (c.id === logboxId) {
				try {
					c.connection.stop();
				} catch (e) {
					console.log(e);
				}
				this.hubConnections.splice(this.hubConnections.indexOf(c), 1);
				return;
			}
		});
	}

	generateNewVehicleId(): string {
		return Math.random().toString(36).substr(2, 9);
	}

	/**
	 * Update vom Datahub empfangen
	 */
	hubUpdateReceived(data: any[]) {
		if (data) {
			data.forEach(dataset => {
				if (dataset != null && (dataset.Map_CenterLongitude !== 0 || dataset.Map_CenterLatitude !== 0)) {
					this.vehicles.forEach(vehicle => {
						if (vehicle.logbox_serial == dataset.f_system_id) {
							if (vehicle['gpsData'].length == 0 || vehicle['gpsData'][vehicle['gpsData'].length - 1].autoid != dataset.autoid) {
								// Fahrzeug
								let lastUpdate = new Date(dataset['f_jdzeit_String']);
								vehicle['LastUpdate'] = lastUpdate.toLocaleString();

								vehicle['onlineStatus'] = 1;

								if (vehicle['keepUpToDate'] && (dataset.Map_CenterLongitude != 0 || dataset.Map_CenterLatitude != 0)) {
									vehicle['gpsData'].push(dataset);
									vehicle['LastGPS'] = [dataset['Map_CenterLongitude'], dataset['Map_CenterLatitude']];

									const feature = this.vehiclesLayer.getSource().getFeatureById(vehicle['mapId'])
									if (this.centeringMapOnVehicle == vehicle['mapId']) {
										if (dataset.Map_CenterLongitude != null && dataset.Map_CenterLatitude != null && (dataset.Map_CenterLongitude != 0 || dataset.Map_CenterLatitude != 0)) {
											this.centerMapOn(dataset.Map_CenterLongitude, dataset.Map_CenterLatitude);
										}
										this.updateTimelineData(vehicle);
									}
									if (dataset.f_heading !== 0) {
										feature.getStyle().getImage().setRotation(+dataset.f_heading / 1000.0);
									}
									feature.getGeometry().setCoordinates([dataset.Map_CenterLongitude, dataset.Map_CenterLatitude]);

									// Route
									if (this.visibleRoutes.indexOf(vehicle['mapId']) > -1 && vehicle['gpsData'].length > 1) {
										const old_dataset = vehicle['gpsData'][vehicle['gpsData'].length - 2];
										if (old_dataset.Map_CenterLongitude !== dataset.Map_CenterLongitude || old_dataset.Map_CenterLatitude !== dataset.Map_CenterLatitude) {
											const routeFeature = new Feature({
												geometry: new LineString(
													[
														[dataset.Map_CenterLongitude, dataset.Map_CenterLatitude],
														[old_dataset.Map_CenterLongitude, old_dataset.Map_CenterLatitude]
													]
												),
												name: lastUpdate.toLocaleString()
											});
											let arrowFeature;
											if (dataset.f_heading !== 0 && !this.isNearOldArrow(vehicle['mapId'], [dataset.Map_CenterLongitude, dataset.Map_CenterLatitude])) {
												const deltaLong = dataset.Map_CenterLongitude - this.lastArrow[vehicle['mapId']][0];
												const deltaLat = dataset.Map_CenterLatitude - this.lastArrow[vehicle['mapId']][1];
												const angle = Math.atan2(deltaLong, deltaLat);
												arrowFeature = new Feature({
													geometry: new Point(
														this.lastArrow[vehicle['mapId']]
													)
												});
												arrowFeature.setStyle(
													new Style({
														image: new Icon({
															src: 'assets/icons/arrow.svg',
															scale: 0.75,
															rotation: angle
														})
													})
												);
												this.lastArrow[vehicle['mapId']] = [dataset.Map_CenterLongitude, dataset.Map_CenterLatitude];
											}
											this.map.getLayers().forEach(layer => {
												if (layer.get('name').split('-')[1] == vehicle['mapId']) {
													if (layer.get('name').split('-')[0] == 'R') {
														layer.getSource().addFeature(routeFeature);
													} else if (arrowFeature && layer.get('name').split('-')[0] == 'A') {
														layer.getSource().addFeature(arrowFeature);
													}
												}
											});
										}
									}

									// Leerungen
									if (dataset.IsLeerung == 1) {
										if (this.visibleCollections.indexOf(vehicle['mapId']) > -1) {
											const collectionfeature = new Feature({
												geometry: new Point([dataset.Map_CenterLongitude, dataset.Map_CenterLatitude]),
												name: lastUpdate.toLocaleString() + (dataset.Identcode ? ' - ' + dataset.Identcode : '')
											})
											this.map.getLayers().forEach(layer => {
												if (layer.get('name').split('-')[0] == 'C' && layer.get('name').split('-')[1] == vehicle['mapId']) {
													layer.getSource().addFeature(collectionfeature);
												}
											});
											vehicle['LeerungenCount'] += 1;
										}
										if (this.vehicleSelection == vehicle.ds_this_id) {
											this.updateCollectionsTable(vehicle);
										}
									}
								}
								this.vehicles = [...this.vehicles];
							}
						}
					});
				}
			});
		}
	}

	/**
	 * Ist ein Filter auf diese Spalte der Fahrzeugliste angewendet
	 */
	isColFiltered(col) {
		let isFiltered = false;
		if (this.vehicleTable && this.vehicleTable.filters && this.vehicleTable.filters[col]) {
			Object.keys(this.vehicleTable.filters[col]).forEach(filter => {
				if (this.vehicleTable.filters[col][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		else if (this.vehicleState && this.vehicleState.filters && this.vehicleState.filters[col]) {
			Object.keys(this.vehicleState.filters[col]).forEach(filter => {
				if (this.vehicleState.filters[col][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		return isFiltered;
	}

	/**
	 * Spaltensortierung in der Fahrzeugliste geändert
	 */
	onVehicleColReorder(event) {
		this.retrieveVehicleTableState(this.vehicleState);
		const columnWidths = this.vehicleState.columnWidths.split(',');
		columnWidths.splice(event.dropIndex, 0, columnWidths.splice(event.dragIndex, 1)[0]);
		this.vehicleState.columnWidths = columnWidths.join(',');
		localStorage.setItem(this.vehicleStateName, JSON.stringify(this.vehicleState));
	}

	/**
	 * Spaltenbreite in der Fahrzeugliste anpassen
	 */
	onVehicleColResize(event) {
		const index = Array.from(event.element.parentNode.children).indexOf(event.element) - 1;
		this.retrieveVehicleTableState(this.vehicleState);
		this.vehicleCols[index].width = Number(event.element.style.width.split('px')[0]);
		this.vehicleState.columnWidths = (this.vehicleCols.map(c => c.width)).concat([this.vehicleButtonColWidth]).join(',');
		localStorage.setItem(this.vehicleStateName, JSON.stringify(this.vehicleState));

		this.resizeTablesWidths(this.vehicleState, 'vehicle');
	}

	/**
	 * Fahrzeugliste filtern
	 */
	onVehicleFilter(event) {
		this.vehicleCount = this.vehicleTable.filteredValue ? this.vehicleTable.filteredValue.length : this.vehicles.length;
	}

	/**
	 * Zeile der Fahrzeugliste angeklickt
	 */
	onRowSelect(event) {
		this.centerMapOn(event.data.Map_CenterLongitude, event.data.Map_CenterLatitude);
		const datetime = new Date(event.data['f_jdzeit_String']);
		this.selectFeature(event.data.Map_CenterLongitude, event.data.Map_CenterLatitude, datetime.toLocaleString() + (event.data.Identcode ? ' - ' + event.data.Identcode : ''));
		this.centeringMapOnVehicle = '';

		this.vehicles.forEach(vehicle => {
			if (event.data.f_system_id == vehicle.logbox_serial) {
				const index = this.visibleCollections.indexOf(vehicle['mapId']);
				if (index == -1) {
					let layerExists = false;
					this.map.getLayers().forEach(layer => {
						if (layer.get('name').split('-')[0] == 'C' && layer.get('name').split('-')[1] == vehicle['mapId']) {
							this.visibleCollections.push(vehicle['mapId']);
							layer.setVisible(true);
							layerExists = true;
						}
					});
					if (!layerExists) {
						this.redrawCollections(vehicle);
					}
				}
			}
		});
	}

	/**
	 * Popup zur Fahrzeugauswahl öffnen
	 */
	openVehicleSelectionDialog() {
		const ref = this.dialogService.open(VehicleSelection, {
			header: this.translate.instant('Fahrzeugauswahl'),
			width: '70%'
		});

		ref.onClose.subscribe((vehicles: tbl_fahrzeug[]) => {
			if (vehicles) {
				vehicles.forEach(vehicle => {
					try {
						this.addVehicle(vehicle);
					} catch (e) {
						console.log(e);
					}
				});
			}
		});
	}

	/**
	 * Alle Fahrzeuge entfernen
	 */
	removeAllVehicles() {
		while (this.vehicles.length > 0) {
			this.removeVehicle(this.vehicles[0]);
		};
	}

	/**
	 * Fahrzeug aus Liste und Karte entfernen und vom Hub abmelden
	 */
	removeVehicle(vehicle) {
		try {
			this.disconnectVehicleFromDatahub(vehicle.logbox_serial);

			this.vehicles.splice(this.vehicles.indexOf(vehicle), 1);
			this.vehicles = [...this.vehicles];
			this.vehicleCount = this.vehicles.length;

			if (this.vehicleSelection == vehicle.ds_this_id) {
				this.clearSelectedCollection();
				this.vehicleSelection = null;
				this.collections = [];
				this.collectionCount = 0;
			}

			if (vehicle['keepUpToDate']) {
				let f = this.vehiclesLayer.getSource().getFeatureById(vehicle['mapId']);
				if (f) {
					this.vehiclesLayer.getSource().removeFeature(f);
				}
			}
			const layersToRemove = [];
			this.map.getLayers().forEach(layer => {
				if (layer.get('name').split('-')[1] == vehicle['mapId']) {
					layersToRemove.push(layer);
				}
			});
			layersToRemove.forEach(l => {
				this.map.removeLayer(l);
			});

			this.clearTimeline();
		} catch (e) {
			console.log(e);
		}
	}

	/**
	 * TableState der Tabelle zurücksetzen und die Seite neu laden
	 */
	resetTable(table) {
		table.clearState();
		window.location.reload();
	}

	/**
	 * TableState der Fahrzeugliste aus dem localstorage holen
	 */
	retrieveVehicleTableState(state?) {
		this.vehicleState = state ? state : JSON.parse(localStorage.getItem(this.vehicleStateName));
		if (this.vehicleTable && (this.vehicleState == undefined)) {
			// for storage of table state
			this.vehicleTable.saveState();
			// reload and parse
			this.vehicleState = JSON.parse(localStorage.getItem(this.vehicleStateName));
		}
	}

	/**
	 * Spalte in der Fahrzeugliste ein-/ausblenden
	 */
	toggleVehicleColumn(event) {
		this.retrieveVehicleTableState(this.vehicleState);
		this.vehicleState.columnOrder = event.value.map(c => c.key);
		this.vehicleState.columnWidths = event.value.map(c => c.width);
		this.vehicleState.columnWidths = this.vehicleState.columnWidths.join(',');
		this.vehicleState.columnWidths = this.vehicleState.columnWidths + ',' + this.vehicleButtonColWidth;
		this.vehicleState.tableWidth = (this.vehicleState.columnWidths.split(',')).reduce((summe, element) => summe + Number(element), 0) + 'px';
		this.vehicleFilters = event.value.map(c => c.key);
		localStorage.setItem(this.vehicleStateName, JSON.stringify(this.vehicleState));
		this.resizeTablesWidths(this.vehicleState);
	}

	// #endregion Vehicle

	// #region Collection

	/**
	 * Position des Spaltenauswahldropdown der Leerungsliste festlegen
	 */
	alignCollectionColumnSelection() {
		const columnSelectionPanel: HTMLElement = (document.getElementsByClassName('p-multiselect-panel')[0] as HTMLElement);
		columnSelectionPanel.style.left = this.collectionColSelection.el.nativeElement.getBoundingClientRect().x;
	}

	/**
	 * Daten der Leerungsliste aktualisieren
	 */
	updateCollectionsTable(vehicle) {
		this.collections = [];
		let tmp: Collection[] = [];
		if( vehicle && vehicle['gpsData']) {
			vehicle['gpsData'].forEach(data => {
				if (data.IsLeerung == 1) {
					if( !this.collectionArrayIdInArray(tmp, data['autoid'])) {
						const datetime = new Date(data.f_jdzeit_String);
						data.datetime = datetime;
						tmp.push(data);
					}
				}
			});
		}
		this.collections = [... tmp];
		this.collectionCount = this.collections.length;
	}

	collectionArrayIdInArray(dataset: Collection[], autoid: number): boolean {
		let isIn = false;
		// TODO
		for(let datarow of dataset) {
			if( datarow && datarow['autoid']) {
				if(datarow['autoid'] === autoid) {
					isIn = true;
					break;
				}
			}
			else {
				if( datarow )
					console.log('no autoid...');
				else console.log('no datarow');
			}
		}

		return isIn;
	}

	/**
	 * Spalten der Leerungsliste umsortiert
	 */
	onCollectionColReorder(event) {
		this.retrieveCollectionTableState(this.collectionState);
		const columnWidths = this.collectionState.columnWidths.split(',');
		columnWidths.splice(event.dropIndex, 0, columnWidths.splice(event.dragIndex, 1)[0]);
		this.collectionState.columnWidths = columnWidths.join(',');
		localStorage.setItem(this.collectionStateName, JSON.stringify(this.collectionState));
	}

	/**
	 * Spaltenbreite der Leerungsliste angepasst
	 */
	onCollectionColResize(event) {
		const index = Array.from(event.element.parentNode.children).indexOf(event.element);
		this.retrieveCollectionTableState(this.collectionState);
		this.collectionCols[index].width = Number(event.element.style.width.split('px')[0]);
		this.collectionState.columnWidths = (this.collectionCols.map(c => c.width)).concat([this.collectionButtonColWidth]).join(',');
		localStorage.setItem(this.collectionStateName, JSON.stringify(this.collectionState));

		this.resizeTablesWidths(this.collectionState, 'collection');
	}

	/**
	 * Leerungsliste filtern
	 */
	onCollectionFilter(event) {
		this.collectionCount = this.collectionTable.filteredValue ? this.collectionTable.filteredValue.length : this.collections.length;
	}

	/**
	 * TableState der Leerungsliste aus dem localstorage holen
	 */
	retrieveCollectionTableState(state?) {
		this.collectionState = state ? state : JSON.parse(localStorage.getItem(this.collectionStateName));
		if (this.collectionTable && (this.collectionState == undefined)) {
			// for storage of table state
			this.collectionTable.saveState();
			// reload and parse
			this.collectionState = JSON.parse(localStorage.getItem(this.collectionStateName));
		}
	}

	/**
	 * Spalte in der Leerungsliste ein-/ausblenden
	 */
	toggleCollectionColumn(event) {
		this.retrieveCollectionTableState(this.collectionState);
		this.collectionState.columnOrder = event.value.map(c => c.key);
		this.collectionState.columnWidths = event.value.map(c => c.width);
		this.collectionState.columnWidths = this.collectionState.columnWidths.join(',');
		this.collectionState.columnWidths = this.collectionState.columnWidths + ',' + this.collectionButtonColWidth;
		this.collectionState.tableWidth = (this.collectionState.columnWidths.split(',')).reduce((summe, element) => summe + Number(element), 0) + 'px';
		this.collectionFilters = event.value.map(c => c.key);
		localStorage.setItem(this.collectionStateName, JSON.stringify(this.collectionState));
		this.resizeTablesWidths(this.collectionState);
	}

	// #endregion Collection

	// #region Timeline

	/**
	 * Punkt auf Tmeline angeklickt
	 */
	selectTimelineData(event) {
		const gps = this.timelineVehicle['gpsData'][event.element._index * this.timelineAccuracy];
		if (gps != null && gps.Map_CenterLongitude != null && gps.Map_CenterLatitude != null) {
			this.centerMapOn(gps.Map_CenterLongitude, gps.Map_CenterLatitude);
		}
		this.centeringMapOnVehicle = '';
	}

	/**
	 * Timeline Option aus localStorage wiederherstellen (safe)
	 */
	restoreTimelineLimitationMode() {
		let storageVal = localStorage.getItem(this.timelineLimitationModeKey);
		if (null != storageVal && undefined != storageVal) {
			if (storageVal === this.timelineLimitationMode_byData) {
				this.timelineMinMaxByData = true;
			} else this.timelineMinMaxByData = false;
		} else this.timelineMinMaxByData = false;
	}

	/**
	 * Timeline Option in localStorage schreiben
	 */
	storeTimelineLimitationMode() {
		localStorage.setItem(this.timelineLimitationModeKey, this.timelineMinMaxByData ? this.timelineLimitationMode_byData : this.timelineLimitationMode_byFilter);
	}

	/**
	 * Timeline Darstellung (Grenzen) umschalten
	 */
	toggleTimelineMinMaxMode() {
		this.timelineMinMaxByData = !this.timelineMinMaxByData;
		this.storeTimelineLimitationMode();

		//if( this.isShownElement('timelineContainer') ) {
		if (this.timelineVehicle) {
			this.updateTimelineData(this.timelineVehicle);
		}
		//}
	}

	/**
	 * Timeline neu zeichnen
	 */
	updateTimelineData(vehicle) {
		if (vehicle) {
			this.timelineVehicle = vehicle;
			const velocityData = [];
			const timeData = [];
			let old_velocity = 0;

			let minMaxByData = this.timelineMinMaxByData;
			if (vehicle['gpsData'] == null || vehicle['gpsData'] == undefined || vehicle['gpsData'].length < 2) {
				minMaxByData = false; // failback auf Zeitgrenzen nach Auswahl
			}

			//MinTime
			if (minMaxByData) {
				let fromDate = new Date(vehicle['gpsData'][0].f_jdzeit_String);
				fromDate.setMinutes(fromDate.getMinutes() - 45);
				timeData.push(fromDate);
			} else {
				timeData.push(vehicle.fromDate);
			}
			velocityData.push(null);
			let time = new Date();
			for (let i = 0; i < vehicle['gpsData'].length; i += 101 - this.timelineAccuracy) {
				const d = vehicle['gpsData'][i];
				const new_velocity = Math.round(0.7 * old_velocity + 0.3 * +d.f_velocity);
				velocityData.push(new_velocity);
				old_velocity = new_velocity;
				time = new Date(d.f_jdzeit_String);
				timeData.push(time);
			};
			//MaxTime
			if (minMaxByData) {
				let toDate = new Date(vehicle['gpsData'][vehicle['gpsData'].length - 1].f_jdzeit_String);
				toDate.setMinutes(toDate.getMinutes() + 45);
				timeData.push(toDate);
			} else {
				timeData.push(vehicle.toDate);
			}
			this.timelineData = {
				labels: timeData,
				datasets: [
					{
						data: velocityData,
						fill: false,
						borderColor: this.routeColors[vehicle['mapId']],
						pointRadius: 1,
						tension: .4
					}
				]
			}
			this.timelineOptions.title =
			{
				display: true,
				text: vehicle.kennzeichen,
				fontSize: 14,
				position: 'bottom'
			};
		} else {
			this.clearTimeline();
		}
	}

	/**
	 * Timeline leer zeichnen
	 */
	clearTimeline() {
		this.timelineVehicle = null;
		const velocityData = [];
		const timeData = [];

		//MinTime
		let fromDate = new Date(Date.now());
		fromDate.setHours(0);
		fromDate.setMinutes(0);
		fromDate.setSeconds(0);
		fromDate.setMilliseconds(0);
		timeData.push(fromDate);

		//MaxTime
		let toDate = new Date(Date.now());
		toDate.setHours(23);
		toDate.setMinutes(59);
		toDate.setSeconds(59);
		toDate.setMilliseconds(0);
		timeData.push(toDate);

		this.timelineData = {
			labels: timeData,
			//datasets: [
			//	{
			//		data: velocityData,
			//		fill: false,
			//		borderColor: '#000000',
			//		pointRadius: 1,
			//		tension: .4
			//	}
			//]
		}
		this.timelineOptions.title =
		{
			display: true,
			text: 'n/a',
			fontSize: 14,
			position: 'bottom'
		};
	}

	// #endregion Timeline
}
