import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';

@Injectable()
export class BreadcrumbService {
	private items: MenuItem[];
	private itemsSource = new Subject<MenuItem[]>();
	itemsHandler = this.itemsSource.asObservable();

	constructor(
		public translate: TranslateService,
	) { }

	getItems(): MenuItem[] {
		return this.items;
	}

	setItems(items: MenuItem[]) {
		this.items = items;
		items.forEach(i => {
			this.translate.get(i.label).subscribe((translation: string) => {
				i.label = translation;
			});
		});
		this.itemsSource.next(items);
	}
}
