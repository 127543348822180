import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { ListComponent } from '@app/common/templates/list/list.component';
import { Module } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { ExportService } from '@app/services/export.service';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { Base } from '@app/models/base';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TblGefaessFilterDialogComponent } from './filter_dialog/filter_dialog.component';
import { ZPLPrintService } from '@app/services/zplprint.service';
import { tbl_objekt_flags } from '@app/models/tbl_objekt_flags';
import { TblGefaessMoveToHaldeDialogComponent } from '../detail/move_to_halde_dialog/move_to_halde_dialog.component';
import { MoveGarbageCanDialogComponent } from '../move_dialog/dialog.component';
import { PopupFromToReasonComponent } from '@app/common/popup/popupFromToReason.component';
import { PopupCloseOnReasonAdditionComponent } from '@app/common/popup/popupCloseOnReasonAddition.component';

@Component({
	templateUrl: './list.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/list/style.scss'],
	providers: [MessageService, ConfirmationService, DialogService]
})
export class TblGefaessListComponent extends ListComponent {
	//fileUploadUrl: string;
	headers: any;
	selectedTrashbins = [];
	//selectionModeMulti = false;
	visibleCheckboxes = false;
	heapObjectId: number = null;

	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public changeDetectorRef: ChangeDetectorRef,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public dialogService: DialogService,
		public elRef: ElementRef,
		public exportService: ExportService,
		public globals: Globals,
		public messageService: MessageService,
		public router: Router,
		public settingsService: SettingsService,
		public translate: TranslateService,
		private zplPrinter: ZPLPrintService
	) {
		super(accountService, breadcrumbService, changeDetectorRef, confirmationService, crudService, elRef, exportService, globals, messageService, router, settingsService, translate);

		this.apiUrl = 'TblGefaess';
		this.name = 'MENU.GEFAESS';
		this.url = '/' + PATH.GEFAESS;
		this.module = Module.BHV;

		this.possibleCols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
			{ type: 'text', key: 'bezeichnung_kurz', required: false, width: 400 },
			{ type: 'text', key: 'barcode', required: false, width: 400 },
			{ type: 'date', key: 'erstdruck_zeitpunkt', required: true, width: 400 },
			{ type: 'date', key: 'nachdruck_zeitpunkt', required: true, width: 400 },
			{ type: 'numeric', key: 'druck_zaehler', required: true, width: 400 },
			{ type: 'boolean', key: 'FREMD_hat_identcode_sperre_im_zeitraum', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_identcode_sperre_sperrgrund', required: false, width: 400 },
			{ type: 'date', key: 'FREMD_identcode_sperre_von', required: false, width: 400 },
			{ type: 'date', key: 'FREMD_identcode_sperre_bis', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_typ_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_zustand_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_status_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_abfuhrrhythmus_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_abfallart_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_volumen_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_objekt_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_objekt_idnummer', required: false, width: 400 },
			{ type: 'numeric', key: 'FREMD_objekt_hausnummer1', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_objekt_hauszusatz1', required: false, width: 400 },
			{ type: 'numeric', key: 'FREMD_objekt_hausnummer2', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_objekt_hauszusatz2', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_objekt_zusatz_text', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_objekt_strasse_name', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_objekt_strasse_ortsteil', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_objekt_strasse_ort_name', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_objekt_strasse_ort_postleitzahl', required: false, width: 400 },
			{ type: 'date', key: 'zugeordnet_von', required: false, width: 400 },
			{ type: 'date', key: 'zugeordnet_bis', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_eigentuemer_bezeichnung', required: false, width: 400 },
			{ type: 'date', key: 'eigentuemer_von', required: false, width: 400 },
			{ type: 'date', key: 'eigentuemer_bis', required: false, width: 400 },
		];
		this.cols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'text', key: 'barcode', required: false, width: 400 },
			{ type: 'date', key: 'erstdruck_zeitpunkt', required: true, width: 400 },
			{ type: 'date', key: 'nachdruck_zeitpunkt', required: true, width: 400 },
			{ type: 'numeric', key: 'druck_zaehler', required: true, width: 400 },
			{ type: 'boolean', key: 'FREMD_hat_identcode_sperre_im_zeitraum', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_identcode_sperre_sperrgrund', required: false, width: 400 },
			{ type: 'date', key: 'FREMD_identcode_sperre_von', required: false, width: 400 },
			{ type: 'date', key: 'FREMD_identcode_sperre_bis', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_typ_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_zustand_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_status_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_abfuhrrhythmus_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_abfallart_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_volumen_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_objekt_bezeichnung', required: false, width: 400 },
			{ type: 'date', key: 'zugeordnet_von', required: false, width: 400 },
			{ type: 'date', key: 'zugeordnet_bis', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_eigentuemer_bezeichnung', required: false, width: 400 },
			{ type: 'date', key: 'eigentuemer_von', required: false, width: 400 },
			{ type: 'date', key: 'eigentuemer_bis', required: false, width: 400 },
		];
		this.breadcrumbService.setItems([
			{ label: 'MENU.BHV' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.LIST', routerLink: [this.url + '/' + PATH.LIST] }
		]);
		this.hasFilterDialog = true;
	}

	async ngOnInit(): Promise<void> {
		super.ngOnInit();
		this.loading += 1;

		//this.fileUploadUrl = `${environment.apiUrl}/TblObjektGefaessLink/UploadTxtFile`;
		//this.fileUploadUrl = `${environment.apiUrl}/TblObjektGefaessLink/UploadSapAbgleichV198File`;
		const options = await this.accountService.getOptions();
		this.headers = options.headers;

		//this.complementContextMenu();

		this.loading -= 1;
	}

	// override
	initContextMenu(): void {
		this.translate.get('init').subscribe((text: string) => {
			this.contextMenu = [
				// default
				{ label: this.translate.instant('CONTEXT_MENU.CREATE'), icon: 'pi pi-fw pi-plus', command: () => this.create() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN'), icon: 'pi pi-fw pi-search', command: () => this.detail() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_TAB'), icon: 'pi pi-fw pi-search', command: () => this.detail('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_WINDOW'), icon: 'pi pi-fw pi-search', command: () => this.detail('window') },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT'), icon: 'pi pi-fw pi-pencil', command: () => this.edit() },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT_TAB'), icon: 'pi pi-fw pi-pencil', command: () => this.edit('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT_WINDOW'), icon: 'pi pi-fw pi-pencil', command: () => this.edit('window') },
				{ label: this.translate.instant('CONTEXT_MENU.DELETE'), icon: 'pi pi-fw pi-trash', command: () => this.delete() },
				{ label: this.translate.instant('CONTEXT_MENU.RESIZE'), icon: 'pi pi-fw pi-arrows-h', command: () => this.resizeTableWidthFromContent(true) },
				// custom
				{ separator: true },
				{ label: this.translate.instant('CONTEXT_MENU.PRINT_CURRENT_LABEL'), icon: 'fa fa-print', command: () => this.printCMGefaess() },
				{ label: this.translate.instant('CONTEXT_MENU.PRINT_SELECTED_LABELS'), icon: 'fa fa-print', command: () => this.printGefaesse() },
				{ label: this.translate.instant('CONTEXT_MENU.SELECT_ALL'), icon: 'pi pi-fw pi-check-square', command: () => this.selectAll() },
				{ label: this.translate.instant('CONTEXT_MENU.UNSELECT_ALL'), icon: 'pi pi-fw pi-stop', command: () => this.unselectAll() },
				{ separator: true },
				{ label: this.translate.instant('CONTEXT_MENU.GEFAESS_HALDE_STELLEN'), icon: 'fa fa-house-circle-check', command: () => this.gfAufHaldeStellen() },
				{ label: this.translate.instant('CONTEXT_MENU.ALLE_GEFAESSE_HALDE_STELLEN'), icon: 'fa fa-house-circle-check', command: () => this.gfAlleAufHaldeStellen() },
				{ separator: true },
				{ label: this.translate.instant('CONTEXT_MENU.GEFAESS_OBJEKT_STELLEN'), icon: 'fa fa-house', command: () => this.gfAufObjektStellen() },
				{ label: this.translate.instant('CONTEXT_MENU.ALLE_GEFAESSE_OBJEKT_STELLEN'), icon: 'fa fa-house', command: () => this.gfAlleAufObjektStellen() },
				{ separator: true },
				{ label: this.translate.instant('CONTEXT_MENU.LOCK_BARCODE'), icon: 'fa fa-lock', command: () => this.gfSperren() },
				{ label: this.translate.instant('CONTEXT_MENU.UNLOCK_BARCODE'), icon: 'fa fa-lock-open', command: () => this.gfEntsperren() },
				{ label: this.translate.instant('CONTEXT_MENU.LOCK_SELECTED_BARCODES'), icon: 'fa fa-lock', command: () => this.gfAlleSperren() },
				{ label: this.translate.instant('CONTEXT_MENU.UNLOCK_SELECTED_BARCODES'), icon: 'fa fa-lock-open', command: () => this.gfAlleEntsperren() },
				//
			];
			this.possibleCols.forEach(c => {
				c.label = this.translate.instant('HEADERS.' + c.key);
			});
		});
	}

	//complementContextMenu(): void {
	//	try {
	//		this.loading += 1;
	//		this.translate.get('init').subscribe((text: string) => {
	//			this.contextMenu.push(
	//				{ separator: true }
	//			);
	//			this.contextMenu.push(
	//				{ label: this.translate.instant('CONTEXT_MENU.PRINT_CURRENT_LABEL'), icon: 'fa fa-print', command: () => this.printCMGefaess() }
	//			);
	//			this.contextMenu.push(
	//				{ label: this.translate.instant('CONTEXT_MENU.PRINT_SELECTED_LABELS'), icon: 'fa fa-print', command: () => this.printGefaesse() }
	//			);
	//			this.contextMenu.push(
	//				{ label: this.translate.instant('CONTEXT_MENU.SELECT_ALL'), icon: 'pi pi-fw pi-check-square', command: () => this.selectAll() }
	//			);
	//			this.contextMenu.push(
	//				{ label: this.translate.instant('CONTEXT_MENU.UNSELECT_ALL'), icon: 'pi pi-fw pi-stop', command: () => this.unselectAll() }
	//			);
	//			this.contextMenu.push(
	//				{ separator: true }
	//			);
	//			this.contextMenu.push(
	//				{ label: this.translate.instant('CONTEXT_MENU.GEFAESS_HALDE_STELLEN'), icon: 'fa fa-house-circle-check', command: () => this.gfAufHaldeStellen() }
	//			);
	//			this.contextMenu.push(
	//				{ label: this.translate.instant('CONTEXT_MENU.ALLE_GEFAESSE_HALDE_STELLEN'), icon: 'fa fa-house-circle-check', command: () => this.gfAlleAufHaldeStellen() }
	//			);
	//			this.contextMenu.push(
	//				{ separator: true }
	//			);
	//			this.contextMenu.push(
	//				{ label: this.translate.instant('CONTEXT_MENU.GEFAESS_OBJEKT_STELLEN'), icon: 'fa fa-house', command: () => this.gfAufObjektStellen() }
	//			);
	//			this.contextMenu.push(
	//				{ label: this.translate.instant('CONTEXT_MENU.ALLE_GEFAESSE_OBJEKT_STELLEN'), icon: 'fa fa-house', command: () => this.gfAlleAufObjektStellen() }
	//			);
	//			this.contextMenu.push(
	//				{ separator: true }
	//			);
	//			this.contextMenu.push(
	//				{ label: this.translate.instant('CONTEXT_MENU.LOCK_BARCODE'), icon: 'fa fa-lock', command: () => this.gfSperren() }
	//			);
	//			this.contextMenu.push(
	//				{ label: this.translate.instant('CONTEXT_MENU.UNLOCK_BARCODE'), icon: 'fa fa-lock-open', command: () => this.gfEntsperren() }
	//			);
	//			this.contextMenu.push(
	//				{ label: this.translate.instant('CONTEXT_MENU.LOCK_SELECTED_BARCODES'), icon: 'fa fa-lock', command: () => this.gfAlleSperren() }
	//			);
	//			this.contextMenu.push(
	//				{ label: this.translate.instant('CONTEXT_MENU.UNLOCK_SELECTED_BARCODES'), icon: 'fa fa-lock-open', command: () => this.gfAlleEntsperren() }
	//			);
	//		});
	//	}
	//	catch {}
	//	finally {
	//		this.loading -= 1;
	//	}
	//}

	selectAll(): void {
		this.unselectAll();
		this.entries.forEach(e => {
			this.selectedTrashbins.push(e);
		});
	}

	unselectAll(): void {
		this.selectedTrashbins = [];
	}

	openFilterDialog() {
		const ref = this.dialogService.open(TblGefaessFilterDialogComponent, {
			header: this.translate.instant('Filter'),
			width: '70%'
		});

		ref.onClose.subscribe((filters) => {
			if (filters) {
				this.getFilteredEntries(filters);
			}
		});
	}

	getFilteredEntries(filters) {
		this.loading += 1;
		this.unselectAll();
		this.entries = [];
		this.loadFilters = filters;
		this.crudService.getFilteredEntries(this.apiUrl, filters).then(res => {
			this.processEntries(res);
			this.resizeTableWidth();
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.unselectAll();
			this.loading -= 1;
		});
	}

	handleContextMenuSelect(event) {
		// das ist dann die mit rechts angeklickte datarow (hat nichts mit der selektion aus dem multiselect zu tun)
		this.selectedEntry = event.data;
	}

	handleRowSelection(event) {
		// und das hier ist die gerade neu selektierte zeile aus dem multiselect
		// unbenutzt
	}

	getIsPrintZplDebug(): boolean {
		let result: boolean = false;
		let key: string = "zplDebug";
		var debugFlag = localStorage.getItem(key);
		if( debugFlag ) {
			result = debugFlag === 'true' ? true : false;
		} else {
			localStorage.setItem(key, 'false');
		}
		return result;
	}

	printCMGefaess(): void {
		if( this.entries && this.entries.length > 0 && this.selectedEntry ) {
			this.loading += 1;
			var zugeordnetVon = this.selectedEntry["zugeordnet_von"];
			let dtZugeordnetVon: Date = null;
			//console.log("zugeordnet_von:");
			//console.log(zugeordnetVon);
			if(zugeordnetVon) {
				dtZugeordnetVon = new Date(zugeordnetVon);
				if( dtZugeordnetVon ) {
					dtZugeordnetVon.setHours(11, 0, 0, 0);
				}
			}

			this.crudService.printGefaess(this.selectedEntry.ds_this_id, dtZugeordnetVon).then(async printRes => {
				const blob = new Blob([printRes], { type: 'application/octet-stream' });
				if( this.getIsPrintZplDebug() ) {
					console.log(await blob.text());
				} else {
					await this.zplPrinter.print(await blob.text());
				}
				this.messageService.add({ severity: 'info', summary: 'Druckvorgang', detail: "Etiketten wurden gedruckt", life: 10000 });
			}).catch(err => {
				this.messageService.add({ severity: 'error', summary: 'Druckvorgang', detail: err.message, life: 10000 });
				err.error.forEach(e => {
					this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
				})
			}).finally(() => {
				this.loading -= 1;
				// und aktualisierten Eintrag neu laden, ggf. Identcode sperren, Liste aktualisieren
				this.loading += 1;
				this.crudService.getEntry(this.apiUrl, this.selectedEntry.ds_this_id).then(reloadRes => {
					this.listReplaceEntry(reloadRes);
					this.selectedEntry = reloadRes;
				}).catch(err => {
					this.messageService.add({ severity: 'error', summary: 'Druckvorgang', detail: err.message, life: 10000 });
				}).finally(() => {
					this.loading -= 1;
				})
			});
		}
	}

	printGefaesse(): void {
		if( this.entries && this.entries.length > 0 ) {
			let ids: number[] = [];
			let id_barcodeVorher_values: any[] = [];
			//let id_barcodeNachher_values: any[] = [];
			if(this.selectedTrashbins && this.selectedTrashbins.length > 0) {
				this.selectedTrashbins.forEach(e => {
					if( e.ds_this_id) {
						ids.push(e.ds_this_id);
						if(e['barcode']) {
							id_barcodeVorher_values.push({id: e.ds_this_id, barcode: e['barcode']});
						}
					}
				});
			} else if(this.selectedEntry) {
				if( this.selectedEntry.ds_this_id ) {
					ids.push(this.selectedEntry.ds_this_id);
					if(this.selectedEntry['barcode']) {
						id_barcodeVorher_values.push({id: this.selectedEntry.ds_this_id, barcode: this.selectedEntry['barcode']});
					}
				}
			}
			this.unselectAll();
			if( ids.length > 0 ) {
				this.loading += 1;
				this.crudService.printGefaesse(ids).then(async printRes => {
					const blob = new Blob([printRes], { type: 'application/octet-stream' });
					if( this.getIsPrintZplDebug() ) {
						console.log(await blob.text());
					} else {
						await this.zplPrinter.print(await blob.text());
					}
					this.messageService.add({ severity: 'info', summary: 'Druckvorgang', detail: "Etiketten wurden gedruckt", life: 10000 });
				}).catch(err => {
					this.messageService.add({ severity: 'error', summary: 'Druckvorgang', detail: err.message, life: 10000 });
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				}).finally(() => {
					this.loading -= 1;
					this.loading += 1;
					this.crudService.getFilteredEntries(this.apiUrl, { ds_this_ids: ids }).then(reloadedRecords => {
						if( reloadedRecords && reloadedRecords.length > 0 ) {
							console.log('reloaded ' + reloadedRecords.length + ' entries');
							this.listReplaceEntries(reloadedRecords);
						}
					}).catch(err => {
						this.messageService.add({ severity: 'error', summary: 'Druckvorgang', detail: err.message, life: 10000 });
					}).finally(() => {
						this.loading -= 1;
						this.unselectAll();
					});
				});
			} else {
				this.messageService.add({ severity: 'error', summary: 'Keine Auswahl', detail: 'Es wurden keine Gefäße zum Druck markiert', life: 5000 });
			}
		}
	}

	printLabels(markedEntries) {
		// this.messageService.add({ severity: 'info', summary: 'Druckvorgang', detail: 'Ausgewählte Etiketten werden gedruckt', life: 10000 });
		//setTimeout(() => {
		//	this.messageService.add({ severity: 'success', summary: 'Drucken abgeschlossen', detail: 'Ausgewählte Etiketten wurden gedruckt', life: 30000 });
		//}, 10000);
		this.printGefaesse();
	}

	uploadError(e): void {
		this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
	}

	gfAlleAufHaldeStellen(): void {
		if(this.selectedTrashbins && this.selectedTrashbins.length > 0) {
			let batchEntries: any[] = [];
			for( let tb of this.selectedTrashbins ) {
				if( tb && tb['ds_this_id'] && tb['ds_this_id'] > 0 ) {
					batchEntries.push(tb);
				}
			}
			this.unselectAll();
			if( batchEntries.length > 0 ) {
				this._gfAufHaldeStellenBatch(batchEntries);
			}
		}
	}

	gfAufHaldeStellen(): void {
		if( this.selectedEntry && this.selectedEntry.ds_this_id ) {
			this._gfAufHaldeStellenBatch([this.selectedEntry]);
		}
	}

	_gfAufHaldeStellenBatch(entries: Base[]): void {
		if( entries && entries.length > 0 ) {
			let batchEntries: Base[] = [];
			this.loading += 1;
			for( let e of entries) {
				if( e && e.ds_this_id && e.ds_this_id > 0 ) {
					batchEntries.push(e);
				}
			}
			this.loading -= 1;
			if( batchEntries.length > 0 ) {
				this.loading += 1;
				this.crudService.getSpecialObjectId(tbl_objekt_flags.flag_Objekt_ObjektTyp_Halde).then(res => {
					this.heapObjectId = res;
				}).catch(err => {
					err.error.forEach(e => {
						if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
						}
					})
				}).finally(() => {
					this.loading -= 1;
					// dialog öffnen, datum erhalten, umstellen auf this.heapObjectId
					if( this.heapObjectId ) {
						const ref = this.dialogService.open(TblGefaessMoveToHaldeDialogComponent, {
							header: this.translate.instant('HEADERS.GFMOVETO_HEAP_POPUP'),
							width: '70%',
							data: { buttonLabel: 'Stellen' }
						});

						ref.onClose.subscribe((filters) => {
							if (filters) {
								if( filters['GF_HALDE_FROM'] ) {
									let arrayTargets: any[] = [];
									for(let e of batchEntries ) {
										arrayTargets.push({
												gefaess_id: e.ds_this_id,
												objekt_id: this.heapObjectId,
												datum_von: new Date(filters['GF_HALDE_FROM']),
												force: true
											}
										);
									}
									this.confirmationService.confirm({
										message: this.translate.instant('CONFIRMATION.POTENTIAL_CONFLICTS'),
										header: this.translate.instant('CONFIRMATION.CONFIRM'),
										icon: 'pi pi-exclamation-triangle',
										acceptLabel: this.translate.instant('CONFIRMATION.YES'),
										rejectLabel: this.translate.instant('CONFIRMATION.NO'),
										accept: () => {
											this.loading += 1;
											this.crudService.moveGarbageCans(arrayTargets).then(res => {
												this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
												for(let gf of arrayTargets) {
													this.reloadAndReplaceGf(gf.gefaess_id);
												}
											}).catch(err => {
												err.error.forEach(e => {
													if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
														this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
													} else {
														this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
													}
												})
											}).finally(() => {
												this.loading -= 1;
											});
										}
									});
								} else {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 30000 });
								}
							}
						});
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORMSG.HEAP_NOT_FOUND_HEADER'), detail: this.translate.instant('ERRORMSG.HEAP_NOT_FOUND_MESSAGE'), life: 7000 });
					}
				});
			}
		}
	}

	gfAlleAufObjektStellen(): void {
		if(this.selectedTrashbins && this.selectedTrashbins.length > 0) {
			//console.log(this.selectedTrashbins);
			let batchEntries: any[] = [];
			//for( let tb of this.selectedTrashbins ) {
			//	if( tb && tb['ds_this_id'] && tb['ds_this_id'] > 0 ) {
			//		batchEntries.push(tb);
			//	}
			//}
			this.selectedTrashbins.forEach(tb => {
				if( tb && tb['ds_this_id'] && tb['ds_this_id'] > 0 ) {
					batchEntries.push(tb);
					//console.log(tb);
				}
			});
			this.unselectAll();
			if( batchEntries.length > 0 ) {
				this._gfAufObjektStellenBatch(batchEntries);
			}
		}
	}

	gfAufObjektStellen(): void {
		if( this.selectedEntry && this.selectedEntry.ds_this_id ) {
			this._gfAufObjektStellenBatch([this.selectedEntry]);
		}
	}

	_gfAufObjektStellenBatch(entries: Base[]): void {
		if( entries && entries.length > 0 ) {
			//console.log(entries);
			let batchEntries: Base[] = [];
			this.loading += 1;
			entries.forEach(e => {//for( let e of entries) {
				if( e && e.ds_this_id && e.ds_this_id > 0 ) {
					batchEntries.push(e);
				}
			});
			this.loading -= 1;
			if( batchEntries.length > 0 ) {
				//console.log(batchEntries);
				const ref = this.dialogService.open(MoveGarbageCanDialogComponent, {
					header: this.translate.instant('HEADERS.GEFAESS_STELLEN'),
					width: '70%'
				});
		
				ref.onClose.subscribe((target) => {
					if (target) {
						this.loading += 1;
						target.gefaess_id = -1;
						target.force = true;
						let targets: any[] = [];
						batchEntries.forEach(be => {//for(let be of batchEntries) {
							// diese ausgabe hat gezeigt: alle be hatten eine unterschiedliche ds_this_id, dennoch
							// kamen am ende in targets objekte mit jeweils der gleichen gefaess_id heraus, irgendwie durch optimierer gekillt oder so
							//console.log('batchEntries.forEach(be => {...}');
							//console.log(be.ds_this_id);
							//let bTarget = target;
							//bTarget.gefaess_id = be.ds_this_id;
							//console.log('bTarget');
							//console.log(bTarget);
							//targets.push(bTarget);
							targets.push({
								datum_bis : target.datum_bis,
								datum_von: target.datum_von,
								force: true,
								objekt_id: target.objekt_id,
								gefaess_id: be.ds_this_id
							});
						});
						this.loading -= 1;
						//console.log('last station batchEntries:');
						//console.log(batchEntries);
						//console.log('last station targets:');
						//console.log(targets);
						this.confirmationService.confirm({
							message: this.translate.instant('CONFIRMATION.POTENTIAL_CONFLICTS'),
							header: this.translate.instant('CONFIRMATION.CONFIRM'),
							icon: 'pi pi-exclamation-triangle',
							acceptLabel: this.translate.instant('CONFIRMATION.YES'),
							rejectLabel: this.translate.instant('CONFIRMATION.NO'),
							accept: () => {
								this.loading += 1;
								this.crudService.moveGarbageCans(targets).then(res => {
									this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
									//console.log(res);
									for(let gf of targets) {
										this.reloadAndReplaceGf(gf.gefaess_id);
									}
								}).catch(err => {
									err.error.forEach(e => {
										if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
											this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
										} else {
											this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
										}
									})
								}).finally(() => {
									this.loading -= 1;
								});
							}
						});
					}
				});
			}
		}
	}

	gfSperren(): void {
		if( this.selectedEntry && this.selectedEntry.ds_this_id ) {
			this._gfSperrenBatch([this.selectedEntry]);
		}
	}

	gfEntsperren(): void {
		if( this.selectedEntry && this.selectedEntry.ds_this_id ) {
			this._gfEntsperrenBatch([this.selectedEntry]);
		}
	}

	gfAlleSperren(): void {
		if(this.selectedTrashbins && this.selectedTrashbins.length > 0) {
			let batchEntries: any[] = [];
			for( let tb of this.selectedTrashbins ) {
				if( tb && tb['ds_this_id'] && tb['ds_this_id'] > 0 ) {
					batchEntries.push(tb);
				}
			}
			this.unselectAll();
			if( batchEntries.length > 0 ) {
				this._gfSperrenBatch(batchEntries);
			}
		}
	}

	gfAlleEntsperren(): void {
		if(this.selectedTrashbins && this.selectedTrashbins.length > 0) {
			let batchEntries: any[] = [];
			for( let tb of this.selectedTrashbins ) {
				if( tb && tb['ds_this_id'] && tb['ds_this_id'] > 0 ) {
					batchEntries.push(tb);
				}
			}
			this.unselectAll();
			if( batchEntries.length > 0 ) {
				this._gfEntsperrenBatch(batchEntries);
			}
		}
	}

	_gfSperrenBatch(entries: Base[]): void {
		if( entries && entries.length > 0 ) {
			let batchEntries: string[] = [];
			this.loading += 1;
			for( let e of entries) {
				if( e && e['barcode'] && e['barcode'].length > 0 ) {
					batchEntries.push(e['barcode']);
				}
			}
			this.loading -= 1;
			if( batchEntries.length > 0 ) {
				const ref = this.dialogService.open(PopupFromToReasonComponent, {
					header: this.translate.instant('Barcodesperre festlegen'),
					width: '70%'
				});
				
				ref.onClose.subscribe((result) => {
					if (result) {
						this.loading += 1;
						var fromDate = result["fromDate"];
						var toDate = result["toDate"];
						var reason = result["reason"];
						// wenn leer, dann ab heute sperren
						if (fromDate == null) {
							fromDate = new Date();
							fromDate = new Date(
								(fromDate as Date).getFullYear(), (fromDate as Date).getMonth(), (fromDate as Date).getDay(),
								0, 0, 0);
						}
		
						this.crudService.createLockBarcodes(batchEntries, reason, fromDate, toDate, 1).then(res => {
							this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
							for(let gf of entries) {
								this.reloadAndReplaceGf(gf.ds_this_id);
							}
						}).catch(err => {
							err.error.forEach(e => {
								if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
								} else {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
								}
							})
						}).finally(() => {
							this.loading -= 1;
						});
					}
				});
			}
		}
	}

	_gfEntsperrenBatch(entries: Base[]): void {
		if( entries && entries.length > 0 ) {
			let batchEntries: string[] = [];
			this.loading += 1;
			for( let e of entries) {
				if( e && e['barcode'] && e['barcode'].length > 0 ) {
					batchEntries.push(e['barcode']);
				}
			}
			this.loading -= 1;
			if( batchEntries.length > 0 ) {
				const ref = this.dialogService.open(PopupCloseOnReasonAdditionComponent, {
					header: this.translate.instant('Barcodesperre freigeben'),
					width: '70%'
				});
				
				ref.onClose.subscribe((result) => {
					if (result) {
						this.loading += 1;
						var toDate = result["unlockDate"];
						var reason = result["reasonAddition"];
						// wenn leer, dann ab heute sperren
						if (toDate == null) {
							toDate = new Date();
							toDate = new Date(
								(toDate as Date).getFullYear(), (toDate as Date).getMonth(), (toDate as Date).getDay(),
								0, 0, 0);
						}
						
						this.crudService.unlockBarcodes(batchEntries, reason, toDate).then(res => {
							this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
							for(let gf of entries) {
								this.reloadAndReplaceGf(gf.ds_this_id);
							}
						}).catch(err => {
							err.error.forEach(e => {
								if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
								} else {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
								}
							})
						}).finally(() => {
							this.loading -= 1;
						});
					}
				});
			}
		}
	}

	reloadAndReplaceGf(gf_id): void {
		this.loading += 1;
		this.crudService.getGarbageCan(gf_id).then(res => {
			if( res && res.ds_this_id ) {
				this.possibleCols.forEach(c => {
					if (c.type == 'date') {
						//entries.forEach(e => {
							if (res[c.key] != null) {
								res[c.key] = new Date(res[c.key]);
							}
						//});
					}
				});
				this.listReplaceEntry(res);
				this.selectedEntry = res;
			}
		}).catch(() => {
			//
		}).finally(() => {
			this.loading -= 1;
		});
	}
}