import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogService } from '@app/services/log.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
	constructor(
		private logService: LogService
	) { }

	/**
	 * Leitet alle http Anfragen auf https um.
	 * Kann Errors abfangen.
	 *
	 * @param request Http Anfrage
	 * @param next	Http Handler
	 */
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const secureRequest = request.clone({
			url: request.url.replace('http://', 'https://'),
			headers: request.headers.set('Cache-Control', 'no-store')
		});

		return next.handle(secureRequest).pipe(catchError((err: HttpErrorResponse) => {
			let errorCode;
			let errorMessage;
			if (err.error instanceof Array) {
				errorCode = err.error[0].Code || err.status + '';
				errorMessage = err.error[0].Description || err.statusText;
			} else {
				errorCode = err.status + '';
				errorMessage = Object.values(err.error.errors)[0] || err.error.title;
			}
			this.logService.log("Error", 1, errorCode, errorMessage);
			return throwError(err);
		}));
	}
}
