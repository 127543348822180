import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Module, Operation } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { environment } from '@environments/environment';
import * as signalR from '@microsoft/signalr';
import { HubConnection, IHttpConnectionOptions } from '@microsoft/signalr';
import { TranslateService } from '@ngx-translate/core';
import 'chartjs-adapter-moment';
import 'ol/ol.css';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	templateUrl: './upload_identcodesperren.component.html',
	styleUrls: ['./style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class UploadIdentcodesperreFromTextfileComponent {
	createPermission: boolean = false;
	deletePermission: boolean = false;
	headers: any;
	hubConnection: HubConnection;
	hubConnectionId: string;
	loading: number = 0;
	module: Module;
	name: string = '';
	progress: number;
	progressDetails: string;
	progressDoneInfo: string;
	showDone: boolean = false;
	showDoneErrorRunningInfo: boolean = false;
	readPermission: boolean = false;
	showDetails: boolean = true;
	updatePermission: boolean = false;
	fileUploadUrl: string;
	modeReplaceLocks: boolean = false;

	constructor(
		public accountService: AccountService,
		public config: DynamicDialogConfig,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public messageService: MessageService,
		public ref: DynamicDialogRef,
		public router: Router,
		public translate: TranslateService,
	) {
		this.name = 'BHV.HEADERUPLOADSCHWARZELISTEDIALOG';
		this.module = Module.BHV;
	}

	async ngOnInit(): Promise<void> {
		this.loading += 1;

		// default; wird in changeReplaceMode ggf. nochmals überschrieben
		this.fileUploadUrl = `${environment.apiUrl}/TblGefaessSperre/UploadFromTxtFile`;
		
		const options = await this.accountService.getOptions();
		this.headers = options.headers;

		this.createPermission = this.accountService.checkPermissions(this.module, Operation.CREATE);
		this.readPermission = this.accountService.checkPermissions(this.module, Operation.READ);
		this.updatePermission = this.accountService.checkPermissions(this.module, Operation.UPDATE);
		this.deletePermission = this.accountService.checkPermissions(this.module, Operation.DELETE);

		this.connectToHub();

		this.loading -= 1;
	}

	ngOnDestroy(): void {
		this.disconnectFromHub();
	}

	close(): void {
		this.ref.close(true);
	}

	/**
	 * Mit MessageHub verbinden
	 */
	connectToHub(): void {
		this.hubConnection = new signalR.HubConnectionBuilder()
			.withUrl(
				`${environment.apiUrl}/UploadFromTxtFile`,
				{
					accessTokenFactory: () => {
						return window.localStorage.getItem('jwt');
					},
					skipNegotiation: true,
					transport: signalR.HttpTransportType.WebSockets,
				} as IHttpConnectionOptions)
			.configureLogging({
				log: (logLevel, message) => {
					if (logLevel > 1) {
						console.log(formatDate(new Date(), 'MM/dd/yyyy HH:mm:ss', 'de') + ' [' + 0 + '] ' + message);
					}
				}
			})
			.withAutomaticReconnect()
			.build();

		if (this.hubConnection != null) {
			this.hubConnection.on('GetConnectionId', (connectionId) => {
				this.hubConnectionId = connectionId;
			});

			// Verarbeitungsfortschritt empfangen
			this.hubConnection.on('UploadProgress', (success, pass, error, read, sum) => {
				if( sum === 0) {
					// start/init
					this.progressDoneInfo = 'Die Datenverarbeitung wird initialisiert, Datei in Überprüfung...';
				} else if(sum < 0) {
					this.progressDoneInfo = 'Die Datenverarbeitung ist fehlgeschlagen / keine Daten';
					this.showDone = true;
				} else {
					// in verarbeitung...
					this.progressDoneInfo = 'Datenverarbeitung läuft...';
					this.progress = Math.round(100 * read / sum);
					//this.progressDetails = 'Success: ' + success + ' / Pass: ' + pass + ' / Error: ' + error + ' / Read: ' + read + ' / Total: ' + sum;
					this.progressDetails = 'Verarbeitet: ' + read + ' von ' + sum + ' (Erfolgreich: ' + success + ' / Fehler: ' + error + ' / Übersprungen: ' + pass + ')';
					if( read >= sum ) {
						if( error > 0 && pass > 0 ) {
							this.progressDoneInfo = 'Vorgang vollständig abgeschlossen! (es liegen Fehler sowie übersprungene Eingabedaten vor)';
						} else if( error > 0 ) {
							this.progressDoneInfo = 'Vorgang vollständig abgeschlossen! (es sind Fehler aufgetreten)';
						} else if( pass > 0 ) {
							this.progressDoneInfo = 'Vorgang vollständig abgeschlossen! (es wurden Eingabedaten übersprungen)';
						} else {
							this.progressDoneInfo = 'Vorgang vollständig abgeschlossen!';
						}
						this.showDone = true;
						this.loading = 0;
					} else {
						this.loading = 1;
					}
				}
				if( !this.showDone ) {
					if( this.progressDoneInfo.trim().length > 0 ) {
						this.showDoneErrorRunningInfo = true;
					} else {
						this.showDoneErrorRunningInfo = false;
					}
				} else {
					this.showDoneErrorRunningInfo = true;
				}
			});

			this.hubConnection.start().then(() => {
				this.hubConnection.invoke("GetConnectionId");
			}).catch(err => console.error(err));
		}
	}

	/**
	 * Verbindung zum MessageHub trennen
	 */
	disconnectFromHub(): void {
		try {
			this.hubConnection.stop();
		} catch (e) {
			console.log(e);
		}
		return;
	}

	uploadError(e): void {
		this.disconnectFromHub();
		this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
	}

	changeReplaceMode(): void {
		//alert(this.modeReplaceLocks);
		if( this.modeReplaceLocks ) {
			let msg: string = "ACHTUNG:\nHiermit werden ALLE vorhandenen Identcode-Sperren aus der Datenbank ";
			msg += "UNWIDERRUFLICH GELÖSCHT.\n\nWählen Sie diese Option nur, wenn Sie wirklich alle Sperren durch Ihren Import ERSETZEN möchten.";
			alert(msg);
		}
		if( this.modeReplaceLocks )
			this.fileUploadUrl = `${environment.apiUrl}/TblGefaessSperre/UploadFromTxtFileReplaceLocks`;
		else this.fileUploadUrl = `${environment.apiUrl}/TblGefaessSperre/UploadFromTxtFile`;
	}
}