import { Component, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { CreateComponent } from '@app/common/templates/create/create.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { TblGebietCreateComponent } from '@app/views/stammdaten/tbl_gebiet/create/create.component';
import { TblGemeindeCreateComponent } from '@app/views/stammdaten/tbl_gemeinde/create/create.component';
import { TblStrasseCreateComponent } from '@app/views/stammdaten/tbl_strasse/create/create.component';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TblFirmaCreateComponent } from '../../tbl_firma/create/create.component';
import { TblObjektGruppeCreateComponent } from '../../tbl_objekt_gruppe/create/create.component';
import { TblObjektStatusCreateComponent } from '../../tbl_objekt_status/create/create.component';
import { TblObjektTypCreateComponent } from '../../tbl_objekt_typ/create/create.component';

@Component({
	templateUrl: '../../../../common/templates/create/create.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/create/style.scss'],
	providers: [DialogService, MessageService, ConfirmationService]
})
export class TblObjektCreateComponent extends CreateComponent {
	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		@Optional() public config: DynamicDialogConfig,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public dialogService: DialogService,
		public globals: Globals,
		public messageService: MessageService,
		@Optional() public ref: DynamicDialogRef,
		public router: Router,
		public translate: TranslateService,
	) {
		super(accountService, breadcrumbService, config, confirmationService, crudService, dialogService, globals, messageService, ref, router, translate);

		this.apiUrl = 'TblObjekt';
		this.name = 'MENU.OBJEKT';
		this.url = '/' + PATH.OBJEKT;
		this.fieldgroups.push(
			[
				{ type: 'text', key: 'ankey', required: true, width: 400 },
				{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
				{ type: 'text', key: 'bemerkung', required: false, width: 400 },
				{ type: 'text', key: 'barcode', required: false, width: 400 },
			],
			[
				{ type: 'object', key: 'strasse_id', required: false, width: 400, apiUrl: 'TblStrasse/true', createComponent: TblStrasseCreateComponent },
				{ type: 'numeric', key: 'hausnummer1', required: false, width: 400 },
				{ type: 'text', key: 'hauszusatz1', required: false, width: 400 },
				{ type: 'numeric', key: 'hausnummer2', required: false, width: 400 },
				{ type: 'text', key: 'hauszusatz2', required: false, width: 400 },
				{ type: 'text', key: 'zusatz_text', required: false, width: 400 },
				{ type: 'object', key: 'gemeinde_id', required: false, width: 400, apiUrl: 'TblGemeinde', createComponent: TblGemeindeCreateComponent },
				{ type: 'object', key: 'gebiet_id', required: false, width: 400, apiUrl: 'TblGebiet', createComponent: TblGebietCreateComponent },
			],
			[
				{ type: 'object', key: 'entsorger_id', required: false, width: 400, apiUrl: 'TblFirma', createComponent: TblFirmaCreateComponent },
				{ type: 'object', key: 'gruppe_id', required: false, width: 400, apiUrl: 'TblObjektGruppe', createComponent: TblObjektGruppeCreateComponent },
				{ type: 'object', key: 'status_id', required: false, width: 400, apiUrl: 'TblObjektStatus', createComponent: TblObjektStatusCreateComponent },
				{ type: 'object', key: 'typ_id', required: false, width: 400, apiUrl: 'TblObjektTyp', createComponent: TblObjektTypCreateComponent },
			]
		);
		this.breadcrumbService.setItems([
			{ label: 'MENU.BHV' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.CREATE', routerLink: [this.url + '/' + PATH.CREATE] }
		]);
	}
}