import { Directive, Host, Optional, Self } from '@angular/core';
import { MultiSelect } from 'primeng/multiselect';

@Directive({
	selector: '[multiSelectDirective]'
})
export class MultiSelectDirective {
	constructor(@Host() @Self() @Optional() public host: MultiSelect) {
		if (this.isTouchDevice()) {
			host.onWindowResize = () => { }
		}
	}

	/**
	 * Überprüft, ob die Seite auf einem TouchDevice geöffnet wurde
	 * 
	 * @returns boolean, ob die Seite auf einem TouchDevice geöffnet wurde
	 */
	isTouchDevice(): boolean {
		return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0));
	}
}