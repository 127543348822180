import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { ListComponent } from '@app/common/templates/list/list.component';
import { Module } from '@app/models/permission';
import { Policy } from '@app/models/policy';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { ExportService } from '@app/services/export.service';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { MaintenanceDialog } from './maintenanceDialog/maintenanceDialog.component';

@Component({
	templateUrl: './list.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/list/style.scss'],
	providers: [DialogService, MessageService, ConfirmationService]
})
export class TblFahrzeugListComponent extends ListComponent {
	isVisy: boolean = false;
	url_detail = PATH.DETAIL;
	url_edit = PATH.EDIT;

	private allIncludingInactive: boolean = false;

	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public changeDetectorRef: ChangeDetectorRef,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public dialogService: DialogService,
		public elRef: ElementRef,
		public exportService: ExportService,
		public globals: Globals,
		public messageService: MessageService,
		public router: Router,
		public settingsService: SettingsService,
		public translate: TranslateService
	) {
		super(accountService, breadcrumbService, changeDetectorRef, confirmationService, crudService, elRef, exportService, globals, messageService, router, settingsService, translate);

		this.apiUrl = 'TblFahrzeug';
		this.name = 'MENU.FAHRZEUGE';
		this.url = '/' + PATH.FAHRZEUG;
		this.module = Module.Masterdata;

		this.possibleCols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'text', key: 'bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'kennzeichen', required: true, width: 400 },
			{ type: 'text', key: 'normalized_kennzeichen', required: true, width: 400 },
			{ type: 'text', key: 'logbox_serial', required: false, width: 400 },
			{ type: 'text', key: 'fahrgestellnummer', required: true, width: 400 },
		];
		this.cols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
			{ type: 'text', key: 'kennzeichen', required: true, width: 400 },
			{ type: 'text', key: 'logbox_serial', required: false, width: 400 },
			{ type: 'text', key: 'fahrgestellnummer', required: true, width: 400 },
		];
		this.breadcrumbService.setItems([
			{ label: 'MENU.STAMMDATEN' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.LIST', routerLink: [this.url + '/' + PATH.LIST] }
		]);

		this.isVisy = this.accountService.isAuthorized(Policy.ViSyUser);

		if (this.isVisy) {
			this.possibleCols.push(
				{ type: 'text', key: 'FREMD_firma_bezeichnung', required: false, width: 400 },
				{ type: 'text', key: 'lexware_projekt_nummer', required: false, width: 400 },
				{ type: 'text', key: 'bemerkung', required: false, width: 400 },
				{ type: 'date', key: 'abholung', required: false, width: 400 },
				{ type: 'date', key: 'anlieferung', required: false, width: 400 },
				{ type: 'date', key: 'aufbau_start', required: false, width: 400 },
				{ type: 'date', key: 'aufbau_ende', required: false, width: 400 },
				{ type: 'text', key: 'aufbau_ort', required: false, width: 400 },
				{ type: 'boolean', key: 'vorbereitung_set_1', required: false, width: 400 },
				{ type: 'boolean', key: 'vorbereitung_set_2', required: false, width: 400 },
				{ type: 'boolean', key: 'vorbereitung_set_3', required: false, width: 400 },
				{ type: 'boolean', key: 'datenaufzeichnung', required: false, width: 400 },
				{ type: 'boolean', key: 'rueckfahrkataster', required: false, width: 400 },
				{ type: 'date', key: 'rueckfahrkataster_bis', required: false, width: 400 },
				{ type: 'string', key: 'simkartennummer', required: false, width: 400 },
				{ type: 'date', key: 'simkarte_bis', required: false, width: 400 },
				{ type: 'boolean', key: 'rueckfahrassistent', required: false, width: 400 },
				{ type: 'boolean', key: 'unterlaufschutz_seitenlader', required: false, width: 400 },
				{ type: 'boolean', key: 'abbiege_rechts_180_grad', required: false, width: 400 },
				{ type: 'boolean', key: 'abbiege_rechts_3d', required: false, width: 400 },
				{ type: 'boolean', key: 'abbiege_rechts_kamera', required: false, width: 400 },
				{ type: 'boolean', key: 'abbiege_rechts_radar', required: false, width: 400 },
				{ type: 'boolean', key: 'abbiege_links_3d', required: false, width: 400 },
				{ type: 'boolean', key: 'abbiege_links_kamera', required: false, width: 400 },
				{ type: 'boolean', key: 'abbiege_links_radar', required: false, width: 400 },
				{ type: 'boolean', key: 'canbus_fms', required: false, width: 400 },
				{ type: 'boolean', key: 'canbus_tail', required: false, width: 400 },
				{ type: 'boolean', key: 'canbus_faun', required: false, width: 400 },
				{ type: 'boolean', key: 'canbus_hs', required: false, width: 400 },
				{ type: 'boolean', key: 'canbus_radar', required: false, width: 400 },
				{ type: 'numeric', key: 'info_sets', required: false, width: 400 },
				{ type: 'numeric', key: 'info_io', required: false, width: 400 },
				{ type: 'numeric', key: 'info_canbus', required: false, width: 400 },
				{ type: 'numeric', key: 'info_features', required: false, width: 400 },
			);

			this.cols.push(
				{ type: 'text', key: 'FREMD_firma_bezeichnung', required: false, width: 400 },
				{ type: 'text', key: 'lexware_projekt_nummer', required: false, width: 400 },
				{ type: 'text', key: 'aufbau_ort', required: false, width: 400 },
				{ type: 'date', key: 'anlieferung', required: false, width: 400 },
				{ type: 'boolean', key: 'vorbereitung_set_1', required: false, width: 400 },
				{ type: 'boolean', key: 'vorbereitung_set_2', required: false, width: 400 },
				{ type: 'boolean', key: 'vorbereitung_set_3', required: false, width: 400 },
				{ type: 'boolean', key: 'datenaufzeichnung', required: false, width: 400 },
				{ type: 'boolean', key: 'rueckfahrkataster', required: false, width: 400 },
				{ type: 'text', key: 'bemerkung', required: false, width: 400 },
			);
		}
	}

	maintenance() {
		const ref = this.dialogService.open(MaintenanceDialog, {
			header: this.translate.instant('VEHICLES.MAINTENANCE'),
			width: '70%',
			data: this.entries
		});
	}

	switchArchivierte() {
		this.allIncludingInactive = !this.allIncludingInactive;
		this.getAllEntries();
	}

	// override
	getAllEntries() {
		this.loading += 1;
		this.messageService.clear('refresh');
		if (this.allIncludingInactive) {
			this.crudService.getAllVehiclesIncludingInactive().then(res => {
				this.processEntries(res);
				this.resizeTableWidthFromContent(false);
			}).catch(err => {
				err.error.forEach(e => {
					this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
				})
			}).finally(() => {
				this.loading -= 1;
			});
		} else {
			this.crudService.getAllVehicles().then(res => {
				this.processEntries(res);
				this.resizeTableWidthFromContent(false);
			}).catch(err => {
				err.error.forEach(e => {
					this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
				})
			}).finally(() => {
				this.loading -= 1;
			});
		}
	}
}
