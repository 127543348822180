import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { ListExComponent } from '@app/common/templates/list/listEx.component';
import { Module } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { ExportService } from '@app/services/export.service';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { TblAuftragFilterDialogComponent } from './filter_dialog/filter_dialog.component';
import { tbl_auftrag_flags } from '@app/models/tbl_auftrag_flags';
import { TblAuftragGfAbmeldungDialogComponent } from './gefaess_abmeldung_dialog/gefaess_abmeldung_dialog.component';
import { TblAuftragStatusDialogComponent } from './auftrag_status_dialog/auftrag_status_dialog.component';
import { tbl_objekt_flags } from '@app/models/tbl_objekt_flags';

@Component({
	//templateUrl: '../../../../common/templates/list/listEx.component.html',
	templateUrl: './list.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/list/style.scss'],
	providers: [MessageService, ConfirmationService, DialogService]
})
export class TblAuftragListComponent extends ListExComponent {
	gefaesseList: any[] = null;
	heapObjectId: number = null;
	selectedTasks = [];

	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public changeDetectorRef: ChangeDetectorRef,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public dialogService: DialogService,
		public elRef: ElementRef,
		public exportService: ExportService,
		public globals: Globals,
		public messageService: MessageService,
		public router: Router,
		public settingsService: SettingsService,
		public translate: TranslateService
	) {
		super(accountService, breadcrumbService, changeDetectorRef, confirmationService, crudService, elRef, exportService, globals, messageService, router, settingsService, translate);

		this.apiUrl = 'TblAuftrag';
		this.name = 'MENU.AUFTRAG';
		this.url = '/' + PATH.AUFTRAG;
		this.module = Module.BHV;

		this.possibleCols = [
			{ sub: "AUFTRAG", type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ sub: "AUFTRAG", type: 'text', key: 'typ_text', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'FREMD_auftrag_status_ankey', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'FREMD_auftrag_status_bezeichnung', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'date', key: 'datum_geplant', required: false, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'ankey', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'bezeichnung', required: false, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'kunde_skey', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'kunde_name_1', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'kunde_name_2', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'kunde_strasse', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'kunde_hausnummer', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'kunde_plz', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'kunde_ort', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'objekt_skey', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'numeric', key: 'objekt_ort_postleitzahl', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'objekt_ort_name', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'FREMD_objekt_bezeichnung', required: false, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'FREMD_strasse_name', required: false, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'FREMD_objekt_hausnummer1', required: false, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'FREMD_objekt_hauszusatz1', required: false, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'FREMD_objekt_hausnummer2', required: false, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'FREMD_objekt_hauszusatz2', required: false, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'gefaess_1_skey', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'gefaess_1_barcode', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'import_rawline', required: false, width: 600 },
		];

		this.cols = [
			{ sub: "AUFTRAG", type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ sub: "AUFTRAG", type: 'text', key: 'typ_text', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'FREMD_auftrag_status_ankey', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'FREMD_auftrag_status_bezeichnung', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'date', key: 'datum_geplant', required: false, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'ankey', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'bezeichnung', required: false, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'kunde_skey', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'kunde_name_1', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'kunde_name_2', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'kunde_strasse', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'kunde_hausnummer', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'kunde_plz', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'kunde_ort', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'objekt_skey', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'numeric', key: 'objekt_ort_postleitzahl', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'objekt_ort_name', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'FREMD_objekt_bezeichnung', required: false, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'FREMD_strasse_name', required: false, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'FREMD_objekt_hausnummer1', required: false, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'FREMD_objekt_hauszusatz1', required: false, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'FREMD_objekt_hausnummer2', required: false, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'FREMD_objekt_hauszusatz2', required: false, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'gefaess_1_skey', required: true, width: 400 },
			{ sub: "AUFTRAG", type: 'text', key: 'gefaess_1_barcode', required: true, width: 400 }
		];

		this.breadcrumbService.setItems([
			{ label: 'MENU.BHV' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.LIST', routerLink: [this.url + '/' + PATH.LIST] }
		]);

		this.hasFilterDialog = true;
	}

	getAllEntries(): void {
		this.unselectAll();
		super.getAllEntries();
	}

	selectAll(): void {
		this.unselectAll();
		this.entries.forEach(e => {
			this.selectedTasks.push(e);
		});
	}

	unselectAll(): void {
		this.selectedTasks = [];
	}

	handleContextMenuSelect(event) {
		// das ist dann die mit rechts angeklickte datarow (hat nichts mit der selektion aus dem multiselect zu tun)
		this.selectedEntry = event.data;
	}

	handleRowSelection(event) {
		// und das hier ist die gerade neu selektierte zeile aus dem multiselect
		// unbenutzt
	}

	openFilterDialog() {
		let merkmaleData: any [] = [];
		merkmaleData.push({
			id: (1<<0),
			bezeichnung: 'vorgemerkte Gefäßabholung'
		});
		merkmaleData.push({
			id: (1<<1),
			bezeichnung: 'aktualisierte Kundenstammdaten'
		});
		merkmaleData.push({
			id: (1<<2),
			bezeichnung: 'aktualisierte Objektdetails'
		});

		const ref = this.dialogService.open(TblAuftragFilterDialogComponent, {
			header: this.translate.instant('Filter'),
			width: '70%',
			data: { manualData1: merkmaleData }
		});

		ref.onClose.subscribe((filters) => {
			if (filters) {
				this.getFilteredEntries(filters);
			}
		});
	}

	getFilteredEntries(filters) {
		this.entries = [];
		this.unselectAll();
		this.loading += 1;
		this.loadFilters = filters;
		this.crudService.getFilteredEntries(this.apiUrl, filters).then(res => {
			this.processEntries(res);
			this.resizeTableWidth();
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.unselectAll();
			this.loading -= 1;
		});
	}

	async ngOnInit(): Promise<void> {
		super.ngOnInit();
		this.loading += 1;

		//this.complementContextMenu();

		this.loading -= 1;
	}

	// override
	initContextMenu(): void {
		this.translate.get('init').subscribe((text: string) => {
			this.contextMenu = [
				// default
				{ label: this.translate.instant('CONTEXT_MENU.CREATE'), icon: 'pi pi-fw pi-plus', command: () => this.create() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN'), icon: 'pi pi-fw pi-search', command: () => this.detail() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_TAB'), icon: 'pi pi-fw pi-search', command: () => this.detail('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_WINDOW'), icon: 'pi pi-fw pi-search', command: () => this.detail('window') },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT'), icon: 'pi pi-fw pi-pencil', command: () => this.edit() },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT_TAB'), icon: 'pi pi-fw pi-pencil', command: () => this.edit('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT_WINDOW'), icon: 'pi pi-fw pi-pencil', command: () => this.edit('window') },
				{ label: this.translate.instant('CONTEXT_MENU.DELETE'), icon: 'pi pi-fw pi-trash', command: () => this.delete() },
				{ label: this.translate.instant('CONTEXT_MENU.RESIZE'), icon: 'pi pi-fw pi-arrows-h', command: () => this.resizeTableWidthFromContent(true) },
				// custom
				{ separator: true },
				{ label: this.translate.instant('CONTEXT_MENU.TASK_SHOW_CONNECTED_CUSTOMER'), icon: 'fa-solid fa-arrow-right', command: () => this.openCustomer() },
				{ label: this.translate.instant('CONTEXT_MENU.TASK_SHOW_CONNECTED_OBJECT'), icon: 'fa-solid fa-arrow-right', command: () => this.openObject() },
				{ label: this.translate.instant('CONTEXT_MENU.TASK_SHOW_CONNECTED_TRASHBIN'), icon: 'fa-solid fa-arrow-right', command: () => this.openTrashbin() },
				{ separator: true },
				{ label: this.translate.instant('CONTEXT_MENU.TASK_UNSET_TRASHBINS'), icon: 'fa-solid fa-trash', command: () => this.openGefaessAbmeldenPopup() },
				{ separator: true },
				{ label: this.translate.instant('CONTEXT_MENU.TASK_SET_TASK_STATE'), icon: 'fa-solid fa-check', command: () => this.setEntryStatusCM() },
				{ label: this.translate.instant('CONTEXT_MENU.TASK_SET_TASKSELECTION_STATE'), icon: 'fa-solid fa-check-double', command: () => this.setEntriesSelectionStatusCM() },
				{ separator: true },
				{ label: this.translate.instant('CONTEXT_MENU.SELECT_ALL'), icon: 'pi pi-fw pi-check-square', command: () => this.selectAll() },
				{ label: this.translate.instant('CONTEXT_MENU.UNSELECT_ALL'), icon: 'pi pi-fw pi-stop', command: () => this.unselectAll() },
				//
			];
			this.possibleCols.forEach(c => {
				if(c.sub)
					c.label = this.translate.instant('HEADERS.' + c.sub + '.' + c.key);
				else c.label = this.translate.instant('HEADERS.' + c.key);
			});
		});
	}

	//complementContextMenu(): void {
	//	try {
	//		this.loading += 1;
	//		this.translate.get('init').subscribe((text: string) => {
	//			this.contextMenu.push(
	//				{ separator: true }
	//			);
	//			this.contextMenu.push(
	//				{ label: this.translate.instant('CONTEXT_MENU.TASK_SHOW_CONNECTED_CUSTOMER'), icon: 'fa-solid fa-arrow-right', command: () => this.openCustomer() }
	//			);
	//			this.contextMenu.push(
	//				{ label: this.translate.instant('CONTEXT_MENU.TASK_SHOW_CONNECTED_OBJECT'), icon: 'fa-solid fa-arrow-right', command: () => this.openObject() }
	//			);
	//			this.contextMenu.push(
	//				{ label: this.translate.instant('CONTEXT_MENU.TASK_SHOW_CONNECTED_TRASHBIN'), icon: 'fa-solid fa-arrow-right', command: () => this.openTrashbin() }
	//			);
	//			
	//			this.contextMenu.push(
	//				{ separator: true }
	//			);
	//			
	//			this.contextMenu.push(
	//				{ label: this.translate.instant('CONTEXT_MENU.TASK_UNSET_TRASHBINS'), icon: 'fa-solid fa-trash', command: () => this.openGefaessAbmeldenPopup() }
	//			);
	//
	//			this.contextMenu.push(
	//				{ separator: true }
	//			);
	//
	//			this.contextMenu.push(
	//				{ label: this.translate.instant('CONTEXT_MENU.TASK_SET_TASK_STATE'), icon: 'fa-solid fa-check', command: () => this.setEntryStatusCM() }
	//			);
	//			
	//			this.contextMenu.push(
	//				{ label: this.translate.instant('CONTEXT_MENU.TASK_SET_TASKSELECTION_STATE'), icon: 'fa-solid fa-check-double', command: () => this.setEntriesSelectionStatusCM() }
	//			);
	//			
	//			this.contextMenu.push(
	//				{ separator: true }
	//			);
	//			this.contextMenu.push(
	//				{ label: this.translate.instant('CONTEXT_MENU.SELECT_ALL'), icon: 'pi pi-fw pi-check-square', command: () => this.selectAll() }
	//			);
	//			this.contextMenu.push(
	//				{ label: this.translate.instant('CONTEXT_MENU.UNSELECT_ALL'), icon: 'pi pi-fw pi-stop', command: () => this.unselectAll() }
	//			);
	//		});
	//	}
	//	catch {}
	//	finally {
	//		this.loading -= 1;
	//	}
	//}

	//handleContextMenuSelect(event) {
	//	// das ist dann die mit rechts angeklickte datarow (hat nichts mit der selektion aus dem multiselect zu tun)
	//	this.selectedEntry = event.data;
	//}

	openCustomer(): void {
		try {
			this.loading += 1;
			if( this.selectedEntry ) {
				let kunde_id = this.selectedEntry['kunde_id'];
				let kunde_skey = this.selectedEntry['kunde_skey'];
				if( kunde_id ) {
					this.openCustomerById(kunde_id);
				} else if( kunde_skey ) {
					this.openCustomerBySkey(kunde_skey);
				} else {
					this.messageService.add({ severity: 'info', summary: 'Information ', detail: 'Keine Kundendaten verknüpft.', life: 2000 });
				}
			}
		}
		catch {}
		finally {
			this.loading -= 1;
		}
	}

	openObject(): void {
		try {
			this.loading += 1;
			if( this.selectedEntry ) {
				let objekt_id = this.selectedEntry['objekt_id'];
				let objekt_skey = this.selectedEntry['objekt_skey'];
				if( objekt_id ) {
					this.openObjectById(objekt_id);
				} else if( objekt_skey ) {
					this.openObjectBySkey(objekt_skey);
				} else {
					this.messageService.add({ severity: 'info', summary: 'Information ', detail: 'Keine Objektdaten verknüpft.', life: 2000 });
				}
			}
		}
		catch {}
		finally {
			this.loading -= 1;
		}
	}

	openTrashbin(): void {
		try {
			this.loading += 1;
			if( this.selectedEntry ) {
				let gefaess_id = this.selectedEntry['gefaess_1_id'];
				let gefaess_skey = this.selectedEntry['gefaess_1_skey'];
				if( gefaess_id ) {
					this.openTrashbinById(gefaess_id);
				} else if( gefaess_skey ) {
					this.openTrashbinBySkey(gefaess_skey);
				} else {
					this.messageService.add({ severity: 'info', summary: 'Information ', detail: 'Keine Gefäßdaten verknüpft.', life: 2000 });
				}
			}
		}
		catch {}
		finally {
			this.loading -= 1;
		}
	}

	openCustomerBySkey(kunde_skey): void {
		if( kunde_skey ) {
			let skey: string = kunde_skey;
			skey = skey.trim();
			let kundeData = null;
			let errorMessage = false;
			if( skey.length > 0 ) {
				this.crudService.getClientByExactAnkey(kunde_skey).then(res => {
					kundeData = res;
				}).catch(err => {
					err.error.forEach(e => {
						errorMessage = true;
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				}).finally(() => {
					if( kundeData && kundeData.ds_this_id ) {
						this.openCustomerById(kundeData.ds_this_id);
					} else {
						if( !errorMessage ) {
							this.messageService.add({ severity: 'error', summary: 'Error', detail: 'customer not found', life: 5000 });
						}
					}
				});
			}
		}
	}

	openCustomerById(kunde_id): void {
		let kunde_url = '/' + PATH.KUNDE;
		if( kunde_id && kunde_id > 0 ) {
			window.open('/#/' + kunde_url + '/' + PATH.DETAIL + '/' + kunde_id);
		}
	}

	openObjectBySkey(objekt_skey): void {
		if( objekt_skey ) {
			let skey: string = objekt_skey;
			skey = skey.trim();
			let objektData = null;
			let errorMessage = false;
			if( skey.length > 0 ) {
				this.crudService.getObjectByExactAnkey(objekt_skey).then(res => {
					objektData = res;
				}).catch(err => {
					err.error.forEach(e => {
						errorMessage = true;
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				}).finally(() => {
					if( objektData && objektData.ds_this_id ) {
						this.openObjectById(objektData.ds_this_id);
					} else {
						if( !errorMessage ) {
							this.messageService.add({ severity: 'error', summary: 'Error', detail: 'object not found', life: 5000 });
						}
					}
				});
			}
		}
	}

	openObjectById(objekt_id): void {
		let objekt_url = '/' + PATH.OBJEKT;
		if( objekt_id && objekt_id > 0 ) {
			window.open('/#/' + objekt_url + '/' + PATH.DETAIL + '/' + objekt_id);
		}
	}

	openTrashbinBySkey(gefaess_skey): void {
		if( gefaess_skey ) {
			let skey: string = gefaess_skey;
			skey = skey.trim();
			let gefaessData = null;
			let errorMessage = false;
			if( skey.length > 0 ) {
				this.crudService.getGarbageCanByExactAnkey(gefaess_skey).then(res => {
					gefaessData = res;
				}).catch(err => {
					err.error.forEach(e => {
						errorMessage = true;
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				}).finally(() => {
					if( gefaessData && gefaessData.ds_this_id ) {
						this.openObjectById(gefaessData.ds_this_id);
					} else {
						if( !errorMessage ) {
							this.messageService.add({ severity: 'error', summary: 'Error', detail: 'trashbin not found', life: 5000 });
						}
					}
				});
			}
		}
	}

	openTrashbinById(gefaess_id): void {
		let gefaess_url = '/' + PATH.GEFAESS;
		if( gefaess_id && gefaess_id > 0 ) {
			window.open('/#/' + gefaess_url + '/' + PATH.DETAIL + '/' + gefaess_id);
		}
	}

	async setEntryStatusCM() {
		if( this.selectedEntry ) {
			this.openSetStatusPopup();
		}
	}

	async setEntriesSelectionStatusCM() {
		if(this.selectedTasks && this.selectedTasks.length > 0) {
			this.openSetStatusPopupMultiSelection();
		}
	}

	async setEntryStatusDone(entry): Promise<any> {
		return this.crudService.setTaskStatus(entry['ds_this_id'], 1);
	}

	async setEntryStatusById(entry, state_id): Promise<any> {
		return this.crudService.setTaskStatus(entry['ds_this_id'], state_id);
	}

	openSetStatusPopup() {
		const ref = this.dialogService.open(TblAuftragStatusDialogComponent, {
			header: this.translate.instant('HEADERS.TASK_STATE_POPUP'),
			width: '70%',
			data: { buttonLabel: this.translate.instant('BUTTONS.COMFIRM_SELECTION') }
		});

		ref.onClose.subscribe((filters) => {
			if (filters && filters['TASK_PICKUP_STATE']) {
				//console.log('selektierter Auftragsstatus:');
				//console.log(filters);
				//console.log(filters['TASK_PICKUP_STATE']);
				
				this.setEntryStatusById(this.selectedEntry, filters['TASK_PICKUP_STATE']).then(res => {
					this.listReplaceEntry(res);
				}).catch(() => {
					//
				}).finally(() => {
					this.unselectAll();
				});
			}
		});
	}

	openSetStatusPopupMultiSelection() {
		const ref = this.dialogService.open(TblAuftragStatusDialogComponent, {
			header: this.translate.instant('HEADERS.TASK_STATE_POPUP'),
			width: '70%',
			data: { buttonLabel: this.translate.instant('BUTTONS.COMFIRM_SELECTION') }
		});

		ref.onClose.subscribe((filters) => {
			if (filters && filters['TASK_PICKUP_STATE']) {
				try {
					if(this.selectedTasks && this.selectedTasks.length > 0) {
						let copySelection = [...this.selectedTasks];
						this.unselectAll();
						copySelection.forEach(e => {
							this.setEntryStatusById(e, filters['TASK_PICKUP_STATE']).then(res => {
								this.listReplaceEntry(res);
							}).catch(() => {
								//
							}).finally(() => {
								//
							});
						});
					}
				} catch {
					//
				}
				finally {
					//
				}
			}
		});
	}

	openGefaessAbmeldenPopup() {
		this.unselectAll();
		try {
			this.gefaesseList = [];
			if( this.selectedEntry && this.selectedEntry['flags'] ) {
				let flags: number = this.selectedEntry['flags'];
				if( (flags & tbl_auftrag_flags.flag_Auftrag_Vorgemerkt_Gefaessabmeldung) != 0 ) {
					this.loading += 1;
					this.crudService.getSpecialObjectId(tbl_objekt_flags.flag_Objekt_ObjektTyp_Halde).then(res => {
						this.heapObjectId = res;
					}).catch(err => {
						err.error.forEach(e => {
							if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
							} else {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
							}
						})
					}).finally(() => {
						this.loading -= 1;
						let typ_skey: string = this.selectedEntry['gefaess_1_typ_skey'];
						let dateTime: number = this.selectedEntry['jd_geplant'];
						let objekt_skey: string = this.selectedEntry['objekt_skey'];
						//let entry_objekt_id: number = this.selectedEntry['objekt_id']; // unused
						if( this.heapObjectId && dateTime && dateTime > 0 && typ_skey && typ_skey.trim().length > 0 && objekt_skey && objekt_skey.trim().length > 0 ) {
							this.loading += 1;

							this.crudService.getGarbageCansByScheduledPickup(typ_skey.trim(), objekt_skey.trim(), dateTime).then(res => {
								this.gefaesseList = res;
								if( this.gefaesseList && this.gefaesseList.length > 0 ) {
									const ref = this.dialogService.open(TblAuftragGfAbmeldungDialogComponent, {
										header: this.translate.instant('HEADERS.GFAUSWAHL_ABMELDUNG_POPUP'),
										width: '70%',
										data: { manualData1: this.gefaesseList, buttonLabel: this.translate.instant('BUTTONS.COMFIRM_SELECTION') }
										// aus: https://stackoverflow.com/questions/54490357/primeng-dialog-service-passing-data-to-dialog-component
									});

									ref.onClose.subscribe((filters) => {
										if (filters) {
											// jetzt kann über filters['GF_PICKUP_KANDIDATEN'] abgemeldet werden
											if( filters['GF_PICKUP_KANDIDATEN'] && (filters['GF_PICKUP_KANDIDATEN'].length > 0) ) {
												let countResult: number = 0;
												let countError: number = filters['GF_PICKUP_KANDIDATEN'].length;
												let paramsGefaesse: any[] = [];
												filters['GF_PICKUP_KANDIDATEN'].forEach(gf_id => {
													paramsGefaesse.push({
														gefaess_id: gf_id,
														objekt_id: this.heapObjectId, // Halde, und nicht entry_objekt_id, weil da steht es ja jetzt gerade
														datum_von_jd: dateTime,
														force: true
													});
												});

												this.loading += 1;
												this.crudService.moveGarbageCans(paramsGefaesse).then(res => {
													if(res) {
														res.forEach(gf_id_res => {
															filters['GF_PICKUP_KANDIDATEN'].forEach(gf_id_given => {
																if( gf_id_res === gf_id_given ) {
																	countResult += 1;
																}
															});
														});
														countError -= countResult;
														if( countResult < 1 && countError > 0 ) {
															// nur Fehler, rote Meldung
															// TODO parametrierte message mit countValues über translation service übersetzen
															let strMessage: string = 'Es wurden keine Gefäße abgezogen, Fehler bei ' + countError + '. Auftrag dennoch als erledigt markieren?';
															this.confirmationService.confirm({
																message: strMessage,
																header: this.translate.instant('MESSAGES.ERROR'),
																icon: 'pi pi-exclamation-triangle',
																acceptLabel: this.translate.instant('CONFIRMATION.YES'),
																rejectLabel: this.translate.instant('CONFIRMATION.NO'),
																accept: () => {
																	this.setEntryStatusDone(this.selectedEntry).then(res => {
																		this.listReplaceEntry(res);
																	}).catch(() => {
																		//
																	}).finally(() => {
																		//
																	});
																}
															});
														} else if(countResult > 0 && countError > 0) {
															// Erfolgreiche Gestellungen und Fehler beim Stellen, gelbe Meldung mit Rückfrage ob trotzdem als erledigt markieren
															// TODO parametrierte message mit countValues über translation service übersetzen
															let strMessage: string = 'Es wurden ' + countResult + ' Gefäße erfolgreich auf die Halde gestellt, Fehler bei ' + countError + '. Auftrag dennoch als erledigt markieren?';
															this.confirmationService.confirm({
																message: strMessage,
																header: this.translate.instant('MESSAGES.ERROR'),
																icon: 'pi pi-exclamation-triangle',
																acceptLabel: this.translate.instant('CONFIRMATION.YES'),
																rejectLabel: this.translate.instant('CONFIRMATION.NO'),
																accept: () => {
																	this.setEntryStatusDone(this.selectedEntry).then(res => {
																		this.listReplaceEntry(res);
																	}).catch(() => {
																		//
																	}).finally(() => {
																		//
																	});
																}
															});
														} else if(countResult > 0) {
															// alle erfolgreich gestellt, grüne Meldung und Frage ob als erledigt markieren
															// TODO parametrierte message mit countValues über translation service übersetzen
															let strMessage: string = '';
															if( countResult == 1 ) {
																strMessage = 'Das Gefäß wurde erfolgreich auf die Halde gestellt. Auftrag jetzt als erledigt markieren?';
															} else {
																strMessage = 'Es wurden alle ' + countResult + ' Gefäße erfolgreich auf die Halde gestellt. Auftrag jetzt als erledigt markieren?';
															}
															this.confirmationService.confirm({
																message: strMessage,
																header: this.translate.instant('MESSAGES.ERROR'),
																icon: 'pi pi-exclamation-triangle',
																acceptLabel: this.translate.instant('CONFIRMATION.YES'),
																rejectLabel: this.translate.instant('CONFIRMATION.NO'),
																accept: () => {
																	this.setEntryStatusDone(this.selectedEntry).then(res => {
																		this.listReplaceEntry(res);
																	}).catch(() => {
																		//
																	}).finally(() => {
																		//
																	});
																}
															});
														}
													}
												}).catch(() => {
													//
												}).finally(() => {
													this.loading -= 1;
												});
											}
										}
									});
								} else {
									this.messageService.add({ severity: 'info', summary: 'Info ', detail: this.translate.instant('MESSAGES.NO_SELECTION_DATA'), life: 7000 });
								}
								//else {
								//	console.log('found NO candidate entries');
								//}
							}).catch(err => {
								err.error.forEach(e => {
									this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
								})
							}).finally(() => {
								this.loading -= 1;
							});
						} else {
							if( !this.heapObjectId ) {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORMSG.HEAP_NOT_FOUND_HEADER'), detail: this.translate.instant('ERRORMSG.HEAP_NOT_FOUND_MESSAGE'), life: 7000 });
							}
						}
					});
				}
			}
		} catch {}
	}
}
