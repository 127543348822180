import { Base } from "@models/base";

export interface tbl_fahrtbericht_abschnitt_typ extends Base {
}

export const colors = {
	0: '32,32,32',
	1: '205,250,250',
	2: '205,250,250',
	3: '205,250,205',
	4: '250,250,225',
	5: '250,250,225',
	6: '225,205,205',
	7: '225,205,205',
	8: '225,205,205',
	9: '250,250,205',
	10: '250,205,205',
	11: '250,205,250',
	12: '250,205,205',
	13: '250,205,205',
	14: '0,0,0',
	15: '0,0,255'
}

// in HEX, für Tracks
export const colorsHex = {
	0: '#202020',
	1: '#CDFAFA',
	2: '#CDFAFA',
	3: '#CDFACD',
	4: '#FAFAE1',
	5: '#FAFAE1',
	6: '#E1CDCD',
	7: '#E1CDCD',
	8: '#E1CDCD',
	9: '#FAFACD',
	10: '#FACDCD',
	11: '#FACDFA',
	12: '#FACDCD',
	13: '#FACDCD',
	14: '#000000',
	15: '#0000FF'
}
