import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import PATH from '@assets/routes/routes.json';
import { AppMainComponent } from '@components/app.main.component';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '@services/account.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
	templateUrl: './changepassword.component.html',
	styleUrls: ['../style.scss'],
	providers: [MessageService, ConfirmationService],
})
export class ChangePasswordComponent implements OnInit {
	loading = false;
	newPassword1: string;
	newPassword2: string;
	oldPassword: string;
	returnUrl: string;

	constructor(
		private accountService: AccountService,
		public app: AppMainComponent,
		private breadcrumbService: BreadcrumbService,
		private messageService: MessageService,
		private route: ActivatedRoute,
		private router: Router,
		public translate: TranslateService,
	) {
		this.breadcrumbService.setItems([
			{ label: 'MENU.EINSTELLUNGEN' },
			{ label: 'MENU.PASSWORT', routerLink: ['/' + PATH.CHANGE_PASSWORD] },
		]);
	}

	ngOnInit() {
		this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
	}

	submit(): void {
		if (this.oldPassword && this.oldPassword !== '') {
			if (this.newPassword1 && this.newPassword1 !== '') {
				if (this.newPassword2 && this.newPassword2 !== '') {
					if (this.newPassword1 === this.newPassword2) {
						if (this.newPassword1 === this.oldPassword) {
							this.messageService.add({
								severity: 'warn',
								summary: this.translate.instant('MESSAGES.WARNING'),
								detail: this.translate.instant('MESSAGES.PASSWORDS_MATCHING'),
								life: 3000
							});
						}
						this.loading = true;
						this.accountService.changePassword(this.oldPassword, this.newPassword1).then(res => {
							this.messageService.add({
								severity: 'success',
								summary: this.translate.instant('MESSAGES.SUCCESSFUL'),
								detail: this.translate.instant('MESSAGES.SAVED'),
								life: 3000
							});
							setTimeout(() => {
								this.loading = false;
								this.router.navigate([this.returnUrl]);
							}, 3000);
						}).catch(err => {
							this.loading = false;
							err.error.forEach(e => {
								if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
								} else {
									this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
								}
							})
						});
					} else {
						this.messageService.add({
							severity: 'error',
							summary: this.translate.instant('MESSAGES.ERROR'),
							detail: this.translate.instant('MESSAGES.PASSWORDS_NOT_MATCHING'),
							life: 3000
						});
					}
				} else {
					this.messageService.add({
						severity: 'error',
						summary: this.translate.instant('MESSAGES.ERROR'),
						detail: this.translate.instant('MESSAGES.REQUIRED_FIELD', { field: this.translate.instant('SETTINGS.CHANGE_PASSWORD.CONFIRM_PASSWORD') }),
						life: 3000
					});
				}
			} else {
				this.messageService.add({
					severity: 'error',
					summary: this.translate.instant('MESSAGES.ERROR'),
					detail: this.translate.instant('MESSAGES.REQUIRED_FIELD', { field: this.translate.instant('SETTINGS.CHANGE_PASSWORD.NEW_PASSWORD') }),
					life: 3000
				});
			}
		} else {
			this.messageService.add({
				severity: 'error',
				summary: this.translate.instant('MESSAGES.ERROR'),
				detail: this.translate.instant('MESSAGES.REQUIRED_FIELD', { field: this.translate.instant('SETTINGS.CHANGE_PASSWORD.OLD_PASSWORD') }),
				life: 3000
			});
		}
	}
}
