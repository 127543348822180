<!-- Confirm -->
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

<!-- Context Menu -->
<p-contextMenu #cm [model]="contextMenu" [style]="{'width': 'auto'}"></p-contextMenu>

<!-- ui Canvas -->
<canvas hidden #emptyCanvas></canvas>

<div class="p-grid">
	<div class="p-col-12">

		<!-- Message -->
		<p-toast>
			<ng-template let-message pTemplate="message">
				<div class="p-grid p-ml-2">
					<div class="p-col-2">
						<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
					</div>
					<div class="p-col-10">
						<h4>{{message.summary}}</h4>
					</div>
					<div class="p-col-12">
						<p>{{message.detail}}</p>
					</div>
					<div *ngIf="message.severity!='success'" class="p-col-12">
						<a class="p-button"
							href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
							Fehler melden
						</a>
					</div>
				</div>
			</ng-template>
		</p-toast>

		<!-- Spinner -->
		<div *ngIf="loading!==0" class="spinner">
			<span class="ball-1"></span>
			<span class="ball-2"></span>
			<span class="ball-3"></span>
			<span class="ball-4"></span>
			<span class="ball-5"></span>
			<span class="ball-6"></span>
			<span class="ball-7"></span>
			<span class="ball-8"></span>
		</div>

		<!--<div *ngIf="entry" class="card">-->
		<div class="card p-m-2">
			<!-- Toolbar -->
			<p-toolbar styleClass="p-mb-4 ">
				<ng-template pTemplate="left">
					<button pButton pRipple label="{{'BUTTONS.EDIT'|translate}}" class="p-button-warning p-mr-2"
						(click)="edit()"></button>
					<button pButton pRipple label="{{'BUTTONS.DELETE'|translate}}" class="p-button-danger p-mr-2"
						(click)="delete()"></button>
				</ng-template>
				<ng-template pTemplate="right">
				</ng-template>
			</p-toolbar>

			<!-- Content -->
			<p-splitter #splitterFBDetail layout="horizontal" [layout]="splitterFBDetailLayout" [gutterSize]="4"
				[style]="{'height': '100%'}" [panelSizes]="[40,60]" [minSizes]="[20,20]"
				(onResizeEnd)="handleFBSplitterChange($event)">
				<ng-template pTemplate>
					<div *ngIf="entry" class="p-p-2">
						<div class="p-grid">
							<div class="p-col-6 p-p-1">
								<div class="p-card p-p-2">
									<h4 class="p-my-1">Bericht</h4>
									<div *ngFor="let field of fieldsAllgemein_Bericht" class="p-col-12 p-my-0">
										<h6 class="p-my-2">{{field.label}}</h6>
										<div
											*ngIf="!entry[field.key] && entry[field.key] !== false && entry[field.key] !== 0">
											<i class="pi pi-minus"></i>
										</div>
										<div *ngIf="(field.type === 'string' || field.type === 'number')">
											{{ entry[field.key] }}
										</div>
										<div *ngIf="field.type === 'link'">
											<a href="{{field.link + entry[field.link_id]}}">
												{{ entry[field.key] }}
											</a>
										</div>
									</div>
								</div>
							</div>
							<div class="p-col-6 p-p-1">
								<div class="p-card p-p-2" style="height: 100%;">
									<h4 class="p-my-1">Kennzahlen</h4>
									<div *ngFor="let field of fieldsAllgemein_Kennzahlen" class="p-col-12 p-my-0">
										<h6 class="p-my-2">{{field.label}}</h6>
										<div
											*ngIf="!entry[field.key] && entry[field.key] !== false && entry[field.key] !== 0">
											<i class="pi pi-minus"></i>
										</div>
										<div *ngIf="(field.type === 'string' || field.type === 'number')">
											{{ entry[field.key] }}
										</div>
										<div *ngIf="field.type === 'link'">
											<a href="{{field.link + entry[field.link_id]}}">
												{{ entry[field.key] }}
											</a>
										</div>
									</div>
								</div>
							</div>

							<div class="p-col-6 p-p-1">
								<div class="p-card p-p-2" style="height: 100%;">
									<h4 class="p-my-1">Tourdaten</h4>
									<div *ngFor="let field of fieldsAllgemein_Tourdaten_Group1" class="p-col-12 p-my-0">
										<h6 class="p-my-2">{{field.label}}</h6>
										<div
											*ngIf="!entry[field.key] && entry[field.key] !== false && entry[field.key] !== 0">
											<i class="pi pi-minus"></i>
										</div>
										<div *ngIf="(field.type === 'string' || field.type === 'number')">
											{{ entry[field.key] }}
										</div>
										<div *ngIf="field.type === 'link'">
											<a href="{{field.link + entry[field.link_id]}}">
												{{ entry[field.key] }}
											</a>
										</div>
									</div>
								</div>
							</div>
							<div class="p-col-6 p-p-1">
								<div class="p-card p-p-2" style="height: 100%;">
									<h4 class="p-my-1">Fahrzeug- und Mitarbeiterdaten</h4>
									<div *ngFor="let field of fieldsAllgemein_Tourdaten_Group2" class="p-col-12 p-my-0">
										<h6 class="p-my-2">{{field.label}}</h6>
										<div
											*ngIf="!entry[field.key] && entry[field.key] !== false && entry[field.key] !== 0">
											<i class="pi pi-minus"></i>
										</div>
										<div *ngIf="(field.type === 'string' || field.type === 'number')">
											{{ entry[field.key] }}
										</div>
										<div *ngIf="field.type === 'link'">
											<a href="{{field.link + entry[field.link_id]}}">
												{{ entry[field.key] }}
											</a>
										</div>
									</div>
								</div>
							</div>

							<!-- hier die Abschnitte, die unten auskommentiert sind -->
							<div *ngFor="let section of sections" class="card p-col-6 p-my-2"
								[ngStyle]="{'backdrop-filter': isDarkMode() ? 'brightness(0.5)' : 'brightness(1.5)', 'background':'none', 'background-color': getBackgroundColor(section.typ_id), 'width': '100%'}">

								<div class="p-col-12">
									<h4 class="p-my-2">
										{{section.FREMD_typ_bezeichnung}}
									</h4>
								</div>
								<div class="p-col-12 p-md-6 p-lg-3 p-my-2">
									<h6 class="p-my-2">
										{{section.anfang_zeit_string}} - {{section.ende_zeit_string}}
									</h6>
									<h6 class="p-my-2">
										{{section.anfang_meter}} m - {{section.ende_meter}} m
									</h6>
								</div>
								<div *ngIf="section.typ_id === 3" class="p-col-12 p-md-6 p-lg-3 p-my-2">
									<h6 class="p-my-2">
										Leerungen
									</h6>
									<p>
										{{ section.anzahl_2rad + section.anzahl_4rad }} (2-Rad: {{ section.anzahl_2rad
										}} /
										4-Rad: {{
										section.anzahl_4rad }})
									</p>
								</div>
								<div *ngIf="section.typ_id === 9" class="p-col-12 p-md-6 p-lg-3 p-my-2">
									<h6 class="p-my-2">
										Entleerungsort
									</h6>
									<div
										*ngIf="section.FREMD_standort_bezeichnung || section.FREMD_standort_bezeichnung !== false || section.FREMD_standort_bezeichnung !== 0">
										{{ section.FREMD_standort_bezeichnung }}
									</div>
									<div
										*ngIf="!section.FREMD_standort_bezeichnung && section.FREMD_standort_bezeichnung !== false && section.FREMD_standort_bezeichnung !== 0">
										<i class="pi pi-minus"></i>
									</div>
								</div>
								<div *ngIf="section.typ_id === 9" class="p-col-12 p-md-6 p-lg-3 p-my-2">
									<h6 class="p-my-2">
										Wiegeschein
									</h6>
									<div
										*ngIf="section.wiegeschein || section.wiegeschein !== false || section.wiegeschein !== 0">
										{{ section.wiegeschein }}
									</div>
									<div
										*ngIf="!section.wiegeschein && section.wiegeschein !== false && section.wiegeschein !== 0">
										<i class="pi pi-minus"></i>
									</div>
								</div>
								<div *ngIf="section.typ_id === 9" class="p-col-12 p-md-6 p-lg-3 p-my-2">
									<h6 class="p-my-2">
										Tonnage
									</h6>
									<div *ngIf="section.netto || section.netto !== false || section.netto !== 0">
										{{ section.netto + ' t'}}
									</div>
									<div *ngIf="!section.netto && section.netto !== false && section.netto !== 0">
										<i class="pi pi-minus"></i>
									</div>
								</div>

							</div>
							<!---->
						</div>
					</div>
				</ng-template>
				<ng-template pTemplate>
					<!--<div class="p-grid">-->
					<div class="card p-col-12 p-m-2 p-p-2" id="tabViewDetails_card">
						<p-tabView class="p-m-0" id="tabViewDetails" (onChange)="handleFBTabChange($event)"
							[(activeIndex)]="activeTab">
							<p-tabPanel header="Karte" id="tabViewDetails_Panel_map">
								<!--Karte-->
								<div id="mapFBdetail" class="p-m-0 p-p-0" [style.width]="initialTabWidth"
									style="height: 80vh; min-height: 200px;"></div>
								<!--<div id="mapFBdetail" class="p-m-0 p-p-0" style="height: 80vh; min-height: 200px;"></div>-->
								<!-- Tooltip -->
								<div id="tooltip" style="position: absolute; display: none;">
									<span class="p-tag">
										{{tooltip}}
									</span>
								</div>
							</p-tabPanel>
							<p-tabPanel header="Leerungen">
								<!--<p-table #table (onColResize)="onColResize($event)" (onColReorder)="onColReorder($event)"
										(onFilter)="onFilter($event)"
										[globalFilterFields]="filters" [reorderableColumns]="true" [resizableColumns]="true"
										[scrollHeight]="contentHeight + 'px'" [virtualRowHeight]="45" [virtualScroll]="true"
										columnResizeMode="expand" dataKey="ds_auto_id" scrollDirection="both" selectionMode="single"
										[stateKey]="stateName" stateStorage="local" styleClass="p-datatable-gridlines" [columns]="cols"
										[rows]="100" [scrollable]="true" [value]="entries" [rowTrackBy]="trackByFunction">-->
								<div *ngIf="tableFBLeerungenRedrawEntprell">
									<p-table #table [style.width]="initialTabWidth" (onColResize)="onColResize($event)"
										(onColReorder)="onColReorder($event)" (onFilter)="onFilter($event)"
										[globalFilterFields]="filters" [reorderableColumns]="true"
										[resizableColumns]="true" [scrollHeight]="contentHeightEx"
										[virtualRowHeight]="45" [virtualScroll]="true" columnResizeMode="expand"
										dataKey="ds_auto_id" scrollDirection="both" selectionMode="single"
										styleClass="p-datatable-gridlines" [columns]="cols" [rows]="100"
										[scrollable]="true" [value]="entries" [rowTrackBy]="trackByFunction">
										<ng-template pTemplate="caption">
											<div class="p-d-flex p-ai-center p-jc-between">
												<!--<div>
													<button type="button" pButton icon="fa fa-search" class="p-my-2 p-mr-3"
														pTooltip="{{'BUTTONS.LOAD' | translate }}" tooltipPosition="bottom"
														(click)="openFilterDialog()"></button>
													<button type="button" pButton icon="fa fa-rectangle-list" (click)="createReport()"
														class="p-my-2 p-ml-1 p-mr-3" pTooltip="{{'BUTTONS.PRINT_REPORT' | translate }}"
														tooltipPosition="bottom" [disabled]="!entries"></button>
													<button type="button" pButton icon="pi pi-file-o" (click)="exportCSV()"
														class="p-my-2 p-mx-1" pTooltip="CSV" tooltipPosition="bottom"></button>
													<button type="button" pButton icon="pi pi-file-excel" (click)="exportXLSX()"
														class="p-button-success p-my-2 p-mx-1" pTooltip="XLS" tooltipPosition="bottom"></button>
													<button type="button" pButton icon="pi pi-file-pdf" (click)="exportPDF()"
														class="p-button-warning p-my-2 p-mx-1" pTooltip="PDF" tooltipPosition="bottom"></button>
												</div>-->
												<h5 class="p-m-0">{{ name | translate }}</h5>
												<span class="p-input-icon-left">
													<i class="pi pi-search"></i>
													<input pInputText id="global" type="text" [value]="globalFilter"
														(input)="table.filterGlobal($event.target.value, 'contains');globalFilter = $event.target.value;"
														placeholder="{{'BUTTONS.SEARCH'|translate}}" />
												</span>
											</div>
										</ng-template>
										<ng-template pTemplate="header" let-columns>
											<tr>
												<th *ngFor="let col of columns"
													[style.flex]="'0 0 ' + col.width + 'px !important'"
													[pSortableColumn]="col.key" pResizableColumn pReorderableColumn>
													<div class="p-d-flex p-ai-center p-jc-between"
														style="min-width: 100%">
														<div>
															<p-sortIcon [field]="col.key"></p-sortIcon>
															{{ 'HEADERS.' + col.key | translate }}
														</div>
														<p-columnFilter [type]="col.type" [field]="col.key"
															display="menu" appendTo="body"
															[style.color]="(isColFiltered(col) ? 'var(--primary-color)' : 'var(--text-color)')">
														</p-columnFilter>
													</div>
												</th>
												<th id="buttoncolhead" [style.flex]="'0 0 ' + buttonColWidth + 'px'"
													alignFrozen="right" pFrozenColumn>
													<p-multiSelect #colselection [options]="possibleCols"
														[(ngModel)]="cols" dataKey="key"
														(onChange)="toggleColumn($event)" optionLabel="label"
														appendTo="body" [style.visibility]="'hidden'"
														[style.position]="'absolute'"
														[panelStyle]="{'min-width':'auto'}" class="colselect">
													</p-multiSelect>
													<button pButton pRipple icon="fas fa-table-columns"
														class="p-button-rounded p-button-secondary p-mr-2"
														(click)="colselection.show($event.originalEvent); $event.originalEvent.stopPropagation();"
														pTooltip="{{'BUTTONS.COL_SELECT'|translate}}"
														tooltipPosition="bottom" showDelay="500"></button>
													<!--<button pButton pRipple icon="pi pi-filter-slash"
														class="p-button-rounded p-button-secondary p-mr-2" (click)="table.clear()"
														pTooltip="{{'BUTTONS.RESET_FILTERS'|translate}}" tooltipPosition="bottom"
														showDelay="500"></button>-->
													<button pButton pRipple icon="fas fa-table-cells"
														class="p-button-rounded p-button-secondary p-mr-2"
														(click)="resetTable()"
														pTooltip="{{'BUTTONS.RESET_TABLE'|translate}}"
														tooltipPosition="bottom" showDelay="500"></button>
												</th>
											</tr>
										</ng-template>
										<ng-template pTemplate="body" let-entry let-rowIndex="rowIndex"
											let-columns="columns">
											<tr style="height:45px" [pContextMenuRow]="entry" [pSelectableRow]="entry">
												<td *ngFor="let col of columns"
													[style.flex]="'0 0 ' + col.width + 'px !important'">
													<div *ngIf="entry[col.key] === null">
														<i class="pi pi-minus"></i>
													</div>
													<div *ngIf="entry[col.key] !== null">
														<div *ngIf="col.type == 'text'">
															{{ entry[col.key] }}
														</div>
														<div *ngIf="col.type == 'numeric'">
															{{ entry[col.key] }}
														</div>
														<div *ngIf="col.type == 'boolean'">
															<i [ngClass]="entry[col.key] ? 'pi pi-check' : 'pi pi-times'"
																[style.color]="entry[col.key] ? 'green' : 'red'"></i>
														</div>
														<div *ngIf="col.type == 'date'">
															{{ entry[col.key].toLocaleString() }}
														</div>
													</div>
												</td>
												<td class="buttoncolbody" [style.flex]="'0 0 ' + buttonColWidth + 'px'"
													alignFrozen="right" pFrozenColumn>
													<button pButton pRipple icon="pi pi-map-marker"
														class="p-button-rounded p-mr-2" (click)="center(entry)"
														pTooltip="{{'BUTTONS.CENTER'|translate}}"
														tooltipPosition="bottom" showDelay="500"></button>
													<a *ngIf="deletePermission" id="deletebutton"
														(click)="delete(entry.ds_this_id)"
														class="p-button-rounded p-button-danger p-mr-2 p-ripple p-button p-component p-button-icon-only">
														<i class="pi pi-trash"></i>
													</a>
												</td>
											</tr>
										</ng-template>
										<ng-template pTemplate="summary">
											<div class="p-d-flex p-ai-center p-jc-between">
												{{ 'TABLE.COUNT' | translate : { count : count } }}
												{{ (table && table.filteredValue ? 'TABLE.TOTAL' :
												'') | translate : { count : (entries ?
												entries.length
												: 0) } }}
												<div>
													{{ 'TABLE.LAST_REFRESH' | translate }}: {{ loadTimestamp ?
													loadTimestamp.toLocaleString() :
													''
													}}
												</div>
											</div>
										</ng-template>
									</p-table>
								</div>
							</p-tabPanel>
							<!--<p-tabPanel header="Kennzahlen">
									TODO Kennzahlen
								</p-tabPanel>-->
						</p-tabView>
					</div>
					<!--</div>-->
				</ng-template>
			</p-splitter>
		</div>

		<!-- Confirm -->
		<p-confirmDialog [style]="{'width': '450px'}"></p-confirmDialog>
	</div>
</div>