import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { ListComponent } from '@app/common/templates/list/list.component';
import { Module } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { ExportService } from '@app/services/export.service';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ObjektGefaessStapelverarbeitungComponent } from '../../stapelverarbeitung/stapelverarbeitung.component';
import { TblObjektFilterDialogComponent } from './filter_dialog/filter_dialog.component';
import { TblGefaessMoveToHaldeDialogComponent } from '../../tbl_gefaess/detail/move_to_halde_dialog/move_to_halde_dialog.component';
import { tbl_objekt_flags } from '@app/models/tbl_objekt_flags';
import { MoveGarbageCanDialogComponent } from '../../tbl_gefaess/move_dialog/dialog.component';
import { PopupFromToReasonComponent } from '@app/common/popup/popupFromToReason.component';
import { PopupCloseOnReasonAdditionComponent } from '@app/common/popup/popupCloseOnReasonAddition.component';

@Component({
	templateUrl: './list.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/list/style.scss'],
	providers: [MessageService, ConfirmationService, DialogService]
})
export class TblObjektListComponent extends ListComponent {
	detail_url = PATH.DETAIL;
	edit_url = PATH.EDIT;

	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public changeDetectorRef: ChangeDetectorRef,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public dialogService: DialogService,
		public elRef: ElementRef,
		public exportService: ExportService,
		public globals: Globals,
		public messageService: MessageService,
		public router: Router,
		public settingsService: SettingsService,
		public translate: TranslateService
	) {
		super(accountService, breadcrumbService, changeDetectorRef, confirmationService, crudService, elRef, exportService, globals, messageService, router, settingsService, translate);

		this.apiUrl = 'TblObjekt';
		this.name = 'MENU.OBJEKT';
		this.url = '/' + PATH.OBJEKT;
		this.module = Module.BHV;

		this.possibleCols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
			{ type: 'text', key: 'barcode', required: false, width: 400 },
			{ type: 'text', key: 'bemerkung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_strasse_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer1', required: false, width: 400 },
			{ type: 'text', key: 'hauszusatz1', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer2', required: false, width: 400 },
			{ type: 'text', key: 'hauszusatz2', required: false, width: 400 },
			{ type: 'text', key: 'zusatz_text', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_ort_plz', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_ort_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_gemeinde_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_gebiet_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_entsorger_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_gruppe_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_status_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_typ_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_eigentuemer_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_pflichtiger_bezeichnung', required: false, width: 400 },
		];
		this.cols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
			{ type: 'text', key: 'FREMD_strasse_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer1', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_ort_plz', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_ort_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_gemeinde_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_eigentuemer_bezeichnung', required: false, width: 400 },
		];
		this.breadcrumbService.setItems([
			{ label: 'MENU.BHV' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.LIST', routerLink: [this.url + '/' + PATH.LIST] }
		]);
		this.hasFilterDialog = true;
	}

	async ngOnInit(): Promise<void> {
		super.ngOnInit();
		//this.complementContextMenu();
	}

	// override
	initContextMenu(): void {
		this.translate.get('init').subscribe((text: string) => {
			this.contextMenu = [
				// default
				{ label: this.translate.instant('CONTEXT_MENU.CREATE'), icon: 'pi pi-fw pi-plus', command: () => this.create() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN'), icon: 'pi pi-fw pi-search', command: () => this.detail() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_TAB'), icon: 'pi pi-fw pi-search', command: () => this.detail('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_WINDOW'), icon: 'pi pi-fw pi-search', command: () => this.detail('window') },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT'), icon: 'pi pi-fw pi-pencil', command: () => this.edit() },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT_TAB'), icon: 'pi pi-fw pi-pencil', command: () => this.edit('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT_WINDOW'), icon: 'pi pi-fw pi-pencil', command: () => this.edit('window') },
				{ label: this.translate.instant('CONTEXT_MENU.DELETE'), icon: 'pi pi-fw pi-trash', command: () => this.delete() },
				{ label: this.translate.instant('CONTEXT_MENU.RESIZE'), icon: 'pi pi-fw pi-arrows-h', command: () => this.resizeTableWidthFromContent(true) },
				// custom
				{ separator: true },
				{ label: this.translate.instant('CONTEXT_MENU.ALLE_GEFAESSE_AUF_HALDE_STELLEN'), icon: 'fa fa-house-circle-check', command: () => this.gfAlleAufHaldeStellen() },
				{ label: this.translate.instant('CONTEXT_MENU.ALLE_GEFAESSE_DES_OBJEKTS_STELLEN'), icon: 'fa fa-house', command: () => this.gfAlleAufObjektStellen() },
				{ separator: true },
				{ label: this.translate.instant('CONTEXT_MENU.LOCK_ALL_OBJECTS_BARCODES'), icon: 'fa fa-lock', command: () => this.gfAlleSperren() },
				{ label: this.translate.instant('CONTEXT_MENU.UNLOCK_ALL_OBJECTS_BARCODES'), icon: 'fa fa-lock-open', command: () => this.gfAlleEntsperren() }
				//
			];
			this.possibleCols.forEach(c => {
				c.label = this.translate.instant('HEADERS.' + c.key);
			});
		});
	}

	//complementContextMenu(): void {
	//	try {
	//		this.loading += 1;
	//		this.translate.get('init').subscribe((text: string) => {
	//			this.contextMenu.push(
	//				{ separator: true }
	//			);
	//			this.contextMenu.push(
	//				{ label: this.translate.instant('CONTEXT_MENU.ALLE_GEFAESSE_AUF_HALDE_STELLEN'), icon: 'fa fa-house-circle-check', command: () => this.gfAlleAufHaldeStellen() }
	//			);
	//		});
	//	}
	//	catch {}
	//	finally {
	//		this.loading -= 1;
	//	}
	//}

	openBatchProcessing() {
		const ref = this.dialogService.open(ObjektGefaessStapelverarbeitungComponent, {
			header: this.translate.instant('BHV.STAPELVERARBEITUNG'),
			width: '70%'
		});

		ref.onClose.subscribe((success) => {
			if (success) {
				this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Batch processing complete', life: 30000 });
			}
		});
	}

	openFilterDialog() {
		const ref = this.dialogService.open(TblObjektFilterDialogComponent, {
			header: this.translate.instant('Filter'),
			width: '70%'
		});

		ref.onClose.subscribe((filters) => {
			if (filters) {
				this.getFilteredEntries(filters);
			}
		});
	}

	getFilteredEntries(filters) {
		this.loading += 1;
		this.loadFilters = filters;
		this.crudService.getFilteredEntries(this.apiUrl, filters).then(res => {
			this.processEntries(res);
			this.resizeTableWidth();
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	gfAlleAufHaldeStellen(): void {
		let heapObjectId = null;
		if( this.selectedEntry && this.selectedEntry.ds_this_id ) {
			this.loading += 1;
			this.crudService.getSpecialObjectId(tbl_objekt_flags.flag_Objekt_ObjektTyp_Halde).then(res => {
				heapObjectId = res;
			}).catch(err => {
				err.error.forEach(e => {
					if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
					}
				})
			}).finally(() => {
				this.loading -= 1;
				// dialog öffnen, datum erhalten, umstellen auf this.heapObjectId
				if( heapObjectId ) {
					const ref = this.dialogService.open(TblGefaessMoveToHaldeDialogComponent, {
						header: this.translate.instant('HEADERS.GFMOVETO_HEAP_POPUP'),
						width: '70%'
					});

					ref.onClose.subscribe((filters) => {
						if (filters) {
							if( filters['GF_HALDE_FROM'] ) {
								//console.log('Stelle auf Halde ab:')
								//console.log(new Date(filters['GF_HALDE_FROM']));
								let target = {
									objekt_id_von: this.selectedEntry.ds_this_id,
									objekt_id_nach: heapObjectId,
									datum_von: new Date(filters['GF_HALDE_FROM']),
									force: true
								};
								this.confirmationService.confirm({
									message: this.translate.instant('CONFIRMATION.POTENTIAL_CONFLICTS'),
									header: this.translate.instant('CONFIRMATION.CONFIRM'),
									icon: 'pi pi-exclamation-triangle',
									acceptLabel: this.translate.instant('CONFIRMATION.YES'),
									rejectLabel: this.translate.instant('CONFIRMATION.NO'),
									accept: () => {
										this.loading += 1;
										this.crudService.moveGarbageCansFromObjectToObject(target).then(res => {
											this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
										}).catch(err => {
											err.error.forEach(e => {
												if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
													this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
												} else {
													this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
												}
											})
										}).finally(() => {
											this.loading -= 1;
										});
									}
								});
							} else {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 30000 });
							}
						}
					});
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORMSG.HEAP_NOT_FOUND_HEADER'), detail: this.translate.instant('ERRORMSG.HEAP_NOT_FOUND_MESSAGE'), life: 7000 });
				}
			});
		}
	}

	gfAlleAufObjektStellen(): void {
		if( this.selectedEntry && this.selectedEntry.ds_this_id ) {
			this.loading += 1;
			// dialog öffnen, datum erhalten, umstellen auf zielobjekt
			const ref = this.dialogService.open(MoveGarbageCanDialogComponent, {
				header: this.translate.instant('HEADERS.GEFAESS_STELLEN'),
				width: '70%'
			});
			ref.onClose.subscribe((filters) => {
				if (filters) {
					if( filters['objekt_id'] && filters['datum_von'] ) {
						//console.log('Stelle auf Halde ab:')
						//console.log(new Date(filters['GF_HALDE_FROM']));
						let target = {
							objekt_id_von: this.selectedEntry.ds_this_id,
							objekt_id_nach: filters['objekt_id'],
							datum_von: new Date(filters['datum_von']),
							datum_bis: filters['datum_bis'] ? new Date(filters['datum_bis']) : null,
							force: true
						};
						this.confirmationService.confirm({
							message: this.translate.instant('CONFIRMATION.POTENTIAL_CONFLICTS'),
							header: this.translate.instant('CONFIRMATION.CONFIRM'),
							icon: 'pi pi-exclamation-triangle',
							acceptLabel: this.translate.instant('CONFIRMATION.YES'),
							rejectLabel: this.translate.instant('CONFIRMATION.NO'),
							accept: () => {
								this.loading += 1;
								this.crudService.moveGarbageCansFromObjectToObject(target).then(res => {
									this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
								}).catch(err => {
									err.error.forEach(e => {
										if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
											this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
										} else {
											this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
										}
									})
								}).finally(() => {
									this.loading -= 1;
								});
							}
						});
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 30000 });
					}
				}
			});
		}
	}

	gfAlleSperren(): void {
		if( this.selectedEntry && this.selectedEntry.ds_this_id ) {
			this.loading += 1;
			// dialog öffnen, datum erhalten, umstellen auf zielobjekt
			const ref = this.dialogService.open(PopupFromToReasonComponent, {
				header: this.translate.instant('Barcodesperre festlegen'),
				width: '70%'
			});
			ref.onClose.subscribe((result) => {
				if (result) {
					this.loading += 1;
					var fromDate = result["fromDate"];
					var toDate = result["toDate"];
					var reason = result["reason"];
					// wenn leer, dann ab heute sperren
					if (fromDate == null) {
						fromDate = new Date();
						fromDate = new Date(
							(fromDate as Date).getFullYear(), (fromDate as Date).getMonth(), (fromDate as Date).getDay(),
							0, 0, 0);
					}
	
					this.crudService.getGarbageCanHistoryByObject(this.selectedEntry.ds_this_id).then(resGefaesse => {
						let barcodes: string[] = [];
						for( let gf of resGefaesse ) {
							if( gf && gf.barcode && gf.barcode.length > 0 ) {
								barcodes.push(gf.barcode);
							}
						}
						if( barcodes.length > 0 ) {
							this.loading += 1;
							this.crudService.createLockBarcodes(barcodes, reason, fromDate, toDate, 1).then(res => {
								this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
							}).catch(err => {
								err.error.forEach(e => {
									if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
										this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
									} else {
										this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
									}
								})
							}).finally(() => {
								this.loading -= 1;
							});
						}
					}).catch(err => {
						err.error.forEach(e => {
							if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
							} else {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
							}
						})
					}).finally(() => {
						this.loading -= 1;
					});
				}
			});
		}
	}

	gfAlleEntsperren(): void {
		if( this.selectedEntry && this.selectedEntry.ds_this_id ) {
			this.loading += 1;
			// dialog öffnen, datum erhalten, umstellen auf zielobjekt
			const ref = this.dialogService.open(PopupCloseOnReasonAdditionComponent, {
				header: this.translate.instant('Barcodesperre freigeben'),
				width: '70%'
			});
			ref.onClose.subscribe((result) => {
				if (result) {
					this.loading += 1;
					var toDate = result["unlockDate"];
					var reason = result["reasonAddition"];
					// wenn leer, dann ab heute sperren
					if (toDate == null) {
						toDate = new Date();
						toDate = new Date(
							(toDate as Date).getFullYear(), (toDate as Date).getMonth(), (toDate as Date).getDay(),
							0, 0, 0);
					}
					
					this.crudService.getGarbageCanHistoryByObject(this.selectedEntry.ds_this_id).then(resGefaesse => {
						let barcodes: string[] = [];
						for( let gf of resGefaesse ) {
							if( gf && gf.barcode && gf.barcode.length > 0 ) {
								barcodes.push(gf.barcode);
							}
						}
						if( barcodes.length > 0 ) {
							this.loading += 1;
							this.crudService.unlockBarcodes(barcodes, reason, toDate).then(res => {
								this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
							}).catch(err => {
								err.error.forEach(e => {
									if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
										this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
									} else {
										this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
									}
								})
							}).finally(() => {
								this.loading -= 1;
							});
						}
					}).catch(err => {
						err.error.forEach(e => {
							if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
							} else {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
							}
						})
					}).finally(() => {
						this.loading -= 1;
					});
				}
			});
		}
	}
}