import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Base } from '@app/models/base';
import { tbl_fahrtbericht } from '@app/models/tbl_fahrtbericht';
import { tbl_fahrtbericht_abschnitt } from '@app/models/tbl_fahrtbericht_abschnitt';
import { tbl_fahrtbericht_abschnitt_typ } from '@app/models/tbl_fahrtbericht_abschnitt_typ';
import { Chassis, Construction, Fraction, Lifter, StaffMember, tbl_fahrzeug, Tour, VehicleFile } from '@app/models/tbl_fahrzeug';
import { tbl_firma } from '@app/models/tbl_firma';
import { tbl_gefaess_status } from '@app/models/tbl_gefaess_status';
import { tbl_gefaess_zustand } from '@app/models/tbl_gefaess_zustand';
import { tbl_gefaess_sperre } from '@app/models/tbl_gefaess_sperre';
import { tbl_gps } from '@app/models/tbl_gps';
import { tbl_objekt } from '@app/models/tbl_objekt';
import { tbl_objekt_gruppe } from '@app/models/tbl_objekt_gruppe';
import { tbl_objekt_status } from '@app/models/tbl_objekt_status';
import { tbl_objekt_typ } from '@app/models/tbl_objekt_typ';
import { tbl_standort } from '@app/models/tbl_standort';
import { tbl_volumen } from '@app/models/tbl_volumen';
import { environment } from '@environments/environment';
import { Area, AreaPoint } from '@models/area';
import { Client } from '@models/bhv';
import { Collection } from '@models/collection';
import { GfStammdaten } from '@models/gfstammdaten';
import { Arrangement, Occasion, Risk, State, Track } from '@models/reversingcadastral';
import { tbl_abfuhrrythmus } from '@models/tbl_abfuhrrythmus';
import { tbl_gefaess } from '@models/tbl_gefaess';
import { tbl_gefaess_typ } from '@models/tbl_gefaess_typ';
import { tbl_gemeinde } from '@models/tbl_gemeinde';
import { tbl_land } from '@models/tbl_land';
import { tbl_leerung_barcode_status } from '@models/tbl_leerung_barcode_status';
import { tbl_ort } from '@models/tbl_ort';
import { tbl_strasse } from '@models/tbl_strasse';
import { tbl_tour_gruppe } from '@models/tbl_tour_gruppe';
import { AccountService } from '@services/account.service';
import { type } from 'os';
import { BaumschnittBerechtigung } from '@app/models/baumschnittBerechtigung';

@Injectable()
export class CRUDService {
	constructor(
		private accountService: AccountService,
		private http: HttpClient
	) { }

	/**
	 * Wieviele Einträge sollen maximal auf einmal geladen werden
	 * 
	 * @returns Anzahl maximal zu ladende Einträge
	 */
	getMaxEntries(): number {
		return +localStorage.getItem('maxEntries');
	}

	// #region Stammdaten

	checkPersistence(url: string, date: Date): Promise<boolean> {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/${url}/CheckPersistence`, date, options).toPromise());
		});
	}

	createEntry(url: string, entry: Base): Promise<any> {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/${url}/create`, entry, options).toPromise());
		});
	}

	deleteEntry(url: string, id: number): Promise<any> {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/${url}/delete/` + id, options).toPromise());
		});
	}

	editEntry(url: string, entry: Base): Promise<any> {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/${url}/edit`, entry, options).toPromise());
		});
	}

	getAllEntries(url: string): Promise<Base[]> {
		return new Promise<Base[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/${url}/`, options).toPromise());
		});
	}

	getFilteredEntries(url: string, filters: any): Promise<Base[]> {
		return new Promise<Base[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/${url}/Filter`, filters, options).toPromise());
		});
	}

	getEntry(url: string, id: number): Promise<Base> {
		return new Promise<Base>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/${url}/details/` + id, options).toPromise());
		});
	}

	// #region tbl_fahrzeug
	createVehicle(vehicle: tbl_fahrzeug) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/tblfahrzeug/create`, vehicle, options).toPromise());
		});
	}

	deleteVehicle(id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeug/delete/` + id, options).toPromise());
		});
	}

	editVehicle(vehicle: tbl_fahrzeug) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/tblfahrzeug/edit`, vehicle, options).toPromise());
		});
	}

	getAllVehicles() {
		return new Promise<tbl_fahrzeug[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeug`, options).toPromise());
		});
	}

	getAllVehiclesIncludingInactive() {
		return new Promise<tbl_fahrzeug[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeug/IndexIncludingInactive`, options).toPromise());
		});
	}

	getAllVehiclesIncludingServiceData() {
		return new Promise<tbl_fahrzeug[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeug/IndexIncludingServiceData`, options).toPromise());
		});
	}

	getVehicle(id: number) {
		return new Promise<tbl_fahrzeug>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeug/details/` + id, options).toPromise());
		});
	}

	getVehicleServiceData(id: number) {
		return new Promise<tbl_fahrzeug>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeug/DetailsIncludingServiceData/` + id, options).toPromise());
		});
	}

	getVehicleRemoteMaintenancePort(id: number) {
		return new Promise<tbl_fahrzeug>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/tblfahrzeug/PostRemoteControlRequest/` + id, {}, options).toPromise());
		});
	}

	setVehicleReceiveMessagesFlag(id: number) {
		return new Promise<tbl_fahrzeug>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/tblfahrzeug/PostSetMessagesFlag/` + id, {}, options).toPromise());
		});
	}

	setVehicleActiveState(id: number, active: boolean) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/tblfahrzeug/setDatensatzActiveState`, { ds_this_id: id, active_state: active }, options).toPromise());
		});
	}

	getAutoGenerateLogboxId() {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeug/AutoGenerateLogboxId/`, options).toPromise());
		});
	}

	checkPersistenceVehicle(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblFahrzeug/CheckPersistence`, date, options).toPromise());
		});
	}

	getVehicleComponentHistoryByComponent(component_id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblFahrzeugAkteKomponenteLink/LinkedComponentsByComponentId/` + component_id, options).toPromise());
		});
	}

	getVehicleComponentHistoryByVehicle(vehicle_id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblFahrzeugAkteKomponenteLink/LinkedComponentsByVehicleId/` + vehicle_id, options).toPromise());
		});
	}

	addVehicleComponent(data: any) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblFahrzeugAkteKomponenteLink/AddComponentVehicleLink`, data, options).toPromise());
		});
	}

	deleteComponent(component_id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblKomponente/Delete/` + component_id, options).toPromise());
		});
	}

	deleteComponentVehicleLinkEx(p_komponente_id: number, p_fahrzeug_akte_id: number) {
		return this.deleteComponentVehicleLink({fahrzeug_akte_id: p_fahrzeug_akte_id, komponente_id: p_komponente_id});
	}

	deleteComponentVehicleLink(params: any) {
		// INPUT:
		//    params["fahrzeug_akte_id"];
		//    params["komponente_id"];
		// OUTPUT:
		//    res["countToDeleteInTotal"]
		//    res["countDeletions"]
		//    res["countErrors"]
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblFahrzeugAkteKomponenteLink/DeleteComponentVehicleLink`, params, options).toPromise());
		});
	}

	getVehicleComponentTypeGroups() {
		return new Promise<any[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblKomponenteTyp/GetKomponententypGruppen`, options).toPromise());
		});
	}

	getVehicleComponentDefaultNames() {
		return new Promise<any[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblKomponente/GetKomponentenStandardBezeichner`, options).toPromise());
		});
	}

	// #endregion tbl_fahrzeug

	// #region fahrzeug abwicklung

	getOrCreateVehicleProcessingFile(vehicle_file_id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeugabwicklung/getorcreate/` + vehicle_file_id, options).toPromise());
		});
	}

	getVehicleProcessTasksByFile(vehicle_processfile_id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeugabwicklungabschnitt/getbyabwicklungid/` + vehicle_processfile_id, options).toPromise());
		});
	}

	getVehicleProcessDefaultTaskTypesOrdered() {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeugabwicklungabschnitttyp/getdefaultabschnitttypensortiert`, options).toPromise());
		});
	}

	createProcessTaskEntries(dataset: any[]) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/tblfahrzeugabwicklungabschnitt/createentries`, dataset, options).toPromise());
		});
	}

	deleteProcessTaskEntry(id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeugabwicklungabschnitt/delete/` + id, options).toPromise());
		});
	}

	getVehicleProcessScheduleDataHistoryByFile(vehicle_processfile_id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeugabwicklungereignisse/getbyabwicklungid/` + vehicle_processfile_id, options).toPromise());
		});
	}

	getVehicleProcessScheduleDataHistoryByFileAndTyp(vehicle_processfile_id: number, event_type_id: number) {
		return new Promise<any>(async resolve => {
			let params = {
				abwicklung_id: vehicle_processfile_id,
				ereignis_typ_id: event_type_id,
				nur_aktuellster: false
			};
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/tblfahrzeugabwicklungereignisse/getbyabwicklungidandtyp`, params , options).toPromise());
		});
	}

	getVehicleProcessScheduleDataLatestByFileAndTyp(vehicle_processfile_id: number, event_type_id: number) {
		return new Promise<any>(async resolve => {
			let params = {
				abwicklung_id: vehicle_processfile_id,
				ereignis_typ_id: event_type_id,
				nur_aktuellster: true
			};
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/tblfahrzeugabwicklungereignisse/getbyabwicklungidandtyp`, params , options).toPromise());
		});
	}

	getVehicleProcessScheduleSupplyDataHistoryByFile(vehicle_processfile_id: number) {
		return this.getVehicleProcessScheduleDataHistoryByFileAndTyp(vehicle_processfile_id, 1);
	}
	getVehicleProcessScheduleDispatchDataHistoryByFile(vehicle_processfile_id: number) {
		return this.getVehicleProcessScheduleDataHistoryByFileAndTyp(vehicle_processfile_id, 2);
	}
	getVehicleProcessScheduleSupplyDataLatestByFile(vehicle_processfile_id: number) {
		return this.getVehicleProcessScheduleDataLatestByFileAndTyp(vehicle_processfile_id, 1);
	}
	getVehicleProcessScheduleDispatchDataLatestByFile(vehicle_processfile_id: number) {
		return this.getVehicleProcessScheduleDataLatestByFileAndTyp(vehicle_processfile_id, 2);
	}

	addVehicleProcessScheduleSupplyEntry(vehicle_processfile_id: number, schedule_date: Date, entry_timestamp: Date, employee_id: number, memo: string) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			let requestParams = {
				abwicklung_id: vehicle_processfile_id,
				ereignis_typ_id: 1,
				ereignis_termin: schedule_date,
				erfassung_datum: entry_timestamp,
				mitarbeiter_id: employee_id,
				bemerkung: memo
			};
			resolve(this.http.post<any>(`${environment.apiUrl}/tblfahrzeugabwicklungereignisse/addeventschedule`, requestParams , options).toPromise());
		});
	}

	deleteVehicleProcessScheduleEntry(id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeugabwicklungereignisse/delete/` + id, options).toPromise());
		});
	}

	addVehicleProcessScheduleDispatchEntry(vehicle_processfile_id: number, schedule_date: Date, entry_timestamp: Date, employee_id: number, memo: string) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			let requestParams = {
				abwicklung_id: vehicle_processfile_id,
				ereignis_typ_id: 2,
				ereignis_termin: schedule_date,
				erfassung_datum: entry_timestamp,
				mitarbeiter_id: employee_id,
				bemerkung: memo
			};
			resolve(this.http.post<any>(`${environment.apiUrl}/tblfahrzeugabwicklungereignisse/addeventschedule`, requestParams , options).toPromise());
		});
	}

	// #endregion fahrzeug abwicklung

	// #region tbl_anhang
	getAllAttachments(type: string, id: number) {
		return new Promise<any[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblanhang/${type}/${id}`, options).toPromise());
		});
	}

	getAttachment(id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get(`${environment.apiUrl}/tblanhang/details/` + id,
				{
					responseType: 'blob',
					withCredentials: true,
					headers: { authorization: 'Bearer ' + localStorage.getItem('jwt') }
				}).toPromise());
		});
	}

	deleteAttachment(id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblanhang/delete/` + id, options).toPromise());
		});
	}

	// #endregion tbl_anhang

	// #region tbl_fahrzeug_akte
	createVehicleFile(vehicleFile: VehicleFile) {
		return new Promise<number>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/tblfahrzeugakte/create`, vehicleFile, options).toPromise());
		});
	}

	deleteVehicleFile(id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeugakte/delete/` + id, options).toPromise());
		});
	}

	editVehicleFile(vehicle: VehicleFile) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/tblfahrzeugakte/edit`, vehicle, options).toPromise());
		});
	}

	getAllVehicleFiles() {
		return new Promise<VehicleFile[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeugakte`, options).toPromise());
		});
	}

	getVehicleFile(id: number) {
		return new Promise<VehicleFile>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblfahrzeugakte/details/` + id, options).toPromise());
		});
	}
	// #endregion tbl_fahrzeug_akte

	// #region tbl_mitarbeiter
	setStaffActiveState(id: number, active: boolean) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblMitarbeiter/setDatensatzActiveState`, { ds_this_id: id, active_state: active }, options).toPromise());
		});
	}

	getAllStaffIncludingInactive() {
		return new Promise<Base[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblMitarbeiter/IndexIncludingInactive`, options).toPromise());
		});
	}

	getStaff() {
		return new Promise<StaffMember[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblMitarbeiter`, options).toPromise());
		});
	}

	getStaffMember(id: number) {
		return new Promise<StaffMember>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblMitarbeiter/details/` + id, options).toPromise());
		});
	}

	createStaffMember(staffMember: StaffMember) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblMitarbeiter/create/`, staffMember, options).toPromise());
		});
	}

	editStaffMember(staffMember: StaffMember) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblMitarbeiter/edit/`, staffMember, options).toPromise());
		});
	}

	checkPersistenceStaff(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblMitarbeiter/CheckPersistence`, date, options).toPromise());
		});
	}
	// #endregion tbl_mitarbeiter

	// #region Tour

	// #region tbl_tour

	getAllTours() {
		return new Promise<Tour[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblTour`, options).toPromise());
		});
	}

	getAllToursIncludingInactive() {
		return new Promise<Base[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblTour/IndexIncludingInactive`, options).toPromise());
		});
	}

	setTourActiveState(id: number, active: boolean) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblTour/setDatensatzActiveState`, { ds_this_id: id, active_state: active }, options).toPromise());
		});
	}

	getTour(id: number) {
		return new Promise<Tour>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblTour/details/` + id, options).toPromise());
		});
	}

	createTour(tour: Tour) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblTour/create/`, tour, options).toPromise());
		});
	}

	editTour(tour: Tour) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblTour/edit/`, tour, options).toPromise());
		});
	}

	checkPersistenceTour(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblTour/CheckPersistence`, date, options).toPromise());
		});
	}

	// #endregion tbl_tour

	// #region tbl_tour_gruppe

	getAllTourGroups() {
		return new Promise<tbl_tour_gruppe[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblTourGruppe`, options).toPromise());
		});
	}

	getTourGroup(id: number) {
		return new Promise<Tour>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblTourGruppe/details/` + id, options).toPromise());
		});
	}

	createTourGroup(tour_group: tbl_tour_gruppe) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblTourGruppe/create/`, tour_group, options).toPromise());
		});
	}

	editTourGroup(tour_group: tbl_tour_gruppe) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblTourGruppe/edit/`, tour_group, options).toPromise());
		});
	}

	checkPersistenceTourGroup(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblTourGruppe/CheckPersistence`, date, options).toPromise());
		});
	}

	// #endregion tbl_tour

	// #endregion Tour

	// #region tbl_abfallart
	getAllFractions() {
		return new Promise<Fraction[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblabfallart`, options).toPromise());
		});
	}

	getAllFractionsIncludingInactive() {
		return new Promise<Base[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblabfallart/IndexIncludingInactive`, options).toPromise());
		});
	}

	setFractionActiveState(id: number, active: boolean) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/tblabfallart/setDatensatzActiveState`, { ds_this_id: id, active_state: active }, options).toPromise());
		});
	}

	getFraction(id: number) {
		return new Promise<Fraction>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblabfallart/details/` + id, options).toPromise());
		});
	}

	createFraction(fraction: Fraction) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/tblabfallart/create/`, fraction, options).toPromise());
		});
	}

	editFraction(fraction: Fraction) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/tblabfallart/edit/`, fraction, options).toPromise());
		});
	}

	checkPersistenceFraction(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblAbfallart/CheckPersistence`, date, options).toPromise());
		});
	}
	// #endregion tbl_abfallart

	// #region tbl_aufbau
	getAllConstructions() {
		return new Promise<Construction[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblAufbau`, options).toPromise());
		});
	}

	getConstruction(id: number) {
		return new Promise<Construction>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblAufbau/details/` + id, options).toPromise());
		});
	}

	createConstruction(construction: Construction) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblAufbau/create/`, construction, options).toPromise());
		});
	}

	editConstruction(construction: Construction) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblAufbau/edit/`, construction, options).toPromise());
		});
	}

	checkPersistenceConstruction(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblAufbau/CheckPersistence`, date, options).toPromise());
		});
	}
	// #endregion tbl_aufbau

	// #region tbl_auftrag
	setTaskStatus(tbl_auftrag_id: number, tbl_auftrag_status_id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblAuftrag/SetAuftragStatus`, {auftrag_id: tbl_auftrag_id, status_id: tbl_auftrag_status_id}, options).toPromise());
		});
	}
	// #endregion tbl_auftrag

	// #region tbl_lifter
	getAllLifter() {
		return new Promise<Lifter[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblLifter`, options).toPromise());
		});
	}

	getLifter(id: number) {
		return new Promise<Lifter>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblLifter/details/` + id, options).toPromise());
		});
	}

	createLifter(lifter: Lifter) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblLifter/create/`, lifter, options).toPromise());
		});
	}

	editLifter(lifter: Lifter) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblLifter/edit/`, lifter, options).toPromise());
		});
	}

	checkPersistenceLifter(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblLifter/CheckPersistence`, date, options).toPromise());
		});
	}
	// #endregion tbl_lifter

	// #region tbl_fahrgestell
	getAllChassis() {
		return new Promise<Chassis[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblFahrgestell`, options).toPromise());
		});
	}

	getChassis(id: number) {
		return new Promise<Chassis>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblFahrgestell/details/` + id, options).toPromise());
		});
	}

	createChassis(chassis: Chassis) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblFahrgestell/create/`, chassis, options).toPromise());
		});
	}

	editChassis(chassis: Chassis) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblFahrgestell/edit/`, chassis, options).toPromise());
		});
	}

	checkPersistenceChassis(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblFahrgestell/CheckPersistence`, date, options).toPromise());
		});
	}
	// #endregion tbl_fahrgestell

	// #region tbl_gebiet
	getAllAreas() {
		return new Promise<Area[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblGebiet`, options).toPromise());
		});
	}

	getArea(id: number) {
		return new Promise<AreaPoint[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblGebiet/details/` + id, options).toPromise());
		});
	}

	createArea(area: Area) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGebiet/create/`, area, options).toPromise());
		});
	}

	deleteArea(id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblGebiet/delete/` + id, options).toPromise());
		});
	}

	editArea(area: Area) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGebiet/edit/`, area, options).toPromise());
		});
	}

	pointInPolygon(point: number[], polygonPoints) {
		const body = { Point: point, PolygonPoints: polygonPoints };
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGebiet/pointinpolygon`, body, options).toPromise());
		});
	}

	checkPersistenceArea(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGebiet/CheckPersistence`, date, options).toPromise());
		});
	}
	// #endregion tbl_gebiet

	// #region tbl_leerung_barcode_status
	getAllStatusBarcodes() {
		return new Promise<tbl_leerung_barcode_status[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblLeerungBarcodeStatus`, options).toPromise());
		});
	}

	getStatusBarcode(id) {
		return new Promise<tbl_leerung_barcode_status>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblLeerungBarcodeStatus/details/` + id, options).toPromise());
		});
	}

	getPossibleIds() {
		return new Promise<any[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblLeerungBarcodeStatus/possibleIds`, options).toPromise());
		});
	}

	createStatusBarcode(statusBarcode) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblLeerungBarcodeStatus/create/`, statusBarcode, options).toPromise());
		});
	}

	editStatusBarcode(statusBarcode) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblLeerungBarcodeStatus/edit`, statusBarcode, options).toPromise());
		});
	}

	checkPersistenceLeerungBarcodeStatus(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblLeerungBarcodeStatus/CheckPersistence`, date, options).toPromise());
		});
	}
	// #endregion tbl_leerung_barcode_status

	// #region tbl_volumen
	getAllVolumes() {
		return new Promise<tbl_volumen[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblVolumen`, options).toPromise());
		});
	}

	getAllVolumesIncludingInactive() {
		return new Promise<Base[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblVolumen/IndexIncludingInactive`, options).toPromise());
		});
	}

	setVolumeActiveState(id: number, active: boolean) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblVolumen/setDatensatzActiveState`, { ds_this_id: id, active_state: active }, options).toPromise());
		});
	}

	getVolume(id: number) {
		return new Promise<tbl_volumen>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblVolumen/details/` + id, options).toPromise());
		});
	}

	createVolume(volume) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblVolumen/create/`, volume, options).toPromise());
		});
	}

	editVolume(volume) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblVolumen/edit`, volume, options).toPromise());
		});
	}

	checkPersistenceVolume(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblVolumen/CheckPersistence`, date, options).toPromise());
		});
	}
	// #endregion tbl_volumen

	// #region tbl_land
	getAllCountries() {
		return new Promise<tbl_land[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblLand`, options).toPromise());
		});
	}

	getCountry(id: number) {
		return new Promise<tbl_land>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblLand/Details/` + id, options).toPromise());
		});
	}

	createCountry(entry) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblLand/Create`, entry, options).toPromise());
		});
	}

	editCountry(entry) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblLand/Edit`, entry, options).toPromise());
		});
	}

	checkPersistenceCountry(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblLand/CheckPersistence`, date, options).toPromise());
		});
	}
	// #endregion tbl_land

	// #region tbl_gemeinde
	getAllCities() {
		return new Promise<tbl_gemeinde[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblGemeinde`, options).toPromise());
		});
	}

	getCity(id: number) {
		return new Promise<tbl_gemeinde>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblGemeinde/Details/` + id, options).toPromise());
		});
	}

	createCity(entry) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGemeinde/Create/`, entry, options).toPromise());
		});
	}

	editCity(entry) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGemeinde/Edit`, entry, options).toPromise());
		});
	}

	checkPersistenceCity(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGemeinde/CheckPersistence`, date, options).toPromise());
		});
	}

	// #endregion tbl_gemeinde

	// #region tbl_ort
	getAllVillages() {
		return new Promise<tbl_ort[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblOrt`, options).toPromise());
		});
	}

	getVillage(id: number) {
		return new Promise<tbl_ort>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblOrt/Details/` + id, options).toPromise());
		});
	}

	createVillage(entry) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblOrt/Create/`, entry, options).toPromise());
		});
	}

	editVillage(entry) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblOrt/Edit`, entry, options).toPromise());
		});
	}

	checkPersistenceVillage(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblOrt/CheckPersistence`, date, options).toPromise());
		});
	}

	getPlacesFromPostalCode(postal_code: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblOrt/GetPlacesFromPostalCode/` + postal_code, options).toPromise());
		});
	}

	// #endregion tbl_ort

	// #region tbl_strasse
	getAllStreets() {
		return new Promise<tbl_strasse[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblStrasse`, options).toPromise());
		});
	}

	getStreet(id: number) {
		return new Promise<tbl_strasse>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblStrasse/Details/` + id, options).toPromise());
		});
	}

	createStreet(entry) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblStrasse/Create/`, entry, options).toPromise());
		});
	}

	editStreet(entry) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblStrasse/Edit`, entry, options).toPromise());
		});
	}

	checkPersistenceStreet(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblStrasse/CheckPersistence`, date, options).toPromise());
		});
	}

	generateStreetsForVillage(ort_id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblStrasse/GenerateStreetsForVillage/` + ort_id, options).toPromise());
		});
	}

	// #endregion tbl_ort

	// #region tbl_standort

	getAllPOI() {
		return new Promise<tbl_standort[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblStandort`, options).toPromise());
		});
	}

	// #endregion tbl_standort

	// #endregion Stammdaten

	// #region Rückfahrkartaster

	// #region tbl_rueckfahrkataster_massnahme
	getAllArrangements() {
		return new Promise<Arrangement[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblRueckfahrkatasterMassnahme`, options).toPromise());
		});
	}

	getArrangement(id: number) {
		return new Promise<Arrangement>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblRueckfahrkatasterMassnahme/details/` + id, options).toPromise());
		});
	}

	createArrangement(arrangement: Arrangement) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterMassnahme/create/`, arrangement, options).toPromise());
		});
	}

	editArrangement(arrangement: Arrangement) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterMassnahme/edit/`, arrangement, options).toPromise());
		});
	}

	checkPersistenceArrangement(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterMassnahme/CheckPersistence`, date, options).toPromise());
		});
	}
	// #endregion tbl_rueckfahrkataster_massnahme

	// #region tbl_rueckfahrkataster_grund
	getAllOccasions() {
		return new Promise<Occasion[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblRueckfahrkatasterGrund`, options).toPromise());
		});
	}

	getOccasion(id: number) {
		return new Promise<Occasion>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblRueckfahrkatasterGrund/Details/` + id, options).toPromise());
		});
	}

	createOccasion(occasion: Occasion) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterGrund/Create/`, occasion, options).toPromise());
		});
	}

	editOccasion(occasion: Occasion) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterGrund/Edit/`, occasion, options).toPromise());
		});
	}

	checkPersistenceOccasion(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterGrund/CheckPersistence`, date, options).toPromise());
		});
	}
	// #endregion tbl_rueckfahrkataster_grund

	// #region tbl_rueckfahrkataster_gefahr
	getAllRisks() {
		return new Promise<Risk[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblRueckfahrkatasterGefahr`, options).toPromise());
		});
	}

	getRisk(id: number) {
		return new Promise<Risk>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblRueckfahrkatasterGefahr/Details/` + id, options).toPromise());
		});
	}

	createRisk(risk: Risk) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterGefahr/Create/`, risk, options).toPromise());
		});
	}

	editRisk(risk: Risk) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterGefahr/Edit`, risk, options).toPromise());
		});
	}

	checkPersistenceRisk(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterGefahr/CheckPersistence`, date, options).toPromise());
		});
	}
	// #endregion tbl_rueckfahrkataster_gefahr

	// #region tbl_rueckfahrkataster_status
	getAllStates() {
		return new Promise<State[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblRueckfahrkatasterStatus`, options).toPromise());
		});
	}

	getState(id: number) {
		return new Promise<State>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblRueckfahrkatasterStatus/details/` + id, options).toPromise());
		});
	}

	createState(state: State) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterStatus/create/`, state, options).toPromise());
		});
	}

	editState(state: State) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterStatus/edit/`, state, options).toPromise());
		});
	}

	checkPersistenceState(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterStatus/CheckPersistence`, date, options).toPromise());
		});
	}
	// #endregion tbl_rueckfahrkataster_status

	// #region tbl_rueckfahrkataster_aufzeichnung
	getFilteredTracks(filters) {
		const body = {
			strasse: filters.strasse,
			plz: filters.plz,
			ort: filters.ort,
			in_kartaster: filters.in_kartaster,
			gefahren: filters.gefahren,
			massnahmen: filters.massnahmen,
			gruende: filters.gruende,
			status: filters.status,
			fahrzeuge: filters.fahrzeuge,
			datum_von: filters.datum_von,
			datum_bis: filters.datum_bis,
			ras_deaktiviert: filters.ras_deaktiviert,
			laenger_als: filters.laenger_als
		};
		return new Promise<Track[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/Filter`, body, options).toPromise());
		});
	}

	getAllTracks() {
		return new Promise<Track[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung` + (this.getMaxEntries() ? '/' + this.getMaxEntries() : ''), options).toPromise());
		});
	}

	getTrack(id: number) {
		return new Promise<Track>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/details/` + id, options).toPromise());
		});
	}

	getTrackVideo(id: number) {
		return new Promise<any[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/video/` + id, options).toPromise());
		});
	}

	getTrackGpx(id: number) {
		return new Promise<any[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/gpx/` + id, options).toPromise());
		});
	}

	createTrack(track: Track) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/create/`, track, options).toPromise());
		});
	}

	deleteTrack(id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/delete/` + id, options).toPromise());
		});
	}

	editTrack(track: Track) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/Edit/`, track, options).toPromise());
		});
	}

	groupTracks() {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/GroupTracks`, options).toPromise());
		});
	}

	getTrackfileCandidates(record: any) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/GetRKfilesForRecord`, record, options).toPromise());
		});
	}

	getTrackfileCandidatesIncludingAlwaysCandidates(record: any) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/GetRKfilesForRecordIncludingAlwaysCandidates`, record, options).toPromise());
		});
	}

	getTrackfileAlwaysCandidatesOnly() {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/GetRKfilesAlwaysCandidates`, options).toPromise());
		});
	}

	createFileForRecord(record: any) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/CreateFileAndTrackForRecord`, record, options).toPromise());
		});
	}

	linkRecordToFile(recordId: number, fileId: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/LinkRecordToFile`, {pRecordId: recordId, pFileId: fileId}, options).toPromise());
		});
	}

	transferFilesToDbInTracks(limit: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/TransferFilesToDb/` + limit, options).toPromise());
		});
	}

	createFileAndTrack(record: Track) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblRueckfahrkatasterAufzeichnung/createFileAndTrack/`, record, options).toPromise());
		});
	}
	// #endregion tbl_rueckfahrkataster_aufzeichnung

	// #endregion Rückfahrkartaster

	// #region BHV

	// #region Gefäß

	// #region tbl_gefaess

	getAllGarbageCans() {
		return new Promise<tbl_gefaess[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblGefaess`, options).toPromise());
		});
	}

	getGarbageCan(id: number) {
		return new Promise<tbl_gefaess>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblGefaess/details/` + id, options).toPromise());
		});
	}

	getGarbageCanByExactAnkey(ankey: string) {
		return new Promise<tbl_gefaess>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblGefaess/DetailsByExactAnkey/` + ankey, options).toPromise());
		});
	}

	getGarbageCansByScheduledPickup(type_skey: string, object_skey: string, dateTime: number) {
		return new Promise<tbl_gefaess[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGefaess/GefaesseByAbholauftrag/`, {gefaess_1_typ_skey: type_skey, objekt_skey: object_skey, jd_geplant: dateTime}, options).toPromise());
		});
	}

	createGarbageCan(entry: tbl_gefaess) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGefaess/create/`, entry, options).toPromise());
		});
	}

	editGarbageCan(entry: tbl_gefaess) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGefaess/edit`, entry, options).toPromise());
		});
	}

	checkPersistenceGarbageCan(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGefaess/CheckPersistence`, date, options).toPromise());
		});
	}

	getGarbageCanHistoryByObject(id: number) {
		return new Promise<tbl_gefaess[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblGefaess/GefaessHistorieObjekt/` + id, options).toPromise());
		});
	}

	moveGarbageCans(targets: any[]) {
		return new Promise<any[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblObjektGefaessLink/GefaesseStellen`, targets, options).toPromise());
		});
	}

	moveGarbageCan(target) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblObjektGefaessLink/GefaessStellen`, target, options).toPromise());
		});
	}

	moveGarbageCansFromObjectToObject(target) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblObjektGefaessLink/GefaesseVonStellenNach`, target, options).toPromise());
		});
	}

	printCounterIncrement(ids: number[]) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGefaess/PrintCounterIncrement`, ids, options).toPromise());
		});
	}

	printCounterDecrement(ids: number[]) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGefaess/PrintCounterDecrement`, ids, options).toPromise());
		});
	}

	printGefaess(id: number, gfDataZeitpunkt: Date = null) {
		return this.printGefaesse([id], gfDataZeitpunkt);
	}

	printGefaesse(ids: number[], gfDataZeitpunkt: Date = null) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			let params = {
				idlist: ids,
				dtZeitpunkt: gfDataZeitpunkt
			};
			resolve(this.http.post(`${environment.apiUrl}/TblGefaess/PrintGefaesse`, params, {
				responseType: 'blob',
				withCredentials: true,
				headers: { authorization: 'Bearer ' + localStorage.getItem('jwt') }
			}).toPromise());
		});
	}
	
	printGefaesseMitZeitpunkt(idsDatesParams: any[]) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post(`${environment.apiUrl}/TblGefaess/PrintGefaesseMitZeitpunkt`, idsDatesParams, {
				responseType: 'blob',
				withCredentials: true,
				headers: { authorization: 'Bearer ' + localStorage.getItem('jwt') }
			}).toPromise());
		});
	}

	// #endregion tbl_gefaess

	// #region tbl_gefaess_typ

	getAllGarbageCanTypes() {
		return new Promise<tbl_gefaess_typ[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblGefaessTyp`, options).toPromise());
		});
	}

	getGarbageCanType(id: number) {
		return new Promise<tbl_gefaess_typ>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblGefaessTyp/details/` + id, options).toPromise());
		});
	}

	createGarbageCanType(entry: tbl_gefaess_typ) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGefaessTyp/create/`, entry, options).toPromise());
		});
	}

	editGarbageCanType(entry: tbl_gefaess_typ) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGefaessTyp/edit`, entry, options).toPromise());
		});
	}

	checkPersistenceGarbageCanType(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGefaessTyp/CheckPersistence`, date, options).toPromise());
		});
	}

	// #endregion tbl_gefaess_typ

	// #region tbl_gefaess_sperre

	getAllWastebinLocks() {
		return new Promise<tbl_gefaess_sperre[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblGefaessSperre`, options).toPromise());
		});
	}

	getWastebinLock(id: number) {
		return new Promise<tbl_gefaess_sperre>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblGefaessSperre/details/` + id, options).toPromise());
		});
	}

	createWastebinLock(entry: tbl_gefaess_sperre) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGefaessSperre/create/`, entry, options).toPromise());
		});
	}

	editWastebinLock(entry: tbl_gefaess_sperre) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGefaessSperre/edit`, entry, options).toPromise());
		});
	}

	exportWastebinLocks() {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get(`${environment.apiUrl}/TblGefaessSperre/DownloadAsTxtFile`, {
				responseType: 'blob',
				withCredentials: true,
				headers: { authorization: 'Bearer ' + localStorage.getItem('jwt') }
			}).toPromise());
		});
	}

	checkPersistenceWastebinLock(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGefaessSperre/CheckPersistence`, date, options).toPromise());
		});
	}

	/*
	* {	
	*		"barcode" : string			// der zu sperrende barcode -> darf nicht null sein!
	*		"firstDay" : datetime		// erster Tag an dem die Sperre aktiv ist (null = today)
	*		"lastDay" : datetime		// letzter Tag an dem die Sperre aktiv ist (oder null)
	*		"lockingReason" : string	// ggf. beschreibung warum der barcode gesperrt wurde
	*		"flags" : number			// bitfeld
	*									// bit 0 = 1	:	sperre aktiv
	*									// bit 0 = 2	:	sperre wegen leerung -> firstDay ist dann der Leerungstag
	* }
	*/
	createLockBarcode(entry) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGefaessSperre/LockBarcode/`, entry, options).toPromise());
		});
	}
	
	createLockBarcodes(barcodeList: string[], pLockingReason: string, pFirstDay: Date, pLastDay: Date, pFlags: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			let params = {
				barcodes: barcodeList,
				lockingReason: pLockingReason,
				firstDay: pFirstDay,
				lastDay: pLastDay,
				flags: pFlags
			}
			resolve(this.http.post(`${environment.apiUrl}/TblGefaessSperre/LockBarcodes/`, params, {
				//responseType: 'blob',
				withCredentials: true,
				headers: { authorization: 'Bearer ' + localStorage.getItem('jwt') }
			}).toPromise());
		});
	}
	
	unlockBarcodes(barcodeList: string[], pLockingReasonAddition: string, pUnlockDay: Date) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			let params = {
				barcodes: barcodeList,
				unlockDay: pUnlockDay,
				lockingReasonAddition: pLockingReasonAddition 
			}
			resolve(this.http.post(`${environment.apiUrl}/TblGefaessSperre/UnlockBarcodes/`, params, {
				//responseType: 'blob',
				withCredentials: true,
				headers: { authorization: 'Bearer ' + localStorage.getItem('jwt') }
			}).toPromise());
		});
	}
	// #endregion tbl_gefaess_sperre

	// #region tbl_gefaess_zustand

	getAllGarbageCanStates() {
		return new Promise<tbl_gefaess_zustand[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblGefaessZustand`, options).toPromise());
		});
	}

	getGarbageCanState(id: number) {
		return new Promise<tbl_gefaess_zustand>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblGefaessZustand/details/` + id, options).toPromise());
		});
	}

	createGarbageCanState(entry: tbl_gefaess_zustand) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGefaessZustand/create/`, entry, options).toPromise());
		});
	}

	editGarbageCanState(entry: tbl_gefaess_zustand) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGefaessZustand/edit`, entry, options).toPromise());
		});
	}

	checkPersistenceGarbageCanState(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGefaessZustand/CheckPersistence`, date, options).toPromise());
		});
	}

	// #endregion tbl_gefaess_zustand

	// #region tbl_gefaess_status

	getAllGarbageCanStatus() {
		return new Promise<tbl_gefaess_status[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblGefaessStatus`, options).toPromise());
		});
	}

	getGarbageCanStatus(id: number) {
		return new Promise<tbl_gefaess_status>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblGefaessStatus/details/` + id, options).toPromise());
		});
	}

	createGarbageCanStatus(entry: tbl_gefaess_status) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGefaessStatus/create/`, entry, options).toPromise());
		});
	}

	editGarbageCanStatus(entry: tbl_gefaess_status) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGefaessStatus/edit`, entry, options).toPromise());
		});
	}

	checkPersistenceGarbageCanStatus(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGefaessStatus/CheckPersistence`, date, options).toPromise());
		});
	}

	// #endregion tbl_gefaess_status

	// #endregion Gefäß

	// #region Firma

	// #region tbl_firma

	getAllCompanies() {
		return new Promise<tbl_firma[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblFirma`, options).toPromise());
		});
	}

	getCompany(id: number) {
		return new Promise<tbl_firma>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblFirma/details/` + id, options).toPromise());
		});
	}

	createCompany(entry: tbl_firma) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblFirma/create/`, entry, options).toPromise());
		});
	}

	editCompany(entry: tbl_firma) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblFirma/edit`, entry, options).toPromise());
		});
	}

	checkPersistenceCompany(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblFirma/CheckPersistence`, date, options).toPromise());
		});
	}

	// #endregion tbl_firma

	// #endregion Firma

	// #region Objekt

	// #region tbl_objekt

	getAllObjects() {
		return new Promise<tbl_objekt[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblObjekt`, options).toPromise());
		});
	}

	getObject(id: number) {
		return new Promise<tbl_objekt>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblObjekt/details/` + id, options).toPromise());
		});
	}

	getObjectByExactAnkey(ankey: string) {
		return new Promise<tbl_objekt>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblObjekt/DetailsByExactAnkey/` + ankey, options).toPromise());
		});
	}

	getSpecialObject(objectType: number) {
		return new Promise<tbl_objekt>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblObjekt/DetailsByObjectType/` + objectType, options).toPromise());
		});
	}

	getSpecialObjectId(objectType: number) {
		return new Promise<number>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblObjekt/IdByObjectType/` + objectType, options).toPromise());
		});
	}

	createObject(entry: tbl_objekt) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblObjekt/create/`, entry, options).toPromise());
		});
	}

	editObject(entry: tbl_objekt) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblObjekt/edit`, entry, options).toPromise());
		});
	}

	checkPersistenceObject(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblObjekt/CheckPersistence`, date, options).toPromise());
		});
	}

	getObjectHistoryByGarbageCan(id: number) {
		return new Promise<tbl_objekt[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblObjekt/ObjektHistorieGefaess/` + id, options).toPromise());
		});
	}

	setCustomerObjectRelation(data) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblKundeObjektLink/ObjektStellen`, data, options).toPromise());
		});
	}

	// #endregion tbl_objekt

	// #region tbl_objekt_status

	getAllObjectStatus() {
		return new Promise<tbl_objekt_status[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblObjektStatus`, options).toPromise());
		});
	}

	getObjectStatus(id: number) {
		return new Promise<tbl_objekt_status>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblObjektStatus/details/` + id, options).toPromise());
		});
	}

	createObjectStatus(entry: tbl_objekt_status) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblObjektStatus/create/`, entry, options).toPromise());
		});
	}

	editObjectStatus(entry: tbl_objekt_status) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblObjektStatus/edit`, entry, options).toPromise());
		});
	}

	checkPersistenceObjectStatus(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblObjektStatus/CheckPersistence`, date, options).toPromise());
		});
	}

	// #endregion tbl_objekt_status

	// #region tbl_objekt_typ

	getAllObjectTypes() {
		return new Promise<tbl_objekt_typ[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblObjektTyp`, options).toPromise());
		});
	}

	getObjectType(id: number) {
		return new Promise<tbl_objekt_typ>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblObjektTyp/details/` + id, options).toPromise());
		});
	}

	createObjectType(entry: tbl_objekt_typ) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblObjektTyp/create/`, entry, options).toPromise());
		});
	}

	editObjectType(entry: tbl_objekt_typ) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblObjektTyp/edit`, entry, options).toPromise());
		});
	}

	checkPersistenceObjectType(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblObjektTyp/CheckPersistence`, date, options).toPromise());
		});
	}

	// #endregion tbl_objekt_typ

	// #region tbl_objekt_gruppe

	getAllObjectGroups() {
		return new Promise<tbl_objekt_gruppe[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblObjektGruppe`, options).toPromise());
		});
	}

	getObjectGroup(id: number) {
		return new Promise<tbl_objekt_gruppe>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblObjektGruppe/details/` + id, options).toPromise());
		});
	}

	createObjectGroup(entry: tbl_objekt_gruppe) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblObjektGruppe/create/`, entry, options).toPromise());
		});
	}

	editObjectGroup(entry: tbl_objekt_gruppe) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblObjektGruppe/edit`, entry, options).toPromise());
		});
	}

	checkPersistenceObjectGroup(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblObjektGruppe/CheckPersistence`, date, options).toPromise());
		});
	}

	// #endregion tbl_objekt_typ
	// #endregion Objekt

	// #region tbl_abfuhrrythmus

	getAllCollectionRhythms() {
		return new Promise<tbl_abfuhrrythmus[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblAbfuhrrhythmus`, options).toPromise());
		});
	}

	getCollectionRhythm(id: number) {
		return new Promise<tbl_abfuhrrythmus>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblAbfuhrrhythmus/details/` + id, options).toPromise());
		});
	}

	createCollectionRhythm(collection_rhythm: tbl_abfuhrrythmus) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblAbfuhrrhythmus/create/`, collection_rhythm, options).toPromise());
		});
	}

	editCollectionRhythm(collection_rhythm: tbl_abfuhrrythmus) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblAbfuhrrhythmus/edit`, collection_rhythm, options).toPromise());
		});
	}

	checkPersistenceCollectionRhythm(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblAbfuhrrhythmus/CheckPersistence`, date, options).toPromise());
		});
	}

	// #endregion tbl_gefaess

	// #region tbl_kunde
	getAllClients() {
		return new Promise<Client[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblKunde`, options).toPromise());
		});
	}

	getClient(id: number) {
		return new Promise<Client>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblKunde/details/` + id, options).toPromise());
		});
	}

	getClientByExactAnkey(ankey: string) {
		return new Promise<Client>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblKunde/DetailsByExactAnkey/` + ankey, options).toPromise());
		});
	}

	createClient(client: Client) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblKunde/create/`, client, options).toPromise());
		});
	}

	editClient(id: number, client: Client) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblKunde/edit/` + id, client, options).toPromise());
		});
	}

	checkPersistenceClient(date: Date) {
		return new Promise<boolean>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblKunde/CheckPersistence`, date, options).toPromise());
		});
	}
	// #endregion tbl_kunde

	// #endregion BHV

	// #region Berichte

	// #region tbl_gps
	getFilteredGps(filters) {
		return new Promise<tbl_gps[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGps/Filter`, filters, options).toPromise());
		});
	}

	getAllGps() {
		return new Promise<tbl_gps[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblGps` + (this.getMaxEntries() ? '/' + this.getMaxEntries() : ''), options).toPromise());
		});
	}

	getGps(id: number) {
		return new Promise<tbl_gps>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblGps/details/` + id, options).toPromise());
		});
	}

	createGps(gps: tbl_gps) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGps/Create`, gps, options).toPromise());
		});
	}

	editGps(gps: tbl_gps) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblGps/Edit`, gps, options).toPromise());
		});
	}
	// #endregion tbl_gps

	// #region tbl_leerung
	getFilteredCollections(filters) {
		return new Promise<Collection[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblLeerung/Filter`, filters, options).toPromise());
		});
	}

	getAllCollections() {
		return new Promise<Collection[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblLeerung` + (this.getMaxEntries() ? '/' + this.getMaxEntries() : ''), options).toPromise());
		});
	}

	getCollection(id: number) {
		return new Promise<Collection>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/tblLeerung/details/` + id, options).toPromise());
		});
	}

	createCollection(collection: Collection) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblLeerung/Create`, collection, options).toPromise());
		});
	}

	editCollection(collection: Collection) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblLeerung/Edit`, collection, options).toPromise());
		});
	}

	getHeader() {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get(`${environment.apiUrl}/TblLeerung/GetHeader`,
				{
					responseType: 'blob',
					withCredentials: true,
					headers: { authorization: 'Bearer ' + localStorage.getItem('jwt') }
				}).toPromise());
		});
	}
	// #endregion tbl_leerung

	// #region tbl_fahrtbericht
	getFilteredTripReports(filters) {
		return new Promise<tbl_fahrtbericht[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblFahrtbericht/Filter`, filters, options).toPromise());
		});
	}

	getAllTripReports() {
		return new Promise<tbl_fahrtbericht[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblFahrtbericht` + (this.getMaxEntries() ? '/' + this.getMaxEntries() : ''), options).toPromise());
		});
	}

	getTripReport(id: number) {
		return new Promise<tbl_fahrtbericht>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblFahrtbericht/details/` + id, options).toPromise());
		});
	}

	deleteTripReport(id: number) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblFahrtbericht/Delete/` + id, options).toPromise());
		});
	}

	createTripReport(entry: tbl_fahrtbericht) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblFahrtbericht/Create`, entry, options).toPromise());
		});
	}

	editTripReport(entry: tbl_fahrtbericht, sections: tbl_fahrtbericht_abschnitt[]) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblFahrtbericht/Edit`, { fahrtbericht: entry, abschnitte: sections }, options).toPromise());
		});
	}

	// #endregion tbl_fahrtbericht

	// #region tbl_fahrtbericht_abschhnitt

	getFilteredTripReportSections(id: number) {
		return new Promise<tbl_fahrtbericht[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblFahrtberichtAbschnitt/Filter/`, id, options).toPromise());
		});
	}

	deleteTripReportSections(ids: number[]) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblFahrtberichtAbschnitt/Delete`, ids, options).toPromise());
		});
	}

	// #endregion tbl_fahrtbericht_typ

	// #region tbl_fahrtbericht_typ

	getAllTripReportTypes() {
		return new Promise<tbl_fahrtbericht[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblFahrtberichtTyp` + (this.getMaxEntries() ? '/' + this.getMaxEntries() : ''), options).toPromise());
		});
	}

	// #endregion tbl_fahrtbericht_typ

	// #region tbl_fahrtbericht_abschhnitt_typ

	getAllTripReportSectionTypes() {
		return new Promise<tbl_fahrtbericht_abschnitt_typ[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/TblFahrtberichtAbschnittTyp` + (this.getMaxEntries() ? '/' + this.getMaxEntries() : ''), options).toPromise());
		});
	}

	// #endregion tbl_fahrtbericht_abschhnitt_typ

	// #endregion Berichte

	// #region Online
	getOnlineCollections(filters) {
		return new Promise<Collection[]>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post<any>(`${environment.apiUrl}/TblOnline/DataExport`, filters, options).toPromise());
		});
	}
	// #endregion Online

	// #region ESW

	getGfStammdaten(p_strObjektnummer: string, p_strKundennummer: string, p_strGefaessnummer: string, p_strKennung: string, p_nYear: number) {
		if (p_strKundennummer && p_strKundennummer.length > 0)
			return new Promise<GfStammdaten>(async resolve => {
				const options = await this.accountService.getOptions();
				resolve(this.http.get<any>(`${environment.apiUrl}/ReportsData/GefaessStammdatenblattKunde/${p_strKundennummer}/${p_strGefaessnummer}/${p_strKennung}/${p_nYear}`, options).toPromise());
				// http://localhost:57368/ReportsData/GefaessStammdatenblatKundet?p_strKundennummer=113336&p_strGefaessnummer=31000002&p_strKennung=ESW&p_nYear=2021
			});
		else
			return new Promise<GfStammdaten>(async resolve => {
				const options = await this.accountService.getOptions();
				resolve(this.http.get<any>(`${environment.apiUrl}/ReportsData/GefaessStammdatenblattObjekt/${p_strObjektnummer}/${p_strGefaessnummer}/${p_strKennung}/${p_nYear}`, options).toPromise());
				// http://localhost:57368/ReportsData/GefaessStammdatenblattObjekt?p_strObjektnummer=113336.200.1&p_strGefaessnummer=31000002&p_strKennung=ESW&p_nYear=2021
			});
	}

	getGfStammdatenPDF(pData: GfStammdaten) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post(`${environment.apiUrl}/ReportsData/GefaessStammdatenblattPdfExport`, pData,
				{
					responseType: 'blob'
				}).toPromise());
		});
		//return this.http.post<any>(`${environment.apiUrl}/ReportsData/GefaessStammdatenblattPdfExport`, pData);
	}

	checkBerechtigungBaumschnittESW(p_strKundennummer: string, p_strGefaessnummer: string, p_strKennung: string) {
		return new Promise<BaumschnittBerechtigung>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.get<any>(`${environment.apiUrl}/ReportsData/CheckBerechtigungBaumschnittESW/${p_strKundennummer}/${p_strGefaessnummer}/${p_strKennung}`, options).toPromise());
		});
	}

	getBaumschnittberechtigungsscheinPDF(pData: BaumschnittBerechtigung) {
		return new Promise<any>(async resolve => {
			const options = await this.accountService.getOptions();
			resolve(this.http.post(`${environment.apiUrl}/ReportsData/BaumschnittberechtigungsscheinPdfExport`, pData,
				{
					responseType: 'blob'
				}).toPromise());
		});
		//return this.http.post<any>(`${environment.apiUrl}/ReportsData/GefaessStammdatenblattPdfExport`, pData);
	}

	// #endregion ESW
}
