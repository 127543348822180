import { Component, OnInit, Input } from '@angular/core';
import { AccountService } from '@app/services/account.service';
import { ListComponent } from '@app/common/templates/list/list.component';

@Component({
    selector: 'common-list-view-elements',
    templateUrl: './common-list-view-elements.html',
    styleUrls: ['./common-list-view-elements.scss']
})

export class CommonListViewElementsComponent implements OnInit {
    @Input() accountService?: AccountService;
    @Input() loading?: number;
    @Input() parent?: ListComponent;

  constructor() { }

    ngOnInit(): void {
    }

}
