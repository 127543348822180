<!-- Confirm -->
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

<!-- Context Menu -->
<p-contextMenu #cm [model]="contextMenu" [style]="{'width': 'auto'}"></p-contextMenu>

<!-- Message -->
<p-toast>
	<ng-template let-message pTemplate="message">
		<div class="p-grid p-ml-2">
			<div class="p-col-2">
				<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
			</div>
			<div class="p-col-10">
				<h4>{{message.summary}}</h4>
			</div>
			<div class="p-col-12">
				<p>{{message.detail}}</p>
			</div>
			<div *ngIf="message.severity!='success'" class="p-col-12">
				<a class="p-button"
					href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
					Fehler melden
				</a>
			</div>
		</div>
	</ng-template>
</p-toast>

<p-toast key="refresh">
	<ng-template let-message pTemplate="message">
		<div class="p-grid p-ml-2">
			<div class="p-col-2">
				<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
			</div>
			<div class="p-col-10">
				<h4>{{message.summary}}</h4>
			</div>
			<div class="p-col-12">
				<p>{{message.detail}}</p>
			</div>
			<div class="p-col-12">
				<button *ngIf="accountService.checkPermissions(4, 0)" pButton pRipple
					label="{{'BUTTONS.REFRESH'|translate}}" (click)="getFilteredEntries(loadFilters)"></button>
			</div>
		</div>
	</ng-template>
</p-toast>

<p-toast key="reset">
	<ng-template let-message pTemplate="message">
		<div class="p-grid p-ml-2">
			<div class="p-col-2">
				<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
			</div>
			<div class="p-col-10">
				<h4>{{message.summary}}</h4>
			</div>
			<div class="p-col-12">
				<p>{{message.detail}}</p>
			</div>
			<div class="p-col-12">
				<button *ngIf="accountService.checkPermissions(4, 0)" pButton pRipple
					label="{{'BUTTONS.RESET'|translate}}" (click)="table.clear()"></button>
			</div>
		</div>
	</ng-template>
</p-toast>

<!-- Spinner -->
<div *ngIf="loading !== 0" class="spinner">
	<span class="ball-1"></span>
	<span class="ball-2"></span>
	<span class="ball-3"></span>
	<span class="ball-4"></span>
	<span class="ball-5"></span>
	<span class="ball-6"></span>
	<span class="ball-7"></span>
	<span class="ball-8"></span>
</div>

<!-- Content -->

<!-- <ul>
	<li *ngFor="let item of todoItems$ | async">
		{{ item.ds_this_id }}
	</li>
</ul> -->
<div class="card" style="min-height: 100%">
	<p-splitter #splitter [panelSizes]="[50,50]" [minSizes]="[30,20]" (onResizeEnd)="initTable()"
		[layout]="splitterLayout" [stateKey]="'state' + apiUrl + 'Leerung' + 'Splitter'" stateStorage="local">
		<ng-template pTemplate>
			<div [style.width]="tableWidth" style="height: 100%;">
				<p-table #table (onColResize)="onColResize($event)" (onColReorder)="onColReorder($event)"
					(onFilter)="onFilter($event)" (onRowSelect)="center($event)"
					[(contextMenuSelection)]="selectedEntry" [(selection)]="selectedEntry" [contextMenu]="cm"
					[globalFilterFields]="filters" [reorderableColumns]="true" [resizableColumns]="true"
					[scrollHeight]="contentHeight + 'px'" [virtualRowHeight]="45" [virtualScroll]="true"
					columnResizeMode="expand" dataKey="ds_auto_id" scrollDirection="both" selectionMode="single"
					[stateKey]="stateName" stateStorage="local" styleClass="p-datatable-gridlines" [columns]="cols"
					[rows]="100" [scrollable]="true" [value]="entries" [rowTrackBy]="trackByFunction">
					<ng-template pTemplate="caption">
						<div class="p-d-flex p-ai-center p-jc-between">
							<div>
								<button *ngIf="createPermission" type="button" pButton icon="fa fa-search"
									class="p-my-2 p-mr-3" pTooltip="{{'BUTTONS.LOAD' | translate }}"
									tooltipPosition="bottom" (click)="openFilterDialog()"></button>

								<button type="button" pButton pRipple icon="fa fa-map" class="p-my-2 p-ml-1 p-mr-3"
									pTooltip="{{(showMap ? 'COLLECTIONS.HIDE_MAP': 'COLLECTIONS.SHOW_MAP')| translate }}"
									tooltipPosition="bottom" (click)="showHideMap()"></button>

								<button type="button" pButton icon="pi pi-file-o" (click)="exportCSV()"
									class="p-my-2 p-mx-1" pTooltip="CSV" tooltipPosition="bottom"></button>
								<button type="button" pButton icon="pi pi-file-excel" (click)="exportXLSX()"
									class="p-button-success p-my-2 p-mx-1" pTooltip="XLS"
									tooltipPosition="bottom"></button>
								<button type="button" pButton icon="pi pi-file-pdf" (click)="exportPDF()"
									class="p-button-warning p-my-2 p-mx-1" pTooltip="PDF"
									tooltipPosition="bottom"></button>
							</div>
							<h5 class="p-m-0">{{ name | translate }}</h5>
							<span class="p-input-icon-left">
								<i class="pi pi-search"></i>
								<input pInputText id="global" type="text" [value]="globalFilter"
									(input)="table.filterGlobal($event.target.value, 'contains');globalFilter = $event.target.value;"
									placeholder="{{'BUTTONS.SEARCH'|translate}}" />
							</span>
						</div>
					</ng-template>
					<ng-template pTemplate="header" let-columns>
						<tr>
							<th *ngFor="let col of columns" [style.flex]="'0 0 ' + col.width + 'px !important'"
								[pSortableColumn]="col.key" pResizableColumn pReorderableColumn>
								<div class="p-d-flex p-ai-center p-jc-between" style="min-width: 100%">
									<div>
										<p-sortIcon [field]="col.key"></p-sortIcon>
										{{ 'HEADERS.' + col.key | translate }}
									</div>
									<p-columnFilter [type]="col.type" [field]="col.key" display="menu" appendTo="body"
										[style.color]="(isColFiltered(col) ? 'var(--primary-color)' : 'var(--text-color)')">
									</p-columnFilter>
								</div>
							</th>
							<th id="buttoncolhead" [style.flex]="'0 0 ' + buttonColWidth + 'px'" alignFrozen="right"
								pFrozenColumn>
								<p-multiSelect #colselection [options]="possibleCols" [(ngModel)]="cols" dataKey="key"
									(onChange)="toggleColumn($event)" optionLabel="label" appendTo="body"
									[style.visibility]="'hidden'" class="colselect" [style.position]="'absolute'"
									[panelStyle]="{'min-width':'auto'}">
								</p-multiSelect>
								<button pButton pRipple icon="fas fa-table-columns"
									class="p-button-rounded p-button-secondary p-mr-2"
									(click)="colselection.show($event.originalEvent);"
									pTooltip="{{'BUTTONS.COL_SELECT'|translate}}" tooltipPosition="bottom"
									showDelay="500"></button>
								<button pButton pRipple icon="pi pi-filter-slash"
									class="p-button-rounded p-button-secondary p-mr-2" (click)="table.clear()"
									pTooltip="{{'BUTTONS.RESET_FILTERS'|translate}}" tooltipPosition="bottom"
									showDelay="500"></button>
								<button pButton pRipple icon="fas fa-table-cells"
									class="p-button-rounded p-button-secondary p-mr-2" (click)="resetTable()"
									pTooltip="{{'BUTTONS.RESET_TABLE'|translate}}" tooltipPosition="bottom"
									showDelay="500"></button>
							</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-entry let-rowIndex="rowIndex" let-columns="columns">
						<tr style="height:45px" [pContextMenuRow]="entry" [pSelectableRow]="entry">
							<td *ngFor="let col of columns" [style.flex]="'0 0 ' + col.width + 'px !important'">
								<div *ngIf="entry[col.key] === null">
									<i class="pi pi-minus"></i>
								</div>
								<div *ngIf="entry[col.key] !== null">
									<div *ngIf="col.type == 'text'">
										{{ entry[col.key] }}
									</div>
									<div *ngIf="col.type == 'numeric'">
										{{ entry[col.key] }}
									</div>
									<div *ngIf="col.type == 'boolean'">
										<i [ngClass]="entry[col.key] ? 'pi pi-check' : 'pi pi-times'"
											[style.color]="entry[col.key] ? 'green' : 'red'"></i>
									</div>
									<div *ngIf="col.type == 'date'">
										{{ entry[col.key].toLocaleString() }}
									</div>
								</div>
							</td>
						</tr>
					</ng-template>
					<ng-template pTemplate="summary">
						<div class="p-d-flex p-ai-center p-jc-between">
							{{ 'TABLE.COUNT' | translate : { count : count } }}
							{{ (table && table.filteredValue ? 'TABLE.TOTAL' : '') | translate : { count : (entries
							?
							entries.length
							: 0) } }}
							<div>
								{{ 'TABLE.LAST_REFRESH' | translate }}: {{ loadTimestamp ?
								loadTimestamp.toLocaleString() :
								''
								}}
							</div>
						</div>
					</ng-template>
				</p-table>
			</div>
		</ng-template>

		<!-- Map -->
		<ng-template pTemplate>
			<div id="collectionMap" [hidden]="!showMap" class="p-m-0 p-p-0" [style.width]="mapWidth"
				style="height: 100%;"></div>
		</ng-template>
	</p-splitter>

	<!-- Tooltip -->
	<div id="tooltip" style="position: absolute; display: none;">
		<span class="p-tag">
			{{tooltip}}
		</span>
	</div>
</div>