import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Module } from '@app/models/permission';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { ConnectionSetting } from '@models/admin';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from '@services/admin.service';
import { MessageService } from 'primeng/api';

@Component({
	templateUrl: './edit.component.html',
	styleUrls: ['../style.scss'],
	providers: [MessageService]
})
export class AdminVisyConnectionSettingsEditComponent implements OnInit {
	basefields: any[];
	connectionFlags: any[];
	connectionSetting: ConnectionSetting;
	displayDialog = false;
	fieldgroups: any[];
	id: string;
	loading = 0;
	newPassword: string;
	permissions: any[];

	constructor(
		private adminService: AdminService,
		private breadcrumbService: BreadcrumbService,
		private messageService: MessageService,
		private router: Router,
		public translate: TranslateService,
	) {
		const href = this.router.url.split('/');
		this.id = href[href.length - 1];

		this.breadcrumbService.setItems([
			{ label: 'MENU.ADMIN' },
			{ label: 'MENU.CONNECTION_SETTINGS', routerLink: ['/' + PATH.CONNECTION_SETTINGS] },
			{ label: 'BREADCRUMBS.DETAIL', routerLink: ['/' + PATH.CONNECTION_SETTINGS + '/' + PATH.DETAIL + '/' + this.id] },
			{ label: 'BREADCRUMBS.EDIT', routerLink: ['/' + PATH.CONNECTION_SETTINGS + '/' + PATH.EDIT + '/' + this.id] }
		]);

		this.basefields = [
			{ type: 'string', key: 'SettingsName', label: 'Name' },
			{ type: 'readonlystring', key: 'Guid', label: 'Guid' },
			{ type: 'string', key: 'ConnectionStringBusinessData', label: 'Business Data' },
			{ type: 'string', key: 'ConnectionStringTblOnline', label: 'TblOnline' },
			{ type: 'string', key: 'ConnectionStringMaptour', label: 'Maptour' },
			{ type: 'string', key: 'ConnectionStringServiceData', label: 'Service Data' },
			{ type: 'string', key: 'MandantId', label: 'Mandant Id' },
			{ type: 'number', key: 'BookedUsers', label: 'Gebuchte Accounts' },
		];

		this.connectionFlags = [
			{ bit: 0, label: '', set: 'TblOnline', unset: 'Maptour' },
			{ bit: 1, label: '', set: 'AtosV3', unset: 'vionDB' },
			{ bit: 4, label: '', set: 'RAS Aufz. NICHT gruppieren', unset: 'RAS Aufz. automatisch gruppieren (default)' },
		];

		this.permissions = [
			{ key: Module.OnlineCollections, label: 'Online Leerungen' },
			{ key: Module.OnlineVehicles, label: 'Online Fahrzeuge' },
			{ key: Module.BHV, label: 'BHV' },
			{ key: Module.ReversingCadastral, label: 'Rückfahrkataster' },
			{ key: Module.Masterdata, label: 'Stammdaten' },
		];

		this.fieldgroups = [
			this.basefields,
		];
	}

	ngOnInit(): void {
		this.loading += 1;
		this.adminService.getConnectionSetting(+this.id).then(res => {
			this.connectionSetting = res;
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	isBookedModule(module: Module): boolean {
		return (this.connectionSetting.BookedModules & Math.pow(2, module)) == Math.pow(2, module);
	}

	isFlagSet(flag: number): boolean {
		return (this.connectionSetting.ConnectionFlags & Math.pow(2, flag)) == Math.pow(2, flag);
	}

	save(): void {
		this.loading += 1;
		this.adminService.editConnectionSetting(+this.id, this.connectionSetting).then(res => {
			this.router.navigate([PATH.CONNECTION_SETTINGS + '/' + PATH.DETAIL + '/' + this.id]);
			this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
			this.loading -= 1;
		});
	}

	setFlag(flag: number, value: boolean): void {
		if (value) {
			this.connectionSetting.ConnectionFlags = this.connectionSetting.ConnectionFlags | Math.pow(2, flag);
		} else {
			this.connectionSetting.ConnectionFlags = this.connectionSetting.ConnectionFlags - Math.pow(2, flag);
		}
	}

	switchModulePermission(module: Module, permitted: boolean): void {
		if (permitted) {
			this.connectionSetting.BookedModules = this.connectionSetting.BookedModules | Math.pow(2, module);
		} else {
			this.connectionSetting.BookedModules = this.connectionSetting.BookedModules - Math.pow(2, module);
		}
	}
}
