import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AppMainComponent } from './app.main.component';

@Component({
	selector: 'app-footer',
	templateUrl: './app.footer.component.html',
	styleUrls: ['./app.footer.component.scss']
})
export class AppFooterComponent implements OnInit {
	copyright: string;
	copyrightDisclaimer: string;
	displayFeedbackDialog = false;
	productTitle: string;
	version: string;
	elem;
	fullscreen = false;

	constructor(
		public app: AppMainComponent,
		public router: Router,
		private settingsService: SettingsService,
		public translate: TranslateService,
		@Inject(DOCUMENT) private document: any
	) { }

	ngOnInit() {
		this.copyright = `Copyright © ${new Date().getFullYear()} ViSy GmbH.`;
		this.copyrightDisclaimer = 'All rights reserved.';
		this.productTitle = 'ViSy Online';
		const v = environment.appVersion;
		const r = environment.appRevision;
		this.version = 'Version ' + v + '-' + r;
		this.elem = document.documentElement;
	}

	openManual() {
		const url = '/#' + this.router.serializeUrl(
			this.router.createUrlTree([`/` + PATH.MANUAL], { fragment: this.router.url.substring(1).toLowerCase() })
		);
		window.open(url, '_blank');
		//this.router.navigate(['/manual'], { fragment: this.router.url.substr(1).toLowerCase() });
	}

	openTerms() {
		this.router.navigate(['/' + PATH.TERMS]);
	}

	scrollToTop(): void {
		window.scrollTo(0, 0);
	}

	sendFeedback() {

	}

	switchFooterState(newValue) {
		this.app.showFooter = newValue;
		localStorage.setItem('showFooter', String(this.app.showFooter));
		this.settingsService.editSetting({ Name: 'showFooter', Value: this.app.showFooter });
		this.settingsService.toggleFooterVisibility();
	}

	toggleFullscreen() {
		if (this.fullscreen) {
			this.closeFullscreen();
		} else {
			this.openFullscreen();
		}
		this.fullscreen = !this.fullscreen;
	}

	openFullscreen() {
		if (this.elem.requestFullscreen) {
			this.elem.requestFullscreen();
		} else if (this.elem.mozRequestFullScreen) {
			/* Firefox */
			this.elem.mozRequestFullScreen();
		} else if (this.elem.webkitRequestFullscreen) {
			/* Chrome, Safari and Opera */
			this.elem.webkitRequestFullscreen();
		} else if (this.elem.msRequestFullscreen) {
			/* IE/Edge */
			this.elem.msRequestFullscreen();
		}
	}

	closeFullscreen() {
		if (this.document.exitFullscreen) {
			this.document.exitFullscreen();
		} else if (this.document.mozCancelFullScreen) {
			/* Firefox */
			this.document.mozCancelFullScreen();
		} else if (this.document.webkitExitFullscreen) {
			/* Chrome, Safari and Opera */
			this.document.webkitExitFullscreen();
		} else if (this.document.msExitFullscreen) {
			/* IE/Edge */
			this.document.msExitFullscreen();
		}
	}
}
