<!-- Message -->
<p-toast>
	<ng-template let-message pTemplate="message">
		<div class="p-grid p-ml-2">
			<div class="p-col-2">
				<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
			</div>
			<div class="p-col-10">
				<h4>{{message.summary}}</h4>
			</div>
			<div class="p-col-12">
				<p>{{message.detail}}</p>
			</div>
			<div *ngIf="message.severity!='success'" class="p-col-12">
				<a class="p-button"
					href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
					Fehler melden
				</a>
			</div>
		</div>
	</ng-template>
</p-toast>

<!-- Spinner -->
<div *ngIf="loading !== 0" class="spinner">
	<span class="ball-1"></span>
	<span class="ball-2"></span>
	<span class="ball-3"></span>
	<span class="ball-4"></span>
	<span class="ball-5"></span>
	<span class="ball-6"></span>
	<span class="ball-7"></span>
	<span class="ball-8"></span>
</div>

<!-- Toolbar -->
<div class="card p-my-0">
	<p-toolbar>
		<div class="p-toolbar-group-left">
			<button *ngIf="adminPermission" type="button" pButton pRipple icon="fa fa-floppy-disk"
				class="p-button-success p-mr-2" pTooltip="{{'BUTTONS.SAVE' | translate }}" tooltipPosition="bottom"
				(click)="createEntry()"></button>
		</div>
		<h5 class="p-m-0">{{ name | translate }}</h5>
		<div class="p-toolbar-group-right">
		</div>
	</p-toolbar>
</div>

<!-- Content -->
<div *ngIf="entry">
	<div *ngFor="let fields of fieldgroups">
		<div class="card p-my-2">
			<div class="p-grid p-formgrid">
				<div *ngFor="let field of fields" class="p-col-12 p-md-6 p-lg-3 p-my-2">
					<h6 class="p-my-2">
						{{ 'HEADERS.' + field.key | translate }}
					</h6>
					<div *ngIf="field.type == 'text'">
						<input type="text" pInputText [(ngModel)]="entry[field.key]" />
					</div>
					<div *ngIf="field.type == 'numeric'">
						<p-inputNumber [(ngModel)]="entry[field.key]" [showButtons]="true" [min]="0" mode="decimal"
							locale="de-DE" [style]="{'width':'100%'}"></p-inputNumber>
					</div>
					<div *ngIf="field.type == 'boolean'">
						<p-inputSwitch [(ngModel)]="entry[field.key]"></p-inputSwitch>
					</div>
					<div *ngIf="field.type == 'object'" class="p-inputgroup" [style.width]="'90%'"
						[style.max-width]="'400px'">
						<p-dropdown class="dropdown-button-group" [style]="{'width':'100%'}"
							[options]="options[field.key]" [(ngModel)]="entry[field.key]" placeholder="-"
							[showClear]="true" [virtualScroll]="true" itemSize="30" [filter]="true"
							filterBy="bezeichnung" optionValue="ds_this_id" optionLabel="bezeichnung" appendTo="body"
							dropdownDirective>
						</p-dropdown>
						<button type="button" pButton pRipple icon="fa fa-plus" class="p-button-success p-ml-2"
							(click)="createOption(field)" pTooltip="{{'BUTTONS.NEW' | translate }}"
							tooltipPosition="bottom"></button>
					</div>
					<div *ngIf="field.type == 'list'" class="p-inputgroup" [style.width]="'90%'"
						[style.max-width]="'400px'">
						<p-multiSelect [style]="{'width':'100%'}" [options]="options[field.key]" placeholder="-"
							[(ngModel)]="entry[field.key]" [virtualScroll]="true" itemSize="30" filterBy="bezeichnung"
							optionValue="ds_this_id" optionLabel="bezeichnung" appendTo="body">
						</p-multiSelect>
						<button type="button" pButton pRipple icon="fa fa-plus" class="p-button-success p-ml-2"
							(click)="createOption(field)" pTooltip="{{'BUTTONS.NEW' | translate }}"
							tooltipPosition="bottom"></button>
					</div>
					<div *ngIf="field.type == 'VehicleRolesList'" [style.width]="'90%'" [style.max-width]="'400px'">
						<p-dropdown [style]="{'width':'100%'}" [options]="options[field.key]"
							[(ngModel)]="entry[field.key]" placeholder="-" [showClear]="true" [virtualScroll]="true"
							itemSize="30" [filter]="true" filterBy="RoleName" optionLabel="RoleName" appendTo="body"
							dropdownDirective>
						</p-dropdown>
					</div>
					<div *ngIf="field.type === 'password'">
						<input type="password" pInputText [(ngModel)]="entry[field.key]">
					</div>
					<small *ngIf="field.required && (entry[field.key] == null || entry[field.key] === '')"
						class="p-error block" translate>REQUIRED</small>
				</div>
			</div>
		</div>
	</div>
</div>