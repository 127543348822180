<div class="exception-body error">
	<div class="exception-content">
		<div class="moon">
			<img src="assets/layout/images/pages/asset-moon.svg" alt="mirage-layout" />
		</div>
		<div class="exception-panel">
			<div class="exception-panel-content">
				<span class="tag"><i class="pi pi-exclamation-triangle" style="vertical-align: bottom"></i></span>
				<h1>Error Occured</h1>
				<div class="seperator"></div>
				<p>Something went wrong. </p>
				<button pButton type="button" [routerLink]="['/']" label="Go To Home"></button>
			</div>
		</div>
		<div class="desert"></div>
	</div>
</div>