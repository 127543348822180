import { Component } from '@angular/core';
import { FilterDialogComponent } from '@app/common/templates/filter_dialog/filter_dialog.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { DateService } from '@app/services/date.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	templateUrl: '../../../../../common/templates/filter_dialog/filter_dialog.component.html',
	styleUrls: ['../../style.scss', '../../../../../common/templates/filter_dialog/style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class TblAuftragFilterDialogComponent extends FilterDialogComponent {
	constructor(
		public accountService: AccountService,
		public crudService: CRUDService,
		public config: DynamicDialogConfig,
		public dateService: DateService,
		public messageService: MessageService,
		public ref: DynamicDialogRef,
		public translate: TranslateService
	) {
		super(accountService, crudService, config, dateService, messageService, ref, translate);

		const optionGroup1 = new Map();
		optionGroup1.set('ankey', { type: 'text' });
		optionGroup1.set('nachname', { type: 'text' });
		optionGroup1.set('vorname', { type: 'text' });
		optionGroup1.set('telefon', { type: 'text' });
		optionGroup1.set('auftrag_status', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblAuftragStatus', values: [] });
		optionGroup1.set('auftrag_merkmal', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'id', apiUrl: 'manualData1', values: [] });

		const optionGroup2 = new Map();
		optionGroup2.set('strasse', { type: 'text' });
		optionGroup2.set('hausnummer_von', { type: 'numeric', min: 0, max: null, step: 1 });
		optionGroup2.set('hausnummer_bis', { type: 'numeric', min: 0, max: null, step: 1 });
		optionGroup2.set('plz', { type: 'text' });
		optionGroup2.set('ort', { type: 'text' });
		optionGroup2.set('objekt', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblObjekt', values: [] });

		this.optionGroups = [optionGroup1, optionGroup2];
	}
}
