import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { EditComponent } from '@app/common/templates/edit/edit.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
	templateUrl: '../../../../common/templates/edit/edit.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/edit/style.scss'],
	providers: [DialogService, MessageService, ConfirmationService]
})
export class TblGefaessSperreEditComponent extends EditComponent {
	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public dialogService: DialogService,
		public globals: Globals,
		public messageService: MessageService,
		public router: Router,
		public translate: TranslateService,
	) {
		super(accountService, breadcrumbService, confirmationService, crudService, dialogService, globals, messageService, router, translate);

		this.apiUrl = 'TblGefaessSperre';
		this.name = 'MENU.SPERRE';
		this.url = '/' + PATH.GEFAESS_SPERRE;
		this.fieldgroups.push(
			[
				{ sub: 'AUFTRAG', type: 'text', key: 'bezeichnung', required: false, width: 400 },
				{ type: 'text', key: 'identcode_sperre', required: true, width: 400 },
				{ type: 'text', key: 'sperrgrund', required: false, width: 400 },
				{ type: 'date', key: 'sperre_von', required: false, width: 400 },
				{ type: 'date', key: 'sperre_bis', required: false, width: 400 },
				{ type: 'boolean', key: 'SYNTH_flag_sperre_aufgehoben', required: false, width: 400 },
				{ type: 'boolean', key: 'SYNTH_typ_graue_liste', required: false, width: 400 },
			]
		);
		this.breadcrumbService.setItems([
			{ label: 'MENU.BHV' },
			{ label: 'MENU.GEFAESS', routerLink: ['/' + PATH.GEFAESS] },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.EDIT', routerLink: [this.url + '/' + PATH.EDIT + '/' + this.id] }
		]);
	}

	// override
	editEntry() {
		if( !this.entry['SYNTH_flag_gesperrt'] )
			this.entry['SYNTH_flag_gesperrt'] = true;
		super.editEntry();
	}
}
