import { Component } from '@angular/core';
import { FilterDialogComponent } from '@app/common/templates/filter_dialog/filter_dialog.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { DateService } from '@app/services/date.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	templateUrl: '../../../../../common/templates/filter_dialog/filter_dialog.component.html',
	styleUrls: ['../../style.scss', '../../../../../common/templates/filter_dialog/style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class TblRueckfahrkatasterAufzeichnungFilterDialogComponent extends FilterDialogComponent {
	constructor(
		public accountService: AccountService,
		public crudService: CRUDService,
		public config: DynamicDialogConfig,
		public dateService: DateService,
		public messageService: MessageService,
		public ref: DynamicDialogRef,
		public translate: TranslateService
	) {
		super(accountService, crudService, config, dateService, messageService, ref, translate);

		//const optionGroup1 = new Map();
		//optionGroup1.set('ankey', { type: 'text' });
		//optionGroup1.set('barcode', { type: 'text' });
		//optionGroup1.set('volumen', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblVolumen', values: [] });
		//optionGroup1.set('status', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblGefaessStatus', values: [] });
		//optionGroup1.set('entsorger', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblFirma', values: [] });
		//optionGroup1.set('abfallart', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblAbfallart', values: [] });
		//optionGroup1.set('abfuhrrhythmus', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblAbfuhrrhythmus', values: [] });
		//optionGroup1.set('typ', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblGefaessTyp', values: [] });
		//optionGroup1.set('zustand', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblGefaesszustand', values: [] });
		//optionGroup1.set('tour', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblTour', values: [] });
		//optionGroup1.set('tourgruppe', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblTourGruppe', values: [] });
		//
		//const optionGroup2 = new Map();
		//optionGroup2.set('objekt', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblObjekt', values: [] });
		//optionGroup2.set('strasse', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblStrasse', values: [] });
		//optionGroup2.set('hausnummer_von', { type: 'numeric', min: 0, max: null, step: 1 });
		//optionGroup2.set('hausnummer_bis', { type: 'numeric', min: 0, max: null, step: 1 });
		//optionGroup2.set('plz', { type: 'text' });
		//optionGroup2.set('ort', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblOrt', values: [] });
		//optionGroup2.set('eigentuemer', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblKunde', values: [] });
		//optionGroup2.set('gesperrt', { type: 'boolean' });

		const optionGroup1 = new Map();
		optionGroup1.set('plz', { sub: "RASAUFZEICHNUNG", type: 'text' });
		optionGroup1.set('ort', { sub: "RASAUFZEICHNUNG", type: 'text' });
		optionGroup1.set('strasse', { sub: "RASAUFZEICHNUNG", type: 'text' });
		optionGroup1.set('hsnr', { sub: "RASAUFZEICHNUNG", type: 'text' });
		optionGroup1.set('laenge_von', { sub: "RASAUFZEICHNUNG", type: 'numeric' });
		optionGroup1.set('laenge_bis', { sub: "RASAUFZEICHNUNG", type: 'numeric' });
		optionGroup1.set('fahrzeug_id', { sub: "RASAUFZEICHNUNG", type: 'dropdown', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblFahrzeug', values: [] });

		optionGroup1.set('datum_von', { sub: "RASAUFZEICHNUNG", type: 'date' });
		optionGroup1.set('datum_bis', { sub: "RASAUFZEICHNUNG", type: 'date' });

		optionGroup1.set('breiter_350_ex', { sub: "RASAUFZEICHNUNG", type: 'dropdown', labelFieldName: 'bezeichnung', valueFieldName: 'mode_id', values: [{ "mode_id": 1, "bezeichnung": 'ja' }, { "mode_id": 0, "bezeichnung": 'nein' }] });
		optionGroup1.set('kuerzer_150_ex', { sub: "RASAUFZEICHNUNG", type: 'dropdown', labelFieldName: 'bezeichnung', valueFieldName: 'mode_id', values: [{ "mode_id": 1, "bezeichnung": 'ja' }, { "mode_id": 0, "bezeichnung": 'nein' }] });
		optionGroup1.set('hat_akte_ex', { sub: "RASAUFZEICHNUNG", type: 'dropdown', labelFieldName: 'bezeichnung', valueFieldName: 'mode_id', values: [{ "mode_id": 1, "bezeichnung": 'ja' }, { "mode_id": 0, "bezeichnung": 'nein' }] });

		optionGroup1.set('hat_geo_adresse_ex', { sub: "RASAUFZEICHNUNG", type: 'dropdown', labelFieldName: 'bezeichnung', valueFieldName: 'mode_id', values: [{ "mode_id": 1, "bezeichnung": 'ja' }, { "mode_id": 0, "bezeichnung": 'nein' }] });

		this.optionGroups = [optionGroup1];
	}
}
