import { Component, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { Base } from '@app/models/base';
import { Module, Operation } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	selector: 'createTemplate',
	templateUrl: './create.component.html',
	styleUrls: ['./style.scss'],
	providers: [DialogService, MessageService, ConfirmationService]
})
export class CreateComponent implements OnInit {
	apiUrl: string = ''; // Url des API-Controllers nach 'https://localhost:44311/'
	createPermission: boolean = false; // Darf der Nutzer neue Stammdateneinträge anlegen
	entry: Base = {}; // Stammdateneintrag, der angelegt werden soll
	fieldgroups: any[] = []; // Gruppen, in die die Felder eingeteilt sind
	isMobile: boolean = false; // Ist das Portal auf einem mobilen Browser 
	loading: number = 0; // Ladeindikator
	name: string = ''; // Name des Stammdatentyps
	options: any = {}; // Auswahloptionen für Dropdowns
	placeNames: any = {}; // Auswahloptionen für Orte
	readPermission: boolean = false; // Darf der Nutzer Stammdateneinträge ansehen
	state: any; // localstorage State der Seite
	stateName: string = ''; // key des States der Seite im localstorage
	url: string = ''; // Url des Stammdatentyps nach dem # 

	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		@Optional() public config: DynamicDialogConfig,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public dialogService: DialogService,
		public globals: Globals,
		public messageService: MessageService,
		@Optional() public ref: DynamicDialogRef,
		public router: Router,
		public translate: TranslateService,
	) {
		const href = this.router.url.split('/');
	}

	// #region Angular

	ngOnInit(): void {
		this.loading += 1;

		// Nutzerberechtigungen abfragen
		this.createPermission = this.accountService.checkPermissions(Module.Masterdata, Operation.CREATE);
		this.readPermission = this.accountService.checkPermissions(Module.Masterdata, Operation.READ);

		// Key des States auf apiUrl generieren
		this.stateName = 'state' + this.apiUrl + 'Create';
		this.state = JSON.parse(localStorage.getItem(this.stateName));
		this.setHorizontalGroups(((this.state && this.state.horizontalGroups !== null) ? this.state.horizontalGroups : true));

		this.isMobile = JSON.parse(this.globals.log_Platform).Mobile == 'yes' || JSON.parse(this.globals.log_Platform).Tablet == 'yes';

		if (this.readPermission) {
			this.getOptions();
		}

		this.loading -= 1;
	}

	// #endregion Angular

	// #region CRUD

	/**
	 * Löscht den Eintrag aus der Datenbank
	 */
	createEntry(): void {
		if (this.checkRequiredFields()) {
			this.loading += 1;
			this.fieldgroups.forEach(fg => {
				fg.forEach(field => {
					if (field.type == 'list') {
						let listString = '';
						if (this.entry[field.key]) {
							this.entry[field.key].forEach(element => {
								listString += element + ',';
							});
						}
						this.entry[field.key] = listString.slice(0, -1);
					}
				});
			});
			this.crudService.createEntry(this.apiUrl, this.entry).then(res => {
				if (this.ref) {
					this.ref.close(true);
				} else {
					if (res) {
						this.router.navigate([this.url + '/' + PATH.DETAIL + '/' + res]);
					} else {
						this.router.navigate([this.url + '/' + PATH.LIST]);
					}
				}
				this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
			}).catch(err => {
				err.error.forEach(e => {
					this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
				})
			}).finally(() => {
				this.loading -= 1;
			});
		}
	}

	// #endregion CRUD

	// #region Spezial

	/**
	 * Schaltet die Sortierung eines Dropdowns oder Multiselects um
	 * 
	 * @param field Maskenfeld, für das die Sortierung geändert werden soll
	 */
	changeSorting(field: any, init?: boolean) {
		if (init) {
			this.state = JSON.parse(localStorage.getItem(this.stateName));
			if (this.state && this.state.optionSortFields) {
				field.optionSortField = this.state.optionSortFields[field.key] ? this.state.optionSortFields[field.key].optionSortField : 'ankey';
				field.sortDescending = this.state.optionSortFields[field.key] ? this.state.optionSortFields[field.key].sortDescending : false;
			} else {
				this.state = this.state ? this.state : {};
				this.state.optionSortFields = {};
				field.optionSortField = 'ankey';
				field.sortDescending = false;
			}
		}

		if (!field.sortDescending) {
			this.options[field.key].sort((a, b) => (b.object[field.optionSortField] < a.object[field.optionSortField]) ? 1 : -1);
		} else {
			this.options[field.key].sort((a, b) => (b.object[field.optionSortField] > a.object[field.optionSortField]) ? 1 : -1);
		}
		this.state.optionSortFields[field.key] = { optionSortField: field.optionSortField, sortDescending: field.sortDescending };
		localStorage.setItem(this.stateName, JSON.stringify(this.state));
		this.options[field.key] = this.options[field.key].map(option => ({ label: (field.optionSortField == 'bezeichnung' ? option.object.bezeichnung : option.object.bezeichnung + ' (' + option.object[field.optionSortField] + ')'), value: option.value, object: option.object }));
		this.options[field.key] = [...this.options[field.key]];
	}

	/**
	 * Überprüft, ob die Pflichtfelder mit gültigen Werten ausgefüllt sind
	 * 
	 * @returns boolean, ob die Pflichtfelder mit gültigen Werten ausgefüllt sind
	 */
	checkRequiredFields(): boolean {
		let valid = true;
		this.fieldgroups.forEach(fg => {
			fg.forEach(field => {
				if (field.required && this.stringIsNullOrWhitespace(this.entry[field.key])) {
					valid = false;
					this.messageService.add({ severity: 'error', summary: this.translate.instant('MESSAGES.ERROR'), detail: this.translate.instant('MESSAGES.REQUIRED_FIELD', { field: this.translate.instant('HEADERS.' + field.key) }), life: 3000 });
				}
			});
		});
		return valid;
	}

	/**
	 * Öffnet ein Popup, um neue Optionen zu diesem Feld hinzuzufügen
	 * 
	 * @param field - Maskenfeld für das eine neue Option angelegt werden soll
	 */
	createOption(field: any): void {
		const ref = this.dialogService.open(field.createComponent, {
			header: this.translate.instant('BREADCRUMBS.CREATE'),
			width: '70%'
		});

		ref.onClose.subscribe((success: boolean) => {
			if (success) {
				this.getOptions();
				this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
			}
		});
	}

	/**
	 * Ordnet das Sortierfeld im Multiselect über dem Filterfeld an
	 */
	formatMultiselectLayout() {
		const msHeaderElements = document.getElementsByClassName('p-multiselect-header');
		Array.from(msHeaderElements).forEach(msHeaderElement => {
			(msHeaderElement as HTMLElement).style.flexWrap = 'wrap';
		});
	}

	/**
	 * Holt sich alle Einträge für alle Felder, die Dropdowns und Listen benötigen
	 */
	getOptions(): void {
		this.fieldgroups.forEach(fg => {
			fg.forEach(field => {
				if (field.type == 'object' || field.type == 'list') {
					this.loading += 1;
					this.crudService.getAllEntries(field.apiUrl).then(res => {
						this.options[field.key] = res.map(option => ({ label: option.bezeichnung, value: option.ds_this_id, object: option }));
						field.optionSortFieldOptions = Object.keys(res[0]).map(key => ({ label: this.translate.instant('HEADERS.' + key), value: key }));
						field.optionSortFieldOptions = field.optionSortFieldOptions.filter(option => !option.label.includes('HEADERS.'));
						this.changeSorting(field, true);
					}).catch(err => {
						err.error.forEach(e => {
							this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
						})
					}).finally(() => {
						this.loading -= 1;
					});
				}
			});
		});
	}

	showTime(field): boolean {
		if(field) {
			if(field['dateonly'] != null && field['dateonly'] != undefined) {
				if( field['dateonly'] === true ) {
					return false;
				}
			}
		}
		return true;
	}

	/**
	 * Holt alle Orte für eine PLZ
	 */
	getPlacesFromPostalCode(key) {
		this.loading += 1;
		this.crudService.getPlacesFromPostalCode(+this.entry[key]).then(res => {
			this.placeNames[key] = res;
		}).catch(err => {
			//err.error.forEach(e => {
			//	if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
			//		this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
			//	} else {
			//		this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
			//	}
			//})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	/**
	 * Schaltet die Feldgruppenanordnung auf horizontal bzw. vertikal
	 * 
	 * @param value boolean, ob die Feldgruppen horizontal angeordnet werden sollen
	 */
	setHorizontalGroups(value: boolean): void {
		this.state = JSON.parse(localStorage.getItem(this.stateName));
		if (!this.state) {
			this.state = {};
		}
		this.state.horizontalGroups = value;
		localStorage.setItem(this.stateName, JSON.stringify(this.state));
	}

	/**
	 * Überprüft, ob ein String einen gültigen und nicht leeren Wert hat
	 * 
	 * @param value String, der überprüft werden soll
	 * @returns boolean, ob der String einen gültigen und nicht leeren Wert enthält
	 */
	stringIsNullOrWhitespace(value: string): boolean {
		if (value !== null && value !== undefined) {
			let val = value + '';
			if (val.trim().length > 0) {
				return false;
			}
		}
		return true;
	}

	// #endregion Spezial
}
