<div class="p-grid">
	<div class="p-col-12 p-p-0">

		<!-- Message -->
		<p-toast>
			<ng-template let-message pTemplate="message">
				<div class="p-grid p-ml-2">
					<div class="p-col-2">
						<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
					</div>
					<div class="p-col-10">
						<h4>{{message.summary}}</h4>
					</div>
					<div class="p-col-12">
						<p>{{message.detail}}</p>
					</div>
					<div *ngIf="message.severity!='success'" class="p-col-12">
						<a class="p-button"
							href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
							Fehler melden
						</a>
					</div>
				</div>
			</ng-template>
		</p-toast>

		<div class="card p-mb-0">
			<!-- Content -->
			<h2>
				Handbuch
			</h2>
			<section id="home" class="card">
				<h4>
					Home
				</h4>
				<p>
					Hier zeigen wir alle relevanten Neuigkeiten und informieren Sie über die letzten Änderungen des ViSy
					Portals.
				</p>
				<p-card>
					<i class="fas fa-lightbulb p-m-2"></i>
					<p class="p-ml-2" style="display:inline">
						Sie können die Startseite des Portals in Ihren Einstellungen individuell festlegen.
					</p>
				</p-card>
			</section>
			<section id="online/map" class="card">
				<h4>
					Online-Fahrzeuge
				</h4>
				<div class="p-my-5">
					<h5>
						Erklärung der Buttons
					</h5>
					<img src="/assets/images/manual/Online_Map_Buttons.png" class="p-mb-2 p-shadow-5">
					<span>
						<p>
							<b>1</b> - Fahrzeugauswahl öffnen
						</p>
						<p>
							<b>2</b> - Fahrzeugliste zeigen/verstecken
						</p>
						<p>
							<b>3</b> - Gebiete auf der Karte ein-/ausblenden
						</p>
						<p>
							<b>4</b> - Zeitleiste zeigen/verstecken
						</p>
						<p>
							<b>5</b> - Leerungsliste zeigen/verstecken
						</p>
						<p>
							<b>6</b> - Aktuellen Kartenausschnitt exportieren
						</p>
					</span>
					<img src="/assets/images/manual/Online_Map_Buttons_rightPane.png" class="p-mb-2 p-mt-2 p-shadow-5">
					<span>
						<p>
							<b>a</b> - Alle Routen einblenden
						</p>
						<p>
							<b>b</b> - Alle Routen ausblenden
						</p>
						<p>
							<b>c</b> - Alle Leerungen einblenden
						</p>
						<p>
							<b>d</b> - Alle Leerungen ausblenden
						</p>
					</span>
				</div>
				<div class="p-my-2">
					<h5>
						Fahrzeuge auswählen und laden
					</h5>
					<img src="/assets/images/manual/Online_Map_Vehicle_Selection.png" class="p-mb-2 p-shadow-5">
					<span>
						<p>
							<b>1</b> - Alle Fahrzeuge an-/abwählen
						</p>
						<p>
							<b>2</b> - Einzelnes Fahrzeug an-/abwählen
						</p>
						<p>
							<b>3</b> - Onlinestatus der Fahrzeuge (weiß: keine Daten / rot: keine aktuellen Daten /
							gelb: keine Livedaten / grün: aktuell online)
						</p>
						<p>
							<b>4</b> - Automatisch aktualisieren, sobald neue Livedaten verfügbar sind
						</p>
						<p>
							<b>5</b> - Zeitspanne, für welche die Routen geladen werden sollen
						</p>
					</span>
					<p-card>
						<i class="fas fa-lightbulb p-m-2"></i>
						<p class="p-ml-2" style="display:inline">
							Während Sie die gewünschten Fahrzeuge auswählen, wird im Hintergrund der Onlinestatus aller
							Fahrzeuge geladen.
							Erst wenn dieser Vorgang abgeschlossen ist, steht die Funktion "Fahrzeuge laden" zur
							Verfügung.
						</p>
					</p-card>
				</div>
			</section>
			<section id="online/collections/list" class="card">
				<h4>
					Online-Leerungen
				</h4>
				<h5>
					Erklärung der Buttons
				</h5>
				<img src="/assets/images/manual/Online_Leerungen_Buttons.png" class="p-mb-2 p-shadow-5">
				<span>
					<p>
						<b>1</b> - Detailauswahl (Filter) öffnen
					</p>
					<p>
						<b>2</b> - Kartenansicht ein- und ausblenden
					</p>
					<p>
						<b>3</b> - Aktuelle Dateneinsicht als CSV-Datei exportieren
					</p>
					<p>
						<b>4</b> - Aktuelle Dateneinsicht als XLSX-Datei (Excel) exportieren
					</p>
					<p>
						<b>5</b> - Aktuelle Dateneinsicht als PDF-Datei exportieren
					</p>
				</span>
				<div class="p-my-2">
					<h5>
						Fahrzeuge und Zeitspanne auswählen, Daten laden
					</h5>
					<img src="/assets/images/manual/Online_Leerungen_Selection.png" class="p-mb-2 p-shadow-5">
					<span>
						<p>
							<b>1</b> - Alle Fahrzeuge an-/abwählen
						</p>
						<p>
							<b>2</b> - Einzelnes Fahrzeug an-/abwählen
						</p>
						<p>
							<b>3</b> - Zeitspanne für die Leerungsdaten
						</p>
						<p>
							<b>4</b> - Weitere Filterkriterien wie Typ und Status
						</p>
					</span>
				</div>
				<p>
					Nun können Sie die Daten nach den gewünschten Filterkriterien laden und anzeigen lassen.
					Die Leerungsdaten werden Ihnen detailliert mit Uhrzeit, Identcode und ggf. vorhandenen
					Stammdateninformationen wie Volumen und Abfallart aufgeführt.
					Zusätzlich sind die Leerungen in der Kartenansicht eingezeichnet, wo Sie durch Herüberfahren mit
					dem Mauscursor den Identcode sowie den Zeitpunkt der Leerung angezeigt bekommen.
				</p>
			</section>
			<section id="reversingcadastral/list" class="card">
				<h4>
					Rückfahrkataster
				</h4>
				<div class="p-my-5">
					<h5>
						Erklärung der Buttons
					</h5>
					<img src="/assets/images/manual/RK_Buttons.png" class="p-mb-2 p-shadow-5">
					<span>
						<p>
							<b>1</b> - Filterdialog öffnen, gewünschte Daten laden
						</p>
						<p>
							<b>2</b> - Zwischen der gruppierten und der einfachen Liste umschalten
						</p>
						<p>
							<b>3</b> - Alle Aufzeichnungen automatisch gruppieren (ACHTUNG: diese Funktion
							überschreibt die aktuellen Gruppierungen)
						</p>
						<p>
							<b>4</b> - Bearbeitungsmodus der Gruppierung an-/ausschalten
						</p>
						<p>
							<b>5</b> - Geladene Aufzeichnungen als CSV-Datei exportieren
						</p>
						<p>
							<b>6</b> - Geladene Aufzeichnungen als XLSX-Datei (Excel) exportieren
						</p>
						<p>
							<b>7</b> - Geladene Aufzeichnungen als PDF-Datei exportieren
						</p>
					</span>
					<p-card>
						<i class="fas fa-lightbulb p-m-2"></i>
						<p class="p-ml-2" style="display:inline">
							Im Rückfahrkataster kann schnell eine große Anzahl an Aufzeichnungen zusammenkommen.
							Um Ihnen unnötige Ladezeiten zu ersparen, werden die Daten hier nicht automatisch geladen,
							sondern stehen Ihnen erst nach Ihrer gewünschten Auswahl mit Hilfe des Filterdialogs zur
							Verfügung.
						</p>
					</p-card>
					<p-card>
						<i class="fas fa-lightbulb p-m-2"></i>
						<p class="p-ml-2" style="display:inline">
							Zur besseren Übersicht können Sie die Aufzeichnungen automatisch nach Straßen und
							Straßenabschnitten
							gruppieren lassen. Im Anschluss können Sie auch händisch Aufzeichnungen zwischen den Gruppen
							frei verschieben (geht bei erneuter automatischer Gruppierung wieder verloren).
							In der einfachen Listenansicht hingegen werden alle Aufzeichnungen ohne Gruppierung und
							ungeachtet der geografischen
							Zusammengehörigkeit untereinander gelistet angezeigt.
						</p>
					</p-card>
				</div>
				<div class="p-my-2">
					<h5>
						Daten gefiltert laden
					</h5>
					<img src="/assets/images/manual/RK_Ladefilter.png" class="p-mb-2 p-shadow-5">
					<span>
						<p>
							Die Filter-Dialoge öffnen sich entweder automatisch (siehe <a
								(click)="scrollToSection('settings/general')">Einstellungen</a>) oder lassen sich via
							Button (siehe oben – Button 1) öffnen. Hier können die gewünschten Aufzeichnungen näher
							eingegrenzt werden.
							Möchten Sie alle Aufzeichnungen ungefiltert laden, können Sie den Filter ohne weitere
							Auswahlkriterien bestätigen.
						</p>
					</span>
					<p-card>
						<i class="fas fa-lightbulb p-m-2"></i>
						<p class="p-ml-2" style="display:inline">
							Auch hier kann, wie in allen Listen, die maximale Anzahl der Einträge durch die Einstellung
							"Max.
							Tabelleneinträge" (siehe <a (click)="scrollToSection('settings/general')">Einstellungen</a>)
							begrenzt werden.
						</p>
					</p-card>
				</div>
			</section>
			<section id="reports/collections/list" class="card">
				<h4>
					Berichte - Leerungsbericht
				</h4>
				<h5>
					Die obere Buttonleiste im Detail
				</h5>
				<img src="/assets/images/manual/Leerungsbericht_Buttons_Top.png" class="p-mb-2 p-shadow-5">
				<span>
					<p>
						<b>1</b> - Filterkriterien ein- und ausblenden
					</p>
					<p>
						<b>2</b> - Eine Druckausgabe der geladenen Berichtsdaten erstellen
					</p>
					<p>
						<b>3</b> - Erfasste Daten unter Beachtung der aktuell gewählten Filterkriterien neu laden
					</p>
				</span>
				<h5>
					Leerungsdaten filtern und laden
				</h5>
				<img src="/assets/images/manual/Leerungsbericht_Filter.png" class="p-mb-2 p-mt-2 p-shadow-5">
				<span>
					<p>
						<b>1</b> - Sie können den Zeitbereich von/bis frei setzen, oder auch eine Zeitspanne aus den
						Vorbelegungen wählen
					</p>
					<p>
						<b>2</b> - Durch Klicken des 'X' können Sie einen gewählten Wert aus dem Filter löschen
					</p>
					<p>
						<b>3</b> - In einer aufgeklappten Auswahlliste finden Sie auch wieder ein Suchfeld zur
						Vereinfachung
					</p>
				</span>
				<h5>
					Anzeige der Leerungsdaten
				</h5>
				<span>
					<p>
						Nachdem Sie Ihre gewünschten Filterkriterien gesetzt und die Daten geladen haben, wird der
						Filterdialog automatisch ausgeblendet.
						Sie können diesen jederzeit durch den Button im oberen Fensterbereich zurückholen.
					</p>
					<p>
						Die Datenansicht umfasst die Leerungsliste mit Zeitstempel, Identcode und den verknüpften Werten
						aus den Stammdaten (Volumen etc.).
						Zusätzlich erhalten Sie eine Kartenansicht mit den eingezeichneten Leerungen an der erfassten
						Position und die Möglichkeit des Datenexports in verschiedene Formate.
					</p>
				</span>
				<img src="/assets/images/manual/Leerungsbericht_Daten.png" class="p-mb-2 p-mt-2 p-shadow-5">
			</section>
			<section id="masterdata/list" class="card">
				<h4>
					Stammdaten - Liste
				</h4>
				<span>
					<p>
						In den Listenansichten Ihrer Stammdaten sehen Sie zunächst alle erfassten Einträge des
						gewünschten Stammdatentyps (Mitarbeiter, Fahrzeuge usw.).
						Sie können die Sortierung durch Anklicken der Spaltenüberschriften ändern, nach gezielten Werten
						spaltenbezogen filtern oder auch eine Volltextsuche über die gesamte Tabelle ausführen.
					</p>
					<p>
						Ebenfalls löschen Sie über die Liste unerwünschte Einträge oder wechseln in die Detail- bzw.
						Bearbeiten-Ansicht eines Eintrages mit den Aktionsbuttons in der Eintragszeile.
						Neue Einträge werden über den "Plus"-Button über der Tabelle angelegt. Die Stammdatenlisten
						bieten zudem den Export der Listen in verschiedenen Dateiformaten an.
					</p>
				</span>
				<div class="p-my-5">
					<h5>
						Erklärung der Buttons
					</h5>
					<img src="/assets/images/manual/Masterdata_List_Buttons.png" class="p-mb-2 p-shadow-5">
					<span>
						<p>
							<b>1</b> - Neuen Eintrag anlegen
						</p>
						<p>
							<b>2</b> - Geladene Liste im CSV-Format exportieren
						</p>
						<p>
							<b>3</b> - Geladene Liste im XLSX-Format (Excel) exportieren
						</p>
						<p>
							<b>4</b> - Geladene Liste im PDF-Format exportieren
						</p>
					</span>
				</div>
				<div class="p-my-5">
					<h6>
						Zusätzlich: Aktionsbuttons eines jeden Listeneintrags
					</h6>
					<img src="/assets/images/manual/Masterdata_List_Entry_Buttons.png" class="p-mb-2 p-shadow-5">
					<span>
						<p>
							<b>1</b> - Öffnet die Detailansicht des Eintrags
						</p>
						<p>
							<b>2</b> - Öffnet die Seite zum Bearbeiten des Eintrags
						</p>
						<p>
							<b>3</b> - Löscht den Eintrag
						</p>
					</span>
				</div>
				<p-card>
					<i class="fas fa-lightbulb p-m-2"></i>
					<p class="p-ml-2" style="display:inline">
						Tipp: Über die Kombination "<b>Rechtsklick</b>" + "<b>Link in neuem Tab öffnen</b>" oder
						"<b>Strg</b>"
						+ "<b>Linksklick</b>" lässt sich die Detail- bzw. Bearbeiten-Seite in einem neuen Tab öffnen.
						Die zuvor
						geladene List bleibt Ihnen damit im aktuellen Browsertab erhalten.
					</p>
				</p-card>
				<div class="p-my-5">
					<h5>
						Suche
					</h5>
					<img src="/assets/images/manual/Masterdata_List_Search.png" class="p-mb-2 p-shadow-5">
					<span>
						<p>
							In diesem Suchfeld kann eine Freitextsuche über alle Einträge und Spalten der geladen Liste
							ausgeführt werden.
						</p>
					</span>
				</div>
				<div class="p-my-5">
					<h5>
						Tabellenkopfzeile
					</h5>
					<img src="/assets/images/manual/Masterdata_List_Header.png" class="p-mb-2 p-shadow-5">
					<span>
						<p>
							<b>1</b> - Öffnet/Schließt die Spaltenfilter
						</p>
						<p>
							<b>2</b> - Bietet eine Auswahl, welche Spalten in der Liste angezeigt werden sollen
						</p>
						<p>
							<b>3</b> - Ermöglicht das Filtern der ganzen Liste nur nach dieser Spalte
						</p>
						<p>
							<b>4</b> - Setzt alle Spaltenfilter zurück
						</p>
						<p>
							<b>5</b> - Durch Klick auf die Spaltenüberschrift lässt sich die geladene Liste nach dieser
							Spalte
							sortieren (erneutes Klicken schaltet zwischen ab- und aufsteigendem Sortieren um)
						</p>
					</span>
				</div>
				<p-card>
					<i class="fas fa-lightbulb p-m-2"></i>
					<p class="p-ml-2" style="display:inline">
						Die Spaltenreihenfolge lässt sich anpassen, indem man Linksklick auf dem Spaltenkopf gedrückt
						hält und ihn dann mit der Maus an die gewünschte Position zieht. Auch die Spaltenbreite können
						Sie durch Linksklick auf der Grenze zweier Spaltenüberschriften (gedrückt halten) und Ziehen
						ändern.
					</p>
				</p-card>
			</section>
			<section id="masterdata/list" class="card">
				<h4>
					Stammdaten - Detail
				</h4>
				<p>
					Die Detailansicht eines Datensatzes zeigt alle relevanten Datenfelder auf einer Seite
					zusammengefasst.
					Welche Datenfelder Sie hier sehen, hängt vom Stammdatentyp ab – so zeigt die
					Fahrzeugstammdaten-Ansicht selbstverständlich andere Inhalte an als die der Mitarbeiter usw.
				</p>
				<p>
					Über die entsprechenden Buttons können Sie von der Detail-Ansicht auch direkt in die Bearbeitung der
					Daten wechseln oder einen Datensatz löschen.
				</p>
			</section>
			<section id="masterdata/list" class="card">
				<h4>
					Stammdaten - Erstellen
				</h4>
				<p>
					Wie für die "Stammdaten - Detail" Ansicht bereits erläutert, finden Sie hier alle relevanten
					Datenfelder des Stammdatensatzes zusammengefasst – jedoch können Sie hier die Datenfelder füllen.
					Bestätigen der Eingabemaske legt einen neuen Eintrag in der Datenbank an.
				</p>
			</section>
			<section id="masterdata/list" class="card">
				<h4>
					Stammdaten - Bearbeiten
				</h4>
				<p>
					Wie für die "Stammdaten - Detail" Ansicht bereits erläutert, finden Sie hier alle relevanten
					Datenfelder des Stammdatensatzes zusammengefasst – jedoch können Sie hier die zuvor erfassten Werte
					bearbeiten.
					Bestätigen der Eingabemaske übernimmt die vorgenommenen Änderungen des bestehenden Dateneintrages in
					die Datenbank.
				</p>
			</section>
			<section id="settings/general" class="card">
				<h4>
					Einstellungen
				</h4>
				<img src="/assets/images/manual/Einstellungen.png" class="p-mb-2 p-shadow-5">
				<span>
					<p>
						<b>Startseite</b> - Legt fest, welche Seite angezeigt wird, wenn man sich einloggt.
					</p>
					<p>
						<b>Max. Tabelleneinträge</b> - Bei Tabellen mit vielen Einträgen kann hier festgelegt werden,
						wieviele Einträge maximal
						geladen werden. Das ist vor allem bei langsamem Internet, altem Computer oder Smartphone
						sinnvoll.
					</p>
					<p>
						<b>Farbe für Liverouten</b> - Legt die initiale Farbe von Routen fest, die aktuell gefahren
						werden.
					</p>
					<p>
						<b>Farbe für historische Routen</b> - Legt die initiale Farbe von Routen fest, die in der
						Vergangenheit liegen.
					</p>
					<p>
						<b>Filterdialog initial anzeigen</b> - Legt fest, ob die Fahrzeugauswahl des Onlineviewers beim
						Betreten der Seite automatisch geöffnet
						werden soll.
					</p>
					<p>
						<b>Routen initial anzeigen</b> - Legt fest, ob der Fahrstrahl der Fahrzeuge beim Hinzufügen zur
						Karte automatisch angezeigt wird.
					</p>
					<p>
						<b>Kunden anzeigen</b> - Sollten die Fahrzeuge von unterschiedlichen Kunden bzw. Firmen sein,
						kann man hiermit das
						entsprechende Feld zur Tabelle der ausgewählten Fahrzeuge hinzugefügt werden.
					</p>
					<p>
						<b>Gebiete initial anzeigen</b> - Legt fest, ob die vorher festgelegten Gebiete beim Öffnen des
						Onlineviewers angezeigt werden
						sollen.
					</p>
					<p>
						<b>Zeitleiste initial anzeigen</b> - Legt fest, ob die Zeitleiste des ausgewählten Fahrzeugs
						beim Hinzufügen zur Karte automatisch
						angezeigt wird.
					</p>
					<p>
						<b>Leerungsliste initial anzeigen</b> - Legt fest, ob die Leerungen des ausgewählten Fahrzeugs
						beim Hinzufügen zur Karte automatisch in
						einer weiteren Tabelle angezeigt werden.
					</p>
					<p>
						<b>Farbe der Fahrspur</b> - Legt die Farbe des vorwärts gefahrenen Anteils der Aufzeichnung
						fest.
					</p>
					<p>
						<b>Farbe der Rückfahrspur</b> - Legt die Farbe des rückwärts gefahrenen Anteils der Aufzeichnung
						fest.
					</p>
				</span>
				<p-card>
					<i class="fas fa-lightbulb p-m-2"></i>
					<p class="p-ml-2" style="display:inline">
						Die Einstellungen zum Onlineviewer bestimmen nur das Aussehen beim Öffnen der Seite. Alle
						Optionen können später im Onlineviewer selbst noch angepasst werden.
					</p>
				</p-card>
			</section>
			<section id="settings/changepassword" class="card">
				<h4>
					Passwort ändern
				</h4>
				<p>
					Hier kann ein neues Passwort festgelegt werden. Dieses muss...
				</p>
				<ul>
					<li>...mindestens sechs Zeichen lang sein.</li>
					<li>...mindestens ein Sonderzeichen enthalten.</li>
					<li>...mindestens eine Zahl enthalten.</li>
					<li>...mindestens einen Großbuchstaben enthalten.</li>
				</ul>
			</section>
		</div>
	</div>
</div>