import { Component, OnInit } from '@angular/core';
import { Area } from '@app/models/area';
import { Base } from '@app/models/base';
import { Timespans } from '@app/models/date';
import { CollectionSetTypes } from '@app/models/collection';
import { Module, Operation } from '@app/models/permission';
import { tbl_fahrzeug } from '@app/models/tbl_fahrzeug';
import { AccountService } from '@app/services/account.service';
import { DateService } from '@app/services/date.service';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	templateUrl: './filter_dialog.component.html',
	styleUrls: ['../style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class TblLeerungFilterDialogComponent implements OnInit {
	areas = [];
	clients = [];
	fractions = [];
	loading = 0;
	fromDate = new Date();
	selectedArea: Area;
	selectedBarcode: string = '';
	selectedClient: Base;
	selectedCount: number = 50000;
	selectedFraction: Base;
	selectedState: Base;
	selectedTimespan: number = 0;
	selectedVehicle: tbl_fahrzeug;
	selectedVolume: Base;
	states = [];
	toDate = new Date();
	vehicles = [];
	volumes = [];
	timespans = [];

	// aus template übernommen für Objektfilter
	optionGroups: Map<any, any>[];
	selections: any = {};

	constructor(
		private accountService: AccountService,
		private crudService: CRUDService,
		public config: DynamicDialogConfig,
		private dateService: DateService,
		private messageService: MessageService,
		public ref: DynamicDialogRef,
		public translate: TranslateService
	) {
		// aus tbl_gefaess filter übernommen für Objektfilter
		const optionGroup1 = new Map();
		optionGroup1.set('objekt', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblObjekt', values: [] });
		optionGroup1.set('strasse', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblStrasse', values: [] });
		optionGroup1.set('plz', { type: 'text' });
		optionGroup1.set('ort', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblOrt', values: [] });
		optionGroup1.set('eigentuemer', { type: 'multiselect', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblKunde', values: [] });
		optionGroup1.set('flag_identcodesperre', { type: 'boolean' });
		optionGroup1.set('collectionSetType', { type: 'collectionsettype', values: [] });
		
		this.optionGroups = [optionGroup1];
	}

	ngOnInit(): void {
		this.loading += 1;
		if (this.accountService.checkPermissions(Module.OnlineCollections, Operation.READ)) {
			this.loadOptions();
		}
		this.loading -= 1;
	}

	/**
	 * Abstrakte Zeitspanne in Datumszeitraum umsetzen
	 *
	 * @param event Eingegebene Zeitspanne
	 */
	convertTimespanSelection(event): void {
		const timespan = this.dateService.getTimespan(event.value);
		if (timespan != null) {
			this.fromDate = timespan.fromDate;
			this.toDate = timespan.toDate;
		}
	}

	inputDate(dateField, input: string): void {
		let date = DateService.inputDateExStatic(input);
		if (date) {
			if (dateField == 'fromDate') {
				this.fromDate = date;
			} else if (dateField == 'toDate') {
				this.toDate = date;
			}
			this.selectedTimespan = null;
		}
	}

	load(): void {
		this.loading += 1;
		if (this.accountService.checkPermissions(Module.Masterdata, Operation.READ)) {
			if (this.fromDate) {
				this.fromDate.setHours(0, 0, 0, 0);
			}
			if (this.toDate) {
				this.toDate.setHours(23, 59, 59, 999);
			}
			this.ref.close({
				Logbox_id: this.selectedVehicle ? this.selectedVehicle.logbox_serial : null,
				Client_id: this.selectedClient ? this.selectedClient.ds_this_id : null,
				Area_id: this.selectedArea ? this.selectedArea.Id : null,
				Fraction_id: this.selectedFraction ? this.selectedFraction.ds_this_id : null,
				Volume_id: this.selectedVolume ? this.selectedVolume.ds_this_id : null,
				Status: this.selectedState ? this.selectedState.ds_this_id : null,
				Barcode: this.selectedBarcode,
				DateFrom: this.fromDate,
				DateTo: this.toDate,
				bericht_id: null,
				abfrage_nur_relevante: null,
				abfrage_datentypauswahl: null,
				Count: this.selectedCount,
				Objekt_ids: this.selections['objekt'],
				Strasse_ids: this.selections['strasse'],
				Ort_ids: this.selections['ort'],
				PLZ: this.selections['plz'],
				Objekt_Eigentuemer_ids: this.selections['eigentuemer'],
				abfrage_nur_mit_identcodesperre_flag: this.selections['flag_identcodesperre'],
				abfrage_leerungen_nur_abrechnungsrelevante: (this.selections['collectionSetType'] == CollectionSetTypes.ALL_RELEVANT_FOR_BILLING) ? true : null
			});
		} else {
			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORS.ACCESS_DENIED'), detail: this.translate.instant('ERRORS.ACCESS_DENIED_MSG'), life: 3000 });
		}
		this.loading -= 1;
	}

	loadOptions(): void {
		this.loading += 1;
		this.translate.get('init').subscribe((text: string) => {
			Object.values(Timespans).filter((v) => isNaN(Number(v))).forEach((key, index) => {
				this.timespans.push(
					{ label: this.translate.instant('TIMESPANS.' + key), value: index }
				);
			});
		});
		this.loading -= 1;

		this.loading += 1;
		this.crudService.getAllStatusBarcodes().then(res => {
			res.forEach(state => {
				this.states.push({ label: state.bezeichnung, value: state });
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getAllVolumes().then(res => {
			res.forEach(volume => {
				this.volumes.push({ label: volume.bezeichnung, value: volume });
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getAllVehicles().then(res => {
			res.forEach(vehicle => {
				this.vehicles.push({ label: vehicle.kennzeichen, value: vehicle });
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getAllCompanies().then(res => {
			res.forEach(c => {
				this.clients.push({ label: c.bezeichnung, value: c });
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getAllFractions().then(res => {
			res.forEach(fraction => {
				this.fractions.push({ label: fraction.bezeichnung, value: fraction });
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		this.loading += 1;
		this.crudService.getAllAreas().then(res => {
			res.forEach(area => {
				this.areas.push({ label: area.Bezeichnung, value: area });
			});
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		// aus template übernommen für Objektfilter
		this.optionGroups.forEach(options => {
			options.forEach((option, key) => {
				if (option['type'] == 'timespan') {
					this.loading += 1;
					this.translate.get('init').subscribe((text: string) => {
						Object.values(Timespans).filter((v) => isNaN(Number(v))).forEach((key, index) => {
							option['values'].push(
								{ label: this.translate.instant('TIMESPANS.' + key), value: index }
							);
						});
					});
					this.loading -= 1;
				} else if (option['type'] == 'collectionsettype') {
					this.loading += 1;
					console.log("lade typen");
					this.translate.get('init').subscribe((text: string) => {
						Object.values(CollectionSetTypes).filter((v) => isNaN(Number(v))).forEach((key, index) => {
							option['values'].push(
								{ label: this.translate.instant('COLLECTIONSETTYPES.' + key), value: CollectionSetTypes[key] }
							);
						});
					});
					this.loading -= 1;
				} else if (option['type'] == 'dropdown' || option['type'] == 'multiselect') {
					this.selections[key] = [];
					this.loading += 1;
					this.crudService.getAllEntries(option['apiUrl']).then(res => {
						option['values'] = res;
					}).catch(err => {
						err.error.forEach(e => {
							this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
						})
					}).finally(() => {
						this.loading -= 1;
					});
				} else {
					this.selections[key] = null;
				}
			});
		});
	}

	unsorted() { }
}
