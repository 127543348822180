import { Component } from '@angular/core';
import { FilterDialogComponent } from '@app/common/templates/filter_dialog/filter_dialog.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { DateService } from '@app/services/date.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	templateUrl: '../../../../../common/templates/filter_dialog/filter_dialog.component.html',
	styleUrls: ['../../style.scss', '../../../../../common/templates/filter_dialog/style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class TblObjektSetEigentuemerDialogComponent extends FilterDialogComponent {
	constructor(
		public accountService: AccountService,
		public crudService: CRUDService,
		public config: DynamicDialogConfig,
		public dateService: DateService,
		public messageService: MessageService,
		public ref: DynamicDialogRef,
		public translate: TranslateService
	) {
		super(accountService, crudService, config, dateService, messageService, ref, translate);

		/*
			UInt64? p_kunde_ds_this_id = (ulong?)p_target["kunde_id"];
			UInt64? p_objekt_ds_this_id = (ulong?)p_target["objekt_id"];
			DateTime? p_date_von = (DateTime?)p_target["datum_von"];
			DateTime? p_date_bis = (DateTime?)p_target["datum_bis"];
			bool? p_Force = (bool?)p_target["force"];
		*/
		const optionGroup1 = new Map();
		optionGroup1.set('kunde_id', { type: 'dropdown', labelFieldName: 'bezeichnung', valueFieldName: 'ds_this_id', apiUrl: 'TblKunde', values: [] });
		optionGroup1.set('datum_von', { type: 'date_noTimespan' });
		optionGroup1.set('datum_bis', { type: 'date_noTimespan' });

		this.optionGroups = [optionGroup1];
	}
}
