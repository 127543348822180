import { ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DetailComponent } from '@app/common/templates/detail/detail.component';
import { Base } from '@app/models/base';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { ExportService } from '@app/services/export.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { MultiSelect } from 'primeng/multiselect';
import { Globals } from '@app/common/global_variables';
import { Module, Operation } from '@app/models/permission';
import { SettingsService } from '@app/services/settings.service';
import { ComponentToVehicleDialogComponent } from './component_to_vehicle_dialog/component_to_vehicle_dialog.component';
import { Policy } from '@app/models/policy';

@Component({
	//templateUrl: '../../../../common/templates/detail/detail.component.html',
	templateUrl: './detail.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/detail/style.scss'],
	providers: [DialogService, MessageService, ConfirmationService]
})
export class TblKomponenteDetailComponent extends DetailComponent {
	@ViewChild('table') table: Table;
	@ViewChild('colselection') colselection: MultiSelect;
	@ViewChild('emptyCanvas', { static: false }) emptyCanvas: ElementRef;
	entries: Base[];
	buttonColWidth: number = 150;
	cols: any[] = [];
	contentHeight: number = 4320;
	contextMenu: MenuItem[];
	count: number = 0;
	filters: string[];
	globalFilter: string = '';
	historyUrl: string = '';
	isMobile: boolean = false;
	isTableInit: boolean = false;
	minTableHeight: number = 80;
	name: string = '';
	possibleCols: any[] = [];
	selectedEntry: Base;
	tableState: any;
	tableStateName: string = '';
	createPermission: boolean = false;
	oldCols: any[] = [];
	isVisy: boolean = false;
	
	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public messageService: MessageService,
		public router: Router,
		public translate: TranslateService,
		private exportService: ExportService,
		public elRef: ElementRef,
		public globals: Globals,
		private settingsService: SettingsService,
		public changeDetectorRef: ChangeDetectorRef,
		public dialogService: DialogService,
	) {
		super(accountService, breadcrumbService, confirmationService, crudService, messageService, router, translate);

		this.isVisy = this.accountService.isAuthorized(Policy.ViSyUser);
		
		this.apiUrl = 'TblKomponente';
		this.name = 'MENU.KOMPONENTE';
		this.url = '/' + PATH.KOMPONENTE;
		this.fieldgroups.push(
			[
				{ type: 'text', key: 'ankey', required: true, width: 400 },
				{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
				{ type: 'text', key: 'bezeichnung_kurz', required: false, width: 400 },
				{ type: 'text', key: 'serial_no', required: false, width: 400 },
				{ type: 'text', key: 'lizenzkey_qt5', required: false, width: 400 },
				{ type: 'text', key: 'lizenzkey_lic_ki_lib', required: false, width: 400 },
				{ type: 'text', key: 'lizenzkey_visy_software', required: false, width: 400 },
				{ type: 'text', key: 'lizenzkey_sywatec_software', required: false, width: 400 },
				{ type: 'text', key: 'FREMD_komponente_typ_ankey', required: false, width: 400 },
				{ type: 'text', key: 'FREMD_komponente_typ_bezeichnung', required: false, width: 400 },
				{ type: 'text', key: 'FREMD_komponente_typ_bezeichnung_kurz', required: false, width: 400 },
				{ type: 'text', key: 'bemerkungen', required: false, width: 400 },
				{ type: 'numeric', key: 'feature_flags', required: false, width: 400 },
			]
		);
		this.breadcrumbService.setItems([
			{ label: 'MENU.STAMMDATEN' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.DETAIL', routerLink: [this.url + '/' + PATH.DETAIL + '/' + this.id] }
		]);

		this.historyUrl = '/' + PATH.FAHRZEUGAKTE_KOMPONENTE_LINK;
		this.possibleCols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'text', key: 'bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_fahrzeug_ankey', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_fahrzeug_kennzeichen', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_fahrzeug_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_fahrzeug_logbox_serial', required: false, width: 400 },
			{ type: 'text', key: 'zugeordnet_von_string', required: false, width: 400 },
			{ type: 'text', key: 'zugeordnet_bis_string', required: false, width: 400 },
			{ type: 'text', key: 'bemerkungen', required: false, width: 400 },
		];
		this.cols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'FREMD_fahrzeug_kennzeichen', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_fahrzeug_logbox_serial', required: false, width: 400 },
			{ type: 'text', key: 'zugeordnet_von_string', required: false, width: 400 },
			{ type: 'text', key: 'zugeordnet_bis_string', required: false, width: 400 },
			{ type: 'text', key: 'bemerkungen', required: false, width: 400 },
		];
	}

	ngOnInit() {
		this.loading += 1;

		this.createPermission = this.accountService.checkPermissions(Module.Masterdata, Operation.CREATE);
		this.readPermission = this.accountService.checkPermissions(Module.Masterdata, Operation.READ);
		this.updatePermission = this.accountService.checkPermissions(Module.Masterdata, Operation.UPDATE);
		this.deletePermission = this.accountService.checkPermissions(Module.Masterdata, Operation.DELETE);

		if (this.readPermission) {
			this.getEntry();
		}

		this.stateName = 'state' + this.apiUrl + 'Detail';
		this.retrieveTableState(this.stateName, this.state);
		this.setHorizontalGroups(((this.state && this.state.horizontalGroups !== null) ? this.state.horizontalGroups : true));

		this.tableStateName = 'state' + this.apiUrl + 'History' + 'List';
		this.retrieveTableState(this.tableStateName, this.tableState);
		if (this.tableState) {
			this.cols = [];
			if(this.tableState['columnOrder']) {
				this.tableState['columnOrder'].forEach(col => {
					this.possibleCols.forEach(c => {
						if (col == c.key) {
							this.cols.push(c);
						}
					});
				});
				if (this.tableState.filters.global) {
					this.globalFilter = this.tableState.filters.global.value;
				}
			}
		}

		this.isMobile = JSON.parse(this.globals.log_Platform).Mobile == 'yes' || JSON.parse(this.globals.log_Platform).Tablet == 'yes';
		this.translate.get('init').subscribe((text: string) => {
			this.contextMenu = [
				{ label: this.translate.instant('CONTEXT_MENU.OPEN'), icon: 'pi pi-fw pi-search', command: () => this.detailHistory() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_TAB'), icon: 'pi pi-fw pi-search', command: () => this.detailHistory('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_WINDOW'), icon: 'pi pi-fw pi-search', command: () => this.detailHistory('window') },
				{ label: this.translate.instant('CONTEXT_MENU.RESIZE'), icon: 'pi pi-fw', command: () => this.resizeTableWidthFromContent(true) },
			];
			this.possibleCols.forEach(c => {
				c.label = this.translate.instant('HEADERS.' + c.key);
			});
		});
		this.loading -= 1;
	}

	ngAfterViewInit() {
		this.settingsService.footerVisibilityChange.subscribe(value => {
			this.initTable();
		});

		this.oldCols = this.cols;
		this.filters = this.cols.map(c => c.key);

		if (this.table.filters) {
			let restoredFilter = false;
			this.filters.forEach(col => {
				Object.keys(this.table.filters[col]).forEach(filter => {
					if (this.table.filters[col][filter]['value'] != null) {
						restoredFilter = true;
					}
				})
			});
			if (restoredFilter) {
				this.messageService.add({ key: 'reset', severity: 'warn', summary: this.translate.instant('MESSAGES.WARNING'), detail: this.translate.instant('MESSAGES.LOADED_FILTER'), life: 10000 });
			}
		}

		const el = document.querySelector<HTMLElement>('.cdk-virtual-scroll-viewport');
		this.changeWheelSpeed(el, 0.9);

		this.initTable();
	}

	ngAfterViewChecked() {
		if (!this.isTableInit && this.table && this.table.value) {
			this.isTableInit = true;
			this.resizeTableWidthFromContent(false);
			this.changeDetectorRef.detectChanges();
		}
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.initTable();
	}

	detailHistory(target?: string) {
		if (target == 'window') {
			window.open('/#/' + this.historyUrl + '/' + PATH.DETAIL + '/' + this.selectedEntry.ds_this_id, '_blank', 'newWindow=1');
		} else if (target == 'tab') {
			window.open('/#/' + this.historyUrl + '/' + PATH.DETAIL + '/' + this.selectedEntry.ds_this_id);
		} else {
			this.router.navigate([this.historyUrl + '/' + PATH.DETAIL + '/' + this.selectedEntry.ds_this_id]);
		}
	}

	/* override */
	getEntry(): void {
		this.loading += 1;
		this.crudService.getEntry(this.apiUrl, this.id).then(res => {
			this.entry = res;
			this.fieldgroups.forEach(fg => {
				fg.forEach(field => {
					if (field.type == 'date') {
						if (this.entry[field.key] != null) {
							this.entry[field.key] = new Date(this.entry[field.key]);
						}
					}
				});
			});
			if (this.isVisy) {
				this.getHistory();
			}
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	getHistory() {
		this.loading += 1;
		this.messageService.clear('refresh');
		this.crudService.getVehicleComponentHistoryByComponent(this.id).then(res => {
			this.entries = res;
			this.count = this.entries.length;
			//this.possibleCols.forEach(c => {
			//	if (c.type == 'date') {
			//		this.entries.forEach(e => {
			//			if (e[c.key] != null) {
			//				e[c.key] = new Date(e[c.key]);
			//			}
			//		});
			//	}
			//});
			//this.resizeTableWidthFromContent(false);
			this.initTable();
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	// #region Table

	changeWheelSpeed(container, speedY) {
		var scrollY = 0;
		var handleScrollReset = function () {
			scrollY = container.scrollTop;
		};
		var handleMouseWheel = function (e) {
			e.preventDefault();
			scrollY += speedY * e.deltaY
			if (scrollY < 0) {
				scrollY = 0;
			} else {
				var limitY = container.scrollHeight - container.clientHeight;
				if (scrollY > limitY) {
					scrollY = limitY;
				}
			}
			container.scrollTop = scrollY;
		};

		var removed = false;
		container.addEventListener('mouseup', handleScrollReset, false);
		container.addEventListener('mousedown', handleScrollReset, false);
		container.addEventListener('mousewheel', handleMouseWheel, false);

		return function () {
			if (removed) {
				return;
			}
			container.removeEventListener('mouseup', handleScrollReset, false);
			container.removeEventListener('mousedown', handleScrollReset, false);
			container.removeEventListener('mousewheel', handleMouseWheel, false);
			removed = true;
		};
	}

	exportPDF() {
		this.exportService.exportPDF(this.translate.instant(this.tableStateName), this.table.value, this.cols);
	}

	exportXLSX() {
		this.exportService.exportXLSX(this.translate.instant(this.tableStateName), this.table.value, this.cols);
	}

	initTable() {
		this.contentHeight = 0;
		setTimeout(() => {
			const detailDiv = document.getElementById('details');
			if (detailDiv) {
				this.contentHeight = this.elRef.nativeElement.parentElement.offsetHeight - 320 - detailDiv.offsetHeight + ((localStorage.getItem('showFooter') === 'true') ? 5 : 0)
				if (this.entries && this.entries.length > 0) {
					let entryHeight;
					if (this.entries.length < 5) {
						entryHeight = this.minTableHeight + (this.entries.length * 45);
					} else {
						entryHeight = this.minTableHeight + (5 * 45);
					}
					if (entryHeight > this.contentHeight) {
						this.contentHeight = entryHeight;
					}
				} else {
					this.contentHeight = this.minTableHeight;
				}
			}
		}, 0);
		this.resizeTableWidthFromContent(false);
		//this.changeDetectorRef.detectChanges();
	}

	isColFiltered(col) {
		let isFiltered = false;
		if (this.table && this.table.filters[col.key]) {
			Object.keys(this.table.filters[col.key]).forEach(filter => {
				if (this.table.filters[col.key][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		else if (this.tableState && this.tableState.filters[col.key]) {
			Object.keys(this.tableState.filters[col.key]).forEach(filter => {
				if (this.tableState.filters[col.key][filter]['value'] != null) {
					isFiltered = true;
				}
			})
		}
		return isFiltered;
	}

	onFilter(event) {
		this.count = this.table.filteredValue ? this.table.filteredValue.length : this.entries.length;
	}

	onColReorder(event) {
		this.tableState = JSON.parse(localStorage.getItem(this.tableStateName));
		const columnWidths = this.tableState.columnWidths.split(',');
		columnWidths.splice(event.dropIndex, 0, columnWidths.splice(event.dragIndex, 1)[0]);
		this.tableState.columnWidths = columnWidths.join(',');
		localStorage.setItem(this.tableStateName, JSON.stringify(this.tableState));
	}

	onColResize(event) {
		const index = Array.from(event.element.parentNode.children).indexOf(event.element);
		this.tableState = JSON.parse(localStorage.getItem(this.tableStateName));
		this.cols[index].width = Number(event.element.style.width.split('px')[0]);
		this.tableState.columnWidths = (this.cols.map(c => c.width)).concat([this.buttonColWidth]).join(',');
		localStorage.setItem(this.tableStateName, JSON.stringify(this.tableState));

		this.resizeTableWidth(this.tableState);
	}

	resetTable() {
		this.table.clearState();
		window.location.reload();
	}

	resizeTableWidth(state?) {
		this.loading += 1;

		this.retrieveTableState(this.tableStateName, this.tableState);
		if (this.table) {
			const tableElement = document.getElementById(this.table.id);
			tableElement.style.width = '100%';
			const columnWidths = this.tableState ? this.tableState.columnWidths.split(',') : (this.cols.map(c => c.width)).concat([this.buttonColWidth]);
			const contentWidth = columnWidths.reduce((summe, element) => summe + Number(element), 0);
			const tableWidthOffset = tableElement.clientWidth - contentWidth;
			for (let index = 0; index < this.cols.length; index++) {
				this.cols[index].width = Number(columnWidths[index]);
			}
			if (tableWidthOffset > 0 && this.cols.length > 0) {
				this.cols[this.cols.length - 1].width += tableWidthOffset;
				if (this.contentHeight < (this.table.filteredValue ? this.table.filteredValue.length : (this.table.value ? this.table.value.length : 0)) * this.table.virtualRowHeight) {
					this.cols[this.cols.length - 1].width -= 10;
				}
			}

			document.getElementById(this.table.id + '-table').style.width = this.cols.reduce((summe, element) => summe + element.width, 0) + this.buttonColWidth + 'px';
			document.getElementById(this.table.id + '-table').style.minWidth = this.cols.reduce((summe, element) => summe + element.width, 0) + this.buttonColWidth + 'px';

			setTimeout(() => {
				if (this.tableState) {
					localStorage.setItem(this.stateName, JSON.stringify(this.tableState));
				}
			}, 0);
		}

		this.loading -= 1;
	}

	retrieveTableState(stateName, state?) {
		this.tableState = state ? state : JSON.parse(localStorage.getItem(stateName));
		if (this.table && (this.tableState == undefined)) {
			// for storage of table state
			this.table.saveState();
			// reload and parse
			this.tableState = JSON.parse(localStorage.getItem(stateName));
		}
	}

	toggleColumn(event) {
		this.tableState = JSON.parse(localStorage.getItem(this.tableStateName));
		this.tableState.columnOrder = event.value.map(c => c.key);
		this.tableState.columnWidths = event.value.map(c => c.width);
		this.tableState.columnWidths = this.tableState.columnWidths.join(',');
		this.tableState.columnWidths = this.tableState.columnWidths + ',' + this.buttonColWidth;
		this.tableState.tableWidth = (this.tableState.columnWidths.split(',')).reduce((summe, element) => summe + Number(element), 0) + 'px';
		this.filters = event.value.map(c => c.key);
		localStorage.setItem(this.tableStateName, JSON.stringify(this.tableState));
		this.resizeTableWidth(this.tableState);
	}

	// liefert die länge der darstellung eines textes in pixeln zurück 
	getTextLength(text: string, styleFont: string) {
		const ctx = this.emptyCanvas.nativeElement.getContext('2d');
		ctx.font = styleFont;
		const textMetrics = ctx.measureText(text);
		return Math.round(textMetrics.actualBoundingBoxLeft + textMetrics.actualBoundingBoxRight);
	}

	// wenn eine spalte noch ein special symbol im header hat
	adaptColumnSize(maxStringLength, columnkey) {
		return maxStringLength;
	}

	// berechnet die optimale spaltenbreite für die tabelle in abhängigkeit vom inhalt
	resizeTableWidthFromContent(bForce, state?) {
		var bResize = bForce;

		this.loading += 1;
		try {
			// code aus retrieveTableState, muss hier separat gemacht werden damit man bResize korrekt setzen kann
			this.tableState = state ? state : JSON.parse(localStorage.getItem(this.tableStateName));
			if (this.tableState == undefined) {
				// force storage of table state
				this.table.saveState();
				// reload state
				this.tableState = JSON.parse(localStorage.getItem(this.tableStateName));
				bResize = true;
			}

			if (this.table && bResize) {
				// autosize columns
				const lTable = document.getElementById(this.table.id);
				var lTableFont = window.getComputedStyle(lTable, null).getPropertyValue('font');
				// für alle spalten, alle daten            
				this.cols.forEach(col => {
					let columnname = this.translate.instant('HEADERS.' + col.key);
					let maxStringLength = this.getTextLength(columnname, lTableFont);
					// filter symbol
					maxStringLength = maxStringLength + 80;
					maxStringLength = this.adaptColumnSize(maxStringLength, col.key);
					if (this.entries) {
						this.entries.forEach(row => {
							let newLength = 0;
							if (col.type == 'date') {
								if (row[col.key] != undefined)
									newLength = this.getTextLength(row[col.key].toLocaleString(), lTableFont);
								else
									newLength = 0;
							} else {
								newLength = this.getTextLength(row[col.key], lTableFont);
							}
							// margins zur zelle
							newLength = newLength + 26;
							if (newLength > maxStringLength)
								maxStringLength = newLength;
						})
					}

					col.width = maxStringLength;
				});

				this.tableState.columnWidths = (this.cols.map(c => c.width)).concat([this.buttonColWidth]).join(',');
			}

			// standard funktion aufrufen
			this.resizeTableWidth(this.tableState);
		} catch {}
		finally {
			this.loading -= 1;
		}
	}

	fahrzeugZuweisung() : void {
		if( this.entry.ds_this_id ) {
			// Popup mit Fahrzeugauswahl, Datum von, Datum bis, Bemerkungen
			const ref = this.dialogService.open(ComponentToVehicleDialogComponent, {
				header: this.translate.instant('HEADERS.COMPONENT_SET_VEHICLE'),
				width: '70%',
				data: { buttonLabel: 'Zuweisen' }
			});

			ref.onClose.subscribe((filters) => {
				if (filters) {
					this.loading += 1;
					let callParams = {
						komponente_id: this.entry.ds_this_id,
						fahrzeug_id: filters['fahrgestellnummer'],
						datum_von: filters['COMPONENT_LINK_FROM'],
						datum_bis: filters['COMPONENT_LINK_TO'],
						bemerkungen: filters['COMPONENT_LINK_COMMENTS']
					}
					this.crudService.addVehicleComponent(callParams).then(res => {
						if(res && res >= 1) {
							this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
							this.getHistory();
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 5000 });
						}
					}).catch(err => {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 5000 });
						err.error.forEach(e => {
							this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 7000 });
						})
					}).finally(() => {
						this.loading -= 1;
					});
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.SaveFailed'), detail: this.translate.instant('ERRORCODE.SaveFailed'), life: 5000 });
				}
			});
		}
	}

	// #endregion Table
}
