import { Component, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { Base } from '@app/models/base';
import { Module, Operation } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { DateService } from '@app/services/date.service';
import { TblAbfuhrrhythmusCreateComponent } from '@app/views/bhv/tbl_abfuhrrhythmus/create/create.component';
import { TblGefaessTypCreateComponent } from '@app/views/bhv/tbl_gefaess_typ/create/create.component';
import { TblObjektCreateComponent } from '@app/views/bhv/tbl_objekt/create/create.component';
import { TblAbfallartCreateComponent } from '@app/views/stammdaten/tbl_abfallart/create/create.component';
import { TblVolumenCreateComponent } from '@app/views/stammdaten/tbl_volumen/create/create.component';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TblGefaessStatusCreateComponent } from '../../tbl_gefaess_status/create/create.component';
import { TblGefaessZustandCreateComponent } from '../../tbl_gefaess_zustand/create/create.component';

@Component({
	selector: 'bhv-garbagecans-create',
	templateUrl: './create.component.html',
	styleUrls: ['../style.scss'],
	providers: [DialogService, MessageService, ConfirmationService]
})
export class TblGefaessCreateComponent implements OnInit {
	showPostal: boolean;
	moveToObject = false;
	objectField: any;
	apiUrl: string = '';
	createPermission: boolean = false;
	entry: Base = {};
	fieldgroups: any[] = [];
	id: number;
	isMobile: boolean = false;
	loading: number = 0;
	name: string = '';
	options: any = {};
	readPermission: boolean = false;
	state: any;
	stateName: string = '';
	url: string = '';

	constructor(
		public accountService: AccountService,
		private breadcrumbService: BreadcrumbService,
		private crudService: CRUDService,
		public dialogService: DialogService,
		private messageService: MessageService,
		private router: Router,
		public translate: TranslateService,
		@Optional() public config: DynamicDialogConfig,
		public confirmationService: ConfirmationService,
		@Optional() public ref: DynamicDialogRef,
		public globals: Globals,
	) {
		const href = this.router.url.split('/');
		this.id = +href[href.length - 1];

		this.apiUrl = 'TblGefaess';
		this.name = 'MENU.GEFAESS';
		this.url = '/' + PATH.GEFAESS;

		this.breadcrumbService.setItems([
			{ label: 'MENU.BHV' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.CREATE', routerLink: [this.url + '/' + PATH.CREATE] }
		]);

		this.fieldgroups = [
			[
				{ type: 'text', key: 'ankey', required: true, width: 400 },
				{ type: 'text', key: 'barcode', required: true, width: 400 },
				{ type: 'text', key: 'bezeichnung', required: false, width: 400 },
				{ type: 'text', key: 'bemerkung', required: false, width: 400 },
			],
			[
				{ type: 'object', key: 'abfallart_id', required: false, width: 400, apiUrl: 'TblAbfallart', createComponent: TblAbfallartCreateComponent },
				{ type: 'object', key: 'volumen_id', required: false, width: 400, apiUrl: 'TblVolumen', createComponent: TblVolumenCreateComponent },
				{ type: 'object', key: 'typ_id', required: false, width: 400, apiUrl: 'TblGefaessTyp', createComponent: TblGefaessTypCreateComponent },
				{ type: 'object', key: 'zustand_id', required: false, width: 400, apiUrl: 'TblGefaessZustand', createComponent: TblGefaessZustandCreateComponent },
				{ type: 'object', key: 'status_id', required: false, width: 400, apiUrl: 'TblGefaessStatus', createComponent: TblGefaessStatusCreateComponent },
				{ type: 'object', key: 'abfuhrrythmus_id', required: false, width: 400, apiUrl: 'TblAbfuhrrhythmus', createComponent: TblAbfuhrrhythmusCreateComponent },
			],
		];
		this.objectField = { type: 'object', key: 'objekt_id', required: false, width: 400, apiUrl: 'TblObjekt', createComponent: TblObjektCreateComponent };

	}


	// #region Angular

	ngOnInit() {
		this.loading += 1;

		this.createPermission = this.accountService.checkPermissions(Module.Masterdata, Operation.CREATE);
		this.readPermission = this.accountService.checkPermissions(Module.Masterdata, Operation.READ);

		this.showPostal = (localStorage.getItem('showPostalInObjectDropdown') === 'true');
		this.isMobile = JSON.parse(this.globals.log_Platform).Mobile == 'yes' || JSON.parse(this.globals.log_Platform).Tablet == 'yes';

		this.stateName = 'state' + this.apiUrl + 'Create';
		this.state = JSON.parse(localStorage.getItem(this.stateName));
		this.setHorizontalGroups(((this.state && this.state.horizontalGroups !== null) ? this.state.horizontalGroups : true));

		if (this.readPermission) {
			this.getOptions();
		}

		this.loading -= 1;
	}

	// #endregion Angular

	// #region CRUD

	createEntry() {
		if (this.checkRequiredFields()) {
			this.loading += 1;
			this.fieldgroups.forEach(fg => {
				fg.forEach(field => {
					if (field.type == 'list') {
						let listString = '';
						this.entry[field.key].forEach(element => {
							listString += element + ',';
						});
						this.entry[field.key] = listString.slice(0, -1);
					}
				});
			});
			this.crudService.createEntry(this.apiUrl, this.entry).then(res => {
				this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
				if (this.moveToObject) {
					this.loading += 1;
					const target = {
						gefaess_id: res,
						objekt_id: this.entry['FREMD_objekt_id'],
						datum_von: this.entry['zugeordnet_von'],
						datum_bis: this.entry['zugeordnet_bis']
					};
					this.crudService.moveGarbageCan(target).then(res => {
						this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
						if (this.ref) {
							this.ref.close(true);
						} else {
							if (res) {
								this.router.navigate([this.url + '/' + PATH.DETAIL + '/' + res]);
							} else {
								this.router.navigate([this.url + '/' + PATH.LIST]);
							}
						}
					}).catch(err => {
						err.error.forEach(e => {
							this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
						})
					}).finally(() => {
						this.loading -= 1;
					});
				} else {
					if (this.ref) {
						this.ref.close(true);
					} else {
						if (res) {
							this.router.navigate([this.url + '/' + PATH.DETAIL + '/' + res]);
						} else {
							this.router.navigate([this.url + '/' + PATH.LIST]);
						}
					}
				}
			}).catch(err => {
				err.error.forEach(e => {
					this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
				})
			}).finally(() => {
				this.loading -= 1;
			});
		}
	}

	// #endregion CRUD

	// #region Spezial

	stringIsNullOrWhitespace(value): boolean {
		if (value !== null && value !== undefined) {
			let val = value + '';
			if (val.trim().length > 0) {
				return false;
			}
		}
		return true;
	}

	/**
	 * Schaltet die Sortierung eines Dropdowns oder Multiselects um
	 * 
	 * @param field Maskenfeld, für das die Sortierung geändert werden soll
	 */
	changeSorting(field: any, init?: boolean) {
		if (init) {
			this.state = JSON.parse(localStorage.getItem(this.stateName));
			if (this.state && this.state.optionSortFields) {
				field.optionSortField = this.state.optionSortFields[field.key] ? this.state.optionSortFields[field.key].optionSortField : 'ankey';
				field.sortDescending = this.state.optionSortFields[field.key] ? this.state.optionSortFields[field.key].sortDescending : false;
			} else {
				this.state = this.state ? this.state : {};
				this.state.optionSortFields = {};
				field.optionSortField = 'ankey';
				field.sortDescending = false;
			}
		}

		if (!field.sortDescending) {
			this.options[field.key].sort((a, b) => (b.object[field.optionSortField] < a.object[field.optionSortField]) ? 1 : -1);
		} else {
			this.options[field.key].sort((a, b) => (b.object[field.optionSortField] > a.object[field.optionSortField]) ? 1 : -1);
		}
		this.state.optionSortFields[field.key] = { optionSortField: field.optionSortField, sortDescending: field.sortDescending };
		localStorage.setItem(this.stateName, JSON.stringify(this.state));
		this.options[field.key] = this.options[field.key].map(option => ({ label: (field.optionSortField == 'bezeichnung' ? option.object.bezeichnung : option.object.bezeichnung + ' (' + option.object[field.optionSortField] + ')'), value: option.value, object: option.object }));
		this.options[field.key] = [...this.options[field.key]];
	}

	checkRequiredFields(): boolean {
		let valid = true;
		this.fieldgroups.forEach(fg => {
			fg.forEach(field => {
				if (field.required && this.stringIsNullOrWhitespace(this.entry[field.key])) {
					valid = false;
					this.messageService.add({ severity: 'error', summary: this.translate.instant('MESSAGES.ERROR'), detail: this.translate.instant('MESSAGES.REQUIRED_FIELD', { field: this.translate.instant(field.key) }), life: 3000 });
				}
			});
		});
		if (this.moveToObject) {
			if (!this.entry['FREMD_objekt_id']) {
				this.messageService.add({ severity: 'error', summary: this.translate.instant('MESSAGES.ERROR'), detail: this.translate.instant('MESSAGES.REQUIRED_FIELD', { field: this.translate.instant('FREMD_objekt_id') }), life: 3000 });
				return false;
			}
			if (!this.entry['zugeordnet_von']) {
				this.messageService.add({ severity: 'error', summary: this.translate.instant('MESSAGES.ERROR'), detail: this.translate.instant('MESSAGES.REQUIRED_FIELD', { field: this.translate.instant('zugeordnet_von') }), life: 3000 });
				return false;
			}
		}
		return valid;
	}

	createOption(field) {
		const ref = this.dialogService.open(field.createComponent, {
			header: this.translate.instant('BREADCRUMBS.CREATE'),
			width: '70%'
		});

		ref.onClose.subscribe((success: boolean) => {
			if (success) {
				this.getOptions();
				this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
			}
		});
	}

	/**
	 * Ordnet das Sortierfeld im Multiselect über dem Filterfeld an
	 */
	formatMultiselectLayout() {
		const msHeaderElements = document.getElementsByClassName('p-multiselect-header');
		Array.from(msHeaderElements).forEach(msHeaderElement => {
			(msHeaderElement as HTMLElement).style.flexWrap = 'wrap';
		});
	}

	getOptions() {
		this.fieldgroups.forEach(fg => {
			fg.forEach(field => {
				if (field.type == 'object' || field.type == 'list') {
					this.loading += 1;
					this.crudService.getAllEntries(field.apiUrl).then(res => {
						this.options[field.key] = res.map(option => ({ label: option.bezeichnung, value: option.ds_this_id, object: option }));
						field.optionSortFieldOptions = Object.keys(res[0]).map(key => ({ label: this.translate.instant('HEADERS.' + key), value: key }));
						field.optionSortFieldOptions = field.optionSortFieldOptions.filter(option => !option.label.includes('HEADERS.'));
						this.changeSorting(field, true);
					}).catch(err => {
						err.error.forEach(e => {
							this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
						})
					}).finally(() => {
						this.loading -= 1;
					});
				}
			});
		});

		this.loading += 1;
		this.crudService.getAllEntries(this.objectField.apiUrl).then(res => {
			this.options[this.objectField.key] = res;
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	inputDate(dateField, input: string) {
		let date = DateService.inputDateExStatic(input);
		if (date) {
			this.entry[dateField] = date;
		}
	}

	setHorizontalGroups(value: boolean) {
		this.state = JSON.parse(localStorage.getItem(this.stateName));
		if (!this.state) {
			this.state = {};
		}
		this.state.horizontalGroups = value;
		localStorage.setItem(this.stateName, JSON.stringify(this.state));
	}

	// #endregion Spezial
}