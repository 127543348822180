<!-- Message -->
<p-toast>
	<ng-template let-message pTemplate="message">
		<div class="p-grid p-ml-2">
			<div class="p-col-2">
				<i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
			</div>
			<div class="p-col-10">
				<h4>{{message.summary}}</h4>
			</div>
			<div class="p-col-12">
				<p>{{message.detail}}</p>
			</div>
			<div *ngIf="message.severity!='success'" class="p-col-12">
				<a class="p-button"
					href="mailto:support@visy-gmbh.de?subject=Fehlermeldung%20VION&amp;body={{message.summary}}%0A%0A{{message.detail}}%0D%0A%0D%0AName:%0D%0AEmail:%0D%0ATelefon:%0D%0AKommentar:%0D%0A%0D%0A%0D%0AZum%20besseren%20Verständnis%20wenn%20möglich%20bitte%20Screenshots%20beifügen.">
					Fehler melden
				</a>
			</div>
		</div>
	</ng-template>
</p-toast>

<!-- Spinner -->
<div *ngIf="loading!==0" class="spinner">
	<span class="ball-1"></span>
	<span class="ball-2"></span>
	<span class="ball-3"></span>
	<span class="ball-4"></span>
	<span class="ball-5"></span>
	<span class="ball-6"></span>
	<span class="ball-7"></span>
	<span class="ball-8"></span>
</div>

<!-- Content -->
<div class="p-grid p-formgrid">
	<div *ngFor="let options of optionGroups" class="p-col-12 card p-mb-2">
		<div class="p-grid p-formgrid">
			<div *ngFor="let option of options | keyvalue: unsorted" class="p-col-12 p-md-6 p-lg-4 p-my-2">
				<!--{{ (field.sub ? ('HEADERS.' + field.sub + '.' + field.key) : ('HEADERS.' + field.key)) | translate }}-->
				<!--<h6 class="p-my-2" translate>{{'HEADERS.' + option.key}}</h6>-->
				<h6 class="p-my-2" translate>{{ (option.value.sub ? ('HEADERS.' + option.value.sub + '.' + option.key) : ('HEADERS.' + option.key)) }}</h6>
				<div *ngIf="option.value.type == 'text'">
					<input type="text" pInputText style="width:100%" [(ngModel)]="selections[option.key]" />
				</div>
				<div *ngIf="option.value.type == 'numeric'">
					<p-inputNumber [style]="{'width':'100%'}" [(ngModel)]="selections[option.key]" [showButtons]="true"
						[min]="option.value.min" [max]="option.value.max" [step]="option.value.step" mode="decimal"
						locale="de-DE"></p-inputNumber>
				</div>
				<div *ngIf="option.value.type == 'boolean'">
					<p-triStateCheckbox [(ngModel)]="selections[option.key]"></p-triStateCheckbox>
				</div>
				<div *ngIf="option.value.type == 'date'">
					<p-calendar [style]="{'width':'100%'}" [showIcon]="true" dateFormat="dd.mm.yy" keepInvalid="true"
						(onInput)="inputDate(option.key, $event.target.value)" appendTo="body"
						[(ngModel)]="selections[option.key]"
						(onSelect)="selections[option.value.timespanFieldName] = null">
					</p-calendar>
				</div>
				<div *ngIf="option.value.type == 'date_noTimespan'">
					<p-calendar [style]="{'width':'100%'}" [showIcon]="true" dateFormat="dd.mm.yy" keepInvalid="true"
						(onInput)="inputDate(option.key, $event.target.value)" appendTo="body"
						[(ngModel)]="selections[option.key]">
					</p-calendar>
				</div>
				<div *ngIf="option.value.type == 'timespan'">
					<p-dropdown [style]="{'width':'100%'}" [options]="option.value.values"
						[(ngModel)]="selections[option.key]" appendTo="body"
						(onChange)="convertTimespanSelection($event, option.value.fromDateFieldName, option.value.toDateFieldName)"
						[filter]="false" [filterBy]="option.value.labelFieldName" placeholder="-" [showClear]="true"
						dropdownDirective></p-dropdown>
				</div>
				<div *ngIf="option.value.type == 'dropdown'">
					<p-dropdown [style]="{'width':'100%'}" [options]="option.value.values" appendTo="body"
						[virtualScroll]="true" itemSize="30" [optionLabel]="option.value.labelFieldName"
						[optionValue]="option.value.valueFieldName" [(ngModel)]="selections[option.key]" [filter]="true"
						[filterBy]="option.value.labelFieldName" placeholder="-" [showClear]="true" dropdownDirective>
					</p-dropdown>
				</div>
				<div *ngIf="option.value.type == 'multiselect'">
					<p-multiSelect [options]="option.value.values" [(ngModel)]="selections[option.key]"
						[optionLabel]="option.value.labelFieldName" [optionValue]="option.value.valueFieldName"
						appendTo="body" placeholder="-" [virtualScroll]="true" itemSize="30" [filter]="true"
						[filterBy]="option.value.labelFieldName" [style]="{'width':'100%'}" multiSelectDirective>
					</p-multiSelect>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="commitButtonVisible" class="p-col-12">
		<!--<button pButton pRipple label="{{'BUTTONS.LOAD'|translate}}" class="p-button p-mt-2" style="width:100%"
			(click)="load()"></button>-->
			<button pButton pRipple [label]="commitButtonLabel" class="p-button p-mt-2" style="width:100%"
			(click)="load()"></button>
	</div>
</div>