import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Config } from '@models/config';

@Injectable()
export class ConfigProvider {
	constructor(
		private http: HttpClient
	) {
	}

	/**
	 * Zieht environment Daten aus der config.json
	 */
	load(): any {
		return new Promise((resolve, reject) => {
			this.http.get('/assets/config/config.json')
				.toPromise()
				.then(data => data as Config)
				.then(data => {
					environment.apiUrl = data.apiUrl;
					environment.appUrl = data.appUrl;
					environment.mapUrl = data.mapUrl;
					environment.logUrl = data.logUrl;
					environment.overpassUrl = data.overpassUrl;

					this.http.get('/assets/version/version.json')
						.toPromise()
						.then(data => data as Config)
						.then(data => {
							environment.appVersion = data.version;

							this.http.get('/assets/version/revision.json')
								.toPromise()
								.then(data => data as Config)
								.then(data => {
									environment.appRevision = data.revision;
									resolve(true);
								});
						});
				});

		})
	}
}
