import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { ListComponent } from '@app/common/templates/list/list.component';
import { Module } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { ExportService } from '@app/services/export.service';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
	templateUrl: './list_aktenzuweisung.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/list/style.scss'],
	providers: [MessageService, ConfirmationService, DialogService]
})
export class TblRueckfahrkatasterAufzeichnungAktenzuweisungListComponent extends ListComponent {
	resultData: any = {};
	commitButtonLabel: string = "An gewählte Akte zuweisen und öffnen";
	commitButtonLabel2: string = "Neue Akte erzeugen und öffnen";
	commitButtonVisible: boolean = true;

	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public changeDetectorRef: ChangeDetectorRef,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public elRef: ElementRef,
		public exportService: ExportService,
		public globals: Globals,
		public messageService: MessageService,
		public router: Router,
		public settingsService: SettingsService,
		public translate: TranslateService,
		public dialogService: DialogService,
		public config: DynamicDialogConfig,
		public ref: DynamicDialogRef
	) {
		super(accountService, breadcrumbService, changeDetectorRef, confirmationService, crudService, elRef, exportService, globals, messageService, router, settingsService, translate);

		this.apiUrl = 'TblRueckfahrkatasterAufzeichnung';
		this.name = 'MENU.AUFZEICHNUNG';
		this.url = '/' + PATH.RK_AUFZEICHNUNG;
		this.module = Module.ReversingCadastral;

		this.possibleCols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'FREMD_strecke_ankey', required: true, width: 400 },
			{ type: 'boolean', key: 'VIRTUAL_flag_is_always_candidate', required: false, width: 400 },
			{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
			{ type: 'text', key: 'bezeichnung_kurz', required: false, width: 400 },
			{ type: 'text', key: 'name', required: true, width: 400 },
			{ type: 'numeric', key: 'plz_von', required: false, width: 400 },
			{ type: 'text', key: 'ort_von', required: false, width: 400 },
			{ type: 'text', key: 'strasse_von', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_von', required: false, width: 400 },
			{ type: 'numeric', key: 'plz_bis', required: false, width: 400 },
			{ type: 'text', key: 'ort_bis', required: false, width: 400 },
			{ type: 'text', key: 'strasse_bis', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_bis', required: false, width: 400 },
		];
		this.cols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'FREMD_strecke_ankey', required: true, width: 400 },
			{ type: 'boolean', key: 'VIRTUAL_flag_is_always_candidate', required: false, width: 400 },
			//{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
			{ type: 'text', key: 'name', required: true, width: 400 },
			{ type: 'numeric', key: 'plz_von', required: false, width: 400 },
			{ type: 'text', key: 'ort_von', required: false, width: 400 },
			{ type: 'text', key: 'strasse_von', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_von', required: false, width: 400 },
			{ type: 'numeric', key: 'plz_bis', required: false, width: 400 },
			{ type: 'text', key: 'ort_bis', required: false, width: 400 },
			{ type: 'text', key: 'strasse_bis', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_bis', required: false, width: 400 },
		];

		this.breadcrumbService.setItems([
			{ label: 'MENU.RUECKFAHRKATASTER' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.LIST', routerLink: [this.url + '/' + PATH.LIST] }
		]);

		this.hasFilterDialog = false; // natürlich nicht; aber get_entries überschreiben, bekommt die Daten ja vorher übergeben

		//let fieldKey: string = 'manualData' + (i+1).toString();
		//if( this.config.data[fieldKey] ) {
		//	this.manualData[i] = this.config.data[fieldKey];
		//}
	}

	getAllEntries(): void {
		this.entries = [];
		if( this.config.data['candidateFiles'] != null && this.config.data['candidateFiles'] != undefined ) {
			this.processEntries(this.config.data['candidateFiles']);
		}
	}

	processEntries(entries: any): void {
		this.loading += 1;
		this.possibleCols.forEach(c => {
			if (c.type == 'date') {
				entries.forEach(e => {
					if (e[c.key] != null) {
						e[c.key] = new Date(e[c.key]);
					}
				});
			}
		});
		this.entries = entries;
		this.count = this.entries.length;
		this.loading -= 1;

		// test
		//this.ref.close(this.resultData);
	}

	commitAktenzuweisung() {
		if( this.selectedEntry ) {
			this.resultData['createNew'] = false;
			this.resultData['selectedEntry'] = this.selectedEntry;
			this.ref.close(this.resultData);
		}
	}

	commitAktenNeuanlage() {
		this.resultData['createNew'] = true;
		this.resultData['selectedEntry'] = null;
		this.ref.close(this.resultData);
	}

	/**
	 * OVERRIDE - Passt die Tabellenhöhe und -breite an den verfügbaren PLatz an, um den Bildschrim auszufüllen
	 */
	initTable(): void {
		setTimeout(() => {
			this.contentHeight = 100;
		}, 0);
		setTimeout(() => {
			this.contentHeight = this.elRef.nativeElement.parentElement.offsetHeight - 200 + ((localStorage.getItem('showFooter') === 'true') ? 5 : 0)
			//console.log('special table contentHeight:');
			//console.log(this.contentHeight);
			if (this.contentHeight < 100) {
				this.contentHeight = 100;
			}
		}, 0);
		this.resizeTableWidthFromContent(false);
		this.changeDetectorRef.detectChanges();
	}
}
