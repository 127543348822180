import { Component, OnInit, Optional, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { Policy } from '@app/models/policy';
import { VehicleFile, tbl_fahrzeug } from '@app/models/tbl_fahrzeug';
import { AccountService } from '@app/services/account.service';
import { DateService } from '@app/services/date.service';
import { TblFirmaCreateComponent } from '@app/views/bhv/tbl_firma/create/create.component';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { CRUDService } from '@services/crud.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OverlayPanel } from 'primeng/overlaypanel';
//import { MasterdataChassisCreateDialog } from './createChassis/create.component';
//import { MasterdataConstructionsCreateDialog } from './createConstruction/create.component';
//import { MasterdataLifterCreateDialog } from './createLifter/create.component';
import { TblLifterCreateComponent } from '../../tbl_lifter/create/create.component';
import { TblAufbauCreateComponent } from '../../tbl_aufbau/create/create.component';
import { TblFahrgestellCreateComponent } from '../../tbl_fahrgestell/create/create.component';
import { Module, Operation } from '@app/models/permission';

@Component({
	templateUrl: './create.component.html',
	styleUrls: ['../style.scss'],
	providers: [DialogService, MessageService, ConfirmationService]
})
export class TblFahrzeugCreateComponent implements OnInit {
	//@ViewChild('createNewDataset') createNewDataset: OverlayPanel;
	autoGenerateLogboxId = false;
	basefields: any[];
	fieldgroups: any[];
	iframePath;
	isMobile: boolean = false; // Ist das Portal auf einem mobilen Browser 
	isVisy = false;
	loading = 0;
	options: any;
	url: string = '';
	vehicle: tbl_fahrzeug;
	vehicleFile: VehicleFile = {};
	vehiclefilefields1: any[];
	vehiclefilefields2: any[];
	vehiclefilefields3: any[];
	vehiclefilefields4: any[];
	state: any;
	stateName: string = '';
	readPermission: boolean = false;
	createPermission: boolean = false;

	constructor(
		private accountService: AccountService,
		private breadcrumbService: BreadcrumbService,
		private crudService: CRUDService,
		public dialogService: DialogService,
		public globals: Globals,
		private messageService: MessageService,
		private router: Router,
		public translate: TranslateService,
		@Optional() public ref: DynamicDialogRef,
		@Optional() public config: DynamicDialogConfig,
	) {
		this.url = '/' + PATH.FAHRZEUG;
		this.breadcrumbService.setItems([
			{ label: 'MENU.STAMMDATEN' },
			{ label: 'MENU.FAHRZEUGE', routerLink: [this.url] },
			{ label: 'BREADCRUMBS.CREATE', routerLink: [this.url + '/' + PATH.CREATE] }
		]);

		this.basefields = [
			{ parent: 'vehicle', type: 'string', key: 'ankey', label: 'ankey', required: false },
			{ parent: 'vehicle', type: 'string', key: 'bezeichnung', label: 'bezeichnung', required: false },
			{ parent: 'vehicle', type: 'string', key: 'kennzeichen', label: 'kennzeichen', required: true },
			{ parent: 'vehicle', type: 'string', key: 'logbox_serial', label: 'logbox_serial', required: false },
			{ parent: 'vehicle', type: 'string', key: 'fahrgestellnummer', label: 'fahrgestellnummer', required: true },
			{ parent: 'vehicle', type: 'string', key: 'bemerkungen', label: 'bemerkungen', required: false },
			//{ parent: 'vehicle', type: 'dropdown', key: 'fahrgestell_id', label: 'fahrgestell', required: false },
			{ parent: 'vehicle', type: 'object', key: 'fahrgestell_id', label: 'fahrgestell', required: false, width: 400, apiUrl: 'TblFahrgestell', createComponent: TblFahrgestellCreateComponent },
			//{ parent: 'vehicle', type: 'dropdown', key: 'aufbau_id', label: 'aufbau', required: false },
			{ parent: 'vehicle', type: 'object', key: 'aufbau_id', label: 'aufbau', required: false, width: 400, apiUrl: 'TblAufbau', createComponent: TblAufbauCreateComponent },
			//{ parent: 'vehicle', type: 'dropdown', key: 'lifter_id', label: 'lifter', required: false },
			{ parent: 'vehicle', type: 'object', key: 'lifter_id', label: 'lifter', required: false, width: 400, apiUrl: 'TblLifter', createComponent: TblLifterCreateComponent },
		];

		this.vehiclefilefields1 = [
			//{ parent: 'vehicle', type: 'dropdown', key: 'firma_id', label: 'FREMD_firma_bezeichnung' },
			{ parent: 'vehicle', type: 'object', key: 'firma_id', label: 'FREMD_firma_bezeichnung', required: false, width: 400, apiUrl: 'TblFirma', createComponent: TblFirmaCreateComponent },
			{ parent: 'vehicleFile', type: 'string', key: 'lexware_projekt_nummer', label: 'lexware_projekt_nummer' },
			{ parent: 'vehicleFile', type: 'string', key: 'bemerkung', label: 'bemerkung' },
			{ parent: 'vehicleFile', type: 'boolean', key: 'vorbereitung_set_1', label: 'vorbereitung_set_1' },
			{ parent: 'vehicleFile', type: 'boolean', key: 'vorbereitung_set_2', label: 'vorbereitung_set_2' },
			{ parent: 'vehicleFile', type: 'boolean', key: 'vorbereitung_set_3', label: 'vorbereitung_set_3' },
			{ parent: 'vehicleFile', type: 'boolean', key: 'datenaufzeichnung', label: 'datenaufzeichnung' },
			{ parent: 'vehicleFile', type: 'boolean', key: 'rueckfahrkataster', label: 'rueckfahrkataster' },
			{ parent: 'vehicleFile', type: 'date', key: 'rueckfahrkataster_bis', label: 'rueckfahrkataster_bis' },
			{ parent: 'vehicleFile', type: 'string', key: 'simkartennummer', label: 'simkartennummer' },
			{ parent: 'vehicleFile', type: 'date', key: 'simkarte_bis', label: 'simkarte_bis' },
		];

		this.vehiclefilefields2 = [
			{ parent: 'vehicleFile', type: 'string', key: 'aufbau_ort', label: 'aufbau_ort' },
			{ parent: 'vehicleFile', type: 'date', key: 'anlieferung', label: 'anlieferung' },
			{ parent: 'vehicleFile', type: 'date', key: 'aufbau_start', label: 'aufbau_start' },
			{ parent: 'vehicleFile', type: 'date', key: 'aufbau_ende', label: 'aufbau_ende' },
			{ parent: 'vehicleFile', type: 'date', key: 'abholung', label: 'abholung' },
		];

		this.vehiclefilefields3 = [
			{ parent: 'vehicleFile', type: 'boolean', key: 'rueckfahrassistent', label: 'rueckfahrassistent' },
			{ parent: 'vehicleFile', type: 'boolean', key: 'unterlaufschutz_seitenlader', label: 'unterlaufschutz_seitenlader' },
			{ parent: 'vehicleFile', type: 'boolean', key: 'abbiege_rechts_180_grad', label: 'abbiege_rechts_180_grad' },
			{ parent: 'vehicleFile', type: 'boolean', key: 'abbiege_rechts_3d', label: 'abbiege_rechts_3d' },
			{ parent: 'vehicleFile', type: 'boolean', key: 'abbiege_rechts_kamera', label: 'abbiege_rechts_kamera' },
			{ parent: 'vehicleFile', type: 'boolean', key: 'abbiege_rechts_radar', label: 'abbiege_rechts_radar' },
			{ parent: 'vehicleFile', type: 'boolean', key: 'abbiege_links_3d', label: 'abbiege_links_3d' },
			{ parent: 'vehicleFile', type: 'boolean', key: 'abbiege_links_kamera', label: 'abbiege_links_kamera' },
			{ parent: 'vehicleFile', type: 'boolean', key: 'abbiege_links_radar', label: 'abbiege_links_radar' },
		];

		this.vehiclefilefields4 = [
			{ parent: 'vehicleFile', type: 'boolean', key: 'canbus_fms', label: 'canbus_fms' },
			{ parent: 'vehicleFile', type: 'boolean', key: 'canbus_tail', label: 'canbus_tail' },
			{ parent: 'vehicleFile', type: 'boolean', key: 'canbus_faun', label: 'canbus_faun' },
			{ parent: 'vehicleFile', type: 'boolean', key: 'canbus_hs', label: 'canbus_hs' },
			{ parent: 'vehicleFile', type: 'boolean', key: 'canbus_radar', label: 'canbus_radar' },
		];

		this.fieldgroups = [
			{ title: 'Fahrzeug', fields: this.basefields }
		];

		this.isVisy = this.accountService.isAuthorized(Policy.ViSyUser);
		if (this.isVisy) {
			this.fieldgroups.push(
				{ title: 'Fahrzeugakte', fields: this.vehiclefilefields1 },
				{ title: 'Aufbau', fields: this.vehiclefilefields2 },
				{ title: 'Produkte', fields: this.vehiclefilefields3 },
				{ title: 'Canbus', fields: this.vehiclefilefields4 }
			);
		}

		this.options = {
			lifter_id: null,
			aufbau_id: null,
			fahrgestell_id: null,
			firma_id: null
		};
	}

	createOption(field) {
		const ref = this.dialogService.open(field.createComponent, {
			header: this.translate.instant('BREADCRUMBS.CREATE'),
			width: '70%'
		});

		ref.onClose.subscribe((success: boolean) => {
			if (success) {
				this.getOptions();
				this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
			}
		});
	}

	getOptions() {
		this.fieldgroups.forEach(fg => {
			fg['fields'].forEach(field => {
				if (field.type == 'object' || field.type == 'list') {
					this.loading += 1;
					this.crudService.getAllEntries(field.apiUrl).then(res => {
						this.options[field.key] = res.map(option => ({ label: option.bezeichnung, value: option.ds_this_id, object: option }));
						field.optionSortFieldOptions = [
							{ label: this.translate.instant('HEADERS.lfd_nummer'), value: 'lfd_nummer' },
							{ label: this.translate.instant('HEADERS.ankey'), value: 'ankey' },
							{ label: this.translate.instant('HEADERS.Bezeichnung'), value: 'bezeichnung' }
						];
						//field.optionSortFieldOptions = Object.keys(res[0]).map(key => ({ label: this.translate.instant('HEADERS.' + key), value: key }));
						//field.optionSortFieldOptions = field.optionSortFieldOptions.filter(option => !option.label.includes('HEADERS.'));
						this.changeSorting(field, true);
					}).catch(err => {
						err.error.forEach(e => {
							this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
						})
					}).finally(() => {
						this.loading -= 1;
					});
				}
			});
		});
	}

	/**
	 * Schaltet die Sortierung eines Dropdowns oder Multiselects um
	 * 
	 * @param field Maskenfeld, für das die Sortierung geändert werden soll
	 */
	changeSorting(field: any, init?: boolean) {
		if (init) {
			this.state = JSON.parse(localStorage.getItem(this.stateName));
			if (this.state && this.state.optionSortFields) {
				field.optionSortField = this.state.optionSortFields[field.key] ? this.state.optionSortFields[field.key].optionSortField : 'bezeichnung';
				field.sortDescending = this.state.optionSortFields[field.key] ? this.state.optionSortFields[field.key].sortDescending : false;
			} else {
				this.state = this.state ? this.state : {};
				this.state.optionSortFields = {};
				field.optionSortField = 'bezeichnung';
				field.sortDescending = false;
			}
		}

		if (!field.sortDescending) {
			this.options[field.key].sort((a, b) => (b.object[field.optionSortField] < a.object[field.optionSortField]) ? 1 : -1);
		} else {
			this.options[field.key].sort((a, b) => (b.object[field.optionSortField] > a.object[field.optionSortField]) ? 1 : -1);
		}
		this.state.optionSortFields[field.key] = { optionSortField: field.optionSortField, sortDescending: field.sortDescending };
		localStorage.setItem(this.stateName, JSON.stringify(this.state));
		this.options[field.key] = this.options[field.key].map(option => ({ label: (field.optionSortField == 'bezeichnung' ? option.object.bezeichnung : option.object.bezeichnung + ' (' + option.object[field.optionSortField] + ')'), value: option.value, object: option.object }));
		this.options[field.key] = [...this.options[field.key]];
	}

	ngOnInit() {
		this.vehicle = {};
		this.loading += 1;
		
		this.createPermission = this.accountService.checkPermissions(Module.Masterdata, Operation.CREATE);
		this.readPermission = this.accountService.checkPermissions(Module.Masterdata, Operation.READ);

		this.isMobile = JSON.parse(this.globals.log_Platform).Mobile == 'yes' || JSON.parse(this.globals.log_Platform).Tablet == 'yes';
		this.crudService.getAutoGenerateLogboxId().then(res => {
			this.autoGenerateLogboxId = res;
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});

		//this.loading += 1;
		//this.crudService.getAllChassis().then(res => {
		//	const o = [];
		//	res.forEach(l => {
		//		o.push({ label: l.bezeichnung, value: l.ds_this_id });
		//	});
		//	this.options.fahrgestell_id = o;
		//}).catch(err => {
		//	err.error.forEach(e => {
		//		if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
		//			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
		//		} else {
		//			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
		//		}
		//	})
		//}).finally(() => {
		//	this.loading -= 1;
		//});
		
		//this.loading += 1;
		//this.crudService.getAllConstructions().then(res => {
		//	const o = [];
		//	res.forEach(l => {
		//		o.push({ label: l.bezeichnung, value: l.ds_this_id });
		//	});
		//	this.options.aufbau_id = o;
		//}).catch(err => {
		//	err.error.forEach(e => {
		//		if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
		//			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
		//		} else {
		//			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
		//		}
		//	})
		//}).finally(() => {
		//	this.loading -= 1;
		//});
		
		//this.loading += 1;
		//this.crudService.getAllLifter().then(res => {
		//	const o = [];
		//	res.forEach(l => {
		//		o.push({ label: l.bezeichnung, value: l.ds_this_id });
		//	});
		//	this.options.lifter_id = o;
		//}).catch(err => {
		//	err.error.forEach(e => {
		//		if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
		//			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
		//		} else {
		//			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
		//		}
		//	})
		//}).finally(() => {
		//	this.loading -= 1;
		//});
		
		//this.loading += 1;
		//this.crudService.getAllCompanies().then(res => {
		//	const o = [];
		//	res.forEach(l => {
		//		o.push({ label: l.bezeichnung, value: l.ds_this_id });
		//	});
		//	this.options.firma_id = o;
		//}).catch(err => {
		//	err.error.forEach(e => {
		//		if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
		//			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
		//		} else {
		//			this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
		//		}
		//	})
		//}).finally(() => {
		//	this.loading -= 1;
		//});

		if (this.readPermission) {
			this.getOptions();
		}
	}

	//createNew(type) {
	//	if (type === 'fahrgestell_id') {
	//		this.createNewChassis();
	//	} else if (type === 'aufbau_id') {
	//		this.createNewConstruction();
	//	} else if (type === 'lifter_id') {
	//		this.createNewLifter();
	//	} else if (type === 'firma_id') {
	//		this.createNewCompany();
	//	}
	//}

	//createNewCompany() {
	//	const ref = this.dialogService.open(TblFirmaCreateComponent, {
	//		header: this.translate.instant('BREADCRUMBS.CREATE'),
	//		width: '70%'
	//	});
	//
	//	ref.onClose.subscribe((success: boolean) => {
	//		if (success) {
	//			this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
	//			this.crudService.getAllCompanies().then(res => {
	//				const o = [];
	//				res.forEach(l => {
	//					o.push({ label: l.bezeichnung, value: l.ds_this_id });
	//				});
	//				this.options.firma_id = o;
	//			}).catch(err => {
	//				err.error.forEach(e => {
	//					if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
	//						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
	//					} else {
	//						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
	//					}
	//				})
	//			}).finally(() => {
	//				this.loading -= 1;
	//			});
	//		}
	//	});
	//}

	//createNewChassis() {
	//	const ref = this.dialogService.open(MasterdataChassisCreateDialog, {
	//		header: this.translate.instant('Chassis erstellen'),
	//		width: '70%'
	//	});
	//
	//	ref.onClose.subscribe((success) => {
	//		if (success) {
	//			this.loadChassis();
	//		}
	//	});
	//}

	//createNewConstruction() {
	//	const ref = this.dialogService.open(MasterdataConstructionsCreateDialog, {
	//		header: this.translate.instant('Aufbau erstellen'),
	//		width: '70%'
	//	});
	//
	//	ref.onClose.subscribe((success) => {
	//		if (success) {
	//			this.loadConstructions();
	//		}
	//	});
	//}

	//createNewLifter() {
	//	const ref = this.dialogService.open(MasterdataLifterCreateDialog, {
	//		header: this.translate.instant('Lifter erstellen'),
	//		width: '70%'
	//	});
	//
	//	ref.onClose.subscribe((success) => {
	//		if (success) {
	//			this.loadLifters();
	//		}
	//	});
	//}

	inputDate(dateField, input: string) {
		let date = DateService.inputDateExStatic(input);
		if (date) {
			if (this.vehicle[dateField]) {
				date.setHours(this.vehicle[dateField].getHours(), this.vehicle[dateField].getMinutes(), this.vehicle[dateField].getSeconds(), this.vehicle[dateField].getMilliseconds());
			} else {
				date.setHours(0, 0, 0, 0);
			}
			this.vehicle[dateField] = date;
		}
	}

	inputFileDate(dateField, input: string) {
		let date = DateService.inputDateExStatic(input);
		if (date) {
			if (this.vehicleFile[dateField]) {
				date.setHours(this.vehicleFile[dateField].getHours(), this.vehicleFile[dateField].getMinutes(), this.vehicleFile[dateField].getSeconds(), this.vehicleFile[dateField].getMilliseconds());
			} else {
				date.setHours(0, 0, 0, 0);
			}
			this.vehicleFile[dateField] = date;
		}
	}

	loadChassis() {
		this.loading += 1;
		this.crudService.getAllChassis().then(res => {
			const o = [];
			res.forEach(l => {
				o.push({ label: l.bezeichnung, value: l.ds_this_id });
			});
			this.options.fahrgestell_id = o;
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	loadConstructions() {
		this.loading += 1;
		this.crudService.getAllConstructions().then(res => {
			const o = [];
			res.forEach(l => {
				o.push({ label: l.bezeichnung, value: l.ds_this_id });
			});
			this.options.aufbau_id = o;
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	loadLifters() {
		this.loading += 1;
		this.crudService.getAllLifter().then(res => {
			const o = [];
			res.forEach(l => {
				o.push({ label: l.bezeichnung, value: l.ds_this_id });
			});
			this.options.lifter_id = o;
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	stringIsNullOrWhitespace(value): boolean {
		if (value !== null && value !== undefined) {
			let val = value + '';
			if (val.trim().length > 0) {
				return false;
			}
		}
		return true;
	}

	save() {
		this.loading += 1;
		if (!this.stringIsNullOrWhitespace(this.vehicle.fahrgestellnummer)) {
			if (!this.stringIsNullOrWhitespace(this.vehicle.kennzeichen)) {
				this.crudService.createVehicleFile(this.vehicleFile).then(akte_id => {
					this.vehicle.akte_id = akte_id;
					this.crudService.createVehicle(this.vehicle).then(res => {
						this.router.navigate([this.url + '/' + PATH.LIST]);
						this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
					}).catch(err => {
						err.error.forEach(e => {
							if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
							} else {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
							}
						})
					});
				}).catch(err => {
					err.error.forEach(e => {
						if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
						}
					})
				}).finally(() => {
					this.loading -= 1;
				});
			} else {
				this.messageService.add({
					severity: 'error',
					summary: this.translate.instant('MESSAGES.ERROR'),
					detail: this.translate.instant('MESSAGES.REQUIRED_FIELD', { field: 'Kennzeichen' }),
					life: 3000
				});
				this.loading -= 1;
			}
		} else {
			this.messageService.add({
				severity: 'error',
				summary: this.translate.instant('MESSAGES.ERROR'),
				detail: this.translate.instant('MESSAGES.REQUIRED_FIELD', { field: 'Fahrgestellnummer' }),
				life: 3000
			});
			this.loading -= 1;
		}
	}
}
