import { Component, ViewEncapsulation } from '@angular/core';
import { DateService } from '@app/services/date.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';


@Component({
	templateUrl: './popupCloseOnReasonAddition.component.html',
	styleUrls: [ './style.scss'	],
	encapsulation: ViewEncapsulation.None
})

export class PopupCloseOnReasonAdditionComponent {
	loading = 0;
	selectedTimespan = 0;
	toDate = new Date();
	reason = ""

	constructor(
		public config: DynamicDialogConfig,
		private messageService: MessageService,
		public ref: DynamicDialogRef,
		public translate: TranslateService,
		public dateService: DateService
	) {
	}

	convertTimespanSelection(event) {
		const timespan = this.dateService.getTimespan(event.value);
		if (timespan != null) {
			this.toDate = timespan.toDate;
		}
	}

	inputDate(dateField, input: string) {
		console.log("input date = ", input);
		let date = DateService.inputDateExStatic(input);
		if (date) {
			/*if (dateField == 'fromDate') {
				this.fromDate = date;
			} else */if (dateField == 'toDate') {
				this.toDate = date;
			}
			this.selectedTimespan = -1;
		}
	}

	setTime(input: Date, HH : number, MM : number, SS : number ): Date {
		var y, m, d, hh, mm, ss, ms;
		y = input.getFullYear();
		m = input.getMonth();
		d = input.getDate();
		hh = input.getHours();
		mm = input.getMinutes();
		ss = input.getSeconds();
		ms = input.getMilliseconds();
		return new Date(y, m, d, HH, MM, SS, 0);
	}

	save(): void {
		// hier wird die lokalzeit zur�ckgeliefert, das ist korrekt !
		//this.fromDate = this.fromDate ? this.setTime(this.fromDate as Date, 0, 0, 0) : null;
		this.toDate = this.toDate ? this.setTime(this.toDate as Date, 12, 0, 0) : null;

		this.ref.close(<JSON><unknown>{
			"unlockDate": this.toDate,
			"reasonAddition": this.reason
		});
	}

	cancel(): void {
		this.ref.close(null);
	}
}