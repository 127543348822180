<div class="exception-body access">
	<div class="exception-content">
		<div class="moon">
			<img src="assets/layout/images/pages/asset-moon.svg" alt="mirage-layout" />
		</div>
		<div class="exception-panel">
			<div class="exception-panel-content">
				<span class="tag">401</span>
				<h1>Access Denied</h1>
				<div class="seperator"></div>
				<p>You do not have the necessary permisions. Please contact your admin.</p>
				<button pButton type="button" [routerLink]="['/' + homeUrl]" label="Go To Home"></button>
				<button pButton type="button" [routerLink]="['/' + loginUrl]" label="Login" class="p-ml-2"></button>
			</div>
		</div>
	</div>
</div>