<div class="p-grid">
	<div class="p-col-12">
		<div class="card">
			<h2>
				Impressum
			</h2>
			<br />
			<span>
				ViSy GmbH<br />
				Albert-Schweitzer-Str. 5<br />
				64807 Dieburg<br />
				Germany<br />
				<br />
				Fon: +49 (0) 6071 / 9599 160<br />
				Fax: +49 (0) 6071 / 9599 70<br />
				Internet: <a href="https://www.visy-gmbh.de/">www.visy-gmbh.de</a><br />
				E-Mail: <a href="mailto:info@visy-gmbh.de">info@visy-gmbh.de</a><br />
			</span>
			<br />
			<h4>
				Rechtliche Hinweise
			</h4>
			<h6>
				Geschäftsführer
			</h6>
			Markus Fick & Enrico Skrypzak
			<h6>
				Ust-ID-Nr
			</h6>
			DE299024648
			<h6>
				Registernummer
			</h6>
			94055<br />
			HRB Amtsgericht Darmstadt
		</div>
	</div>
</div>