import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { ListComponent } from '@app/common/templates/list/list.component';
import { Module } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { ExportService } from '@app/services/export.service';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { UploadIdentcodesperreFromTextfileComponent } from '../upload_identcodesperren_dialog/upload_identcodesperren.component';
import * as fs from 'file-saver';

@Component({
	//templateUrl: '../../../../common/templates/list/list.component.html',
	templateUrl: './list.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/list/style.scss'],
	providers: [MessageService, ConfirmationService, DialogService]
})
export class TblGefaessSperreListComponent extends ListComponent {
	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public changeDetectorRef: ChangeDetectorRef,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public elRef: ElementRef,
		public exportService: ExportService,
		public globals: Globals,
		public messageService: MessageService,
		public router: Router,
		public settingsService: SettingsService,
		public translate: TranslateService,
		public dialogService: DialogService
	) {
		super(accountService, breadcrumbService, changeDetectorRef, confirmationService, crudService, elRef, exportService, globals, messageService, router, settingsService, translate);

		this.apiUrl = 'TblGefaessSperre';
		this.name = 'MENU.SPERRE';
		this.url = '/' + PATH.GEFAESS_SPERRE;
		this.module = Module.BHV;

		this.possibleCols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
			{ type: 'text', key: 'bezeichnung_kurz', required: false, width: 400 },
			{ type: 'text', key: 'identcode_sperre', required: false, width: 400 },
			{ type: 'text', key: 'sperrgrund', required: false, width: 400 },
			{ type: 'date', key: 'sperre_von', required: false, width: 400 },
			{ type: 'date', key: 'sperre_bis', required: false, width: 400 },
			{ type: 'numeric', key: 'sperre_flags', required: false, width: 400 },
			{ type: 'boolean', key: 'SYNTH_flag_gesperrt', required: false, width: 400 },
			{ type: 'boolean', key: 'SYNTH_flag_sperre_aufgehoben', required: false, width: 400 },
			{ type: 'boolean', key: 'SYNTH_flag_sperre_storniert', required: false, width: 400 },
			{ type: 'boolean', key: 'SYNTH_typ_graue_liste', required: false, width: 400 },
		];
		this.cols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
			{ type: 'text', key: 'bezeichnung_kurz', required: false, width: 400 },
			{ type: 'text', key: 'identcode_sperre', required: false, width: 400 },
			{ type: 'text', key: 'sperrgrund', required: false, width: 400 },
			{ type: 'date', key: 'sperre_von', required: false, width: 400 },
			{ type: 'date', key: 'sperre_bis', required: false, width: 400 },
			{ type: 'numeric', key: 'sperre_flags', required: false, width: 400 },
			{ type: 'boolean', key: 'SYNTH_flag_gesperrt', required: false, width: 400 },
			{ type: 'boolean', key: 'SYNTH_flag_sperre_aufgehoben', required: false, width: 400 },
			{ type: 'boolean', key: 'SYNTH_flag_sperre_storniert', required: false, width: 400 },
		];

		this.breadcrumbService.setItems([
			{ label: 'MENU.BHV' },
			{ label: 'MENU.GEFAESS', routerLink: ['/' + PATH.GEFAESS] },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.LIST', routerLink: [this.url + '/' + PATH.LIST] }
		]);
	}

	openUploadTextfileDialog() {
		const ref = this.dialogService.open(UploadIdentcodesperreFromTextfileComponent, {
			header: this.translate.instant('BHV.HEADERUPLOADSCHWARZELISTEDIALOG'),
			width: '70%'
		});

		ref.onClose.subscribe((success) => {
			if (success) {
				this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Import complete', life: 3000 });
				this.getAllEntries();
			}
		});
	}

	downloadNegativliste() {
		this.loading += 1;
		this.crudService.exportWastebinLocks().then(res => {
			this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Export complete', life: 3000 });
			const blob = new Blob([res], { type: 'application/octet-stream' });
			fs.saveAs(blob, 'negativ.txt');
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 3000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}
}
