import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from '@services/account.service';
import { SettingsService } from '@services/settings.service';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
	selector: 'app-login',
	templateUrl: './app.login.component.html',
	styleUrls: ['./pages.component.scss'],
	providers: [MessageService, ConfirmationService, CookieService]
})
export class AppLoginComponent {
	dark: boolean;
	loading = false;
	password: string;
	rememberMe = false;
	private returnUrl: string;
	private returnUserID: string;
	username: string;

	constructor(
		private accountService: AccountService,
		private confirmationService: ConfirmationService,
		private cookieService: CookieService,
		private globals: Globals,
		private messageService: MessageService,
		private router: Router,
		private settingsService: SettingsService,
		public route: ActivatedRoute,
		public translate: TranslateService,
	) {
		this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
		this.returnUserID = this.route.snapshot.queryParams.userID || '/';
		this.initLang();
	}

	/**
	 * Öffnet vor dem Login den Dialog über Datenschutz und Zustimmung zur Cookie Nutzung
	 */
	confirmNoteOnTheGDPR(): void {
		const confirmed = this.cookieService.get('ConfirmedNoteOnTheGDPR');
		if (confirmed === 'true') {
			this.login()
		} else {
			this.confirmationService.confirm({
				header: this.translate.instant('CONFIRMATION.GDPR'),
				message: this.translate.instant('MESSAGES.GDPR'),
				icon: 'pi pi-exclamation-triangle',
				rejectLabel: this.translate.instant('CONFIRMATION.NO'),
				acceptLabel: this.translate.instant('CONFIRMATION.YES'),
				accept: () => {
					this.cookieService.set('ConfirmedNoteOnTheGDPR', 'true', 365);
					this.login();
				}
			});
		}
	}

	/**
	 * Sprache setzen
	 */
	initLang(): void {
		const lang = localStorage.getItem('lang');
		if (lang != null) {
			this.translate.use(lang);
		} else {
			this.translate.use(this.translate.getDefaultLang());
		}
	}

	/**
	 * Loggt den Nutzer ein.
	 * Holt sich die Nutzer Tokens und Einstellungen und legt sie in den lokalen Speicher.
	 */
	login(): void {
		if (this.username && this.password) {
			this.loading = true;
			this.accountService.login(this.username, this.password, this.rememberMe).then(res => {
				const accessToken = res.credentials.accessToken;
				const refreshToken = res.credentials.refreshToken;
				localStorage.setItem('jwt', accessToken);
				localStorage.setItem('refreshToken', refreshToken);

				localStorage.setItem('username', res.username);
				localStorage.setItem('userID', res.userID);
				localStorage.setItem('roles', '' + res.roles);
				localStorage.setItem('database', '' + res.database);

				this.globals.log_User = res.username;
				this.globals.log_UserID = res.userID;
				this.globals.log_Client = res.database;
				this.globals.log_Session = accessToken;

				this.loading = true;
				this.settingsService.getSettings().then(response => {
					Object.keys(response).forEach(key => {
						if (response[key]) { localStorage.setItem(key, response[key]); }
						//console.log(key);
						//console.log(response[key]);
					});

					if (res.userID != this.returnUserID || this.returnUrl == '/') {
						if (response['landingPage']) {
							this.returnUrl = '/' + this.settingsService.getRouteForLandingPageAlias(response['landingPage']);
						} else {
							this.returnUrl = '/';
						}
					}

					/*
					if (response.inputStyle) { localStorage.setItem('inputStyle', response.inputStyle); }
					if (response.horizontalMenu) { localStorage.setItem('horizontalMenu', response.horizontalMenu); }
					if (response.staticMenuActive) { localStorage.setItem('staticMenuActive', response.staticMenuActive); }
					if (response.forwardingColor) { localStorage.setItem('forwardingColor', response.forwardingColor); }
					if (response.reversingColor) { localStorage.setItem('reversingColor', response.reversingColor); }
					if (response.darkMode) { localStorage.setItem('darkMode', response.darkMode); }
					if (response.themeColor) { localStorage.setItem('themeColor', response.themeColor); }
					if (response.lang) { localStorage.setItem('lang', response.lang); }
					*/

					this.initLang();
				}).catch(err => {
					err.error.forEach(e => {
						if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
						}
					})
				}).finally(() => {
					this.loading = false;
					this.router.navigate([this.returnUrl]);
				});

			}).catch(err => {
				err.error.forEach(e => {
					if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
					} else {
						this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
					}
				})
			}).finally(() => {
				this.loading = false;
			});
		} else {
			this.messageService.add({
				severity: 'error',
				summary: this.translate.instant('MESSAGES.LOGIN_INVALID'),
				detail: this.translate.instant('MESSAGES.REQUIRED_FIELD', { field: this.username ? this.translate.instant('LOGIN.PASSWORD') : this.translate.instant('LOGIN.EMAIL') }),
				life: 3000
			});
		}
	}

	/*getDatabase(username) {
		this.adminService.getAllUsers().then(res => {
			res.forEach(user => {
				if (user.UserEmail == username) {
					localStorage.setItem('database', '' + user.UserConnectionSettingsName);
				}
			});
		});
	}*/
}
