<div class="layout-wrapper" (click)="onLayoutClick()" [ngClass]="{'layout-horizontal': horizontalMenu,
'layout-wrapper-static': staticMenuActive,
'layout-topbar-mobile-active': topbarMobileMenuActive,
'layout-sidebar-mobile-active': menuMobileActive,
'p-input-filled': inputStyle === 'filled'}" [class]="menuColor" [ngStyle]="{'font-size':fontSize+'px'}">

	<app-menu></app-menu>

	<div #content class="layout-main">
		<app-topbar></app-topbar>

		<app-breadcrumb></app-breadcrumb>

		<div id="innerContent" class="layout-content p-p-3">
			<router-outlet></router-outlet>
		</div>

		<app-footer></app-footer>

		<div class="layout-main-mask"></div>
	</div>
</div>