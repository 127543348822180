import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { ListComponent } from '@app/common/templates/list/list.component';
import { Module } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { ExportService } from '@app/services/export.service';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
	templateUrl: './list.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/list/style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class TblTourListComponent extends ListComponent {
	private allIncludingInactive: boolean = false;

	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public changeDetectorRef: ChangeDetectorRef,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public elRef: ElementRef,
		public exportService: ExportService,
		public globals: Globals,
		public messageService: MessageService,
		public router: Router,
		public settingsService: SettingsService,
		public translate: TranslateService
	) {
		super(accountService, breadcrumbService, changeDetectorRef, confirmationService, crudService, elRef, exportService, globals, messageService, router, settingsService, translate);

		this.apiUrl = 'TblTour';
		this.name = 'MENU.TOUR';
		this.url = '/' + PATH.TOUR;
		this.module = Module.Masterdata;

		this.possibleCols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
			{ type: 'text', key: 'bezeichnung_kurz', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_typ_bezeichnung', required: false, width: 400 },
			{ type: 'text', key: 'FREMD_gruppe_bezeichnungen', required: false, width: 400 },
			{ type: 'boolean', key: 'feiertagstour', required: false, width: 400 },
		];
		this.cols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'ankey', required: true, width: 400 },
			{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
		];

		this.breadcrumbService.setItems([
			{ label: 'MENU.STAMMDATEN' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.LIST', routerLink: [this.url + '/' + PATH.LIST] }
		]);
	}

	switchArchivierte() {
		this.allIncludingInactive = !this.allIncludingInactive;
		this.getAllEntries();
	}

	// override
	getAllEntries() {
		this.loading += 1;
		this.messageService.clear('refresh');
		if (this.allIncludingInactive) {
			this.crudService.getAllToursIncludingInactive().then(res => {
				this.processEntries(res);
				this.resizeTableWidthFromContent(false);
			}).catch(err => {
				err.error.forEach(e => {
					this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
				})
			}).finally(() => {
				this.loading -= 1;
			});
		} else {
			this.crudService.getAllEntries(this.apiUrl).then(res => {
				this.processEntries(res);
				this.resizeTableWidthFromContent(false);
			}).catch(err => {
				err.error.forEach(e => {
					this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
				})
			}).finally(() => {
				this.loading -= 1;
			});
		}
	}
}
