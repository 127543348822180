import { Component } from '@angular/core';
import PATH from '@assets/routes/routes.json';

@Component({
	selector: 'app-accessdenied',
	templateUrl: './app.accessdenied.component.html',
})
export class AppAccessdeniedComponent {
	homeUrl = PATH.DEFAULT;
	loginUrl = PATH.LOGIN;
}
